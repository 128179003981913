import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { LiaSortNumericUpSolid } from "react-icons/lia";

const UpcomingPage = () => {
  const [nextPaymentAmount, setNextPaymentAmount] = React.useState(10000);
  const [comment, setComment] = React.useState("This is a comment");

  return (
    <AccountLayout>
      <h3>Upcoming Payments</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search by name or ID"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              placeholder="All Transactions"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <button className="bg-transparent rounded-4 py-2 px-3 border-yellow fw-semibold">
            Reset
          </button>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col
          md={4}
          className="d-flex align-items-center gap-3 justify-content-end"
        >
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4">
            <CiFilter /> Filter
          </button>
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4 ">
            <LiaSortNumericUpSolid /> Sort
          </button>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Parent I'D</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child I'D</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Current Cycle</th>
                <th className="text-white">Total Enrolled Classes</th>
                <th className="text-white">Completed Classes</th>
                <th className="text-white">Remaining Classes</th>
                <th className="text-white">Last Payment Amount</th>
                <th className="text-white">Last Payment Date</th>
                <th className="text-white">Next Payment Date</th>
                <th className="text-white">Next Payment Amount</th>
                <th className="text-white">Comment</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentName}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.courseId}</td>
                  <td>{item.courseName}</td>
                  <td>{item.currentCycle}</td>
                  <td>{item.totalEnrolledClasses}</td>
                  <td>{item.completedClasses}</td>
                  <td>{item.remainingClasses}</td>
                  <td>{item.lastPaymentAmount}</td>
                  <td>{item.lastPaymentDate}</td>
                  <td>{item.nextPaymentDate}</td>
                  <td>
                    <input
                      type="number"
                      className="p-1"
                      value={nextPaymentAmount}
                      onChange={(e) => setNextPaymentAmount(e.target.value)}
                    />{" "}
                    <br />
                    <button
                      className="btn mt-1 btn-sm w-100 text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Save
                    </button>
                  </td>
                  <td>
                    <textarea
                      type="text"
                      className="p-1"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <br />
                    <button
                      className="btn mt-1 btn-sm w-100 text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p
            className="m-0 fw-semibold text-decoration-underline text_yellow"
            style={{ cursor: "pointer" }}
          >
            View More
          </p>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default UpcomingPage;

const data = [
  {
    id: 1,
    parentId: "P0987",
    parentName: "John Doe",
    childId: "C0987",
    childName: "Jane Doe",
    teacherId: "T0987",
    teacherName: "Jane Doe",
    courseId: "C0987",
    courseName: "Maths",
    currentCycle: 5,
    totalEnrolledClasses: 10,
    completedClasses: 5,
    remainingClasses: 5,
    lastPaymentAmount: 1000,
    lastPaymentDate: "12/05/2021",
    nextPaymentDate: "12/06/2021",
    nextPaymentAmount: 1000,
    comment: "Payment Successful",
  },
  {
    id: 2,
    parentId: "P0987",
    parentName: "John Doe",
    childId: "C0987",
    childName: "Jane Doe",
    teacherId: "T0987",
    teacherName: "Jane Doe",
    courseId: "C0987",
    courseName: "Maths",
    currentCycle: 5,
    totalEnrolledClasses: 10,
    completedClasses: 5,
    remainingClasses: 5,
    lastPaymentAmount: 1000,
    lastPaymentDate: "12/05/2021",
    nextPaymentDate: "12/06/2021",
    nextPaymentAmount: 1000,
    comment: "Payment Successful",
  },
  {
    id: 3,
    parentId: "P0987",
    parentName: "John Doe",
    childId: "C0987",
    childName: "Jane Doe",
    teacherId: "T0987",
    teacherName: "Jane Doe",
    courseId: "C0987",
    courseName: "Maths",
    currentCycle: 5,
    totalEnrolledClasses: 10,
    completedClasses: 5,
    remainingClasses: 5,
    lastPaymentAmount: 1000,
    lastPaymentDate: "12/05/2021",
    nextPaymentDate: "12/06/2021",
    nextPaymentAmount: 1000,
    comment: "Payment Successful",
  },
  {
    id: 4,
    parentId: "P0987",
    parentName: "John Doe",
    childId: "C0987",
    childName: "Jane Doe",
    teacherId: "T0987",
    teacherName: "Jane Doe",
    courseId: "C0987",
    courseName: "Maths",
    currentCycle: 5,
    totalEnrolledClasses: 10,
    completedClasses: 5,
    remainingClasses: 5,
    lastPaymentAmount: 1000,
    lastPaymentDate: "12/05/2021",
    nextPaymentDate: "12/06/2021",
    nextPaymentAmount: 1000,
    comment: "Payment Successful",
  },
  {
    id: 5,
    parentId: "P0987",
    parentName: "John Doe",
    childId: "C0987",
    childName: "Jane Doe",
    teacherId: "T0987",
    teacherName: "Jane Doe",
    courseId: "C0987",
    courseName: "Maths",
    currentCycle: 5,
    totalEnrolledClasses: 10,
    completedClasses: 5,
    remainingClasses: 5,
    lastPaymentAmount: 1000,
    lastPaymentDate: "12/05/2021",
    nextPaymentDate: "12/06/2021",
    nextPaymentAmount: 1000,
    comment: "Payment Successful",
  },
];
