// BookResources
import BookResourcesPage from "./BookResourcesPage";

export const BookResourcesPageConfig = {
  routes: [
    {
      path: "/operation/book-resources",
      exact: true,
      component: BookResourcesPage
    }
  ]
};