import FreeDemoPage from "./FreeDemo";

export const FreeDemoConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/freeDemo",
      exact: true,
      component: FreeDemoPage,
    },
  ],
};
