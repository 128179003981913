import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Row, Table } from "react-bootstrap";
import ParentPayment from "./ParentPayment";
import TeacherPayment from "./TeacherPayment";
import EmployeePayment from "./EmployeePayment";

const WalletPage = () => {
  return (
    <>
      <AccountLayout>
        <h3>Wallet</h3>

        {/* <h4 className="mt-4">All Transactions</h4>

        <Table className="mt-3" striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Parent ID</th>
              <th>Parent Name</th>
              <th>Child ID</th>
              <th>Child Name</th>
              <th>Course ID</th>
              <th>Course Name</th>
              <th>Transaction For</th>
              <th>Transaction Type</th>
              <th>Transaction Date</th>
              <th>Transaction Amount</th>
              <th>Transaction Status</th>
              <th>Transaction Method</th>
              <th>Transaction Description</th>
            </tr>
          </thead>
          <tbody>
            {allTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>{transaction.transactionId}</td>
                <td>{transaction.parentID}</td>
                <td>{transaction.parentName}</td>
                <td>{transaction.childId}</td>
                <td>{transaction.childName}</td>
                <td>{transaction.courseId}</td>
                <td>{transaction.courseName}</td>
                <td>{transaction.transactionFor}</td>
                <td>{transaction.transactionType}</td>
                <td>{transaction.transactionDate}</td>
                <td>{transaction.transactionAmount}</td>
                <td>{transaction.transactionStatus}</td>
                <td>{transaction.transactionMethod}</td>
                <td>{transaction.transactionDescription}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}

        <ParentPayment />
        <TeacherPayment />
        {/* <EmployeePayment /> */}
      </AccountLayout>
    </>
  );
};

export default WalletPage;

const allTransactions = [
  {
    id: 1,
    transactionId: "TRN-001",
    parentID: "PRNT-001",
    parentName: "John Doe",
    childId: "CHLD-001",
    childName: "Jane Doe",
    courseId: "CRS-001",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 2,
    transactionId: "TRN-002",
    parentID: "PRNT-002",
    parentName: "John Doe",
    childId: "CHLD-002",
    childName: "Jane Doe",
    courseId: "CRS-002",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 3,
    transactionId: "TRN-003",
    parentID: "PRNT-003",
    parentName: "John Doe",
    childId: "CHLD-003",
    childName: "Jane Doe",
    courseId: "CRS-003",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 4,
    transactionId: "TRN-004",
    parentID: "PRNT-004",
    parentName: "John Doe",
    childId: "CHLD-004",
    childName: "Jane Doe",
    courseId: "CRS-004",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 5,
    transactionId: "TRN-005",
    parentID: "PRNT-005",
    parentName: "John Doe",
    childId: "CHLD-005",
    childName: "Jane Doe",
    courseId: "CRS-005",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
];
