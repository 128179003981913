import TeacherLivePage from "./TeacherLivePage";

export const TeacherLivePageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/live",
      exact: true,
      component: TeacherLivePage
    }
  ]
};
