import React, { useState } from 'react';
import { Modal, Button, ProgressBar, Form } from 'react-bootstrap';
import { uploadFileToS3 } from '../../utils/AWS';
import { toast } from 'react-toastify';

const ChatFilesModel = ({ show, setShow, fileType, id, socket, isPToP, closeState }) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSendMsgFile = (fileType, fileKey) => {
        try {
            const message = {
                file_key: fileKey,
                file_type: fileType === 'image' ? 'Image' : fileType === 'video' ? 'Video' : 'Pdf',
            };

            if (isPToP) {
                message.convo_id = id;
                socket.emit("send-message", message);
            } else {
                message.grp_id = id;
                socket.emit("send_msg_grp", message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            toast.error("Please select a file first.");
            return;
        }

        setUploading(true);
        setUploadProgress(0); // Reset progress

        try {
            const url = await uploadFileToS3(file, setUploadProgress); // Pass the progress callback
            handleSendMsgFile(fileType, url); // Send the file after successful upload
            closeState(false); // Close the model when the upload completes
            setShow(false);
        } catch (error) {
            toast.error(`Error uploading file: ${error.message}`);
        } finally {
            setUploading(false);
        }
    };

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Upload {fileType.charAt(0).toUpperCase() + fileType.slice(1)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {uploading && (
                    <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} className="mt-3" />
                )}
                <Form.Group className="mt-2">
                    <Form.Label>Choose File</Form.Label>
                    <Form.Control
                        type="file"
                        accept={fileType === 'image' ? 'image/*' : fileType === 'video' ? 'video/*' : '*/*'}
                        onChange={handleFileChange}
                    />
                </Form.Group>
                <Button className="mt-2" variant="primary" onClick={handleUpload} disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default ChatFilesModel;
