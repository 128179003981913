import React from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

function AssignmentModal({ showModal, closeModal }) {
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      style={{ marginTop: '13vh', zIndex:9999 }}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formAssignmentTitle'>
              <Form.Label>Assignment Title</Form.Label>
              <Form.Control type='text' placeholder='Enter Title' />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formAssignmentFile'>
              <Form.Label>Assignment File</Form.Label>
              <Form.Control type='file' />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formSubmissionDate'>
              <Form.Label>Assignment Submission Date</Form.Label>
              <Form.Control type='date' />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formStartDate'>
              <Form.Label>Assignment Start Date</Form.Label>
              <Form.Control type='date' />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className='mt-3' controlId='formInstructions'>
              <Form.Label>Instructions</Form.Label>
              <Form.Control as='textarea' rows={3} placeholder='Enter instructions here...' />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={closeModal}>
          Close
        </Button>
        <Button variant='primary' onClick={closeModal}>
          Add Assignment
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AssignmentModal;
