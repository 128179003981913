import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";


import navigationConfig from "../../../oftadeh-configs/AccountNavigationConfig";
import OftadehNavGroup from "../../parentComponents/OftadehNavigation/sections/OftadehNavGroup";
import OftadehNavCollapse from "../../parentComponents/OftadehNavigation/sections/OftadehNavCollapse";
import OftadehNavItem from "../../parentComponents/OftadehNavigation/sections/OftadehNavItem";
import OftadehNavLink from "../../parentComponents/OftadehNavigation/sections/OftadehNavLink";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logoBg: {
    // backgroundColor: theme.palette.type !== "dark" && "#18202c",
    // backgroundColor: "var(--color-primary)",
    // backgroundColor: "linear-gradient(to right, var(--color-primary), red)",
  },
  logo: {
    padding: "1rem",
    "& span": {
      display: "block",
      color: "red",
    },
  },
  navCustom: {
    "& .MuiTypography-root": {
      fontSize: ".85rem",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
      paddingBottom: "8px",
    },
    "& .MuiCollapse-wrapperInner a": {
      paddingLeft: "50px",
    },
  },
}));

const AccountNavigation = (props) => {
  const classes = useStyles(props);
  return (
    <div>
      <div className={clsx(classes.toolbar, classes.logoBg)}>
        <Typography
          className={classes.logo}
          variant="h6"
          component="h1"
          align="center"
        >
          Account Dashboard
        </Typography>
      </div>
      <Divider />
      <List className={classes.navCustom}>
        {navigationConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && (
              <OftadehNavGroup item={item} nestedLevel={0} />
            )}

            {item.type === "collapse" && (
              <OftadehNavCollapse item={item} nestedLevel={0} />
            )}

            {item.type === "item" && (
              <OftadehNavItem item={item} nestedLevel={0} />
            )}

            {item.type === "link" && (
              <OftadehNavLink item={item} nestedLevel={0} />
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default AccountNavigation;
