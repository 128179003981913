import React from 'react'
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Chip, IconButton } from '@mui/material';
import { MdDelete } from 'react-icons/md';
function ParentProfile() {
    return (
        <OperationLayout>
            <h5>Parent {'>'} 142512001</h5>

            <Container className="n-box-shadow rounded-2 mt-4 p-3">
                <Col className="d-flex align-items-center justify-content-between">
                    <Col md={12}>
                        <Chip className="bg-dark text-white" label="Personal Settings" />
                        <Button
                            className="teacher-button bg-warning text-white ms-2 n-box-shadow rounded-5 border-0"
                            variant="outlined"
                        >
                            {" "}
                            Block{" "}
                        </Button>
                        <Button
                            className="teacher-button bg-danger text-white ms-2 n-box-shadow rounded-5 border-0"
                            variant="outlined"
                        >
                            {" "}
                            Delete{" "}
                        </Button>

                    </Col>
                </Col>

                <Row className="mt-3 p-2">
                    <Col
                        md={3}
                        className="d-flex align-items-center justify-content-center mt-3"
                    >
                        {" "}
                        <div className="profile-parent-page-image-area">
                            <img
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                                alt=""
                            />
                        </div>{" "}
                    </Col>
                    <Col md={5} className="mt-3">
                        <Col md={6} className="mt-1">
                            <b>Parent ID </b> : 9821hjwhuy8y
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Visible Name </b> : Hello Name
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Full Name </b> : Kishan Jhadav
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Email </b> : parent@gmail.com
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Phone </b> : +91-123456789
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Whatsapp no.</b> : +91-123456789
                        </Col>
                    </Col>
                    <Col md={4} className="mt-3">
                        <Col className="mt-1">
                            <b>Nationality </b> : Indian
                        </Col>
                        <Col className="mt-1">
                            <b>Address </b> : Lorem ipsum dolor sit amet.
                        </Col>
                        <Col className="mt-1">
                            <b>City </b> : Bhopal
                        </Col>
                        <Col className="mt-1">
                            <b>Pin Code</b> : 12345
                        </Col>
                        <Col className="mt-1">
                            <b>Country </b> : India
                        </Col>
                        <Col className="mt-1">
                            <b>Residency </b> : India
                        </Col>
                        <Col className="mt-1">
                            <b>Education Approach </b> : IIT Preparation
                        </Col>
                    </Col>
                </Row>
            </Container>

            <Container className="n-box-shadow rounded-2 p-3 mt-5">
                <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Child - 1" />
                    </Col>
                </Col>
                <Row>
                    <Col md={6} className="mt-1 ps-2">
                        <b>unique code : </b> 545CsafdAE54654
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Name : </b> Krishna pawar
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Standard : </b> 8 <sup>th</sup>
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Board : </b> CBSE
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Nationality : </b> Indian
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Favourite subject : </b> Hindi
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Weakest subject : </b> Spanice
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Date Of Birth : </b> 26 Jun 2013
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Age : </b> 17 year
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Current School : </b> Lorem ipsum dolor sit amet.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Learning Difficulty : </b> Hindi, English
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Gender : </b> Male
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Hobby : </b> Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Odio, vel.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Intrest : </b> Lorem ipsum dolor sit amet consectetur,
                        adipisicing elit. In, corrupti.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Display Name : </b> Joh Smith
                    </Col>
                </Row>
            </Container>

            <Container className="n-box-shadow rounded-2 p-3 mt-5">
                <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Child - 2" />
                    </Col>
                </Col>
                <Row>
                    <Col md={6} className="mt-1 ps-2">
                        <b>unique code : </b> 545CsafdAE54654
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Name : </b> Krishna pawar
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Standard : </b> 8 <sup>th</sup>
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Board : </b> CBSE
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Nationality : </b> Indian
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Favourite subject : </b> Hindi
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Weakest subject : </b> Spanice
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Date Of Birth : </b> 26 Jun 2013
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Age : </b> 17 year
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Current School : </b> Lorem ipsum dolor sit amet.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Learning Difficulty : </b> Hindi, English
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Gender : </b> Male
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Hobby : </b> Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Odio, vel.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Intrest : </b> Lorem ipsum dolor sit amet consectetur,
                        adipisicing elit. In, corrupti.
                    </Col>
                    <Col md={6} className="mt-1 ps-2">
                        <b>Display Name : </b> Joh Smith
                    </Col>
                </Row>
            </Container>

            <Container className="n-box-shadow rounded-2 p-3 mt-5">
                <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Contact Details" />
                    </Col>
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Primary Email : </b> krishnapawar@gmail.com
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Secondary Email : </b> krishnapawar@gmail.com
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Primary Phone : </b> +91 1234567890
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Secondary Phone : </b> +91 1234567890
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Preffered call timings : </b> 9.00 am - 2.00 pm ist
                </Col>
            </Container>

            <Container className="n-box-shadow rounded-2 p-3 mt-5">
                <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Other details" />
                    </Col>
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Country : </b> Canada
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Time zone : </b> 5.00 GMT
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Language : </b> English
                </Col>
            </Container>

            <Container className="n-box-shadow rounded-2 p-3 mt-5">
                <Col className="d-flex align-items-center justify-content-between mb-3">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Preferences" />
                    </Col>
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Do You want Homework for your child. : </b> Yes
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Do You want teacher can take test. : </b> No
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Whether the parent wants a teacher meeting for PTA. : </b> Yes
                </Col>
                <Col className="mt-1 ps-2">
                    <b>Prefered Notifications of classes by. : </b> Email
                </Col>
            </Container>
        </OperationLayout>
    )
}

export default ParentProfile