import ClassPage from "./ClassPage";

export const ClassPageConfig = {
  routes: [
    {
      path: "/teacher/classes",
      exact: true,
      component: ClassPage
    }
  ]
};
