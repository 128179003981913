import React, { useState } from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { FaEye } from "react-icons/fa6";
import { IconButton } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { TiArrowForward } from "react-icons/ti";

const TecherData = [
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 10,
    joiningDate: "12 January 2012 10:30 AM",
  },
];

const PeoplePage = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <OperationLayout>
      <h4>Peoples</h4>
      <Row className="mt-4 align-items-center">
        <Col md={10}>
          <h5>Top Clients</h5>
        </Col>
        <Col md={2} className="m-0">
          <Form.Select
            className="shadow-sm border-0"
            style={{ borderRadius: "25px" }}
          >
            <option>Sort By</option>
            <option>Month</option>
            <option>Weak</option>
            <option>Year</option>
          </Form.Select>
        </Col>
      </Row>

      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">Parent ID</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">No. of Students</th>
            <th className="text-white">No. Class En.</th>
            <th className="text-white">Total No. of Amount Pay</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>PAR3313</td>
            <td>John Doe</td>
            <td>3</td>
            <td>5</td>
            <td>5000</td>
          </tr>
          <tr className="bg-light">
            <td>PAR3313</td>
            <td>John Doe</td>
            <td>3</td>
            <td>5</td>
            <td>5000</td>
          </tr>
          <tr className="bg-light">
            <td>PAR3313</td>
            <td>John Doe</td>
            <td>3</td>
            <td>5</td>
            <td>5000</td>
          </tr>
        </tbody>
      </Table>

      <Row className="mt-4 align-items-center">
        <Col md={10}>
          <h5>Top Teachers</h5>
        </Col>
        <Col md={2} className="m-0">
          <Form.Select
            className="shadow-sm border-0"
            style={{ borderRadius: "25px" }}
          >
            <option>Sort By</option>
            <option>Month</option>
            <option>Weak</option>
            <option>Year</option>
          </Form.Select>
        </Col>
      </Row>

      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">Teacher Id</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Grade</th>
            <th className="text-white">Board</th>
            <th className="text-white">Teacher Rating</th>
            <th className="text-white">No. of Course</th>
            <th className="text-white">Hours of Teaching</th>
            <th className="text-white">No. of Students</th>
            <th className="text-white">Joining Date</th>
            <th className="text-white" colSpan={3}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {TecherData.map((data, index) => (
            <tr className="bg-light" key={index}>
              <td>{data.teacherId}</td>
              <td>{data.teacherName}</td>
              <td>{data.subject.join(", ")}</td>
              <td>{data.grade}</td>
              <td>{data.board}</td>
              <td>{data.teacherRating}</td>
              <td>{data.noOfCourse}</td>
              <td>{data.hoursOfTeaching}</td>
              <td>
                <p
                  onClick={handleShow}
                  className="text-decoration-underline text-primary"
                  style={{ cursor: "pointer" }}
                >
                  {data.noOfStudents}
                </p>
              </td>
              <td>{data.joiningDate}</td>
              <td>
                <IconButton aria-label="delete">
                  <FaRegFilePdf color="blue" />
                </IconButton>
              </td>
              <td>
                <IconButton aria-label="delete">
                  <FaEye color="blue" />
                </IconButton>
              </td>
              <td>
                <IconButton aria-label="delete">
                  <TiArrowForward color="blue" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="mt-4 align-items-center">
        <Col md={10}>
          <h5>Top Students</h5>
        </Col>
        <Col md={2} className="m-0">
          <Form.Select
            className="shadow-sm border-0"
            style={{ borderRadius: "25px" }}
          >
            <option>Sort By</option>
            <option>Month</option>
            <option>Weak</option>
            <option>Year</option>
          </Form.Select>
        </Col>
      </Row>
      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">Student ID</th>
            <th className="text-white">Student Name</th>
            <th className="text-white">Standard </th>
            <th className="text-white">Grade </th>
            <th className="text-white">Board </th>
            <th className="text-white">No. Class En.</th>
            <th className="text-white">Avg. H/W Marks</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>A</td>
            <td>CBSE</td>
            <td>5</td>
            <td>90</td>
          </tr>
          <tr className="bg-light">
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>A</td>
            <td>CBSE</td>
            <td>5</td>
            <td>90</td>
          </tr>
          <tr className="bg-light">
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>A</td>
            <td>CBSE</td>
            <td>5</td>
            <td>90</td>
          </tr>
        </tbody>
      </Table>

      <Modal show={show} size="lg" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped hover>
            <thead
              style={{
                background: "var(--color-primary)",
              }}
            >
              <tr>
                <th className="text-white">SN</th>
                <th className="text-white">Student ID</th>
                <th className="text-white">Student Name</th>
                <th className="text-white">Standard </th>
                <th className="text-white">Grade </th>
                <th className="text-white">Board </th>
                <th className="text-white">No. Class En.</th>
                <th className="text-white">Avg. H/W Marks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>2</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>3</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>4</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>5</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>6</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>7</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>8</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>9</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
              <tr>
                <td>10</td>
                <td>STU3313</td>
                <td>John Doe</td>
                <td>10th</td>
                <td>A</td>
                <td>CBSE</td>
                <td>5</td>
                <td>90</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </OperationLayout>
  );
};

export default PeoplePage;
