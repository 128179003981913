import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { LiaSortNumericUpSolid } from "react-icons/lia";
import { BsDownload } from "react-icons/bs";

const PayementLogsPage = () => {
  const handleDownloadInvoice = (transactionId) => {
    // Implement logic to download invoice based on the transaction ID
    console.log(`Downloading invoice for transaction ID: ${transactionId}`);
  };
  return (
    <AccountLayout>
      <h3>Payment Logs</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search by name or ID"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              placeholder="All Transactions"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="transactionTypeSelect">
            <Form.Control
              as="select"
              className="bg-transparent border-yellow rounded-4 shadow"
            >
              <option value="all">All Transactions</option>
              <option value="parent">Parent Transactions</option>
              <option value="teacher">Teacher Transactions</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={2}>
          <button className="bg-transparent rounded-4 py-2 px-3 border-yellow fw-semibold">
            Reset
          </button>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col
          md={4}
          className="d-flex align-items-center gap-3 justify-content-end"
        >
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4">
            <CiFilter /> Filter
          </button>
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4 ">
            <LiaSortNumericUpSolid /> Sort
          </button>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Transaction ID</th>
                <th className="text-white">Amount</th>
                <th className="text-white">Payment Type</th>
                <th className="text-white">Payment Status</th>
                <th className="text-white">Payment Date</th>
                <th className="text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
                <td>
                  <button
                    className="bg-yellow rounded-4 py-2 px-3 border-0 text-white"
                    onClick={() => handleDownloadInvoice("123456789")}
                  >
                    <BsDownload /> Invoice
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p
            className="m-0 fw-semibold text-decoration-underline text_yellow"
            style={{ cursor: "pointer" }}
          >
            View More
          </p>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default PayementLogsPage;
