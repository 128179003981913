import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import ParentFooter from "../../components/ParentFooter";
import { LuSend } from "react-icons/lu";
import { TbCertificate } from "react-icons/tb";
import { MdOutlineCloudUpload } from "react-icons/md";

const CertificateManagement = () => {
  const [showCertificateModel, setShowCertificateModel] = useState(false);
  const [file, setFile] = useState(null);
  const handleShowCertificateModel = () => setShowCertificateModel(true);
  const handleCloseCertificateModel = () => setShowCertificateModel(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    // Handle the file upload logic here
    console.log('File uploaded:', file);
    setShowCertificateModel(false);
  };
  return (
    <TeacherLayout>
      <h3 className='mt-3'>Certificate Management</h3>
      <Row className='mt-3 mb-3 d-flex align-items-center justify-content-center'>
        <Col md={6} className='pt-3'>

        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-end"
        >
          <button className='w-100 mt-3 px-0 text-white bg-primary py-2 border rounded-3 me-2' size='sm'>
            <TbCertificate size={22} />
          </button>
          <div className="form-group col-md-3 mt-3">
            <select
              className='form-control border-yellow'
              id='gender'
            >
              <option value="" disabled selected>
                All
              </option>
              <option value="Waiting">Waiting</option>
              <option value="Approved">Approved</option>
              <option value="Recieved">Recieved</option>
              <option value="Discontinued">Discontinued</option>
            </select>
          </div>
          <div className="form-group ms-2 col-md-6 mt-3 ">
            <Form.Group controlId="searchInput">
              <InputGroup>
                <Form.Control
                  className="border-yellow"
                  type="text"
                  placeholder="Search"
                />
                <InputGroup.Text className="border-yellow">
                  <BsSearch />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </div>
        </Col>
      </Row>

      <Modal style={{ marginTop: '10vh', zIndex: '142512001' }} show={showCertificateModel} onHide={handleCloseCertificateModel}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile">
              <Form.Label>Upload Certificate</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCertificateModel}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped bordered hover responsive>
        <thead style={{ background: "var(--color-primary)" }}>
          <tr>
            <th className="text-white">S. No.</th>
            <th className="text-white">Course ID</th>
            <th className="text-white">Course Name</th>
            <th className="text-white">grade</th>
            <th className="text-white">gender</th>
            <th className="text-white">subject/course</th>
            <th className="text-white">Classes/attended</th>
            <th className="text-white" colSpan={2}>action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Co@#fd34</td>
            <td>Benerjee</td>
            <td>16</td>
            <td>11</td>
            <td>XYZ</td>
            <td>12</td>
            <td className="student-managment-teacher-view-btn">
              <center>
                <MdOutlineCloudUpload onClick={handleShowCertificateModel} size={22} />
              </center>
            </td>
            <td className="student-managment-teacher-view-btn">
              <center><LuSend size={22} /></center>
            </td>

          </tr>
          <tr>
            <td>2.</td>
            <td>Co@#fd34</td>
            <td>Benerjee</td>
            <td>16</td>
            <td>11</td>
            <td>XYZ</td>
            <td>12</td>
            <td className="student-managment-teacher-view-btn">
              <center>
                <MdOutlineCloudUpload onClick={handleShowCertificateModel} size={22} />
              </center>
            </td>
            <td className="student-managment-teacher-view-btn">
              <center><LuSend size={22} /></center>
            </td>
          </tr>
          <tr>
            <td>3.</td>
            <td>Co@#fd34</td>
            <td>Benerjee</td>
            <td>16</td>
            <td>11</td>
            <td>XYZ</td>
            <td>12</td>
            <td className="student-managment-teacher-view-btn">
              <center>
                <MdOutlineCloudUpload onClick={handleShowCertificateModel} size={22} />
              </center>
            </td>
            <td className="student-managment-teacher-view-btn">
              <center><LuSend size={22} /></center>
            </td>
          </tr>
          <tr>
            <td>4.</td>
            <td>Co@#fd34</td>
            <td>Benerjee</td>
            <td>16</td>
            <td>11</td>
            <td>XYZ</td>
            <td>12</td>
            <td className="student-managment-teacher-view-btn">
              <center>
                <MdOutlineCloudUpload onClick={handleShowCertificateModel} size={22} />
              </center>
            </td>
            <td className="student-managment-teacher-view-btn">
              <center><LuSend size={22} /></center>
            </td>
          </tr>
          <tr>
            <td>5.</td>
            <td>Co@#fd34</td>
            <td>Benerjee</td>
            <td>16</td>
            <td>11</td>
            <td>XYZ</td>
            <td>12</td>
            <td className="student-managment-teacher-view-btn">
              <center>
                <MdOutlineCloudUpload onClick={handleShowCertificateModel} size={22} />
              </center>
            </td>
            <td className="student-managment-teacher-view-btn">
              <center><LuSend size={22} /></center>
            </td>
          </tr>
        </tbody>
      </Table>
      <ParentFooter />
    </TeacherLayout>
  );
};

export default CertificateManagement;
