import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Container, Row, Col, Modal, Form, Card } from "react-bootstrap";
import { Button as MaterialButton } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import ParentFooter from "../../components/ParentFooter";
import { MdCallReceived, MdOutlineCallMade } from "react-icons/md";
import { toast } from "react-toastify";
import { FiDownload } from "react-icons/fi";
import { IoAlertOutline } from "react-icons/io5";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

const WalletPage = () => {
  const [showChangeAccountModal, setShowChangeAccountModal] = useState(false);
  const [wallet, setWallet] = useState({});
  const [currentTab, setCurrentTab] = useState(1);
  const token = useSelector((state) => state.token.value);

  const [accountDetails, setAccountDetails] = useState({
    accountNumber: "1234-5678-9012-3456",
    bankName: "Sample Bank",
  });

  const fetchWallet = async () => {
    try {
      const res = await axios.get("/teacher/wallet", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        setWallet(res.data.data.wallet);
      } else {
        toast.error(res.data.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWallet();
  }, []);

  const handleShowChangeAccountModal = () => {
    setShowChangeAccountModal(true);
  };

  const handleCloseChangeAccountModal = () => {
    setShowChangeAccountModal(false);
  };

  const handleSaveAccountDetails = (formData) => {
    setAccountDetails({
      accountNumber: formData.accountNumber,
      bankName: formData.bankName,
    });
    handleCloseChangeAccountModal();
  };

  return (
    <TeacherLayout>
      <h3>Wallet</h3>

      <Container className="mt-4">
        <Row className="mb-4">
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Your Balance</Card.Title>
                <Card.Text>
                  <h2>${wallet?.value}</h2>

                  <button className="mt-3 w-100 border-0 bg-yellow py-2 px-4 rounded-4 text-white">
                    Withdraw
                  </button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Recent Transactions</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Payment received - $100.00</li>
                    <li>Payment received - $1800.00</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={8}>
            <Card>
              <Card.Body>
                <Card.Title>Account Details</Card.Title>

                <Card.Text>
                  <p className="mt-4">
                    {" "}
                    <b>Account Number</b> : 1234567890009876
                  </p>

                  <p>
                    {" "}
                    <b>Bank Name</b> : Sample Bank
                  </p>

                  <p>
                    <b>IFSC Code</b> : 1234-5678-9012-3456
                  </p>

                  <p>
                    {" "}
                    <b>Account Holder Name</b> : John Doe
                  </p>

                  <p>
                    {" "}
                    <b>Branch Name</b> : Sample Branch
                  </p>

                  <button
                    className="mt-3 w-100 border-0 bg-yellow py-2 px-4 rounded-4 text-white"
                    onClick={handleShowChangeAccountModal}
                  >
                    Change Account Details
                  </button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Col className="mt-5 mb-3 d-flex gap-3 flex-wrap">
          <span
            className={
              currentTab === 1
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer"
                : "all-transaction cursor-pointer"
            }
            onClick={() => setCurrentTab(1)}
          >
            All Transactions
          </span>

          <span
            className={
              currentTab === 2
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(2)}
          >
            Incoming
          </span>
          <span
            className={
              currentTab === 3
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(3)}
          >
            Outgoing
          </span>
          <span
            className={
              currentTab === 4
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(4)}
          >
            Fine
          </span>
        </Col>

        {currentTab === 1 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-danger rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-danger me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">failed transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Name</b> : English
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student Name</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Date</b> : 24 January 2023
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Time</b> : 2:00 PM
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Cycle Count</b> : 2/5
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Class Count</b> : 2/5
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Joined</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Total Hours</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    // onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {currentTab === 2 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-danger rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-danger me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">failed transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Name</b> : English
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student Name</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Date</b> : 24 January 2023
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Time</b> : 2:00 PM
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Cycle Count</b> : 2/5
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Class Count</b> : 2/5
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Teacher Status</b> :{" "}
                  <span className="badge  bg-primary">Join late</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Id</b> : ijhsadhih
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Total Hours</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    // onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {currentTab === 3 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-danger rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-danger me-3">
                  <MdOutlineCallMade size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">failed transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdOutlineCallMade size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Start Date</b> : 24 January 2023
                </Col>
                <Col className="mt-1" md={6}>
                  <b>End Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Id</b> : ijhsadhih
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Total Hours</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    // onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <IoAlertOutline size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {currentTab === 4 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-danger rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-danger me-3">
                  <MdOutlineCallMade size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">failed transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdOutlineCallMade size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Name</b> : English
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student Name</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Date</b> : 24 January 2023
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Time</b> : 2:00 PM
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Cycle Count</b> : 2/5
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Class Count</b> : 2/5
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Teacher Status</b> :{" "}
                  <span className="badge  bg-primary">Join late</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Id</b> : ijhsadhih
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Total Hours</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <Col md={12}>
                  Apologies for missing class. 500 deducted for the fine. I'll
                  ensure better attendance. Acknowledging deduction. Thank you
                  for your understanding.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    // onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <IoAlertOutline size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {/* Change Account Modal */}
        <Modal
          show={showChangeAccountModal}
          onHide={handleCloseChangeAccountModal}
          centered
          style={{ zIndex: 99999 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="accountNumber">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter account holder name"
                />
              </Form.Group>
              <Form.Group controlId="accountNumber">
                <Form.Label>Bank IFSC Code</Form.Label>
                <Form.Control type="text" placeholder="Enter bank IFSC code" />
              </Form.Group>
              <Form.Group controlId="accountNumber">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter account number"
                  defaultValue={accountDetails.accountNumber}
                />
              </Form.Group>
              <Form.Group controlId="bankName">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter bank name"
                  defaultValue={accountDetails.bankName}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="border-0 bg-yellow py-2 px-4 rounded-4 text-white"
              onClick={() => handleSaveAccountDetails}
            >
              Save Changes
            </button>
            <button
              className="border-yellow rounded-4 text_yellow py-2 px-4 bg-transparent"
              onClick={handleCloseChangeAccountModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
      <ParentFooter />
    </TeacherLayout>
  );
};

export default WalletPage;
