import UpcomingPaymentPage from "./UpcomingPage";

export const UpcomingPaymentPageConfig = {
  routes: [
    {
      path: "/account/dashboard/upcoming-payment",
      exact: true,
      component: UpcomingPaymentPage
    }
  ]
};
