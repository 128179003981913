import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import AccountLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import "../../assets/css/parent/parent.css";
import { Col, Row, Table, Modal, Button, Form } from "react-bootstrap";
import { FaSort } from "react-icons/fa";
import { MdOutlineFilterAltOff, MdSend } from "react-icons/md";
import { RiFileCopy2Line } from "react-icons/ri";

const RefundPage = (props) => {
  const { history } = props;
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [autoLapse, setAutoLapse] = useState(false);

  const handleShowRefundModal = (refund) => {
    setSelectedRefund(refund);
    setShowRefundModal(true);
  };

  const handleCloseRefundModal = () => {
    setShowRefundModal(false);
  };

  const handleApproveRefund = () => {
    // Implement logic to approve refund and forward reasons to admin
    console.log(`Refund approved for: ${selectedRefund.name}`);
    handleCloseRefundModal();
  };

  const handleShareTransaction = () => {
    // Implement logic to share transaction
    console.log("Transaction shared");
  };

  const handleShareInvoice = () => {
    // Implement logic to share invoice
    console.log("Invoice shared");
  };

  const handleAutoLapseChange = () => {
    setAutoLapse(!autoLapse);
  };

  return (
    <AccountLayout>
      <h3>Refund</h3>
      {/* <AccountLocation path={history} /> */}
      <Row className="mt-3 mb-3">
        <Col md={6} className="d-flex align-items-center justify-content-start">
          <Col md={4}>
            <Form.Group controlId="nameInput">
              <Form.Control className="shadow mt-3" type="text" placeholder="Enter Tuition ID" />
            </Form.Group>
          </Col>
          <Col className="ms-3 mt-3">
            <Button className="black-outline-button border-0 shadow rounded-3" variant="outlined">
              {" "}
              Set{" "}
            </Button>
          </Col>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <Button
            className="black-outline-button border-0 shadow rounded-3 mt-3"
            variant="outlined"
            startIcon={<MdOutlineFilterAltOff />}
          >
            {" "}
            Filter{" "}
          </Button>
          <Button
            className="black-outline-button border-0 shadow rounded-3 ms-3 mt-3"
            variant="outlined"
            startIcon={<FaSort />}
          >
            {" "}
            Sort{" "}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={6} className="d-flex align-items-center justify-content-start">
          <Form.Check
            type="checkbox"
            id="autoLapseCheckbox"
            label="Auto Lapse After 45 Days (No Classes Taken)"
            checked={autoLapse}
            onChange={handleAutoLapseChange}
          />
        </Col>
      </Row>
      <Table
        responsive
        style={{ backgroundColor: "#FFD743" }}
        striped
        hover
        className="mt-3 custom-styled-table rounded-3 text-center"
      >
        <thead>
          <tr>
            <th>Refund Date</th>
            <th>Name</th>
            <th>Account</th>
            <th>Amount</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>05 Apr. 2022</td>
            <td>John Doe</td>
            <td>Account#001</td>
            <td>1000</td>
            <td>parent</td>
            <td>
              <Button variant="success" onClick={() => handleShowRefundModal({ name: "John Doe" })}>
                Approve Refund
              </Button>
              <Button variant="primary ms-2" onClick={handleShareTransaction}>
               <MdSend />
              </Button>
              <Button variant="info ms-2" onClick={handleShareInvoice}>
               <RiFileCopy2Line />
              </Button>
            </td>
          </tr>
          {/* Additional rows */}
        </tbody>
      </Table>

      {/* Refund Modal */}
      <Modal show={showRefundModal} onHide={handleCloseRefundModal} centered
        style={{ zIndex: 9999 }}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to approve the refund for {selectedRefund && selectedRefund.name}?</p>
          <Form.Group controlId="reasonInput">
            <Form.Label>Reason for Refund:</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleApproveRefund}>
            Approve
          </Button>
          <Button variant="secondary" onClick={handleCloseRefundModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default RefundPage;
