import React from "react";
import Button from "./Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { FaQuoteLeft } from "react-icons/fa6";
import testi_img_1 from "../assets/testi_img_1.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Testimonial = () => {
  const navigate = useNavigate();
  return (
    <div className="testi p-md-5 p-3">
      <div className="row">
        <div className="col-md-4 px-md-0 px-3 ">
          <div className="badch_c mb-3 py-1 px-3">
            <p className="m-0">Testimonials</p>
          </div>
          <h2 className="fw-bold">
            We love these <br /> rave reviews!
          </h2>
          <p>
            The value of learniee can be measured by impact we’ve had on our
            users. Here’s what they have to say about us!
          </p>
          <motion.div
            className="mt-4"
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            onClick={() => navigate("/testimonials")}
          >
            <Button text="Read More" />
          </motion.div>
        </div>
        <div className="col-md-8 mt-md-0 mt-5 px-md-0 px-3 ">
          <div className="row">
            <Swiper
              slidesPerView={window.innerWidth > 768 ? 2 : 1}
              spaceBetween={20}
              modules={[Navigation, Autoplay]}
              grabCursor={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              className="mySwiper py-4 "
            >
              <SwiperSlide>
                <Card />
              </SwiperSlide>
              <SwiperSlide>
                <Card />
              </SwiperSlide>
              <SwiperSlide>
                <Card />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

const Card = () => {
  return (
    <div className="card_t">
      <div className="quot d-flex align-items-center justify-content-center">
        <FaQuoteLeft className="text-white" />
      </div>
      <div className="inner bg-white rounded-3 p-4">
        <p>
          As a busy parent, I needed an e-learning platform that was flexible
          and affordable, without sacrificing quality. My child was able to
          learn{" "}
          <span class="color-primary text-decoration-underline">
            Vedic Math
          </span>{" "}
          , on their own schedule, and achieve their learning goals with ease.{" "}
          <span class="color-primary text-decoration-underline">
            Chandni ma'am
          </span>{" "}
          was knowledgeable and engaging, and the course material was
          comprehensive and relevant.
        </p>
        <div className="triangle"></div>
      </div>
      <div className="mt-4 d-flex align-items-center gap-5">
        <div className="user_image">
          <img src={testi_img_1} alt="" />
        </div>
        <div className="user_info mt-2">
          <h5 className="mb-1">Bhakti Shah</h5>
          <p>Mumbai</p>
        </div>
      </div>
    </div>
  );
};
