import TeacherPage from "./TeacherPage";
import TeacherProfilePage from "./TeacherProfilePage";

export const TeacherConfig = {
  routes: [
    {
      path: "/parent/teacher",
      exact: true,
      component: TeacherPage,
    },
    {
      path: "/parent/teacher/:id",
      exact: true,
      component: TeacherProfilePage,
    },
  ],
};
