import React from "react";
import { Col, Row, Container, Form, Table } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button } from "@mui/material";
import { RiEdit2Fill } from "react-icons/ri";

const DashboardPage = (props) => {
  const { history } = props;

  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h5>Key Responsibility Area : Department</h5>
      <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
        <Col md={6} sm={6}>
          <h6>Prepared By: Employee Name</h6>
        </Col>
        <Col
          md={6}
          sm={6}
          className="ms-2 align-items-end justify-content-end d-flex"
        >
          <Button
            className="border-black text-black shadow border-0 rounded-5 teacher-button"
            variant="outlined"
            size="small"
            startIcon={<RiEdit2Fill />}
          >
            Edit
          </Button>
        </Col>
      </div>
      <Row className="mb-3" noGutters>
        <Form.Group className="p-0" as={Col} xs={4} controlId="department">
          <Form.Label>Department</Form.Label>
          <Form.Control
            className="border-yellow shadow"
            style={{
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            type="text"
            placeholder="Enter department"
          />
        </Form.Group>

        <Form.Group
          className="p-0"
          as={Col}
          xs={4}
          controlId="departmentSupervisor"
        >
          <Form.Label>Supervisor</Form.Label>
          <Form.Control
            className="rounded-0 border-yellow shadow"
            type="text"
            placeholder="Enter supervisor"
          />
        </Form.Group>

        <Form.Group className="p-0" as={Col} xs={4} controlId="dateIssued">
          <Form.Label>Date Issued</Form.Label>
          <Form.Control
            className="border-yellow shadow"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
            type="date"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3" noGutters>
        <Form.Group className="p-0" as={Col} xs={4} controlId="department">
          <Form.Label>Reviewer Name</Form.Label>
          <Form.Control
            className="border-yellow shadow"
            style={{
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            type="text"
            placeholder="Enter department"
          />
        </Form.Group>

        <Form.Group
          className="p-0"
          as={Col}
          xs={4}
          controlId="departmentSupervisor"
        >
          <Form.Label>Reviewer Title</Form.Label>
          <Form.Control
            className="rounded-0 border-yellow shadow"
            type="text"
            placeholder="Enter supervisor"
          />
        </Form.Group>

        <Form.Group className="p-0" as={Col} xs={4} controlId="dateIssued">
          <Form.Label>Last Revised</Form.Label>
          <Form.Control
            className="border-yellow shadow"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
            type="date"
          />
        </Form.Group>
      </Row>
      <Row
        className="p-2 mt-5"
        style={{
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          backgroundColor: "#f7ff62",
        }}
        noGutters
      >
        <Col className="text-center" xs={6}>
          <h6>Department mission</h6>
        </Col>
        <Col className="text-center" xs={6}>
          <h6>Growth Plan</h6>
        </Col>
      </Row>
      <Row className="border-bottom" noGutters>
        <Col className="text-start p-2 border" xs={6}>
          What is the primary purpose of the department?
        </Col>
        <Col className="text-start p-2 border" xs={6}>
          What is your plan for the next period to focus on improvement and
          growth?
        </Col>
      </Row>
      <Row
        className="pt-5 border-bottom"
        style={{
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
        }}
        noGutters
      >
        <Col className="text-center" xs={6}>
          &nbsp;
        </Col>
        <Col className="text-center" xs={6}>
          &nbsp;
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <h6>KRAs in order of importance</h6>
      </Row>
      <Table
        responsive
        striped
        className="mt-5 custom-styled-table"
        style={{ borderRadius: "25px", backgroundColor: "#f7ff62" }}
      >
        <thead>
          <tr>
            <th className="text-center p-2">KRA#1</th>
            <th className="text-center p-2">KPI</th>
            <th className="text-center p-2">KPI Metrics</th>
            <th className="text-center p-2">Target</th>
            <th className="text-center p-2">Actual Performance</th>
            <th className="text-center p-2">Status</th>
            <th className="text-center p-2">Review</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((item) => (
            <tr key={item} className="text-center bg-light">
              <td></td>
              <td>{`1.${item}`}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr>
            <th className="text-center p-2">KRA#1</th>
            <th className="text-center p-2">KPI</th>
            <th className="text-center p-2">KPI Metrics</th>
            <th className="text-center p-2">Target</th>
            <th className="text-center p-2">Actual Performance</th>
            <th className="text-center p-2">Status</th>
            <th className="text-center p-2">Review</th>
          </tr>
          {[1, 2, 3, 4].map((item) => (
            <tr key={item} className="text-center bg-light">
              <td></td>
              <td>{`2.${item}`}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr>
            <th className="text-center p-2">KRA#1</th>
            <th className="text-center p-2">KPI</th>
            <th className="text-center p-2">KPI Metrics</th>
            <th className="text-center p-2">Target</th>
            <th className="text-center p-2">Actual Performance</th>
            <th className="text-center p-2">Status</th>
            <th className="text-center p-2">Review</th>
          </tr>
          {[1, 2, 3, 4].map((item) => (
            <tr key={item} className="text-center bg-light">
              <td></td>
              <td>{`3.${item}`}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
          <tr>
            <th className="text-center p-2">KRA#1</th>
            <th className="text-center p-2">KPI</th>
            <th className="text-center p-2">KPI Metrics</th>
            <th className="text-center p-2">Target</th>
            <th className="text-center p-2">Actual Performance</th>
            <th className="text-center p-2">Status</th>
            <th className="text-center p-2">Review</th>
          </tr>
          {[1, 2, 3, 4].map((item) => (
            <tr key={item} className="text-center bg-light">
              <td></td>
              <td>{`4.${item}`}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="mt-3 mb-3">
        <h5>Provide Feedback</h5>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} xs={12} controlId="exampleTextarea">
          <Form.Label>Enter Text:</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Type something..."
          />
        </Form.Group>
      </Row>
      <Row className="ps-3">
        <Button
          style={{ width: "fit-content" }}
          className="border-black text-black rounded-5 teacher-button shadow border-0"
          variant="outlined"
          size="small"
        >
          Done
        </Button>
      </Row>
    </OperationLayout>
  );
};

export default DashboardPage;
