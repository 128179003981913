import CourseMangementPage from "./CourseManagement";

export const CourseManagementPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/course-management",
      exact: true,
      component: CourseMangementPage
    }
  ]
};
