import React from "react";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineFilterAltOff } from "react-icons/md";
import { FiEdit3 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { LuBoxSelect } from "react-icons/lu";

import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row, Table } from "react-bootstrap";
import { Button, IconButton } from "@mui/material";
function PasswordManager(props) {
  const { history } = props;
  const users = [
    {
      id: 1,
      user: "John Doe",
      changedBy: "Admin",
      category: "Editor",
      password: "********",
      action: "Edit/Delete",
      lastEdited: new Date("2024-02-02T12:34:56"),
    },
    {
      id: 2,
      user: "xngash",
      changedBy: "Admin",
      category: "Editor",
      password: "********",
      action: "Edit/Delete",
      lastEdited: new Date("2024-02-02T12:34:56"),
    },
    {
      id: 3,
      user: "John smith",
      changedBy: "Admin",
      category: "Editor",
      password: "********",
      action: "Edit/Delete",
      lastEdited: new Date("2024-02-02T12:34:56"),
    },
    {
      id: 4,
      user: "Deo John",
      changedBy: "Admin",
      category: "Editor",
      password: "********",
      action: "Edit/Delete",
      lastEdited: new Date("2024-02-02T12:34:56"),
    },
  ];

  // Function to format date and time
  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };
  return (
    <>
      <h5>Password Manager</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="mt-3 mb-3">
        <Col md={6}>
          <Button
            className="black-outline-button rounded-5"
            variant="outlined"
            endIcon={<IoIosAdd />}
          >
            {" "}
            Add{" "}
          </Button>
          <Button
            className="ms-3 black-outline-button rounded-5"
            variant="outlined"
            startIcon={<MdOutlineFilterAltOff />}
          >
            {" "}
            Filter{" "}
          </Button>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <Button
            className="black-outline-button rounded-5"
            variant="outlined"
            startIcon={<LuBoxSelect />}
          >
            {" "}
            Select{" "}
          </Button>
          <Button
            className="ms-3 black-outline-button rounded-5"
            variant="outlined"
            startIcon={<FiEdit3 />}
          >
            {" "}
            Edit{" "}
          </Button>
          <Button
            className="ms-3 black-outline-button rounded-5"
            variant="outlined"
            startIcon={<MdDelete />}
          >
            {" "}
            Delete{" "}
          </Button>
        </Col>
      </Row>
      <Table
        className="mt-5 mb-3 rounded-4"
        striped
        responsive
        hover
        style={{ backgroundColor: "var(--color-primary)" }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">User</th>
            <th className="text-white">Changed By</th>
            <th className="text-white">Category</th>
            <th className="text-white">Password</th>
            <th className="text-white">Last Edited</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.user}</td>
              <td>{user.changedBy}</td>
              <td>{user.category}</td>
              <td>{user.password}</td>
              <td>{formatDate(user.lastEdited)}</td>
              <td>
                <IconButton aria-label="delete">
                  <FiEdit3 />
                </IconButton>
                <IconButton className="ms-3" aria-label="delete">
                  <MdDelete />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row className="mt-3 mb-3"><h5>Recent Deleted</h5></Row>
      <Table
        className="mt-3 mb-3 rounded-4"
        striped
        responsive
        hover
        style={{ backgroundColor: "var(--color-primary)" }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">User</th>
            <th className="text-white">Changed By</th>
            <th className="text-white">Category</th>
            <th className="text-white">Password</th>
            <th className="text-white">Deleted At</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.user}</td>
              <td>{user.changedBy}</td>
              <td>{user.category}</td>
              <td>{user.password}</td>
              <td>{formatDate(user.lastEdited)}</td>
              <td>
               <p className="text-danger">Deleted</p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default PasswordManager;
