// Tags
import RatePage from "./RatePage";

export const RatePageConfig = {
  routes: [
    {
      path: "/operation/rate",
      exact: true,
      component: RatePage
    }
  ]
};