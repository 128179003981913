import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button, IconButton } from "@mui/material";
import { MdNotifications } from "react-icons/md";
import { MdOutlineStarOutline } from "react-icons/md";

const NotificationPage = (props) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleAddNotification = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <OperationLayout>
      <h5>Notification</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3">
              <option value="option2">All</option>
              <option value="option1">Stared</option>
              <option value="option3">Recent</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      <button
        onClick={handleAddNotification}
        className="mb-3 py-2 px-4 rounded-5 border-0 bg-yellow new-button"
      >
        Add Notification
      </button>

      <Row className="mt-3 mb-3">
        <h6>Previous notification</h6>
      </Row>

      <StyleContainerT>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image bg-dark d-flex align-items-center justify-content-center">
                <MdNotifications size={26} color="white" />
              </div>
              <div className="ms-5">
                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                <p>12 January 2023</p>
              </div>
            </div>
            <div>
              <IconButton aria-label="delete">
                <MdOutlineStarOutline />
              </IconButton>
            </div>
          </div>
        </div>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image bg-dark d-flex align-items-center justify-content-center">
                <MdNotifications size={26} color="white" />
              </div>
              <div className="ms-5">
                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                <p>12 January 2023</p>
              </div>
            </div>
            <div>
              <IconButton aria-label="delete">
                <MdOutlineStarOutline />
              </IconButton>
            </div>
          </div>
        </div>
      </StyleContainerT>

      <Modal show={showModal} centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event title"
                name="title"
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <div className="d-flex gap-3">
                <Form.Check
                  type="checkbox"
                  label="Parent"
                  id="parentCheckbox"
                  name="parent"
                />
                <Form.Check
                  type="checkbox"
                  label="Teacher"
                  id="teacherCheckbox"
                  name="teacher"
                />
                <Form.Check
                  type="checkbox"
                  label="Child"
                  id="childCheckbox"
                  name="child"
                />
              </div>
            </Form.Group>
            <Form.Group className="mt-3" controlId="formEnd">
              <Form.Label>Time</Form.Label>
              <Form.Control type="time" name="end" />
            </Form.Group>
            <Form.Group className="mt-3" controlId="formStart">
              <Form.Label>Start Date and Time</Form.Label>
              <Form.Control type="datetime-local" name="start" />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="me-3 py-2 px-4 rounded-5 color-yellow fw-bold bg-transparent"
            style={{ border: "1px solid var(--yellow-color)" }}
            onClick={handleCloseModal}
          >
            Close
          </button>
          <button
            className="py-2 px-4 rounded-5 border-0"
            style={{ background: "var(--yellow-color)" }}
          >
            Send Notification
          </button>
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

const StyleContainerT = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 768px) {
    .feedback_review {
      height: auto; /* Adjust height for smaller screens */
    }
  }
`;

export default NotificationPage;
