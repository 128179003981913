import React from "react";
import styled from "styled-components";
import { MdCloudUpload } from "react-icons/md";

const Upload = () => {
  return (
    <StyledContainer>
      <div className="upload_box">
        <MdCloudUpload className="text_yellow fs-1 mb-2" />
        <p className="m-0">Drag files or upload</p>
        <p className="small_text m-0 my-2">or</p>

        <input type="file" />

        <p className="m-0 mb-2">
          Max file size: <b>50MB</b>{" "}
        </p>
        <p className="m-0 mb-2">
          Supported file types: <b>PNG, JPG, PDF</b>
        </p>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .upload_box {
    width: 100%;
    height: 100%;
    border: 2px dashed #5d5d5d;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    text-align: center;

    button {
      border: 2px solid var(--color-primary);
      background-color: transparent;
    }
  }
  input {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-primary);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export default Upload;
