import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import { Table, Modal, Button,Row,Col,Form } from "react-bootstrap";
import { FaClipboardCheck } from "react-icons/fa";
import { ImDownload3 } from "react-icons/im";
import { GrScorecard } from "react-icons/gr";

const users = [
  {
    uniqueId: "1",
    name: "John Doe",
    status: "Completed",
    submittedAt: "10/04/2024",
    deadline: "10/04/2024",
  },
  {
    uniqueId: "2",
    name: "Jane Smith",
    status: "Pending",
    submittedAt: "12/04/2024",
    deadline: "12/04/2024",
  },
  {
    uniqueId: "3",
    name: "Michael Johnson",
    status: "Incomplete",
    submittedAt: "15/04/2024",
    deadline: "15/04/2024",
  },
  {
    uniqueId: "4",
    name: "Emily Wilson",
    status: "Completed",
    submittedAt: "20/04/2024",
    deadline: "20/04/2024",
  },
  {
    uniqueId: "5",
    name: "David Brown",
    status: "Pending",
    submittedAt: "25/04/2024",
    deadline: "25/04/2024",
  },
  {
    uniqueId: "6",
    name: "Emma Garcia",
    status: "Incomplete",
    submittedAt: "30/04/2024",
    deadline: "30/04/2024",
  },
];

const TestWithId = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <TeacherLayout>
      <h3>Students</h3>

      <Row className="mt-3">
        <Col md={3}>
        <Form.Select className="shadow rounded-5" aria-label="Select option">
          <option>Select</option>
          <option value="Completed">Completed	</option>
          <option value="Pending">Pending</option>
          <option value="Incomplete">Incomplete</option>
        </Form.Select>
        </Col>
        <Col md={6}></Col>
        <Col md={3}>
          <Form.Group controlId="searchBox">
            <Form.Control className="shadow rounded-5" type="text" placeholder="Search" />
          </Form.Group>
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">Unique ID</th>
            <th className="text-white">Name</th>
            <th className="text-white">Status</th>
            <th className="text-white">Deadline</th>
            <th colSpan={2} className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.uniqueId}>
              <td>{user.uniqueId}</td>
              <td>{user.name}</td>
              <td>{user.status}</td>
              <td>{user.deadline}</td>
              <td>
                <GrScorecard
                  onClick={()=>navigate('/teacher/HwandTest/test/testId/result')}
                  className="cursor-pointer"
                  size={22}
                  color="green"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ParentFooter />

      <Modal show={showModal} onHide={handleCloseModal} style={{marginTop:'20vh'}}>
        <Modal.Header closeButton>
          <Modal.Title>Check Homework</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea placeholder="Enter your feedback..." rows="4" className="form-control mb-3" />
          <input type="file" className="form-control mb-3" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </TeacherLayout>
  );
};

export default TestWithId;
 