import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Table } from "react-bootstrap";

const LearnieAccountPage = () => {
  const [mainTab, setMainTab] = useState(1);
  const [bankSubTab, setBankSubTab] = useState(1);

  return (
    <OperationLayout>
      <h4>Learnie Account</h4>

      <Row className="mt-4">
        <div
          onClick={() => setMainTab(1)}
          className="py-2 px-4 rounded-5 border"
          style={{
            width: "fit-content",
            background: mainTab === 1 ? "var(--color-primary)" : "",
            color: mainTab === 1 ? "white" : "",
            cursor: "pointer",
          }}
        >
          <h6 className="m-0">Bank</h6>
        </div>
        <div
          onClick={() => setMainTab(2)}
          className="py-2 px-4 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: mainTab === 2 ? "var(--color-primary)" : "",
            color: mainTab === 2 ? "white" : "",
            cursor: "pointer",
          }}
        >
          <h6 className="m-0">Wallet</h6>
        </div>
      </Row>

      {mainTab === 1 ? (
        <Row className="mt-3">
          <div
            onClick={() => setBankSubTab(1)}
            className="py-1 px-3 rounded-5 border"
            style={{
              width: "fit-content",
              background: bankSubTab === 1 ? "var(--color-primary)" : "",
              color: bankSubTab === 1 ? "white" : "",
              cursor: "pointer",
            }}
          >
            Parent
          </div>
          <div
            onClick={() => setBankSubTab(2)}
            className="py-1 px-3 ms-3 rounded-5 border"
            style={{
              width: "fit-content",
              background: bankSubTab === 2 ? "var(--color-primary)" : "",
              color: bankSubTab === 2 ? "white" : "",
              cursor: "pointer",
            }}
          >
            Teacher
          </div>
        </Row>
      ) : (
        ""
      )}

      {mainTab === 1 && bankSubTab === 1 ? (
        <div>
          <h6 className="mt-4">Incoming</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Source</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Parent</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <h6 className="mt-4">Outgoing</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Destination</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Class</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ) : mainTab === 1 && bankSubTab === 2 ? (
        <div>
          <h6 className="mt-4">Incoming</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Source</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Parent</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <h6 className="mt-4">Outgoing</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Destination</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Parent</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div>
          <h6 className="mt-4">Incoming</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Source</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Parent</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <h6 className="mt-4">Outgoing</h6>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>SN</th>
                <th>Parent ID</th>
                <th>Parent Name</th>
                <th>Child ID</th>
                <th>Child Name</th>
                <th>Teacher ID</th>
                <th>Teacher Name</th>
                <th>Amount</th>
                <th>Destination</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR345</td>
                    <td>Parent Name</td>
                    <td>CHI454</td>
                    <td>Child Name</td>
                    <td>TEA565</td>
                    <td>Teacher Name</td>
                    <td>$5,000</td>
                    <td>Parent</td>
                    <td>12/09/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </OperationLayout>
  );
};

export default LearnieAccountPage;
