import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { IoPrint } from "react-icons/io5";

const data = [
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    newReturnStatus: "New",
    marketingSource: "Facebook",
    email: "test@test.com",
    refredBy: "John Doe",
    courseName: "Course 1",
    teacherName: "Teacher 1",
    totatlRevenue: "$100",
    source: "Whatsapp",
    websiteStatus: "Demo Done",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    newReturnStatus: "New",
    marketingSource: "Facebook",
    email: "test@test.com",
    refredBy: "John Doe",
    courseName: "Course 1",
    teacherName: "Teacher 1",
    totatlRevenue: "$100",
    source: "Whatsapp",
    websiteStatus: "Demo Done",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    newReturnStatus: "New",
    marketingSource: "Facebook",
    email: "test@test.com",
    refredBy: "John Doe",
    courseName: "Course 1",
    teacherName: "Teacher 1",
    totatlRevenue: "$100",
    source: "Whatsapp",
    websiteStatus: "Demo Done",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    newReturnStatus: "New",
    marketingSource: "Facebook",
    email: "test@test.com",
    refredBy: "John Doe",
    courseName: "Course 1",
    teacherName: "Teacher 1",
    totatlRevenue: "$100",
    source: "Whatsapp",
    websiteStatus: "Demo Done",
  },
];

const data2 = [
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    teacherId: "123",
    teacherName: "John Doe",
    referrelCode: "123",
    demoDate: "12/12/2021",
    demoTime: "12:00",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    teacherId: "123",
    teacherName: "John Doe",
    referrelCode: "123",
    demoDate: "12/12/2021",
    demoTime: "12:00",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    teacherId: "123",
    teacherName: "John Doe",
    referrelCode: "123",
    demoDate: "12/12/2021",
    demoTime: "12:00",
  },
  {
    id: 1,
    parentId: "123",
    parentName: "John Doe",
    childId: "123",
    childName: "John Doe",
    teacherId: "123",
    teacherName: "John Doe",
    referrelCode: "123",
    demoDate: "12/12/2021",
    demoTime: "12:00",
  },
];

const LeadsPage = (props) => {
  const { history } = props;
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <Row className="my-4">
        <Col>
          <h3> Leads </h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Select className="rounded-5 shadow border-yellow">
              <option disabled>Filters</option>
              <option value="option1">Google</option>
              <option value="option2">Socail Media</option>
              <option value="option5">Refferal NoCode</option>
              <option value="option5">Refferal Code</option>
              <option value="option5">Others</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="my-4">
        <Col md={12}>
          <Table
            striped
            responsive
            bordered
            hover
            style={{
              minWidth: 1500,
            }}
          >
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Parent Id</th>
                <th>Parent Name</th>
                <th>Child Id</th>
                <th>Child Name</th>
                <th>New/Return</th>
                <th>Marketing Source</th>
                <th>Email</th>
                <th>Referred By</th>
                <th>Course Name</th>
                <th>Teacher Name</th>
                <th>Total Revenue</th>
                <th>Source</th>
                <th>Website Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentName}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.newReturnStatus}</td>
                  <td>{item.marketingSource}</td>
                  <td>{item.email}</td>
                  <td>{item.refredBy}</td>
                  <td>{item.courseName}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.totatlRevenue}</td>
                  <td>{item.source}</td>
                  <td>
                    <Badge bg="success">{item.websiteStatus}</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <p className="m-0 text_yellow fw-semibold text-decoration-underline text-end">
            View More
          </p>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={8}>
          <div className="mt-4 p-4 shadow rounded-4 bg-white">
            <div className=" d-flex align-items-center justify-content-end">
              <button
                className="btn btn-sm"
                style={{
                  background: "var(--color-primary)",
                  color: "white",
                }}
              >
                Weekly
              </button>
              <button
                className="btn btn-sm ms-3"
                style={{
                  background: "var(--color-primary)",
                  color: "white",
                }}
              >
                Monthly
              </button>
              <button
                className="btn btn-sm ms-3"
                style={{
                  background: "var(--color-primary)",
                  color: "white",
                }}
              >
                Yearly
              </button>
            </div>
            <div>
              <h3>Profit</h3>
              <h6>$87,678</h6>
              <h6 className="mt-2 text-success">+46.58%</h6>
              <p className="m-0 mt-4">Since Last Month</p>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className="mt-4 p-4 shadow rounded-4 bg-white">
            <h3 className="text_yellow">17hr</h3>

            <p className="m-0 mt-2">
              Average Time parent <br /> stayed with Learnie
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Row className="mt-3">
          <Col md={12}>
            <div className="shadow rounded-4 p-4">
              <h3 className="fw-seminbold">Profit</h3>
              <div className="mt-2 d-flex align-items-center justify-content-between px-4">
                <p className="m-0">Group Class</p>
                <p className="m-0">Single Class</p>
              </div>
              <hr />
              <div className=" d-flex align-items-center justify-content-between px-4">
                <div>
                  <h2>$27,446</h2>
                  <p className="m-0 fw-semibold text-success">+16.58%</p>
                  <p className="m-0 mt-1">Since Last Month</p>
                </div>
                <div>
                  <h2>$27,446</h2>
                  <p className="m-0 fw-semibold text-success">+16.58%</p>
                  <p className="m-0 mt-1">Since Last Month</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="my-5">
        <Col md={6}>
          <h3> Leads by refferal </h3>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Select className="rounded-5 shadow border-yellow">
              <option value="option1">Filters</option>
              <option value="option1">Lead Sourced</option>
              <option value="option2">Lead converted</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="my-4">
        <Col md={12}>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <td>S.No.</td>
                <td>Parent ID</td>
                <td>Parent Name</td>
                <td>Child Id</td>
                <td>Child Name</td>
                <td>Techear Id</td>
                <td>Teacher Name</td>
                <td>Referrel Code</td>
                <td>Demo Date</td>
                <td>Demo Time</td>
              </tr>
            </thead>
            <tbody>
              {data2.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentName}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.referrelCode}</td>
                  <td>{item.demoDate}</td>
                  <td>{item.demoTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <p className="m-0 text_yellow fw-semibold text-decoration-underline text-end">
            View More
          </p>
        </Col>
      </Row>

      <Row className="my-5">
        <Col md={12}>
          <div className="d-flex align-items-center justify-content-between shadow rounded-4 p-4">
            <Row>
              <Col md={6}>
                <h3>Bounce Back rate</h3>
                <h6 className="mt-3 text-success">+46.58%</h6>
                <p className="m-0 mt-4">Since last month</p>
              </Col>
              <Col md={6}>
                <p className="m-0">
                  Reason:Lorem ipsum dolor sit amet consectetur, adipisicing
                  elit. Hic impedit deserunt minus incidunt? Magni recusandae
                  similique harum minima iure! Commodi!
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default LeadsPage;
