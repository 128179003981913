import React, { useContext, useEffect, useState } from "react";
import TimePicker from "react-time-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Form, Spinner } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import "react-time-picker/dist/TimePicker.css";
import "./Preferences.css";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
function Preferences() {
  const [userData, setUserData] = useState(null);
  const [isHomework, setIsHomework] = useState("");
  const [isTest, setIsTest] = useState("");
  const [isPTA, setIsPTA] = useState("");
  const [notification, setNotification] = useState("");
  const [time, setTime] = useState("");
  const parentId = localStorage.getItem("parentId");
  const token = useSelector((state) => state.token.value);
  const [loading, setLoading] = useState(false);
  const [havePreferences, setHavePreferences] = useState(false);

  useEffect(() => {
    if (parentId) {
      // find parent by id
      const getParentById = async () => {
        try {
          const response = await axios.get(`/parent/getById?ID=${parentId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.data.res_type === "success") {
            const parent = response.data.data.parent;
            setUserData(parent);

            if (parent.preferences) {
              setIsHomework(parent.preferences.isHw);
              setIsTest(parent.preferences.isTest);
              setIsPTA(parent.preferences.isPta);
              setNotification(parent.preferences.isNotify);
              setTime(parent.preferences.preferedTime);
              setHavePreferences(true);
            }
          }
        } catch (error) {
          console.error("Error fetching parent data:", error);
        }
      };

      getParentById();
    } else {
      console.error("Parent ID not found");
    }
  }, [parentId, token]);

  const handleSetPreferences = async () => {
    const url = havePreferences ? "/parent/preferences" : "/parent/preferences";
    const method = havePreferences ? "put" : "post";

    try {
      setLoading(true);
      const res = await axios({
        method,
        url,
        data: {
          isHw: isHomework,
          isTest: isTest,
          isPta: isPTA,
          isNotify: notification,
          preferedTime: time,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error setting preferences:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(userData);

  return (
    <OftadehLayout>
      <h3>Preferences</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Row className="d-flex align-items-start justify-content-center mt-4">
        <Col md={6}>
          <span className="prederences-item-title">
            Do You want Homework for your child.
          </span>
        </Col>
        <div className="form-group col-md-3 ">
          <select
            className="form-control"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
            onChange={(e) => setIsHomework(e.target.value)}
            value={isHomework}
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        {/* <Col md={3} className='pt-2 ps-3 m-0 '> <Button style={{ borderRadius: '25px', borderColor: '#FFD63B', color: '#FFD63B',fontWeight:'bold' }} variant="outlined" startIcon={<AddIcon />}>
                    add exceptions
                </Button> </Col> */}
        <Col md={3} className="pt-2 ps-3 m-0 ">
          {" "}
          &nbsp;{" "}
        </Col>
      </Row>
      <Row className="d-flex align-items-start justify-content-center mt-3">
        <Col md={6}>
          <span className="prederences-item-title">
            Do You want teacher can take test.
          </span>
        </Col>
        <div className="form-group col-md-3 ">
          <select
            className="form-control"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
            value={isTest}
            onChange={(e) => setIsTest(e.target.value)}
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        {/* <Col md={3} className='ps-3 pt-2 m-0'> <Button style={{ borderRadius: '25px', borderColor: '#FFD63B', color: '#FFD63B',fontWeight:'bold' }} variant="outlined" startIcon={<AddIcon />}>
                    add exceptions
                </Button> </Col> */}
        <Col md={3} className="pt-2 ps-3 m-0 ">
          {" "}
          &nbsp;{" "}
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-3">
        <Col md={6}>
          <span className="prederences-item-title">
            Whether the parent wants a teacher meeting for PTA.
          </span>
        </Col>
        <div className="form-group col-md-4 ">
          <select
            className="form-control"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
            value={isPTA}
            onChange={(e) => setIsPTA(e.target.value)}
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <Col md={2}>&nbsp;</Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-3">
        <Col md={6}>
          <span className="prederences-item-title">
            Preferred Notifications of classes by.
          </span>
        </Col>
        <div className="form-group col-md-3 ">
          <select
            className="form-control"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
            value={notification}
            onChange={(e) => setNotification(e.target.value)}
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="true">Email</option>
            <option value="false">SMS</option>
            <option value="true">SMS / Email</option>
          </select>
        </div>
        <Col md={3}>&nbsp;</Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-3">
        <Col md={6}>
          <span className="prederences-item-title">
            Preferred time to contact.
          </span>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Control
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={3}>&nbsp;</Col>
      </Row>

      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          {loading ? (
            <button
              className="btn rounded-5 px-3 py-2 text-white fw-semibold"
              style={{
                backgroundColor: "var(--color-primary)",
              }}
            >
              <Spinner animation="border" variant="light" />
            </button>
          ) : (
            <button
              onClick={handleSetPreferences}
              className="btn rounded-5 px-3 py-2 text-white fw-semibold"
              style={{
                backgroundColor: "var(--color-primary)",
              }}
            >
              {havePreferences ? "Update Preferences" : "Set Preferences"}
            </button>
          )}
        </Col>
      </Row>

      <ParentFooter />
    </OftadehLayout>
  );
}

export default Preferences;
