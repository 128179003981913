import React from 'react'
import AccountLayout from '../../components/accountComponents/AccountLayout/AccountLayout'
import AccountLocation from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import '../../assets/css/parent/parent.css'
import { Col, Form, Row } from 'react-bootstrap'

const CurrencyConversionPage = props => {
  const { history } = props
  return (
    <AccountLayout>
      <h3>Currency Conversion</h3>
      {/* <AccountLocation path={history} /> */}
      <Row className='mt-3 mb-3'>
        <h6>Convertor</h6>
      </Row>
      <Row className='mt-3 mb-3'>
        <Col md={4} className='mt-3 mb-3'>
          <Form.Group controlId='selectOption'>
            <Form.Select className='border bg-transparent shadow'>
              <option value='option1'>INR</option>
              <option value='option2'>USD</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='nameInput'>
            <Form.Control className='mt-3 shadow rounded-3 border' type='text' placeholder='Ammount' />
          </Form.Group>
        </Col>
        <Col md={4} className='mt-3 mb-3'>
          <Form.Group controlId='selectOption'>
            <Form.Select className='border bg-transparent shadow'>
              <option value='option2'>USD</option>
              <option value='option1'>INR</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='nameInput'>
            <Form.Control className='mt-3 shadow rounded-3 border' type='text' placeholder='Ammount' />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <h6>Popular conversions</h6>
      </Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
      <Row className='mt-2'><span>$1 = Rs. 72.84</span></Row>
    </AccountLayout>
  )
}

export default CurrencyConversionPage
