import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Row, Table } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";

const AccountDetailsPage = () => {
  return (
    <AccountLayout>
      <h3>Client Account Details</h3>

      <Row className="mt-5 pe-5">
        <Col md={12}>
          <p className="m-0">Client Account Details</p>
        </Col>
        <Col md={12} className="mt-4 d-flex align-items-center gap-3">
          <FaRegUserCircle className="fs-3 text_yellow" />
          <div>
            <p className="m-0">User Name</p>
            <span>User ID</span>
          </div>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">First Name</p>
          <p className="m-0">Username 1</p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Last Name</p>
          <p className="m-0">Username 1</p>
        </Col>

        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">UserID</p>
          <p className="m-0">244678756</p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Department</p>
          <p className="m-0">asd</p>
        </Col>

        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">E-mail</p>
          <p className="m-0">abcd@gmail.com</p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Phone No.</p>
          <p className="m-0">9876543210</p>
        </Col>

        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Country</p>
          <p className="m-0">India</p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Status</p>
          <p className="m-0">Active</p>
        </Col>

        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Account No.</p>
          <p className="m-0"></p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">IFSC</p>
          <p className="m-0"></p>
        </Col>

        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Address</p>
          <p className="m-0"></p>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-between pe-5"
        >
          <p className="m-0 fw-semibold">Aadhar No.</p>
          <p className="m-0"></p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Date</th>
                <th className="text-white">Name/ID</th>
                <th className="text-white">Account</th>
                <th className="text-white">Category</th>
                <th className="text-white">Amount</th>
                <th className="text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>12/05/2021</td>
                <td>Username 1</td>
                <td>244678756</td>
                <td>asd</td>
                <td>1000</td>
                <td>Success</td>
              </tr>
              <tr>
                <td>2</td>
                <td>12/05/2021</td>
                <td>Username 1</td>
                <td>244678756</td>
                <td>asd</td>
                <td>1000</td>
                <td>Success</td>
              </tr>
              <tr>
                <td>3</td>
                <td>12/05/2021</td>
                <td>Username 1</td>
                <td>244678756</td>
                <td>asd</td>
                <td>1000</td>
                <td>Success</td>
              </tr>
              <tr>
                <td>4</td>
                <td>12/05/2021</td>
                <td>Username 1</td>
                <td>244678756</td>
                <td>asd</td>
                <td>1000</td>
                <td>Success</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p
            className="m-0 fw-semibold text-decoration-underline text_yellow"
            style={{ cursor: "pointer" }}
          >
            View More
          </p>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default AccountDetailsPage;
