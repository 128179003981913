import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Modal, Row } from "react-bootstrap";
import {
  CalendarToday,
  MyLocation,
  SupervisorAccount,
} from "@material-ui/icons";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SchoolIcon from "@mui/icons-material/School";
import { useNavigate } from "react-router-dom";
import { GiPostOffice } from "react-icons/gi";
import { IoTimeSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { MyAPI } from "../../utils/MyAPI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const TeacherVerifyDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [subjectDetails, setSubjectDetails] = useState({});
  const [verifyLoading, setVerifyLoading] = useState(false);
  const handleShowSubjectModal = (grade) => {
    setShowSubjectModal(true);
    setSubjectDetails(grade);
  };
  const handleCloseSubjectModal = () => setShowSubjectModal(false);

  const handleVerifyTeacher = async (token) => {
    try {
      setVerifyLoading(true);
      const res = await MyAPI.PUT(
        `/admin/teacher/verify/${state?.teacher?._id}`,
        {},
        token
      );

      const { res_type, message } = res.data;
      if (res_type === "success") {
        toast.success(message);
        state.teacher.status = "active";
        navigate("/admin/teacher-verification");
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error("Verification failed. Please try again.");
      console.log(error);
    } finally {
      setVerifyLoading(false);
    }
  };

  return (
    <Container className="mt-3 p-3 n-box-shadow rounded-2 py-4">
      <Row>
        <Col md={6} className="d-flex flex-column ">
          <div className="d-flex gap-4 align-items-center flex-wrap">
            <div className="shadow rounded-pill">
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src={state?.teacher?.image_key}
                alt=""
              />
            </div>
            <div className="d-flex flex-column">
              <div className="like d-flex align-items-center flex-wrap">
                <h5>
                  {state?.teacher?.firstName} {state?.teacher?.lastName}
                </h5>
              </div>
              <button
                disabled={state?.teacher?.status === "active" || verifyLoading}
                onClick={() => handleVerifyTeacher(token)}
                className="text-white btn mt-3"
                style={{ background: "var(--color-primary)" }}
              >
                {state?.teacher?.status === "active"
                  ? "Verified Teacher"
                  : verifyLoading
                  ? "Verifying..."
                  : "Verify Teacher"}
              </button>
            </div>
          </div>
          <Row className="mt-5">
            <Col md={4}>
              <b>Top Qualification: </b>
            </Col>
            <Col md={8} className="d-flex align-items-center gap-2">
              {state?.teacher?.teacher_detail_id?.qualifications.map(
                (qualification, index) => (
                  <p key={index} className="text-decoration-underline ">
                    {qualification.degree_name},{" "}
                  </p>
                )
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={4}>
              <b>Schools They Teach: </b>
            </Col>
            <Col md={8} className="d-flex align-items-center gap-2">
              {state?.teacher?.teacher_detail_id?.pre_schools.map(
                (schl, index) => (
                  <p key={index} className="text-decoration-underline ">
                    {schl.name},
                  </p>
                )
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <b>Language : </b>
            </Col>
            <Col md={8} className="d-flex align-items-center gap-2">
              {state?.teacher?.teacher_detail_id?.languages.map(
                (language, index) => (
                  <p key={index} className="text-decoration-underline ">
                    {language},
                  </p>
                )
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <b>Subjects : </b>
            </Col>
            <Col md={8} className="d-flex gap-2 ">
              {state?.teacher?.teacher_detail_id?.grades.map((grade, index) => (
                <p
                  className="cursor-pointer text-decoration-underline"
                  onClick={() => handleShowSubjectModal(grade)}
                >
                  {grade.subject},
                </p>
              ))}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={4}>
              <b>Competitive Exams: </b>
            </Col>
            <Col md={8}>JEE, NEET</Col>
          </Row>
        </Col>
        <Col md={6} className="p-0 m-0">
          <div className="intro_video position-relative pe-3 rounded-3">
            <video
              autoPlay
              loop
              className="w-100 h-100 rounded-3"
              muted
              src={state?.teacher?.introduction_video_key}
            ></video>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <h5>About Me:</h5>
        <p>{state?.teacher?.about}</p>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <div className="teacher-profile-items-item mt-2">
            <GiPostOffice size={22} /> <span>Teacher Designation</span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <LocalLibraryIcon /> <span>100 Classes Completed</span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <MyLocation /> <span>Lives in {state?.teacher?.address}</span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <IoTimeSharp size={22} /> <span>120+ Hours of Classes</span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <CalendarToday />{" "}
            <span>
              Joined on {""}
              {new Date(state?.teacher?.createdAt).toLocaleDateString()}
            </span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <SupervisorAccount /> <span>10+ Years of Experience</span>
          </div>
          <div className="teacher-profile-items-item mt-2">
            <SchoolIcon /> <span>1000+ Students Taught</span>
          </div>

          <h4 className="mt-4">Certificates</h4>
          <div className="slide_video">
            <Swiper
              slidesPerView={window.innerWidth > 768 ? 2 : 1}
              spaceBetween={20}
              modules={[Navigation, Autoplay, Pagination]}
              grabCursor={true}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              className="mySwiper pt-4 pb-5"
            >
              {state?.teacher?.teacher_detail_id?.certificates.map(
                (cr, index) => (
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        height={200}
                        style={{ width: "100%", objectFit: "cover" }}
                        src={cr.certificate_key}
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </Col>
        <Col md={6}>
          <h5>Qualification</h5>
          <div>
            {state?.teacher?.teacher_detail_id?.qualifications.map(
              (qualification, index) => (
                <Row>
                  <b>
                    {index + 1}. {qualification.degree_name} from{" "}
                    {qualification.institute_name}
                  </b>
                </Row>
              )
            )}
          </div>
        </Col>
      </Row>

      <Row className="mt-2 mt-sm-3">
        <h5>Special Acheivement:</h5>
      </Row>
      <Col className="p-2">
        <Row>
          {state?.teacher?.teacher_detail_id?.achievements.map(
            (achievement, index) => (
              <Col md={3} className="mt-3 position-relative" key={index}>
                <p>{achievement.title}</p>
                <img
                  style={{ height: 200, width: "100%", objectFit: "cover" }}
                  src={achievement?.certificate_key}
                  alt=""
                />
              </Col>
            )
          )}
        </Row>
      </Col>

      <Modal
        show={showSubjectModal}
        onHide={handleCloseSubjectModal}
        style={{ marginTop: "15vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{subjectDetails?.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Board :</b> {subjectDetails?.board}
          </p>
          <p>
            <b>Grade :</b> {subjectDetails?.grade}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseSubjectModal} className="new-button">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TeacherVerifyDetails;
