import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MyAPI } from "../../utils/MyAPI";
import { toast } from "react-toastify";

const CourseSettingsModal = ({ handleShowModal, course, handleClose, fetchCourses }) => {
    const [status, setStatus] = useState('')
    const token = useSelector(state => state.token.value)
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {
        if (course && course.status) {
            setStatus(course.status)
        }
    }, [course])

    const handleChangeStatus = async () => {
        try {
            let payload = {
                status: status
            }
            setBtnLoading(true)
            let res = await MyAPI.PUT(`/common/course-manage?id=${course._id}`, payload, token)
            let { res_type, message, data: apiData } = res.data || res;
            if (res_type === 'success') {
                toast.success('Status Updated Successfully...')
                handleClose()
                fetchCourses({}, token)
            } else {
                toast.error(message || 'Error Changing Status...!')
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <>
            <Modal show={handleShowModal} onHide={handleClose} size="md" style={{ zIndex: '1111', marginTop: '20vh' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Course Settings </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-3">Here you can change various settings related to the course.</p>
                    <Form>
                        {/* <Col className="mt-2 "><FormControlLabel control={<Checkbox />} label="Stop Appearing in Search" /></Col> */}
                        {/* <Col className="mt-2 "><FormControlLabel control={<Checkbox />} label="Stop Enrollment in Course" /></Col> */}
                        <Form.Group controlId="formBasicSelect">
                            <Form.Label>Change Status</Form.Label>
                            <Form.Select value={status} onChange={e => setStatus(e.target.value)} >
                                <option disabled selected>Course Status</option>
                                <option value="active">Active</option>
                                <option value="pending">Pending</option>
                                <option value="on-hold">On Hold</option>
                                <option value="blocked">Blocked</option>
                                <option value="ended">Ended</option>
                                <option value="paused">Stopped</option>
                                <option value="completed">Completed</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Delete
                    </Button>
                    <Button disabled={btnLoading} variant="primary" onClick={handleChangeStatus}>
                        {
                            btnLoading ? 'Saving...' : 'Save'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CourseSettingsModal;
