import ReviewPagePage from "./ReviewPage";

export const ReviewPageConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/review",
      exact: true,
      component: ReviewPagePage,
    },
  ],
};
