// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";
import AppsIcon from "@material-ui/icons/Apps";
import EventIcon from "@material-ui/icons/Event";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Book from "@material-ui/icons/Book";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CalculateIcon from "@mui/icons-material/Calculate";
import HomeIcon from "@mui/icons-material/Home";
import StudentIcon from "@material-ui/icons/EmojiPeople";
import VacancyIcon from "@mui/icons-material/WorkOutline";
import { PaymentSharp, PeopleAlt } from "@material-ui/icons";
import ReportIcon from "@mui/icons-material/Report";
import ClassIcon from "@mui/icons-material/Class";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { FcLeave } from "react-icons/fc";
import { SiGoogleclassroom, SiGooglemeet } from "react-icons/si";
import {
  FaBlogger,
  FaNetworkWired,
  FaQuestion,
  FaRegLightbulb,
} from "react-icons/fa";
import { FaCircleDollarToSlot, FaUsersViewfinder } from "react-icons/fa6";
import { MdReviews } from "react-icons/md";
import { RiLiveFill } from "react-icons/ri";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";

const TeacherNavigationConfig = [
  {
    id: "Main",
    title: "",
    type: "group",
    children: [
      // {
      //   id: "homePage",
      //   title: "Home",
      //   type: "item",
      //   icon: <HomeIcon />,
      //   url: "/",
      //   exact: true,
      // },
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <AppsIcon />,
        url: "/teacher/dashboard",
        exact: true,
      },
      // {
      //   id: "Classes",
      //   title: "",
      //   type: "group",
      //   children: [
      //     {
      //       id: "Registration-p",
      //       title: "Courses & Classes",
      //       type: "collapse",
      //       icon: <AccountCircleIcon />,
      //       children: [
      //         {
      //           id: "courses",
      //           title: "Courses",
      //           type: "item",
      //           icon: <Book />,
      //           url: "/teacher/courses",
      //           exact: true,
      //         },
      //         // {
      //         //   id: "class",
      //         //   title: "Class",
      //         //   type: "item",
      //         //   icon: <SiGoogleclassroom />,
      //         //   url: "/teacher/dashboard/pages/class",
      //         //   exact: true,
      //         // },
      //         // {
      //         //   id: "courseManagement",
      //         //   title: "Course Management",
      //         //   type: "item",
      //         //   icon: <Book />,
      //         //   url: "/teacher/dashboard/pages/course-management",
      //         //   exact: true,
      //         // },
      //       ],
      //     },
      //   ],
      // },
      {
        id: "Courses Management",
        title: "Class Management",
        type: "item",
        icon: <AccountCircleIcon />,
        url: "/teacher/courses",
        exact: true,
      },
      {
        id: "PT Meeting",
        title: "PT Meeting",
        type: "item",
        icon: <SiGooglemeet />,
        url: "/teacher/pta/meeting",
        exact: true,
      },
      // {
      //   id: "Courses & Classes",
      //   title: "Courses Management",
      //   type: "item",
      //   icon: <Book />,
      //   url: "/teacher/courses",
      //   id: "Classes",
      //   title: "",
      //   type: "group",
      //   children: [
      //     {
      //       id: "Registration-p",
      //       title: "Courses & Classes",
      //       type: "collapse",
      //       icon: <AccountCircleIcon />,
      //       children: [
      //         {
      //           id: "courses",
      //           title: "Courses",
      //           type: "item",
      //           icon: <Book />,
      //           url: "/teacher/courses",
      //           exact: true,
      //         },
      //         {
      //           id: "class",
      //           title: "Classes",
      //           type: "item",
      //           icon: <SiGoogleclassroom />,
      //           url: "/teacher/classes",
      //           exact: true,
      //         },
      //         // {
      //         //   id: "courseManagement",
      //         //   title: "Course Management",
      //         //   type: "item",
      //         //   icon: <Book />,
      //         //   url: "/teacher/dashboard/pages/course-management",
      //         //   exact: true,
      //         // },
      //       ],
      //     },
      //   ],
      // },
      {
        id: "live",
        title: "Live",
        type: "item",
        icon: <RiLiveFill />,
        url: "/teacher/dashboard/pages/live",
        exact: true,
      },
      {
        id: "calender",
        title: "Calender",
        type: "item",
        icon: <EventIcon />,
        url: "/teacher/dashboard/pages/calendar",
        exact: true,
      },

      {
        id: "vacancy",
        title: "Vacancy",
        type: "item",
        icon: <VacancyIcon />,
        url: "/teacher/dashboard/pages/vacancy",
        exact: true,
      },
      {
        id: "techerReports",
        title: "Teacher Reports",
        type: "item",
        icon: <ReportIcon />,
        url: "/teacher/dashboard/pages/reports",
        exact: true,
      },
      {
        id: "Reschedule",
        title: "Reschedule",
        type: "item",
        icon: <EditCalendarIcon />,
        url: "/teacher/reschedule",
        exact: true,
      },
      {
        id: "complain-p",
        title: "complain",
        type: "item",
        icon: <AddCommentIcon />,
        url: "/teacher/complain",
        exact: true,
      },
      {
        id: "Referral",
        title: "Referral",
        type: "item",
        icon: <FaCircleDollarToSlot />,
        url: "/teacher/referral",
        exact: true,
      },
      {
        id: "StudentsManagement",
        title: "Students Management",
        type: "item",
        icon: <PeopleAlt />,
        url: "/teacher/StudentsManagement",
        exact: true,
      },
      {
        id: "TeacherProfile",
        title: "Teacher Profile",
        type: "item",
        icon: <AccountCircleIcon />,
        url: "/teacher/TeacherProfile",
        exact: true,
      },
      {
        id: "blog",
        title: "Blog",
        type: "item",
        icon: <FaBlogger />,
        url: "/teacher/dashboard/pages/blog",
        exact: true,
      },
      {
        id: "learnieMall",
        title: "Learnie Mall",
        type: "item",
        icon: <StudentIcon />,
        url: "/teacher/learnieMall",
        exact: true,
      },
      {
        id: "demo",
        title: "Demo",
        type: "item",
        icon: <FaUsersViewfinder />,
        url: "/teacher/dashboard/pages/demo",
      },
      {
        id: "CertificateManagement",
        title: "Certificate Management",
        type: "item",
        icon: <CardMembershipIcon />,
        url: "/teacher/CertificateManagement",
        exact: true,
      },
      {
        id: "HwandTest",
        title: "Hw & Test",
        type: "item",
        icon: <FaNetworkWired />,
        url: "/teacher/HwandTest",
        exact: true,
      },
      {
        id: "TeacherFAQ",
        title: "FAQ",
        type: "item",
        icon: <FaQuestion />,
        url: "/teacher/TeacherFAQ",
        exact: true,
      },
      {
        id: "ReviewPage",
        title: "Review",
        type: "item",
        icon: <MdReviews />,
        url: "/teacher/review",
        exact: true,
      },
      {
        id: "Suggestion",
        title: "Suggestion",
        type: "item",
        icon: <FaRegLightbulb />,
        url: "/teacher/suggestion",
        exact: true,
      },
      {
        id: "TeacherLeave",
        title: "Teacher Leave",
        type: "item",
        icon: <FcLeave />,
        url: "/teacher/TeacherLeave",
        exact: true,
      },
      // {
      //   id: "registration",
      //   title: "Registration",
      //   type: "item",
      //   icon: <AccountCircleIcon />,
      //   url: "/teacher/registration",
      //   exact: true,
      // },
      {
        id: "wallet",
        title: "Wallet",
        type: "item",
        icon: <EventIcon />,
        url: "/teacher/TeacherWallet",
        exact: true,
      },
      // {
      //   id: "Payment",
      //   title: "Payment",
      //   type: "item",
      //   icon: <PaymentSharp />,
      //   url: "/teacher/dashboard/pages/payment",
      //   exact: true,
      // },
      {
        id: "Chat",
        title: "Chat",
        type: "item",
        icon: <IoChatboxEllipsesOutline />,
        url: "/teacher/chat",
        exact: true,
      },
      // {
      //   id: "Under Review",
      //   title: "Under Review",
      //   type: "item",
      //   icon: <IoChatboxEllipsesOutline />,
      //   url: "/teacher/dashboard/pages/under-review",
      //   exact: true,
      // },
      {
        id: "Setting",
        title: "Settings",
        type: "item",
        icon: <IoIosSettings />,
        url: "/teacher/setting",
        exact: true,
      },
    ],
  },
];

export default TeacherNavigationConfig;
