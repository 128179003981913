import React from 'react'
import { Col, Row, Container, Form, InputGroup, Table } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import './RolesAndPermissionsPage.css';
import ClearIcon from '@mui/icons-material/Clear';
import { MdNavigateNext } from "react-icons/md";
import BlockIcon from '@mui/icons-material/Block';
import { IoMdAdd } from "react-icons/io";
import { BsSearch } from 'react-icons/bs';
import DoneIcon from '@mui/icons-material/Done';
import { Button, IconButton } from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
const teacherImg = require('../../assets/parent/profile-user.jpg');
function RolesAndPermissionsPage(props) {
    const { history } = props;
    return (
        <OperationLayout>
            <h4>Roles & Permissions</h4>
            {/* <OperationLocation path={history} /> */}
            <Col className='d-flex align-items-center justify-content-between'>
                <Col md={4}>
                    <Form.Group controlId="searchInput">
                        <InputGroup>
                            <Form.Control type="text" placeholder="Search" />
                            <InputGroup.Text>
                                <BsSearch />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col md={4}>&nbsp;</Col>
                <Col md={4} className='d-flex align-items-center justify-content-end'><Button style={{ borderColor: 'black', color: 'black' }} startIcon={<IoMdAdd />} variant="outlined">Add</Button></Col>
            </Col>
            <Container className='mt-3'>
                <h5>Teachers</h5>
                <div className="parent-operation-container">
                    <div className="table-container-operations yellow-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                    <div className="table-container-operations mt-3 grey-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                </div>
                <center><Button className='mt-3 rounded-3 p-2' style={{borderColor:'black',color:'black'}} variant="outlined">View More</Button></center>
            </Container>
            <Container className='mt-3'>
                <h5>Employees</h5>
                <div className="parent-operation-container">
                    <div className="table-container-operations yellow-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                    <div className="table-container-operations mt-3 grey-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                </div>
                <center><Button className='mt-3 rounded-3 p-2' style={{borderColor:'black',color:'black'}} variant="outlined">View More</Button></center>
            </Container>
            <Container className='mt-3'>
                <h5>Parents</h5>
                <div className="parent-operation-container">
                    <div className="table-container-operations yellow-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                    <div className="table-container-operations mt-3 grey-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                </div>
                <center><Button className='mt-3 rounded-3 p-2' style={{borderColor:'black',color:'black'}} variant="outlined">View More</Button></center>
            </Container>
            <Container className='mt-3'>
                <h5>Students</h5>
                <div className="parent-operation-container">
                    <div className="table-container-operations yellow-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                    <div className="table-container-operations mt-3 grey-bottom">
                        <div className="table-container-operations-item-td">
                            <img className='operation-role-and-permission-img shadow' src={teacherImg} alt="" />
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Cody Fisher</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <span className="content-operation">Physical Education</span>
                        </div>
                        <div className="table-container-operations-item-td">
                            <IconButton style={{ backgroundColor: '#FCD3D2', color: 'red' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <BlockIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#D6FFDA', color: 'green' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <ClearIcon />
                            </IconButton>
                            <IconButton style={{ backgroundColor: '#FDF2D2', color: '#FFD32D' }} className="rounded-1 p-1 ms-2" aria-label="delete">
                                <RecordVoiceOverIcon />
                            </IconButton>
                        </div>
                        <div className="table-container-operations-item-td">
                            <div className="operation-view-button">
                                <div className="text"><b>View <MdNavigateNext /></b></div>

                            </div>
                        </div>
                    </div>
                </div>
                <center><Button className='mt-3 rounded-3 p-2' style={{borderColor:'black',color:'black'}} variant="outlined">View More</Button></center>
            </Container>
        </OperationLayout>
    )
}

export default RolesAndPermissionsPage