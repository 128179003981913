import { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { IoMdClose, IoMdTime } from "react-icons/io";
import { IoHeart, IoSchoolOutline, IoStar } from "react-icons/io5";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { RiShareForwardFill } from "react-icons/ri";
import { TiBook, TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { baseAwsUrl, MyAPI, truncateText } from "../../utils/MyAPI";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import './Card-Thumnil.css'

export const Card = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
  onCourseClick,
  demoBtn,
  corcesEditModelClick,
  isActions,
  isAcceptReject,
  likes,
  courseData,
  fetchCourses
}) => {
  const navigate = useNavigate();
  const [showModalHold, setShowModalHold] = useState(false);

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };

  const token = useSelector(state => state.token.value)
  //'status (active,blocked)'
  const handleChangeStatus = async (status) => {
    try {
      let payload = {
        status: status
      }
      let res = await MyAPI.PUT(`/common/course-manage?id=${courseData._id}`, payload, token)
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === 'success') {
        if (status === 'active') {
          toast.success('Course Accepted.')
        } else {
          toast.success('Course Rejected.')
        }
        fetchCourses({}, token)
      } else {
        toast.error(message || 'Error Changing Status...!')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const copyCourseUrl = (courseId) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object to extract the domain
    const url = new URL(currentUrl);
    const domain = `${url.protocol}//${url.host}`; // Get the protocol and host

    // Define the specific path to append using the provided course ID
    // const path = `/operation/courses/${courseId}`;
    const path = `/course/${courseId}`;

    // Create the full URL by combining the domain with the path
    const fullUrl = `${domain}${path}`;

    // Create a temporary textarea element to copy the URL
    const textarea = document.createElement("textarea");
    textarea.value = fullUrl; // Set the textarea value to the full URL
    document.body.appendChild(textarea); // Append the textarea to the body
    textarea.select(); // Select the content of the textarea

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);

    // Optional: Show a message indicating the URL has been copied
    toast.success(`Link Copied to clipboard.`);
  };

  return (
    <>
      <div
        key={courseData?._id}
        className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative"
      >
        <img
          onClick={() => navigate(`/operation/courses/${courseData._id}`)}
          className="cource_img rounded-3 px-3 cursor-pointer"
          style={{ width: "90%", maxHeight: '140px' }}
          src={baseAwsUrl + courseData?.thumbnail_key}
          alt=""
        />

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3"> {truncateText(courseData?.course_tags[0], 1)}</button>
          <h6 className="m-0 fw-semibold">Rs. {courseData?.price}</h6>
        </div>
        <h6
          className="mt-3 px-3"
          style={{ fontSize: '12px' }}
          onClick={() => navigate(`/operation/courses/${courseData?._id}`)}
        >
          Course Id : {courseData?._id?.slice(0, 15)}...
        </h6>
        <h6
          className=" px-3"
          style={{ fontSize: '12px' }}
          onClick={() => navigate(`/operation/courses/${courseData?.teacher_id}`)}
        >
          Teacher Id : {courseData?.teacher_id?.slice(0, 15)}...
        </h6>

        <h5 className="mt-3 px-3">{truncateText(courseData?.title, 5)}</h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
            <p className="m-0 text-secondary">Robert Fox</p>
          </div>

          <div className="d-flex align-items-center flex-column gap-2">
            <span>
              <IoStar className="star" /> ({courseData?.reviews_count} Reviews)
            </span>
            {likes && (
              <div className="d-flex align-items-center gap-2">
                <IoHeart className="star" /> (20 likes)
              </div>
            )}
          </div>
        </div>

        {badge && (
          <div className="d-flex align-items-center justify-content-between mt-3 px-3 pb-3">
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Online
            </span>
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Paid
            </span>

            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Fixed
            </span>
          </div>
        )}

        <div className="mt-2">
          <Container fluid>
            <Row className="align-items-center">
              <Col className="overflow-auto overflow-auto-thum" style={{ whiteSpace: 'nowrap', maxWidth: '100%' }}>
                {courseData?.course_tags?.map((t, i) => (
                  <div
                    key={i}
                    className="d-inline-block px-3 me-2 py-1 border rounded-5 cursor-pointer text-truncate"
                    style={{ width: 'fit-content' }}
                  >
                    {t}
                  </div>
                ))}
                <div
                  className="d-inline-block px-3 ms-2 py-1 border rounded-5 cursor-pointer text-truncate"
                  style={{ width: 'fit-content' }}
                >
                  {courseData?.grade}
                </div>
                <div
                  className="d-inline-block px-3 py-1 ms-2 border rounded-5 cursor-pointer text-truncate"
                  style={{ width: 'fit-content' }}
                >
                  {courseData?.board}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {completed && (
          <div className="w-100 mt-2 d-flex justify-content-end ">
            <span
              className="badge text-black me-3"
              style={{
                background: "var(--yellow-color)",
                height: "fit-content",
              }}
            >
              36% Completed
            </span>
          </div>
        )}

        {isActions && (
          <div className="py-2 px-3 mt-3 border border-0 border-top d-flex align-items-center justify-content-end">
            {/* <button
              className="btn btn-sm text-white"
              style={{
                backgroundColor: "var(--color-primary)",
              }}
            >
              Edit Course
            </button> */}
            <div
              onClick={() => corcesEditModelClick(courseData)}
              className="parent_accept  ms-4 d-flex align-items-center justify-content-center"
            >
              <MdEdit className="fs-4" />
            </div>
            <div
              onClick={() => copyCourseUrl(courseData._id)}
              style={{ backgroundColor: "#7D88F4" }}
              className="parent_decline  ms-4 d-flex align-items-center justify-content-center"
            >
              <RiShareForwardFill color="#fff" className="fs-4" />
            </div>
          </div>
        )}

        {isAcceptReject && (
          <div className="py-2 px-3 mt-3 border border-0 border-top d-flex align-items-center justify-content-end">
            <div onClick={() => handleChangeStatus('active')} className="parent_accept  ms-4 d-flex align-items-center justify-content-center">
              <TiTick className="fs-4" />
            </div>
            <div onClick={() => handleChangeStatus('blocked')} className="parent_decline  ms-4 d-flex align-items-center justify-content-center">
              <IoMdClose className="fs-4" />
            </div>
          </div>
        )}

        <div
          className={`${holdbtn ? "bottom" : ""} ${paybtn ? "bottom" : ""} ${startCBtn ? "bottom" : ""
            } py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {demoBtn && <button className="py-1 px-3">Book Demo</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
        </div>

        {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )}

        {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> {courseData?.duration_time}
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" />{" "}
            {courseData?.students_count}
          </p>
        </div>
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
