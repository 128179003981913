import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Navbar from "../../components/Navbar.js";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import "../../assets/css/parent/SignIn.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/axios.js";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleDashboard = async () => {
    if (email === "") {
      toast.error("Please enter email.");
      return;
    }
    try {
      const res = await axios.post("/parent/reset-password", {
        type: "email",
        value: email,
      });

      if (res.data.res_type === "success") {
        toast.success("Please check your mail for reset link !!");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div
        className="main admin-main parent-main-container"
        style={{ overflowY: "hidden" }}
      >
        <Navbar />
        <br />
        <br />
        <br />
        <Container className="mt-5 d-flex align-items-center justify-content-center p-2">
          <Row>
            <Col></Col>
            <Col
              md={5}
              className="login-container-semi-p mt-3 p-5 pt-4 pb-2 border-0"
              style={{ overflowY: "hidden" }}
            >
              <Row>
                <Col md={12}>
                  <center>
                    <h2>Reset Password</h2>
                  </center>
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="shadow rounded-2 border-2"
                      type="email"
                      placeholder="Enter Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Form.Group>
                </Col>

                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <span className="text-start">
                    Already have a account?{" "}
                    <Link className="text-primary" to={"/parent/sign-in"}>
                      sign-in
                    </Link>{" "}
                  </span>
                </div>
              </Row>
              <Button
                onClick={handleDashboard}
                style={{ width: "100%" }}
                className="p-2 bg-dark text-white border-0"
              >
                Reset Password
              </Button>
              {/* <center><p className="text-primary">need help ?</p></center> */}
              <center>
                <p className="text-primary">&nbsp;</p>
              </center>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
