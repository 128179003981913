// Report
import ReportPage from "./ReportPage";
import TeacherReport from "./TeacherReport";

export const ReportPageConfig = {
  routes: [
    {
      path: "/operation/Report",
      exact: true,
      component: ReportPage
    },
    {
      path: "/operation/report/:id",
      exact: true,
      component: TeacherReport
    },

  ]
};