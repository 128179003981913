import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import { Table, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { FaDownload, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";
import { useSelector } from "react-redux";

const users = [
  {
    id: 1,
    class: "Class 1",
    course: "Mathematics",
  },
  {
    id: 2,
    class: "Class 2",
    course: "Science",
  },
  {
    id: 3,
    class: "Class 3",
    course: "English",
  },
  {
    id: 4,
    class: "Class 4",
    course: "Mathematics",
  },
  {
    id: 5,
    class: "Class 5",
    course: "Science",
  },
];

const HwandTest = () => {
  const navigate = useNavigate();
  const dummyData = [
    {
      studentId: "S001",
      studentName: "Alice Johnson",
      courseName: "Mathematics",
      homeworkTitle: "Algebra Assignment",
      deadline: "2024-06-20",
      studentStatus: "Submitted",
      summation: "Complete",
      teacherStatus: "Reviewed",
      marks: "85",
    },
    {
      studentId: "S002",
      studentName: "Bob Smith",
      courseName: "Science",
      homeworkTitle: "Physics Lab Report",
      deadline: "2024-06-21",
      studentStatus: "Pending",
      summation: "Incomplete",
      teacherStatus: "Not Reviewed",
      marks: "N/A",
    },
    {
      studentId: "S003",
      studentName: "Carol White",
      courseName: "History",
      homeworkTitle: "World War II Essay",
      deadline: "2024-06-22",
      studentStatus: "Submitted",
      summation: "Complete",
      teacherStatus: "Reviewed",
      marks: "92",
    },
  ];

  const token = useSelector((state) => state.token.value);

  const [subjectStatus, setSubjectStatus] = useState("");
  const [allCourse, setAllCourse] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const fetchAllCourse = async (status, token) => {
    try {
      let res = await MyAPI.GET(`/teacher/course?status=${status}`, token);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setAllCourse(apiData);
      } else {
        toast.error(message || "Error fetching course");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (token) {
      fetchAllCourse(subjectStatus, token);
    }
  }, [token, subjectStatus]);

  return (
    <TeacherLayout>
      <h3>Homework & Test</h3>

      <Row>
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option disabled>Select</option>
            <option value="completed">Completed </option>
            <option value="pending">Pending</option>
            <option value="incomplete">Incomplete</option>
          </Form.Select>
        </Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col md={3}>
          <Form.Group controlId="searchBox">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">Class Name</th>
            <th className="text-white">Course Name</th>
            <th className="text-white">View Hw</th>
            <th className="text-white">View Test</th>
            <th className="text-white">Add Hw</th>
            <th className="text-white">Add Test</th>
          </tr>
        </thead>
        <tbody>
          {!dataLoading &&
            allCourse &&
            allCourse?.length > 0 &&
            allCourse.map((course) => (
              <tr className="text-center bg-light" key={course._id}>
                <td>{course?.title}</td>
                <td>{course?.subject}</td>
                <td
                  onClick={() => navigate(`/teacher/hw/${course._id}`)}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  View
                </td>
                <td
                  onClick={() => navigate(`/teacher/test/${course._id}`)}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  View
                </td>

                <td
                  onClick={() => navigate(`/teacher/hw/${course._id}/add`)}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  Add Hw
                </td>
                <td
                  onClick={() => navigate(`/teacher/test/${course._id}/add`)}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                >
                  Add Test
                </td>
              </tr>
            ))}
          {dataLoading && (
            <>
              <tr>
                <td colSpan={6}>
                  <Spinner size="sm" variant="primary" />
                </td>
              </tr>
            </>
          )}
          {!dataLoading && allCourse && allCourse?.length === 0 && (
            <tr>
              <td colSpan={6}>No Data Found.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Row className="mt-3 mb-3">
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option>Select</option>
            <option value="Completed">Checking Completed </option>
            <option value="Pending">Pending Homework for checking</option>
          </Form.Select>
        </Col>
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option>Select</option>
            <option value="Completed">Completed </option>
            <option value="Pending">Pending</option>
          </Form.Select>
        </Col>
        <Col md={3}></Col>
        <Col md={3}>
          <Form.Group controlId="searchBox">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white text-truncate">Student ID</th>
            <th className="text-white text-truncate">Student Name</th>
            <th className="text-white text-truncate">Course Name</th>
            <th className="text-white text-truncate">Homework Title</th>
            <th className="text-white text-truncate">Deadline</th>
            <th className="text-white text-truncate">Student Status</th>
            <th className="text-white text-truncate">Submission</th>
            <th className="text-white text-truncate">Teacher Status</th>
            <th className="text-white text-truncate">Marks</th>
            <th className="text-white text-truncate" colSpan={2}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((data, index) => (
            <tr key={index} className="text-center">
              <td className=" bg-light text-truncate">{data.studentId}</td>
              <td className=" bg-light text-truncate">{data.studentName}</td>
              <td className=" bg-light text-truncate">{data.courseName}</td>
              <td className=" bg-light text-truncate">{data.homeworkTitle}</td>
              <td className=" bg-light text-truncate">{data.deadline}</td>
              <td className=" bg-light text-truncate">{data.studentStatus}</td>
              <td className=" bg-light text-truncate">{data.summation}</td>
              <td className=" bg-light text-truncate">{data.teacherStatus}</td>
              <td className=" bg-light text-truncate">{data.marks}</td>
              <td className="bg-light">
                <Button variant="success" size="sm">
                  <FaEye size={22} />
                </Button>
              </td>
              <td className="bg-light">
                <Button variant="primary" size="sm">
                  <FaDownload size={22} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="mt-3 mb-3">
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option>Select</option>
            <option value="Completed">Checking Completed </option>
            <option value="Pending">Pending Homework for checking</option>
          </Form.Select>
        </Col>
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option>Select</option>
            <option value="Completed">Completed </option>
            <option value="Pending">Pending</option>
          </Form.Select>
        </Col>
        <Col md={3}></Col>
        <Col md={3}>
          <Form.Group controlId="searchBox">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white text-truncate">Student ID</th>
            <th className="text-white text-truncate">Student Name</th>
            <th className="text-white text-truncate">Course Name</th>
            <th className="text-white text-truncate">Test Title</th>
            <th className="text-white text-truncate">Deadline</th>
            <th className="text-white text-truncate">Student Status</th>
            <th className="text-white text-truncate">Submission</th>
            <th className="text-white text-truncate">Teacher Status</th>
            <th className="text-white text-truncate">Marks</th>
            <th className="text-white text-truncate" colSpan={2}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {dummyData.map((data, index) => (
            <tr key={index} className="text-center">
              <td className=" bg-light text-truncate">{data.studentId}</td>
              <td className=" bg-light text-truncate">{data.studentName}</td>
              <td className=" bg-light text-truncate">{data.courseName}</td>
              <td className=" bg-light text-truncate">{data.homeworkTitle}</td>
              <td className=" bg-light text-truncate">{data.deadline}</td>
              <td className=" bg-light text-truncate">{data.studentStatus}</td>
              <td className=" bg-light text-truncate">{data.summation}</td>
              <td className=" bg-light text-truncate">{data.teacherStatus}</td>
              <td className=" bg-light text-truncate">{data.marks}</td>
              <td className="bg-light">
                <Button variant="success" size="sm">
                  <FaEye size={22} />
                </Button>
              </td>
              <td className="bg-light">
                <Button variant="primary" size="sm">
                  <FaDownload size={22} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default HwandTest;
