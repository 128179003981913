import React from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaDownload, FaSearch } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";

const data = [
  {
    id: "1",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    lastCycle: "05 Apr. 2022 10:30 AM",
    lastClassCount: "40",
    lastPaymentAmount: "40,000",
    lastPaymentDate: "15,000",
  },
  {
    id: "2",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    lastCycle: "05 Apr. 2022 10:30 AM",
    lastClassCount: "40",
    lastPaymentAmount: "40,000",
    lastPaymentDate: "15,000",
  },
  {
    id: "3",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    lastCycle: "05 Apr. 2022 10:30 AM",
    lastClassCount: "40",
    lastPaymentAmount: "40,000",
    lastPaymentDate: "15,000",
  },
  {
    id: "4",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    lastCycle: "05 Apr. 2022 10:30 AM",
    lastClassCount: "40",
    lastPaymentAmount: "40,000",
    lastPaymentDate: "15,000",
  },
];

const data2 = [
  {
    id: "1",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    cycleNo: "05 Apr. 2022 10:30 AM",
    classCount: "40",
    remainingClass: "40,000",
    lastPaymentDate: "02/02/2022",
    transactionID: "TRA@#%234",
    lastPaidAmount: "40,000",
    nextPaymentDate: "02/02/2022",
    nextPaymentAmount: "40,000",
  },
  {
    id: "2",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    cycleNo: "05 Apr. 2022 10:30 AM",
    classCount: "40",
    remainingClass: "40,000",
    lastPaymentDate: "02/02/2022",
    transactionID: "TRA@#%234",
    lastPaidAmount: "40,000",
    nextPaymentDate: "02/02/2022",
    nextPaymentAmount: "40,000",
  },
  {
    id: "3",
    parentID: "parent#001",
    parentName: "John Doe",
    courseID: "order#001",
    courseName: "40,000",
    childID: "40",
    childName: "40,000",
    teacherID: "15,000",
    teacherName: "1,004",
    cycleNo: "05 Apr. 2022 10:30 AM",
    classCount: "40",
    remainingClass: "40,000",
    lastPaymentDate: "02/02/2022",
    transactionID: "TRA@#%234",
    lastPaidAmount: "40,000",
    nextPaymentDate: "02/02/2022",
    nextPaymentAmount: "40,000",
  },
];

function ParentPayment(props) {
  const [tab, setTab] = React.useState("Recieved Payment");

  return (
    <>
      <h5>Parent Payment</h5>
      {/* <OperationLocation path={history} /> */}

      <Row className="d-flex mt-4 align-items-center justify-content-between">
        <Col md={4}>
          <Form.Select
            className="shadow rounded-5"
            onChange={(e) => setTab(e.target.value)}
            value={tab}
          >
            <option value="Recieved Payment">Recieved Payment</option>
            <option value="Due Payment">Due Payment</option>
            <option value="Upcoming Payment">Upcoming Payment</option>
          </Form.Select>
        </Col>
        <Col md={4}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      {tab === "Recieved Payment" ? (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)", minWidth: 2000 }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">S. No.</th>
                <th className="text-white">Parent I'D</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Child I'D</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Last Cycle</th>
                <th className="text-white">Last Class Count</th>
                <th className="text-white">Last Payment Amount</th>
                <th className="text-white">Last Payment Date</th>
                <th className="text-white">Download Invoice</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id} className="bg-light">
                  <td>{index + 1}</td>
                  <td>{item.parentID}</td>
                  <td>{item.parentName}</td>
                  <td>{item.courseID}</td>
                  <td>{item.courseName}</td>
                  <td>{item.childID}</td>
                  <td>{item.childName}</td>
                  <td>{item.teacherID}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.lastCycle}</td>
                  <td>{item.lastClassCount}</td>
                  <td>{item.lastPaymentAmount}</td>
                  <td>{item.lastPaymentDate}</td>
                  <td>
                    <Button variant="primary" className="rounded-5">
                      <FaDownload />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)", minWidth: 2000 }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">S. No.</th>
                <th className="text-white">Parent I'D</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Child I'D</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Cycle No.</th>
                <th className="text-white">Class Count</th>
                <th className="text-white">Remaining Class</th>
                <th className="text-white">Last Payment Date</th>
                <th className="text-white">Transacrion I'D</th>
                <th className="text-white">Last Paid Amount</th>
                <th className="text-white">Next Payment Date</th>
                <th className="text-white">Next Payment Amount</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {data2.map((item, index) => (
                <tr key={item.id} className="bg-light">
                  <td>{index + 1}</td>
                  <td>{item.parentID}</td>
                  <td>{item.parentName}</td>
                  <td>{item.courseID}</td>
                  <td>{item.courseName}</td>
                  <td>{item.childID}</td>
                  <td>{item.childName}</td>
                  <td>{item.teacherID}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.cycleNo}</td>
                  <td>{item.classCount}</td>
                  <td>{item.remainingClass}</td>
                  <td>{item.lastPaymentDate}</td>
                  <td>{item.transactionID}</td>
                  <td>{item.lastPaidAmount}</td>
                  <td>{item.nextPaymentDate}</td>
                  <td>{item.nextPaymentAmount}</td>
                  <td>
                    <Button variant="primary" className="rounded-5">
                      Add a Comment
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

export default ParentPayment;
