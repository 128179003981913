import React, { useState } from "react";
import brain_img_1 from "../../assets/brain_img_1.png";
import brain_img_2 from "../../assets/brain_img_2.png";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { Modal } from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";

const BrainBits2 = () => {
  return (
    <div className="brain_bits2 p-5">
      <div className="badch_cu d-block mx-auto py-2 px-4">
        <h5 className="m-0 fw-medium">Brain Bits</h5>
      </div>

      <div className="row mt-5 px-md-3 px-3">
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={brain_img_1} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={brain_img_2} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={brain_img_1} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={brain_img_2} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default BrainBits2;

const Cards = ({ img }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [activeHeart, setActiveHeart] = useState(false);
  return (
    <>
      <div className="card_c py-3 px-3 bg-white rounded-3 overflow-hidden position-relative">
        <img className="cource_img rounded-3" src={img} alt="" />

        <h5 className="mt-3 fw-semibold">Top 10 Books for JEE Prep!</h5>

        <p className="mt-3">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          expedita corrupti quia, voluptas quas rem saepe deleniti iste mollitia
          in quisquam placeat distinctio explicabo earum possimus modi cum
          dolores? Recusandae, rerum iusto.
        </p>

        <div className="row">
          <div className="col-9">
            <p className="m-0">
              <b>Author:</b> Sunny Dhiman
            </p>
            <p className="m-0">
              <b>Topic:</b> Competitive Exam Prep
            </p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center gap-2">
            {activeHeart ? (
              <IoMdHeart
                className="fs-5 text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveHeart(!activeHeart)}
              />
            ) : (
              <IoMdHeartEmpty
                className="fs-5"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveHeart(!activeHeart)}
              />
            )}

            <IoShareSocialOutline
              className="fs-5"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            />
          </div>
        </div>
      </div>

      <Modal show={show} size="md" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share With Your Friends</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-4 ">
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#3B5998",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaFacebookF className="fs-4 text-white " />
            </div>
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#007FB1",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaLinkedinIn className="fs-4 text-white " />
            </div>

            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaWhatsapp className="fs-4 text-white " />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
              <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                https://www.urjatalents.com/brain-bits
              </p>

              <button className="btn btn-sm btn-primary mt-md-0 mt-2">
                copy
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
