import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { FaBookOpenReader } from "react-icons/fa6";
import SendIcon from "@mui/icons-material/Send";
import { FaFilePdf } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
const RecheduleLeavePage = (props) => {
  const { history } = props;

  const [currentTab, setCurrentTab] = useState("1");

  return (
    <OperationLayout>
      <h5>Reshedule Leave</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select
              onChange={(e) => setCurrentTab(e.target.value)}
              className="shadow rounded-5 mt-3"
            >
              <option value={"1"}>Leave Request</option>
              <option value={"2"}>Reshedule Request</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <h6>
          {currentTab === "1" ? "Leave Request's" : "Reshedule  Request's"}
        </h6>
      </Row>

      {currentTab === "1" && (
        <>
          <Container>
            <Row className="mt-3 mb-3 p-3 shadow border rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ height: "fit-content" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn English By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="success">Aproved</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="mt-3 mb-3 p-3 border shadow rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ maxHeight: "50px" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn Math's By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                  <p className="mt-3">
                    <h6>Reason :</h6>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Quae reiciendis tempore quos, beatae voluptatum ex quas ad
                    unde fuga voluptatibus. Tenetur dolores fugit tempora animi
                    pariatur atque porro ipsum voluptates.
                  </p>
                  <p>
                    <h6>Attachments</h6>
                    <Col md={5} style={{ padding: "10px" }}>
                      <div className="resorce-parent-item">
                        <div className="name">
                          <FaFilePdf /> HC Verma
                        </div>
                        <MdOutlineFileDownload />
                      </div>
                    </Col>
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-start justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="danger">Rejcted</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === "2" && (
        <>
          <Container>
            <Row className="mt-3 mb-3 p-3 shadow border rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ height: "fit-content" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn English By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="success">Aproved</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="mt-3 mb-3 p-3 border shadow rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ maxHeight: "50px" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn Math's By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                  <p className="mt-3">
                    <h6>Reason :</h6>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Quae reiciendis tempore quos, beatae voluptatum ex quas ad
                    unde fuga voluptatibus. Tenetur dolores fugit tempora animi
                    pariatur atque porro ipsum voluptates.
                  </p>
                  <p>
                    <h6>Attachments</h6>
                    <Col md={5} style={{ padding: "10px" }}>
                      <div className="resorce-parent-item">
                        <div className="name">
                          <FaFilePdf /> HC Verma
                        </div>
                        <MdOutlineFileDownload />
                      </div>
                    </Col>
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-start justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="danger">Rejcted</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="mt-3 mb-3 p-3 shadow border rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ height: "fit-content" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn English By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="success">Aproved</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="mt-3 mb-3 p-3 shadow border rounded-3">
              <Col md={8} className=" d-flex">
                <span
                  style={{ height: "fit-content" }}
                  className="p-3 bg-warning rounded-3 d-flex align-items-center"
                >
                  <FaBookOpenReader size={22} />
                </span>

                <div className="ms-3">
                  <h6>Learn English By John Smith</h6>
                  <p className="mb-1">
                    <b>Leave at</b> : 12 January 2012
                  </p>
                  <Badge bg="primary">From Parent</Badge>
                  <p className="mt-3">
                    <b>Date For Rechedule : </b> 12/12/2024
                  </p>
                  <p>
                    <b>Date of Request : </b> 12/12/2024
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex align-items-center justify-content-end"
              >
                <span>
                  <b>To Teacher :</b> <Badge bg="success">Aproved</Badge>{" "}
                </span>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </OperationLayout>
  );
};

export default RecheduleLeavePage;
