import React, { useEffect, useRef, useState } from "react";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoChevronDown } from "react-icons/io5";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { storeRole, storeToken } from "../slices/tokenSlice";

const Navbar = () => {
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showAside, setShowAside] = useState(false);

  const navigate = useNavigate();

  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  useOutsideAlerter(dropdownRef1, setShowDropdown1);
  useOutsideAlerter(dropdownRef2, setShowDropdown2);

  function useOutsideAlerter(ref, setShowDropdown) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setShowDropdown]);
  }

  const dispatch = useDispatch();
  const parentToken = sessionStorage.getItem("parentToken");
  const teacherToken = sessionStorage.getItem("teacherToken");
  const accountToken = sessionStorage.getItem("accountToken");
  const operationToken = sessionStorage.getItem("operationToken");
  const adminToken = sessionStorage.getItem("adminToken");
  const token = useSelector((state) => state.token.value);
  const role = useSelector((state) => state.token.role);

  useEffect(() => {
    if (parentToken) {
      dispatch(storeToken(parentToken));
      dispatch(storeRole("parent"));
    } else if (teacherToken) {
      dispatch(storeToken(teacherToken));
      dispatch(storeRole("teacher"));
    } else if (accountToken) {
      dispatch(storeToken(accountToken));
      dispatch(storeRole("account"));
    } else if (operationToken) {
      dispatch(storeToken(operationToken));
      dispatch(storeRole("operation"));
    } else if (adminToken) {
      dispatch(storeToken(adminToken));
      dispatch(storeRole("admin"));
    }
  }, [accountToken, adminToken, operationToken, parentToken, teacherToken]);

  return (
    <nav
      className={`d-flex align-items-center justify-content-between px-md-5 py-4 pe-5 bg-white shadow `}
    >
      <div className="menu" onClick={() => setShowAside(!showAside)}>
        <BiMenuAltRight />
      </div>

      <div className="logo">
        <Link to="/">
          <h4 className="m-0 fw-bold fst-italic">Learniee</h4>
        </Link>
      </div>
      <div className="part2 ">
        <ul className="list-unstyled d-flex align-items-center gap-5 m-0">
          <li>
            <Link to="/" className="links">
              Home
            </Link>
          </li>
          <li ref={dropdownRef1} className="dropdown-cus position-relative">
            <Link
              to="#"
              className="links"
              onClick={() => setShowDropdown1(!showDropdown1)}
            >
              Courses <IoChevronDown />
            </Link>

            <div
              className={`dropdown-content bg-white shadow  mt-4 ${
                showDropdown1 ? "pe-3 py-3 active" : ""
              }`}
            >
              <li>
                <Link to="/course-main">Science</Link>
              </li>
              <li>
                <Link to="/course-main">Hindi</Link>
              </li>
              <li>
                <Link to="/course-main">English</Link>
              </li>
              <li>
                <Link to="/course-main">Maths</Link>
              </li>
              <li>
                <Link to="/course-main">Social Science</Link>
              </li>
            </div>
          </li>
          <li>
            <Link className="links" to="/blog">
              Blog
            </Link>
          </li>
          <li>
            <Link className="links" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="links" to="/faq">
              FAQ
            </Link>
          </li>
          <li>
            <Link className="links" to="/help">
              Help
            </Link>
          </li>
        </ul>
      </div>
      <div className="part3 d-flex align-items-center">
        <div className="search d-md-flex d-none">
          <form onSubmit={() => navigate("/search")}>
            <input type="text" placeholder="Search here.." />
          </form>
          <CiSearch className="fs-4" />
        </div>
        {token ? (
          <button
            className="ms-3"
            onClick={() => navigate(`/${role}/dashboard`)}
          >
            Dashboard
          </button>
        ) : (
          <button className="ms-3" onClick={() => navigate("/parent/sign-up")}>
            Register
          </button>
        )}
      </div>

      {/* mobile menu */}
      <aside className={`aside shadow ${showAside ? "active" : ""}`}>
        <div className="close" onClick={() => setShowAside(!showAside)}>
          <AiOutlineClose />
        </div>
        <ul className="list-unstyled">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li
            ref={dropdownRef2}
            className={`dropdown-cus ${showDropdown2 ? "active" : ""}`}
          >
            <Link to="/" onClick={() => setShowDropdown2(!showDropdown2)}>
              Courses <IoChevronDown />
            </Link>

            <div className="list-unstyled dropdown-content ms-5">
              <li>
                <Link to="/course-main">Hindi</Link>
              </li>
              <li>
                <Link to="/course-main">English</Link>
              </li>
              <li>
                <Link to="/course-main">Maths</Link>
              </li>
              <li>
                <Link to="/course-main">Science</Link>
              </li>
              <li>
                <Link to="/course-main">Social Science</Link>
              </li>
            </div>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/help">Help</Link>
          </li>
        </ul>
      </aside>
    </nav>
  );
};

export default Navbar;
