import React from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

const users = [
  {
    id: 1,
    parentId: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Alex Doe",
    parentStatus: "Indian",
    grade: "Grade 1",
    board: "CBSE",
    subject: "Maths",
    dayTime: "Fri 4:00 PM",
    status: "Active",
  },
  {
    id: 2,
    parentId: "P002",
    parentName: "Jane Doe",
    childId: "C002",
    childName: "Alex Doe",
    parentStatus: "NRI",
    grade: "Grade 1",
    board: "CBSE",
    subject: "Maths",
    dayTime: "Fri 4:00 PM",
    status: "On Hold",
  },
  {
    id: 3,
    parentId: "P003",
    parentName: "John Doe",
    childId: "C003",
    childName: "Alex Doe",
    parentStatus: "Indian",
    grade: "Grade 1",
    board: "CBSE",
    subject: "Maths",
    dayTime: "Fri 4:00 PM",
    status: "Active",
  },
];

const ParentManagment = () => {
  const navigate = useNavigate();
  return (
    <>
      <h5>Parent Management</h5>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">#</th>
            <th className="text-white">Parent I'D</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Child I'D</th>
            <th className="text-white">Child Name</th>
            <th className="text-white">Parent Status</th>
            <th className="text-white">Grade</th>
            <th className="text-white">Board</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Day & Time</th>
            <th className="text-white">Status</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{index + 1}</td>
              <td>{user.parentId}</td>
              <td>{user.parentName}</td>
              <td>{user.childId}</td>
              <td>{user.childName}</td>
              <td>{user.parentStatus}</td>
              <td>{user.grade}</td>
              <td>{user.board}</td>
              <td>{user.subject}</td>
              <td>{user.dayTime}</td>
              <td>{user.status}</td>
              <td
                className="text-primary"
                onClick={() => navigate("/admin/parent-profile")}
                style={{ cursor: "pointer" }}
              >
                View
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ParentManagment;
