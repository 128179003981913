import ChatPage from "./ChatPage";

export const ChatPageConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/chat",
      exact: true,
      component: ChatPage,
    },
  ],
};
