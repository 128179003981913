import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Row, Col, Modal, Form, Alert } from "react-bootstrap";
import "./Settings.css";
import axios from "../../utils/axios";
import { Button } from "@mui/material";
import { MdCloudDownload } from "react-icons/md";
import ParentFooter from "../../components/ParentFooter";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
function Settings() {
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const parentId = localStorage.getItem("parentId");
  const token = useSelector((state) => state.token.value);

  const [holdClasses, setHoldClasses] = useState(false);
  const [rateAnonymously, setRateAnonymously] = useState(false);
  const [holdStartDate, setHoldStartDate] = useState(null);
  const [holdEndDate, setHoldEndDate] = useState(null);
  const [preferedDate, setPreferedDate] = useState(null);
  const [preferedTime, setPreferedTime] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [educationApproach, setEducationApproach] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = () => {
    handleCloseModal();
  };

  useEffect(() => {
    if (parentId) {
      // find parent by id
      const getParentById = async () => {
        try {
          const response = await axios.get(`/parent/getById?ID=${parentId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.data.res_type === "success") {
            const parent = response.data.data.parent;
            setUserData(parent);
          }
        } catch (error) {
          console.error("Error fetching parent data:", error);
        }
      };

      getParentById();
    } else {
      console.error("Parent ID not found");
    }
  }, [parentId, token]);

  // console.log("userData", userData);

  const handleHoldAndRate = async () => {
    try {
      const response = await axios.put(
        `/parent/setting`,
        {
          isHoldClass: holdClasses,
          isRateAnonymously: rateAnonymously,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        // console.log("Settings updated successfully");
        toast.success("Settings updated successfully");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleStartEnd = async () => {
    try {
      const response = await axios.put(
        `/parent/setting`,
        {
          holdStartDate: holdStartDate,
          holdEndDate: holdEndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        // console.log("Settings updated successfully");
        toast.success("Settings updated successfully");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const preferedHandle = async () => {
    try {
      const response = await axios.put(
        `/parent/setting`,
        {
          preferredDateTime: preferedDate + " " + preferedTime,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        // console.log("Settings updated successfully");
        toast.success("Settings updated successfully");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleEmailAndEdu = async () => {
    try {
      const response = await axios.put(
        `/parent/setting`,
        {
          primaryEmail: primaryEmail,
          educationApproach: educationApproach,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        // console.log("Settings updated successfully");
        toast.success("Settings updated successfully");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleDeleteAccountRequest = async () => {
    try {
      setLoading(true);

      const res = await axios.delete("/parent/account-request", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(res);

      if (res.data.res_type === "success") {
        toast.success("Account deletion request sent successfully");
        handleCloseModal();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      // console.error("Error deleting account:", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OftadehLayout>
      <h1>Settings</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row>
        <span className="settings-title-heading-parent">
          Select Notification for
        </span>
      </Row>

      <div className="bg-white shadow p-4 rounded-4 mt-3">
        <Row className="mt-3 ">
          <Col
            md={4}
            className="d-flex align-items-center jsutify-content-between"
          >
            <span style={{ width: "80%" }} className="stting-parent-title">
              Hold Classes
            </span>
            <span>
              <FormControlLabel
                control={<Android12Switch color="primary" />}
                label=""
                labelPlacement="start"
                onChange={(e) => setHoldClasses(e.target.checked)}
              />
            </span>
          </Col>
          <Col
            md={4}
            className="d-flex align-items-center jsutify-content-between"
          >
            <span style={{ width: "80%" }} className="stting-parent-title">
              Rate Anonymously
            </span>
            <span>
              <FormControlLabel
                control={<Android12Switch color="primary" />}
                label=""
                labelPlacement="start"
                onChange={(e) => setRateAnonymously(e.target.checked)}
              />
            </span>
          </Col>
          <Col md={4}>
            <Button onClick={handleHoldAndRate} variant="outlined">
              Save
            </Button>
          </Col>
        </Row>

        <Col
          md={12}
          className="d-flex align-items-center justify-content-center mt-3 pe-3"
        >
          <div className="form-group col-md-4 mt-sm-3">
            <span className="mb-3">
              <b>Hold Start Date</b> : 12/7/2022
            </span>
            <br />
            <label className="mt-3" htmlFor="DOB">
              Hold Start Classses
            </label>
            <br />
            {/* <ReactDatePicker
              className="form-control col-md-5 shadow"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              style={{ width: "100% !important", height: "6vh" }}
              onChange={(date) => setHoldStartDate(date)}

            /> */}

            <Form.Control
              type="date"
              className="form-control shadow"
              style={{ width: "100%", height: "6vh" }}
              onChange={(e) => setHoldStartDate(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4 mt-sm-3 ps-3">
            <span className="mb-3">
              <b>Hold End Date</b> : 12/7/2022
            </span>
            <br />
            <label className="mt-3" htmlFor="Time">
              {" "}
              Hold End Classses
            </label>
            <br />
            {/* <ReactDatePicker
              className="form-control col-md-4 shadow"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
              style={{ width: "100%", height: "6vh" }}
              onChange={(date) => setHoldEndDate(date)}
            /> */}

            <Form.Control
              type="date"
              className="form-control shadow"
              style={{ width: "100%", height: "6vh" }}
              onChange={(e) => setHoldEndDate(e.target.value)}
            />
          </div>
          <Col md={4} className="mt-4">
            <Button
              onClick={handleStartEnd}
              className="mt-5 ms-4"
              variant="outlined"
            >
              Save
            </Button>
          </Col>
        </Col>

        <Col md={8}>
          <Alert variant="warning" className="mt-3 mb-3">
            Your classes will expire in more than 45 days. Please complete them
            before they expire.
          </Alert>
        </Col>

        <Row className="mt-3">
          <Col md={3}>
            <Form.Group controlId="nameInput">
              <Form.Label>prefered date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Email ID"
                onChange={(e) => setPreferedDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="nameInput">
              <Form.Label>prefered time</Form.Label>
              <Form.Control
                type="time"
                placeholder="Enter Preferred Time"
                onChange={(e) => setPreferedTime(e.target.value)}
                value={preferedTime}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Button
              onClick={preferedHandle}
              className="mt-4"
              variant="outlined"
            >
              Save
            </Button>
          </Col>
        </Row>

        <Row className=" d-flex align-items-center justify-content-center mt-3">
          <div class="form-group col-md-3 mt-md-3 mt-sm-3 ">
            <span className="mb-3">
              <b>Current Email</b> : example@email.com
            </span>
            <br />
            <label for="inputEmail4">Primary Email id</label>
            <input
              type="email"
              class="form-control shadow"
              id="inputEmail4"
              placeholder="example@adc.com"
              onChange={(e) => setPrimaryEmail(e.target.value)}
              value={primaryEmail}
            />
          </div>
          <div className="form-group col-md-3  mt-3 ">
            <br />
            <label for="inputEmail4">Education Approach</label>
            <select
              className="form-control shadow"
              style={{ width: "100%", height: "6vh" }}
              id="gender"
              onChange={(e) => setEducationApproach(e.target.value)}
              value={educationApproach}
            >
              <option value="" disabled selected>
                Home School
              </option>
              <option value="Home School">Home School</option>
              <option value="IIT Preparation">IIT Preparation</option>
              <option value="NEET Preparation">NEET Preparation</option>
              <option value="Gifted Program">Gifted Program</option>
              <option value="Disability">Disability</option>
            </select>
          </div>
          <Col md={6}>
            <Button
              onClick={handleEmailAndEdu}
              className="mt-5"
              variant="outlined"
            >
              Save
            </Button>
          </Col>
        </Row>

        {/* button for download recordings */}
        <Col className="mt-4 mb-3" md={12}>
          <Button
            className="shadow fw-bold d-flex align-items-center"
            style={{ borderColor: "orange", color: "orange" }}
            variant="outlined"
          >
            Download Recordings <MdCloudDownload className="fs-5 ms-2" />
          </Button>
        </Col>

        <Col className="mt-4 mb-3" md={12}>
          <Button
            className="shadow"
            style={{ borderColor: "red", color: "red" }}
            variant="outlined"
            onClick={handleShowModal}
          >
            Delete Account
          </Button>

          <Modal
            show={showModal}
            onHide={handleCloseModal}
            style={{ zIndex: "1111", marginTop: "20vh" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete your account?</p>
              <p>This action cannot be undone.</p>
              <p>
                Deleting your account will result in the forfeiture of your
                wallet funds.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                onClick={handleDeleteAccountRequest}
                className="ms-3 bg-danger text-white border-0"
                variant="outlined"
              >
                {loading ? "Deleting..." : "Delete Account"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </div>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default Settings;
