import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Row, Form, Badge } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import styled from "styled-components";
import { MdDelete, MdOutlineStarHalf, MdStar } from "react-icons/md";
import ParentFooter from "../../components/ParentFooter";
import { CiEdit } from "react-icons/ci";
import { IconButton } from "@mui/material";
import FeedbackModal from "./FeedbackModal";

const ReviewPage = () => {
  const [active1, setActive1] = React.useState(false);
  const [active2, setActive2] = React.useState(true);
  const [active3, setActive3] = React.useState(true);

  const [selectedOption, setSelectedOption] = useState('1');

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <OftadehLayout>
      <h1>Review</h1>
      <FeedbackModal show={showModal} onHide={handleCloseModal} />
      <StyleContainer>
        <Row>
          <Col md={3} className="mt-4">
            <Form.Select
              onChange={(e) => setSelectedOption(e.target.value)}
              value={selectedOption}
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Select</option>
              <option value={'1'}>Course</option>
              <option value={'2'}>Teacher</option>
            </Form.Select>
          </Col>
        </Row>

        {selectedOption === '1' && (
          <>
            <div
              className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border border-3 mt-4 ${active2 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>
                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Course End Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="warning">Pending</Badge>
                <button onClick={handleShowModal} className="btn btn-primary btn-sm rounded-5">Submit Review</button>
              </div>
            </div>

            <div
              className={`position-relative pe-5 mt-4 feedback_review py-2 px-3 shadow rounded-3 border border-3 ${active1 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>

                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Course End Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="success">Submitted</Badge>
                <p
                  className="m-0 fw-bold text_yellow"
                  onClick={() => setActive1(!active1)}
                >
                  view <FaAngleRight />
                </p>
              </div>
              <div className="content mt-3">
                <p className="m-0 d-flex align-items-center">
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                  4.5 Rating
                </p>
                <p className="m-0 mt-3">
                  Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                  nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                  porttitor. Fermentum scelerisque pretium mi convallis ac eget
                  ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                  dignissim congue ultrices dui diam. Convallis ut eu elit
                  imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                  fringilla. Augue condimentum nulla in velit nascetur tempor
                  venenatis cras. Auctor mi nulla non neque.
                  <br />
                  <br />
                  <IconButton onClick={handleShowModal} aria-label="delete">
                    <CiEdit size={22} color="blue" />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdDelete size={22} color="red" />
                  </IconButton>
                </p>
              </div>
            </div>

            <div
              className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border border-3 mt-4 ${active2 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>
                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Course End Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="success">Submitted</Badge>
                <p
                  className="m-0 fw-bold text_yellow"
                  onClick={() => setActive2(!active2)}
                >
                  view <FaAngleRight />
                </p>
              </div>
              <div className="content mt-3">
                <p className="m-0 d-flex align-items-center">
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                  4.5 Rating
                </p>
                <p className="m-0 mt-3">
                  Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                  nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                  porttitor. Fermentum scelerisque pretium mi convallis ac eget
                  ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                  dignissim congue ultrices dui diam. Convallis ut eu elit
                  imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                  fringilla. Augue condimentum nulla in velit nascetur tempor
                  venenatis cras. Auctor mi nulla non neque.
                  <br />
                  <br />
                  <IconButton onClick={handleShowModal} aria-label="delete">
                    <CiEdit size={22} color="blue" />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdDelete size={22} color="red" />
                  </IconButton>
                </p>
              </div>
            </div>
          </>
        )}

        {selectedOption === '2' && (
          <>
            <div
              className={`position-relative pe-5 mt-4 feedback_review py-2 px-3 shadow rounded-3 border border-3 ${active1 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>

                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Class Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="success">Submitted</Badge>
                <p
                  className="m-0 fw-bold text_yellow"
                  onClick={() => setActive1(!active1)}
                >
                  view <FaAngleRight />
                </p>
              </div>
              <div className="content mt-3">
                <p className="m-0 d-flex align-items-center">
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                  4.5 Rating
                </p>
                <p className="m-0 mt-3">
                  Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                  nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                  porttitor. Fermentum scelerisque pretium mi convallis ac eget
                  ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                  dignissim congue ultrices dui diam. Convallis ut eu elit
                  imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                  fringilla. Augue condimentum nulla in velit nascetur tempor
                  venenatis cras. Auctor mi nulla non neque.
                  <br />
                  <br />
                  <IconButton onClick={handleShowModal} aria-label="delete">
                    <CiEdit size={22} color="blue" />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdDelete size={22} color="red" />
                  </IconButton>
                </p>
              </div>
            </div>

            <div
              className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border border-3 mt-4 ${active2 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>
                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Course End Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="warning">Pending</Badge>
                <button onClick={handleShowModal} className="btn btn-primary btn-sm rounded-5">Submit Review</button>
              </div>
            </div>

            <div
              className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border border-3 mt-4 ${active2 === true ? "active" : ""
                }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="review_user_image">
                    <img
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </div>
                  <div className="ms-5">
                    <h6 className="m-0 fw-bold">Kristin Watson</h6>
                    <p>12 January 2023</p>
                  </div>
                </div>
                <div className="">
                  <h6>This is Course Name </h6>
                  <span><b>Class Date :</b> 10 Mar 2024</span>
                </div>
                <Badge bg="success">Submitted</Badge>
                <p
                  className="m-0 fw-bold text_yellow"
                  onClick={() => setActive2(!active2)}
                >
                  view <FaAngleRight />
                </p>
              </div>
              <div className="content mt-3">
                <p className="m-0 d-flex align-items-center">
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdStar className="text_yellow fs-6 me-1" />
                  <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                  4.5 Rating
                </p>
                <p className="m-0 mt-3">
                  Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                  nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                  porttitor. Fermentum scelerisque pretium mi convallis ac eget
                  ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                  dignissim congue ultrices dui diam. Convallis ut eu elit
                  imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                  fringilla. Augue condimentum nulla in velit nascetur tempor
                  venenatis cras. Auctor mi nulla non neque.
                  <br />
                  <br />
                  <IconButton onClick={handleShowModal} aria-label="delete">
                    <CiEdit size={22} color="blue" />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdDelete size={22} color="red" />
                  </IconButton>
                </p>
              </div>
            </div>
          </>
        )}
      </StyleContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;



export default ReviewPage;
