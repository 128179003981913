import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import PhoneInput from "react-phone-number-input";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function TeacherSetting() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [presentTeacher, setPresentTeacher] = useState({});
  const [otpSentEmail, setOtpSentEmail] = useState(false);
  const teacherToken = useSelector((state) => state.token.value);
  const [otpSentPhone, setOtpSentPhone] = useState(false);
  const [otpSentWhatsapp, setOtpSentWhatsapp] = useState(false);
  const [otpPhone, setOtpPhone] = useState("");
  const [otpWhatsapp, setOtpWhatsapp] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const response = await axios.get("/teacher/setting", {
          headers: { Authorization: `Bearer ${teacherToken}` },
        });
        const data = response.data;
        // console.log(data);

        if (data.res_type == "success") {
          setEmail(data.data.email);
          setPhone(data.data.phone);
          setWhatsapp(data.data.whatsApp);
          setPresentTeacher(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeacherData();
  }, []);

  const handleSendOtp = (type) => {
    // validate email with regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    axios
      .post("/teacher/otp", {
        type: type,
        value: type === "email" ? email : type === "phone" ? phone : whatsapp,
      })
      .then((response) => {
        // console.log(response.data);

        if (response.data.res_type === "success") {
          toast.success(response.data.message);
          if (type === "email") {
            setOtpSentEmail(true);
          } else if (type === "phone") {
            setOtpSentPhone(true);
          } else if (type === "whatsApp") {
            setOtpSentWhatsapp(true);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error sending OTP:", error);
      });
  };

  const handleVerifyOtp = async (type) => {
    try {
      const response = await axios.put("/teacher/otp", {
        type: type,
        value: type === "email" ? email : type === "phone" ? phone : whatsapp,
        otp:
          type === "email"
            ? otpEmail
            : type === "phone"
            ? otpPhone
            : otpWhatsapp,
      });

      if (response.data.data.isVerified) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // toast.error("Something went wrong");
      console.error("Error verifying OTP:", error);
      return false;
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      if (email !== presentTeacher.email && !otpEmail) {
        toast.error("Please verify email OTP");
        return;
      }
      if (phone !== presentTeacher.phone && !otpPhone) {
        toast.error("Please verify phone OTP");
        return;
      }
      if (whatsapp !== presentTeacher.whatsApp && !otpWhatsapp) {
        toast.error("Please verify WhatsApp OTP");
        return;
      }

      if (email !== presentTeacher.email && !handleVerifyOtp("email")) {
        toast.error("Invalid email OTP");
        return;
      }
      if (phone !== presentTeacher.phone && !handleVerifyOtp("phone")) {
        toast.error("Invalid phone OTP");
        return;
      }
      if (
        whatsapp !== presentTeacher.whatsApp &&
        !handleVerifyOtp("whatsApp")
      ) {
        toast.error("Invalid WhatsApp OTP");
        return;
      }

      const response = await axios.put(
        "/teacher/setting",
        {
          email: email,
          email_otp: otpEmail,
          phone: phone,
          phone_otp: otpPhone,
          whatsApp: whatsapp,
          whatsApp_otp: otpWhatsapp,
          old_password: oldPassword,
          new_password: password,
        },
        {
          headers: { Authorization: `Bearer ${teacherToken}` },
        }
      );

      console.log(response);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
      } else if (response.data.res_type === "bad_request") {
        response.data.data.forEach((error) => {
          toast.error(error);
        });
      } else {
        toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeacherLayout>
      <Container>
        <h2>Settings</h2>
        <Form onSubmit={handleSaveChanges}>
          <Row className="mb-3">
            <Col md={12} className="position-relative">
              <Form.Group className="position-relative" controlId="formEmail">
                <Form.Label>Register Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {presentTeacher.email !== email && (
                  <Form.Text
                    onClick={() => handleSendOtp("email")}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 30,
                      cursor: "pointer",
                      background: "#f4f4f4",
                      color: "#0d6efd",
                      padding: 5,
                      borderRadius: 5,
                    }}
                  >
                    Send OTP
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            {otpSentEmail && (
              <Col md={12} className="mt-3 mb-3">
                <Form.Group controlId="otpInput">
                  <Form.Control
                    className="shadow rounded-2 border-2"
                    type="text"
                    placeholder="Enter Email OTP"
                    value={otpEmail}
                    onChange={(e) => setOtpEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Register Phone Number</Form.Label>
                <div className=" px-3 py-2 border d-flex align-items-center justify-content-between rounded-3">
                  <PhoneInput
                    international
                    style={{
                      border: "none !important",
                    }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    countryCodeEditable={true}
                  />
                  {presentTeacher.phone !== phone && (
                    <Form.Text
                      onClick={() => handleSendOtp("phone")}
                      style={{
                        cursor: "pointer",
                        background: "#f4f4f4",
                        color: "#0d6efd",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      Send OTP
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            {otpSentPhone && (
              <Col md={6} className="mt-4">
                <Form.Group controlId="otpInput">
                  <Form.Control
                    className="shadow rounded-2 border-2"
                    type="text"
                    placeholder="Enter Phone OTP"
                    value={otpPhone}
                    onChange={(e) => setOtpPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md={6} className="mt-3 ">
              <Form.Group controlId="formWhatsapp">
                <Form.Label>WhatsApp Number</Form.Label>
                <div className="px-3 py-2 border rounded-3 d-flex align-items-center justify-content-between">
                  <PhoneInput
                    international
                    style={{
                      border: "none !important",
                    }}
                    defaultCountry="IN"
                    placeholder="Enter Phone number"
                    value={whatsapp}
                    onChange={setWhatsapp}
                    countryCodeEditable={true}
                  />
                  {presentTeacher.whatsApp !== whatsapp && (
                    <Form.Text
                      onClick={() => handleSendOtp("whatsApp")}
                      style={{
                        cursor: "pointer",
                        background: "#f4f4f4",
                        color: "#0d6efd",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      Send OTP
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
            </Col>
            {otpSentWhatsapp && (
              <Col md={6} className="mt-5">
                <Form.Group controlId="otpInput">
                  <Form.Control
                    className="shadow rounded-2 border-2"
                    type="text"
                    placeholder="Enter WhataApp OTP"
                    value={otpWhatsapp}
                    onChange={(e) => setOtpWhatsapp(e.target.value)}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formPassword">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            {loading ? "Updating..." : "Save Changes"}
          </Button>
        </Form>
      </Container>
    </TeacherLayout>
  );
}

export default TeacherSetting;
