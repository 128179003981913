import React from 'react'
import TeacherLayout from '../../components/teacherCompoents/TeacherLayout/TeacherLayout'
import { Col, Dropdown, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { FaPlus } from 'react-icons/fa6'
import ParentFooter from '../../components/ParentFooter'

const AddSubject = () => {
  return (
    <TeacherLayout>
      <h1>Add Subject</h1>
      <StyledContainer>
        <Row className='mt-5'>
          <Col md={6}>
            <Row>
              <Col>
                <p className='m-0'>Category:</p>
              </Col>
            </Row>
            <Row className='pe-5'>
              <Col className='d-flex align-items-center jsutify-content-between mt-3 pe-5'>
                <Dropdown>
                  <Dropdown.Toggle
                    id='dropdown-basic'
                    className='dropdown-basic shadow rounded-4 py-2 px-3 position-relative'
                  >
                    Select
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href='#/action-1'>Academic</Dropdown.Item>
                    <Dropdown.Item href='#/action-2'>
                      Competitive Exams
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <h3>
              Select subjects or courses you can teach (More than one can be
              selected){' '}
            </h3>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col md={3}>
            <Dropdown>
              <Dropdown.Toggle
                id='dropdown-basic'
                className='dropdown-basic shadow rounded-4 py-2 px-3 position-relative'
              >
                Subject
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href='#/action-1'>Maths</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Physics</Dropdown.Item>
                <Dropdown.Item href='#/action-3'>Social</Dropdown.Item>
                <Dropdown.Item href='#/action-3' className='bg-yellow'>
                  <FaPlus className='fs-6 me-2' /> Add
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3}>
            <Dropdown>
              <Dropdown.Toggle
                id='dropdown-basic'
                className='dropdown-basic shadow rounded-4 py-2 px-3 position-relative'
              >
                Grade
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href='#/action-1'>
                  {/* chackbox */}
                  <input type='checkbox' className='me-2' />
                  1st Grade
                </Dropdown.Item>
                <Dropdown.Item href='#/action-2'>
                  <input type='checkbox' className='me-2' />
                  2nd Grade
                </Dropdown.Item>
                <Dropdown.Item href='#/action-3'>
                  <input type='checkbox' className='me-2' />
                  3rd Grade
                </Dropdown.Item>
                <Dropdown.Item href='#/action-3' className='bg-yellow'>
                  <FaPlus className='fs-6 me-2' /> Add
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={3}>
            <Dropdown>
              <Dropdown.Toggle
                id='dropdown-basic'
                className='dropdown-basic shadow rounded-4 py-2 px-3 position-relative'
              >
                Board
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href='#/action-1'>
                  {/* chackbox */}
                  <input type='checkbox' className='me-2' />
                  CBSE
                </Dropdown.Item>
                <Dropdown.Item href='#/action-2'>
                  <input type='checkbox' className='me-2' />
                  ICSE
                </Dropdown.Item>
                <Dropdown.Item href='#/action-3'>
                  <input type='checkbox' className='me-2' />
                  State Board
                </Dropdown.Item>
                <Dropdown.Item href='#/action-3' className='bg-yellow'>
                  <FaPlus className='fs-6 me-2' /> Add
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row className='mt-5'>
          <Col>
            <button className='add_btn'>
              <FaPlus className='fs-6 me-2' />
              ADD
            </button>
          </Col>
        </Row>

        <Row className='mt-4 d-flex justify-content-end pe-5'>
          <button className='approval_btn text-white'>Send For Approval</button>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  )
}

const StyledContainer = styled.div`
  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 250px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .form-control {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .add_btn {
    background-color: var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px 20px;
    border: none;
  }

  .approval_btn {
    background-color: var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
  }
`

export default AddSubject
