import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import "../../assets/css/parent/parent.css";
import { Table, Modal, Button, Form, Badge, Row, Col } from "react-bootstrap";
import { Button as MIButton } from "@mui/material";

const data = [
  {
    id: 1,
    teacherId: "teacher#0001",
    teacherName: "John Doe",
    accountNumber: "Account#001",
    ifscCode: "IFSC#001",
    branchName: "Branch#001",
    date: "05 Apr. 2022",
  },
  {
    id: 2,
    teacherId: "teacher#0001",
    teacherName: "John Doe",
    accountNumber: "Account#001",
    ifscCode: "IFSC#001",
    branchName: "Branch#001",
    date: "05 Apr. 2022",
  },
  {
    id: 3,
    teacherId: "teacher#0001",
    teacherName: "John Doe",
    accountNumber: "Account#001",
    ifscCode: "IFSC#001",
    branchName: "Branch#001",
    date: "05 Apr. 2022",
  },
  {
    id: 4,
    teacherId: "teacher#0001",
    teacherName: "John Doe",
    accountNumber: "Account#001",
    ifscCode: "IFSC#001",
    branchName: "Branch#001",
    date: "05 Apr. 2022",
  },
];

const BankDetailsPage = () => {
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  const handleShowApprovalModal = () => {
    setShowApprovalModal(true);
  };

  const handleCloseApprovalModal = () => {
    setShowApprovalModal(false);
  };

  const handleApproveChange = () => {
    // Implement logic to approve change
    handleCloseApprovalModal();
  };

  const [tab, setTab] = useState("Pending");

  return (
    <AccountLayout>
      <h3>Bank Details</h3>

      <Row className="mt-4">
        <Col md={4}>
          <Form.Select
            onChange={(e) => setTab(e.target.value)}
            aria-label="Default select example"
          >
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Canceled">Canceled</option>
          </Form.Select>
        </Col>
      </Row>

      {tab === "Pending" ? (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)" }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">Teacher ID</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Account No.</th>
                <th className="text-white">IFSC Code</th>
                <th className="text-white">Branch Name</th>
                <th className="text-white">Date</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="bg-light">
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.ifscCode}</td>
                  <td>{item.branchName}</td>
                  <td>{item.date}</td>
                  <td>
                    <MIButton
                      onClick={handleShowApprovalModal}
                      variant="outlined"
                      size="small"
                    >
                      Accept
                    </MIButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : tab === "Accepted" ? (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)" }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">Teacher ID</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Account No.</th>
                <th className="text-white">IFSC Code</th>
                <th className="text-white">Branch Name</th>
                <th className="text-white">Date</th>
                <th className="text-white">View</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="bg-light">
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.ifscCode}</td>
                  <td>{item.branchName}</td>
                  <td>{item.date}</td>
                  <td>
                    <p
                      className="text-primary text-decoration-underline"
                      style={{ cursor: "pointer" }}
                    >
                      view
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)" }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">Teacher ID</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Account No.</th>
                <th className="text-white">IFSC Code</th>
                <th className="text-white">Branch Name</th>
                <th className="text-white">Date</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id} className="bg-light">
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.ifscCode}</td>
                  <td>{item.branchName}</td>
                  <td>{item.date}</td>
                  <td>
                    <Button variant="danger" size="sm">
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {/* Approval Modal */}
      <Modal
        show={showApprovalModal}
        onHide={handleCloseApprovalModal}
        centered
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Approve Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to approve the change in bank details for John Doe?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleApproveChange}>
            Approve
          </Button>
          <Button variant="secondary" onClick={handleCloseApprovalModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default BankDetailsPage;
