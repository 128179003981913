import React from "react";
import { Table } from "react-bootstrap";

const AllNewsLetters = () => {
  return (
    <>
      <h5 className="mt-3">All News Letters</h5>

      <Table
        className="mt-4 mb-3 rounded-4"
        striped
        responsive
        hover
        style={{ backgroundColor: "var(--color-primary)" }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Email</th>
            <th className="text-white">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center bg-light">
            <td>1.</td>
            <td>test1@gmail.com</td>
            <td>02-04-2024</td>
          </tr>
          <tr className="text-center bg-light">
            <td>2.</td>
            <td> test2@gmail.com</td>
            <td>02-04-2024</td>
          </tr>
          <tr className="text-center bg-light">
            <td>3.</td>
            <td> test3@gmail.com</td>
            <td>02-04-2024</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default AllNewsLetters;
