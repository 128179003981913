import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Dropdown, Row } from "react-bootstrap";
import styled from "styled-components";
import img from "../../assets/prog.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const SeoPage = (props) => {
  const { history } = props;
  const [active, setActive] = React.useState(true);

  useEffect(() => {
    const createChart = (canvasId) => {
      const canvasElement = document.getElementById(canvasId);
      if (canvasElement) {
        const ctx = canvasElement.getContext("2d");
        return new Chart(ctx, {
          type: "line",
          data: {
            labels: ["FAB", "MAR", "APR", "MAY", "JUN"],
            datasets: [
              {
                label: "Traffice Reports",
                data: [50, 40, 90, 70, 30, 110],
                backgroundColor: "#f4e880",
                borderColor: "#FFD32B",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
      return null;
    };

    const linechart1 = createChart("linechat1");
    const linechart2 = createChart("linechat2");
    const linechart3 = createChart("linechat3");

    return () => {
      if (linechart1) linechart1.destroy();
      if (linechart2) linechart2.destroy();
      if (linechart3) linechart3.destroy();
    };
  }, [active]);

  useEffect(() => {
    const createChart = (canvasId) => {
      const canvasElement = document.getElementById(canvasId);
      if (canvasElement) {
        const ctx = canvasElement.getContext("2d");
        return new Chart(ctx, {
          type: "bar",
          data: {
            labels: [
              "Maths I",
              "Maths II",
              "Maths III",
              "Maths IV",
              "Maths V",
              "Maths VI",
            ],
            datasets: [
              {
                label: "Number of Students",
                data: [50, 40, 90, 70, 30, 110],
                backgroundColor: "#f4e880",
                borderColor: "#FFD32B",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
      return null;
    };

    const chart1 = createChart("studentCourceGraph1");
    const chart2 = createChart("studentCourceGraph2");

    return () => {
      if (chart1) chart1.destroy();
      if (chart2) chart2.destroy();
    };
  }, [active]);

  return (
    <OperationLayout>
      <StyledContainer>
        {/* <OperationLocation path={history} /> */}
        <h3>SEO Performance</h3>

        {active ? (
          <>
            <Row className="mt-5 shadow rounded-4 p-3">
              <Col md={3}>
                <h4>Net Profit</h4>
                <p className="m-0">$87,768</p>

                <p className="m-0 mt-2 text-success">+78.98%</p>
                <p className="m-0">Since last Month</p>
              </Col>
              <Col md={9}>
                <canvas id="linechat1"></canvas>
              </Col>
            </Row>

            <Row className="mt-5 align-items-center">
              <Col md={9}></Col>
              <Col md={3} className="d-flex justify-content-end ">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                  >
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Most search{" "}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {" "}
                      word courses
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row className="mt-4 shadow  rounded-4 p-3">
              <h6 className="m-0 fw-semibold mb-4">Top queries on Google</h6>
              <Col md={4}>
                <div className="shadow rounded-4 border d-flex align-items-center justify-content-center py-3">
                  <p className="m-0">Lorem, ipsum.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="shadow rounded-4 border d-flex align-items-center justify-content-center py-3">
                  <p className="m-0">Lorem, ipsum.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="shadow rounded-4 border d-flex align-items-center justify-content-center py-3">
                  <p className="m-0">Lorem, ipsum.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="shadow rounded-4 border d-flex align-items-center justify-content-center py-3 mt-4">
                  <p className="m-0">Lorem, ipsum.</p>
                </div>
              </Col>
            </Row>

            <Row className="mt-5 align-items-center">
              <Col md={9}>
                <h6 className="fw-bold m-0">Page Performance</h6>
              </Col>
              <Col md={3}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                  >
                    Filters
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Traffic</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Sales</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Behaviour</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Marketing</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={2} className="traffic_col position-relative ps-5">
                <h6 className="m-0">1 Day</h6>
              </Col>
              <Col md={2} className="traffic_col position-relative ps-5">
                <h6 className="m-0">5 Day</h6>
              </Col>
              <Col md={2} className="traffic_col position-relative ps-5">
                <h6 className="m-0">1 Month</h6>
              </Col>
              <Col md={2} className="traffic_col position-relative ps-5">
                <h6 className="m-0">6 Months</h6>
              </Col>
              <Col md={2} className="traffic_col position-relative ps-5">
                <h6 className="m-0">1 Year</h6>
              </Col>
              <Col md={2} className="position-relative ps-5">
                <h6 className="m-0">5 Years</h6>
              </Col>

              <Col md={12} className="mt-5">
                <canvas id="linechat2"></canvas>
              </Col>
            </Row>

            <Row className="mt-5 shadow bg-white  rounded-4 p-3">
              <Col
                md={6}
                className="d-flex flex-column justify-content-center align-items-center "
              >
                <div>
                  <h4>Net Profit</h4>
                  <p className="m-0">$87,768</p>

                  <p className="m-0 mt-2 text-success">+78.98%</p>
                  <p className="m-0">Since last Month</p>
                </div>
              </Col>
              <Col md={6}>
                <img src={img} alt="" />
              </Col>
            </Row>

            <button
              className="py-3 px-4 rounded-2 mt-4"
              onClick={() => setActive(false)}
            >
              Bot Page <FaAngleRight />
            </button>
          </>
        ) : (
          <>
            <Row className="mt-5">
              <Col>
                <h6
                  className="d-flex align-items-center  gap-3 fw-bold "
                  onClick={() => setActive(true)}
                >
                  {" "}
                  <FaAngleLeft /> Bot Page
                </h6>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={6}>
                <div className="shadow rounded-4 bg-white p-3">
                  <h6 className="m-0 fw-bold text_yellow">
                    Active Registrations
                  </h6>
                  <hr />
                  <div className="d-flex w-100 align-items-center gap-3">
                    <div>
                      <h3 className="m-0 mb-1">1,502</h3>
                      <p className="m-0">Users</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-21.2%</h6>
                    <div>
                      <h3 className="m-0 mb-1">9,373</h3>
                      <p className="m-0">Conversions</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                    <div>
                      <h3 className="m-0 mb-1">1,502</h3>
                      <p className="m-0">Per Month</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="shadow rounded-4 bg-white p-3">
                  <h6 className="m-0 fw-bold text_yellow">Bot Stastics</h6>
                  <hr />
                  <div className="d-flex w-100 align-items-center gap-3">
                    <div>
                      <h3 className="m-0 mb-1">1,502</h3>
                      <p className="m-0">Users</p>
                    </div>
                    <h6 className="fw-bold m-0 text-success ">+21.2%</h6>
                    <div>
                      <h3 className="m-0 mb-1">9,373</h3>
                      <p className="m-0">Conversions</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                    <div>
                      <h3 className="m-0 mb-1">1,502</h3>
                      <p className="m-0">Per Month</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={12}>
                <div className="shadow p-3 rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="m-0 fw-bold text_yellow">Leads Generated</h6>
                    <div className="d-flex align-items-center gap-3">
                      <button className="btns">Day</button>
                      <button className="btns">Week</button>
                      <button className="btns">Month</button>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex w-100 align-items-center gap-5">
                    <div>
                      <h2 className="m-0 mb-1">40%</h2>
                      <p className="m-0">Growth</p>
                    </div>
                    <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                    <div>
                      <h2 className="m-0 mb-1">12%</h2>
                      <p className="m-0">Loss</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                  </div>
                  <hr />
                  <canvas id="linechat3"></canvas>
                </div>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col md={6}>
                <div className="shadow p-3 rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="m-0 fw-bold text_yellow">
                      Revenue Generation
                    </h6>
                    <div className="d-flex align-items-center gap-3">
                      <button className="btns">Day</button>
                      <button className="btns">Week</button>
                      <button className="btns">Month</button>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex w-100 align-items-center gap-5">
                    <div>
                      <h2 className="m-0 mb-1">40%</h2>
                      <p className="m-0">Growth</p>
                    </div>
                    <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                    <div>
                      <h2 className="m-0 mb-1">12%</h2>
                      <p className="m-0">Loss</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                  </div>
                  <hr />
                  <canvas id="studentCourceGraph1"></canvas>
                </div>
              </Col>
              <Col md={6}>
                <div className="shadow p-3 rounded-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="m-0 fw-bold text_yellow">
                      Revenue Generation
                    </h6>
                    <div className="d-flex align-items-center gap-3">
                      <button className="btns">Day</button>
                      <button className="btns">Week</button>
                      <button className="btns">Month</button>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex w-100 align-items-center gap-5">
                    <div>
                      <h2 className="m-0 mb-1">40%</h2>
                      <p className="m-0">Growth</p>
                    </div>
                    <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                    <div>
                      <h2 className="m-0 mb-1">12%</h2>
                      <p className="m-0">Loss</p>
                    </div>
                    <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                  </div>
                  <hr />
                  <canvas id="studentCourceGraph2"></canvas>
                </div>
              </Col>
            </Row>
          </>
        )}
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .traffic_col::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0%;
    right: 0%;
    background-color: #000000;
  }

  .btns {
    background: var(--color-primary);
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    color: #404040;
  }

  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 200px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }
`;

export default SeoPage;
