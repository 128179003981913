import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";

const CommunitiesPage = (props) => {
  const { history } = props;
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h3>Community </h3>

      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Create New Community Page</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Create New Community Chat</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Add new Children to Existing Communities</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Set Monthly Price To Community</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Assign Teachers to Communities</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={12} className="shadow rounded-4 d-flex align-items-center justify-content-between py-3 px-3">
          <p className="m-0">Other Settings</p>
          <FaAngleRight className="fs-5" />
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default CommunitiesPage;
