import TeacherReportsPage from "./TeacherReports";

export const TeacherReportsPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/reports",
      exact: true,
      component: TeacherReportsPage
    }
  ]
};
