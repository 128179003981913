import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Card, Col, Table, Row } from "react-bootstrap";
import styled from "styled-components";
import { TiTick } from "react-icons/ti";
import { IoCloseSharp } from "react-icons/io5";
import ParentFooter from "../../components/ParentFooter";
import RescheduleModel from "./RescheduleModel";

const demo = [
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Satyam",
    course: "Hindi",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Hitesh",
    course: "English",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Ravi",
    course: "Maths",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "John",
    course: "English",
  },
];

const Pdemo = [
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Satyam",
    course: "Hindi",
    status: "Converted",
    review: 4.5,
    comments: "Good",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Hitesh",
    course: "English",
    status: "Loss",
    review: 4.5,
    comments: "Good",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "Ravi",
    course: "Maths",
    status: "Converted",
    review: 4.5,
    comments: "Good",
  },
  {
    parentId: "123456789",
    studentId: "235gny968975",
    studentName: "John",
    course: "English",
    status: "Loss",
    review: 4.5,
    comments: "Good",
  },
];

const DemoPage = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };
  return (
    <TeacherLayout>
      <h3>Demo</h3>
      <StyledContainer>
        <Row className="mt-4">
          <Col md={12}>
            <Table striped bordered hover>
              <thead>
                <tr className="bg-yellow ">
                  <th className="text-white">#</th>
                  <th className="text-white">Parent ID</th>
                  <th className="text-white">Student ID</th>
                  <th className="text-white">Student Name</th>
                  <th className="text-white">Course</th>
                  <th className="text-white">Action</th>
                </tr>
              </thead>
              <tbody>
                {demo.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.parentId}</td>
                    <td>{item.studentId}</td>
                    <td>{item.studentName}</td>
                    <td>{item.course}</td>

                    <td className="d-flex gap-3">
                      <TiTick
                        className="icon tick"
                        style={{ cursor: "pointer" }}
                      />
                      {/* <IoCloseSharp
                        className="icon close"
                        style={{ cursor: "pointer" }}
                      /> */}
                      <button onClick={toggleFeedbackModal}>Reschedule</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>

          <Col md={12} className="mt-3">
            <h4>Upcoming Demo</h4>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr className="bg-yellow ">
                  <th className="text-white">#</th>
                  <th className="text-white">Parent ID</th>
                  <th className="text-white">Student ID</th>
                  <th className="text-white">Student Name</th>
                  <th className="text-white">Course</th>
                  <th className="text-white">Action</th>
                </tr>
              </thead>
              <tbody>
                {demo.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.parentId}</td>
                    <td>{item.studentId}</td>
                    <td>{item.studentName}</td>
                    <td>{item.course}</td>

                    <td className="d-flex gap-3">
                      <span className="badge bg-warning text-black">
                        Upcoming
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>

          <Col md={12} className="mt-3">
            <h4>Previous Demo</h4>
            <Table striped bordered hover className="mt-4">
              <thead>
                <tr className="bg-yellow ">
                  <th className="text-white">#</th>
                  <th className="text-white">Parent ID</th>
                  <th className="text-white">Student ID</th>
                  <th className="text-white">Student Name</th>
                  <th className="text-white">Course</th>
                  <th className="text-white">Status</th>
                  <th className="text-white">Review</th>
                  <th className="text-white">Comments</th>
                </tr>
              </thead>
              <tbody>
                {Pdemo.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.parentId}</td>
                    <td>{item.studentId}</td>
                    <td>{item.studentName}</td>
                    <td>{item.course}</td>

                    <td className="d-flex gap-3">
                      {item.status === "Converted" ? (
                        <span className="badge bg-success">{item.status}</span>
                      ) : (
                        <span className="badge bg-danger">{item.status}</span>
                      )}
                    </td>
                    <td>{item.review}</td>
                    <td>{item.comments}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <RescheduleModel
          show={showFeedbackModal}
          toggleModal={toggleFeedbackModal}
        />
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .icon {
    height: 25px;
    width: 25px;
    border-radius: 10px;
  }

  .tick {
    color: green;
    background-color: rgba(0, 255, 0, 0.1);
  }

  .close {
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
  }

  button {
    padding: 3px 10px;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    background-color: transparent;
  }
`;

export default DemoPage;
