import { Link } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { IoMdStar } from "react-icons/io";

const Card1 = ({
  img,
  head,
  heart,
  bottomText1,
  bottomText2,
  close,
  accept,
  text1Icons,
  text1,
  text2Icons,
  text3Icons,
  text4Icons,
  text2,
  text3,
  text4,
  join,
  bottomText1Icon,
  bottomBtn,
  startBtn,
  feedback,
  toggleFeedbackModal,
  isTakeDemo,
  demoUniqueId,
  newButoon,
  onHoldClass,
  clickHandler,
  pay,
  paid,
  youTubeLink,
  Age,
  type,
  schedule,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="parent_cards1 mb-3 border rounded-4 overflow-hidden  bg-white shadow pb-2 w-100 ms-3">
      <div className="parent_cards1_top d-flex align-items-center justify-content-center p-4 ">
        <img
          style={{ width: '100%' }}
          onClick={clickHandler ? () => clickHandler() : null}
          src={img}
          className="rounded-4"
          alt=""
        />
      </div>
      <div className="parent_cards1_bottom mt-1 px-4 position-relative">
        <div className="d-flex align-items-center">
          <div className="col">
            {head && (
              <h5
                onClick={clickHandler ? () => clickHandler() : null}
                className="fw-bold"
              >
                {head}
              </h5>
            )}
            <h6>Student Name</h6>
            {demoUniqueId && <p>demo#00njknasd89719uuij</p>}
            {youTubeLink && (
              <Link
                href={youTubeLink}
                className="text-primary text-decoration-underline"
                target="_blank"
                rel="noreferrer"
              >
                Go to YouTube
              </Link>
            )}
          </div>
          {heart && (
            <div className="col d-flex justify-content-end">{heart}</div>
          )}
        </div>

        {text1Icons && text1 && (
          <p className="mt-3 mb-2 d-flex align-items-center">
            {text1Icons} {text1}
          </p>
        )}
        {text2Icons && text2 && (
          <p className="mb-2 d-flex align-items-center">
            {text2Icons} {text2}
          </p>
        )}

        {text3Icons && text3 && (
          <p className="mb-2 d-flex align-items-center">
            {text3Icons} {text3}
          </p>
        )}

        {text4Icons && text4 && (
          <p className="mb-2 d-flex align-items-center">
            {text4Icons} {text4}
          </p>
        )}

        <div className="mt-3 d-flex gap-3 align-items-center">
          <div className="parent_user_img">
            <img
              src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
              alt=""
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="small_text line_height m-0 mb-1">XYZ</p>
              <p className="small_text line_height m-0 mb-1">Group Class</p>
              <p className="small_text line_height m-0 d-flex align-items-center">
                <IoMdStar className="me-1 text_yellow" />
                4.3
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {onHoldClass && (
                <Chip
                  onClick={handleButtonClick}
                  className="ms-3 border-warning text-warning"
                  style={{ cursor: "pointer" }}
                  label="Hold"
                  variant="outlined"
                />
              )}
            </div>
          </div>
          {startBtn && (
            <button className="start_btn_card1 py-1 px-3 border-0">
              {startBtn}
            </button>
          )}
          {join && <p className="m-0 fw-bold ms-5">{join}</p>}
          {feedback && toggleFeedbackModal && (
            <Button
              onClick={toggleFeedbackModal}
              className="ms-5 mt-2 p-1"
              variant="outlined"
              size="small"
            >
              Feed Back
            </Button>
          )}
        </div>

        {pay && (
          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
            <p className="m-0">
              Course Fees:- <b>{pay}/-</b>{" "}
            </p>
            <button
              className="py-1 px-2 rounded-5 border-0"
              style={{ background: "var(--yellow-color)" }}
            >
              Pay
            </button>
          </div>
        )}

        <div className="d-flex align-items-center gap-2">
          {paid && (
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
              <div
                style={{ background: "green", fontSize: 10 }}
                className="py-1 m-0 px-2 text-white rounded-5"
              >
                Paid
              </div>
            </div>
          )}

          {type && (
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
              <div
                style={{ background: "green", fontSize: 10 }}
                className="py-1 m-0 px-2 text-white rounded-5"
              >
                {type}
              </div>
            </div>
          )}

          {schedule && (
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
              <div
                style={{ background: "green", fontSize: 10 }}
                className="py-1 m-0 px-2 text-white rounded-5"
              >
                {schedule}
              </div>
            </div>
          )}
        </div>

        <div className="mt-3 d-flex align-items-center justify-content-between">
          <p className="m-0 fw-bold d-flex align-items-center">
            {bottomText1Icon && bottomText1Icon}
            {bottomText1 && bottomText1}
          </p>
          {Age && <p className="m-0 fw-bold">{Age}+ Years</p>}
          <p className="m-0 fw-bold">{bottomText2 && bottomText2}</p>
          {newButoon && <p className="m-0 fw-bold"> Resume</p>}
          {bottomBtn && <button className="gift_btn">{bottomBtn}</button>}
        </div>

        {close && accept && (
          <div className="card1_icons">
            <div className="parent_decline mb-2 ms-4 d-flex align-items-center justify-content-center">
              {close}
            </div>
            <div className="parent_accept ms-4 d-flex align-items-center justify-content-center">
              {accept}
            </div>
          </div>
        )}

        {isTakeDemo && (
          <>
            <Row className="d-flex align-items-center">
              <Col md={4}>
                <Form.Group controlId="classSelect">
                  <Form.Control className="shadow rounded-5" as="select">
                    <option selected disabled>
                      Enroll
                    </option>
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                    <option value={24}>24</option>
                    <option value={30}>30</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Button className="shadow rounded-5" variant="outlined">
                  proceed
                </Button>
              </Col>
            </Row>
          </>
        )}

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="small"
          style={{ zIndex: "1111", marginTop: "20vh" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hold Class</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Do you want to hold this class?</p>
            <div className="mb-3">
              <label htmlFor="start-date" className="form-label">
                Start Date
              </label>
              <input type="date" className="form-control" id="start-date" />
            </div>
            <div className="mb-3">
              <label htmlFor="end-date" className="form-label">
                End Date
              </label>
              <input type="date" className="form-control" id="end-date" />
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              className="bg-danger text-white me-3 border-0"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button variant="outlined" onClick={handleCloseModal}>
              Hold
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Card1;
