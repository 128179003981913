import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Badge, Col, Form, Row, Table } from "react-bootstrap";

const ParentAccount = () => {
  return (
    <AccountLayout>
      <h3>Parent Account</h3>

      <Row className="mt-4">
        <Col md={4}>
          <Form.Select aria-label="Default select example">
            <option>Filter</option>
            <option value="1">By Parent</option>
            <option value="2">By Child</option>
            <option value="3">By Teacher</option>
          </Form.Select>
        </Col>
      </Row>

      <Table className="mt-4" striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Parent ID</th>
            <th>Parent Name</th>
            <th>Teacher ID</th>
            <th>Teacher Name</th>
            <th>Child ID</th>
            <th>Child Name</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Subject</th>
            <th>Grade</th>
            <th>Board</th>
            <th>Type</th>
            <th>Start Date</th>
            <th>Last Date</th>
            <th>Per Class Rate That Parent Has Pay</th>
            <th>Total Class</th>
            <th>Total Charges by Razorpay</th>
            <th>Gift Card Use</th>
            <th>Reffrel ID</th>
            <th>Reffrel Person Name</th>
            <th>Total Class Conducted Till Date</th>
            <th>Total No. of Class Parent Pay For</th>
            <th>Total Class Remaining</th>
            <th>Total No of Amount Pay to Teacher Till Date</th>
            <th>Last Date of Payment</th>
            <th>Extended Class</th>
            <th>Extended Class By Day</th>
            <th>Profit</th>
            <th>Report</th>
            <th>Comment</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(1)
            .map((_, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>PAR123</td>
                <td>Parent Name</td>
                <td>TEA675</td>
                <td>Teacher Name</td>
                <td>CHI597</td>
                <td>Child Name</td>
                <td>C45958</td>
                <td>Course Name</td>
                <td>Maths</td>
                <td>Grade</td>
                <td>CBSE</td>
                <td>One-On-One</td>
                <td>12 Jan 2024</td>
                <td>30 Jan 2024</td>
                <td>₹ 4500</td>
                <td>30</td>
                <td>₹ 100</td>
                <td>
                  <Badge variant="secondary">GIF575768</Badge> <br />
                  <Badge variant="secondary">₹ 238</Badge> <br />
                  <Badge variant="secondary">₹ 12 Fab 2024</Badge>
                </td>
                <td>REF76097932</td>
                <td>John Deo</td>
                <td>12</td>
                <td>30</td>
                <td>18</td>
                <td>₹ 2000</td>
                <td>12 Jan 2024</td>
                <td>
                  <Badge variant="success">Yes</Badge>
                </td>
                <td>10</td>
                <td>₹ 2000</td>
                <td>Report</td>
                <td>This is a comment</td>
                <td>
                  <button
                    className="btn btn-sm px-3 rounded-4 text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Send for Approval
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AccountLayout>
  );
};

export default ParentAccount;
