import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row } from "react-bootstrap";

import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";

import geoUrl from "./features.json";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#ffedea", "#ff5233"]);

const MarketingPage = (props) => {
  const { history } = props;

  useEffect(() => {
    const createChart = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Maths I",
            "Maths II",
            "Maths III",
            "Maths IV",
            "Maths V",
            "Maths VI",
          ],
          datasets: [
            {
              label: "Number of Students",
              data: [50, 40, 90, 70, 30, 110],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const chart1 = createChart("studentCourceGraph1");
    // const chart2 = createChart("studentCourceGraph2");

    return () => {
      chart1.destroy();
      //   chart2.destroy();
    };
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`./vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h3>Marketing</h3>

      <Row className="mt-5 shadow rounded-4 p-3">
        <Col md={12}>
          <div className="d-flex align-items-center justify-content-between ">
            <h6 className="m-0 text_yellow">Website Traffic vs Conversions</h6>
            <div className="d-flex align-items-center gap-3 ">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="date"
                  placeholder="Appointment Title"
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="time"
                  placeholder="Appointment Title"
                />
              </Form.Group>
            </div>
          </div>
          <hr />
          <canvas id="studentCourceGraph1"></canvas>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h5 className="m-0">Location of the visitors</h5>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <ComposableMap
            projectionConfig={{
              rotate: [-10, 0, 0],
              scale: 147,
            }}
          >
            <Sphere stroke="#6600ff" strokeWidth={0.5} />
            <Graticule stroke="#ff00c8" strokeWidth={0.5} />
            {data?.length > 0 && (
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const d = data.find((s) => s.ISO3 === geo.id);
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={d ? colorScale(d["2017"]) : "#ffae00"}
                      />
                    );
                  })
                }
              </Geographies>
            )}
          </ComposableMap>
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default MarketingPage;
