import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { IoMdStar } from "react-icons/io";
import { PiStudent } from "react-icons/pi";
import styled from "styled-components";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import FeedbackModal from "./FeedbackModal";

const CardCourse = ({
  editIcon,
  students,
  date,
  startCount,
  head,
  img,
  view,
  deleteIcon,
  feedback,
  reject,
  success,
  bottomRow,
  viewDetails,
  feedbackP,
  joinClass,
  viewHW,
}) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal)
  }
  return (
    <StyledContainer>
      <Card className="shadow rounded-4 py-3 px-3 position-relative">
        <img src={img} alt="" />
        <div className="d-flex align-items-center justify-content-between">
          {head && <h5 className="mt-3">{head}</h5>}
          {startCount && (
            <div className="d-flex align-items-center mt-2">
              <span className="text_yellow">
                <IoMdStar className="text_yellow fs-5 me-1" />
              </span>
              <span>{startCount}</span>
            </div>
          )}
        </div>
        {date && (
          <p className="m-0 d-flex align-items-center mt-2">
            <FaRegCalendarCheck className="text_yellow fs-5 me-2" /> {date}{" "}
          </p>
        )}
        {students && (
          <p className="m-0 d-flex align-items-center mt-2">
            <PiStudent className="text_yellow fs-5 me-2" /> {students}{" "}
          </p>
        )}

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3 mt-3">
            <div className="card_user_img">
              <img
                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                alt=""
              />
            </div>
            <div>
              <p className="m-0 small_text line_height">XYZ</p>
              <p className="m-0 small_text line_height">9 Years Onwards</p>
              <p className="m-0 small_text line_height">Group Class</p>
            </div>
          </div>
          {view && <p className="m-0 mt-3 fw-bold">View</p>}
        </div>

        {bottomRow && (
          <div className="d-flex align-items-center justify-content-between mt-2">
            {success && (
              <p className="m-0 mt-3 fw-bold text-success">{success}</p>
            )}

            {reject && <p className="m-0 mt-3 fw-bold text-danger">{reject}</p>}
            {feedback && <p onClick={()=>setShowFeedbackModal(true)} className="m-0 mt-3 fw-bold">{feedback}</p>}
          </div>
        )}

        {viewHW && joinClass && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <p className="m-0 fw-bold">{viewHW}</p>
            <button className="py-1 px-2 rounded-5 btns text-white">{joinClass}</button>
          </div>
        )}

        {feedbackP && viewDetails && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <p className="m-0 fw-bold">{feedbackP}</p>
            <button className="py-1 px-2 rounded-5 btns text-white">View Details</button>
          </div>
        )}

        {editIcon && <MdEdit className="fs-3 edit text_yellow" />}
        {deleteIcon && <MdDeleteOutline className="fs-3 delete text-danger" />}
      </Card>

      <FeedbackModal show={showFeedbackModal}
        toggleModal={toggleFeedbackModal}  />

    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .card_user_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .edit {
    position: absolute;
    top: 60%;
    right: 8%;
    cursor: pointer;
  }
  .delete {
    position: absolute;
    top: 75%;
    right: 8%;
    cursor: pointer;
  }
  .btns{
    background: var(--color-primary);
    border: none;
  }
`;

export default CardCourse;
