import React from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Row, Col, Card } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";
import styled from "styled-components";
import ParentFooter from "../../components/ParentFooter";
import { FaWhatsapp } from "react-icons/fa";

const VacancyPage = () => {
  return (
    <TeacherLayout>
      <h3>Vacancy</h3>
      <StyledContainer>
        <Row className="mt-5">
          <Col>
            <h5>1. Subject/ Courses</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <CustomeCard className={"Physics Class 11"} courseCount={"06"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Chemistry Class 12"} courseCount={"04"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Social Class 10"} courseCount={"02"} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h5>2. Teacher</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <CustomeCard className={"Physics Class 11"} courseCount={"06"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Chemistry Class 12"} courseCount={"04"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Social Class 10"} courseCount={"02"} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h5>3. Employees</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <CustomeCard className={"Physics Class 11"} courseCount={"06"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Chemistry Class 12"} courseCount={"04"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Social Class 10"} courseCount={"02"} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h5>4. Intern</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <CustomeCard className={"Physics Class 11"} courseCount={"06"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Chemistry Class 12"} courseCount={"04"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Social Class 10"} courseCount={"02"} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h5>5. Specific Qualification</h5>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={4}>
            <CustomeCard className={"Physics Class 11"} courseCount={"06"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Chemistry Class 12"} courseCount={"04"} />
          </Col>
          <Col md={4}>
            <CustomeCard className={"Social Class 10"} courseCount={"02"} />
          </Col>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .copy {
    border: 1px solid #b6b6b6;
    padding: 0.5rem 1rem;
    border-radius: 10px;

    > svg {
      cursor: pointer;
    }
  }
  button {
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
    background: transparent;
    border-radius: 100px;
    padding: 0.3rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1rem;
    width: fit-content;
  }
`;
export default VacancyPage;

const CustomeCard = ({ className, courseCount }) => {
  return (
    <Card className="shadow py-3 px-3 rounded-3 border">
      <h6 className="text-center">{className}</h6>
      <div className="d-flex justify-content-center">

        <div className="flex-grow-1">
          <h6 className="text-center">Vacancies</h6>
          <h1 className="mb-1 text-center mt-3">{courseCount}</h1>
        </div>
      </div>
      {/* <p className="m-0 mb-2">Refer:</p>
      <div className="copy d-flex align-items-center justify-content-between">
        <p className="m-0">learniee.uniquename.subj.id</p>
        <MdOutlineContentCopy className="fs-5 text_yellow" />
      </div> */}
      <div className="d-flex align-items-center gap-1 justify-content-center">
        <button>Apply</button>
        <button>Share</button>
        <div className="p-2 rounded-5 border border-primary mt-3 cursor-pointer ms-3">
          <FaWhatsapp size={22} color="blue" />
        </div>
      </div>
    </Card>
  );
};

