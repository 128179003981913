import ClientAccountDetailsPage from "./AccountDetailsPage";

export const ClientAccountDetailsPageConfig = {
  routes: [
    {
      path: "/account/dashboard/client/details",
      exact: true,
      component: ClientAccountDetailsPage
    }
  ]
};
