// src/slices/tokenSlice.js
import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: { value: null, role: null }, // Initial token value as null
  reducers: {
    storeToken: (state, action) => {
      // Action to store the token
      state.value = action.payload;
    },
    storeRole: (state, action) => {
      // Action to store the role
      state.role = action.payload;
    },
    removeToken: (state) => {
      // Action to remove the token
      state.value = null;
      state.role = null;
    },
  },
});

export const { storeToken, removeToken, storeRole } = tokenSlice.actions;
export default tokenSlice.reducer;
