import React, { useState } from "react";
import {
  Col,
  Row,
  Container,
  InputGroup,
  FormControl,
  Form,
  Table,
} from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { IoSearch } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";
import { Button } from "@mui/material";
import { RiFilterOffFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { MdNavigateNext } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    parentName: "Parent 1",
    parentId: "P123456",
    childName: "Child 1",
    childId: "C123456",
    teacherName: "Teacher 1",
    teacherId: "T123456",
    grade: "Grade 1",
    board: "Board 1",
    subject: "Subject 1",
    days: ["Monday", "Tuesday", "Wednesday"],
    timing: "10:00 AM - 12:00 PM",
    classStatus: "Active",
    classCount: 12,
    completedMonth: 3,
    profit: "$2200.00",
    parentACStatus: "Active",
    EnrollDate: "14th Jul 2023, 18:54",
    EndDate: "14th Jul 2023, 18:54",
    ExtDate: "14th Jul 2023, 18:54",
    attendence: 13,
    Hw: 12,
    Test: 2,
    rating: 4.5,
    frequency: "Weekly",
  },
  {
    id: 2,
    parentName: "Parent 2",
    parentId: "P123456",
    childName: "Child 2",
    childId: "C123456",
    teacherName: "Teacher 2",
    teacherId: "T123456",
    grade: "Grade 2",
    board: "Board 2",
    subject: "Subject 2",
    days: ["Monday", "Tuesday", "Wednesday"],
    timing: "10:00 AM - 12:00 PM",
    classStatus: "Active",
    classCount: 12,
    completedMonth: 3,
    profit: "$2200.00",
    parentACStatus: "Active",
    EnrollDate: "14th Jul 2023, 18:54",
    EndDate: "14th Jul 2023, 18:54",
    ExtDate: "14th Jul 2023, 18:54",
    attendence: 13,
    Hw: 12,
    Test: 2,
    rating: 4.5,
    frequency: "Weekly",
  },
  {
    id: 3,
    parentName: "Parent 3",
    parentId: "P123456",
    childName: "Child 3",
    childId: "C123456",
    teacherName: "Teacher 3",
    teacherId: "T123456",
    grade: "Grade 3",
    board: "Board 3",
    subject: "Subject 3",
    days: ["Monday", "Tuesday", "Wednesday"],
    timing: "10:00 AM - 12:00 PM",
    classStatus: "Active",
    classCount: 12,
    completedMonth: 3,
    profit: "$2200.00",
    parentACStatus: "Active",
    EnrollDate: "14th Jul 2023, 18:54",
    EndDate: "14th Jul 2023, 18:54",
    ExtDate: "14th Jul 2023, 18:54",
    attendence: 13,
    Hw: 12,
    Test: 2,
    rating: 4.5,
    frequency: "Weekly",
  },
  {
    id: 4,
    parentName: "Parent 4",
    parentId: "P123456",
    childName: "Child 4",
    childId: "C123456",
    teacherName: "Teacher 4",
    teacherId: "T123456",
    grade: "Grade 4",
    board: "Board 4",
    subject: "Subject 4",
    days: ["Monday", "Tuesday", "Wednesday"],
    timing: "10:00 AM - 12:00 PM",
    classStatus: "Active",
    classCount: 12,
    completedMonth: 3,
    profit: "$2200.00",
    parentACStatus: "Active",
    EnrollDate: "14th Jul 2023, 18:54",
    EndDate: "14th Jul 2023, 18:54",
    ExtDate: "14th Jul 2023, 18:54",
    attendence: 13,
    Hw: 12,
    Test: 2,
    rating: 4.5,
    frequency: "Weekly",
  },
];

const ParentDatabase = () => {
  const navigate = useNavigate();
  const handleExpandRow = (rowIndex) => {
    // setExpandedRow((prevRow) => (prevRow === rowIndex ? null : rowIndex));
    navigate("/operation/parent/142512001");
  };

  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h5>Parent Database</h5>
      <Row className="align-items-center mt-3 mb-3">
        <Col md={3}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-5">
              <option selected disabled>
                select teachers type
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>InActive</option>
              <option value={"2"}>Blocked</option>
              <option value={"2"}>Deleted</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3} className="d-flex align-items-center justify-content-start">
          <Form.Group controlId="searchInput">
            <InputGroup>
              <FormControl
                className="border-yellow shadow border-right-0"
                style={{
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                }}
                type="text"
                placeholder="Search"
              />
              <InputGroup.Text
                style={{
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                }}
                className="border-left-0 border-yellow shadow"
              >
                <BsSearch />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Button
            className="ms-3 text-white teacher-button rounded-5 shadow border-0"
            variant="outlined"
            startIcon={<RiFilterOffFill />}
          >
            <div className="d-none d-md-block">Apply Filters</div>
          </Button>
        </Col>
        <Col md={3} className="d-flex justify-content-end">
          <Button
            className="ms-3 text-white teacher-button rounded-5 shadow border-0"
            variant="outlined"
            startIcon={<IoMdAdd />}
          >
            Add
          </Button>
        </Col>
      </Row>

      <Table
        responsive
        striped
        className="mt-5"
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
          minWidth: 2300,
        }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S.No.</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Parent Id</th>
            <th className="text-white">Child Name</th>
            <th className="text-white">Child Id</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Teacher Id</th>
            <th className="text-white">Grade</th>
            <th className="text-white">Board</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Days</th>
            <th className="text-white">Timing</th>
            <th className="text-white">Class Status</th>
            <th className="text-white">Class Count</th>
            <th className="text-white">Completed Month</th>
            <th className="text-white">Profit</th>
            <th className="text-white">Parent AC Status</th>
            <th className="text-white">Enroll Date</th>
            <th className="text-white">End Date</th>
            <th className="text-white">Ext Date</th>
            <th className="text-white">Attendence</th>
            <th className="text-white">HW</th>
            <th className="text-white">Test</th>
            <th className="text-white">Rating</th>
            <th className="text-white">Frequency</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="text-center bg-light">
              <td>{index + 1}</td>
              <td>{row.parentName}</td>
              <td>{row.parentId}</td>
              <td>{row.childName}</td>
              <td>{row.childId}</td>
              <td>{row.teacherName}</td>
              <td>{row.teacherId}</td>
              <td>{row.grade}</td>
              <td>{row.board}</td>
              <td>{row.subject}</td>
              <td>{row.days.join(", ")}</td>
              <td>{row.timing}</td>
              <td>{row.classStatus}</td>
              <td>{row.classCount}</td>
              <td>{row.completedMonth}</td>
              <td>{row.profit}</td>
              <td>{row.parentACStatus}</td>
              <td>{row.EnrollDate}</td>
              <td>{row.EndDate}</td>
              <td>{row.ExtDate}</td>
              <td>{row.attendence}</td>
              <td>{row.Hw}</td>
              <td>{row.Test}</td>
              <td>{row.rating}</td>
              <td>{row.frequency}</td>
              <td>
                <Button
                  onClick={() => handleExpandRow(index)}
                  className="text-white teacher-button rounded-5 shadow border-0"
                  variant="outlined"
                  startIcon={<MdNavigateNext />}
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default ParentDatabase;
