import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import "./FAQParent.css";
import ParentFooter from "../../components/ParentFooter";
import { FaAngleDown } from "react-icons/fa";
import { MdNavigateNext } from "react-icons/md";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";
const HomeFaqImg = require("../../assets/home-faq-image.png");
function FAQParent() {
  const [active1, setactive1] = useState(false);
  const [active2, setactive2] = useState(false);
  const [active3, setactive3] = useState(true);

  const [allFaqs, setAllFaqs] = useState([]);

  const fetchFaqs = async () => {
    try {
      let res = await MyAPI.GET("/common/show-faq?keyword=");
      let { res_type, message, data, error } = res.data || res;

      if (res_type === "success") {
        setAllFaqs(data);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const [faqs, setFaqs] = useState([
    // {
    //   question: 'Teacher Related FAQ',
    //   answer: 'To enroll as a teacher, you need to...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'What qualifications do I need?',
    //       answer: 'You need to have...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'How do I create my teacher profile?',
    //       answer: 'You can create your teacher profile by...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: ' Payment Related FAQ',
    //   answer: 'To enroll in a course, you can...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Can I enroll in multiple courses at once?',
    //       answer: 'Yes, you can enroll in multiple courses...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'Is there a deadline for course enrollment?',
    //       answer: 'The deadline for course enrollment...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: 'Class Related FAQ',
    //   answer: 'Once you have enrolled in a course, you can...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Can I access my courses on mobile devices?',
    //       answer: 'Yes, you can access your courses...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'Do I need to download any software?',
    //       answer: 'No, you can access your courses...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: ' Live Related FAQ',
    //   answer: 'To schedule a class, you need to...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Can I reschedule a class?',
    //       answer: 'Yes, you can reschedule...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'How far in advance can I schedule a class?',
    //       answer: 'You can schedule a class up to...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: 'Technical FAQ',
    //   answer: 'We accept various payment methods including...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Do you offer installment plans?',
    //       answer: 'Yes, we offer installment plans for...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'Is my payment information secure?',
    //       answer: 'Yes, we use secure payment processing...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: 'Offers and Coupons FAQ',
    //   answer: 'We accept various payment methods including...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Do you offer installment plans?',
    //       answer: 'Yes, we offer installment plans for...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'Is my payment information secure?',
    //       answer: 'Yes, we use secure payment processing...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: 'Lerniee Mall FAQ',
    //   answer: 'We accept various payment methods including...',
    //   isOpen: false,
    //   nestedFaqs: [
    //     {
    //       question: 'Do you offer installment plans?',
    //       answer: 'Yes, we offer installment plans for...',
    //       isOpen: false,
    //     },
    //     {
    //       question: 'Is my payment information secure?',
    //       answer: 'Yes, we use secure payment processing...',
    //       isOpen: false,
    //     },
    //   ],
    // },
    // {
    //   question: "General FAQ",
    //   answer: "We accept various payment methods including...",
    //   isOpen: false,
    //   nestedFaqs: [
    //     allFaqs.map((faq) => {
    //       return {
    //         question: faq?.title,
    //         answer: faq?.description,
    //         isOpen: false,
    //       };
    //     }),
    //   ],
    // },
    // Add more FAQs as needed
  ]);

  const toggleAccordion = (index) => {
    const newFaqs = [...faqs];
    newFaqs[index].isOpen = !newFaqs[index].isOpen;
    setFaqs(newFaqs);
  };

  const toggleNestedAccordion = (parentIndex, nestedIndex) => {
    const newFaqs = [...faqs];
    newFaqs[parentIndex].nestedFaqs[nestedIndex].isOpen =
      !newFaqs[parentIndex].nestedFaqs[nestedIndex].isOpen;
    setFaqs(newFaqs);
  };
  return (
    <OftadehLayout>
      <Container
        fluid
        className="home-faq-container"
        style={{ marginTop: "-1%" }}
      >
        <Row>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className=" d-flex align-items-start justify-content-center p-5"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <h5>FAQ's</h5>
              {/* <OftadehBreadcrumbs path={history} /> */}
              <p className="text-start" style={{ width: "80%" }}>
                Have questions? Here You’ll find the answer most valued by our
                partners, alongs with access to step-by-step instructions and
                support.
              </p>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center home-faq-img-section"
          >
            <div className="home-faq-img-area">
              <img src={HomeFaqImg} className="" alt="" />
            </div>
          </Col>
        </Row>
      </Container>

      <Row className="mt-4 mb-4">
        <Col
          md={12}
          className="d-flex align-items-center justify-content-between"
        >
          <h4 className="fw-bold">Frequently Asked Questions</h4>
          <Form.Group>
            <Form.Control
              type="text"
              className="shadow rounded-5"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Row>

      <Container className="px-3 py-2">
        <div className="">
          {/* {faqs.map((faq, index) => (
            <div key={index}>
              <Col
                md={12}
                className={`d-flex align-items-center justify-content-between cursor-pointer ${
                  faq.isOpen ? "pt-4" : "border-bottom py-4 pb-0"
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <h6>{faq.question}</h6>
                {faq.isOpen ? (
                  <FaAngleDown size={22} />
                ) : (
                  <MdNavigateNext size={22} />
                )}
              </Col>
              {faq.isOpen && (
                <>
                  <hr />
                  {faq.nestedFaqs.map((nestedFaq, nestedIndex) => (
                    <div key={nestedIndex}>
                      <Col
                        md={12}
                        className="d-flex align-items-center justify-content-between cursor-pointer"
                        onClick={() =>
                          toggleNestedAccordion(index, nestedIndex)
                        }
                      >
                        <h6>{nestedFaq.question}</h6>
                        {nestedFaq.isOpen ? (
                          <FaAngleDown size={22} />
                        ) : (
                          <MdNavigateNext size={22} />
                        )}
                      </Col>
                      {nestedFaq.isOpen && (
                        <Col
                          md={12}
                          className="d-flex align-items-center justify-content-between cursor-pointer "
                        >
                          <p>{nestedFaq.answer}</p>
                        </Col>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          ))} */}

          {allFaqs && allFaqs?.length > 0 ? (
            allFaqs.map((faq, index) => (
              <Row key={index} className="mb-3">
                <Col md={12}>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{faq.title}</Accordion.Header>
                      <Accordion.Body>{faq.description}</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            ))
          ) : (
            <h6 className="mt-5 text-center">No FAQs found</h6>
          )}
        </div>
      </Container>

      <ParentFooter />
    </OftadehLayout>
  );
}

export default FAQParent;
