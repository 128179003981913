// Search
import employeePage from "./employeePage";

export const EmployeePageConfig = {
  routes: [
    {
      path: "/operation/employee",
      exact: true,
      component: employeePage
    },
  ]
};