import CoursePage from "./CourcesPage";
import CourseProgress from "./CourseProgress";
import CourseWithActive from "./CourseWithActive";
import CourseWithId from "./CourseWithId";
import StudentProfile from "./StudentProfile";
import TranscriptPage from "./TranscriptPage";

export const CoursePageConfig = {
  routes: [
    {
      path: "/teacher/courses",
      exact: true,
      component: CoursePage
    },
    {
      path: "/teacher/course/:id",
      exact: true,
      component: CourseWithId
    },
    {
      path: "/teacher/course/:id/:classType",
      exact: true,
      component: CourseWithActive
    },
    {
      path: "/teacher/transcript",
      exact: true,
      component: TranscriptPage
    },
    {
      path: "/teacher/courses/:id/course-id",
      exact: true,
      component: CourseProgress
    },
    {
      path: "/teacher/class/student-profile",
      exact: true,
      component: StudentProfile
    },
  ]
};
