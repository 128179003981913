// OPSBanks
import OPSBanksPage from "./OPSBanksPage";

export const OPSBanksPageConfig = {
  routes: [
    {
      path: "/operation/OPSBanks",
      exact: true,
      component: OPSBanksPage
    }
  ]
};
