import FavoritesPage from "./Favorotes";

export const FavoritesConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/favorites",
      exact: true,
      component: FavoritesPage,
    },
  ],
};
