import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Row, Container, Modal } from "react-bootstrap";
import { IoCalendarClearOutline, IoStarSharp } from "react-icons/io5";
import { GiCycle } from "react-icons/gi";
import { IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import { FaAngleRight, FaUsers } from "react-icons/fa";
import { MdCastForEducation, MdOutlineStarBorderPurple500, MdOutlineTopic } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { GoStopwatch } from "react-icons/go";
import { SiSinglestore } from "react-icons/si";
import { CiHeart } from "react-icons/ci";
import { VscLiveShare } from "react-icons/vsc";
import { FaChalkboardUser } from "react-icons/fa6";
import screenShot from "../../assets/online-tutorials-concept_52683-37480.avif";
import BlogCommentImg1 from "../../assets/blog-comment-01.png";

const CourseWithId = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleYes = () => {
    setShow(false);
    setShow2(false);
    alert("Your request has been sent successfully to the admin.");
  };

  return (
    <OftadehLayout>
      <h5>Courses {">"} laravel pro</h5>
      <Container className="p-3">
        <Row>
          <Col md={8}>
            <img
              width={"100%"}
              style={{ maxHeight: "50vh", objectFit: "cover" }}
              height={"100%"}
              className="rounded-3"
              src={
                "https://themegenix.com/demo/skillgro/assets/img/courses/courses_details.jpg"
              }
              alt=""
            />

            <div className="mt-4 d-flex align-items-center justify-content-between">
              {/* <div className="d-flex align-items-center gap-3">
                <span className="tag px-3 py-1 rounded-5 bg-body-tertiary shadow">
                  Devlopment
                </span>
                <MdOutlineStarPurple500 size={22} color="#FFD846" /> ( 4.5
                Reviews )
              </div> */}
              <h3>Course Name</h3>
              <div className="d-flex align-items-center gap-3">
                <button
                  onClick={() => navigate("/parent/dashboard/pages/transcript")}
                  className="ms-3 mb-3 new-button"
                >
                  View Transcript & Recording
                </button>
                <button
                  onClick={() => navigate("/parent/dashboard/courses/142512/course-id")}
                  className="ms-3 mb-3 new-button"
                >
                  My Progress
                </button>
                <button className="new-button ms-3 mb-3" onClick={handleShow}>
                  Hold Course
                </button>
              </div>
            </div>

            <div className="d-flex align-items-center mt-4">
              <img
                src={screenShot}
                className="border border-warning"
                width={"60px"}
                height={"60px"}
                style={{ borderRadius: "50%" }}
                alt=""
              />
              <span className="teacher-name ms-3">
                <b>By John Smith</b>
                <br />
                <b><IoStarSharp className="text_yellow me-2 fs-6" /> 4.5</b>
                <br />
                <Link to="#">
                  <span className="text-decoration-underline text-primary">
                    View Profile
                  </span>
                </Link>
              </span>
              <div className="d-block ms-auto">
                <span className="class-room-dated-flex align-items-center justify-content-center ms-3">
                  <IoCalendarClearOutline size={22} /> &nbsp;{" "}
                  <span>27 Jan 2022</span>
                </span>
                <span className="number-of-students align-items-center justify-content-center ms-3">
                  <MdCastForEducation size={22} /> &nbsp;{" "}
                  <span>2,250 Students Enrolled</span>
                </span>
              </div>
            </div>

            <Container
              className="shadow rounded-3 mt-4 px-5 py-4"
              style={{ border: "1px solid #EEEEEE" }}
            >
              <h5>Course Description</h5>
              <p>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.dolor sit amet,
                consectetur adipiscing elited do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </p>
              <h5>What you'll learn in this course?</h5>
              <p>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.
              </p>

              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>Work with color & Gradients & Grids</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>All the useful shortcuts</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>Be able to create Flyers, Brochures, Advertisements</b>
              </Col>
              <Col className="mt-3">
                {" "}
                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                  <FaAngleRight />
                </span>{" "}
                <b>How to work with Images & Text</b>
              </Col>
              <br />
              <p>
                Morem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.Dorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magn.
              </p>
            </Container>
            <Col className="mt-4 mb-2">
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
              <IconButton aria-label="delete">
                <MdOutlineStarBorderPurple500 />
              </IconButton>
            </Col>
            <Col className="d-flex  mb-4 align-items-center gap-1">
              <input
                type="text"
                placeholder="Comment"
                className="py-2 px-3 border-0"
                style={{ width: "100%", background: "rgb(229 229 229)" }}
              />
              <button
                className="py-2 px-3 text-white border-0"
                style={{ background: "var(--color-primary)" }}
              >
                Comment
              </button>
            </Col>
            <Col className="bload_heading">2 Comments</Col>
            <Col className="p-2">
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Marrion Willsoriam
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
            </Col>
          </Col>

          <Col md={4}>
            <div
              className="border border-2 rounded-3 px-3 py-3 position-sticky"
              style={{ overflow: "hidden", top: "10vh", right: "0" }}
            >
              <div
                className="d-flex align-items-center rounded-3 justify-content-center"
                style={{
                  height: "15vh",
                  background: "linear-gradient(to right, #5751E0, #5751E1)",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h5 className="font-popppins text-white">
                    This is Course fee
                  </h5>
                  <h5 className="text-white">
                    ₹ 500 <sup>weekly</sup>{" "}
                  </h5>
                </div>
              </div>

              <br />
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SlCalender size={22} /> &nbsp; <b>Subject</b>
                </span>{" "}
                <b>English</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <MdOutlineTopic size={22} /> &nbsp; <b>Topic</b>
                </span>{" "}
                <b>Course Topic</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <GoStopwatch size={22} /> &nbsp; <b>Age group</b>
                </span>{" "}
                <b>14-17 years</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SlCalender size={22} /> &nbsp; <b>Grade</b>
                </span>{" "}
                <b>
                  <span>9th - 12th</span>
                </b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SiSinglestore size={22} /> &nbsp; <b>Type</b>
                </span>{" "}
                <b>1-on-1 Class</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <FaUsers size={22} /> &nbsp; <b>Students</b>
                </span>{" "}
                <b>200+</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <GiCycle size={22} /> &nbsp; <b>Cycle</b>
                </span>{" "}
                <b>2</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <FaChalkboardUser size={22} /> &nbsp; <b>Lectures</b>
                </span>{" "}
                <b>12/32</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <FaChalkboardUser size={22} /> &nbsp; <b>Last Paid</b>
                </span>{" "}
                <b>27 Jan 2022</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <SlCalender size={22} /> &nbsp; <b>Next Paid</b>
                </span>{" "}
                <b>27 July 2022</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <GiCycle size={22} /> &nbsp; <b>Cycle Start Date</b>
                </span>{" "}
                <b>22 March 2024</b>
              </span>
              <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                <span>
                  <GiCycle size={22} /> &nbsp; <b>Cycle End Date</b>
                </span>{" "}
                <b>22 April 2024</b>
              </span>

              <Col className="mt-3">
                <span className="mt-3 mb-2">
                  <b>Course Completed</b>
                </span>
                <ProgressBar className="mt-2" now={30} label={`${30}%`} />
              </Col>
              <div
                className="d-flex align-items-center justify-content-between mt-3"
                style={{ gap: "10px" }}
              >
                <IconButton aria-label="delete">
                  <CiHeart size={32} />
                </IconButton>

                <IconButton aria-label="delete">
                  <VscLiveShare size={32} />
                </IconButton>
              </div>
              <button
                className="w-100 py-2 rounded-3 mt-2 fw-bold text-white border-0"
                style={{ background: "var(--color-primary)" }}
              >
                Take a Free Demo
              </button>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <button
              className="btn btn-danger rounded-5 fw-semibold"
              onClick={handleShow2}
            >
              Cancel Course
            </button>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="py-3 px-3 d-flex align-items-center"
            style={{ background: "#f8d7da", color: "#721c24" }}
          >
            Your course will be expired in 20 days .
          </Col>
        </Row>
      </Container>
      <ParentFooter />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Hold Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to hold this course?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-warning rounded-5" onClick={handleYes}>
            Yes
          </button>
          <button className="btn btn-danger rounded-5" onClick={handleClose}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel this course?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-warning rounded-5" onClick={handleYes}>
            Yes
          </button>
          <button className="btn btn-danger rounded-5" onClick={handleClose2}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </OftadehLayout>
  );
};

export default CourseWithId;
