import ConversionPage from "./ConversationPage";

export const ConversionPageConfig = {
  routes: [
    {
      path: "/operation/Conversion",
      exact: true,
      component: ConversionPage
    }
  ]
};
