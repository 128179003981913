import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { Button } from "@mui/material";
import {
  FaQrcode,
  FaShareAlt,
  FaInstagram,
  FaTwitter,
  FaFacebook,
} from "react-icons/fa";

const ManualPage = () => {
  return (
    <AccountLayout>
      <h3>Manual Payment</h3>

      <Row className="mt-3 mb-3">
        <h5>Payment collection/Payment links</h5>
      </Row>
      <Table 
     
      className="mt-3" responsive striped hover bordered>
        <thead
         style={{
          background:'var(--color-primary)'
        }}
        >
          <tr className="text-center">
            <th className="text-white">Payment link ID</th>
            <th className="text-white">Created at</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Payment link</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
          <tr className="text-center">
            <td></td>
            <td></td>
            <td></td>
            <td>http://localhot:300/pay/uihwei78y7 </td>
            <td>
              <Button
                className="teacher-button shadow rounded-5 border-0"
                variant="outlined"
                size="small"
              >
                copy
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Col className="d-flex align-items-center justify-content-end">
        <Button
          className="teacher-button shadow border-0 rounded-5"
          variant="outlined"
        >
          View More
        </Button>
      </Col>
      <Row className="mt-3 mb-3">
        <h5>Payment for Marketing Activities</h5>
      </Row>
      <Container className="mt-3">
        <Form>
          {/* Reason for Payment */}
          <Form.Group as={Row} controlId="reasonForPayment">
            <Form.Label column sm={12}>
              Reason for Payment
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                className="border-yellow rounded-5 shadow"
                type="text"
                placeholder="Enter reason"
              />
            </Col>
          </Form.Group>

          {/* Date of Payment, Amount Paid, Paid To, Paid By */}
          <Row>
            <Col sm={3} className={"mt-3"}>
              <Form.Group controlId="dateOfPayment">
                <Form.Label>Date of Payment</Form.Label>
                <Form.Control
                  className="border-yellow rounded-5 shadow"
                  type="date"
                />
              </Form.Group>
            </Col>
            <Col sm={3} className={"mt-3"}>
              <Form.Group controlId="amountPaid">
                <Form.Label>Amount Paid</Form.Label>
                <Form.Control
                  className="border-yellow rounded-5 shadow"
                  type="number"
                  placeholder="Enter amount"
                />
              </Form.Group>
            </Col>
            <Col sm={3} className={"mt-3"}>
              <Form.Group controlId="paidTo">
                <Form.Label>Paid To</Form.Label>
                <Form.Control
                  className="border-yellow rounded-5 shadow"
                  type="text"
                  placeholder="Enter payee"
                />
              </Form.Group>
            </Col>
            <Col sm={3} className={"mt-3"}>
              <Form.Group controlId="paidBy">
                <Form.Label>Paid By</Form.Label>
                <Form.Control
                  className="border-yellow rounded-5 shadow"
                  type="text"
                  placeholder="Enter payer"
                />
              </Form.Group>
            </Col>
          </Row>

          <Col className="align-items-center justify-content-center mt-3">
            <Button
              className="teacher-button shadow rounded-5 boredr-0"
              variant="contained"
              size="medium"
            >
              Save
            </Button>
          </Col>
        </Form>
        <Row className="justify-content-center mt-5">
          <Col md={4} className="text-center">
            <Card bg="light" style={{ width: "18rem", height: "20vh" }}>
              <Card.Body
                className="d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <FaQrcode size={50} color="#FFD32B" />
                <Card.Text>Create QR Code</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8}>
            <div>
              <h5>Payment Links</h5>
              <div className="d-flex justify-content-between align-items-center">
                <span>Link 1</span>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" size="sm">
                    <FaShareAlt size={20} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <FaInstagram size={20} color="#405DE6" className="mr-2" />
                      Instagram
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <FaTwitter size={20} color="#1DA1F2" className="mr-2" />
                      Twitter
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <FaFacebook size={20} color="#1877f2" className="mr-2" />
                      Facebook
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-between align-items-center mt-2">
                <span>Link 2</span>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" size="sm">
                    <FaShareAlt size={20} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <FaInstagram size={20} color="#405DE6" className="mr-2" />
                      Instagram
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <FaTwitter size={20} color="#1DA1F2" className="mr-2" />
                      Twitter
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <FaFacebook size={20} color="#1877f2" className="mr-2" />
                      Facebook
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </AccountLayout>
  );
};

export default ManualPage;
