import React from "react";

const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-relative"
      style={{ height: "100vh", width: "100vw", backgroundColor: "#fff" }}
    >
      <div class="ring"></div>
      <div class="ring"></div>
      <div class="ring"></div>
    </div>
  );
};

export default Loader;
