import React from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row } from 'react-bootstrap';
import { GiNextButton } from "react-icons/gi";

function OperationAnalytics(props) {
    const { history } = props;
  return (
    <>
    <h5>Operation Analytics</h5>
    {/* <OperationLocation path={history} /> */} 
    <Row>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Return On Investment</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Return On Equity</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Return On Capital</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Return On Assets</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Net Profit Margin</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Gross Profit Margin</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Operating Margins</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Operating Profit</span></Col>
        <Col md={12} className='mt-3 p-3 ps-5 border rounded-3 shadow'> <GiNextButton size={22} /> <span className='ms-4'>Return On Employee Investment</span></Col>
    </Row>
    </>
  )
}

export default OperationAnalytics