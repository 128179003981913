import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Paper } from "@material-ui/core";
import ParentFooter from "../../components/ParentFooter";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const token = useSelector((state) => state.token.value);
  const [showModal, setShowModal] = useState(false);
  const [showModalReminder, setShowModalReminder] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [eventFormData, setEventFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  const [reminderFormData, setReminderFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  const [myEventsList, setMyEventsList] = useState([]);
  const [myRemindersList, setMyRemindersList] = useState([]);
  const [combinedList, setCombinedList] = useState([]);
  const [selectedEventOrReminder, setSelectedEventOrReminder] = useState(null);

  useEffect(() => {
    const getAllEventsAndReminders = async () => {
      try {
        const [eventsRes, remindersRes] = await Promise.all([
          axios.get("/teacher/events", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get("/teacher/reminders", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        if (eventsRes.data.data) {
          setMyEventsList(
            eventsRes.data.data.map((event) => ({
              ...event,
              start: new Date(event.start_datetime),
              end: new Date(event.end_datetime),
            }))
          );
        }

        if (remindersRes.data.data) {
          setMyRemindersList(
            remindersRes.data.data.map((reminder) => ({
              ...reminder,
              start: new Date(reminder.start_datetime),
              end: new Date(reminder.end_datetime),
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching events and reminders", error);
      }
    };

    getAllEventsAndReminders();
  }, [token]);

  useEffect(() => {
    setCombinedList([...myEventsList, ...myRemindersList]);
  }, [myEventsList, myRemindersList]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModalReminder = () => setShowModalReminder(true);
  const handleCloseModalReminder = () => setShowModalReminder(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleInputChange = (e, setFormData) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (field, date, setFormData) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleAddEvent = async () => {
    if (!eventFormData.title) {
      toast.error("Please fill the title");
      return;
    }

    try {
      const res = await axios.post(
        "/teacher/event",
        {
          title: eventFormData.title,
          start_datetime: eventFormData.start,
          end_datetime: eventFormData.end,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(res);

      if (res.data.res_type === "success") {
        const newEvent = {
          ...res.data.data,
          start: new Date(res.data.data.start_datetime),
          end: new Date(res.data.data.end_datetime),
        };
        setMyEventsList((prevList) => [...prevList, newEvent]);
        toast.success("Event added successfully");
      } else {
        console.error("Failed to add event");
      }
    } catch (error) {
      console.error("Error adding event", error);
    } finally {
      // Reset event form data
      setEventFormData({
        title: "",
        start: new Date(),
        end: new Date(),
      });
      handleCloseModal();
    }
  };

  const handleAddReminder = async () => {
    if (!reminderFormData.title) {
      toast.error("Please fill the title");
      return;
    }

    try {
      const res = await axios.post(
        "/teacher/reminder",
        {
          title: reminderFormData.title,
          start_datetime: reminderFormData.start,
          end_datetime: reminderFormData.end,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(res);

      if (res.data.res_type === "success") {
        const newReminder = {
          ...res.data.data,
          start: new Date(res.data.data.start_datetime),
          end: new Date(res.data.data.end_datetime),
        };
        setMyRemindersList((prevList) => [...prevList, newReminder]);
        toast.success("Reminder added successfully");
      } else {
        console.error("Failed to add reminder");
      }
    } catch (error) {
      console.error("Error adding reminder", error);
    } finally {
      // Reset reminder form data
      setReminderFormData({
        title: "",
        start: new Date(),
        end: new Date(),
      });
      handleCloseModalReminder();
    }
  };

  const handleSelectEventOrReminder = (selectedEvent) => {
    if (selectedEvent) {
      const event = myEventsList.find((e) => e._id === selectedEvent._id);
      const reminder = myRemindersList.find((e) => e._id === selectedEvent._id);

      if (event) {
        setSelectedEventOrReminder({
          type: "event",
          id: event._id,
          ...event,
        });
      } else if (reminder) {
        setSelectedEventOrReminder({
          type: "reminder",
          id: reminder._id,
          ...reminder,
        });
      }
      handleShowDeleteModal();
    }
  };

  const handleDeleteEventOrReminder = async () => {
    try {
      const endpoint =
        selectedEventOrReminder.type === "event"
          ? `teacher/event?id=${selectedEventOrReminder.id}`
          : `teacher/reminder?id=${selectedEventOrReminder.id}`;

      const res = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(res);

      if (res.data.res_type === "success") {
        if (selectedEventOrReminder.type === "event") {
          setMyEventsList((prevList) =>
            prevList.filter((event) => event._id !== selectedEventOrReminder.id)
          );
        } else {
          setMyRemindersList((prevList) =>
            prevList.filter(
              (reminder) => reminder._id !== selectedEventOrReminder.id
            )
          );
        }
        toast.success(`${selectedEventOrReminder.type} deleted successfully`);
      } else {
        console.error("Failed to delete");
      }
    } catch (error) {
      console.error("Error deleting event or reminder", error);
    } finally {
      handleCloseDeleteModal();
    }
  };

  return (
    <TeacherLayout>
      <h1>Calendar</h1>
      <Button
        style={{ background: "var(--color-primary)" }}
        className="mb-3 rounded-4"
        onClick={handleShowModal}
      >
        Add Event
      </Button>
      <Button
        style={{ background: "var(--color-primary)" }}
        className="mb-3 ms-3 rounded-4"
        onClick={handleShowModalReminder}
      >
        Add Reminder
      </Button>

      <Paper style={{ padding: "5px" }}>
        <Calendar
          localizer={localizer}
          events={combinedList}
          startAccessor="start"
          endAccessor="end"
          defaultDate={new Date(2024, 4, 29)}
          style={{ height: 500 }}
          onSelectEvent={handleSelectEventOrReminder}
        />
      </Paper>

      {/* Delete Modal */}
      <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEventOrReminder?.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Title: <b>{selectedEventOrReminder?.title}</b>
          </p>
          <p>
            Start:{" "}
            <b>
              {moment(selectedEventOrReminder?.start_datetime).format("LLL")}
            </b>
          </p>
          <p>
            End:{" "}
            <b>{moment(selectedEventOrReminder?.end_datetime).format("LLL")}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteEventOrReminder}>
            Delete {selectedEventOrReminder?.type}
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Event Modal */}
      <Modal centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={eventFormData.title}
                onChange={(e) => handleInputChange(e, setEventFormData)}
                placeholder="Enter event title"
              />
            </Form.Group>
            <Form.Group controlId="formStart">
              <Form.Label>Start</Form.Label>
              <Form.Control
                type="datetime-local"
                name="start"
                value={moment(eventFormData.start).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleDateChange("start", e.target.value, setEventFormData)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEnd">
              <Form.Label>End</Form.Label>
              <Form.Control
                type="datetime-local"
                name="end"
                value={moment(eventFormData.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleDateChange("end", e.target.value, setEventFormData)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddEvent}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Reminder Modal */}
      <Modal
        centered
        show={showModalReminder}
        onHide={handleCloseModalReminder}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={reminderFormData.title}
                onChange={(e) => handleInputChange(e, setReminderFormData)}
                placeholder="Enter reminder title"
              />
            </Form.Group>
            <Form.Group controlId="formStart">
              <Form.Label>Start</Form.Label>
              <Form.Control
                type="datetime-local"
                name="start"
                value={moment(reminderFormData.start).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                onChange={(e) =>
                  handleDateChange("start", e.target.value, setReminderFormData)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEnd">
              <Form.Label>End</Form.Label>
              <Form.Control
                type="datetime-local"
                name="end"
                value={moment(reminderFormData.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleDateChange("end", e.target.value, setReminderFormData)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleAddReminder}>
            Save Changes
          </Button>
          <Button variant="secondary" onClick={handleCloseModalReminder}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default CalendarPage;
