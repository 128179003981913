import BlogPage from "./BlogPage";

export const TeacherBlogPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/blog",
      exact: true,
      component: BlogPage
    }
  ]
};
