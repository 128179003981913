import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
import { IoIosHeart, IoMdTime } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TiBook } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";
import Img_404 from '../../assets/parent/404.png';

function CoursesSearch(props) {
  const { history } = props;
  const navigate = useNavigate();
  const handleCoursesView = () => {
    navigate("/parent/dashboard/pages/courses/123456");
  };

  const [showModal, setShowModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'title') {
      setCourseTitle(value);
    } else if (name === 'description') {
      setCourseDescription(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement the logic to handle the submission of the course details
    // For example, you can send a request to your backend to create a new course
    console.log('Course Title:', courseTitle);
    console.log('Course Description:', courseDescription);
    // Close the modal after submission
    handleClose();
  };

  return (
    <OftadehLayout>
      <h3>Search Result ( 3 ) </h3>

      <Row className="position-relative pe-5">
        <Col md={4} className="mt-3">
          <Cards img={c1} />
        </Col>
        <Col md={4} className="mt-3">
          <Cards img={c2} />
        </Col>
        <Col md={4} className="mt-3">
          <Cards img={c3} />
        </Col>
        <Col md={4} className="mt-3">
          <Cards img={c4} />
        </Col>
      </Row>


      <Container className="px-3 py-5 d-flex align-items-center justify-content-center flex-column">
        <img src={Img_404} width={"50%"} height={"280vh"} alt="" />
        <h5>Requested Course was not found.</h5>
        <button className="btn btn-primary mt-3" onClick={handleShow}> Request course</button>
      </Container>


      <Modal show={showModal} onHide={handleClose} style={{marginTop:'10vh'}}>
        <Modal.Header closeButton>
          <Modal.Title>Request Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="courseTitle">
              <Form.Label>Course Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter course title"
                value={courseTitle}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mt-3" controlId="courseDescription">
              <Form.Label>Course Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                placeholder="Enter course description"
                value={courseDescription}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button className="mt-3" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ParentFooter />
    </OftadehLayout>
  );
}

export default CoursesSearch;

const Cards = ({ img }) => {
  const navigate = useNavigate();
  const handleCoursesView = () => {
    navigate("/course");
  };
  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img onClick={handleCoursesView} className="cource_img rounded-3 px-3 cursor-pointer" src={img} alt="" />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
        </div>

        <h5 className="mt-3 px-3">
          Learn Mandarin - Beginner <br /> Level
        </h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
            <p className="m-0 text-secondary">Robert Fox</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end pe-3">
          <span
            className="badge my-3 "
            style={{ background: "var(--color-primary)" }}
          >
            36% Completed
          </span>
        </div>

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
          </p>
        </div>
      </div>
    </>
  );
};
