// Media
import MediaPage from "./MediaPage";

export const MediaPageConfig = {
  routes: [
    {
      path: "/operation/media",
      exact: true,
      component: MediaPage
    }
  ]
};
