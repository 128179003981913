// Cources
import CourcesPage from "./CourcesPage";
import CourseWithId from "./CourseWithId";
import CoursesRequests from "./CoursesRequests";

export const CourcesPageConfig = {
  routes: [
    {
      path: "/operation/courses",
      exact: true,
      component: CourcesPage
    },
    {
      path: "/operation/courses/:id",
      exact: true,
      component: CourseWithId
    },
    {
      path: "/operation/courses/request",
      exact: true,
      component: CoursesRequests
    },
  ]
};