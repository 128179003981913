import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Chip, IconButton } from "@mui/material";
import { CiHeart } from "react-icons/ci";
import { VscLiveShare } from "react-icons/vsc";
import { FaAngleRight, FaUsers } from "react-icons/fa6";
import { AiOutlineFileDone } from "react-icons/ai";
import { GoStopwatch } from "react-icons/go";
import { SlCalender } from "react-icons/sl";
import { Col, Container, Row } from "react-bootstrap";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import { MdCastForEducation, MdOutlineStarPurple500 } from "react-icons/md";
import { IoCalendarClearOutline } from "react-icons/io5";
import { SiFuturelearn } from "react-icons/si";

const BlogCommentImg1 = require("../../assets/blog-comment-01.png");
const screenShot = require("../../assets/online-tutorials-concept_52683-37480.avif");
function CourseWithId() {
  return (
    <>
      <OperationLayout>
        <Container className="p-3">
          <Row>
            <Col md={9}>
              <Col md={12}>
                <img
                  width={"100%"}
                  style={{ maxHeight: "50vh", objectFit: "cover" }}
                  height={"100%"}
                  className="rounded-3"
                  src={
                    "https://themegenix.com/demo/skillgro/assets/img/courses/courses_details.jpg"
                  }
                  alt=""
                />
              </Col>
              <br />
              <Col xs={12}>
                <span
                  className="tag px-3 py-1 rounded-5"
                  style={{
                    backgroundColor: "var(--color-secondary)",
                    fontSize: "16px",
                  }}
                >
                  Devlopment
                </span>{" "}
                &nbsp;
                <MdOutlineStarPurple500 size={22} color="#FFD846" /> ( 4.5
                Reviews )
              </Col>
              <br />
              <Row className="p-3">
                <Col md={12} className="d-flex align-items-center">
                  <img
                    src={screenShot}
                    className="border border-warning"
                    width={"60px"}
                    height={"60px"}
                    style={{ borderRadius: "50%" }}
                    alt=""
                  />
                  <span className="teacher-name ms-3">
                    <b>By John Smith</b>
                  </span>
                  <span className="class-room-dated-flex align-items-center justify-content-center ms-3">
                    <IoCalendarClearOutline size={22} /> &nbsp;{" "}
                    <span>27 Jan 2022</span>
                  </span>
                  <span className="number-of-students align-items-center justify-content-center ms-3">
                    <MdCastForEducation size={22} /> &nbsp;{" "}
                    <span>2,250 Students</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button className="ms-3 mb-3 new-button">Overview</button>
                </Col>
              </Row>
              <Container
                className="shadow rounded-3 px-5 py-4"
                style={{ border: "1px solid #EEEEEE" }}
              >
                <h5>Course Description</h5>
                <p>
                  Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan lacus vel facilisis.dolor sit amet,
                  consectetur adipiscing elited do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua.
                </p>
                <h5>What you'll learn in this course?</h5>
                <p>
                  Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan.
                </p>

                <Col className="mt-3">
                  {" "}
                  <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                    <FaAngleRight />
                  </span>{" "}
                  <b>Work with color & Gradients & Grids</b>
                </Col>
                <Col className="mt-3">
                  {" "}
                  <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                    <FaAngleRight />
                  </span>{" "}
                  <b>All the useful shortcuts</b>
                </Col>
                <Col className="mt-3">
                  {" "}
                  <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                    <FaAngleRight />
                  </span>{" "}
                  <b>Be able to create Flyers, Brochures, Advertisements</b>
                </Col>
                <Col className="mt-3">
                  {" "}
                  <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                    <FaAngleRight />
                  </span>{" "}
                  <b>How to work with Images & Text</b>
                </Col>
                <br />
                <p>
                  Morem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                  maecenas accumsan.Dorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magn.
                </p>
              </Container>

              <br />
              <Col className="mt-5 bload_heading">2 Comments</Col>
              <Col className="p-2">
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Carolyn Wallace
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Marrion Willsoriam
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Carolyn Wallace
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col md={3}>
              <div
                className="border border-2 rounded-3 px-3 py-3"
                style={{ overflow: "hidden" }}
              >
                <div
                  className="d-flex align-items-center rounded-3 justify-content-center"
                  style={{
                    height: "15vh",
                    background: "linear-gradient(to right, #5751E0, #5751E1)",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <h5 className="font-popppins text-white">
                      This is Course fee
                    </h5>
                    <h5 className="text-white">
                      ₹ 500 <sup>weekly</sup>{" "}
                    </h5>
                  </div>
                </div>
                {/* <span className='d-flex align-items-center'>
                  <h2>₹ 500</h2>&nbsp; weekly
                </span> */}
                <br />
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                  <span>
                    <SlCalender size={22} /> &nbsp; <b>Class</b>
                  </span>{" "}
                  <b>1x week</b>
                </span>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                  <span>
                    <GoStopwatch size={22} /> &nbsp; <b>Duration</b>
                  </span>{" "}
                  <b>30 min</b>
                </span>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                  <span>
                    <AiOutlineFileDone size={22} /> &nbsp; <b>Hours</b>
                  </span>{" "}
                  <b>140 hours</b>
                </span>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                  <span>
                    <FaUsers size={22} /> &nbsp; <b>Enrollments </b>
                  </span>{" "}
                  <b>200+</b>
                </span>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                  <span>
                    <SiFuturelearn size={22} /> &nbsp; <b>Earning</b>
                  </span>{" "}
                  <b>12000 /-</b>
                </span>
                {/* all available time slots */}
                <div className="d-flex align-items-center justify-content-between border-bottom mt-3">
                  <div className="left-box p-0">
                    <h6>Feb 26 – Apr 22</h6>
                    <p>Time: 10:30 PM</p>
                  </div>
                  <div className="right">
                    <Chip
                      style={{ cursor: "pointer" }}
                      label="Enroll"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between border-bottom mt-3">
                  <div className="left-box p-0">
                    <h6>Feb 26 – Apr 22</h6>
                    <p>Time: 10:30 PM</p>
                  </div>
                  <div className="right">
                    <Chip
                      style={{ cursor: "pointer" }}
                      label="Enroll"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <IconButton aria-label="delete">
                    <CiHeart size={32} />
                  </IconButton>

                  <IconButton aria-label="delete">
                    <VscLiveShare size={32} />
                  </IconButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </OperationLayout>
    </>
  );
}

export default CourseWithId;
