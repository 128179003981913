import AWS from "aws-sdk";

// Configure AWS
AWS.config.update({
  accessKeyId: "AKIA6GB4RFKTOOL6SPUE",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const s3 = new AWS.S3();

/**
 * Uploads a file to S3
 * @param {File} file - The file to upload
 * @param {string} bucketName - The name of the S3 bucket
 * @param {function} onProgress - Callback for tracking progress
 * @returns {Promise<string>} - The URL of the uploaded file
 */
export const uploadFileToS3 = async (file, onProgress) => {
  const params = {
    Bucket: "hariom-bucket/urja",
    Key: file.name,
    Body: file,
    ContentType: file.type,
  };

  const options = {
    partSize: 5 * 1024 * 1024, // Set part size for larger files
    queueSize: 1, // Adjust if needed
    ...params,
  };

  return new Promise((resolve, reject) => {
    s3.upload(options, (error, data) => {
      if (error) {
        reject(new Error(`Failed to upload file: ${error.message}`));
      } else {
        resolve(data.Location); // Return the file URL
      }
    }).on("httpUploadProgress", (progress) => {
      const percentage = (progress.loaded / progress.total) * 100;
      onProgress(percentage); // Call the progress callback
    });
  });
};
