import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from "../components/Footer";
import axios from "../utils/axios.js"
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetLinkPage = () => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const params = useParams();

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (password === "" || confirmPassword === "") {
            toast.error("Please fill all the fields !!")
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Password does not match !!")
            return;
        }

        try {
            const res = await axios.put(`/parent/reset-password-executor/${params.token}`, {
                new_password: password,
            }, {
                headers: {
                    Authorization: `Bearer ${params.token}`,
                },
            })

            // console.log(res);

            if (res.data.res_type === "success") {
                toast.success("Password reset successfully !!")
            } else {
                toast.error(res.data.message)
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Navbar />

            <div className="row py-5 my-5 d-flex align-items-center justify-content-center">
                <div className="col-md-6 mt-5 p-4 bg-body-tertiary rounded-4 shadow">
                    <h3 className='text-center'>Enter your new password</h3>
                    <form className='mt-3'>
                        <div className="form-group">
                            <label className='mb-2' htmlFor="password">New Password</label>
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" id="password" />
                        </div>
                        <div className="form-group mt-3">
                            <label className='mb-2' htmlFor="confirm_password">Confirm Password</label>
                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className="form-control" id="confirm_password" />
                        </div>
                        <button onClick={handleResetPassword} type="submit" className="btn rounded-4 mt-4 text-white" style={{ background: "var(--color-primary)" }}>Submit</button>
                    </form>
                </div>
            </div>



            <Footer />
        </>
    )
}

export default ResetLinkPage