import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { IconButton } from '@mui/material';
import { FaRegStar, FaStar } from 'react-icons/fa';

function RescheduleModel(props) {
  // State to manage the rating
  const [rating, setRating] = useState(4);

  // Function to handle star click event
  const handleStarClick = (starCount) => {
    setRating(starCount);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.toggleModal}
      style={{ zIndex: '1111', marginTop: '20vh' }}
    >
      <Modal.Header className='border-0' closeButton>
        <Modal.Title>Reschedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formSubmissionDate'>
              <Form.Control type='date' />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className='mt-3' controlId='formSubmissionTime'>
              <Form.Control type='time' />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <Button variant='secondary' onClick={props.toggleModal}>
          Close
        </Button>
        <Button variant='primary' onClick={props.toggleModal}>
          save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RescheduleModel;
