import HomeIcon from "@mui/icons-material/Home";
import { MdMoney, MdOutlinePayments, MdScatterPlot } from "react-icons/md";
import { GiBanknote } from "react-icons/gi";
import { RiRefund2Fill } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import { PiContactlessPaymentLight } from "react-icons/pi";
import { MdOutlineAddLink } from "react-icons/md";
import { LiaApplePay } from "react-icons/lia";

import { MdPayment } from "react-icons/md";
import { AiOutlineCalculator } from "react-icons/ai";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { IoIosNotifications } from "react-icons/io";
const AccountNavigationConfig = [
  {
    id: "Account",
    title: "Account",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <HomeIcon />,
        url: "/account/dashboard",
        exact: true,
      },
      {
        id: "dashboard",
        title: "Parent Fee",
        type: "item",
        icon: <MdOutlinePayments />,
        url: "/account/parent-fee",
        exact: true,
      },
      {
        id: "bank-details",
        title: "Bank Details",
        type: "item",
        icon: <GiBanknote />,
        url: "/account/bank-details",
        exact: true,
      },
      // {
      //   id: "RefundPage",
      //   title: "Refund",
      //   type: "item",
      //   icon: <RiRefund2Fill />,
      //   url: "/account/refund",
      //   exact: true,
      // },
      {
        id: "CurrencyConversionPage",
        title: "Currency Conversion",
        type: "item",
        icon: <BsCurrencyExchange />,
        url: "/account/currency-conversion",
        exact: true,
      },
      // {
      //   id: "PaymentGatewayPage",
      //   title: "Payment Gateway",
      //   type: "item",
      //   icon: <PiContactlessPaymentLight />,
      //   url: "/account/payment-getway",
      //   exact: true,
      // },
      // {
      //   id: "AdditionalLinksPage",
      //   title: "Additional Links",
      //   type: "item",
      //   icon: <MdOutlineAddLink />,
      //   url: "/account/additional-link",
      //   exact: true,
      // },
      {
        id: "SalaryPaymentPage",
        title: "Salary Payment",
        type: "item",
        icon: <MdPayment />,
        url: "/account/salary-payment",
        exact: true,
      },
      {
        id: "AppPaymentPage",
        title: "App Payment",
        type: "item",
        icon: <MdPayment />,
        url: "/account/app-payment",
        exact: true,
      },
      {
        title: "Payments",
        type: "collapse",
        icon: <MdPayment />,
        children: [
          // {
          //   id: "payments",
          //   title: "Payments",
          //   type: "item",
          //   url: "/account/payments",
          //   exact: true,
          // },
          {
            id: "paymentslogs",
            title: "Payment Logs",
            type: "item",
            url: "/account/dashboard/payment-logs",
            exact: true,
          },
          {
            id: "upcomingpayments",
            title: "Upcoming Payments",
            type: "item",
            url: "/account/dashboard/upcoming-payment",
            exact: true,
          },
          {
            id: "incomingpayments",
            title: "Incoming Payments",
            type: "item",
            url: "/account/dashboard/incoming",
            exact: true,
          },
          {
            id: "otherreq",
            title: "Other Requests",
            type: "item",
            url: "/account/dashboard/other-req",
            exact: true,
          },
          {
            id: "duepayments",
            title: "Due Payments",
            type: "item",
            url: "/account/dashboard/due-payment",
            exact: true,
          },
          // {
          //   id: "query",
          //   title: "Query",
          //   type: "item",
          //   url: "/account/dashboard/query",
          //   exact: true,
          // },
          {
            id: "RefundPage",
            title: "Refund Payment",
            type: "item",
            url: "/account/dashboard/refund",
            exact: true,
          },
        ],
      },
      {
        title: "Account",
        type: "collapse",
        icon: <MdPayment />,
        children: [
          {
            id: "Parent Account",
            title: "Parent Account",
            type: "item",
            url: "/account/parent-account",
            exact: true,
          },
          {
            id: "Teacher Account",
            title: "Teacher Account",
            type: "item",
            url: "/account/teacher-account",
            exact: true,
          },
          {
            id: "Company Account",
            title: "Company Account",
            type: "item",
            url: "/account/company-account",
            exact: true,
          },
          {
            id: "Employee Account",
            title: "Employee Account",
            type: "item",
            url: "/account/employee-account",
            exact: true,
          },
        ],
      },
      {
        title: "Expenses",
        type: "collapse",
        icon: <MdScatterPlot />,
        children: [
          {
            id: "Fixed Const Expense",
            title: "Fixed Const Expense",
            type: "item",
            url: "/account/fixed-const-expense",
            exact: true,
          },
          {
            id: "Variable Const Expense",
            title: "Variable Const Expense",
            type: "item",
            url: "/account/variable-const-expense",
            exact: true,
          },
        ],
      },
      {
        id: "calculation",
        title: "Calculation",
        type: "item",
        icon: <AiOutlineCalculator />,
        url: "/account/dashboard/calculation",
        exact: true,
      },
      {
        id: "Attendance",
        title: "Attendance",
        type: "item",
        icon: <MdScatterPlot />,
        url: "/account/attendance",
        exact: true,
      },
      {
        id: "Wallet",
        title: "Wallet",
        type: "item",
        icon: <MdPayment />,
        url: "/account/wallet",
        exact: true,
      },
      {
        id: "Leanie Account",
        title: "Leanie Account",
        type: "item",
        icon: <MdMoney />,
        url: "/account/learnie-account",
        exact: true,
      },
      {
        id: "chat",
        title: "Chat",
        type: "item",
        icon: <IoChatbubbleEllipses />,
        url: "/account/dashboard/chat",
        exact: true,
      },
      {
        id: "manualpages",
        title: "Manual Payments",
        type: "item",
        icon: <MdPayment />,
        url: "/account/dashboard/manual",
        exact: true,
      },
      {
        id: "clientaccount",
        title: "Client",
        type: "collapse",
        icon: <MdPayment />,
        children: [
          {
            id: "clientaccount",
            title: "Client Account",
            type: "item",
            url: "/account/dashboard/client",
            exact: true,
          },
          {
            id: "client complaint",
            title: "Client Complain",
            type: "item",
            url: "/account/client/complaint",
            exact: true,
          },
          {
            id: "teacher complaint",
            title: "Teacher Complain",
            type: "item",
            url: "/account/teacher/complaint",
            exact: true,
          },
          {
            id: "clientaccountdetails",
            title: "Client Account Details",
            type: "item",
            url: "/account/dashboard/client/details",
            exact: true,
          },
        ],
      },
      {
        id: "notification",
        title: "Notification",
        type: "item",
        icon: <IoIosNotifications />,
        url: "/account/dashboard/notification",
        exact: true,
      },
    ],
  },
];

export default AccountNavigationConfig;
