import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";

const ServiceProviderPage = (props) => {
  const { history } = props;


  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h4>Service Provider</h4>
    </OperationLayout>
  );
};

export default ServiceProviderPage;
