import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import CircularProgressBar from "../../screens/Admin/graph/CircularProgressBar";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import EChartsGraph from "../../screens/Admin/graph/EChartsGraph";
import { Container, Row, Col, Form } from "react-bootstrap";
import OprationEchart from "../../screens/Admin/graph/OprationEchart";

const DashboardPage = () => {
  const data = [
    {
      name: "Sales",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
    },
    {
      name: "Leads",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
    },
    {
      name: "Total Leads",
      type: "line",
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
    },
  ];

  return (
    <AccountLayout>
      <Container>
        <Row>
          <Col className=" p-2" md={3}>
            <div className="admin-card-items shadow card-1">
              <span>
                Academics <br /> (This Month)
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#3C5DB5" size={27} />
                  <div className="count">
                    <h4>$ 3575</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={60} color="#3C5DB5" />
                </div>
              </div>
            </div>
          </Col>
          <Col className=" p-2" md={3}>
            <div className="admin-card-items shadow card-2">
              <span>
                Extra Carriculam <br /> (This Month){" "}
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowDownSLine color="#AB2043" size={27} />
                  <div className="count">
                    <h4>$ 978</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={62} color="#AB2043" />
                </div>
              </div>
            </div>
          </Col>
          <Col className=" p-2" md={3}>
            <div className="admin-card-items shadow card-3">
              <span>
                Employee's Salary <br /> (This Month)
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#E6BB4E" size={27} />
                  <div className="count">
                    <h4>$452</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={72} color="#E6BB4E" />
                </div>
              </div>
            </div>
          </Col>
          <Col className=" p-2" md={3}>
            <div className="admin-card-items shadow card-4">
              <span>
                Teacher's Salary <br /> (This Month)
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#35AB77" size={27} />
                  <div className="count">
                    <h4>$ 125</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={81} color="#35AB77" />
                </div>
              </div>
            </div>
          </Col>
          <Col className="mt-3 p-2" md={3}>
            <div className="admin-card-items shadow card-4">
              <span>
                Other Expenses <br /> (This Month)
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#35AB77" size={27} />
                  <div className="count">
                    <h4>$ 785</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={81} color="#35AB77" />
                </div>
              </div>
            </div>
          </Col>
          <Col className="mt-3 p-2" md={3}>
            <div className="admin-card-items shadow card-3">
              <span>
                Leaniee Mall <br /> (This Month)
              </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#E6BB4E" size={27} />
                  <div className="count">
                    <h4>$856</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={72} color="#E6BB4E" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col md={7} className="px-3">
            <div className="graph-container shadow rounded-3 py-3 mx-auto">
              <EChartsGraph id="main" data={data} />
            </div>
          </Col>
          <Col
            md={5}
            className="shadow rounded-3 p-0"
            style={{ overflow: "hidden" }}
          >
            <div className="heder-graph-2 mb-4 px-3 d-flex align-items-center justify-content-between">
              <h6>Total Revenue</h6>
              <Form.Group controlId="selectOption">
                <Form.Select className="shadow rounded-5">
                  <option value="1">Weekly</option>
                  <option value="2">Monthly</option>
                  <option value="3">Yearly</option>
                </Form.Select>
              </Form.Group>
            </div>
            {/* <CustomEChartsGraph height={250} data={dataOne} /> */}
            <OprationEchart />
          </Col>
        </Row>
      </Container>
    </AccountLayout>
  );
};

export default DashboardPage;
