// authService.js
import axios from "axios";

export const createBlog = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/blog`,
      { ...obj },
      {
        headers: {
          adminToken: localStorage.getItem("superAdminToken"),
        },
      }
    );
    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    console.log(error.response.data.message || "Login failed");

    throw error;
  }
};
