import React, { useState } from "react";
import Navbar from "../../components/Navbar.js";
import { Col, Row, Button, Form, Container, Spinner } from "react-bootstrap";
import "../../assets/css/parent/SignIn.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer.js";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "../../utils/axios.js";
import "../../index.css";

function SignUp() {
  const [number, setNumber] = useState();
  const [otp, setOtp] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpSentEmail, setOtpSentEmail] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const navigate = useNavigate();
  const otpLength = 6; // Expected OTP length

  // const handleSendOtp = () => {
  //   if (!number) {
  //     toast.error("Please enter a phone number");
  //     return;
  //   }

  //   setLoading1(true);
  //   axios
  //     .post("/parent/otp", {
  //       type: "phone",
  //       value: number,
  //     })
  //     .then((response) => {
  //       if (response.data.res_type === "success") {
  //         toast.success(response.data.message);
  //         setOtpSent(true);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message);
  //       console.error("Error sending OTP:", error);
  //     })
  //     .finally(() => {
  //       setLoading1(false);
  //     });
  // };

  const handleSendOtp1 = () => {
    // validate email with regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    setLoading2(true);
    axios
      .post("/parent/otp", {
        type: "email",
        value: email,
        name:`${fName} ${lName}` || 'User'
      })
      .then((response) => {
        if (response.data.res_type === "success") {
          toast.success(response.data.message);
          setOtpSentEmail(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.error("Error sending OTP:", error);
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const handleDashboard = async () => {
    if (
      fName === "" ||
      lName === "" ||
      email === "" ||
      number === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (!checked) {
      toast.error("Please agree to the terms and conditions");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    // if (otp?.length !== otpLength) {
    //   toast.error("Invalid phone OTP");
    //   return;
    // }

    if (otpEmail?.length !== otpLength) {
      toast.error("Invalid Email OTP");
      return;
    }

    try {
      setLoading(true);
      // const isOtpVerified = await handleVerifyOtp();
      const isOtpVerified1 = await handleVerifyOtp1();

      if (isOtpVerified1) {
        axios
          .post("/parent/sign-up", {
            email: email,
            phone: number,
            password: password,
            firstName: fName,
            lastName: lName,
            referralCode: referralCode,
          })
          .then((response) => {
            if (response.data.res_type === "success") {
              toast.success(response.data.message);
              navigate("/parent/sign-in");
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
            console.error("Error creating account:", error);
          });
      } else {
        toast.error("OTP not verified");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleVerifyOtp = async () => {
  //   try {
  //     const response = await axios.put("/parent/otp", {
  //       type: "phone",
  //       value: number,
  //       otp: otp,
  //     });

  //     if (response.data.data.isVerified) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     // toast.error("Something went wrong");
  //     console.error("Error verifying OTP:", error);
  //     return false;
  //   }
  // };

  const handleVerifyOtp1 = async () => {
    try {
      const response = await axios.put("/parent/otp", {
        type: "email",
        value: email,
        otp: otpEmail,
      });

      if (response.data.data.isVerified) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // toast.error("Something went wrong");
      console.error("Error verifying OTP:", error);
      return false;
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className="" style={{ overflowY: "hidden" }}>
        <Navbar />
        <br />
        <br />

        <Container
          fluid
          className="d-flex justify-content-center align-items-center mt-5 mb-3"
        >
          <Row className="w-100">
            <Col
              md={6}
              className="mx-auto shadow px-sm-2 px-md-5 py-2 rounded-3"
            >
              <h2 className="text-center mb-4">Parent Sign-Up</h2>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={fName}
                        className="shadow border border-2 rounded-3"
                        onChange={(e) => setFName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="shadow border border-2 rounded-3"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    className="shadow border border-2 rounded-3"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={handleSendOtp1}
                  >
                    {loading2 ? "Sending..." : "Send OTP"}
                  </Button>
                </Form.Group>
                {otpSentEmail && (
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      className="shadow border border-2 rounded-3"
                      value={otpEmail}
                      onChange={(e) => setOtpEmail(e.target.value)}
                    />
                  </Form.Group>
                )}
                <Form.Group className="mb-3 border rounded-2 px-2 py-2 shadow border-2 rounded-3">
                  <PhoneInput
                    international
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={number}
                    onChange={setNumber}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    className="shadow border border-2 rounded-3"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="password"
                    className="shadow border border-2 rounded-3"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Referral Code (optional)"
                    className="shadow border border-2 rounded-3"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="I agree to the terms and conditions"
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                </Form.Group>
                <Button
                  className="p-2 w-100 border-0 new-button rounded-5"
                  onClick={handleDashboard}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
                <p className="mt-3 text-center">
                  Already have an account?{" "}
                  <Link className="text-primary" to="/parent/sign-in">
                    Sign In
                  </Link>
                </p>
                <p className="text-center">
                  Join as a{" "}
                  <Link className="text-primary" to="/teacher/sign-up">
                    Teacher
                  </Link>
                </p>
                <p className="text-center">
                  Apply as an{" "}
                  <Link className="text-primary" to="/career">
                    Employee
                  </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default SignUp;
