import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "@mui/material";

const LiveRequestsPage = (props) => {
    const { history } = props;

    return (
        <OperationLayout>
            <h5>Teacher Live Request</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className="justify-content-between">
                <Col md={4}>
                    &nbsp;
                </Col>
                <Col md={4} className='mt-3'>
                    <Form.Group controlId='nameInput'>
                        <Form.Control
                            className='shadow rounded-3'
                            type='text'
                            placeholder='Search...'
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-3 mb-3"><h6>Recent Request</h6></Row>

            <StyleContainerT>
                <div
                    className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
                >
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='review_user_image'>
                                <img
                                    src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
                                    alt=''
                                />
                            </div>
                            <div className='ms-5'>
                                <h6 className='m-0 fw-bold'>Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                            <Badge className='ms-3' bg='primary'>
                                Active
                            </Badge>
                        </div>
                        <div>
                            <Button className='rounded-5' variant='outlined' size='small'>
                                Approve
                            </Button>
                            <Button className='rounded-5 border-danger bg-danger ms-2 text-white' variant='outlined' size='small'>
                                Reject
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
                >
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='review_user_image'>
                                <img
                                    src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
                                    alt=''
                                />
                            </div>
                            <div className='ms-5'>
                                <h6 className='m-0 fw-bold'>Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                            <Badge className='ms-3' bg='danger'>
                                In Active
                            </Badge>
                        </div>
                        <div>
                            <Button className='rounded-5' variant='outlined' size='small'>
                                Approve
                            </Button>
                            <Button className='rounded-5 border-danger bg-danger ms-2 text-white' variant='outlined' size='small'>
                                Reject
                            </Button>
                        </div>
                    </div>
                </div>
            </StyleContainerT>

            <Row className="mt-3 mb-3"><h6>Last Month</h6></Row>

            <StyleContainerT>
                <div
                    className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
                >
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='review_user_image'>
                                <img
                                    src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
                                    alt=''
                                />
                            </div>
                            <div className='ms-5'>
                                <h6 className='m-0 fw-bold'>Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                            <Badge className='ms-3' bg='primary'>
                                Active
                            </Badge>
                        </div>
                        <div>
                            <Button className='rounded-5' variant='outlined' size='small'>
                                Approve
                            </Button>
                            <Button className='rounded-5 border-danger bg-danger ms-2 text-white' variant='outlined' size='small'>
                                Reject
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
                >
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='review_user_image'>
                                <img
                                    src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
                                    alt=''
                                />
                            </div>
                            <div className='ms-5'>
                                <h6 className='m-0 fw-bold'>Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                            <Badge className='ms-3' bg='danger'>
                                In Active
                            </Badge>
                        </div>
                        <div>
                            <Button className='rounded-5' variant='outlined' size='small'>
                                Approve
                            </Button>
                            <Button className='rounded-5 border-danger bg-danger ms-2 text-white' variant='outlined' size='small'>
                                Reject
                            </Button>
                        </div>
                    </div>
                </div>
            </StyleContainerT>

        </OperationLayout>
    );
};


const StyleContainerT = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 768px) {
    .feedback_review {
        height: auto; /* Adjust height for smaller screens */
    }
}
`


export default LiveRequestsPage;
