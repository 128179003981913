import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Button as MaterialButton } from "@mui/material";
import { LuBoxSelect } from "react-icons/lu";
import { FiEdit3 } from "react-icons/fi";
import { IoMdRemove } from "react-icons/io";
import axios from "../../utils/axios";
import { IoMdAdd } from "react-icons/io";
import { IconButton } from "@material-ui/core";
import { MdDelete } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function EmployeeManagement(props) {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show2, setShow2] = useState(false);
  const adminToken = localStorage.getItem("adminToken");

  const token = useSelector((state) => state.token.value);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEmployeeNameChange = (e) => {
    setEmployeeName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const fetchEmployees = async (token) => {
    try {
      const res = await axios.get("/admin/employee/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(res.data);

      if (res.data.res_type === "success") {
        setEmployees(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchEmployees(token);
    }
  }, [token]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/admin/employee/create",
        {
          name: employeeName,
          email: email,
          phone: phone,
          password: password,
          role: role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data);

      if (res.data.res_type === "success") {
        setEmployees([...employees, res.data.data]);
        toast.success(res.data.message);
        setEmployeeName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setRole("");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    setShowModal(false);
  };

  return (
    <>
      <h5>Employee Management</h5>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formEmployeeName">
              <Form.Label>Employee Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter employee name"
                value={employeeName}
                onChange={handleEmployeeNameChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                value={phone}
                onChange={handlePhoneChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Select value={role} onChange={handleRoleChange}>
                <option>Select</option>
                <option value="operation">Operation</option>
                <option value="account">Account</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {loading ? "Adding Employee..." : "Add Employee"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="mt-3 mb-3">
        <Col md={6}>
          <MaterialButton
            style={{
              color: "var(--color-primary)",
              borderColor: "var(--color-primary)",
            }}
            className="black-outline-button rounded-5"
            variant="outlined"
            startIcon={<IoMdAdd />}
            onClick={handleAddButtonClick}
          >
            {" "}
            Add{" "}
          </MaterialButton>
          <MaterialButton
            style={{
              color: "var(--color-primary)",
              borderColor: "var(--color-primary)",
            }}
            className="ms-3 black-outline-button rounded-5"
            variant="outlined"
            startIcon={<IoMdRemove />}
          >
            {" "}
            Remove{" "}
          </MaterialButton>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <MaterialButton
            style={{
              color: "var(--color-primary)",
              borderColor: "var(--color-primary)",
            }}
            className="black-outline-button rounded-5"
            variant="outlined"
            startIcon={<LuBoxSelect />}
          >
            {" "}
            Select{" "}
          </MaterialButton>
          <MaterialButton
            style={{
              color: "var(--color-primary)",
              borderColor: "var(--color-primary)",
            }}
            className="ms-3 black-outline-button rounded-5"
            variant="outlined"
            startIcon={<FiEdit3 />}
          >
            {" "}
            Edit{" "}
          </MaterialButton>
        </Col>
      </Row>
      <Table
        className="mt-5 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white text-truncate">S. No.</th>
            <th className="text-white text-truncate">Emp No.</th>
            <th className="text-white text-truncate">Emp Name</th>
            <th className="text-white text-truncate">Date of Joining</th>
            <th className="text-white text-truncate">Email</th>
            <th className="text-white text-truncate">Phone</th>
            <th className="text-white text-truncate">Role</th>
            <th className="text-white text-truncate">Last Changes</th>
            <th className="text-white text-truncate">Details</th>
            <th className="text-white text-truncate">Action</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((emp, index) => (
            <tr className="text-center bg-light" key={emp._id}>
              <td className="text-truncate">{index + 1}.</td>
              <td className="text-truncate">{emp.uid ?? 'N/A'}</td>
              <td className="text-truncate">{emp.name ?? 'N/A'}</td>
              <td className="text-truncate">{emp.joining_date ?? 'N/A'}</td>
              <td className="text-truncate">{emp.email ?? 'N/A'}</td>
              <td className="text-truncate">{emp.phone ?? 'N/A'}</td>
              <td className="text-truncate">{emp.role ?? 'N/A'}</td>
              <td className="text-truncate">
                <Moment format="DD/MM/YYYY HH:mm A">{emp.updatedAt}</Moment>
              </td>
              <td
                onClick={handleShow2}
                className="text-primary text-decoration-underline text-truncate"
                style={{ cursor: "pointer" }}
              >
                view
              </td>
              <td className="emp_mngmnt-drop text-truncate">
                <IconButton size="small" aria-label="delete">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      size="sm"
                    >
                      <SlOptionsVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Block</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        style={{ zIndex: 99999 }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <h6>Employee Name :</h6>
              <h6>Employee ID :</h6>
              <h6>Date of Joining :</h6>
              <h6>Phone :</h6>
              <h6>Email :</h6>
              <h6>Designation :</h6>
              <h6>Salary :</h6>
              <h6>Incentive :</h6>
              <h6>Address :</h6>
              <h6>Date of Birth :</h6>
              <h6>Role :</h6>
            </Col>
            <Col sm={6}>
              <h6>John Doe</h6>
              <h6>123456</h6>
              <h6>12 January 2023</h6>
              <h6>1234567890</h6>
              <h6>test@test.com</h6>
              <h6>Manager</h6>
              <h6>10000</h6>
              <h6>2000</h6>
              <h6>Address</h6>
              <h6>12 January 2023</h6>
              <h6>Account handle</h6>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Verified Documents</h6>
              <span>Document A</span> <br />
              <span>Document B</span>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Joining Latter</h6>
              <span>Document A</span>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>CV</h6>
              <span>Document A</span>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>Attendence</h6>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Present</th>
                    <th>Absent</th>
                    <th>Half Day</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>--</td>
                    <td>Yes</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>--</td>
                    <td>--</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td>6</td>
                    <td>Yes</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <h6>Complaints</h6>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Complaint Id</th>
                    <th>Complaint</th>
                    <th>Complaint Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        View
                      </button>
                      <button className="btn btn-sm text-white rounded-5 bg-danger ms-2">
                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        View
                      </button>
                      <button className="btn btn-sm rounded-5 text-white bg-danger ms-2">
                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>123456</td>
                    <td>Complaint</td>
                    <td>12 January 2023</td>
                    <td>
                      <button
                        className="btn btn-sm rounded-5"
                        style={{
                          backgroundColor: "var(--color-primary)",
                          color: "white",
                        }}
                      >
                        View
                      </button>
                      <button className="btn btn-sm text-white rounded-5 bg-danger ms-2">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: "var(--color-primary)",
              color: "white",
            }}
            onClick={handleClose2}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeManagement;
