import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";

import { toast } from "react-toastify";
import { Row, Col, Container, Modal, Form } from "react-bootstrap";
import "./Profile.css";
import { Button, Chip } from "@mui/material";
import parseTimeZone from "./tmzon";
import CountryList from "./allCountry";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
function Profile(props) {
  const [formData, setFormData] = useState({
    visibleName: "",
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    city: "",
    pincode: "",
  });
  const [isHomework, setIsHomework] = useState("");
  const [isTest, setIsTest] = useState("");
  const [isPTA, setIsPTA] = useState("");
  const [notification, setNotification] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [modelId, setModelId] = useState(0);
  const [havePreferences, setHavePreferences] = useState(false);
  const [children, setChildren] = useState([
    { name: "Kishan Jhadav" },
    { name: "Kishan Jhadav" },
  ]);

  const handleShowModal = (id) => {
    setShowModal(true);
    setModelId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModelId(0);
  };

  const parentId = localStorage.getItem("parentId");
  const token = useSelector((state) => state.token.value);
  // find parent by id
  const getParentById = async () => {
    try {
      const response = await axios.get(`/parent/getById?ID=${parentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.res_type === "success") {
        setUserData(response.data.data.parent);
        setFormData({
          visibleName: response.data.data.parent.visibleName,
          firstName: response.data.data.parent.firstName,
          lastName: response.data.data.parent.lastName,
          country: response.data.data.parent.country,
          address: response.data.data.parent.address,
          city: response.data.data.parent.city,
          pincode: response.data.data.parent.pincode,
        });
        if (response.data.data.parent.preferences) {
          setIsHomework(response.data.data.parent.preferences.isHw);
          setIsTest(response.data.data.parent.preferences.isTest);
          setIsPTA(response.data.data.parent.preferences.isPta);
          setNotification(response.data.data.parent.preferences.isNotify);
          setTime(response.data.data.parent.preferences.preferedTime);
          setHavePreferences(true);
        }
      } else {
        toast.error("Failed to fetch parent data");
      }
    } catch (error) {
      console.error("Error fetching parent data:", error);
    }
  };
  useEffect(() => {
    if (parentId) {
      getParentById();
    } else {
      toast.error("No parent ID found in localStorage");
    }
  }, [parentId, token]);

  // console.log("userData", userData);

  const handleUpdatePofile = async () => {
    try {
      setLoading(true);
      const response = await axios.put("/parent/parent-profile", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log("response", response);

      if (response.data.res_type === "success") {
        toast.success("Profile updated successfully");
        getParentById();
        handleCloseModal();
      } else {
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  const handleSetPreferences = async () => {
    const url = havePreferences ? "/parent/preferences" : "/parent/preferences";
    const method = havePreferences ? "put" : "post";

    try {
      setLoading(true);
      const res = await axios({
        method,
        url,
        data: {
          isHw: isHomework,
          isTest: isTest,
          isPta: isPTA,
          isNotify: notification,
          preferedTime: time,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        getParentById();
        handleCloseModal();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error setting preferences:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDetails = () => {
    if (modelId === 1) {
      return handleUpdatePofile();
    }
    if (modelId === 5) {
      return handleSetPreferences();
    }
  };

  return (
    <OftadehLayout>
      <h3>Profile</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      {/* Button for delete account */}

      {/* <Button
        // onClick={alert("No money will be refunded")}
        className="bg-danger text-white border-0 d-block ms-auto mb-5"
        variant="outlined"
      >
        Delete Account
      </Button> */}

      <Container className="n-box-shadow rounded-2 p-3">
        <Col className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <Chip className="bg-dark text-white" label="Personal Settings" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Button
              className="bg-dark text-white rounded-5 text-center py-1 px-3"
              variant="contained"
              size="small"
              onClick={() => handleShowModal(1)}
            >
              Edit personal info
            </Button>
          </Col>
        </Col>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          style={{ zIndex: "99999", paddingTop: "20vh" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {modelId === 1 && "Edit Personal Info"}
              {modelId === 2 && "Edit Child-1 Info"}
              {modelId === 3 && "Edit Child-2 Info"}
              {modelId === 4 && "Edit Contact Details"}
              {modelId === 5 && "Edit Other Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modelId === 1 && (
              <>
                <Row>
                  <Col md={6} className="mt-1">
                    <Form.Group controlId="visibleName">
                      <Form.Label>
                        <b>Visible Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="visibleName"
                        placeholder="Enter Visible Name"
                        value={formData.visibleName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            visibleName: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1">
                    <Form.Group controlId="FirstName">
                      <Form.Label>
                        <b>First Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={formData.firstName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="LastName">
                      <Form.Label>
                        <b>Last Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={formData.lastName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            lastName: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6} className="mt-1">
                    <Form.Group controlId="email">
                      <Form.Label>
                        <b>Email</b>
                      </Form.Label>
                      <Form.Control type="email" placeholder="Enter Email" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1">
                    <Form.Group controlId="phone">
                      <Form.Label>
                        <b>Phone</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Phone" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1">
                    <Form.Group controlId="whatsappNo">
                      <Form.Label>
                        <b>Whatsapp no.</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Whatsapp No."
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="nationality">
                      <Form.Label>
                        <b>Nationality</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Nationality"
                      />
                    </Form.Group>
                  </Col> */}
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="address">
                      <Form.Label>
                        <b>Address</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        placeholder="Enter Address"
                        value={formData.address}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            address: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="city">
                      <Form.Label>
                        <b>City</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="Enter City"
                        value={formData.city}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            city: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="pinCode">
                      <Form.Label>
                        <b>Pin Code</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        placeholder="Enter Pin Code"
                        value={formData.pincode}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            pincode: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="country">
                      <Form.Label>
                        <b>Country</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        value={formData.country}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            country: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                    <Form.Group controlId="residency">
                      <Form.Label>
                        <b>Residency</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Residency" />
                    </Form.Group>
                  </Col> */}
                </Row>
              </>
            )}

            {modelId === 2 && (
              <>
                <Row>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="uniqueCode">
                      <Form.Label>
                        <b>Unique Code</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Unique Code"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="name">
                      <Form.Label>
                        <b>Name</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="standard">
                      <Form.Label>
                        <b>Standard</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Standard" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="board">
                      <Form.Label>
                        <b>Board</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Board" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="nationality">
                      <Form.Label>
                        <b>Nationality</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Nationality"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="favouriteSubject">
                      <Form.Label>
                        <b>Favourite Subject</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Favourite Subject"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="weakestSubject">
                      <Form.Label>
                        <b>Weakest Subject</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Weakest Subject"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="dob">
                      <Form.Label>
                        <b>Date Of Birth</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Date Of Birth"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="age">
                      <Form.Label>
                        <b>Age</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Age" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="currentSchool">
                      <Form.Label>
                        <b>Current School</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Current School"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="learningDifficulty">
                      <Form.Label>
                        <b>Learning Difficulty</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Learning Difficulty"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="gender">
                      <Form.Label>
                        <b>Gender</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Gender" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="hobby">
                      <Form.Label>
                        <b>Hobby</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Hobby" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="interest">
                      <Form.Label>
                        <b>Interest</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Interest" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="displayName">
                      <Form.Label>
                        <b>Display Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Display Name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {modelId === 3 && (
              <>
                <Row>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="uniqueCode">
                      <Form.Label>
                        <b>Unique Code</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Unique Code"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="name">
                      <Form.Label>
                        <b>Name</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="standard">
                      <Form.Label>
                        <b>Standard</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Standard" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="board">
                      <Form.Label>
                        <b>Board</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Board" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="nationality">
                      <Form.Label>
                        <b>Nationality</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Nationality"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="favouriteSubject">
                      <Form.Label>
                        <b>Favourite Subject</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Favourite Subject"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="weakestSubject">
                      <Form.Label>
                        <b>Weakest Subject</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Weakest Subject"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="dob">
                      <Form.Label>
                        <b>Date Of Birth</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Date Of Birth"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="age">
                      <Form.Label>
                        <b>Age</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Age" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="currentSchool">
                      <Form.Label>
                        <b>Current School</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Current School"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="learningDifficulty">
                      <Form.Label>
                        <b>Learning Difficulty</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Learning Difficulty"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="gender">
                      <Form.Label>
                        <b>Gender</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Gender" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="hobby">
                      <Form.Label>
                        <b>Hobby</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Hobby" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="interest">
                      <Form.Label>
                        <b>Interest</b>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Interest" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-1 ps-2">
                    <Form.Group controlId="displayName">
                      <Form.Label>
                        <b>Display Name</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Display Name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {modelId === 4 && (
              <>
                <Row>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>Primary Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Primary Email"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>Secondary Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Secondary Email"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>Primary Phone</Form.Label>
                      <Form.Control type="text" placeholder="Primary Phone" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>Secondary Phone</Form.Label>
                      <Form.Control type="text" placeholder="Secondary Phone" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>call timing start</Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Label>call timing end</Form.Label>
                      <Form.Control type="time" />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {modelId === 5 && (
              <>
                <Row>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="selectOption">
                      <Form.Label>
                        Do You want Homework for your child.
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setIsHomework(e.target.value)}
                        value={isHomework}
                      >
                        <option>Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="selectOption">
                      <Form.Label>
                        Do You want teacher can take test.
                      </Form.Label>
                      <Form.Select
                        value={isTest}
                        onChange={(e) => setIsTest(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mt-3">
                    <Form.Group controlId="selectOption">
                      <Form.Label>
                        Whether the parent wants a teacher meeting for PTA.
                      </Form.Label>
                      <Form.Select
                        value={isPTA}
                        onChange={(e) => setIsPTA(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mt-3">
                    <Form.Group controlId="selectOption">
                      <Form.Label>
                        Preferred Notifications of classes by.
                      </Form.Label>
                      <Form.Select
                        value={notification}
                        onChange={(e) => setNotification(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="true">Email</option>
                        <option value="false">SMS</option>
                        <option value="true">SMS / Email</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mt-3">
                    <Form.Group controlId="selectOption">
                      <Form.Label>Preferred time to contact.</Form.Label>
                      <Form.Control
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              className="bg-danger text-white border-0"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              onClick={handleUpdateDetails}
              className="ms-3"
              variant="outlined"
            >
              {loading ? "Loading..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="mt-3 p-2">
          <Col
            md={3}
            className="d-flex align-items-center justify-content-center mt-3"
          >
            {" "}
            <div className="profile-parent-page-image-area">
              <img
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>{" "}
          </Col>
          <Col md={5} className="mt-3">
            <Col md={6} className="mt-1">
              <b>Parent ID </b> : {userData?._id}
            </Col>
            <Col md={6} className="mt-1">
              <b>Visible Name </b> : {userData?.visibleName}
            </Col>
            <Col md={6} className="mt-1">
              <b>Full Name </b> : {userData?.firstName} {userData?.lastName}
            </Col>
            <Col md={6} className="mt-1">
              <b>Email </b> : {userData?.email}
            </Col>
            <Col md={6} className="mt-1">
              <b>Phone </b> : {userData?.phone}
            </Col>
            <Col md={6} className="mt-1">
              <b>Whatsapp no.</b> : {userData?.whatsapp}
            </Col>
          </Col>
          <Col md={4} className="mt-3">
            <Col className="mt-1">
              <b>Nationality </b> : Indian
            </Col>
            <Col className="mt-1">
              <b>Address </b> : {userData?.address}
            </Col>
            <Col className="mt-1">
              <b>City </b> : {userData?.city}
            </Col>
            <Col className="mt-1">
              <b>Pin Code</b> : {userData?.pincode}
            </Col>
            <Col className="mt-1">
              <b>Country </b> : {userData?.country}
            </Col>
            <Col className="mt-1">
              <b>Residency </b> : {userData?.country}
            </Col>
            <Col className="mt-1">
              <b>Education Approach </b> : IIT Preparation
            </Col>
          </Col>
        </Row>
      </Container>

      {userData?.children.map((child, index) => (
        <Container key={index} className="n-box-shadow rounded-2 p-3 mt-5">
          <Col className="d-flex align-items-center justify-content-between mb-3">
            <Col md={6}>
              <Chip className="bg-dark text-white" label="Child" />
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                className="bg-dark text-white rounded-5 text-center p-1"
                variant="contained"
                size="small"
                onClick={() => handleShowModal(2)}
              >
                Edit
              </Button>
            </Col>
          </Col>
          <Row>
            <Col md={6} className="mt-1 ps-2">
              <b>unique code : </b> {child._id}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Name : </b> {child.firstName} {child.lastName}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Standard : </b> {child.standard}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Board : </b> {child.board}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Nationality : </b>
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Favourite subject : </b> {child.favoriteSubject}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Weakest subject : </b> {child.weekSubject}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Date Of Birth : </b> {child.dob}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Age : </b> {child.age}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Current School : </b> {child.currentSchool}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Learning Difficulty : </b> {child.learningDeficulties}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Gender : </b> {child.gender}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Hobby : </b> {child.hobby}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Intrest : </b> {child.interest}
            </Col>
            <Col md={6} className="mt-1 ps-2">
              <b>Display Name : </b> {child.displayedName}
            </Col>
          </Row>
        </Container>
      ))}

      <Container className="n-box-shadow rounded-2 p-3 mt-5">
        <Col className="d-flex align-items-center justify-content-between mb-3">
          <Col md={6}>
            <Chip className="bg-dark text-white" label="Contact Details" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Button
              className="bg-dark text-white rounded-5 text-center"
              variant="contained"
              size="small"
              onClick={() => handleShowModal(4)}
            >
              Edit
            </Button>
          </Col>
        </Col>
        <Col className="mt-1 ps-2">
          <b>Primary Email : </b> krishnapawar@gmail.com
        </Col>
        <Col className="mt-1 ps-2">
          <b>Secondary Email : </b> krishnapawar@gmail.com
        </Col>
        <Col className="mt-1 ps-2">
          <b>Primary Phone : </b> +91 1234567890
        </Col>
        <Col className="mt-1 ps-2">
          <b>Secondary Phone : </b> +91 1234567890
        </Col>
        <Col className="mt-1 ps-2">
          <b>Preffered call timings : </b> 9.00 am - 2.00 pm ist
        </Col>
      </Container>

      <Container className="n-box-shadow rounded-2 p-3 mt-5">
        <Col className="d-flex align-items-center justify-content-between mb-3">
          <Col md={6}>
            <Chip className="bg-dark text-white" label="Other details" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Button
              className="bg-dark text-white rounded-5 text-center"
              variant="contained"
              size="small"
              onClick={() => handleShowModal(5)}
            >
              Edit
            </Button>
          </Col>
        </Col>
        <Col className="mt-1 ps-2">
          <b>Country : </b> {userData?.country}
        </Col>
        <Col className="mt-1 ps-2">
          <b>Time zone : </b> {userData?.timZone}
        </Col>
        {/* <Col className="mt-1 ps-2">
          <b>Language : </b> English
        </Col> */}
      </Container>

      <Container className="n-box-shadow rounded-2 p-3 mt-5">
        <Col className="d-flex align-items-center justify-content-between mb-3">
          <Col md={6}>
            <Chip className="bg-dark text-white" label="Preferences" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Button
              className="bg-dark text-white rounded-5 text-center"
              variant="contained"
              size="small"
              onClick={() => handleShowModal(5)}
            >
              Edit
            </Button>
          </Col>
        </Col>
        <Col className="mt-1 ps-2">
          <b>Do You want Homework for your child : </b>{" "}
          {isHomework === true ? "Yes" : "No"}
        </Col>
        <Col className="mt-1 ps-2">
          <b>Do You want teacher can take test : </b>{" "}
          {isTest === true ? "Yes" : "No"}
        </Col>
        <Col className="mt-1 ps-2">
          <b>Whether the parent wants a teacher meeting for PTA : </b>{" "}
          {isPTA === true ? "Yes" : "No"}
        </Col>
        <Col className="mt-1 ps-2">
          <b>Preferred Notifications of classes by : </b>{" "}
          {notification === "true" ? "Email" : "SMS"}
        </Col>
        <Col className="mt-1 ps-2">
          <b>Preferred time to contact : </b> {time}
        </Col>
      </Container>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default Profile;
