import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Col, Row } from "react-bootstrap";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaDotCircle } from "react-icons/fa";
import Footer from "../components/Footer";

const CopyRight = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <PolicyContainer>
        <div className="hero px-5 pb-4">
          <div className="responsive-box-hidden-for-copy"></div>
          <h2 className=" fw-bold mb-3">Copyright</h2>
          <p className="mt-3">Last Updated: 20 January 2024</p>
        </div>

        <Row className="mt-5 px-5">
          <Col>
            <ul
              className="ps-md-5"
              style={{ listStyleType: "none", padding: 0 }}
            >
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                All content on learniee.com, including text, graphics, logos,
                images, and software, is protected by copyright and owned or
                controlled by learniee.com or its licensors. All rights
                reserved.
              </li>
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                You may download and print portions of the materials from this
                website for your own non-commercial, educational use, provided
                you include the following copyright notice: "Copyright © 2024
                learniee.com. All rights reserved." Any other use, including,
                but not limited to, the reproduction, distribution,
                modification, display, or transmission of the content on this
                website is strictly prohibited without the express prior written
                permission of learniee.com.
              </li>
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                This website may contain links to other websites that are not
                owned or controlled by learniee.com. We are not responsible for
                the content, accuracy, or opinions expressed in such websites,
                and such links do not necessarily constitute a recommendation or
                endorsement by learniee.com.
              </li>
            </ul>
          </Col>
        </Row>
      </PolicyContainer>
      <Footer />
    </>
  );
};

const PolicyContainer = styled.div`
  overflow-x: hidden;
  h4 {
    font-weight: 600;
  }
  .hero {
    padding-top: 10vmax;
    height: fit-content;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }
`;

export default CopyRight;
