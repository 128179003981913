import Suggestions from "./Suggestions.jsx";

export const SuggestionsPageConfig = {
  routes: [
    {
      path: "/parent/suggestions",
      exact: true,
      component: Suggestions,
    },
  ],
};
