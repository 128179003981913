import React, { useEffect, useState } from 'react'
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import Chart from "chart.js/auto";
import styled from "styled-components";
import {
    Badge,
    Card,
    Col,
    Container,
    Dropdown,
    Row,
    Table,
} from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
import { IconButton } from "@material-ui/core";
import {
    MdDelete,
    MdOutlineFileDownload,
    MdOutlineStarHalf,
    MdStar,
} from "react-icons/md";
import { FaAngleRight, FaCheck, FaFilePdf } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";
import { Chip } from "@mui/material";
function TeacherReport() {
    useEffect(() => {
        var ctx = document.getElementById("studentFrequencyGraph").getContext("2d");

        var studentFrequencyGraph = new Chart(ctx, {
            type: "line",
            data: {
                labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"],
                datasets: [
                    {
                        label: "Data",
                        borderColor: "#f4e880",
                        pointBorderColor: "#FFD536",
                        pointBackgroundColor: "#80b6f4",
                        pointHoverBackgroundColor: "#80b6f4",
                        pointHoverBorderColor: "#80b6f4",
                        pointBorderWidth: 10,
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 1,
                        pointRadius: 3,
                        fill: false,
                        borderWidth: 4,
                        data: [100, 120, 150, 170, 180, 170, 160],
                    },
                ],
            },
            options: {
                legend: {
                    position: "bottom",
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                fontColor: "rgba(0,0,0,0.5)",
                                fontStyle: "bold",
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                padding: 20,
                            },
                            gridLines: {
                                drawTicks: false,
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                padding: 20,
                                fontColor: "rgba(0,0,0,0.5)",
                                fontStyle: "bold",
                            },
                        },
                    ],
                },
            },
        });

        return () => studentFrequencyGraph.destroy();
    }, []);

    useEffect(() => {
        const createChart = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: [
                        "Maths I",
                        "Maths II",
                        "Maths III",
                        "Maths IV",
                        "Maths V",
                        "Maths VI",
                    ],
                    datasets: [
                        {
                            label: "Number of Students",
                            data: [50, 40, 90, 70, 30, 110],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const createChart2 = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    datasets: [
                        {
                            label: "Number of Leads",
                            data: [50, 40, 90, 70, 30, 110, 50, 40, 90, 70, 30, 110],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const createChart3 = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: [
                        "Total Classes",
                        "Active Classes",
                        "Hold Classes",
                        "Cancelled Classes",
                    ],
                    datasets: [
                        {
                            label: "Number of Classes",
                            data: [50, 40, 90, 70],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const createChart4 = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: ["Total Students", "Enrolled", "Retained", "Loss"],
                    datasets: [
                        {
                            label: "Number of Students",
                            data: [50, 40, 90, 70],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const createChart5 = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: ["Total Classes", "On Time", "Late", "Reschedule", "No Show"],
                    datasets: [
                        {
                            label: "Number of Attendance",
                            data: [50, 40, 90, 70, 60],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const createChart6 = (canvasId) => {
            const ctx = document.getElementById(canvasId).getContext("2d");
            return new Chart(ctx, {
                type: "bar",
                data: {
                    labels: [
                        "Total Students",
                        "On Time",
                        "Late",
                        "Reschedule",
                        "No Show",
                    ],
                    datasets: [
                        {
                            label: "Number of Attendance",
                            data: [50, 40, 90, 70, 60],
                            backgroundColor: "#f4e880",
                            borderColor: "#FFD32B",
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };

        const chart1 = createChart("studentCourceGraph1");
        const chart2 = createChart2("studentCourceGraph2");
        const chart3 = createChart3("studentCourceGraph3");
        const chart4 = createChart4("studentCourceGraph4");
        const chart5 = createChart5("studentCourceGraph5");
        const chart6 = createChart6("studentCourceGraph6");

        return () => {
            chart1.destroy();
            chart2.destroy();
            chart3.destroy();
            chart4.destroy();
            chart5.destroy();
            chart6.destroy();
        };
    }, []);

    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(true);

    const courses = [
        {
            id: 1,
            name: "React Fundamentals",
            startDate: "2024-05-01",
            endDate: "2024-06-01",
            lectureCount: 8,
            totalLectures: 10,
            studentsEnrolled: 20,
            amount: 100,
            status: "completed",
        },
        {
            id: 2,
            name: "Node.js Basics",
            startDate: "2024-06-15",
            endDate: "2024-07-15",
            lectureCount: 5,
            totalLectures: 8,
            studentsEnrolled: 15,
            amount: 80,
            status: "ongoing",
        },
        {
            id: 3,
            name: "Python Advanced",
            startDate: "2024-08-01",
            endDate: "2024-09-01",
            lectureCount: 2,
            totalLectures: 6,
            studentsEnrolled: 10,
            amount: 120,
            status: "hold",
        },
    ];
    return (
        <OperationLayout>
            <h5>Teacher Report</h5>
            <StyledContainer>
                <Row className="mt-2">
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Earning Till Date</h6>
                            <h5 className="fw-bold text-center m-0">₹ 19000</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Earning Withdraw</h6>
                            <h5 className="fw-bold text-center m-0">₹ 19000 </h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Total Courses</h6>
                            <h5 className="fw-bold text-center m-0">19</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Total Clients</h6>
                            <h5 className="fw-bold text-center m-0">19</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Retention</h6>
                            <h5 className="fw-bold text-center m-0">19</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Number of hours taught </h6>
                            <h5 className="fw-bold text-center m-0">999</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Number of Students taught </h6>
                            <h5 className="fw-bold text-center m-0">199</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Teacher Rating </h6>
                            <h5 className="fw-bold text-center m-0">4.5</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Conversion ratio </h6>
                            <h5 className="fw-bold text-center m-0">20</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Reschedulement Request </h6>
                            <h5 className="fw-bold text-center m-0">20</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Reschedulement frequency </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">No-show frequency </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">No. leads </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">No leads converted </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">lost leads </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Free slot </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Book slot </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Group Class </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">1-1 Class </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="shadow card p-3 rounded-3 mt-3 cursor-pointer">
                            <h6 className="text-center">Late Join Classes </h6>
                            <h5 className="fw-bold text-center m-0">00</h5>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-5 align-items-center">
                    <Col md={9}>
                        <h6 className="fw-bold m-0">No of Students enrolled Frequency</h6>
                    </Col>
                    <Col md={3}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                            >
                                Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Month</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Week</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Day</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={11} className="mt-5">
                        {" "}
                        <canvas id="studentFrequencyGraph"></canvas>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <h6 className="fw-bold m-0">
                            No. of students with ratings averagely per course
                        </h6>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Revenue Generation</h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Day</button>
                                    <button className="btns">Week</button>
                                    <button className="btns">Month</button>
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex w-100 align-items-center gap-5">
                                <div>
                                    <h2 className="m-0 mb-1">40%</h2>
                                    <p className="m-0">Growth</p>
                                </div>
                                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                                <div>
                                    <h2 className="m-0 mb-1">12%</h2>
                                    <p className="m-0">Loss</p>
                                </div>
                                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                            </div>
                            <hr />
                            <canvas id="studentCourceGraph1"></canvas>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Lead Generation</h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Total Leads</button>
                                    <button className="btns">Converted</button>
                                    <button className="btns">Loss</button>
                                </div>
                            </div>
                            <hr />
                            <div className="d-flex w-100 align-items-center gap-5">
                                <div>
                                    <h2 className="m-0 mb-1">40%</h2>
                                    <p className="m-0">Growth</p>
                                </div>
                                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                                <div>
                                    <h2 className="m-0 mb-1">12%</h2>
                                    <p className="m-0">Loss</p>
                                </div>
                                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
                            </div>
                            <hr />
                            <canvas id="studentCourceGraph2"></canvas>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Classes</h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Day</button>
                                    <button className="btns">Week</button>
                                    <button className="btns">Month</button>
                                </div>
                            </div>

                            <canvas id="studentCourceGraph3"></canvas>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Students</h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Day</button>
                                    <button className="btns">Week</button>
                                    <button className="btns">Month</button>
                                </div>
                            </div>

                            <canvas id="studentCourceGraph4"></canvas>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Teacher Attendance </h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Day</button>
                                    <button className="btns">Week</button>
                                    <button className="btns">Month</button>
                                </div>
                            </div>

                            <canvas id="studentCourceGraph5"></canvas>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="shadow p-3 rounded-4">
                            <div className="d-flex align-items-center mb-4 justify-content-between">
                                <h6 className="m-0 fw-bold text_yellow">Student Attendance</h6>
                                <div className="d-flex align-items-center gap-3">
                                    <button className="btns">Day</button>
                                    <button className="btns">Week</button>
                                    <button className="btns">Month</button>
                                </div>
                            </div>

                            <canvas id="studentCourceGraph6"></canvas>
                        </div>
                    </Col>
                </Row>

                <Container fluid className="mt-4 px-0 py-3">
                    <h5>Teaching Information</h5>
                    <br />
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-start flex-wrap gap-3"
                    >
                        <b>Subject</b>
                        <span className="px-3 py-1 border rounded-2">Hindi</span>
                        <span className="px-3 py-1 border rounded-2">English</span>
                        <span className="px-3 py-1 border rounded-2">Math's</span>
                    </Col>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-start flex-wrap gap-3 mt-3"
                    >
                        <b>Grade</b>
                        <span className="px-3 py-1 border rounded-2">Grade -1 </span>
                        <span className="px-3 py-1 border rounded-2">Grade -1 </span>
                        <span className="px-3 py-1 border rounded-2">Grade -1 </span>
                    </Col>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-start flex-wrap gap-3 mt-3"
                    >
                        <b>Board</b>
                        <span className="px-3 py-1 border rounded-2">Board</span>
                        <span className="px-3 py-1 border rounded-2">Board</span>
                        <span className="px-3 py-1 border rounded-2">Board</span>
                    </Col>
                </Container>
                <h5 className="mt-4 mb-3">Course Earning</h5>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>S.no</th>
                            <th>Course ID</th>
                            <th>Course Name</th>
                            <th>Lecture Count</th>
                            <th>Students Enrolled</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courses.map((course, index) => (
                            <tr key={course.id}>
                                <td>{index + 1}</td>
                                <td>{course.id}</td>
                                <td>{course.name}</td>
                                <td>{`${course.lectureCount} / ${course.totalLectures}`}</td>
                                <td>{course.studentsEnrolled}</td>
                                <td>${course.amount}</td>
                                <td>
                                    <Badge
                                        variant={
                                            course.status === "completed"
                                                ? "success"
                                                : course.status === "hold"
                                                    ? "warning"
                                                    : "info"
                                        }
                                    >
                                        {course.status}
                                    </Badge>
                                </td>
                                <td>{course.startDate}</td>
                                <td>{course.endDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h5 className="mt-4 mb-3">Review</h5>

                <div
                    className={`position-relative pe-5 mt-4 feedback_review py-2 px-3 shadow rounded-3 border border-3 ${active1 === true ? "active" : ""
                        }`}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div
                                className="review_user_image"
                                style={{ width: "50px", height: "50px" }}
                            >
                                <img
                                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                                    alt=""
                                />
                            </div>

                            <div className="ms-5">
                                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                        </div>
                        <div className="">
                            <h6>This is Course Name </h6>
                            <span>
                                <b>Class Date :</b> 10 Mar 2024
                            </span>
                        </div>
                        <Badge bg="success">Submitted</Badge>
                        <p
                            className="m-0 fw-bold text_yellow"
                            onClick={() => setActive1(!active1)}
                        >
                            view <FaAngleRight />
                        </p>
                    </div>
                    <div className="content mt-3">
                        <p className="m-0 d-flex align-items-center">
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                            4.5 Rating
                        </p>
                        <p className="m-0 mt-3">
                            Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                            nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                            porttitor. Fermentum scelerisque pretium mi convallis ac eget
                            ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                            dignissim congue ultrices dui diam. Convallis ut eu elit
                            imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                            fringilla. Augue condimentum nulla in velit nascetur tempor
                            venenatis cras. Auctor mi nulla non neque.
                            <br />
                            <br />
                            <IconButton aria-label="delete">
                                <IoIosCheckmark size={32} color="blue" />
                            </IconButton>
                            <IconButton aria-label="delete">
                                <IoCloseOutline size={22} color="red" />
                            </IconButton>
                        </p>
                    </div>
                </div>

                <div
                    className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border border-3 mt-4 ${active2 === true ? "active" : ""
                        }`}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="review_user_image">
                                <img
                                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                                    alt=""
                                />
                            </div>
                            <div className="ms-5">
                                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                                <p>12 January 2023</p>
                            </div>
                        </div>
                        <div className="">
                            <h6>This is Course Name </h6>
                            <span>
                                <b>Class Date :</b> 10 Mar 2024
                            </span>
                        </div>
                        <Badge bg="success">Submitted</Badge>
                        <p
                            className="m-0 fw-bold text_yellow"
                            onClick={() => setActive2(!active2)}
                        >
                            view <FaAngleRight />
                        </p>
                    </div>
                    <div className="content mt-3">
                        <p className="m-0 d-flex align-items-center">
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdStar className="text_yellow fs-6 me-1" />
                            <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
                            4.5 Rating
                        </p>
                        <p className="m-0 mt-3">
                            Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
                            nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
                            porttitor. Fermentum scelerisque pretium mi convallis ac eget
                            ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
                            dignissim congue ultrices dui diam. Convallis ut eu elit
                            imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
                            fringilla. Augue condimentum nulla in velit nascetur tempor
                            venenatis cras. Auctor mi nulla non neque.
                            <br />
                            <br />
                            <IconButton aria-label="delete">
                                <IoIosCheckmark size={32} color="blue" />
                            </IconButton>
                            <IconButton aria-label="delete">
                                <IoCloseOutline size={22} color="red" />
                            </IconButton>
                        </p>
                    </div>
                </div>

                <h5 className="mt-4 mb-3">Complaint</h5>

                <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow mt-4">
                    <Row className="p-2 m-0">
                        <Col
                            md={4}
                            className="d-flex align-items-center justify-content-start p-0 m-0"
                            style={{ gap: "10px" }}
                        >
                            {" "}
                            <div className="parent-complain-image">
                                <img
                                    src={
                                        "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                                    }
                                    alt="userImg"
                                />{" "}
                            </div>{" "}
                            <h5>Cody Fisher</h5>{" "}
                        </Col>
                        <Col
                            md={8}
                            className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
                            style={{ fontSize: "18px" }}
                        >
                            Subject: Introduction to Thermodynamics
                        </Col>
                    </Row>
                    <Col className="d-flex align-items-center justify-content-between p-2">
                        <Col md={6}> 5 Jun 2023</Col>
                        <Col
                            md={6}
                            className="d-flex align-items-center justify-content-end"
                        >
                            <Chip
                                label="Close"
                                className="n-box-shadow"
                                style={{
                                    color: "white",
                                    borderColor: "yellow",
                                    background: "red",
                                }}
                                color="primary"
                            />
                        </Col>
                    </Col>
                    <hr />
                    <Col className="p-2">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias
                        atque impedit officia porro maiores illum quae eveniet, odio ad non
                        expedita eius earum reiciendis sequi assumenda optio dignissimos
                        debitis quis similique praesentium. Corporis debitis molestiae
                        officiis nesciunt, minima temporibus possimus nisi quisquam, in
                        perferendis ipsam, hic ea quidem dolorem veniam assumenda aspernatur
                        maiores. Repudiandae reiciendis aspernatur omnis nobis adipisci
                        totam.
                    </Col>
                    <Col className="mt-3">
                        <h6>Attachment</h6>
                    </Col>
                    <Row className="ps-3">
                        <Col md={3} style={{ padding: "10px" }}>
                            <div className="resorce-parent-item cursor-pointer">
                                <div className="name">
                                    <FaFilePdf /> HC Verma
                                </div>
                                <MdOutlineFileDownload />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Col className="p-2">
                        <h6>Reply {">"} </h6>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias
                        atque impedit officia porro maiores illum quae eveniet.
                    </Col>
                </Col>

                <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
                    <Row className="p-2 m-0">
                        <Col
                            md={4}
                            className="d-flex align-items-center justify-content-start p-0 m-0"
                            style={{ gap: "10px" }}
                        >
                            {" "}
                            <div className="parent-complain-image">
                                <img
                                    src={
                                        "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                                    }
                                    alt="userImg"
                                />{" "}
                            </div>{" "}
                            <h5>Cody Fisher</h5>{" "}
                        </Col>
                        <Col
                            md={8}
                            className="d-flex align-items-center justify-content-end"
                            style={{ fontSize: "18px" }}
                        >
                            Subject: Introduction to Thermodynamics
                        </Col>
                    </Row>
                    <Col className="d-flex align-items-center justify-content-between p-2">
                        <Col md={6}> 5 Jun 2023</Col>
                        <Col
                            md={6}
                            className="d-flex align-items-center justify-content-end"
                        >
                            <Chip
                                label="Open"
                                className="n-box-shadow"
                                style={{
                                    color: "black",
                                    borderColor: "var(--yellow-color)",
                                    background: "var(--yellow-color)",
                                }}
                                color="primary"
                            />
                        </Col>
                    </Col>
                    <hr />
                    <Col className="p-2">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias
                        atque impedit officia porro maiores illum quae eveniet, odio ad non
                        expedita eius earum reiciendis sequi assumenda optio dignissimos
                        debitis quis similique praesentium. Corporis debitis molestiae
                        officiis nesciunt, minima temporibus possimus nisi quisquam, in
                        perferendis ipsam, hic ea quidem dolorem veniam assumenda aspernatur
                        maiores. Repudiandae reiciendis aspernatur omnis nobis adipisci
                        totam.
                    </Col>
                </Col>
            </StyledContainer>
        </OperationLayout>
    )
}

const StyledContainer = styled.div`
  .card {
    transition: all 0.3s ease-in-out;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      background: var(--color-primary);
      transform: translate(-6px, -6px);
    }
  }

  .btns {
    background: var(--color-primary);
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
  }

  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 200px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }
`;

export default TeacherReport