import React, { useState } from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row, Table } from 'react-bootstrap';
import { CiLink } from "react-icons/ci";
import { LuFolderSymlink } from "react-icons/lu";
import { Button, Checkbox } from '@mui/material';
import { PiScreencastFill } from "react-icons/pi";
import { GrFormView } from "react-icons/gr";
import { GrAdd } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { FaBlogger, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { IoServer } from "react-icons/io5";
import { AiOutlineCloudServer } from "react-icons/ai";
import { FaAws } from "react-icons/fa";

function InformationTechnology(props) {
    const { history } = props;
    const [isEmbed, setIsEmbed] = useState(true);
    const [isApplication, setIsApplication] = useState(false);
    const [isAws, setIsAws] = useState(false);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <>
            <h5>Information Technology</h5>
       {/* <OperationLocation path={history} /> */}
            <Row>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3' onClick={() => setIsEmbed(!isEmbed)}>
                        <div className="d-flex align-items-end justify-content-start">
                            <CiLink size={32} />
                            <h6 className='ms-3 text-center'>Embed Link</h6>
                        </div>
                        <div>
                            {isEmbed ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                    {
                        isEmbed && (
                            <>
                                <Col className='border-top p-2 ps-2 ps-md-4'>
                                    <div className="d-flex align-items-end justify-content-start">
                                        <LuFolderSymlink size={22} />
                                        <h6 className='ms-3 text-center'>Embed URL</h6>
                                    </div>
                                    <p className="mt-2 mb-2">Paste a link to the content you want to display</p>
                                    <Row>
                                        <Col md={8}>
                                            <Form.Group controlId="nameInput">
                                                <Form.Control className='rounded-5 shadow border' type="text" placeholder="Embeded URL" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Button className='rounded-5 shadow' variant="outlined" size="medium">
                                                Embed
                                            </Button>
                                        </Col>
                                    </Row>
                                    <p className="mt-2 mb-2 text-primary">learn more</p>
                                    <p className="mt-2 mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <Col md={6} className='mb-3'>
                                        <Button className='rounded-5 shadow' variant="outlined" size="medium">
                                            Try Again
                                        </Button>
                                        <Button className='rounded-5 shadow ms-2' variant="outlined" size="medium">
                                            Convert To Link
                                        </Button>
                                    </Col>
                                </Col>
                            </>
                        )
                    }
                </div>
            </Row>
            <Row className='mt-3'>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3' onClick={() => setIsApplication(!isApplication)}>
                        <div className="d-flex align-items-end justify-content-start">
                            <PiScreencastFill size={28} />
                            <h6 className='ms-3 text-center'>Application</h6>
                        </div>
                        <div>
                            {isApplication ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                    {
                        isApplication && (
                            <>
                                <Col className='border-top p-2 ps-2 ps-md-4'>
                                    <div className="d-flex align-items-end justify-content-start">
                                        <GrFormView size={32} />
                                        <h6 className='ms-3 text-center'>View Application</h6>
                                    </div>
                                    <Table className='text-center mt-3 mb-3' responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Emp No.</th>
                                                <th>Application Status</th>
                                                <th>E-mail ID</th>
                                                <th>Details Open</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>123</td>
                                                <td>Approved</td>
                                                <td>employee1@example.com</td>
                                                <td className='text-primary'>open</td>
                                            </tr>
                                            <tr>
                                                <td>123</td>
                                                <td>Approved</td>
                                                <td>employee1@example.com</td>
                                                <td className='text-primary'>open</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Col md={6} className='mb-3'>
                                        <Button startIcon={<GrAdd />} className='rounded-5 shadow' variant="outlined" size="medium">
                                            Add Application
                                        </Button>
                                        <Button startIcon={<MdDelete />} className='rounded-5 shadow ms-2' variant="outlined" size="medium">
                                            Delete Application
                                        </Button>
                                    </Col>
                                </Col>
                            </>
                        )
                    }
                </div>
            </Row>
            <Row className='mt-3'>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3'>
                        <div className="d-flex align-items-end justify-content-start">
                            <FaBlogger size={28} />
                            <h6 className='ms-3 text-center'>Blogs</h6>
                        </div>
                        <div>
                            {isEmbed ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                </div>
            </Row>
            <Row className='mt-3'>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3'>
                        <div className="d-flex align-items-end justify-content-start">
                            <FaServer size={28} />
                            <h6 className='ms-3 text-center'>Change Server</h6>
                        </div>
                        <div>
                            {isEmbed ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                </div>
            </Row>

            <Row className='mt-3'>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3' onClick={() => setIsAws(!isAws)}>
                        <div className="d-flex align-items-end justify-content-start">
                            <IoServer size={28} />
                            <h6 className='ms-3 text-center'>AWS Settings</h6>
                        </div>
                        <div>
                            {isAws ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                    {
                        isAws && (
                            <>
                                <Col className='border-top p-2 ps-2 ps-md-4'>
                                    <div className="d-flex align-items-end justify-content-start">
                                        <FaAws size={32} />
                                        <h6 className='ms-3 text-center'>Amazon Web Services (AWS) Settings</h6>
                                    </div>
                                    <Row className='mt-3 mb-3 ps-3'>Settings For AWS Data Sources</Row>
                                    <Row className='mt-3 mb-3 ps-3'>Configuration Information</Row>
                                    <Row className='mt-3 mb-3 ps-3 border-bottom pb-2'>
                                        <Col md={8} className='p-2 rounded-2' style={{ width: 'fit-content', backgroundColor: '#F5EEFF' }}>View Configuration Information for this EC2 instance.</Col>
                                    </Row>
                                    <Row className='mt-3 mb-3 ps-3'>Enable AWS Security Group Changes</Row>
                                    <Row className='border-bottom pb-3 mt-3 mb-3'>
                                        <Col md={1}>
                                            <Checkbox {...label} />
                                        </Col>
                                        <Col md={7}>Automatically create and update AWS security groups to support AWS data sources. When disabled, all security groups must be managed manually.</Col>
                                        <Col md={4}>
                                            <Button className='rounded-5 shadow' variant="outlined" size="medium">
                                                change
                                            </Button>
                                            <Button className=' bg-danger text-white border-0 ms-3 rounded-5 shadow' variant="outlined" size="medium">
                                                cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3 mb-3 ps-3'>Security Group Name</Row>
                                    <Row className='border-bottom pb-3 mt-3 mb-3'>
                                        <Col md={8}>
                                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</span>
                                            <Col md={12} className='mt-3 mb-3'>
                                                <Form.Group controlId="nameInput">
                                                    <Form.Control className='shadow rounded-5' type="text" placeholder="JRSSecurityGroup-1.52436" />
                                                </Form.Group>
                                            </Col>
                                        </Col>
                                        <Col md={4}>
                                            <Button className='rounded-5 shadow' variant="outlined" size="medium">
                                                change
                                            </Button>
                                            <Button className=' bg-danger text-white border-0 ms-3 rounded-5 shadow' variant="outlined" size="medium">
                                                cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3 mb-3 ps-3'>Security Group Description</Row>
                                    <Row >
                                        <Col md={8}>
                                            <span>This description will be displayed in the AWS Console.</span>
                                            <Col md={12} className='mt-3 mb-3'>
                                                <Form.Group controlId="nameInput">
                                                    <Form.Control className='shadow rounded-5' type="text" placeholder="JRSSecurityGroup-1.52436" />
                                                </Form.Group>
                                            </Col>
                                        </Col>
                                        <Col md={4}>
                                            <Button className='rounded-5 shadow' variant="outlined" size="medium">
                                                change
                                            </Button>
                                            <Button className=' bg-danger text-white border-0 ms-3 rounded-5 shadow' variant="outlined" size="medium">
                                                cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )
                    }
                </div>
            </Row>

            <Row className='mt-3'>
                <div className="shadow border rounded-3 p-0 cursor-pointer">
                    <Col md={12} className='p-2 d-flex align-items-center justify-content-between px-3'>
                        <div className="d-flex align-items-end justify-content-start">
                            <AiOutlineCloudServer size={28} />
                            <h6 className='ms-3 text-center'>Storage Status</h6>
                        </div>
                        <div>
                            {isEmbed ? <FaCaretUp /> : <FaCaretDown /> }
                            </div>
                    </Col>
                </div>
            </Row>
        </>
    )
}

export default InformationTechnology