// BankDetailsPageConfig
import BankDetailsPage from "./BankDetailsPage";

export const BankDetailsPageConfig = {
  routes: [
    {
      path: "/account/bank-details",
      exact: true,
      component: BankDetailsPage
    }
  ]
};
