import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Badge, Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { MdOutlineFileDownload, MdReplyAll } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import axios from "../../utils/axios";
import Moment from "react-moment";
const ProfilePic = require("../../assets/parent/profile-user.jpg");

const Sugessions = () => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isNewComplet, setIsNewSuggestion] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const token = useSelector((state) => state.token.value);

  const handleShowModal = (newComplet) => {
    setShowModal(true);
    setIsNewSuggestion(newComplet);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const handleRaiseSuggestion = () => {
    console.log("Suggestion raised:", title, description);
    handleCloseModal();
  };

  const fetchSuggestions = async () => {
    try {
      const res = await axios.get("/suggestion", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.data.res_type === "success") {
        setSuggestions(res.data.data);
      }

      // console.log("Suggestions fetched:", res.data);
    } catch (error) {
      console.log("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  return (
    <TeacherLayout>
      <h5>Suggestions</h5>
      {/* <OperationLocation path={history} /> */}

      <Col className="d-flex align-items-center justify-content-between">
        {/* <Col md={3}>
                    <Col>
                        <Form.Group controlId="selectOption">
                            <Form.Select className="shadow rounded-5 mt-3">
                                <option value="option1">Suggestion type</option>
                                <option value="option2">Active</option>
                                <option value="option2">Close</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Button className="d-none" onClick={() => handleShowModal(true)} variant="outlined" style={{ borderColor: 'var(--color-primary)', borderRadius: '25px' }} endIcon={<Add />}> Raise Suggestion </Button>
                </Col> */}
        {/* <Col className='d-flex align-items-center justify-content-end' md={6}><Button variant="outlined" style={{ borderColor: 'var(--color-primary)', borderRadius: '25px', }} startIcon={<FilterAltOffIcon />}> Filter </Button></Col> */}
        <Col md={4} className="my-3">
          <Form.Control type="text" placeholder="Search suggestion" />
        </Col>
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isNewComplet && "Raise Suggestion"}
            {!isNewComplet && "Reply Suggestion"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="SuggestionTitle">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <Form.Group controlId="selectOption">
            <Form.Select className="mt-3">
              <option selected disabled value="option1">
                Select Status
              </option>
              <option value="option2">Open</option>
              <option value="option2">Pending</option>
              <option value="option3">Close</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="SuggestionDescription" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={handleDescriptionChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleRaiseSuggestion}
          >
            {isNewComplet && "Raise Suggestion"}
            {!isNewComplet && "Reply Suggestion"}
          </Button>
        </Modal.Footer>
      </Modal>

      {suggestions?.length > 0 ? (
        suggestions.map((suggestion, index) => (
          <Col key={index} className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
            <Row className="p-2 m-0">
              <Col
                md={4}
                className="d-flex align-items-center justify-content-start p-0 m-0"
                style={{ gap: "10px" }}
              >
                {" "}
                <div className="parent-complain-image">
                  <img src={ProfilePic} alt="userImg" />{" "}
                </div>{" "}
                <div>
                  <h5>
                    {suggestion?.user_id?.firstName}{" "}
                    {suggestion?.user_id?.lastName}
                  </h5>
                  <p>{suggestion?.user_id?.uid}</p>
                </div>{" "}
              </Col>
              <Col
                md={8}
                className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
                style={{ fontSize: "18px" }}
              >
                Subject: {suggestion?.content}
              </Col>
            </Row>
            <Col className="d-flex align-items-center justify-content-between p-2">
              <Col md={6}>
                <Moment format="DD-MM-YYYY">{suggestion.createdAt}</Moment>{" "}
                <br />
                {suggestion?.status === "pending" ? (
                  <Badge bg="warning" className="mt-2">
                    Pending
                  </Badge>
                ) : (
                  <Badge bg="success" className="mt-2">
                    Open
                  </Badge>
                )}
              </Col>
              {suggestion?.status === "pending" && (
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-end"
                >
                  <Button
                    onClick={() => handleShowModal(false)}
                    className="me-3 rounded-5 shadow"
                    variant="outlined"
                    startIcon={<MdReplyAll />}
                  >
                    Reply
                  </Button>
                </Col>
              )}

              {/* <Col md={6} className="d-flex align-items-center justify-content-end">
              <Chip
                label="Close"
                className="n-box-shadow"
                style={{
                  color: "white",
                  borderColor: "yellow",
                  background: "red",
                }}
                color="primary"
              />
            </Col> */}
            </Col>
            <hr />
            <Col className="p-2">{suggestion?.content}</Col>
            <Col className="mt-3">
              <h6>Attachment</h6>
            </Col>
            <Row>
              <a
                href={suggestion?.attachment_key}
                download="Suggetion Attachment File"
              >
                <Col md={3} style={{ padding: "10px" }}>
                  <div className="resorce-parent-item cursor-pointer">
                    <div className="name">
                      <FaFilePdf /> HC Verma
                    </div>
                    <MdOutlineFileDownload />
                  </div>
                </Col>
              </a>
            </Row>
          </Col>
        ))
      ) : (
        <h3 className="mt-5 text-center">No Suggetion Found</h3>
      )}
    </TeacherLayout>
  );
};

export default Sugessions;
