import React, { useEffect, useState } from 'react'
import { Modal, Button, Col, Form } from 'react-bootstrap'
import { MdDeleteOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { FaRegHeart } from 'react-icons/fa'
import { FaHeart } from 'react-icons/fa6'
import { CiEdit } from 'react-icons/ci'
import { IconButton } from '@mui/material'
import { toast } from 'react-toastify'
import { MyAPI } from '../../utils/MyAPI'
import { useSelector } from 'react-redux'

const Card2 = ({
  img,
  head,
  review,
  time,
  blogIcon,
  blog,
  bottomRowText1,
  bottomRowText2,
  bottomRowText2Icon,
  deleteButton,
  blogLikeDislike,
  editButton,
  redirect,
  link,
  fetchLearneeMal
}) => {
  let navigate = useNavigate()

  const [like, setLike] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState(null)
  const [alink, setLink] = useState('')
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const token = useSelector((state) => state.token.value)

  useEffect(() => {
    if (editButton) {
      setTitle(editButton.title)
      setDescription(editButton.description)
      setLink(editButton.external_link)
    }
  }, [editButton])

  const handleUpdateLearneeMal = async () => {

    // validate filleds
    if (!title || !description || !alink) {
      return toast.error('Please fill all fields')
    }

    //new form data
    const formData = new FormData()
    formData.append('title', title)
    formData.append('description', description)
    if (image) {
      formData.append('image', image)
    }
    formData.append('external_link', alink)
    setBtnLoading(true)
    try {
      let res = await MyAPI.PUT(`/mall?id=${editButton._id}`, formData, token)
      let { res_type, message, data, error } = res.data || res;
      if (res_type === 'success') {
        toast.success(message)
        setTitle('')
        setDescription('')
        setImage(null)
        setLink('')
        fetchLearneeMal(token)
        setShowModal(false)
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setBtnLoading(false)
    }
  }

  const handleDeleteMal = async () => {
    try {
      setBtnLoading(true)
      let res = await MyAPI.DELETE(`/mall?id=${deleteButton}`, token)
      let { res_type, message, data, error } = res.data || res;
      if (res_type === 'success') {
        toast.success(message)
        fetchLearneeMal(token)
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setBtnLoading(false)
    }
  }

  const handlenavigatetoviewblog = (link) => {
    navigate(link)
  }

  const handleNavigate = path => {
    navigate(path)
  }

  const handleEdit = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div
      className='parent_card2 shadow rounded-4 mb-3 ms-3'
    // onClick={redirect ? () => handleNavigate(redirect) : null}
    >
      <div onClick={link ? () => navigate(link) : null} className='parent_card2_top d-flex align-items-center justify-content-center pe-2 py-2 overflow-hidden position-relative'>
        <img src={img} className='rounded-3' alt='' />
      </div>
      <div className='parent_card2_bottom p-3'>
        {head && (
          <h5 className='fw-bold mt-1'>
            {head}
            {blogLikeDislike && (
              <IconButton
                onClick={() => setLike(!like)}
                className='ms-5'
                aria-label='delete'
              >
                {like ? <FaHeart color='red' /> : <FaRegHeart />}
              </IconButton>
            )}
          </h5>
        )}
        {review && time && blogIcon && blog && (
          <div className='d-flex align-items-center justify-content-between mt-3'>
            <div className='d-flex align-items-center gap-4'>
              <p className='m-0'>{review}</p>
              <p className='m-0'>{time}</p>
            </div>
            <div>
              {editButton && (
                <>
                  <IconButton aria-label='delete' onClick={handleEdit}>
                    <CiEdit color='blue' />
                  </IconButton>
                </>
              )}
              {deleteButton && (
                <>
                  <IconButton disabled={btnLoading} onClick={handleDeleteMal} aria-label='delete'>
                    <MdDeleteOutline color='red' />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        )}

        {bottomRowText1 && bottomRowText2 && bottomRowText2Icon && (
          <div className='d-flex align-items-center justify-content-between'>
            <p className='m-0 fw-bold d-flex align-items-center'>
              {bottomRowText2Icon} {bottomRowText2}
            </p>
            <p className='m-0 fw-bold'>{bottomRowText1}</p>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} style={{ zIndex: '1111', marginTop: '20vh' }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Learnie Mall </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="mt-3">
            <Form.Group controlId="formItemName">
              <Form.Label>Title</Form.Label>
              <Form.Control value={title} onChange={e => setTitle(e.target.value)} type="text" placeholder="Enter Title" />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <Form.Group controlId="formItemName">
              <Form.Label>Link</Form.Label>
              <Form.Control
                value={alink} onChange={e => setLink(e.target.value)}
                type="text" placeholder="Link" />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <Form.Group controlId="formItemName">
              <Form.Label>File</Form.Label>
              <Form.Control
                onChange={e => setImage(e.target.files[0])}
                type="file" placeholder="Enter Title" />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <Form.Group className="mt-3" controlId="formInput">
              <Form.Label>Enter Text</Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows={3}
                placeholder="Enter text..."
              />
            </Form.Group>
          </Col>
          {/* <Col md={12}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select Status</Form.Label>
              <Form.Select>
                <option value="option1">Active</option>
                <option value="option2">In Active</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button disabled={btnLoading} variant='primary' onClick={handleUpdateLearneeMal}>
            {
              btnLoading ? 'Saving...' : 'Save Changes'
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default Card2
