import CommunitiesPage from "./CommunitiesPage";

export const CommunitiesPageConfig = {
  routes: [
    {
      path: "/operation/communities",
      exact: true,
      component: CommunitiesPage
    }
  ]
};
