import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Col, Form, Table, Modal, Button, Badge } from "react-bootstrap";

const users = [
  {
    id: 1,
    courseId: "C97sg76548",
    courseName: "Course 1",
    parentID: "Parent 1",
    parentName: "Parent 1",
    studentID: "Student 1",
    studentName: "Student 1",
    teacherID: "Teacher 1",
    teacherName: "Teacher 1",
    subject: "Subject 1",
    grade: "Grade 1",
    board: "Board 1",
    noOfCycle: {
      total: 12,
      completed: 3,
    },
    noOfClass: {
      total: 34,
      pending: 12,
      completed: 22,
    },
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    holdDate: {
      startDate: "2022-09-01",
      endDate: "2022-09-30",
    },
    reason: "Some Reason",
  },
  {
    id: 2,
    courseId: "C97sg76548",
    courseName: "Course 1",
    parentID: "Parent 1",
    parentName: "Parent 1",
    studentID: "Student 1",
    studentName: "Student 1",
    teacherID: "Teacher 1",
    teacherName: "Teacher 1",
    subject: "Subject 1",
    grade: "Grade 1",
    board: "Board 1",
    noOfCycle: {
      total: 12,
      completed: 3,
    },
    noOfClass: {
      total: 34,
      pending: 12,
      completed: 22,
    },
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    holdDate: {
      startDate: "2022-09-01",
      endDate: "2022-09-30",
    },
    reason: "Some Reason",
  },
  {
    id: 3,
    courseId: "C97sg76548",
    courseName: "Course 1",
    parentID: "Parent 1",
    parentName: "Parent 1",
    studentID: "Student 1",
    studentName: "Student 1",
    teacherID: "Teacher 1",
    teacherName: "Teacher 1",
    subject: "Subject 1",
    grade: "Grade 1",
    board: "Board 1",
    noOfCycle: {
      total: 12,
      completed: 3,
    },
    noOfClass: {
      total: 34,
      pending: 12,
      completed: 22,
    },
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    holdDate: {
      startDate: "2022-09-01",
      endDate: "2022-09-30",
    },
    reason: "Some Reason",
  },
  {
    id: 4,
    courseId: "C97sg76548",
    courseName: "Course 1",
    parentID: "Parent 1",
    parentName: "Parent 1",
    studentID: "Student 1",
    studentName: "Student 1",
    teacherID: "Teacher 1",
    teacherName: "Teacher 1",
    subject: "Subject 1",
    grade: "Grade 1",
    board: "Board 1",
    noOfCycle: {
      total: 12,
      completed: 3,
    },
    noOfClass: {
      total: 34,
      pending: 12,
      completed: 22,
    },
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    holdDate: {
      startDate: "2022-09-01",
      endDate: "2022-09-30",
    },
    reason: "Some Reason",
  },
  {
    id: 5,
    courseId: "C97sg76548",
    courseName: "Course 1",
    parentID: "Parent 1",
    parentName: "Parent 1",
    studentID: "Student 1",
    studentName: "Student 1",
    teacherID: "Teacher 1",
    teacherName: "Teacher 1",
    subject: "Subject 1",
    grade: "Grade 1",
    board: "Board 1",
    noOfCycle: {
      total: 12,
      completed: 3,
    },
    noOfClass: {
      total: 34,
      pending: 12,
      completed: 22,
    },
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    holdDate: {
      startDate: "2022-09-01",
      endDate: "2022-09-30",
    },
    reason: "Some Reason",
  },
];

const HoldClassPage = () => {
  return (
    <OperationLayout>
      <h5>Hold Class Requests</h5>

      <Row className="mt-4">
        <Col md={4}>
          <Form.Control
            type="text"
            className="rounded-5"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)", minWidth: 2000 }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Parent ID</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Student ID</th>
            <th className="text-white">Student Name</th>
            <th className="text-white">Teacher Id</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Grade</th>
            <th className="text-white">Board</th>
            <th className="text-white">No. of Cycle</th>
            <th className="text-white">No. of Class</th>
            <th className="text-white">Start Date</th>
            <th className="text-white">End Date</th>
            <th className="text-white">Hold Date</th>
            <th className="text-white">Reason</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.id}</td>
              <td>{user.parentID}</td>
              <td>{user.parentName}</td>
              <td>{user.studentID}</td>
              <td>{user.studentName}</td>
              <td>{user.teacherID}</td>
              <td>{user.teacherName}</td>
              <td>{user.subject}</td>
              <td>{user.grade}</td>
              <td>{user.board}</td>
              <td>
                <Badge bg="primary" className="me-2">
                  Total: {user.noOfCycle.total}
                </Badge>{" "}
                <br />
                <Badge bg="success">
                  Completed: {user.noOfCycle.completed}
                </Badge>
              </td>
              <td>
                <Badge bg="primary" className="me-2">
                  Total: {user.noOfClass.total}
                </Badge>{" "}
                <br />
                <Badge bg="warning" className="me-2">
                  Pending: {user.noOfClass.pending}
                </Badge>{" "}
                <br />
                <Badge bg="success">
                  Completed: {user.noOfClass.completed}
                </Badge>
              </td>
              <td>{user.startDate}</td>
              <td>{user.endDate}</td>
              <td>
                <Badge bg="primary" className="me-2">
                  Start Date: {user.holdDate.startDate}
                </Badge>{" "}
                <br />
                <Badge bg="success">End Date: {user.holdDate.endDate}</Badge>
              </td>
              <td>{user.reason}</td>

              <td className="text-primary">
                <button className="btn btn-sm btn-success rounded-5 me-3 px-3">
                  Accept
                </button>
                <button className="btn btn-sm btn-danger rounded-5  px-3">
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default HoldClassPage;
