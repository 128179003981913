import React, { useEffect } from "react";
import { IoMdTime } from "react-icons/io";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
import { TiBook } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";
import Navbar from "../components/Navbar.js";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/SearchResults.css";
import Footer from "../components/Footer.js";
import c1 from "../assets/cc1.jpg";
import c2 from "../assets/cc2.jpg";
import c3 from "../assets/cc3.jpg";
import c4 from "../assets/cc4.jpg";
import { useNavigate } from "react-router-dom";

function SearchResults() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <Container className="mt-3 pt-5">
          <center>
            <h5>Results ( 6 )</h5>
          </center>
          <Row className="mt-5">
            <Col md={4} className="mb-4">
              <Cards img={c1} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c3} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

const Cards = ({ img }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/course")}
      className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative"
    >
      <img className="cource_img rounded-3 px-3" src={img} alt="" />
      <div className="heart d-flex align-items-center justify-content-center shadow">
        <FiHeart className="fs-5" />
      </div>

      <div className="d-flex px-3 align-items-center justify-content-between mt-3">
        <button className="px-3">Business</button>
        <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
      </div>

      <h5 className="mt-3 px-3">
        Learn Mandarin - Beginner <br /> Level
      </h5>

      <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3 pb-3">
        <div className="user d-flex align-items-center gap-2">
          <div className="user_img">
            <img
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
          </div>
          <p className="m-0 text-secondary">Robert Fox</p>
        </div>
        <div className="d-flex align-items-center gap-2">
          <IoStar className="star" /> (4 Reviews)
        </div>
      </div>

      <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
        <p className="m-0">
          <TiBook className="fs-5 me-1 mb-1" /> 05
        </p>
        <p className="m-0">
          <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
        </p>
        <p className="m-0">
          <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
        </p>
      </div>
    </div>
  );
};

export default SearchResults;
