import Reschedule from "./Reschedule.jsx";

export const ReschedulePageConfig = {
  routes: [
    {
      path: "/parent/reschedule",
      exact: true,
      component: Reschedule,
    },
  ],
};
