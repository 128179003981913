import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Col, Row } from "react-bootstrap";
import card_img1 from "../assets/card_img1.png";
import { IoMdHeartEmpty } from "react-icons/io";
import { GiProgression } from "react-icons/gi";
import { RiTimer2Fill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";

const LearningCards = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar />

      <Row className="px-5" style={{ marginTop: "8rem", overflowX: "hidden" }}>
        <Col>
        <h3 className="fw-bold">Find your learning here !</h3>
        </Col>
      </Row>

      <Row className="px-5 mt-4">
        <Col md={3}>
          <Cards />
        </Col>
        <Col md={3}>
          <Cards />
        </Col>
        <Col md={3}>
          <Cards />
        </Col>
        <Col md={3}>
          <Cards />
        </Col>
        <Col md={3} className="mt-4">
          <Cards />
        </Col>
        <Col md={3} className="mt-4">
          <Cards />
        </Col>
        <Col md={3} className="mt-4">
          <Cards />
        </Col>
        <Col md={3} className="mt-4">
          <Cards />
        </Col>
      </Row>

      <Row className="mt-5 ">
        <Col
          md={12}
          className="d-flex align-items-center justify-content-center"
        >
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  {/* <span class="sr-only">Previous</span> */}
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  1
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  2
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  3
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  {/* <span class="sr-only">Next</span> */}
                </a>
              </li>
            </ul>
          </nav>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default LearningCards;

const Cards = () => {
  return (
    <div className="learning_card rounded-2 overflow-hidden ">
      <div className="learning_card_top position-relative ">
        <img src={card_img1} alt="" />
        <IoMdHeartEmpty className="heart" />
      </div>
      <div className="learning_card_bottom p-2">
        <div className="learning_card_bottom_top_row d-flex align-items-center justify-content-between ">
          <p className="m-0 p-0 fw-bold p-1 rounded-1 ">Programming</p>
          <p className="m-0 p-0 d-flex align-items-center ">
            <GiProgression className="me-2 text_yellow" />
            Beginner
          </p>
          <p className="m-0 p-0 d-flex align-items-center ">
            <RiTimer2Fill className="me-2 text_yellow" />
            120 Hours
          </p>
        </div>

        <h6 className="fw-bold mt-2">
          Learn Mandarin - Beginner <br /> Level
        </h6>

        <div className="d-flex mt-2 gap-3 ">
          <div className="user_img">
            <img
              src="https://plus.unsplash.com/premium_photo-1664382465062-beb842dac1ec?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <p className="m-0 p-0 small_text line_height">
            XYZ <br className="m-0" /> 9 Years Onwards <br /> Group Class
          </p>
        </div>

        <div className="bottom_part mt-3 d-flex align-items-center justify-content-between  ">
          <div className="bottom_prt_div rounded-2 d-flex align-items-center justify-content-center flex-column ">
            <div className="d-flex align-items-center gap-2">
              <FaStar className="text_yellow mb-1" />
              <p className="m-0 p-0 fe-bold">4.3</p>
            </div>
            <p className="m-0 p-0 small_text">Rating</p>
          </div>
          <div className="bottom_prt_div rounded-2 d-flex align-items-center justify-content-center flex-column ">
            <div className="d-flex align-items-center gap-2">
              <FaStar className="text_yellow mb-1" />
              <p className="m-0 p-0 fe-bold">4.3</p>
            </div>
            <p className="m-0 p-0 small_text">Rating</p>
          </div>
          <div className="bottom_prt_div rounded-2 d-flex align-items-center justify-content-center flex-column ">
            <div className="d-flex align-items-center gap-2">
              <FaStar className="text_yellow mb-1" />
              <p className="m-0 p-0 fe-bold">4.3</p>
            </div>
            <p className="m-0 p-0 small_text">Rating</p>
          </div>
        </div>
      </div>
    </div>
  );
};
