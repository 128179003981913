import React from "react";
import { Button, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const suggestions = [
  {
    _id: "1",
    name: "John Doe",
    email: "john@gmail.com",
    phone: "1234567890",
    message: "This is a suggestion",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "john@gmail.com",
    phone: "1234567890",
    message: "This is a suggestion",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "john@gmail.com",
    phone: "1234567890",
    message: "This is a suggestion",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "john@gmail.com",
    phone: "1234567890",
    message: "This is a suggestion",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
];

const Suggestion = () => {
  const deleteHandler = (id) => {
    console.log("Delete");
  };
  return (
    <>
      <h3>Suggestion</h3>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S.No.</th>
            <th className="text-white">Name</th>
            <th className="text-white">Email</th>
            <th className="text-white">Phone</th>
            <th className="text-white">Message</th>
            <th className="text-white">Created At</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {suggestions.map((suggestion, index) => (
            <tr className="text-center bg-light" key={index}>
              <td>{index + 1}</td>
              <td>{suggestion.name}</td>
              <td>{suggestion.email}</td>
              <td>{suggestion.phone}</td>
              <td>{suggestion.message}</td>
              <td>{suggestion.createdAt.substring(0, 10)}</td>
              <td>
                <Button
                  variant="danger"
                  className="btn-sm"
                  onClick={() => deleteHandler(suggestion._id)}
                >
                 <MdDelete />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Suggestion;
