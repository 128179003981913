import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Form } from 'react-bootstrap';
import { IconButton } from '@mui/material';
import { FaRegStar, FaStar } from 'react-icons/fa';

function FeedbackModal(props) {
  const [rating, setRating] = useState(4);

  const handleStarClick = (starCount) => {
    setRating(starCount);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.toggleModal}
      style={{ zIndex: '1111', marginTop: '20vh' }}
    >
      <Modal.Header className='border-0' closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col className='d-flex align-items-center justify-content-start'>
          {[1, 2, 3, 4, 5].map((index) => (
            <IconButton
              key={index}
              aria-label='rating'
              style={{ color: index <= rating ? '#FFD947' : '' }}
            >
              {index <= rating ? <FaStar /> : <FaRegStar />}
            </IconButton>
          ))}
        </Col>
        <Col md={12} className='mt-3'>
          <h5>Comment</h5>
          <p>This is Parent FeedBack</p>
        </Col>
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <Button variant='secondary' onClick={props.toggleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FeedbackModal;
