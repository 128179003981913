import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { FaAngleRight, FaAnglesRight } from "react-icons/fa6";
import styled from "styled-components";
import { Button } from "@mui/material";
import Dropdown from "../../screens/Admin/Dropdown";
import Card2 from "../../screens/Admin/Card2";
import card2 from "../../assets/parent/card2.png";
const PinBlogPage = (props) => {
    const { history } = props;
    const [activeDropdown, setActiveDropdown] = useState(null);

    const handleDropdownChange = (dropdownId) => {
        setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
    };
    return (
        <OperationLayout>
            <h5>Pin </h5>
            {/* <OperationLocation path={history} /> */}
            <Row className="d-flex align-items-center justify-content-between">
                <Col md={3}>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="shadow rounded-5 mt-3 mb-3">
                            <option value="option1">Pin Blog</option>
                            <option value="option2">Pin Limitless Learning</option>
                            <option value="option2">Pin Meet Our Mentors</option>
                            <option value="option2">Pin Learniee</option>
                            <option value="option2">Pin Growth Journeys</option>
                            <option value="option2">Pin Brain Bites</option>
                            <option value="option2">Change Banner</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="nameInput">
                        <Form.Control className="shadow rounded-5 mt-3 mb-3" type="text" placeholder="Search" />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <h3 className="mb-2">Suggested Blogs</h3>
                </Col>
            </Row>
            <Row className="mt-3 pe-5">
                <Col md={6} className="mt-4 pe-4">
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        blogIcon={<FaAnglesRight />}
                        blog={"View blog"}
                        blogLikeDislike={true}
                    />
                </Col>
                <Col md={6} className="mt-4 ps-4">
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        blogIcon={<FaAnglesRight />}
                        blog={"View blog"}
                        blogLikeDislike={true}
                    />
                </Col>
                <Col md={6} className="mt-4 ps-4">
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        blogIcon={<FaAnglesRight />}
                        blog={"View blog"}
                        blogLikeDislike={true}
                    />
                </Col>
                <Col md={6} className="mt-4 ps-4">
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        blogIcon={<FaAnglesRight />}
                        blog={"View blog"}
                        blogLikeDislike={true}
                    />
                </Col>
            </Row>
        </OperationLayout>
    );
};


const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default PinBlogPage;
