import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "../../../oftadeh-configs/routesConfig";
import axios from "../../../utils/axios";
import ParentRegistration from "../../../ParentPages/parentRegistration/ParentRegistration";
import { useSelector } from "react-redux";
import { getCommonInfo } from "../../../utils/MyAPI";

const OftadehRoutes = () => {
  const parentId = localStorage.getItem("parentId");
  const token = useSelector((state) => state.token.value);
  const [currentStep, setCurrentStep] = useState(1);
  const [parentInfo, setParentInfo] = useState({});
  const location = useLocation();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchParentInfo = async () => {
  //     try {
  //       const { data } = await getCommonInfo(token);
  //       // console.log("Parent info: ", data);
  //       if (data.res_type === "success") {
  //         setParentInfo(data.data);
  //       }
  //     } catch (error) {
  //       console.log("Error fetching parent info: ", error);
  //     }
  //   };
  //   fetchParentInfo();
  // }, []);

  // useEffect(() => {
  //   if (parentInfo) {
  //     console.log("Parent info: ", parentInfo);
  //   }
  // }, []);

  // // if currentStep is not 3 then no link should be accessible except the registration page
  // if (currentStep !== 3) {
  //   return (
  //     <Routes>
  //       <Route path="/parent/registration" element={<ParentRegistration />} />
  //     </Routes>
  //   );
  // }

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
          {...route}
        />
      ))}
    </Routes>
  );
};

export default OftadehRoutes;
