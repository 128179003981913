import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import gsap from 'gsap';
import { Col, Container, Row } from 'react-bootstrap';
import '../assets/css/Testimonials.css';
import Footer from '../components/Footer';
const user_1 = require('../assets/user_1.jpg');
const user_2 = require('../assets/user_2.jpg');
const user_3 = require('../assets/user_3.jpg');
function Testimonials() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []); 

    return (
        <>
            <div className="main">
                <Navbar />
                <br />
                <br />
                <br />
                <Container className='mt-5'>
                    <center><h4 className='testimonial-heading'>WHAT CLIENTS SAY</h4></center>
                    <center><div className='horizontal-hr-testimonial'>&nbsp;</div></center>
                    <center>
                        <p className='testimonials-peragraph mt-3'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non sed aspernatur molestiae. Ea animi ullam repellendus alias, culpa itaque delectus dolorem blanditiis praesentium perspiciatis molestiae iste ipsum consequuntur sunt commodi!</p>
                    </center>
                </Container>

                <Container className='mt-5'>
                    <Row>
                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_1} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>
                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_2} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>

                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_3} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>
                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_3} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>
                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_2} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>
                        <Col md={4} className='p-5'>
                            <div className="testimonial-cards-item shadow rounded-2">
                            <div className="testimonial-cards-top-img">
                                <img src={user_1} alt="" />
                            </div>
                            <br />
                            <br />
                            <center><h5>DIANNA KIMWEALTH</h5></center>
                            <p className="profession-here">Robo Constuction</p>
                            <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default Testimonials