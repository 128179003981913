// WebsiteSetting
import VacancyPage from "./VacencyPage";
import VacencyWithId from "./VacencyWithId";

export const VacancyPageConfig = {
  routes: [
    {
      path: "/operation/Vacancy",
      exact: true,
      component: VacancyPage
    },
    {
      path: "/operation/Vacancy/:id",
      exact: true,
      component: VacencyWithId
    },
  ]
};
