import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

const Sugessions = (props) => {
    const { history } = props;
    const navigate = useNavigate();

    return (
        <OperationLayout>
            <h5>Sugessions</h5>
            {/* <OperationLocation path={history} /> */}

            <Row className="justify-content-between">
                <Col md={4}>
                    <Form.Group controlId="selectOption">
                        <Form.Select  className='shadow rounded-3 mt-3'>
                            <option selected disabled >select type</option>
                            <option value={'1'}>Employee</option>
                            <option value={'1'}>Student</option>
                            <option value={'2'}>Teacher</option>
                            <option value={'2'}>Parent</option>
                            <option value={'2'}>Class</option>
                            <option value={'2'}>Cources</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4} className='mt-3'>
                    <Form.Group controlId='nameInput'>
                        <Form.Control
                            className='shadow rounded-3'
                            type='text'
                            placeholder='Search...'
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Container className="shadow rounded-3 p-3 mt-3 mb-3">
                <Row>
                    <Col>
                        <h6>John Smith</h6>
                        <p>12 March 2023</p>
                    </Col>
                </Row>
                <Row>
                    <Col><p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam maxime, labore quasi eos cupiditate quia est aut ullam accusamus omnis doloremque molestiae cum, optio voluptatibus incidunt, quisquam animi repellat nemo.</p></Col>
                </Row>
            </Container>

            <Container className="shadow rounded-3 p-3 mt-3 mb-3">
                <Row>
                    <Col>
                        <h6>John Smith</h6>
                        <p>12 March 2023</p>
                    </Col>
                </Row>
                <Row>
                    <Col><p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam maxime, labore quasi eos cupiditate quia est aut ullam accusamus omnis doloremque molestiae cum, optio voluptatibus incidunt, quisquam animi repellat nemo.</p></Col>
                </Row>
            </Container>

            <Container className="shadow rounded-3 p-3 mt-3 mb-3">
                <Row>
                    <Col>
                        <h6>John Smith</h6>
                        <p>12 March 2023</p>
                    </Col>
                </Row>
                <Row>
                    <Col><p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam maxime, labore quasi eos cupiditate quia est aut ullam accusamus omnis doloremque molestiae cum, optio voluptatibus incidunt, quisquam animi repellat nemo.</p></Col>
                </Row>
            </Container>

        </OperationLayout>
    );
};


const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default Sugessions;
 