// TermCondition
import TermAddPage from "./TermEditPage";
import TermConditionPage from "./TermConditionPage";
import TermEditPage from "./TermEditPage";

export const TermConditionPageConfig = {
  routes: [
    {
      path: "/operation/term-condition",
      exact: true,
      component: TermConditionPage
    },
    {
      path: "/operation/term-condition/add",
      exact: true,
      component: TermAddPage
    },
    {
      path: "/operation/term-condition/edit",
      exact: true,
      component: TermEditPage
    },
  ]
};
