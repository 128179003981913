import axios from "axios";

// Set baseURL conditionally based on the environment (development or production)
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000" // Development API URL
      : "https://stingray-app-fvhou.ondigitalocean.app/", // Production API URL
});

export default instance;

// Set chatServerUrl conditionally
export const chatServerUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000" // Development Chat Server URL
    : "https://stingray-app-fvhou.ondigitalocean.app/"; // Production Chat Server URL
