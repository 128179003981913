// Search
import SearchPage from "./SearchPage";
import Sugessions from "./Sugessions";

export const SearchPageConfig = {
  routes: [
    {
      path: "/operation/Search",
      exact: true,
      component: SearchPage
    },
    {
      path: "/operation/sugession",
      exact: true,
      component: Sugessions
    }
  ]
};