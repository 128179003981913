import Payment from "./Payment.jsx";

export const PaymentPageConfig = {
  routes: [
    {
      path: "/parent/payment",
      exact: true,
      component: Payment,
    },
  ],
};
