import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { LiaSortNumericUpSolid } from "react-icons/lia";

const data = [
  {
    id: 1,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 2,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 3,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 4,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 5,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 6,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 7,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
  {
    id: 8,
    employeeId: "EMP656",
    employeeName: "John Doe",
    paymentFor: "Salary",
    description: "Payment for the month of May",
    dateAndTime: "12/05/2021 12:00:00",
    amount: 1000,
    bankAccountNo: "123456789",
  },
];

const OtherReqPage = () => {
  return (
    <AccountLayout>
      <h3>Other Requests</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search by name or ID"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              placeholder="All Transactions"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <button className="bg-transparent rounded-4 py-2 px-3 border-yellow fw-semibold">
            Reset
          </button>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col
          md={4}
          className="d-flex align-items-center gap-3 justify-content-end"
        >
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4">
            <CiFilter /> Filter
          </button>
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4 ">
            <LiaSortNumericUpSolid /> Sort
          </button>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Employee ID</th>
                <th className="text-white">Employee Name</th>
                <th className="text-white">Payment For</th>
                <th className="text-white">Description</th>
                <th className="text-white">Date & Time</th>
                <th className="text-white">Amount</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => (
                <tr key={d.id}>
                  <td>{d.id}</td>
                  <td>{d.employeeId}</td>
                  <td>{d.employeeName}</td>
                  <td>{d.paymentFor}</td>
                  <td>{d.description}</td>
                  <td>{d.dateAndTime}</td>
                  <td>{d.amount}</td>
                  <td>
                    <button
                      className="rounded-4 btn btn-sm text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Modify
                    </button>{" "}
                    <br />
                    <button className="rounded-4 bg-success btn btn-sm mt-2 text-white">
                      Approve
                    </button>{" "}
                    <br />
                    <button className="rounded-4 bg-danger btn btn-sm mt-2 text-white">
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p
            className="m-0 fw-semibold text-decoration-underline text_yellow"
            style={{ cursor: "pointer" }}
          >
            View More
          </p>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default OtherReqPage;
