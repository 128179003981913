// LiveRequests
import LiveRequestsPage from "./LiveRequestsPage";
import NotificationPage from "./NotificationPage";

export const LiveRequestsPageConfig = {
  routes: [
    {
      path: "/operation/live-request",
      exact: true,
      component: LiveRequestsPage
    },
    {
      path: "/operation/notification",
      exact: true,
      component: NotificationPage
    },
  ]
};