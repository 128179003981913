import React from 'react'
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Form, Row, Table } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa6';

function VacencyWithId() {
    const dummyData = [
        { id: 1, vacancyTitle: 'Software Engineer', name: 'John Doe', email: 'john@example.com', contact: '123-456-7890', salary: '$80,000', experience: '5 years', cvLink: '/cv/john_doe_cv.pdf' },
        { id: 2, vacancyTitle: 'Marketing Manager', name: 'Jane Smith', email: 'jane@example.com', contact: '456-789-0123', salary: '$70,000', experience: '7 years', cvLink: '/cv/jane_smith_cv.pdf' },
        // Add more dummy data as needed
    ];
    return (
        <>
            <OperationLayout>
                <h5 className="my-4">Applicant for Vacancy</h5>
                <Row>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Control className='shadow rounded-5' type="text" placeholder="Search by name & id" />
                        </Form.Group>
                    </Col>
                </Row>

                <Table
                    responsive
                    striped
                    style={{
                        borderRadius: "25px",
                        backgroundColor: "var(--color-primary)",
                        color: "#fff",
                    }}
                    className="mt-4"
                >
                    <thead>
                        <tr className="text-center">
                            <th className="text-white">#</th>
                            <th className="text-white">Vacancy Title</th>
                            <th className="text-white">Name</th>
                            <th className="text-white">Email</th>
                            <th className="text-white">Contact</th>
                            <th className="text-white">Expect Salary</th>
                            <th className="text-white">Experience</th>
                            <th className="text-white">Download CV</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dummyData.map((applicant, index) => (
                            <tr key={applicant.id} className="text-center bg-light">
                                <td>{index + 1}</td>
                                <td>{applicant.vacancyTitle}</td>
                                <td>{applicant.name}</td>
                                <td>{applicant.email}</td>
                                <td>{applicant.contact}</td>
                                <td>{applicant.salary}</td>
                                <td>{applicant.experience}</td>
                                <td>
                                    <a href={applicant.cvLink} download>
                                        <FaDownload size={22} />
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </OperationLayout>
        </>
    )
}

export default VacencyWithId