import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Row, Table, Form } from "react-bootstrap";

const Recordings = () => {
  return (
    <OperationLayout>
      <h4>Class Recordings</h4>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Form.Select
                className="rounded-4"
                aria-label="Default select example"
                style={{ width: "20vw" }}
              >
                <option>Filter By</option>
                <option value="1">Newest</option>
                <option value="2">Oldest</option>
              </Form.Select>
            </Col>
            <Col md={6} className="d-flex ps-4 align-items-center">
              From: <Form.Control className="rounded-4 ms-3" type="date" />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6} className="d-flex ps-4 align-items-center">
              To: <Form.Control className="rounded-4 ms-3" type="date" />
            </Col>
            <Col md={6}>
              {" "}
              <Form.Group controlId="nameInput">
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Search..."
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
          color: "#fff",
        }}
        className="mt-4"
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">#</th>
            <th className="text-white">Class Name</th>
            <th className="text-white">Course Name</th>
            <th className="text-white">Class Date</th>
            <th className="text-white">Class Time</th>
            <th className="text-white">Duration</th>
            <th className="text-white">Recording</th>
            <th className="text-white">Transcript</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light text-center">
            <td>1</td>
            <td>Class 1</td>
            <td>Hindi</td>
            <td>01/01/2021</td>
            <td>10:00 AM</td>
            <td>1 hour</td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
          </tr>
          <tr className="bg-light text-center">
            <td>2</td>
            <td>Class 2</td>
            <td>English</td>
            <td>01/02/2021</td>
            <td>10:00 AM</td>
            <td>1 hour</td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
          </tr>
          <tr className="bg-light text-center">
            <td>3</td>
            <td>Class 3</td>
            <td>Maths</td>
            <td>01/03/2021</td>
            <td>10:00 AM</td>
            <td>1 hour</td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
            <td>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 fw-semibold"
              >
                Download
              </button>
              <button
                style={{
                  border: "1px solid var(--color-primary)",
                  color: "var(--color-primary)",
                }}
                className="btn btn-sm rounded-4 px-3 ms-2 fw-semibold"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default Recordings;
