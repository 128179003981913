import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { Button, Card, Col, Dropdown, Modal, Row, Form } from "react-bootstrap";
import { PiStudent } from "react-icons/pi";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import Card1 from "../../ParentPages/parent/Card1";
import card1 from "../../assets/parent/card1.png";
import { FaRegCalendarTimes } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Chip } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";

const ClassPage = () => {
  const [currentTab, setCurrentTab] = useState('1');
  return (
    <TeacherLayout>
      <h3>Class</h3>

      <Row>
        <Col md={3}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search by Name, Subject, Grade, Board..."
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Select value={currentTab} onChange={(e)=>setCurrentTab(e.target.value)} className="shadow rounded-5 border">
              <option value="1">1-on-1</option>
              <option value="2">Group</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={1}>
          <button className="new-button">
            Search
          </button>
        </Col>
      </Row>

      {/* <h5>Upcoming Live Classes</h5> */}
      {/* <Row className="p-4 shadow rounded-3 border border-warning">
        <Col md={6}>
          <span>http://link.com/join?123445</span>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <Chip label="Join" variant="outlined" />
        </Col>
      </Row> */}

      <StyledContainer>
        <Row className="mt-5">
          <Col>
            <h3>1-On-1 Classes</h3>
          </Col>
        </Row>

        <Row className="mt-3 m-0 p-0">
          <Col md={4}>
            <CustomeCard isGropClass={false} />
          </Col>
          <Col md={4}>
            <CustomeCard isGropClass={false}  />
          </Col>
          <Col md={4}>
            <CustomeCard isGropClass={false} />
          </Col>
          <Col md={4} className="mt-4">
            <CustomeCard isGropClass={false} />
          </Col>
          <Col md={4} className="mt-4">
            <CustomeCard isGropClass={false} />
          </Col>
          <Col md={4} className="mt-4">
            <CustomeCard isGropClass={false} />
          </Col>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .edit_drop {
    position: absolute;
    top: 105%;
    right: 0%;
    background-color: #fff;
    scale: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      padding: 5px 10px;
      background-color: #f1f1f1;
      color: #000;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--color-primary) !important;
      }
    }
  }

  .edit_drop.active {
    scale: 1;
  }
`;

export default ClassPage;

const CustomeCard = ({isGropClass}) => {

  const [editShow, setEditShow] = React.useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HandleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <>
      <div onClick={() => navigate(isGropClass ? '/teacher/class/group' : '/teacher/class/student-profile')} className="parent_cards1 mb-3 rounded-4 overflow-hidden shadow pb-4 w-100">
        <div className="parent_cards1_top d-flex align-items-center justify-content-center p-4 ">
          <img src={card1} className="rounded-3 w-100" alt="" />
        </div>
        <div className="parent_cards1_bottom mt-1 px-4 position-relative">
          <div className="d-flex align-items-center">
            <div className="col">
              <h6 className="fw-bold">Learn Mandarin - Beginner Level</h6>
            </div>
          </div>

          <Row>
            <Col xs={6}>Parent ID :</Col>
            <Col xs={6}>parent#0001</Col>
          </Row>
          <Row>
            <Col xs={6}>Student ID :</Col>
            <Col xs={6}>student#0001</Col>
          </Row>
          <Row>
            <Col xs={6}>Student Name :</Col>
            <Col xs={6}>Student Name</Col>
          </Row>
          <Row>
            <Col xs={6}>Start Date :</Col>
            <Col xs={6}>12 Jan 2024</Col>
          </Row>
          <hr />
          <div className=" w-100 d-flex align-items-start justify-content-start flex-wrap gap-2">
            <span className="px-2 py-1 ms-2 border rounded-5">18 Years</span>
            <span className="px-2 py-1 ms-2 border rounded-5">1-on-1</span>
            <span className="px-2 py-1 ms-2 border rounded-5">Grade</span>
            <span className="px-2 py-1 ms-2 border rounded-5">Board</span>
          </div>
          <hr />

          {/* <p className="mt-3 mb-2 d-flex align-items-center">
            <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
            2023, 6 pm
          </p>

          <p className="mt-3 mb-2 d-flex align-items-center">
            <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
            2023, 6 pm
          </p>

          <p className="mb-2 d-flex align-items-center">
            <PiStudent className="fs-5 text_yellow me-2" /> 26 students enrolled
          </p> */}

          <div className="mt-4 d-flex gap-3 align-items-center">
            <div className="parent_user_img">
              <img
                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                alt=""
              />
            </div>
            <div>
              <p className="small_text line_height m-0 mb-1">XYZ</p>
              <p className="small_text line_height m-0 mb-1">1-On-1</p>
              <p className="small_text line_height m-0 d-flex align-items-center">
                <IoMdStar className="me-1 text_yellow" />
                4.3
              </p>
            </div>
            {/* <button className="start_btn_card1 py-1 px-3 border-0">
              start
            </button> */}
          </div>

          {/* <div className="card1_icons">
            <div className="parent_accept ms-4 mb-2 d-flex align-items-center justify-content-center position-relative">
              <MdEdit className="fs-4" onClick={() => setEditShow(!editShow)} />
              <div
                className={`edit_drop p-2 rounded-3 shadow ${editShow === true ? "active" : ""
                  }`}
              >
                <ul>
                  <li onClick={handleShow}>Reschedule</li>
                  <li
                    onClick={() =>
                      navigate(
                        "/teacher/dashboard/pages/class/edit-class-details"
                      )
                    }
                  >
                    Edit class details
                  </li>
                </ul>
              </div>
            </div>
            <div className="parent_decline  ms-4 d-flex align-items-center justify-content-center">
              <MdOutlineDeleteOutline className="fs-4" />
            </div>
          </div> */}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Form onSubmit={HandleSubmit}>
          <Row className="p-3">
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Date:</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDate">
                <Form.Label>Time:</Form.Label>
                <Form.Control type="time" />
              </Form.Group>
            </Col>

            <Col
              md={12}
              className="mt-4 d-flex align-items-center justify-content-end"
            >
              <button className="cancel_btn" onClick={handleClose}>
                Cancel
              </button>
              <button className="reschedule_btn ms-4" type="submit">
                Reschedule
              </button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
