import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import "../../assets/css/parent/parent.css";
import { Col, Row } from "react-bootstrap";
import { IoLogoBuffer } from "react-icons/io";
import { TbApps } from "react-icons/tb";
import { SiReactquery } from "react-icons/si";
import { MdOutlinePayments, MdRequestQuote } from "react-icons/md";

const PaymentPage = () => {
  return (
    <AccountLayout>
      <h3>Payments</h3>

      <Row className="mt-5">
        <Col md={4}>
          <Cards
            icon={<IoLogoBuffer className="fs-1 text_yellow" />}
            text={"Payment logs"}
          />
        </Col>
        <Col md={4}>
          <Cards
            icon={<TbApps className="fs-1 text_yellow" />}
            text={"Apps Payments"}
          />
        </Col>
        <Col md={4}>
          <Cards
            icon={<SiReactquery className="fs-1 text_yellow" />}
            text={"View Queries"}
          />
        </Col>
        <Col md={4} className="mt-4">
          <Cards
            icon={<MdOutlinePayments className="fs-1 text_yellow" />}
            text={"View Upcoming payments"}
          />
        </Col>
        <Col md={4} className="mt-4">
          <Cards
            icon={<MdRequestQuote className="fs-1 text_yellow" />}
            text={"Refund Request"}
          />
        </Col>
        <Col md={4} className="mt-4">
          <Cards
            icon={<SiReactquery className="fs-1 text_yellow" />}
            text={"View Payment request from other department"}
          />
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default PaymentPage;

const Cards = ({ icon, text }) => {
  return (
    <div
      className="shadow rounded-4 bg-white d-flex flex-column align-items-center justify-content-center"
      style={{ height: 150 }}
    >
      {icon}
      <p className="text-center mt-3">{text}</p>
    </div>
  );
};
