import React, { useState } from "react";
import { Col, Row, Form, Table, Badge } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { MdDownload } from "react-icons/md";

const teacherPaymentData = [
  {
    id: 1,
    transactionId: "TRN-001",
    courseId: "COURSE-001",
    courseName: "Mathematics",
    parentId: "PARENT-001",
    parentName: "Parent 1",
    studentName: "Student 1",
    cycleCountNo: 1,
    classCountNo: 6 / 34,
    amount: 2000,
    status: "Paid",
    teacherStatus: "On Time",
  },
  {
    id: 2,
    transactionId: "TRN-002",
    courseId: "COURSE-002",
    courseName: "Physics",
    parentId: "PARENT-002",
    parentName: "Parent 2",
    studentName: "Student 2",
    cycleCountNo: 2,
    classCountNo: 7 / 34,
    amount: 2000,
    status: "Fine",
    teacherStatus: "On Show",
  },
  {
    id: 3,
    transactionId: "TRN-003",
    courseId: "COURSE-003",
    courseName: "Chemistry",
    parentId: "PARENT-003",
    parentName: "Parent 3",
    studentName: "Student 3",
    cycleCountNo: 3,
    classCountNo: 8 / 34,
    amount: 2000,
    status: "Paid",
    teacherStatus: "join Late",
  },
  {
    id: 4,
    transactionId: "TRN-004",
    courseId: "COURSE-004",
    courseName: "Biology",
    parentId: "PARENT-004",
    parentName: "Parent 4",
    studentName: "Student 4",
    cycleCountNo: 4,
    classCountNo: 9 / 34,
    amount: 2000,
    status: "Paid",
    teacherStatus: "On Time",
  },
  {
    id: 5,
    transactionId: "TRN-005",
    courseId: "COURSE-005",
    courseName: "English",
    parentId: "PARENT-005",
    parentName: "Parent 5",
    studentName: "Student 5",
    cycleCountNo: 5,
    classCountNo: 10 / 34,
    amount: 2000,
    status: "Fine",
    teacherStatus: "On Time",
  },
];

const parentPaymentData = [
  {
    id: 1,
    transactionId: "TRN-001",
    courseId: "COURSE-001",
    courseName: "Mathematics",
    parentId: "PARENT-001",
    parentName: "Parent 1",
    childId: "CHILD-001",
    childName: "Child 1",
    paymentDate: "14th Jul 2023, 18:54",
    cycleCountNo: 1,
    totalClass: 34,
    paymentMethod: "Online",
    amount: 2000,
  },
  {
    id: 2,
    transactionId: "TRN-002",
    courseId: "COURSE-002",
    courseName: "Physics",
    parentId: "PARENT-002",
    parentName: "Parent 2",
    childId: "CHILD-002",
    childName: "Child 2",
    paymentDate: "14th Jul 2023, 18:54",
    cycleCountNo: 2,
    totalClass: 34,
    paymentMethod: "Online",
    amount: 2000,
  },
  {
    id: 3,
    transactionId: "TRN-003",
    courseId: "COURSE-003",
    courseName: "Chemistry",
    parentId: "PARENT-003",
    parentName: "Parent 3",
    childId: "CHILD-003",
    childName: "Child 3",
    paymentDate: "14th Jul 2023, 18:54",
    cycleCountNo: 3,
    totalClass: 34,
    paymentMethod: "Online",
    amount: 2000,
  },
  {
    id: 4,
    transactionId: "TRN-004",
    courseId: "COURSE-004",
    courseName: "Biology",
    parentId: "PARENT-004",
    parentName: "Parent 4",
    childId: "CHILD-004",
    childName: "Child 4",
    paymentDate: "14th Jul 2023, 18:54",
    cycleCountNo: 4,
    totalClass: 34,
    paymentMethod: "Online",
    amount: 2000,
  },
  {
    id: 5,
    transactionId: "TRN-005",
    courseId: "COURSE-005",
    courseName: "English",
    parentId: "PARENT-005",
    parentName: "Parent 5",
    childId: "CHILD-005",
    childName: "Child 5",
    paymentDate: "14th Jul 2023, 18:54",
    cycleCountNo: 5,
    totalClass: 34,
    paymentMethod: "Online",
    amount: 2000,
  },
];

const PaymentsPage = () => {
  const [tabValue, setTabValue] = useState("1");
  return (
    <OperationLayout>
      <h5>Payments</h5>
      <Row>
        <Form.Group as={Col} xs={4} controlId="exampleForm.SelectCustom">
          <Form.Select
            className="rounded-5 shadow border-yellow"
            onChange={(e) => setTabValue(e.target.value)}
            value={tabValue}
          >
            <option value={"1"}>Employee</option>
            <option value={"2"}>Teachers</option>
            <option value={"3"}>Parents</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} xs={4} controlId="exampleForm.SelectCustom">
          <Form.Select className="rounded-5 shadow border-yellow">
            <option value="option1">Made</option>
            <option value="option1">Issue</option>
            <option value="option1">Due</option>
          </Form.Select>
        </Form.Group>
      </Row>

      {tabValue === "1" ? (
        <Table
          className="mt-5 rounded-3"
          responsive
          striped
          hover
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S.No.</th>
              <th className="text-white">Transaction Id</th>
              <th className="text-white">Course Id</th>
              <th className="text-white">Course Name</th>
              <th className="text-white">Parent Id</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Child Id</th>
              <th className="text-white">Child Name</th>
              <th className="text-white">Payment Date</th>
              <th className="text-white">Cycle Count No</th>
              <th className="text-white">Total Class</th>
              <th className="text-white">Payment Method</th>
              <th className="text-white">Amount</th>
              <th className="text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {parentPaymentData.map((payment, index) => (
              <tr key={payment.id} className="bg-light text-center">
                <td>{index + 1}</td>
                <td>{payment.transactionId}</td>
                <td>{payment.courseId}</td>
                <td>{payment.courseName}</td>
                <td>{payment.parentId}</td>
                <td>{payment.parentName}</td>
                <td>{payment.childId}</td>
                <td>{payment.childName}</td>
                <td>{payment.paymentDate}</td>
                <td>{payment.cycleCountNo}</td>
                <td>{payment.totalClass}</td>
                <td>{payment.paymentMethod}</td>
                <td>{payment.amount}</td>
                <td>
                  <button
                    className="btn "
                    style={{
                      backgroundColor: "var(--color-primary)",
                      color: "white",
                    }}
                  >
                    <MdDownload /> Invoice
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : tabValue === "2" ? (
        <Table
          className="mt-5 rounded-3"
          responsive
          striped
          hover
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S.No.</th>
              <th className="text-white">Transaction Id</th>
              <th className="text-white">Course Id</th>
              <th className="text-white">Course Name</th>
              <th className="text-white">Parent Id</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Student Name</th>
              <th className="text-white">Cycle Count No</th>
              <th className="text-white">Class Count No</th>
              <th className="text-white">Amount</th>
              <th className="text-white">Status</th>
              <th className="text-white">Teacher Status</th>
              <th className="text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {teacherPaymentData.map((payment, index) => (
              <tr key={payment.id} className="bg-light text-center">
                <td>{index + 1}</td>
                <td>{payment.transactionId}</td>
                <td>{payment.courseId}</td>
                <td>{payment.courseName}</td>
                <td>{payment.parentId}</td>
                <td>{payment.parentName}</td>
                <td>{payment.studentName}</td>
                <td>{payment.cycleCountNo}</td>
                <td>{payment.classCountNo}</td>
                <td>{payment.amount}</td>
                <td>
                  <Badge
                    bg={`${payment.status === "Paid" ? "success" : "danger"}`}
                    text="light"
                  >
                    {payment.status}
                  </Badge>
                </td>
                <td>
                  <Badge
                    bg={`${
                      payment.teacherStatus === "On Time"
                        ? "success"
                        : payment.teacherStatus === "On Show"
                        ? "warning"
                        : "danger"
                    }`}
                    text="light"
                  >
                    {payment.teacherStatus}
                  </Badge>
                </td>
                <td>
                  <button
                    className="btn "
                    style={{
                      backgroundColor: "var(--color-primary)",
                      color: "white",
                    }}
                  >
                    <MdDownload /> Invoice
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table
          className="mt-5 rounded-3"
          responsive
          striped
          hover
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S.No.</th>
              <th className="text-white">Transaction Id</th>
              <th className="text-white">Course Id</th>
              <th className="text-white">Course Name</th>
              <th className="text-white">Parent Id</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Child Id</th>
              <th className="text-white">Child Name</th>
              <th className="text-white">Payment Date</th>
              <th className="text-white">Cycle Count No</th>
              <th className="text-white">Total Class</th>
              <th className="text-white">Payment Method</th>
              <th className="text-white">Amount</th>
              <th className="text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {parentPaymentData.map((payment, index) => (
              <tr key={payment.id} className="bg-light text-center">
                <td>{index + 1}</td>
                <td>{payment.transactionId}</td>
                <td>{payment.courseId}</td>
                <td>{payment.courseName}</td>
                <td>{payment.parentId}</td>
                <td>{payment.parentName}</td>
                <td>{payment.childId}</td>
                <td>{payment.childName}</td>
                <td>{payment.paymentDate}</td>
                <td>{payment.cycleCountNo}</td>
                <td>{payment.totalClass}</td>
                <td>{payment.paymentMethod}</td>
                <td>{payment.amount}</td>
                <td>
                  <button
                    className="btn "
                    style={{
                      backgroundColor: "var(--color-primary)",
                      color: "white",
                    }}
                  >
                    <MdDownload /> Invoice
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </OperationLayout>
  );
};

export default PaymentsPage;
