import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";

const contactPage = (props) => {
    const { history } = props;


    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h5>contact Page</h5>
            <Col className="d-flex align-items-start justify-content-between mt-3">
                <Col md={6}><h6>Contact</h6></Col>
                <Col className="d-flex align-items-center justify-content-end" md={6}> <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" startIcon={<IoMdAdd />}>
                    Add
                </Button></Col>
            </Col>
            <Row className="p-2 mt-3">
                <Col md={8}>Urja Office Land-line Number</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="+91 1234567890"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Row className="p-2 mt-3">
                <Col md={8}>Learnie Recruiter Number</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="+91 1234567890"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Col className="d-flex align-items-start justify-content-between mt-3">
                <Col md={6}><h6>Emails</h6></Col>
                <Col className="d-flex align-items-center justify-content-end" md={6}> <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" startIcon={<IoMdAdd />}>
                    Add
                </Button></Col>
            </Col>
            <Row className="p-2 mt-3">
                <Col md={8}>Urja Office Admin Email ID</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="admin@learnie.in"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Row className="p-2 mt-3">
                <Col md={8}>Urja Office HR Email ID</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="hr@learnie.in"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Col className="d-flex align-items-start justify-content-between mt-3">
                <Col md={6}><h6>Whatsapp</h6></Col>
                <Col className="d-flex align-items-center justify-content-end" md={6}> <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" startIcon={<IoMdAdd />}>
                    Add
                </Button></Col>
            </Col>
            <Row className="p-2 mt-3">
                <Col md={8}>Urja Office Admin WA Numbar</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="+91 12345657890"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Row className="p-2 mt-3">
                <Col md={8}>Urja Office HR WA Number</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="+91 1234567890"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Col className="d-flex align-items-center justify-content-end mt-5">
                <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined">
                    Download Backup
                </Button>
            </Col>
        </OperationLayout>
    );
};

export default contactPage;
