import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import axios from "../../utils/axios";
import { Col, Form, Container, InputGroup, FormControl } from "react-bootstrap";
import "./Suggestions.css";
import user_image_url from "../../assets/user_1.jpg";
import { Button, FormControlLabel, Switch } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";
import { FaSearch, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Suggestions() {
  const [suggestion, setSuggestion] = useState("");
  const [attachment, setAttachment] = useState("");
  const [forTeacher, setForTeacher] = useState(false);
  const token = useSelector((state) => state.token.value);

  const handleSubmitSuggestion = async () => {
    const formData = new FormData();
    formData.append("content", suggestion);
    formData.append("attachment", attachment);
    formData.append("isTeacher", forTeacher);
    formData.append("teacher_id", "66c711ac9bc9e488b6b30ab8");

    try {
      const response = await axios.post("/suggestion", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        console.log(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <OftadehLayout>
      <h3>Suggestions</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Container
        style={{
          padding: "15px",
          borderRadius: "7px",
          // boxShadow: "1px 1px 10px #8e7f7f",
        }}
        className="mt-4 shadow border"
      >
        <Col md={12} className="mt-3">
          <Form.Group controlId="formReason">
            <Form.Label>Suggestions</Form.Label>
            <Form.Control
              placeholder="suggestions...."
              as="textarea"
              rows={3}
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={12} className="mt-3">
          <Form.Group controlId="formReason">
            <Form.Label>Select attachment</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </Form.Group>
        </Col>
        <Col md={12} className="mt-3 p-0">
          <FormControlLabel
            value={forTeacher}
            onChange={() => setForTeacher(!forTeacher)}
            control={<Switch color="primary" />}
            label="Suggestion for teacher"
            labelPlacement="end"
          />
        </Col>

        {forTeacher && (
          <Col md={6} className="forward-message-container px-3 py-3">
            <div className="forward-message-header">
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
                <FormControl placeholder="Search for teacher..." />
                <Button variant="light" className="border">
                  <FaTimes />
                </Button>
              </InputGroup>
            </div>
            <div className="forward-message-content">
              <div className="user-list">
                {/* Example user card */}
                <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                  <div className="user-details">
                    <img
                      width={45}
                      height={45}
                      src={user_image_url}
                      alt="User"
                      className="user-image rounded-5"
                    />
                    <span className="user-name ms-3 text-bold">User Name</span>
                  </div>
                  <input type="checkbox" className="user-checkbox" />
                </div>
                <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                  <div className="user-details">
                    <img
                      width={45}
                      height={45}
                      src={user_image_url}
                      alt="User"
                      className="user-image rounded-5"
                    />
                    <span className="user-name ms-3 text-bold">User Name</span>
                  </div>
                  <input type="checkbox" className="user-checkbox" />
                </div>
                <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                  <div className="user-details">
                    <img
                      width={45}
                      height={45}
                      src={user_image_url}
                      alt="User"
                      className="user-image rounded-5"
                    />
                    <span className="user-name ms-3 text-bold">User Name</span>
                  </div>
                  <input type="checkbox" className="user-checkbox" />
                </div>
                <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                  <div className="user-details">
                    <img
                      width={45}
                      height={45}
                      src={user_image_url}
                      alt="User"
                      className="user-image rounded-5"
                    />
                    <span className="user-name ms-3 text-bold">User Name</span>
                  </div>
                  <input type="checkbox" className="user-checkbox" />
                </div>
                <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                  <div className="user-details">
                    <img
                      width={45}
                      height={45}
                      src={user_image_url}
                      alt="User"
                      className="user-image rounded-5"
                    />
                    <span className="user-name ms-3 text-bold">User Name</span>
                  </div>
                  <input type="checkbox" className="user-checkbox" />
                </div>
              </div>
            </div>
          </Col>
        )}
        <Button
          className="mt-2 rounded-5"
          variant="contained"
          style={{ background: "var(--yellow-color)" }}
          onClick={handleSubmitSuggestion}
        >
          Send
        </Button>
      </Container>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default Suggestions;
