import React from 'react'
import OperationLayout from '../../components/operationComponents/OperationLayout/OperationLayout'
import OperationLocation from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import { Badge, Col, Container, Form, Row } from 'react-bootstrap'
import { Button } from '@mui/material'
import { IoMdAdd } from 'react-icons/io'
import { CiEdit } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'

const TermConditionPage = props => {
  const { history } = props
  const navigate = useNavigate()

  const handleAddTerm = () => {
    navigate('/operation/term-condition/add')
  }

  const handleEditTerm = () => {
    navigate('/operation/term-condition/edit')
  }

  return (
    <OperationLayout>
      <h5>Term & Conditions</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className='justify-content-between'>
        <Col md={4}>
          <Form.Group controlId='selectOption'>
            <Form.Select className='shadow rounded-3 mt-3'>
              <option selected disabled>
                select type
              </option>
              <option value={'1'}>Active</option>
              <option value={'2'}>In Active</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className='mt-3'>
          <Form.Group controlId='nameInput'>
            <Form.Control
              className='shadow rounded-3'
              type='text'
              placeholder='Search...'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <Col className='d-flex justify-content-end'>
          <Button
            onClick={handleAddTerm}
            className='shadow'
            variant='outlined'
            startIcon={<IoMdAdd />}
          >
            Add
          </Button>
          <Button
          onClick={handleEditTerm}
            className='ms-3 shadow bg-transparent'
            variant='outlined'
            startIcon={<CiEdit />}
          >
            Edit
          </Button>
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <h6>Term & Conditions</h6>
      </Row>
      <Container>
        <ul>
          <li className='mt-md-3 circle-list'>
            Teacher means an individual registered on the Platform as defined in
            clause 1.8 to provide teaching/tutoring services to users.{' '}
          </li>
          <li className='mt-md-3 circle-list'>
            Content means all material including profile details, courses, study
            materials, feedback etc. uploaded by a Teacher on the Platform.
          </li>
          <li className='mt-md-3 circle-list'>
            Services means teaching, tutoring or any other educational services
            provided by a Teacher to users through the Platform
          </li>
          <li className='mt-md-3 circle-list'>
            Parties means the Teacher and Urja Talents collectively referred to
            in this Agreement
          </li>
          <li className='mt-md-3 circle-list'>
            Terms means these terms and conditions including any annexures,
            schedules, exhibits and/or amendments thereto and the Privacy Policy
            collectively
          </li>
          <li className='mt-md-3 circle-list'>
            Website means the website owned and operated by Urja Talents at
            [WEBSITE URL] and any other websites/applications owned/operated by
            Urja Talents
          </li>
          <li className='mt-md-3 circle-list'>
            Urja Talents means Urja Talents Private Limited, a company
            incorporated under the Companies Act, 2013 and having its registered
            office at [REGISTERED ADDRESS].
          </li>
          <li className='mt-md-3 circle-list'>
            Platform means the online platform owned and operated by Urja
            Talents to enable connection between Teachers and users for
            educational services
          </li>
          <li className='mt-md-3 circle-list'>
            Online hours shall encompass the total duration the Teacher marks
            themselves as available for classes on the Urja Talents platform.
            This includes both active teaching time and periods spent waiting
            for student connections
          </li>
        </ul>
        <h4 className='mt-4'>Scope and Applicability</h4>
        <ul>
          <li className='mt-md-3 circle-list'>
            These Terms shall be binding upon the Teacher and Urja Talents upon
            the Teacher registering on the Platform
          </li>
          <li className='mt-md-3 circle-list'>
            These Terms govern the relationship between the Teacher and Urja
            Talents and use of the Platform by the Teacher
          </li>
          <li className='mt-md-3 circle-list'>
            3 By continuing to use the Platform, the Teacher agrees to be bound
            by the latest version of these Terms as may be in force from time to
            time.{' '}
          </li>
          <li className='mt-md-3 circle-list'>
            Urja Talents reserves the right to amend these Terms from time to
            time. An updated version will be binding upon notification to the
            Teacher through the Platform or email. Continued use of the Platform
            after such notification will constitute consent by the Teacher.
          </li>
          <li className='mt-md-3 circle-list'>
            These Terms cover the Service Terms and Privacy Policy governing use
            of the Platform. Together they constitute the legal terms between
            the Teacher and Urja Talents.
          </li>
          <li className='mt-md-3 circle-list'>
            A Teacher shall mean an individual who has submitted verified
            credentials of a recognised degree/diploma in the subjects being
            taught and whose profile has been approved and activated on the
            Platform by Urja Talents for the purpose of providing teaching
            services
          </li>
        </ul>
        <h4 className='mt-4'>Obligations of the Parties</h4>
        <ul>
          <li className='mt-md-3 number-list'>
            The Teacher affirms and undertakes that:
          </li>
          <ul>
            <li className='mt-md-3 circle-list'>
              All information provided by the Teacher to Urja Talents including
              qualifications,experience, skills etc. shall be true, accurate and
              complete{' '}
            </li>
            <li className='mt-md-3 circle-list'>
              The Teacher shall maintain the declared qualifications and skills
              and update Urja Talents of any changes.
            </li>
            <li className='mt-md-3 circle-list'>
              3 The Teacher shall not perform any services for which they are
              not duly qualified or authorized.
            </li>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not provide any career or vocational counselling
              services to students without proper certification or license.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>
            The Teacher affirms and undertakes that:
          </li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Maintaining proper decorum while interacting and teaching students
              on the Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              Not uploading any unlawful, harmful, abusive or objectionable
              content on the Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              Maintaining respectful communications with students, parents and
              Urja Talents.
            </li>
            <li className='mt-md-3 circle-list'>
              Not engaging in any acts that harm the reputation or business
              interests of Urja Talents.
            </li>
            <li className='mt-md-3 circle-list'>
              Not promoting any competing platforms or services while associated
              with Urja Talents.
            </li>
            <li className='mt-md-3 circle-list'>
              Not sharing any sensitive or internal information with
              unauthorized parties.
            </li>
            <li className='mt-md-3 circle-list'>
              Not disclosing internal policies or contractual details of Urja
              Talents.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>The Teacher shall</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Not share any personal details of themselves, friends or contacts
              with students.
            </li>
            <li className='mt-md-3 circle-list'>
              Not charge students for free services provided through the
              Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              3 Not receive any payments directly from students and shall route
              all transactions through the Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              4 Not contact or solicit students met through the Platform outside
              of the Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              5 Maintain privacy and confidentiality of any student information
              shared.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>
            The Teacher shall spend a minimum of 4 hours daily being online on
            the Platform.
          </li>
          <li className='mt-md-3 number-list'>The Teacher shall</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Not use the Platform to promote any personal services without
              verification.
            </li>
            <li className='mt-md-3 circle-list'>
              Not force or impose any personal services on students.
            </li>
            <li className='mt-md-3 circle-list'>
              Submit to penalties for misusing the Platform for personal gain.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>The Teacher shall</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Not obtain student contact information or solicit students
              personally.
            </li>
            <li className='mt-md-3 circle-list'>
              Inform students to route queries only through the Platform.
            </li>
            <li className='mt-md-3 circle-list'>
              Refer other teachers only by their Platform profile, without
              sharing other contact details
            </li>
            <li className='mt-md-3 circle-list'>
              Not use or disclose any student personal information received
              through the Platform.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>
            The Teacher shall maintain services at fair prices as determined by
            Urja Talents without associating with any competitors.
          </li>
          <li className='mt-md-3 number-list'>
            While associated with Urja Talents, the Teacher shall not work with
            or assist any competing platforms in any manner.
          </li>
        </ul>
        <h4 className='mt-4'>Usage Terms</h4>
        <ul>
          <li className='mt-md-3 number-list'>Profile Details</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall ensure that all details provided in their
              profile are true and accurate. The profile cannot be changed
              without prior approval from Urja Talents. Urja Talents shall have
              the right in perpetuity to use, reproduce and publish the
              Teacher's profile details for marketing and promotional purposes.{' '}
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Conduct</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall maintain proper decorum and conduct when using
              the platform. The Teacher shall not post any unlawful, harmful,
              threatening, abusive, harassing, defamatory or objectionable
              content. The Teacher shall not promote any competitors of Urja
              Talents or disclose any internal policies of Urja Talents
            </li>
            <li className='mt-1'>
              Teachers shall treat all users approaching through Urja Talents
              with courtesy and respect. Any instance of abusive language,
              threats, or other unprofessional behavior will result in temporary
              deactivation (minimum 3 days) and a penalty of Rs. 5,100 for
              reactivation. Failure to pay the penalty within 3 days will result
              in an additional daily penalty of Rs. 100 until payment is
              received.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Personal Information </li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not share any personal details of themselves or
              customers with any third party. The Teacher shall not charge
              customers for any free services provided through the platform. The
              Teacher shall not collect any payments from customers outside the
              platform.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Time Commitment</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall spend a minimum of 4 hours daily being online
              and available on the platform. In case the Teacher fails to meet
              the minimum hours requirement, Urja Talents may reduce priority
              and allocation of customer leads to the Teacher.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Platform Misuse </li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not use the platform for any services or
              products not listed or approved on the platform. The Teacher shall
              not force any services or products upon customers
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Direct Contact Prohibition</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not obtain customer contact details from the
              platform. The Teacher can only refer other Teachers or services
              via their profiles on the platform.{' '}
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Credential Misuse</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not share or misuse their login credentials. The
              Teacher shall not impersonate any other users of the platform. The
              Teacher shall disclose all devices used to access the platform.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Pricing Commitment</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall adhere to agreed pricing for services. The
              Teacher shall not associate or provide services to any competitors
              of Urja Talents.{' '}
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Non-solicitation, Non-compete</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher shall not work with or induce others to work with
              competitors of Urja Talents in any manner during association and
              post-termination with Urja Talents.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>
            Feedback and Communication Decorum
          </li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Teachers agree to maintain a professional and respectful tone when
              responding to feedback from students, parents, or Urja Talents
              staff. Failure to do so may result in disciplinary action,
              including temporary or permanent deactivation.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Minimum Online Presence</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Teacher agrees to maintain a minimum online presence of four (4)
              hours per week on the Urja Talents website. This minimum shall be
              calculated based on the cumulative time spent online by the
              Teacher during each week.
            </li>
            <li className='mt-md-1'>
              Maintaining this minimum online presence will enhance the
              Teacher's credibility and visibility among users, potentially
              leading to increased student leads and opportunities
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Non-Compliance Consequences</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              In the event the Teacher fails to meet the minimum weekly online
              requirement, their profile priority for receiving student leads
              may be diminished. This means they may be less likely to receive
              assigned students as compared to Teachers who demonstrate higher
              online engagement.
            </li>
          </ul>
        </ul>
        <h4 className='mt-4'>Intellectual Property</h4>
        <ul>
          <li className='mt-md-3 number-list'>Ownership of Content</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Urja Talents shall solely and exclusively own all rights, title
              and interests in the Teacher Profile and any Content uploaded by
              the Teacher on the Platform. The Teacher hereby assigns in
              perpetuity all its rights, title and interests in the Teacher
              Profile and Content, if any, to Urja Talents.{' '}
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Licence to Urja Talents</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              The Teacher grants to Urja Talents a non-exclusive, royalty-free,
              worldwide, perpetual licence to use, reproduce, distribute,
              modify, adapt, publish, translate, create derivative works from,
              and publicly display the Teacher Profile and Content.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Usage Rights</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              Urja Talents shall have the right to use the Teacher Profile and
              Content for marketing, promotional and branding activities on any
              medium. Urja Talents may also permit its contractors, vendors and
              partners to use the Teacher Profile and Content.
            </li>
          </ul>
          <li className='mt-md-3 number-list'>Survival of Rights</li>
          <ul>
            <li className='mt-md-3 circle-list'>
              All rights of Urja Talents over the Teacher Profile and Content
              shall survive the termination of this Agreement and Urja Talents
              can continue to use such Teacher Profile and Content post
              termination of this Agreement
            </li>
          </ul>
        </ul>
      </Container>
    </OperationLayout>
  )
}

export default TermConditionPage
