import React from "react";
import { Navigate } from "react-router-dom";

// import { DashboardPageConfig } from "../ParentPages/dashboard/DashboardPageConfig";
import { CalendarPageConfig } from "../ParentPages/calendar/CalendarPageConfig";
import { ForgotPasswordPageConfig } from "../ParentPages/auth/forgot-password/ForgotPasswordPageConfig";
import { LoginPageConfig } from "../ParentPages/auth/login/LoginPageConfig";
import { RegisterPageConfig } from "../ParentPages/auth/register/RegisterPageConfig";
import { ChatPageConfig } from "../ParentPages/chat/ChatPageConfig";
import { ReferralPageConfig } from "../ParentPages/Referral/ReferralPageConfig";
import { ParentPageConfig } from "../ParentPages/parent/ParentPageConfig";
import { ParentRegistrationPageConfig } from "../ParentPages/parentRegistration/ParentRegistrationConfig";
import { FreeDemoConfig } from "../ParentPages/freeDemo/FreeDemoConfig";
import { CoursesConfig } from "../ParentPages/courses/CoursesConfig";
import { FavoritesConfig } from "../ParentPages/favorites/FavoritesConfig";
import { BlogConfig } from "../ParentPages/blogs/BlogPageConfig";
import { TranscriptConfig } from "../ParentPages/transcript/TranscriptPageConfig";
import { LearnieMallConfig } from "../ParentPages/learnieMall/LearnieMallConfig";
import { TeacherConfig } from "../ParentPages/teacher/TeachePageConfig";
import { GiftPageConfig } from "../ParentPages/gift/GiftPageConfig";
import { GoLivePageConfig } from "../ParentPages/goLive/GoLivePageConfig";
import { ReviewPageConfig } from "../ParentPages/reviews/ReviewPageConfig";
import { PreferencePageConfig } from "../ParentPages/Preferences/PreferencesPageConfig.jsx";
import { NotificationsPageConfig } from "../ParentPages/Notifications/NotificationsPageConfig.jsx";
import { SettingsPageConfig } from "../ParentPages/Settings/SettingsPageConfig.jsx";
import { ReschedulePageConfig } from "../ParentPages/Reschedule/ReschedulePageConfig.jsx";
import { PaymentPageConfig } from "../ParentPages/Payment/PaymentPageConfig.jsx";
import { HomeTuationPageConfig } from "../ParentPages/HomeTuation/HomeTuationPageConfig.jsx";
import { FAQParentPageConfig } from "../ParentPages/FAQParent/FAQParentPageConfig.jsx";
import { ResourcesPageConfig } from "../ParentPages/Resources/ResourcesPageConfig.jsx";
import { SuggestionsPageConfig } from "../ParentPages/Suggestions/SuggestionsPageConfig.jsx";
import { ComplainPageConfig } from "../ParentPages/Complain/ComplainPageConfig.jsx";
import { ProfilePageConfig } from "../ParentPages/Profile/ProfilePageConfig.jsx";
import { HomeWorkPageConfig } from "../ParentPages/HomeWork/HomeWorkPageConfig.jsx";
import { ManualDemoPageConfig } from "../ParentPages/ManualDemo/ManualDemoPageConfig.jsx";
import { DashboardPageConfig } from "../ParentPages/dashboard/DashboardPageConfig.jsx";
import { PtaMeetingPageConfig } from "../ParentPages/PTAMeeting/PtaMeetingPageConfig.jsx";

const routeConfigs = [
  // ...DashboardPageConfig.routes,
  ...CalendarPageConfig.routes,
  ...ForgotPasswordPageConfig.routes,
  ...LoginPageConfig.routes,
  ...RegisterPageConfig.routes,
  ...ChatPageConfig.routes,
  ...ReferralPageConfig.routes,
  ...ParentPageConfig.routes,
  ...ParentRegistrationPageConfig.routes,
  ...FreeDemoConfig.routes,
  ...CoursesConfig.routes,
  ...FavoritesConfig.routes,
  ...BlogConfig.routes,
  ...TranscriptConfig.routes,
  ...LearnieMallConfig.routes,
  ...TeacherConfig.routes,
  ...GiftPageConfig.routes,
  ...GoLivePageConfig.routes,
  ...ReviewPageConfig.routes,
  ...PreferencePageConfig.routes,
  ...NotificationsPageConfig.routes,
  ...SettingsPageConfig.routes,
  ...ReschedulePageConfig.routes,
  ...PaymentPageConfig.routes,
  ...HomeTuationPageConfig.routes,
  ...FAQParentPageConfig.routes,
  ...ResourcesPageConfig.routes,
  ...SuggestionsPageConfig.routes,
  ...ComplainPageConfig.routes,
  ...ProfilePageConfig.routes,
  ...HomeWorkPageConfig.routes,
  ...ManualDemoPageConfig.routes,
  ...PtaMeetingPageConfig.routes,
];

const routes = [
  ...routeConfigs,
  {
    element: <Navigate to="/parent/sign-in" replace />,
  },
  // {
  //   path: "/test",
  //   exact: true,
  //   component: <Example />
  // }
];

export default routes;
