import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const EditPage = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [teacherData, setTeacherData] = useState({});
  const [introductionVideo, setIntroductionVideo] = useState(null);
  const [teacherImage, setTeacherImage] = useState(null);
  const [visibleName, setVisibleName] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [viewButtonVisible, setViewButtonVisible] = useState(false);
  const [viewButtonVisibleVideo, setViewButtonVisibleVideo] = useState(false);

  const handleVideoModalClose = () => setShowVideoModal(false);
  const handleVideoModalShow = () => setShowVideoModal(true);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  // Preview for image
  const handlePreviewImage = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const { data } = await axios.get("/teacher/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.res_type === "success") {
          const { teacher } = data.data;
          setTeacherData(teacher);
          setVisibleName(teacher?.visibleName);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeacherData();
  }, [token]);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setIntroductionVideo(file);
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoPreview(videoUrl);
      setViewButtonVisibleVideo(true);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setTeacherImage(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setViewButtonVisible(true);
    }
  };

  const handleUpdate = async () => {
    // if no video and image is selected then return and show error
    if (!introductionVideo) {
      toast.error("Please select video to update");
      return;
    }

    if (!teacherImage) {
      toast.error("Please select image to update");
      return;
    }

    const form = new FormData();

    form.append("visibleName", visibleName);

    if (introductionVideo) {
      form.append("introduction_video", introductionVideo);
    }

    if (teacherImage) {
      form.append("image", teacherImage);
    }

    try {
      setLoading(true);
      const res = await axios.put("/teacher/profile", form, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        setVisibleName(res.data.data.visibleName);
        setIntroductionVideo(null);
        setTeacherImage(null);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeacherLayout>
      <h3>Edit Profile</h3>

      <div className="container mt-4 bg-white rounded-3 border border-2 shadow py-4 px-3">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3 mt-3">
            {/* Introduction Video */}
            <Form.Group
              as={Col}
              className="mt-2"
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Introduction Video</Form.Label>
              <Form.Control
                type="file"
                placeholder="Introduction Video"
                onChange={handleVideoChange}
              />
              {viewButtonVisibleVideo && (
                <Button
                  className="btn btn-sm btn-primary mt-2"
                  onClick={handleVideoModalShow}
                >
                  View Video
                </Button>
              )}
            </Form.Group>

            {/* Teacher Image */}
            <Form.Group
              className="mt-2"
              as={Col}
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Teacher Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="Teacher Image"
                onChange={handleImageChange}
              />
              {viewButtonVisible && (
                <Button
                  className="btn btn-sm btn-primary mt-2"
                  onClick={handlePreviewImage}
                >
                  View Image
                </Button>
              )}
            </Form.Group>

            {/* Visible Name */}
            <Form.Group
              className="mt-2"
              as={Col}
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Visible Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Visible Name"
                value={visibleName}
                onChange={(e) => setVisibleName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <button
            className="border-0 bg-yellow px-4 py-2 rounded-3 mt-3 text-white"
            type="button"
            onClick={handleUpdate}
          >
            {loading ? "Loading..." : "Update"}
          </button>
          <button
            className="border-0 ms-3 bg-yellow px-4 py-2 rounded-3 mt-3 text-white"
            type="button"
            onClick={() => navigate("/teacher/TeacherProfile/edit/next")}
          >
            Next
          </button>
        </Form>
      </div>

      {/* Video Preview Modal */}
      <Modal
        style={{ zIndex: "142512001" }}
        show={showVideoModal}
        onHide={handleVideoModalClose}
        size="lg"
        centered
      >
        <Modal.Body className="p-4 m-0">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  background: "#000",
                }}
              >
                <video
                  controls
                  autoPlay
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <source src={videoPreview} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Image Preview Modal */}
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imagePreview && (
            <img src={imagePreview} alt="preview" style={{ width: "100%" }} />
          )}
        </Modal.Body>
      </Modal>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default EditPage;
