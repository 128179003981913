import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Chip } from "@mui/material";
import { MdOutlineFileDownload, MdReplyAll } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";

import ProfilePic from "../../assets/parent/profile-user.jpg";

function ClientComplaint() {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isNewComplet, setIsNewComplaint] = useState(false);

  const handleShowModal = (newComplet) => {
    setShowModal(true);
    setIsNewComplaint(newComplet);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const handleRaiseComplaint = () => {
    console.log("Complaint raised:", title, description);
    handleCloseModal();
  };
  return (
    <AccountLayout>
      <h5>Client Complaint</h5>
      <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
        <Row className="p-2 m-0">
          <Col
            md={4}
            className="d-flex align-items-center justify-content-start p-0 m-0"
            style={{ gap: "10px" }}
          >
            {" "}
            <div className="parent-complain-image">
              <img src={ProfilePic} alt="userImg" />{" "}
            </div>{" "}
            <div>
              <h5>Cody Fisher</h5>
              <p>help#90818mnjn</p>
            </div>{" "}
          </Col>
          <Col
            md={8}
            className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
            style={{ fontSize: "18px" }}
          >
            Subject: Introduction to Thermodynamics
          </Col>
        </Row>
        <Col className="d-flex align-items-center justify-content-between p-2">
          <Col md={6}> 5 Jun 2023</Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Chip
              label="Forward To Amdin"
              className="n-box-shadow"
              style={{
                color: "white",
                background: "var(--color-primary)",
              }}
            />
            <Chip
              label="Close"
              className="n-box-shadow ms-2"
              style={{
                color: "white",
                borderColor: "yellow",
                background: "red",
              }}
              color="primary"
            />
          </Col>
        </Col>
        <hr />
        <Col className="p-2">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias atque
          impedit officia porro maiores illum quae eveniet, odio ad non expedita
          eius earum reiciendis sequi assumenda optio dignissimos debitis quis
          similique praesentium. Corporis debitis molestiae officiis nesciunt,
          minima temporibus possimus nisi quisquam, in perferendis ipsam, hic ea
          quidem dolorem veniam assumenda aspernatur maiores. Repudiandae
          reiciendis aspernatur omnis nobis adipisci totam.
        </Col>
        <Col className="mt-3">
          <h6>Attachment</h6>
        </Col>
        <Row className="ps-3">
          <Col md={3} style={{ padding: "10px" }}>
            <div className="resorce-parent-item cursor-pointer">
              <div className="name">
                <FaFilePdf /> HC Verma
              </div>
              <MdOutlineFileDownload />
            </div>
          </Col>
        </Row>
      </Col>

      <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
        <Row className="p-2 m-0">
          <Col
            md={4}
            className="d-flex align-items-center justify-content-start p-0 m-0"
            style={{ gap: "10px" }}
          >
            {" "}
            <div className="parent-complain-image">
              <img src={ProfilePic} alt="userImg" />{" "}
            </div>{" "}
            <div>
              <h5>Cody Fisher</h5>
              <p>help#90818mnjn</p>
            </div>{" "}
          </Col>
          <Col
            md={8}
            className="d-flex align-items-center justify-content-end"
            style={{ fontSize: "18px" }}
          >
            Subject: Introduction to Thermodynamics
          </Col>
        </Row>
        <Col className="d-flex align-items-center justify-content-between p-2">
          <Col md={6}> 5 Jun 2023</Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Button
              onClick={() => handleShowModal(false)}
              className="me-3 rounded-5 shadow"
              variant="outlined"
              startIcon={<MdReplyAll />}
            >
              Reply
            </Button>
            <Chip
              label="Open"
              className="n-box-shadow"
              style={{
                color: "black",
                borderColor: "yellow",
                background: "yellow",
              }}
              color="primary"
            />
          </Col>
        </Col>
        <hr />
        <Col className="p-2">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias atque
          impedit officia porro maiores illum quae eveniet, odio ad non expedita
          eius earum reiciendis sequi assumenda optio dignissimos debitis quis
          similique praesentium. Corporis debitis molestiae officiis nesciunt,
          minima temporibus possimus nisi quisquam, in perferendis ipsam, hic ea
          quidem dolorem veniam assumenda aspernatur maiores. Repudiandae
          reiciendis aspernatur omnis nobis adipisci totam.
        </Col>
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isNewComplet && "Raise Complaint"}
            {!isNewComplet && "Reply Complaint"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="complaintTitle">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <Form.Group controlId="selectOption">
            <Form.Select className="mt-3">
              <option selected disabled value="option1">
                Select Status
              </option>
              <option value="option2">Open</option>
              <option value="option2">Pending</option>
              <option value="option3">Close</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="complaintDescription" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={handleDescriptionChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleRaiseComplaint}
          >
            {isNewComplet && "Raise Complaint"}
            {!isNewComplet && "Reply Complaint"}
          </Button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
}

export default ClientComplaint;
