import React, { useState } from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Card, Col, Row, Modal, Form, Container, Accordion } from "react-bootstrap";
import { MdEdit, MdNavigateNext } from "react-icons/md";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { FaBookOpenReader } from "react-icons/fa6";

function InstructorSupport(props) {
    const { history } = props;
    const [showModal, setShowModal] = useState(false);
    const [like, setLike] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLike = () => {
        setLike(!like);
    };

    return (
        <>
            <h5>Instructor Support</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className="d-flex align-items-center justify-content-between">
                <Col md={3}>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="shadow rounded-5 mt-3 mb-3">
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                            <option value="option3">Option 3</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="nameInput">
                        <Form.Control className="shadow rounded-5 mt-3 mb-3" type="text" placeholder="Search" />
                    </Form.Group>
                </Col>
            </Row>

            <Row className="mt-3 mb-3"><h6>Contact Information</h6></Row>

            <Container className="shadow rounded-3 border border">
                <Row>
                    <Col className="mt-3 mb-3" md={12}> <h6>Instructor Suport</h6> </Col>
                    <Col md={6}>
                        <Form.Group controlId="nameInput">
                            <Form.Control className="rounded-5 mt-2 mb-3" type="text" placeholder="Contact No." />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="nameInput">
                            <Form.Control className=" rounded-5 mt-2 mb-3" type="text" placeholder="Contact Email" />
                        </Form.Group>
                    </Col>
                    <Col md={12} className='mt-3 mb-3'>
                        <Form.Group controlId='textareaInput'>
                            <Form.Control
                                className='rounded-3'
                                as='textarea'
                                rows={3}
                                placeholder='Instruction Details...'
                            />
                        </Form.Group>
                    </Col>
                    <Col className="mt-3 mb-3">
                        <Button variant="outlined" className="border-danger text-white bg-danger">cancel</Button>
                        <Button className="ms-3" variant="outlined">Save Changes</Button>
                    </Col>
                </Row>
            </Container>

            <Row className="mt-3 mb-3"><h5>Reshedule</h5></Row>

            <Container className="mt-3 shadow p-4 border rounded-3">

                <Row className='mt-2 mb-2'>
                    <Col md={3}><b>Sudhakar Rao</b></Col>
                    <Col md={3}><b>Induction System</b></Col>
                    <Col md={3}><b>From: wed,6 Jun 16:30</b></Col>
                    <Col md={3}><b>To: wed,6 Jun 16:30</b></Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group controlId="formDate">
                            <Form.Label>Reschedule Date</Form.Label>
                            <Form.Control type="date" />
                        </Form.Group>
                    </Col>
                    <Col className='reshedule-display-none-on-mobile' md={6}>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Group controlId="formDate">
                            <Button style={{ width: '100%' }} className='text-white' variant="contained">
                                Send Reqest
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
                <Col md={12} className='mt-3'>
                    <Form.Group controlId="formReason">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group></Col>
                <Button className='mt-3 d-md-none reshedule-display-none-on-laptop-desktop bg-dark text-white' variant="contained" endIcon={<SendIcon />}>
                    Send
                </Button>
            </Container>
            <Container>
                <Row className='mt-3 mb-3 p-3 shadow border rounded-3'>
                    <Col md={8} className=' d-flex'>

                        <span className='p-3 bg-warning rounded-3 d-flex align-items-center'><FaBookOpenReader size={22} /></span>

                        <div className='ms-3'>
                            <h6>Learn Math's By John Smith</h6>
                            <p><b>Leave at</b> : 12 January 2012</p>
                        </div>
                    </Col>
                    <Col md={4} className='d-flex align-items-center justify-content-end'>
                        <Button className='bg-danger text-white border-0' variant='outlined'>cancel</Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='mt-3 mb-3 p-3 border shadow rounded-3'>
                    <Col md={8} className=' d-flex'>

                        <span className='p-3 bg-warning rounded-3 d-flex align-items-center'><FaBookOpenReader size={22} /></span>

                        <div className='ms-3'>
                            <h6>Learn English By John Smith</h6>
                            <p><b>Leave at</b> : 12 January 2012</p>
                        </div>
                    </Col>
                    <Col md={4} className='d-flex align-items-center justify-content-end'>
                        <Button disabled className='bg-secondary text-white border-0' variant='outlined'>Refunded</Button>
                    </Col>
                </Row>
            </Container>

            <Row className="mt-5">
                <Col md={12}>
                    <h5 className="m-0">
                        Frequently Asked Questions (FAQs) about Suport Resorces
                    </h5>
                    <Accordion defaultActiveKey="0" className="mt-5">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident, sunt in culpa qui officia deserunt
                                mollit anim id est laborum.
                                <Button onClick={() => handleShowModal(0)}>Edit <MdEdit /></Button>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                irure dolor in reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat non proident, sunt in culpa qui officia deserunt
                                mollit anim id est laborum.
                                <Button onClick={() => handleShowModal(1)}>Edit <MdEdit /></Button>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </Col>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Col md={12}>
                        <Form.Group controlId="nameInput">
                            <Form.Control type="text" placeholder="Enter Title" />
                        </Form.Group>
                    </Col>

                    <Col md={12} className='mt-3 mb-3'>
                        <Form.Group controlId='textareaInput'>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder='Enter Description....'
                            />
                        </Form.Group>
                    </Col>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="border-danger bg-danger text-white" variant="outlined" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className="ms-3" variant="outlined" onClick={handleCloseModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InstructorSupport;
