import CalendarPage from "./TeacherCalendarPage";

export const TeacherCalendarPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/calendar",
      exact: true,
      component: CalendarPage
    }
  ]
};
