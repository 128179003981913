import React, { useState, useEffect } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import ParentFooter from "../../components/ParentFooter";
import { Button, Col, Form, Row } from "react-bootstrap";
import "../../assets/css/parent/chat.css";
import { IoMdAdd } from "react-icons/io";
import { FaImages } from "react-icons/fa";
import { FaPhotoVideo } from "react-icons/fa";
import { MdDelete, MdInsertDriveFile } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdStarOutline } from "react-icons/io";
import { MdReportProblem } from "react-icons/md";
import { useSelector } from "react-redux";
import axios, { chatServerUrl } from "../../utils/axios";
import { io } from "socket.io-client";
import {
  createConvo,
  getAllGroups,
  getConvoList,
  getMessages,
} from "../../utils/SocketApies";
import { toast } from "react-toastify";
import ChatPageCommon from "../../components/chat/ChatPageCommon";

const role_Arr = [
  { text: "Admin", value: "ADMIN" },
  { text: "Operation", value: "OPERATION" },
  { text: "Account", value: "ACCOUNT" },
  { text: "Teacher", value: "TEACHER" },
  { text: "Parent", value: "PARENT" },
];

const ChatPageNew = () => {
  const [user, setUser] = useState({});

  const role = useSelector((state) => state.token.role);
  const token = useSelector((state) => state.token.value);


  const fetchTeacherData = async () => {
    try {
      const { data } = await axios.get("/teacher/info", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.res_type === "success") {
        setUser(data.data.teacher);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTeacherData();
  }, []);


  return (
    <TeacherLayout>
      <h3>Chat</h3>

      <ChatPageCommon user={user} token={token} community={true} />

      <ParentFooter />
    </TeacherLayout>
  );
};

export default ChatPageNew;
