import AllSubjects from "./AllSubjects";
import AllTest from "./AllTest";
import HomeWorkID from "./HomeWorkID";
import HwandTest from "./HwandTest";
import SingleTest from "./SingleTest";
import TestWithId from "./TestWithId";

export const HwandTestPageConfig = {
  routes: [
    {
      path: "/teacher/HwandTest",
      exact: true,
      component: HwandTest
    },
    {
      path: "/teacher/hw/:courseId",
      exact: true,
      component: AllSubjects
    },
    {
      path: "/teacher/HwandTest/:classId/:homework",
      exact: true,
      component: HomeWorkID
    },
    {
      path: "/teacher/test/:courseId",
      exact: true,
      component: AllTest
    },
    {
      path: "/teacher/HwandTest/test/testId",
      exact: true,
      component: TestWithId
    },
    {
      path: "/teacher/HwandTest/test/testId/result",
      exact: true,
      component: SingleTest
    },
  ]
};
