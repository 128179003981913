import ChatPage from "./ChatPage";

export const ChatPageConfig = {
  routes: [
    {
      path: "/account/dashboard/chat",
      exact: true,
      component: ChatPage
    }
  ]
};
