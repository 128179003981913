import CancelledCources from "./CancelledCources";
import CompleteCources from "./CompleteCources";
import CourseProgress from "./CourseProgress";
import CourseWithId from "./CourseWithId";
import CoursesPage from "./Courses";
import CoursesDetails from "./CoursesDetails";
import CoursesSearch from "./CoursesSearchPage";

export const CoursesConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/courses",
      exact: true,
      component: CoursesPage,
    },
    {
      path: "/parent/dashboard/pages/courses/:courseID",
      exact: true,
      component: CoursesDetails,
    },
    {
      path: "/parent/courses/completed",
      exact: true,
      component: CompleteCources,
    },
     {
      path: "/parent/courses/cancelled",
      exact: true,
      component: CancelledCources,
    },
    {
      path: "/parent/dashboard/courses/search/:keyword?",
      exact: true,
      component: CoursesSearch,
    },
    {
      path: "/parent/dashboard/courses/:keyword?",
      exact: true,
      component: CourseWithId,
    },
    {
      path: "/parent/dashboard/courses/:keyword/:courseId",
      exact: true,
      component: CourseProgress,
    },
  ],
};
