import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";

function AllBlogs() {
  const [selectedBlogCat, setSelectedBlogCat] = useState("admin");
  return (
    <>
      <Row className="mt-3 mb-3 ps-4">
        <Col>
          <h5 className="text-uppercase">{selectedBlogCat} Blogs</h5>
        </Col>
      </Row>
      <Row>
        <Col md={3} className="p-0 m-0 ps-4 mb-4">
          <Form.Select
            onChange={(e) => setSelectedBlogCat(e.target.value)}
            className="rounded-5 shadow"
          >
            <option value="teacher">Teacher</option>
            <option value="admin">Admin</option>
            <option value="parent">Parent</option>
          </Form.Select>
        </Col>
        <Col md={9}></Col>
      </Row>

      <Row>
        <Col md={6} className="mt-3">
          <BlogCard
            img={card2}
            head={"Why is Gravity? | Class 11 | Physics"}
            review={"267 views"}
            time={"3 days ago"}
            blogIcon={<FaAnglesRight />}
            blog={"View blog"}
            isEdit={true}
          />
        </Col>
        <Col md={6} className="mt-3">
          <BlogCard
            img={card2}
            head={"Why is Gravity? | Class 11 | Physics"}
            review={"267 views"}
            time={"3 days ago"}
            blogIcon={<FaAnglesRight />}
            blog={"View blog"}
            isEdit={true}
          />
        </Col>
        <Col md={6} className="mt-3">
          <BlogCard
            img={card2}
            head={"Why is Gravity? | Class 11 | Physics"}
            review={"267 views"}
            time={"3 days ago"}
            blogIcon={<FaAnglesRight />}
            blog={"View blog"}
            isEdit={true}
          />
        </Col>
        <Col md={6} className="mt-3">
          <BlogCard
            img={card2}
            head={"Why is Gravity? | Class 11 | Physics"}
            review={"267 views"}
            time={"3 days ago"}
            blogIcon={<FaAnglesRight />}
            blog={"View blog"}
            isEdit={true}
          />
        </Col>
      </Row>
    </>
  );
}

export default AllBlogs;
