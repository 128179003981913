// Account
import AccountPage from "./AccountPage";
import LearnieAccountPage from "./LearnieAccountPage";
import ParentAccountPage from "./ParentAccountPage";
import TeacherAccount from "./TeacherAccount";
import EmployeeAccount from "./EmployeeAccount";
import ParentAccountDetails from "./ParentAccountDetails";
import TeacherAccountDetails from "./TeacherAccountDetails";

export const AccountPageConfig = {
  routes: [
    {
      path: "/operation/account",
      exact: true,
      component: AccountPage,
    },
    {
      path: "/operation/account/learnieAccount",
      exact: true,
      component: LearnieAccountPage,
    },
    {
      path: "/operation/account/parentAccount",
      exact: true,
      component: ParentAccountPage,
    },
    {
      path: "/operation/account/teacherAccount",
      exact: true,
      component: TeacherAccount,
    },
    {
      path: "/operation/account/employeeAccount",
      exact: true,
      component: EmployeeAccount,
    },
    {
      path: "/operation/account/parentAccount/parentDetails",
      exact: true,
      component: ParentAccountDetails,
    },
    {
      path: "/operation/account/parentAccount/teacherDetails",
      exact: true,
      component: TeacherAccountDetails,
    },
  ],
};
