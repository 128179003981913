import CalculationPage from "./CalculationPage";

export const CalculationPageConfig = {
  routes: [
    {
      path: "/account/dashboard/calculation",
      exact: true,
      component: CalculationPage
    }
  ]
};
