import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import AccountLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaSearch } from "react-icons/fa";
import { Button } from "@mui/material";
import { MdDelete, MdOutlineFilterAltOff, MdWatchLater } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { useNavigate } from "react-router";

const ParentsFeesPage = (props) => {
  const { history } = props;

  return (
    <AccountLayout>
      <h3>Parents Fee</h3>
      {/* <AccountLocation path={history} /> */}
      <Row className="float-end">
        <Col>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow">
              <option value="option1">Monthly</option>
              <option value="option1">Weekly</option>
              <option value="option1">Daily</option>
              <option value="option1">Yearly</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-5 mt-md-0 mt-lg-0 mt-xl-0">
        <Col
          md={3}
          className="d-flex align-items-center justify-content-start shadow rounded-3 border  p-2 ps-3 mt-3"
        >
          <FaSearch size={18} />
          <input
            type="text"
            className="border-0 ms-3 bg-transparent"
            placeholder="Search here..."
            style={{ outline: "none" }}
          />
        </Col>
        <Col md={3} className="mt-3">
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow">
              <option value="option1">All Transaction</option>
              <option value="option1">Pending</option>
              <option value="option1">Cencel</option>
              <option value="option1">Success</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className="mt-3">
          <Button
            className="rounded-3 shadow border-0 text-dark"
            variant="outlined"
            size="medium"
          >
            reset
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={6} className="d-flex align-items-center justify-content-start">
          <Col md={4}>
            <Form.Group controlId="nameInput">
              <Form.Control
                className="shadow mt-3"
                type="text"
                placeholder="Enter Tution ID"
              />
            </Form.Group>
          </Col>
          <Col className="ms-3 mt-3">
            <Button
              className="black-outline-button border-0 shadow rounded-3"
              variant="outlined"
            >
              {" "}
              Set{" "}
            </Button>
          </Col>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <Button
            className="black-outline-button border-0 shadow rounded-3 mt-3"
            variant="outlined"
            startIcon={<MdOutlineFilterAltOff />}
          >
            {" "}
            Filter{" "}
          </Button>
          <Button
            className="black-outline-button border-0 shadow rounded-3 ms-3 mt-3"
            variant="outlined"
            startIcon={<FaSort />}
          >
            {" "}
            Sort{" "}
          </Button>
        </Col>
      </Row>
      <Table
        responsive
        style={{ backgroundColor: "var(--color-primary)", minWidth: 2000 }}
        striped
        hover
        className="mt-3 custom-styled-table rounded-3 text-center"
      >
        <thead>
          <tr>
            <th className="text-white">#</th>
            <th className="text-white">Parent I'D</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Child I'D</th>
            <th className="text-white">Child Name</th>
            <th className="text-white">Teacher I'D</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Grade</th>
            <th className="text-white">Board</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Platform Fee</th>
            <th className="text-white">GST</th>
            <th className="text-white">Deposite Date</th>
            <th className="text-white">Teachers Fees</th>
            <th className="text-white">Profit</th>
            <th className="text-white">Class Count</th>
            <th className="text-white">Cycle No</th>
            <th className="text-white">Start Date</th>
            <th className="text-white">End Date</th>
            <th className="text-white">Extended</th>
            <th className="text-white">Extra Payment</th>
            <th className="text-white">Remark</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="bg-light">
              <td>{item.id}</td>
              <td>{item.parentID}</td>
              <td>{item.parentName}</td>
              <td>{item.childId}</td>
              <td>{item.childName}</td>
              <td>{item.teacherID}</td>
              <td>{item.teacherName}</td>
              <td>{item.subject}</td>
              <td>{item.grade}</td>
              <td>{item.board}</td>
              <td>{item.amount}</td>
              <td>{item.platformFee}</td>
              <td>{item.gst}</td>
              <td>{item.depositeDate}</td>
              <td>{item.teachersFees}</td>
              <td>{item.profit}</td>
              <td>{item.classCount}</td>
              <td>{item.cycleNo}</td>
              <td>{item.startDate}</td>
              <td>{item.endDate}</td>
              <td>{item.extended}</td>
              <td>{item.extraPayment}</td>
              <td>{item.remark}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </AccountLayout>
  );
};

export default ParentsFeesPage;

const data = [
  {
    id: 1,
    transactionId: "T001",
    parentID: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Jane Doe",
    teacherID: "T001",
    teacherName: "John Doe",
    subject: "Mathematics",
    grade: "Grade 1",
    board: "CBSE",
    amount: "1000",
    platformFee: "$ 2",
    gst: "$ 1",
    depositeDate: "05 Apr. 2022",
    teachersFees: "$ 500",
    profit: "$ 800",
    classCount: 23,
    cycleNo: 7,
    startDate: "3 Apr. 2023",
    endDate: "3 Apr. 2023",
    extended: "yes",
    extraPayment: "$ 100",
    remark: "this is remark",
  },
  {
    id: 2,
    transactionId: "T001",
    parentID: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Jane Doe",
    teacherID: "T001",
    teacherName: "John Doe",
    subject: "Mathematics",
    grade: "Grade 1",
    board: "CBSE",
    amount: "1000",
    platformFee: "$ 2",
    gst: "$ 1",
    depositeDate: "05 Apr. 2022",
    teachersFees: "$ 500",
    profit: "$ 800",
    classCount: 23,
    cycleNo: 7,
    startDate: "3 Apr. 2023",
    endDate: "3 Apr. 2023",
    extended: "no",
    extraPayment: "$ 100",
    remark: "this is remark",
  },
  {
    id: 3,
    transactionId: "T001",
    parentID: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Jane Doe",
    teacherID: "T001",
    teacherName: "John Doe",
    subject: "Mathematics",
    grade: "Grade 1",
    board: "CBSE",
    amount: "1000",
    platformFee: "$ 2",
    gst: "$ 1",
    depositeDate: "05 Apr. 2022",
    teachersFees: "$ 500",
    profit: "$ 800",
    classCount: 23,
    cycleNo: 7,
    startDate: "3 Apr. 2023",
    endDate: "3 Apr. 2023",
    extended: "no",
    extraPayment: "$ 100",
    remark: "this is remark",
  },
  {
    id: 4,
    transactionId: "T001",
    parentID: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Jane Doe",
    teacherID: "T001",
    teacherName: "John Doe",
    subject: "Mathematics",
    grade: "Grade 1",
    board: "CBSE",
    amount: "1000",
    platformFee: "$ 2",
    gst: "$ 1",
    depositeDate: "05 Apr. 2022",
    teachersFees: "$ 500",
    profit: "$ 800",
    classCount: 23,
    cycleNo: 7,
    startDate: "3 Apr. 2023",
    endDate: "3 Apr. 2023",
    extended: "yes",
    extraPayment: "$ 100",
    remark: "this is remark",
  },
  {
    id: 5,
    transactionId: "T001",
    parentID: "P001",
    parentName: "John Doe",
    childId: "C001",
    childName: "Jane Doe",
    teacherID: "T001",
    teacherName: "John Doe",
    subject: "Mathematics",
    grade: "Grade 1",
    board: "CBSE",
    amount: "1000",
    platformFee: "$ 2",
    gst: "$ 1",
    depositeDate: "05 Apr. 2022",
    teachersFees: "$ 500",
    profit: "$ 800",
    classCount: 23,
    cycleNo: 7,
    startDate: "3 Apr. 2023",
    endDate: "3 Apr. 2023",
    extended: "yes",
    extraPayment: "$ 100",
    remark: "this is remark",
  },
];
