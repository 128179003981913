import gsap from "gsap";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Button from "@mui/material/Button";
import { Col, Container, Form, Row } from "react-bootstrap";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import "../assets/css/FeedBack.css";

import { IconButton } from "@mui/material";
import Footer from "../components/Footer.js";
const feedbackImg = require("../assets/feed-back.png");
const FeedBackUser = require("../assets/feed-back-user.png");
const FeedBackStarGroup = require("../assets/feed-star.png");
function FeedBack() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [reting1, setRating1] = useState(false);
  const [reting2, setRating2] = useState(false);
  const [reting3, setRating3] = useState(false);
  const [reting4, setRating4] = useState(false);
  const [reting5, setRating5] = useState(false);

  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <br />
        <Container className="mt-3">
          <Row>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center FeedBack-Container"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <div>
                <h3 className="FeedBack-page-heading-2">WE WANT YOUR</h3>
                <h3 className="FeedBack-page-heading-1">FEEDBACK</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae praesentium sint officiis aliquid vitae quidem? Non
                  aut ducimus hic repellendus fugit in dolorem illo ipsum
                  accusantium doloribus dicta,{" "}
                </p>
                <Button
                  className="text-dark rounded-5"
                  style={{ background: "var(--yellow-color)" }}
                  variant="contained"
                >
                  Read More.
                </Button>
              </div>
            </Col>
            <Col
              md={6}
              className="mt-3 mb-3 d-flex align-items-center justify-content-center"
            >
              <img src={feedbackImg} alt="" />
            </Col>
          </Row>
        </Container>

        <Container className="mt-5 mb-5">
          <center>
            <h4 className="testimonial-heading">PROVIDE YOUR FEEDBACK</h4>
          </center>
          <center>
            <div className="horizontal-hr-testimonial">&nbsp;</div>
          </center>
          <center>
            <p className="testimonials-peragraph mt-3">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non sed
              aspernatur molestiae. Ea animi ullam repellendus alias, culpa
              itaque delectus dolorem blanditiis praesentium perspiciatis
              molestiae iste ipsum consequuntur sunt commodi!
            </p>
          </center>
        </Container>

        <Container className="mt-3 mb-5">
          <Row>
            <Col
              md={5}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="rating-question-feed-back-container rounded-3 shadow">
                <h4>Can We ask You Something.</h4>
                <p>
                  Let us know what we're getting right <br />
                  and what we can improve.
                </p>
                <Button
                  variant="contained"
                  className="teacher-button rounded-5 text-white"
                >
                  Take the survay
                </Button>
              </div>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              <img className="feed-back-user-stand" src={FeedBackUser} alt="" />
            </Col>
            <Col
              md={5}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="rating-question-feed-back-container rating-question-feed-back-auto rounded-3 shadow">
                <h4>Please Rate Your Experiance.</h4>
                <img
                  className="FeedBackStarGroup"
                  src={FeedBackStarGroup}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Form.Group as={Col} md={4} className="mt-3">
              <Form.Label htmlFor="FirstName">Full Name</Form.Label>
              <Form.Control
                type="text"
                className="shadow form-control rounded-2 border-yellow"
                id="FirstName"
                placeholder="Full Name"
              />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mt-3">
              <Form.Label htmlFor="MobileNo">Mobile No.</Form.Label>
              <Form.Control
                type="text"
                className="shadow form-control rounded-2 border-yellow"
                id="FirstName"
                placeholder="Full Name"
              />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mt-3">
              <Form.Label htmlFor="Email">Email</Form.Label>
              <Form.Control
                type="email"
                className="shadow form-control rounded-2 border-yellow"
                id="Email"
                placeholder="example@gmail.com"
              />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mt-3">
              <Form.Label htmlFor="Time">Rating</Form.Label>
              <Col className="d-flex align-items-start justify-content-start">
                {reting1 ? (
                  <IconButton
                    onClick={() => setRating1(!reting1)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarIcon className="rating-feed-dark" fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setRating1(!reting1)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarBorderIcon fontSize="inherit" />
                  </IconButton>
                )}
                {reting2 ? (
                  <IconButton
                    onClick={() => setRating2(!reting2)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarIcon className="rating-feed-dark" fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setRating2(!reting2)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarBorderIcon fontSize="inherit" />
                  </IconButton>
                )}
                {reting3 ? (
                  <IconButton
                    onClick={() => setRating3(!reting3)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarIcon className="rating-feed-dark" fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setRating3(!reting3)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarBorderIcon fontSize="inherit" />
                  </IconButton>
                )}

                {reting4 ? (
                  <IconButton
                    onClick={() => setRating4(!reting4)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarIcon className="rating-feed-dark" fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setRating4(!reting4)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarBorderIcon fontSize="inherit" />
                  </IconButton>
                )}

                {reting5 ? (
                  <IconButton
                    onClick={() => setRating5(!reting5)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarIcon className="rating-feed-dark" fontSize="inherit" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setRating5(!reting5)}
                    aria-label="delete"
                    size="large"
                  >
                    <StarBorderIcon fontSize="inherit" />
                  </IconButton>
                )}
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <Form.Control
                as="textarea"
                className="form-control shadow border-yellow"
                placeholder="Description"
                rows="5"
              />
            </Col>
          </Row>
          <Row className="mt-3 ps-2">
            <Button
              className="border-0 rounded-5 text-black"
              variant="contained"
              style={{ width: "fit-content" , background: "var(--yellow-color)"}}
            >
              submit
            </Button>
            <Button
              className="ms-2 rounded-5"
              variant="outlined"
              style={{ width: "fit-content", borderColor: "var(--yellow-color)", color: "var(--yellow-color)"}}
            >
              Clear
            </Button>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default FeedBack;
