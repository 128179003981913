import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import AccountLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import "../../assets/css/parent/parent.css";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaSearch, FaSort } from "react-icons/fa";
import { MdOutlineFilterAltOff } from "react-icons/md";

const SalaryPaymentPage = (props) => {
  const { history } = props;

  const [currentTab, setcurrentTab] = useState("1");

  return (
    <AccountLayout>
      <h3>Salary Payment</h3>
      {/* <AccountLocation path={history} /> */}
      <Row className="mt-3 mb-3 flex-wrap">
        <Col className="d-flex align-items-center justify-content-start flex-wrap">
          <Col
            md={3}
            className="d-flex align-items-center justify-content-start shadow rounded-3 border  p-2  mt-3"
          >
            <FaSearch size={18} />
            <input
              type="text"
              className="border-0 ms-3 bg-transparent"
              placeholder="Search here..."
              style={{ outline: "none" }}
            />
          </Col>
          <Col md={3} sm={6} className="mt-3 ms-2 me-2">
            <Form.Group controlId="selectOption">
              <Form.Select
                value={currentTab}
                onChange={(e) => setcurrentTab(e.target.value)}
                className="shadow"
              >
                <option selected disabled value="0">
                  Salary Type
                </option>
                <option value="1">Employee</option>
                <option value="2">Teacher</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} sm={6} className="mt-3 ms-2 me-2">
            <Form.Group controlId="selectOption">
              <Form.Select className="shadow">
                <option value="option1">All Transaction</option>
                <option value="option1">Monthly</option>
                <option value="option1">Weekly</option>
                <option value="option1">Daily</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <Button
              className="rounded-3 shadow border-0 text-dark"
              variant="outlined"
              size="medium"
            >
              reset
            </Button>
          </Col>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <Button
            className="black-outline-button border-0 shadow rounded-3 mt-3"
            variant="outlined"
            startIcon={<MdOutlineFilterAltOff />}
          >
            {" "}
            Filter{" "}
          </Button>
          <Button
            className="black-outline-button border-0 shadow rounded-3 ms-3 mt-3"
            variant="outlined"
            startIcon={<FaSort />}
          >
            {" "}
            Sort{" "}
          </Button>
        </Col>
      </Row>

      {currentTab === "1" && (
        <>
          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)" }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">Transaction Date</th>
                <th className="text-white">Employee Name</th>
                <th className="text-white">Account</th>
                <th className="text-white">Leaves</th>
                <th className="text-white">Half Day</th>
                <th className="text-white">Present Day</th>
                <th className="text-white">Amount</th>
                <th className="text-white">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
              <tr className="bg-light">
                <td>05 Apr. 2022</td>
                <td>John Doe</td>
                <td>Account#001</td>
                <td>3</td>
                <td>2</td>
                <td>25</td>
                <td>30,000</td>
                <td>parent</td>
              </tr>
            </tbody>
          </Table>
        </>
      )}

      {currentTab === "2" && (
        <>
          <Row className="mt-3 mb-3 flex-wrap">
            <Col md={4}>
              <Form.Control type="text" placeholder="Search here by id..." />
            </Col>
          </Row>

          <Table
            responsive
            style={{ backgroundColor: "var(--color-primary)" }}
            striped
            hover
            className="mt-3 custom-styled-table rounded-3 text-center"
          >
            <thead>
              <tr>
                <th className="text-white">Transaction I'D </th>
                <th className="text-white">Parent I'D</th>
                <th className="text-white">Course I'D </th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Student ID </th>
                <th className="text-white">Student Name</th>
                <th className="text-white">Course Date</th>
                <th className="text-white">Course Time</th>
                <th className="text-white">Cycle Count</th>
                <th className="text-white">Class Count</th>
                <th className="text-white">Bank Name</th>
                <th className="text-white">Payee </th>
                <th className="text-white">Teacher Status</th>
                <th className="text-white">Status </th>
                <th className="text-white">Total Hours</th>
                <th className="text-white">Download Invoice</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <tr key={index} className="bg-light">
                    <td>TRX123</td>
                    <td>PAR123</td>
                    <td>C123</td>
                    <td>Maths</td>
                    <td>ST123</td>
                    <td>John Doe</td>
                    <td>05 Apr. 2022</td>
                    <td>10:00 AM</td>
                    <td>3</td>
                    <td>25</td>
                    <td>Bank of America</td>
                    <td>John Doe</td>
                    <td>
                      <span className="badge bg-warning">Join Late</span>
                    </td>
                    <td>
                      <span className="badge bg-success">Success</span>
                    </td>
                    <td>75</td>
                    <td>
                      <Button
                        variant="outlined"
                        className="black-outline-button border-0 shadow rounded-3"
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </AccountLayout>
  );
};

export default SalaryPaymentPage;
