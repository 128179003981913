import Referral from "./Referral.jsx";

export const ReferralPageConfig = {
  routes: [
    {
      path: "/teacher/referral",
      exact: true,
      component: Referral,
    },
  ],
};
