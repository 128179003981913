import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Row, Form } from "react-bootstrap";
import { IoIosClose, IoMdSend } from "react-icons/io";

const NotificationPage = () => {
  return (
    <AccountLayout>
      <h3>Send Notificaion</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Select className="rounded-5 shadow border-yellow">
              <option value="option1">Parents-All</option>
              <option value="option1">Teachers-All</option>
              <option value="option1">Parents-Individual</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Control
            className="border-yellow rounded-5 shadow"
            type="text"
            placeholder="Search by name or ID"
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col
          md={5}
          className="ps-5 d-flex align-items-center justify-content-between"
        >
          <p className="m-0 fw-semibold text_yellow">To:</p>
          <div className="d-flex align-items-center gap-4">
            <p className="m-0 bg-yellow py-2 px-3 rounded-4 d-flex align-items-center gap-2 shadow text-white">
              Parent 1 <IoIosClose className="fs-5" />
            </p>
            <p className="m-0 bg-yellow py-2 px-3 rounded-4 d-flex align-items-center gap-2 shadow text-white">
              Parent 2 <IoIosClose className="fs-5" />
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 rounded-5 shadow py-3 position-relative">
        <Col md={12} className="px-4 pb-3">
          <p className="m-0">Message Title</p>
        </Col>
        <hr />
        <Col md={7} className="px-4" style={{ height: 400 }}>
          <p className="m-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A, id est,
            nemo molestiae odit obcaecati, illum maiores nihil eaque cum labore
            possimus vitae.
          </p>
        </Col>
        <Col
          md={12}
          className="d-flex align-items-center justify-content-between px-4 position-absolute  left-0 w-100"
          style={{ bottom: 13 }}
        >
          <input
            type="text"
            className="border-0 bg-transparent w-75"
            placeholder="Type Message Content Here..."
          />
          <button type="submit" className="border-0 bg-transparent">
            <IoMdSend className="fs-4 text_yellow " />
          </button>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default NotificationPage;
