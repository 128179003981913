// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EventIcon from "@material-ui/icons/Event";
import DescriptionIcon from "@material-ui/icons/Description";
import ChatIcon from "@material-ui/icons/Chat";
import User from "@material-ui/icons/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Book from "@material-ui/icons/Book";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import StudentIcon from "@material-ui/icons/EmojiPeople";
import EducationIcon from "@material-ui/icons/School";
import GiftIcon from "@material-ui/icons/CardGiftcard";
import ReviewIcon from "@material-ui/icons/RateReview";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import HomeIcon from "@mui/icons-material/Home";
import AddCommentIcon from "@mui/icons-material/AddComment";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { CiWallet } from "react-icons/ci";
import { SiGooglemeet } from "react-icons/si";

const navigationConfig = [
  {
    id: "Main",
    title: "",
    type: "group",
    children: [
      {
        id: "homePage",
        title: "Home",
        type: "item",
        icon: <HomeIcon />,
        url: "/",
        exact: true,
      },
      {
        id: "parent",
        title: "Parent",
        type: "item",
        icon: <User />,
        url: "/parent/dashboard",
        exact: true,
      },
      {
        id: "calendar",
        title: "Calendar",
        type: "item",
        icon: <EventIcon />,
        url: "/parent/dashboard/pages/calendar",
        exact: true,
      },
      {
        id: "Classes",
        title: "",
        type: "group",
        children: [
          {
            id: "Registration-p",
            title: "Courses & Classes",
            type: "collapse",
            icon: <AccountCircleIcon />,
            children: [
              {
                id: "courses",
                title: "Courses",
                type: "item",
                icon: <Book />,
                url: "/parent/dashboard/pages/courses",
                exact: true,
              },
              {
                id: "Resources-p",
                title: "Resources",
                type: "item",
                icon: <ModelTrainingIcon />,
                url: "/parent/resources",
                exact: true,
              },
              {
                id: "transcript",
                title: "Transcript",
                type: "item",
                icon: <DescriptionIcon />,
                url: "/parent/dashboard/pages/transcript",
                exact: true,
              },
              {
                id: "HomeWork-p",
                title: "Home Work",
                type: "item",
                icon: <HomeRepairServiceIcon />,
                url: "/parent/HomeWork",
                exact: true,
              },
              {
                id: "reschedule-p",
                title: "Reschedule",
                type: "item",
                icon: <PendingActionsIcon />,
                url: "/parent/reschedule",
                exact: true,
              },
              // {
              //   id: "goLive",
              //   title: "Live",
              //   type: "item",
              //   icon: <LiveIcon />,
              //   url: "/parent/dashboard/pages/live",
              //   exact: true,
              // },
              // {
              //   id: "Completed Courses",
              //   title: "Completed Courses",
              //   type: "item",
              //   icon: <AiOutlineFileDone />,
              //   url: "/parent/courses/completed",
              //   exact: true,
              // },
              // {
              //   id: "Cancelled Courses",
              //   title: "Cancelled Courses",
              //   type: "item",
              //   icon: <MdOutlineMenuBook />,
              //   url: "/parent/courses/cancelled",
              //   exact: true,
              // },
            ],
          },
        ],
      },
      // {
      //   id: "Live-1",
      //   title: "",
      //   type: "group",
      //   children:[
      //     {
      //       id: "Live",
      //       title: "Live",
      //       type: "collapse",
      //       icon: <LiveIcon />,
      //       children:[
      //         {
      //           id: "goLive",
      //           title: "1-on-1",
      //           type: "item",
      //           url: "/parent/dashboard/live/1-on-1",
      //           exact: true,
      //         },
      //         {
      //           id: "goLive",
      //           title: "Group Classes",
      //           type: "item",
      //           url: "/parent/dashboard/live/group",
      //           exact: true,
      //         },
      //       ]
      //     }
      //   ]
      // },
      {
        id: "Demo-p",
        title: "Demo",
        type: "item",
        icon: <FileCopyIcon />,
        url: "/parent/dashboard/pages/freeDemo",
        exact: true,
      },
      // {
      //   id: "Classes",
      //   title: "",
      //   type: "group",
      //   children: [
      //     {
      //       id: "Registration-p",
      //       title: "Demo",
      //       type: "collapse",
      //       icon: <FileCopyIcon />,
      //       children: [
      //         {
      //           id: "freeDemo",
      //           title: "Free Demo",
      //           type: "item",
      //           icon: <FileCopyIcon />,
      //           url: "/parent/dashboard/pages/freeDemo",
      //           exact: true,
      //         },
      //         // {
      //         //   id: "manual-demo-p",
      //         //   title: "Manual Demo",
      //         //   type: "item",
      //         //   icon: <MdEmojiPeople   />,
      //         //   url: "/parent/manual-demo",
      //         //   exact: true,
      //         // },
      //       ]
      //     },
      //   ],
      // },
      {
        id: "Teacher-p",
        title: "Teacher",
        type: "item",
        icon: <EducationIcon />,
        url: "/parent/teacher",
        exact: true,
      },
      // {
      //   id: "Classes",
      //   title: "",
      //   type: "group",
      //   children: [
      //     {
      //       id: "Registration-p",
      //       title: "Teacher",
      //       type: "collapse",
      //       icon: <EducationIcon />,
      //       children: [
      //         {
      //           id: "teacher",
      //           title: "Teacher",
      //           type: "item",
      //           icon: <EducationIcon />,
      //           url: "/parent/teacher",
      //           exact: true,
      //         },

      //       ]
      //     },
      //   ],
      // },
      {
        id: "chat",
        title: "Chat",
        type: "item",
        icon: <ChatIcon />,
        url: "/parent/dashboard/pages/chat",
        exact: true,
      },
      {
        id: "PT Meeting",
        title: "PT Meeting",
        type: "item",
        icon: <SiGooglemeet />,
        url: "/parent/pta/meeting",
        exact: true,
      },
      // {
      //   id: "favorites",
      //   title: "Favorites",
      //   type: "item",
      //   icon: <FavoriteIcon />,
      //   url: "/parent/dashboard/pages/favorites",
      //   exact: true,
      // },
      // {
      //   id: "Referral",
      //   title: "Referral",
      //   type: "item",
      //   icon: <MonetizationOnIcon />,
      //   url: "/parent/referral",
      //   exact: true,
      // },
      // {
      //   id: "Payment-p",
      //   title: "Payment",
      //   type: "item",
      //   icon: <AccountBalanceWalletIcon />,
      //   url: "/parent/payment",
      //   exact: true,
      // },

      // {
      //   id: "chat",
      //   title: "Chat",
      //   type: "item",
      //   icon: <ChatIcon />,
      //   url: "/parent/dashboard/pages/chat",
      //   exact: true,
      // },

      // {
      //   id: "favorites",
      //   title: "Favorites",
      //   type: "item",
      //   icon: <FavoriteIcon />,
      //   url: "/parent/dashboard/pages/favorites",
      //   exact: true,
      // },

      // {
      //   id: "Payment-p",
      //   title: "Payment",
      //   type: "item",
      //   icon: <AccountBalanceWalletIcon />,
      //   url: "/parent/payment",
      //   exact: true,
      // },
      {
        id: "Wallet-p",
        title: "Wallet",
        type: "item",
        icon: <CiWallet />,
        url: "/parent/wallet",
        exact: true,
      },
      // {
      //   id: "HomeTuation-p",
      //   title: "Home Tution",
      //   type: "item",
      //   icon: <CastForEducationIcon />,
      //   url: "/parent/homeTuation",
      //   exact: true,
      // },
      {
        id: "gift",
        title: "Gift",
        type: "item",
        icon: <GiftIcon />,
        url: "/parent/dashboard/pages/gift",
        exact: true,
      },
      //  {
      //   id: "Profile-p",
      //   title: "Profile",
      //   type: "item",
      //   icon: <AccountCircleIcon />,
      //   url: "/parent/profile",
      //   exact: true,
      // },

      // {
      //   id: "HomeTuation-p",
      //   title: "Home Tution",
      //   type: "item",
      //   icon: <CastForEducationIcon />,
      //   url: "/parent/homeTuation",
      //   exact: true,
      // },
      {
        id: "blogs",
        title: "Blogs",
        type: "item",
        icon: <BookmarkIcon />,
        url: "/parent/dashboard/pages/blogs",
        exact: true,
      },
      {
        id: "learnieMall",
        title: "Learnie Mall",
        type: "item",
        icon: <StudentIcon />,
        url: "/parent/dashboard/pages/learnieMall",
        exact: true,
      },
      {
        id: "complain-p",
        title: "complain",
        type: "item",
        icon: <AddCommentIcon />,
        url: "/parent/complain",
        exact: true,
      },
      {
        id: "review",
        title: "Review",
        type: "item",
        icon: <ReviewIcon />,
        url: "/parent/dashboard/pages/review",
        exact: true,
      },
      {
        id: "Suggestions-p",
        title: "Suggestions",
        type: "item",
        icon: <SettingsSuggestIcon />,
        url: "/parent/suggestions",
        exact: true,
      },
      {
        id: "FAQParent-p",
        title: "FAQ ",
        type: "item",
        icon: <LiveHelpIcon />,
        url: "/parent/FAQParent",
        exact: true,
      },
    ],
  },
  // {
  //   id: "Pages",
  //   title: "",
  //   type: "group",
  //   children: [
  //     {
  //       id: "Registration-p",
  //       title: "Registration",
  //       type: "item",
  //       icon: <AccountCircleIcon />,
  //       url: "/parent/registration",
  //       exact: true,
  //     },
  //   ],
  // },
];

export default navigationConfig;
