import React, { useState } from "react";
import { Row, Col, Card, Container, Dropdown, Button, Form } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { AiOutlineStock } from "react-icons/ai";
import { MdNavigateNext } from "react-icons/md";
import { RiFilterOffLine } from "react-icons/ri";

const BehaviourPage = (props) => {
    const { history } = props;
    const [showMenu, setShowMenu] = useState(false);

    const handleToggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuClick = (option) => {
        // Handle click on menu option (e.g., set filter option in state)
        console.log(`Selected option: ${option}`);
        // Optionally, you can close the menu after selecting an option
        setShowMenu(false);
    };
    return (
        <OperationLayout>
            <h4>Behaviour</h4>
            {/* <OperationLocation path={history} /> */}
            <Row className="shadow rounded-3">
                <Col md={6} className="min-vh-7 p-3">
                    <h6>Total Leads</h6>
                    <div className="d-flex align-items-center justify-content-between">
                        <h5>34</h5>
                        <span><AiOutlineStock size={32} /></span>
                    </div>
                    <hr />
                    <h6>No Visitors right now</h6>
                </Col>
                <Col md={6} className="min-vh-7 p-3">
                    <h6>Total New clients</h6>
                    <h6>No Demos set</h6>
                    <hr className="mt-4" />
                    <h6>Conversions today</h6>
                </Col>
            </Row>

            <Container className="mt-3 mb-3">
                <Row>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="bg-transparent shadow border-0 mt-3">
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <h5>Heading 1</h5>
                                <MdNavigateNext size={20} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Row className="mt-3 mb-3  float-end">
                <Col>
                    {showMenu && (
                        <Dropdown className="float-right">
                            <Dropdown.Toggle id="filter-dropdown" className="custom-dropdown-toggle teacher-button rounded-5 shadow border-0">
                                <RiFilterOffLine size={20} className="me-2" />
                                Filter
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleMenuClick('Daily')}>
                                    Daily
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleMenuClick('Weekly')}>
                                    Weekly
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleMenuClick('Monthly')}>
                                    Monthly
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </Col>
            </Row>
            <br />
            <br />
            <Container className="p-3 mt-5 shadow rounded-3">
                <Row>
                    <Col md={6}> <h4>Top Searchers</h4></Col>
                    <Col md={6} className="d-flex align-items-center justify-content-end ">
                        <Col xs={3}>
                            <Form.Group controlId="inputField1">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control className="border-yellow shadow rounded-5" type="date" placeholder="Enter data for Input 1" />
                            </Form.Group>
                        </Col>
                        <Col xs={3} className="ms-3">
                            <Form.Group controlId="inputField1">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control className="border-yellow shadow rounded-5" type="date" placeholder="Enter data for Input 1" />
                            </Form.Group>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Form.Group controlId="inputField1">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group controlId="inputField1">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group controlId="inputField1">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group controlId="inputField1">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <Row className="mt-5 mb-5">
                <Col md={6}></Col>
                <Col xs={3} className="float-end">
                    <Form.Group controlId="inputField1">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control className="border-yellow shadow rounded-5" type="date" placeholder="Enter data for Input 1" />
                    </Form.Group>
                </Col>
                <Col xs={3} className="float-end">
                    <Form.Group controlId="inputField1">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control className="border-yellow shadow rounded-5" type="date" placeholder="Enter data for Input 1" />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={4} className="mt-3">
                    <Form.Group controlId="inputField1">
                        
                        <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                    </Form.Group>
                </Col>
                <Col xs={4} className="mt-3">
                    <Form.Group controlId="inputField1">
                        
                        <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                    </Form.Group>
                </Col>
                <Col xs={4} className="mt-3">
                    <Form.Group controlId="inputField1">
                       
                        <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                    </Form.Group>
                </Col>
                <Col xs={4} className="mt-3">
                    <Form.Group controlId="inputField1">
                        <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Serach" />
                    </Form.Group>
                </Col>
            </Row>

        </OperationLayout>
    );
};

export default BehaviourPage;
