import React from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Card, Col, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { MdNavigateNext } from "react-icons/md";

function Authortise(props) {
  const { history } = props;
  return (
    <>
      <h5>Authortise</h5>
     {/* <OperationLocation path={history} /> */} 
      <Row>
        <Col xs={3}>
          <Card className="min-vh-7 shadow rounded-2 border">
            <Card.Body className="d-flex align-items-center justify-content-between">
              <h5>Exceptions</h5>
              <MdNavigateNext size={30} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className="min-vh-7 shadow rounded-2 border">
            <Card.Body className="d-flex align-items-center justify-content-between">
              <h5>Teacher Payments</h5>
              <MdNavigateNext size={30} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className="min-vh-7 shadow rounded-2 border">
            <Card.Body className="d-flex align-items-center justify-content-between">
              <h5>Escalation</h5>
              <MdNavigateNext size={30} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className="min-vh-7 shadow rounded-2 border">
            <Card.Body className="d-flex align-items-center justify-content-between">
              <h5>Salaries</h5>
              <MdNavigateNext size={30} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Authortise;
