import React from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Col, Row } from "react-bootstrap";
import img from "../../assets/parent/card3.png";
import { IoPlay } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <StyledContainer className="px-5">
        <h2 className="text-center">Welcome to Learniee</h2>
        <p className="text-center mt-4">
          Welcome, aspiring teachers! Before you embark on your journey to
          become a part of our esteemed teaching community, we kindly request
          that you take a moment to acquaint yourself with the essential
          information provided.
        </p>
        <p className="text-center">
          We encourage you to watch all the informative videos meticulously, as
          they offer valuable insights into our platform's teaching methods and
          philosophies. Additionally, we strongly urge you to carefully read
          through the rules and terms and conditions that govern our community.
        </p>

        <Row className="mt-5 d-flex align-items-center justify-content-center">
          <div className="content p-0 m-0">
            <div className="img rounded-5 overflow-hidden position-relative">
              <img src={img} alt="" />
              <div className="play d-flex align-items-center justify-content-center">
                <IoPlay className="fs-5" />
              </div>
            </div>
            {/* chaeckbox */}
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                By ticking this checkbox, I acknowledge and agree to fully
                understanding how the platform works and agree to adhere to the
                community guidelines
              </label>
            </div>
          </div>
        </Row>

        <Row className="mt-3 d-flex align-items-center justify-content-center">
          <div className="content p-0 m-0">
            <div className="img rounded-5 overflow-hidden position-relative">
              <img src={img} alt="" />
              <div className="play d-flex align-items-center justify-content-center">
                <IoPlay className="fs-5" />
              </div>
            </div>
            {/* chaeckbox */}
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                By checking this box, I acknowledge and agree to abide by the
                code of conduct outlined by Learnie.
              </label>
            </div>
          </div>
        </Row>

        <Row className="mt-3 d-flex align-items-center justify-content-center">
          <div className="content p-0 m-0">
            <div className="img rounded-5 overflow-hidden position-relative">
              <img src={img} alt="" />
              <div className="play d-flex align-items-center justify-content-center">
                <IoPlay className="fs-5" />
              </div>
            </div>
            {/* chaeckbox */}
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                By checking this box, I acknowledge and agree to the terms and
                conditions outlined in the employment contract with Learnie.
              </label>
            </div>
          </div>
        </Row>

        <Row className="mt-5">
          <Col>
            <h4 className="fw-bold">Terms and Conditions</h4>
            <ul className="m-0">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                aliquet ipsum nec rutrum interdum. Fusce euismod malesuada orci,
                eu condi
              </li>
              <li>
                mentum nisl viverra sed. Praesent in lacinia arcu. Donec semper
                efficitur lorem id consequat. Curabitur lacinia consectetur
                aliquet. Proin
              </li>
              <li>
                scelerisque, nisi nec maximus consequat, risus lacus aliquam
                libero, at dignissim leo libero vitae metus. Etiam id urna et
                velit tincidunt viverra.
              </li>
              <li>
                Nam dictum turpis ut lectus pulvinar, nec varius turpis
                vestibulum. Sed ac lacinia ex. Sed rutrum ex quis tellus
                consequat, sed viverra nisi semper.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                aliquet ipsum nec rutrum interdum. Fusce euismod malesuada orci,
                eu
              </li>
              <li>
                condimentum nisl viverra sed. Praesent in lacinia arcu. Donec
                semper efficitur lorem id consequat. Curabitur lacinia
                consectetur aliquet. Proin
              </li>
              <li>
                scelerisque, nisi nec maximus consequat, risus lacus aliquam
                libero, at dignissim leo libero vitae metus. Etiam id urna et
                velit tincidunt viverra .
              </li>
              <li>
                Nam dictum turpis ut lectus pulvinar, nec varius turpis
                vestibulum. Sed ac lacinia ex. Sed rutrum ex quis tellus
                consequat, sed viverra nisi semper.
              </li>
            </ul>
            {/* chaeckbox */}
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                By ticking this checkbox, I acknowledge and agree to the terms
                and conditions.
              </label>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h4 className="fw-bold">Code of Conduct</h4>
            <ul className="m-0">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                aliquet ipsum nec rutrum interdum. Fusce euismod malesuada orci,
                eu condi
              </li>
              <li>
                mentum nisl viverra sed. Praesent in lacinia arcu. Donec semper
                efficitur lorem id consequat. Curabitur lacinia consectetur
                aliquet. Proin
              </li>
              <li>
                scelerisque, nisi nec maximus consequat, risus lacus aliquam
                libero, at dignissim leo libero vitae metus. Etiam id urna et
                velit tincidunt viverra.
              </li>
              <li>
                Nam dictum turpis ut lectus pulvinar, nec varius turpis
                vestibulum. Sed ac lacinia ex. Sed rutrum ex quis tellus
                consequat, sed viverra nisi semper.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                aliquet ipsum nec rutrum interdum. Fusce euismod malesuada orci,
                eu
              </li>
              <li>
                condimentum nisl viverra sed. Praesent in lacinia arcu. Donec
                semper efficitur lorem id consequat. Curabitur lacinia
                consectetur aliquet. Proin
              </li>
              <li>
                scelerisque, nisi nec maximus consequat, risus lacus aliquam
                libero, at dignissim leo libero vitae metus. Etiam id urna et
                velit tincidunt viverra .
              </li>
              <li>
                Nam dictum turpis ut lectus pulvinar, nec varius turpis
                vestibulum. Sed ac lacinia ex. Sed rutrum ex quis tellus
                consequat, sed viverra nisi semper.
              </li>
            </ul>
            {/* chaeckbox */}
            <div className="form-check mt-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                By ticking this checkbox, I acknowledge and agree to the terms
                and conditions.
              </label>
            </div>
          </Col>
        </Row>

        <Row className="mt-5 justify-content-end">
          <Col
            md={4}
            className="d-flex align-items-center justify-content-end me-5"
          >
            <button onClick={() => navigate("/teacher/dashboard")}>
              Accept & Continue
            </button>
          </Col>
        </Row>
      </StyledContainer>
      <Footer />
    </>
  );
};

const StyledContainer = styled.div`
  padding-top: 12vmax;
  padding-bottom: 3vmax;
  .content {
    height: 70vh;
    width: 40%;
  }
  .img {
    width: 100%;
    height: 45vh;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      background: #3333339f;
      border-radius: 50%;
      cursor: pointer;
      svg {
        color: #d8ae8e;
      }
    }
  }

  ul li {
    list-style: outside;
  }

  button {
    padding: 10px 40px;
    border-radius: 100px;
    background: var(--color-primary);
    color: var(--color-dark);
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
  }
`;

export default WelcomePage;
