import MarketingPage from "./MarketingPage";

export const MarketingPageConfig = {
  routes: [
    {
      path: "/operation/Marketing",
      exact: true,
      component: MarketingPage
    }
  ]
};
