/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BsPinAngleFill } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";
import { FaUser } from "react-icons/fa6";
import Moment from "react-moment";
import {
  FaCalendar,
  FaYoutube,
  FaTwitter,
  FaLinkedinIn,
  FaComments,
} from "react-icons/fa";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

import gsap from "gsap";
import Navbar from "../components/Navbar.js";
import "../assets/css/blogPage.css";
import Footer from "../components/Footer.js";
import { useNavigate, useParams } from "react-router-dom";
import { getOneBlog } from "../Api/BlogApi/getAllBlogs.js";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { IconButton } from "@mui/material";
import { GiAges } from "react-icons/gi";

const blogImg = require("../assets/blog_details_image_1.png");
const BlogeImage2 = require("../assets/blog_details_image_2.png");
const BlogeImage3 = require("../assets/blog_details_image_2.png");
const BlogCommentImg1 = require("../assets/blog-comment-01.png");
const ArticalImage1 = require("../assets/blog_img_1.png");
const ArticalImage2 = require("../assets/blog_img_2.png");
const ArticalImage3 = require("../assets/blog_img_3.png");

function BlogPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const [suggestOpen, setSuggestOpen] = useState(true);
  const [suggestCommentOpen, setSuggestCommentOpen] = useState(true);
  const [suggestTagsOpen, setSuggestTagsOpen] = useState(true);

  const [blogData, setBlogData] = useState(null);
  const [like, setLike] = useState(false);

  let params = useParams();
  let { blogId } = params;
  console.log("params id", blogId);
  useEffect(() => {
    getOneBlog(blogId)
      .then((res) => {
        let { blog, status } = res;
        if (status === true) {
          console.log("blog found", res);
          setBlogData(blog);
        } else {
          console.log("blog not found", status);
        }
      })
      .catch((err) => {
        console.log("blog error:-", err.message);
      });
  }, [blogId]);

  const handleSuggestArtical = () => {
    setSuggestOpen(!suggestOpen);
  };

  const handleSuggestComment = () => {
    setSuggestCommentOpen(!suggestCommentOpen);
  };

  const handleSuggestTags = () => {
    setSuggestTagsOpen(!suggestTagsOpen);
  };

  return (
    <>
      {/* <div className="main blog-page"> */}
      <div className="">
        <Navbar />
        <br />
        <br />
        <Container fluid className="mt-5">
          <Row className="blog_banner_area">
            <div className="blog-banner-title">
              {blogData && blogData.title ? blogData.title : "loading..."}
            </div>
          </Row>
        </Container>
        <Container style={{ overflowY: "hidden" }}>
          <Row className="career-area mb-5">
            <Col
              md={8}
              className="pt-2 p-0 m-0"
              style={{ overflow: "hidden", wordWrap: "wrap" }}
            >
              <div className="blog-poster-img">
                <Image
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={
                    blogData && blogData.imageURL ? blogData.imageURL : blogImg
                  }
                />
              </div>
              <Col className="items-menu mt-4 d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <div className="items-cares-sub-menu ms-2 ">
                    <BsPinAngleFill /> Sticky Post
                    <div
                      onClick={() => navigate("/teacher/profile/142512001")}
                      className="items-cares-sub-menu ms-2 cursor-pointer"
                    >
                      <FaUser /> by john_deo
                      {/* {blogData && blogData.author
                      ? blogData.author.username
                      : "Loading..."} */}
                    </div>
                    <div className="items-cares-sub-menu ms-2 ">
                      <FaCalendar />{" "}
                      <Moment format="MMM Do YYYY">
                        {blogData && blogData.datePublished}
                      </Moment>{" "}
                    </div>
                  </div>
                  <div className="items-cares-sub-menu ms-2 ">
                    <GiAges size={22} /> 18+
                  </div>
                </div>
                <div>
                  <IconButton
                    className="me-3"
                    aria-label="like"
                    onClick={() => setLike(!like)}
                  >
                    {like ? <FaHeart color={"red"} /> : <FaRegHeart />}
                  </IconButton>
                </div>
              </Col>
              {/* <Col className='pt-3'>
                                Mauris rhoncus aenean vel elit scelerisque mauris. Ultrices vitae auctor eu augue ut. Curabitur gravida arcu ac tortor dignissim convallis aenean et tortor. Proin fermentum leo vel orci porta non pulvinar neque. At tellus at urna condimentum mattis pellentesque.
                            </Col> */}
              <Col className="pt-3">
                {blogData && blogData.content ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: blogData.content }}
                  ></div>
                ) : null}
              </Col>

              <Col className="pt-3">
                Senectus et netus et malesuada. Massa tincidunt dui ut ornare
                lectus sit. Sed enim ut sem viverra. Fermentum odio eu feugiat
                pretium. Interdum varius sit amet mattis vulputate enim.
              </Col>
              <Col className="mt-3 bload_heading">
                Hard skills for a business analyst resume
              </Col>
              <Col className="mt-3">
                Amet cursus sit amet dictum sit amet justo. Morbi tincidunt
                ornare massa eget. In nulla posuere sollicitudin aliquam
                ultrices sagittis orci a. Diam donec adipiscing tristique risus
                nec feugiat in fermentum posuere. In mollis nunc sed id semper
                risus in hendrerit gravida. Pretium viverra suspendisse potenti
                nullam ac tortor vitae purus faucibus. Aliquet lectus proin nibh
                nisl condimentum id venenatis.
              </Col>
              <Col className="image_second_area d-flex mt-3">
                <Col className="col-md-6 ">
                  <Image
                    src={BlogeImage2}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "7px",
                    }}
                  />
                </Col>
                <Col className="col-md-6 ms-3">
                  <Image
                    src={BlogeImage3}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "7px",
                    }}
                  />
                </Col>
              </Col>
              <br />
              <Col className="mt-5 bload_heading">
                Conduct research into the company you are applying to
              </Col>
              <Col className="pt-3">
                Non diam phasellus vestibulum lorem sed. Leo in vitae turpis
                massa sed. Urna cursus eget nunc scelerisque. Elementum tempus
                egestas sed sed risus. Aliquam sem et tortor consequat id porta
                nibh venenatis. Egestas congue quisque egestas diam in arcu
                cursus euismod.
              </Col>
              <Col className="pt-3 pe-2">
                Libero enim sed faucibus turpis in. Condimentum vitae sapien
                pellentesque habitant morbi tristique senectus et netus. Sit
                amet nisl purus in mollis nunc sed id. Odio ut enim blandit
                volutpat maecenas volutpat blandit aliquam etiam. Felis donec et
                odio pellentesque diam volutpat commodo sed.
              </Col>
              <Col className="mt-3 bload_heading">Publisher Bio</Col>
              <Col className="pt-3">
                Senectus et netus et malesuada. Massa tincidunt dui ut ornare
                lectus sit. Sed enim ut sem viverra. Fermentum odio eu feugiat
                pretium. Interdum varius sit amet mattis vulputate enim.
              </Col>
              <Col className="mt-3 bload_heading">Publisher description</Col>
              <Col className="pt-3">
                Senectus et netus et malesuada. Massa tincidunt dui ut ornare
                lectus sit. Sed enim ut sem viverra. Fermentum odio eu feugiat
                pretium. Interdum varius sit amet mattis vulputate enim.
              </Col>
              <Col className="d-block  d-md-flex d-lg-flex d-xl-flex d-xll-flex align-items-center justify-content-center mt-4">
                <Col md className="blog_categry_area">
                  <span className="blog_tag_categrory">photography</span>
                  <span className="blog_tag_categrory">technology</span>
                  <span className="blog_tag_categrory">programming</span>
                </Col>
                <Col className="blog_categry_socieal_link_area pe-2">
                  <a href="">
                    {" "}
                    <div className="blog_social_link_icons">
                      {" "}
                      <GrFacebookOption className="text-white" />{" "}
                    </div>{" "}
                  </a>
                  <a href="">
                    {" "}
                    <div className="blog_social_link_icons">
                      {" "}
                      <FaYoutube className="text-white" />{" "}
                    </div>{" "}
                  </a>
                  <a href="">
                    {" "}
                    <div className="blog_social_link_icons">
                      {" "}
                      <FaTwitter className="text-white" />{" "}
                    </div>{" "}
                  </a>
                  <a href="">
                    {" "}
                    <div className="blog_social_link_icons">
                      {" "}
                      <FaLinkedinIn className="text-white" />{" "}
                    </div>{" "}
                  </a>
                </Col>
              </Col>
              <Col className="mt-5 bload_heading">2 Comments</Col>
              <Col className="p-2">
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Carolyn Wallace
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Marrion Willsoriam
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
                <Col className="mt-3 blog-comment-user-box">
                  <Row>
                    <Col md className="d-flex" style={{ gap: "10px" }}>
                      <div className="blog-comment-image">
                        <img src={BlogCommentImg1} alt="" />
                      </div>
                      <Col
                        className="d-flex align-items-start justify-content-center"
                        style={{ flexDirection: "column", gap: "0" }}
                      >
                        <span
                          style={{ height: "fit-content", fontWeight: "600" }}
                        >
                          Carolyn Wallace
                        </span>
                        <span style={{ height: "fit-content" }}>
                          January 27, 2023
                        </span>
                      </Col>
                    </Col>
                    <Col
                      md
                      className="d-flex align-items-center justify-content-end"
                      style={{ gap: "10px" }}
                    >
                      <span className="blog_comment_like_icon">
                        <BiLike />
                      </span>
                      <span className="blog_comment_like_icon">
                        <BiSolidDislike />
                      </span>
                    </Col>
                  </Row>
                  <Col className="mt-3 p-2">
                    Platea dictumst vestibulum rhoncus est pellentesque elit
                    ullamcorper dignissim cras. Vitae ultricies leo integer
                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                    Convallis a cras semper auctor neque vitae tempus. Mattis
                    molestie a iaculis at erat pellentesque adipiscing.
                  </Col>
                </Col>
              </Col>
              <Col className="mt-5 bload_heading">Leave a Reply</Col>
              <Col className="mt-4">Comment</Col>
              <Col md className="blog-comment-text-area">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Leave a Comment."
                ></textarea>
              </Col>
              <Col md className="mt-3 col-12 blog_comment_submit_button">
                {" "}
                Submit Comment{" "}
              </Col>
            </Col>
            {/* ---------------- left side -------------------- */}
            <Col md={4} className="pt-3">
              <Row>
                <Col
                  onClick={handleSuggestArtical}
                  className="col-10 bload_heading-a"
                >
                  Suggested Articles
                </Col>
                <Col
                  onClick={handleSuggestArtical}
                  className="col-2 bload_heading arrowUp_blog_icon"
                >
                  {suggestOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestOpen ? (
                  <>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                    <Row md className="col-12 mt-3">
                      <Col className="col-4">
                        <Col className="blog-suggest-artical-img">
                          <img src={BlogCommentImg1} alt="" />
                        </Col>
                      </Col>
                      <Col className="col-8 ">
                        <Col>
                          <FaUser /> by Corabelle Durrad
                        </Col>
                        <Col className="mt-2">
                          <b>See How Michaele Built a New Life and Career</b>
                        </Col>
                        <hr />
                        <Col style={{ opacity: ".5" }}> October 12, 2023</Col>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Row>
              <hr />
              <Row>
                <Col
                  onClick={handleSuggestComment}
                  className="col-10 bload_heading-a"
                >
                  Recent Comments
                </Col>
                <Col
                  onClick={handleSuggestComment}
                  className="col-2 bload_heading arrowUp_blog_icon"
                >
                  {suggestCommentOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestCommentOpen ? (
                  <>
                    <Col md className="mt-3 col-md-12">
                      <FaComments /> <b>Irene Flores</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                    <Col md className="mt-3 col-md-12">
                      <FaComments /> <b>Anthony Patterson</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                    <Col className="mt-3 col-md-12">
                      <FaComments /> <b>Wendy Johnson</b> felis eget velit
                      aliquet sagittis id consectetur.
                    </Col>
                  </>
                ) : null}
              </Row>
              <hr />
              <Row>
                <Col
                  onClick={handleSuggestTags}
                  md={10}
                  className="col-10 bload_heading-a"
                >
                  Tags
                </Col>
                <Col
                  onClick={handleSuggestTags}
                  md={2}
                  className="col-2 bload_heading arrowUp_blog_icon"
                >
                  {suggestTagsOpen ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}
                </Col>
                {suggestTagsOpen ? (
                  <Col md aria-colcount={12} className="p-3 mt-3 blog_tag_area">
                    <span className="blog-tag"> Project Management </span>
                    <span className="blog-tag"> Engineer</span>
                    <span className="blog-tag">Network </span>
                    <span className="blog-tag"> Systems</span>
                    <span className="blog-tag"> Security</span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag"> Career</span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag">IT & Software </span>
                    <span className="blog-tag"> Hard Skills</span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Col className="mt-2 bload_heading">Articles</Col>
          <Row className="m-0 p-0 d-flex p-3">
            <Col className="ps-1 mt-md-0 mt-4 col-12 col-md-4 blog-artical-item">
              <div className="blog-artical-area-label">Photography</div>
              <Col className="blog-artican-image mb-4">
                <img src={ArticalImage1} alt="" />
                <div class="image-border"></div>
              </Col>
              <Col style={{ width: "65%" }}>
                <b>The Top Technical Skills All Employees Need in 2023</b>
              </Col>
              <Row className="d-flex ms-1" style={{ fontWeight: 600 }}>
                Read Articles
              </Row>
            </Col>
            <Col className="ps-1 mt-md-0 mt-4 col-12 col-md-4 blog-artical-item">
              <div className="blog-artical-area-label">Photography</div>
              <Col className="blog-artican-image  mb-4">
                <img src={ArticalImage2} alt="" />
                <div class="image-border"></div>
              </Col>
              <Col style={{ width: "65%" }}>
                <b>The Top Technical Skills All Employees Need in 2023</b>
              </Col>
              <Row className="d-flex ms-1" style={{ fontWeight: 600 }}>
                Read Articles
              </Row>
            </Col>
            <Col className="ps-1 mt-md-0 mt-4 col-12 col-md-4 blog-artical-item">
              <div className="blog-artical-area-label">Photography</div>
              <Col className="blog-artican-image  mb-4">
                <img src={ArticalImage3} alt="" />
                <div class="image-border"></div>
              </Col>
              <Col style={{ width: "65%" }}>
                <b>The Top Technical Skills All Employees Need in 2023</b>
              </Col>
              <Row className="d-flex ms-1" style={{ fontWeight: 600 }}>
                Read Articles
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default BlogPage;
