import RateCalculator from "./RateCalculator";

export const RateCalculatorPageConfig = {
  routes: [
    {
      path: "/teacher/RateCalculator",
      exact: true,
      component: RateCalculator
    }
  ]
};
