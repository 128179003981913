import Notifications from "./Notifications.jsx";

export const NotificationsPageConfig = {
  routes: [
    {
      path: "/parent/notification",
      exact: true,
      component: Notifications,
    },
  ],
};
