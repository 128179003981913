import Settings from "./Settings.jsx";

export const SettingsPageConfig = {
  routes: [
    {
      path: "/parent/setting",
      exact: true,
      component: Settings,
    },
  ],
};
