import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../components/Navbar";
import { Button } from "@mui/material";
import "../assets/css/InstructionsToJoin.css";
import Footer from "../components/Footer";
import HomeFaqImg from "../assets/instruction-page-img.png";

function InstructionsToJoin() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="main">
      <Navbar />
      <br />
      <br />
      <br />
      <Container fluid className="home-faq-container mt-3">
        <Row className="py-4">
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className=" d-flex align-items-start justify-content-center p-5"
              style={{ flexDirection: "column", gap: "10px" }}
            >
              <h5 className="fw-bold">User Instructions Manual</h5>
              <p className="text-start">
                A software user manual is a crucial resource for users of any
                software application. It provides them with information on how
                to use the software effectively and efficiently.
              </p>
              <h6>Last Updated : 17 Jan 2023</h6>
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center home-faq-img-section"
          >
            <div className="home-faq-img-area">
              <img src={HomeFaqImg} className="" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        {/* Instruction to join step by step */}

        <div className="mt-5">
          <h5 className="fw-bold">Instruction to Join</h5>
          <div className="mt-4">
            <div className="mt-4">
              <h6 className="fw-semibold mb-3">Step 1</h6>
              <p>
                Click on the "Register" button on the top right corner of the
                website.
              </p>
            </div>
            <div className="mt-4">
              <h6 className="fw-semibold mb-3">Step 2</h6>
              <p>
                Fill in the required details and click on the "Sign Up" button.
              </p>
            </div>
            <div className="mt-4">
              <h6 className="fw-semibold mb-3">Step 3</h6>
              <p>
                You will receive an email with a verification link. Click on the
                link to verify your email.
              </p>
            </div>
            <div className="mt-4">
              <h6 className="fw-semibold mb-3">Step 4</h6>
              <p>
                After verifying your email, you can now login to your account
                using your email and password.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default InstructionsToJoin;
