import TaskManagementPage from "./TaskManagementPage";

export const TaskManagementPageConfig = {
  routes: [
    {
      path: "/operation/TaskManagement",
      exact: true,
      component: TaskManagementPage
    }
  ]
};
