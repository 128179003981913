// contact
import contactPage from "./contactPage";

export const contactPageConfig = {
  routes: [
    {
      path: "/operation/contact",
      exact: true,
      component: contactPage
    }
  ]
};
