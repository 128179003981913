// Search
import EditWebsite from "./EditWebsite";

export const EditWebsitePageConfig = {
  routes: [
    {
      path: "/operation/edit/website",
      exact: true,
      component: EditWebsite
    },
  ]
};