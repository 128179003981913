// authService.js
import axios from "axios";
import { toast } from "react-toastify";

const adminLogin = async (username, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
      { username, password }
    );
    return response.data;
  } catch (error) {
    console.log(error.response.data.message || "Login failed");
    toast.error(error.response.data.message || "Login failed");
    throw error;
  }
};

export { adminLogin };
