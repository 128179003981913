import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import { Col, Container, Row } from "react-bootstrap";
import { FaBook } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoTimer } from "react-icons/io5";

import "../assets/css/GiftOnlineCources.css";
import Footer from "../components/Footer";
import { FaChevronRight } from "react-icons/fa6";
import Button from "../components/Button.jsx";

const GiftOnlineCourcesCertificate = require("../assets/gift-certicate.png");

function GiftOnlineCources() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <Container
          className=" d-flex flex-column justify-content-center px-5"
          fluid
          style={{ background: "rgb(247, 246, 249)", height: "40vh" }}
        >
          {/* <img
            style={{ width: "100%", height: "100%" }}
            src={GiftOnlineCourcesI}
            alt=""
          /> */}
          <h3 className="fw-bold font-popppins">GIFT ONLINE COURCE</h3>
          <p className="font-popppins">
            Home <FaChevronRight style={{ fontSize: 12 }} /> gift
          </p>
        </Container>
        <Container className="mt-3 mb-5">
          <Row>
            <Col md={8}>
              <h5>Decode the Magic of Gift Accessories:</h5>
              <h5 className="mt-2">Upgrade Your Gift-Giving Experience!</h5>
              <p style={{ width: "90%" }}>
                Consider accessories as the frosting on a cake, adding detail
                and visual flair. They offer a variety of options to genuinely
                make your gifts one-of-a-kind, going above and beyond the
                standard wrapping paper and ribbons.
              </p>
              <p>
                Join our one-day professional course and unlock the potential
                for business growth!
              </p>
              <hr style={{ opacity: ".2", width: "90%" }} />
              <h5>Why are Accessories Important?</h5>

              <ul>
                <li style={{ listStyle: "outside" }} className="mt-2">
                  Accessories Make Your Spaces Come Alive By Adding Texture,
                  Colour, Pattern And Form.
                </li>
                <li style={{ listStyle: "outside" }} className="mt-2">
                  Accessories Make Your Spaces Come Alive By Adding Texture,
                  Colour, Pattern And Form. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Nihil, totam facere dolor
                  voluptates doloremque, maiores deserunt temporibus perferendis
                  quisquam id ex consequatur sit aut odio iste, at earum omnis
                  asperiores quasi expedita!
                </li>
                <li style={{ listStyle: "outside" }} className="mt-2">
                  Accessories Make Your Spaces Come Alive By Adding Texture,
                  Colour, Pattern And Form.
                </li>
                <li style={{ listStyle: "outside" }} className="mt-2">
                  Accessories Make Your Spaces Come Alive By Adding Texture,
                  Colour, Pattern And Form. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Totam earum tempore sequi?
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <div className="img-box-certificate">
                <img
                  src={GiftOnlineCourcesCertificate}
                  className="rounded-2"
                  style={{ width: "100%", height: "100%", borderRadius: "7px" }}
                  alt=""
                />
              </div>
              <Row className="ps-3 mt-3">
                <h6>Our Cources</h6>
              </Row>
              <div className="gift-page-cources-list-area shadow">
                <div className="gift-page-cources-list-area-item">
                  <FaBook />
                  <span>Hindi</span>
                </div>
                <div className="gift-page-cources-list-area-item">
                  <FaBook />
                  <span>English</span>
                </div>
                <div className="gift-page-cources-list-area-item">
                  <FaBook />
                  <span>Math's</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="shadow mt-3 mb-3 rounded-3 p-3 gift-cource-checkout">
          <Row>
            <Col md={8} className="ps-5">
              <h5 className="mb-3">Course Details</h5>
              <Col
                className="d-flex align-items-center justify-content-start gift-cource-checkout-item"
                style={{ gap: "10px" }}
              >
                <FaBook /> <span className="ps-2">Training Methodology</span>
              </Col>
              <Col
                className="d-flex align-items-center justify-content-start gift-cource-checkout-item"
                style={{ gap: "10px" }}
              >
                <BsInfoCircleFill />{" "}
                <span className="ps-2">
                  Makeup your brand highly visible and expand your client base
                  through exceptional gift packaging.
                </span>
              </Col>
              <Col
                className="d-flex align-items-center justify-content-start gift-cource-checkout-item"
                style={{ gap: "10px" }}
              >
                <IoTimer /> <span className="ps-2">6th Months</span>
              </Col>
            </Col>
            <Col md={4} className="pt-4">
              <div>Price : 501</div>
              <div>Quantity : 1</div>
              <div>Discount : 2%</div>
              
            <button className="btn btn-sm mt-3 rounded-5 px-3" style={{background:"var(--yellow-color)", color:"black"}}>
              Checkout
            </button>

            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default GiftOnlineCources;
