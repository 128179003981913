import AddSubjectPage from "./AddSubject";

export const AddSubjectPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/course-management/add-subject",
      exact: true,
      component: AddSubjectPage
    }
  ]
};
