import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import {
  Badge,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import styled from "styled-components";
import { Button, IconButton } from "@mui/material";
import { Star } from "@material-ui/icons";
import { MdDelete, MdStarHalf } from "react-icons/md";
import { FaRegBookmark } from "react-icons/fa";

import {
  CalendarToday,
  MyLocation,
  SupervisorAccount,
} from "@material-ui/icons";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SchoolIcon from "@mui/icons-material/School";
import ShareIcon from "@mui/icons-material/Share";
import { GiPostOffice } from "react-icons/gi";
import { IoTimeSharp } from "react-icons/io5";
import { FaAngleRight, FaTrophy } from "react-icons/fa6";
import { Paper } from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
const UserProfile = require("../../assets/parent/profile-user.jpg");
const localizer = momentLocalizer(moment);
const TecherWithID = (props) => {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [eventFormData, setEventFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  // Define the events list
  const [myEventsList, setMyEventsList] = useState([
    {
      id: 0,
      title: "Your Remainder",
      start: new Date(2020, 0, 29, 9, 0, 0),
      end: new Date(2020, 0, 29, 13, 0, 0),
      resourceId: 1,
    },
    {
      id: 1,
      title: "Parent Meeting",
      allDay: true,
      start: new Date(2020, 13, 29, 14, 0, 0),
      end: new Date(2020, 0, 29, 16, 30, 0),
      resourceId: 2,
    },
    {
      id: 2,
      title: "Upcoming class",
      start: new Date(2020, 0, 29, 8, 30, 0),
      end: new Date(2020, 0, 29, 12, 30, 0),
      resourceId: 3,
    },
    {
      id: 11,
      title: "Birthday",
      start: new Date(2020, 0, 30, 7, 0, 0),
      end: new Date(2020, 0, 30, 10, 30, 0),
      resourceId: 4,
    },
    {
      id: 11,
      title: "Demo Class",
      start: new Date(2020, 0, 30, 7, 0, 0),
      end: new Date(2020, 0, 30, 10, 30, 0),
      resourceId: 4,
    },
  ]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventFormData({
      ...eventFormData,
      [name]: value,
    });
  };

  const handleDateChange = (field, date) => {
    setEventFormData({
      ...eventFormData,
      [field]: date,
    });
  };

  const handleAddEvent = () => {
    // Add the new event to the list of events
    // For simplicity, we'll use a random id here
    const newEvent = {
      id: Math.random(),
      title: eventFormData.title,
      start: eventFormData.start,
      end: eventFormData.end,
    };

    // Update the state with the new event
    setMyEventsList([...myEventsList, newEvent]);

    // Close the modal
    handleCloseModal();
  };

  const [showSubjectModal, setShowSubjectModal] = useState(false);

  const handleShowSubjectModal = () => setShowSubjectModal(true);
  const handleCloseSubjectModal = () => setShowSubjectModal(false);

  const [isAwardOpen, setIsAwardOpen] = useState(false);

  return (
    <OperationLayout>
      <h5>Teacher Profile</h5>
      {/* <OperationLocation path={history} /> */}
      <StyledContainer>
        <Container className="mt-3 p-3 border shadow rounded-2">
          <Row className="mt-3 p-3">
            <Col
              md={3}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="student-managent-student-profile">
                <img src={UserProfile} alt="" />
              </div>
            </Col>
            <Col md={9} className="mt-3">
              <Row>
                <h5>Cia</h5>
              </Row>
              <Col className="d-flex align-items-center justify-content-start teacher-profile-rating">
                <Star />
                <Star />
                <Star />
                <MdStarHalf /> 4.5 Rating
              </Col>
              <Col className="d-flex align-items-center justify-content-start mt-3">
                <DriveFileRenameOutlineIcon /> &nbsp; 39 reviews
              </Col>
              <Col className="d-flex align-items-center justify-content-start mt-3">
                <Button
                  className="teacher-button rounded-5 n-box-shadow border-0"
                  variant="outlined"
                >
                  {" "}
                  Follow{" "}
                </Button>
                <Button
                  className="teacher-button ms-2 n-box-shadow rounded-5 border-0"
                  variant="outlined"
                  endIcon={<ShareIcon />}
                >
                  {" "}
                  Shere{" "}
                </Button>
                <Button
                  className="teacher-button ms-2 n-box-shadow rounded-5 border-0"
                  variant="outlined"
                >
                  {" "}
                  Edit{" "}
                </Button>
                <Button
                  className="teacher-button bg-danger text-white ms-2 n-box-shadow rounded-5 border-0"
                  variant="outlined"
                >
                  Stop Appearing in search
                </Button>
                <Button
                  className="teacher-button bg-danger text-white ms-2 n-box-shadow rounded-5 border-0"
                  variant="outlined"
                >
                  {" "}
                  Block{" "}
                </Button>
                <IconButton aria-label="delete">
                  <MdDelete color="red" />
                </IconButton>
                <IconButton aria-label="delete">
                  <FaRegBookmark />
                </IconButton>
              </Col>
            </Col>
            <Row className="TeacherProfile-title-responsive">
              <h5>About Me:</h5>
            </Row>
            <Row className="TeacherProfile-peragraph-responsive">
              Lorem ipsum dolor sit amet consectetur. Sed ut dictumst penatibus
              eu nisi in. Cursus elit nibh nisl facilisis. Ullamcorper lacus
              massa volutpat sagittis scelerisque purus. Fringilla nisl maecenas
              cursus tincidunt pellentesque quam.
            </Row>
            <Row className="TeacherProfile-peragraph-responsive">
              Hac tellus in sit nunc mus. Tortor maecenas at justo laoreet. Urna
              fringilla euismod eu penatibus. Convallis ridiculus quam etiam
              donec. Morbi adipiscing facilisis volutpat facilisis dui. In nulla
              et vestibulum in sed ac massa. Fermentum nisi quisque tristique
              magnis. Viverra aliquam orci elementum malesuada sed. Lectus
              ultrices lacus maecenas volutpat arcu dolor viverra congue massa
            </Row>
          </Row>
          <Row className="ps-md-5">
            <Col md={5} className="ps-sm-5">
              <div className="teacher-profile-items-item mt-2">
                <GiPostOffice size={22} /> <span>Teacher Designation</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <LocalLibraryIcon /> <span>100 classes Completed</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <MyLocation /> <span>Lives in Mumbai</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <IoTimeSharp size={22} /> <span>120+ Hours Classes</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <CalendarToday /> <span>Joined on 24th Jan, 2023</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SupervisorAccount /> <span>10 + years of experience</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SchoolIcon /> <span>1000+ students taught</span>
              </div>
              <div
                className="teacher-profile-items-item mt-2 cursor-pointer"
                onClick={() => setIsAwardOpen(!isAwardOpen)}
              >
                <FaTrophy size={22} /> <span>Awards</span>
              </div>

              {isAwardOpen && (
                <div className="awards mt-3">
                  <div
                    className="line ms-2 rounded-5"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                  <div
                    className="line ms-2"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                  <div className="content-award ms-1 d-flex align-items-center">
                    <div
                      className="dot"
                      style={{
                        width: 10,
                        height: 10,
                        background: "#0000FF",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div className="ms-3">
                      <div className="award-title">This is Award Title</div>
                      <div className="award-desc"></div>
                    </div>
                  </div>
                  <div
                    className="line ms-2"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                  <div
                    className="line ms-2"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                  <div className="content-award ms-1 d-flex align-items-center">
                    <div
                      className="dot"
                      style={{
                        width: 10,
                        height: 10,
                        background: "#0000FF",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div className="ms-3">
                      <div className="award-title">This is Award Title</div>
                      <div className="award-desc"></div>
                    </div>
                  </div>
                  <div
                    className="line ms-2"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                  <div
                    className="line ms-2"
                    style={{ width: 3, height: 20, background: "#0000FF" }}
                  ></div>
                </div>
              )}

              <div className="slide_video mt-4 mb-5">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  modules={[Navigation, Autoplay, Pagination]}
                  grabCursor={true}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper pt-4 pb-5"
                >
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        style={{ width: "100%", height: "260px" }}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        style={{ width: "100%", height: "260px" }}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        style={{ width: "100%", height: "260px" }}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md={7} className="pe-5 mt-sm-3">
              <Row>
                <h4>Top Reviews</h4>
              </Row>
              <div className="teacter-profile-top-review-itme shadow border">
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="profile-name-for-review">
                      <span className="name">Adam Shah</span>
                      <br />
                      <span className="d-flex align-items-center justify-content-center this-is-review-star">
                        <Star /> <Star /> <Star /> <Star /> <MdStarHalf />{" "}
                        &nbsp;{" "}
                        <div className="teacher-profile-review-date">
                          07/02/2023
                        </div>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
                <Col className="p-2">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>
              <div className="teacter-profile-top-review-itme shadow border mt-3">
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="profile-name-for-review">
                      <span className="name">Adam Shah</span>
                      <br />
                      <span className="d-flex align-items-center justify-content-center this-is-review-star">
                        <Star /> <Star /> <Star /> <Star /> <MdStarHalf />{" "}
                        &nbsp;{" "}
                        <div className="teacher-profile-review-date">
                          07/02/2023
                        </div>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
                <Col className="p-2">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mt-sm-3 ps-md-3">
            <h5>Qualification</h5>
          </Row>
          <Col className="p-2">
            Lorem ipsum dolor sit amet consectetur. Sed ut dictumst penatibus eu
            nisi in. Cursus elit nibh nisl facilisis. Ullamcorper lacus massa
            volutpat sagittis scelerisque purus. Fringilla nisl maecenas cursus
            tincidunt pellentesque quam.
          </Col>
          <Row className="mt-2 mt-sm-3 ps-md-3">
            <h5>Special Acheivement:</h5>
          </Row>
          <Col className="p-2">
            Lorem ipsum dolor sit amet consectetur. Sed ut dictumst penatibus eu
            nisi in. Cursus elit nibh nisl facilisis. Ullamcorper lacus massa
            volutpat sagittis scelerisque purus. Fringilla nisl maecenas cursus
            tincidunt pellentesque quam.
          </Col>
        </Container>

        <h5 className="mt-5 mb-4">Calender</h5>

        <Row>
          <Col>
            <Paper style={{ padding: "5px" }}>
              <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                defaultDate={new Date(2020, 1, 1)}
                style={{ height: 500 }}
              />
            </Paper>

            {/* Add Event Modal */}
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              centered
              style={{ zIndex: 99999 }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Event</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter event title"
                      name="title"
                      value={eventFormData.title}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStart">
                    <Form.Label>Start Date and Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="start"
                      value={moment(eventFormData.start).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(e) =>
                        handleDateChange("start", new Date(e.target.value))
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formEnd">
                    <Form.Label>End Date and Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="end"
                      value={moment(eventFormData.end).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(e) =>
                        handleDateChange("end", new Date(e.target.value))
                      }
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="me-3 py-2 px-4 rounded-5 color-yellow fw-bold bg-transparent"
                  style={{ border: "1px solid var(--yellow-color)" }}
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className="py-2 px-4 rounded-5 border-0"
                  style={{ background: "var(--yellow-color)" }}
                  onClick={handleAddEvent}
                >
                  Add Event
                </button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

        <Container className="mt-3">
          <Row>
            <h6>Payments</h6>
          </Row>
          <Row>
            <Col className="p-4 shadow rounded-3">
              <span>
                <b>Total Ammount Paid (Monthly) : </b> 40,000 (INR)
              </span>
              <br />
              <span className="mt-2">
                <b>Total Leaves (Monthly) : </b> 10 Day's
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <h6>Cources Request</h6>
          </Row>
          <Row className="mt-4">
            <h6>Complaints</h6>
          </Row>

          <Row className="mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Complaint</th>
                  <th>Complaint Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Complaint 1</td>
                  <td>12 January 2023</td>
                  <td>
                    <Badge bg="danger">In Active</Badge>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Complaint 2</td>
                  <td>12 January 2023</td>
                  <td>
                    <Badge bg="danger">In Active</Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>

          <Row>
            <StyleContainerT>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      className="rounded-5"
                      variant="outlined"
                      size="small"
                    >
                      Accept
                    </Button>
                    <Button
                      className="rounded-5 border-danger bg-danger ms-2 text-white"
                      variant="outlined"
                      size="small"
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      className="rounded-5"
                      variant="outlined"
                      size="small"
                    >
                      Accept
                    </Button>
                    <Button
                      className="rounded-5 border-danger bg-danger ms-2 text-white"
                      variant="outlined"
                      size="small"
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              </div>
            </StyleContainerT>
          </Row>
          <Row className="mt-3">
            <h6>Schedules</h6>
          </Row>
          <Row>
            <StyleContainerT>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      disabled
                      className="rounded-5"
                      variant="outlined"
                      size="small"
                    >
                      Acceptd
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      disabled
                      className="rounded-5 border-danger bg-danger ms-2 text-white"
                      variant="outlined"
                      size="small"
                    >
                      Rejected
                    </Button>
                  </div>
                </div>
              </div>
            </StyleContainerT>
          </Row>
          <Row className="mt-3">
            <h6>Certificate Requiest</h6>
          </Row>
          <Row>
            <StyleContainerT>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      disabled
                      className="rounded-5"
                      variant="outlined"
                      size="small"
                    >
                      Acceptd
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <Button
                      disabled
                      className="rounded-5 border-danger bg-danger ms-2 text-white"
                      variant="outlined"
                      size="small"
                    >
                      Rejected
                    </Button>
                  </div>
                </div>
              </div>
            </StyleContainerT>
          </Row>
          <Row className="mt-3">
            <h6>Associated Parents</h6>
          </Row>
          <Row>
            <StyleContainerT>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <p className="text-warning">
                      <b>
                        view {">"}
                        {">"}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="review_user_image">
                      <img
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                        alt=""
                      />
                    </div>
                    <div className="ms-5">
                      <h6 className="m-0 fw-bold">Kristin Watson</h6>
                      <p>12 January 2023</p>
                    </div>
                    <Badge className="ms-3" bg="danger">
                      In Active
                    </Badge>
                  </div>
                  <div>
                    <p className="text-warning">
                      <b>
                        view {">"}
                        {">"}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </StyleContainerT>
          </Row>
        </Container>
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .teacher_right_arrow2,
  .teacher_left_arrow2,
  .teacher_right_arrow1,
  .teacher_left_arrow1,
  .teacher_right_arrow3,
  .teacher_left_arrow3 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .teacher_right_arrow2,
  .teacher_right_arrow1,
  .teacher_right_arrow3 {
    right: 5%;
  }
  .teacher_left_arrow2,
  .teacher_left_arrow1,
  .teacher_left_arrow3 {
    left: -1%;
  }
`;

const StyleContainerT = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default TecherWithID;
