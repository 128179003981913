import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";

const data = [
  {
    id: 1,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 1",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 2,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 2",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 3,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 3",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 4,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 4",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 5,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 5",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 6,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 6",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
  {
    id: 7,
    parentID: "PAR346",
    parentName: "Parent 1",
    studentID: "STU346",
    studentName: "Student 1",
    courseId: "COURSE346",
    courseName: "Course 1",
    tittle: "Demo 7",
    date: "2021-09-01 12:00:00",
    status: "Pending",
  },
];

const MenualDemos = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <h5>Manual Demos</h5>

      <Table
        className="mt-4"
        style={{ minWidth: 1600 }}
        striped
        bordered
        hover
        responsive
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Parent ID</th>
            <th>Parent Name</th>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Title</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.parentID}</td>
              <td>{item.parentName}</td>
              <td>{item.studentID}</td>
              <td>{item.studentName}</td>
              <td>{item.courseId}</td>
              <td>{item.courseName}</td>
              <td>{item.tittle}</td>
              <td>{item.date}</td>
              <td>{item.status}</td>
              <td>
                <button
                  onClick={handleShow}
                  className="btn btn-sm"
                  style={{
                    backgroundColor: "var(--color-primary)",
                    color: "white",
                  }}
                >
                  Assign a Teacher
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign a Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Teacher ID: <input type="text" />
          </p>
          <p>
            Teacher Name: <input type="text" />
          </p>
          <p>
            Teacher Email: <input type="text" />
          </p>
          <p>
            Teacher Phone: <input type="text" />
          </p>
          <p>
            Teacher Address: <input type="text" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary">Save</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MenualDemos;
