import Axios from "./axios";
import { toast } from "react-toastify";
import { MyAPI } from "./MyAPI";

export const createConvo = async (token, { id, role }) => {
  try {
    const response = await Axios.post(
      "/chat/conversation",
      {
        id,
        role,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log({ response: response.data });
    if (response.data.isData) {
      toast.success(response.data.message);
    }

    if (response.data.res_type !== "success") return null;

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getConvoList = async (token) => {
  try {
    const response = await Axios.get("/chat/list", {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data.res_type !== "success") return null;

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMessages = async (token, id) => {
  try {
    const response = await Axios.get(`/chat/messages/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data.res_type !== "success") return null;

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCommunityMessages = async (token) => {
  try {
    const response = await Axios.get("/chat//pcom", {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data.res_type !== "success") return null;

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const addGroup = async (token, data, setLoading) => {
  try {
    setLoading(true);
    const response = await Axios.post("/chat/group", data, {
      headers: { Authorization: `Bearer ${token}` },
      "Content-Type": "multipart/form-data",
    });

    console.log(response);

    if (response.data.res_type !== "success") return null;

    return response.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getAllGroups = async (token) => {
  try {
    let res = await MyAPI.GET("/chat/group", token);
    let { res_type, data, message } = res.data || res;
    if (res_type === "success") {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    toast.error(error.message);
  }
};
