import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const Chats = [
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
  {
    _id: "1",
    name: "John Doe",
    email: "test@gmail.com",
    phone: "1234567890",
    message: "This is a chat",
    createdAt: "2021-06-01T19:20:14.298Z",
  },
];

const ChatReport = () => {
  const token = useSelector((state) => state.token.value);
  const [dataLoading, setDataLoading] = useState(false);
  const [chats, setChats] = useState([]);

  const fetchAllReportedChat = async (token) => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET("/chat/report", token);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setChats(apiData);
      } else {
        toast.error(message || "Error Fetching data.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(()=>{
    if(token){
      fetchAllReportedChat(token)
    }
  },[token])

  const deleteHandler = (id) => {
    console.log("Delete");
  };
  return (
    <>
      <h3>Chat Reports</h3>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S.No.</th>
            <th className="text-white">Name</th>
            <th className="text-white">Email</th>
            <th className="text-white">Phone</th>
            <th className="text-white">Message</th>
            <th className="text-white">Date</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {Chats.map((chat, index) => (
            <tr key={chat._id} className="text-center bg-light">
              <td>{index + 1}</td>
              <td>{chat.name}</td>
              <td>{chat.email}</td>
              <td>{chat.phone}</td>
              <td>{chat.message}</td>
              <td>{chat.createdAt.substring(0, 10)}</td>
              <td>
                <Button
                  variant="danger"
                  className="btn-sm"
                  onClick={() => deleteHandler(chat._id)}
                >
                  <MdDelete />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ChatReport;
