import ComplaintPage from "./ComplaintPage";
import Sugessions from "./Sugessions";

export const ComplaintPageConfig = {
  routes: [
    {
      path: "/operation/complaint",
      exact: true,
      component: ComplaintPage
    },
    {
      path: "/operation/suggestions",
      exact: true,
      component: Sugessions
    },
  ]
};
