import Attendance from "./Attendance";
import PaymentPage from "./PaymentPage";

export const PaymentPageConfig = {
  routes: [
    {
      path: "/account/payments",
      exact: true,
      component: PaymentPage
    },
    {
      path: "/account/attendance",
      exact: true,
      component: Attendance
    },
  ]
};
