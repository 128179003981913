import AllBlogs from "./AllBlogs";
import BlogAddPage from "./BlogAddPage";
import BlogPage from "./BlogPage";
import ReqBlogs from "./ReqBlogs";
import UpdateBlog from "./UpdateBlog";

export const BlogPageConfig = {
  routes: [
    {
      path: "/operation/Blogs",
      exact: true,
      component: BlogPage,
    },
    {
      path: "/operation/Blogs/add",
      exact: true,
      component: BlogAddPage,
    },
    {
      path: "/operation/Blogs/update/:id",
      exact: true,
      component: UpdateBlog,
    },
    {
      path: "/operation/blog/all",
      exact: true,
      component: AllBlogs,
    },
    {
      path: "/operation/blog/request",
      exact: true,
      component: ReqBlogs,
    },
  ],
};
