// WebsiteSetting
import WebsiteSettingPage from "./WebsiteSettingPage";

export const WebsiteSettingPageConfig = {
  routes: [
    {
      path: "/operation/WebsiteSetting",
      exact: true,
      component: WebsiteSettingPage
    }
  ]
};
