import AddTestPage from "./AddTestPage";

export const AddTestPageConfig = {
  routes: [
    {
      path: "/teacher/test/:courseId/add",
      exact: true,
      component: AddTestPage
    }
  ]
};
