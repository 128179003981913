import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import ParentFooter from "../../components/ParentFooter";
import "../../assets/css/parent/chat.css";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import ChatPageCommon from "../../components/chat/ChatPageCommon";

const ChatPage = () => {
  const [user, setUser] = useState({});
  const parentId = localStorage.getItem("parentId");
  const token = useSelector((state) => state.token.value);

  const getParentById = async () => {
    try {
      const response = await axios.get(`/parent/getById?ID=${parentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.res_type === "success") {
        setUser(response.data.data.parent);
      } else {
        toast.error("Failed to fetch parent data");
      }
    } catch (error) {
      console.error("Error fetching parent data:", error);
    }
  };
  
  useEffect(() => {
    getParentById();
  }, [parentId, token]);

  return (
    <OftadehLayout>
      <h1>Chat</h1>

      <ChatPageCommon user={user} token={token} community={true} />

      <ParentFooter />
    </OftadehLayout>
  );
};

export default ChatPage;
