import React from "react";
import { Button, Table, Badge } from "react-bootstrap";

const complaints = [
  {
    id: 1,
    compaintId: "C001",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Close",
  },
  {
    id: 2,
    compaintId: "C002",
    name: "Ahmed",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Open",
  },
  {
    id: 3,
    compaintId: "C003",
    name: "Sohail",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Close",
  },
  {
    id: 4,
    compaintId: "C004",
    name: "Nasir",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Open",
  },
  {
    id: 5,
    compaintId: "C005",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Close",
  },
  {
    id: 6,
    compaintId: "C006",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Open",
  },
  {
    id: 7,
    compaintId: "C007",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Close",
  },
  {
    id: 8,
    compaintId: "C008",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Open",
  },
  {
    id: 9,
    compaintId: "C009",
    name: "Ali",
    complaint: "I am facing issue in my internet connection",
    dateOfComplaint: "12-12-2021",
    status: "Close",
  },
];

const TaskManagementComponent = () => {
  //   // Dummy tasks data
  //   const tasks = [
  //     { id: 1, title: "Task 1", status: "pending" },
  //     { id: 2, title: "Task 2", status: "done" },
  //     { id: 3, title: "Task 3", status: "issue" },
  //   ];

  //   const handleTaskStatus = (taskId, newStatus) => {
  //     // Handle changing task status
  //   };

  return (
    // <Container className="mt-3 mb-3 px-3 py-3">
    //   <Row>
    //     <Col
    //       md={6}
    //       className="bg-danger text-white"
    //       style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
    //     >
    //       ISSUE FOUND
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center">TRACKED</Col>
    //       <Col className="text-center">ASSIGNEE</Col>
    //       <Col className="text-center">DUE DATE</Col>
    //       <Col className="text-center">STAGE</Col>
    //       <Col className="text-center">PRIORITY</Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-danger me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FEECF3" }}
    //           className="px-2 py-1 text-danger rounded-2"
    //         >
    //           INITIATION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-danger me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FEECF3" }}
    //           className="px-2 py-1 text-danger rounded-2"
    //         >
    //           INITIATION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-danger me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FEECF3" }}
    //           className="px-2 py-1 text-danger rounded-2"
    //         >
    //           INITIATION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-danger me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FEECF3" }}
    //           className="px-2 py-1 text-danger rounded-2"
    //         >
    //           INITIATION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <br />
    //   <br />
    //   <Row>
    //     <Col
    //       md={6}
    //       className="bg-warning text-white"
    //       style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
    //     >
    //       REVIEW
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center">TRACKED</Col>
    //       <Col className="text-center">ASSIGNEE</Col>
    //       <Col className="text-center">DUE DATE</Col>
    //       <Col className="text-center">STAGE</Col>
    //       <Col className="text-center">PRIORITY</Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-warning me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FFF7D9" }}
    //           className="px-2 py-1 text-warning rounded-2"
    //         >
    //           EXECUTION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-warning me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FFF7D9" }}
    //           className="px-2 py-1 text-warning rounded-2"
    //         >
    //           EXECUTION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-warning me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FFF7D9" }}
    //           className="px-2 py-1 text-warning rounded-2"
    //         >
    //           EXECUTION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    //   <Row className="border rounded-2 mt-2 mb-2">
    //     <Col md={6} className="d-flex py-2 px-3">
    //       <div
    //         className="box rounded-1 border-danger bg-warning me-3"
    //         style={{ width: "15px", height: "15px" }}
    //       ></div>
    //       <div className="">Update Contractor Agreement</div>
    //     </Col>
    //     <Col
    //       md={6}
    //       className="d-flex align-items-center justify-content-between"
    //     >
    //       <Col className="text-center ">
    //         <IoTimerOutline color="#ccc" size={22} />
    //       </Col>
    //       <Col className="text-center ">ME</Col>
    //       <Col className="text-center ">14 Jan 2024</Col>
    //       <Col className="text-center ">
    //         <div
    //           style={{ backgroundColor: "#FFF7D9" }}
    //           className="px-2 py-1 text-warning rounded-2"
    //         >
    //           EXECUTION
    //         </div>
    //       </Col>
    //       <Col className="text-center ">
    //         {" "}
    //         <FaRegFlag color="#ccc" size={22} />
    //       </Col>
    //     </Col>
    //   </Row>
    // </Container>

    <>
      <h4 className="mt-5">All Complaints</h4>
      <Table
        className="mt-4 mb-3 rounded-4"
        striped
        responsive
        hover
        style={{ backgroundColor: "var(--color-primary)" }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Complaint ID</th>
            <th className="text-white">Name</th>
            <th className="text-white">Complaint</th>
            <th className="text-white">Date of Complaint</th>
            <th className="text-white">Status</th>
            <th className="text-white" colSpan={2}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {complaints.map((complaint, index) => (
            <tr className="text-center bg-light" key={complaint.id}>
              <td>{index + 1}</td>
              <td>{complaint.compaintId}</td>
              <td>{complaint.name}</td>
              <td>{complaint.complaint}</td>
              <td>{complaint.dateOfComplaint}</td>

              <td>
                <Badge
                  bg={`${complaint.status == "Close" ? "danger" : "warning"}`}
                >
                  {complaint.status}
                </Badge>
              </td>
              <td>
                <Button variant="info" size="sm">
                  Forward to Admin
                </Button>
              </td>
              <td>
                <Button variant="info" size="sm">
                  Forward to Account
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TaskManagementComponent;
