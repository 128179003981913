import PaymentPage from "./PaymentPage";

export const TeacherPaymentPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/payment",
      exact: true,
      component: PaymentPage
    }
  ]
};
