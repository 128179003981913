import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import ParentFooter from "../../components/ParentFooter";
import { Container, Card, Button,Col,Row } from "react-bootstrap";

const SingleTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const totalQuestions = 12; // Assuming 12 questions for demonstration


  return (
    <TeacherLayout>
      <Container className="mt-4">
        <h3>Student Test Result</h3>
        <Card className="my-3">
          <Card.Body>
            <h5>Student Name: John Doe</h5>
            <h5>Test Score: 85%</h5>
            <p>Test Date: 10/04/2024</p>
            <p>Test Duration: 60 minutes</p>
            <hr />
            <p><b>Question {currentQuestion + 1}</b>. Question text goes here...</p>

            <Col md={7}>
              <Row>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(A) Objective 1</Col>
              <Col className="border rounded-3 py-2 mt-2 ms-2 bg-success text-white border-success" md={5}>(B) Objective 2</Col>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(C) Objective 3</Col>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(D) Objective 4</Col>
              </Row>
            </Col>
            <br />
            <p><b>Question {currentQuestion + 2}.</b> Question text goes here...</p>

            <Col md={7}>
              <Row>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(A) Objective 1</Col>
              <Col className="border border-danger bg-danger text-white rounded-3 py-2 mt-2 ms-2" md={5}>(B) Objective 2</Col>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(C) Objective 3</Col>
              <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(D) Objective 4</Col>
              </Row>
            </Col>
            
          </Card.Body>
        </Card>
        
        <div className="text-center">
          <p>Total Questions: {totalQuestions}</p>
          <p>Maximum Obtainable Score: 100%</p>
          <p>Failed Questions: 2</p>
        </div>
      </Container>
      <ParentFooter />
    </TeacherLayout>
  );
};

export default SingleTest;
