import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "../../../oftadeh-layouts/layout/TeacherLayout";
import LayoutContext from "../../../context/LayoutContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

function TeacherLayout(props) {
  const classes = useStyles(props);
  const token = useSelector((state) => state.token.value);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/parent/sign-in");
    }
  }, [token, navigate]);

  return (
    <LayoutContext.Provider value={{ content: props.children }}>
      <div className={classes.root}>
        <CssBaseline />
        <Layout />
      </div>
    </LayoutContext.Provider>
  );
}

TeacherLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default TeacherLayout;
