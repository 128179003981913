import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row, Table } from "react-bootstrap";
import "chart.js/auto";
import { Chart as ChartJS, Bar, Pie } from "react-chartjs-2";

const SalesReportPage = (props) => {
  const { history } = props;
  const UserData = [
    {
      id: 1,
      name: "Returning(353)",
      userGain: 353,
    },
    {
      id: 2,
      name: "Not Returning(523)",
      userGain: 523,
    },
  ];

  const [userDataa, setUserData] = useState({
    labels: UserData.map((data) => data.name),
    datasets: [
      {
        label: "Users Gained",
        data: [353, 523],
        backgroundColor: ["#1684EA", "#1ABEF3"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    setUserData({
      labels: UserData.map((data) => data.name),
      datasets: [
        {
          label: "Users Gained",
          data: [353, 523],
          backgroundColor: ["#1684EA", "#1ABEF3"],
          borderColor: "black",
          borderWidth: 0,
        },
      ],
    });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "All Details",
      },
    },
  };
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}

      <Row className="mt-4">
        <Col md={12} className="shadow rounded-4 p-3">
          <Row>
            <Col md={6}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Select className="rounded-5 shadow border-yellow">
                  <option value="option1">Top Searchers</option>
                  <option value="option1">Top clicks</option>
                  <option value="option2">No result Search</option>
                  <option value="option3">Page visit</option>
                  <option value="option3">Conversion</option>
                  <option value="option3">Top courses</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col
              md={6}
              className=" mt-3 mt-md-0 d-flex align-items-center justify-content-end gap-3"
            >
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="date"
                  placeholder="Appointment Title"
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="time"
                  placeholder="Appointment Title"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="shadow rounded-3  bg-secondary text-white py-3 px-3 mt-4">
                <h6 className="m-0">Search 1</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="shadow rounded-3 bg-secondary text-white py-3 px-3 mt-4">
                <h6 className="m-0">Search 2</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="shadow rounded-3  bg-secondary text-white py-3 px-3 mt-4">
                <h6 className="m-0">Search 3</h6>
              </div>
            </Col>
            <Col md={4}>
              <div className="shadow rounded-3 bg-secondary text-white py-3 px-3 mt-4">
                <h6 className="m-0">Search 4</h6>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={6}></Col>
        <Col
          md={6}
          className=" d-flex align-items-center justify-content-end gap-3"
        >
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              className="rounded-5 shadow border-yellow"
              type="date"
              placeholder="Appointment Title"
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Control
              className="rounded-5 shadow border-yellow"
              type="time"
              placeholder="Appointment Title"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="my-4">
        <Col md={12}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td>Top Paying</td>
                <td>Overtime Numbers</td>
                <td>New Clients</td>
                <td>Sources</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <p className="m-0 text_yellow fw-semibold text-decoration-underline text-end">
            View More
          </p>
        </Col>
      </Row>

      <Row className="my-5">
        <Col>
          <h3>Returning vs not Returning Clients</h3>
        </Col>
      </Row>

      <Row className="my-5">
        <Col md={6}>
          <Pie
            data={userDataa}
            options={{
              ...options,
              style: { width: "280px", height: "280px" },
            }}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h3>Sources</h3>
        </Col>
      </Row>

      <Row className="my-4 px-4">
        <Col md={12}>
          <div>
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Affiliate Reference</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "80%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Reffered by teacher</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "20%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Campaign</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "0%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Coupon Code</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "80%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Facebook</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "20%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Insta</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">11</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "20%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Sourced by marketing team</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">0</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "0%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Referred by parent</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">2</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "20%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Internal customers</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">0</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "0%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>

          <div className="mt-3">
            <Row>
              <Col md={6} className="m-0 p-0">
                <p className="m-0">Others</p>
              </Col>
              <Col md={6} className="d-flex justify-content-end m-0 p-0">
                <p className="m-0">2</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col
                md={12}
                style={{ height: 5, width: "100%" }}
                className="bg-secondary rounded-5 position-relative overflow-hidden m-0 p-0"
              >
                <div
                  style={{ height: "100%", width: "20%" }}
                  className="position-absolute top-0 left-0 bg-yellow rounded-5"
                ></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default SalesReportPage;
