import gsap from "gsap";
import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import { PiStudentFill } from "react-icons/pi";
import { TbDeviceMobileMessage } from "react-icons/tb";
import { FaUserEdit } from "react-icons/fa";

import { Col, Container, ListGroup, Row } from "react-bootstrap";
import "../assets/css/HowDoesItWork.css";
import { PlayCircleOutline } from "@material-ui/icons";
import Footer from "../components/Footer.js";
import HomeFaqImg from "../assets/how-it-works-banner-img.png";
import Accordian from "../components/homepage/Accordian.jsx";

function HowDoesItWork() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <Container fluid className="home-faq-container mt-3">
          <Row>
            <Col
              md={7}
              className="d-flex align-items-center justify-content-center"
            >
              <div
                className=" d-flex align-items-start justify-content-center p-5"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <h3 className="fw-bold">
                  How Urja <br /> Talents Works
                </h3>
                <p className="text-start">
                  Urja Talents takes online learning to the next level with a
                  supportive environment that balances structure with
                  flexibility. Young scholars engage with a quality curriculum
                  that fosters a love of learning and personal growth.
                </p>
              </div>
            </Col>
            <Col
              md={5}
              className="d-flex align-items-center justify-content-start home-faq-img-section"
            >
              <div className="home-faq-img-area p-4">
                <img src={HomeFaqImg} className="" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 mb-3">
          <center>
            <h3 className="fw-semibold">The Benefits of Online Learning</h3>
          </center>
          <center>
            <p style={{ width: "80%" }}>
              Online school works much like conventional school with the added
              convenience of a virtual platform. Students can learn from
              anywhere there’s an internet connection. At the same time,
              students enjoy a strong curriculum and consistent academic
              guidance to support their unique learning styles. Students and
              parents at Urja Talents -powered schools have access to our Online
              Learning System (OLS) at any time. This uninterrupted access to
              lessons and resources helps bolster advanced learning initiatives
              for all grade levels.
            </p>
          </center>
        </Container>
        <Container>
          <Row className="mt-5">
            <Col md={4} className="p-3">
              <div className="shadow how-it-works-cards d-flex flex-column align-items-center justify-content-center px-4 py-4  rounded-4">
                <div
                  className="icon shadow"
                  style={{
                    background:
                      "linear-gradient(rgb(152, 134, 255), rgb(212, 202, 255))",
                  }}
                >
                  <PiStudentFill />
                </div>

                <h4 className="mt-3">Learn</h4>
                <p className="mt-3 text-center">
                  Curriculum is presented through offline activities, as well as
                  live online instruction, ready-made videos, self-paced
                  assignments, offline activities, and interactive quizzes.
                </p>
              </div>
            </Col>
            <Col md={4} className="p-3">
              <div className="shadow how-it-works-cards d-flex flex-column align-items-center justify-content-center px-4 py-4  rounded-4">
                <div
                  className="icon shadow"
                  style={{
                    background:
                      "linear-gradient(rgb(255, 184, 85), rgb(255, 222, 175))",
                  }}
                >
                  <TbDeviceMobileMessage />
                </div>
                <h4 className="mt-3">Learn</h4>
                <p className="mt-3 text-center">
                  We encourage active participation for all grade levels.
                  Learners can take part in live discussions, post on message
                  boards, and connect with peers to enjoy the social aspects of
                  school in a safe.
                </p>
              </div>
            </Col>
            <Col md={4} className="p-3">
              <div className="shadow how-it-works-cards d-flex flex-column align-items-center justify-content-center px-4 py-4 rounded-4 ">
                <div
                  className="icon shadow"
                  style={{
                    background:
                      "linear-gradient(rgb(255, 132, 220), rgb(255, 211, 243))",
                  }}
                >
                  <FaUserEdit />
                </div>
                <h4 className="mt-3">Experience</h4>
                <p className="mt-3 text-center">
                  Pursue interest groups, clubs, competitions, and other
                  extracurricular activities — online and offline — to reinforce
                  academic concepts and support fulfilling social interactions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Accordian />
        <Footer />
      </div>
    </>
  );
}

export default HowDoesItWork;
