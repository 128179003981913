import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Modal, Row, Table } from "react-bootstrap";

const AppPaymentPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <AccountLayout>
      <h3>App Payment</h3>

      <Row className="mt-4 d-flex align-items-center justify-content-end">
        <button
          onClick={handleShow}
          className="btn text-white rounded-4"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Add App Payment
        </button>
      </Row>

      <h5>Requests</h5>

      <Table className="mt-3" striped bordered hover>
        <thead>
          <tr className="bg-yellow ">
            <th className="text-white">#</th>
            <th className="text-white">App Name</th>
            <th className="text-white">Name</th>
            <th className="text-white">Date</th>
            <th className="text-white">Time</th>
            <th className="text-white">Next Payment Date</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Reaseon</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(1)
            .map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>App Name</td>
                <td>Name</td>
                <td>12/12/2021</td>
                <td>12:12</td>
                <td>12/12/2021</td>
                <td>1000</td>
                <td>Reason</td>
                <td>
                  <button className="btn btn-sm btn-success">Approve</button>
                  <button className="btn btn-sm ms-2 btn-danger">Reject</button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <h5 className="mt-5">All App Payments</h5>

      <Table className="mt-3" striped bordered hover>
        <thead>
          <tr className="bg-yellow ">
            <th className="text-white">#</th>
            <th className="text-white">App Name</th>
            <th className="text-white">Name</th>
            <th className="text-white">Date</th>
            <th className="text-white">Time</th>
            <th className="text-white">Next Payment Date</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Reaseon</th>
            <th className="text-white">Status</th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill(1)
            .map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>App Name</td>
                <td>Name</td>
                <td>12/12/2021</td>
                <td>12:12</td>
                <td>12/12/2021</td>
                <td>1000</td>
                <td>Reason</td>
                <td>
                  <span className="text-success">Approved</span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Modal
        show={show}
        style={{ zIndex: 99999 }}
        centered
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add App Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Row className="mb-3">
              <Col md={6}>
                <label htmlFor="appName" className="form-label">
                  App Name
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="appName"
                  placeholder="App Name"
                />
              </Col>
              <Col md={6}>
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="date"
                  placeholder="Date"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <label htmlFor="time" className="form-label">
                  Time
                </label>
                <input
                  type="time"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="time"
                  placeholder="Time"
                />
              </Col>
              <Col md={6}>
                <label htmlFor="nextPaymentDate" className="form-label">
                  Next Payment Date
                </label>
                <input
                  type="date"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="nextPaymentDate"
                  placeholder="Next Payment Date"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <label htmlFor="amount" className="form-label">
                  Amount
                </label>
                <input
                  type="number"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="amount"
                  placeholder="Amount"
                />
              </Col>
              <Col md={6}>
                <label htmlFor="reason" className="form-label">
                  Reason
                </label>
                <input
                  type="text"
                  className="form-control bg-transparent border-yellow rounded-4 shadow"
                  id="reason"
                  placeholder="Reason"
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger rounded-4" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn rounded-4 text-white"
            style={{ background: "var(--color-primary)", width: "fit-content" }}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default AppPaymentPage;
