import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import "./Complain.css";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button, Chip } from "@mui/material";
import { Add } from "@material-ui/icons";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ProfilePic from "../../assets/parent/profile-user.jpg";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import moment from "moment";
import { FaFilePdf } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";

function Complain() {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [complaints, setComplaints] = useState(null);

  const token = useSelector((state) => state.token.value);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const handleRaiseComplaint = async () => {
    handleCloseModal();

    const formData = new FormData();
    formData.append("titile", title);
    formData.append("description", description);
    formData.append("category", type);
    formData.append("attachment", file);

    // send data to server with axios and token

    try {
      const response = await axios.post("/parent/complaint", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      // console.log(response.data);

      if (response.data.res_type === "success") {
        setComplaints((prevComplaints) => [
          response.data.data,
          ...prevComplaints,
        ]);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const response = await axios.get("/parent/complaint", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.res_type === "success") {
          setComplaints(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchComplaints();
  }, [token]);

  // download file directly from s3 bucket

  const handleDownloadFile = (key) => {
    const link = document.createElement("a");
    link.href = key;
    link.setAttribute("download", "file.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <OftadehLayout>
      <h3>Complain</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Col className="d-flex align-items-center justify-content-between">
        <Col md={6}>
          <Button
            variant="outlined"
            onClick={handleShowModal}
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              borderColor: "var(--color-primary)",
              color: "#fff",
            }}
            endIcon={<Add color="#fff" />}
          >
            {" "}
            Raise Complaint{" "}
          </Button>
        </Col>
        <Col className="d-flex align-items-center justify-content-end" md={6}>
          <Button
            variant="outlined"
            style={{
              borderColor: "black",
              borderRadius: "25px",
              color: "black",
            }}
            startIcon={<FilterAltOffIcon />}
          >
            {" "}
            Filter{" "}
          </Button>
        </Col>
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Raise Complaint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="complaintTitle">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={handleTitleChange}
            />
          </Form.Group>
          <Form.Group className="mt-2" controlId="dropdownForm">
            <Form.Select
              aria-label="Select complaint type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option>Select complaint type</option>
              <option>Payment Related</option>
              <option>Previous Class</option>
              <option>Live Related</option>
              <option>Technical Issue</option>
              <option>Teacher Related</option>
              <option>Offer and Coupons</option>
              <option>Learniee Mall</option>
              <option>General FAQ</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3" controlId="complaintTitle">
            <Form.Control
              type="file"
              placeholder="upload file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
          <Form.Group controlId="complaintDescription" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={handleDescriptionChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger rounded-5 text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3 rounded-5"
            variant="outlined"
            onClick={handleRaiseComplaint}
          >
            Raise Complaint
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
        <Row className="p-2 m-0">
          <Col
            md={4}
            className="d-flex align-items-center justify-content-start p-0 m-0"
            style={{ gap: "10px" }}
          >
            {" "}
            <div className="parent-complain-image">
              <img src={ProfilePic} alt="userImg" />{" "}
            </div>{" "}
            <h5>Cody Fisher</h5>{" "}
          </Col>
          <Col
            md={8}
            className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
            style={{ fontSize: "18px" }}
          >
            Subject: Introduction to Thermodynamics
          </Col>
        </Row>
        <Col className="d-flex align-items-center justify-content-between p-2">
          <Col md={6}> 5 Jun 2023</Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <Chip
              label="Close"
              className="n-box-shadow"
              style={{
                color: "white",
                borderColor: "yellow",
                background: "red",
              }}
              color="primary"
            />
          </Col>
        </Col>
        <hr />
        <Col className="p-2">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias atque
          impedit officia porro maiores illum quae eveniet, odio ad non expedita
          eius earum reiciendis sequi assumenda optio dignissimos debitis quis
          similique praesentium. Corporis debitis molestiae officiis nesciunt,
          minima temporibus possimus nisi quisquam, in perferendis ipsam, hic ea
          quidem dolorem veniam assumenda aspernatur maiores. Repudiandae
          reiciendis aspernatur omnis nobis adipisci totam.
        </Col>
        <Col className="mt-3">
          <h6>Attachment</h6>
        </Col>
        <Row className="ps-3">
          <Col md={3} style={{ padding: "10px" }}>
            <div className="resorce-parent-item cursor-pointer">
              <div className="name">
                <FaFilePdf /> HC Verma
              </div>
              <MdOutlineFileDownload />
            </div>
          </Col>
        </Row>
        <hr />
        <Col className="p-2">
          <h6>Reply {">"} </h6>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias atque
          impedit officia porro maiores illum quae eveniet.
        </Col>
      </Col> */}

      {complaints &&
        complaints.map((complaint, index) => (
          <Col key={index} className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
            <Row className="p-2 m-0">
              <Col
                md={4}
                className="d-flex align-items-center justify-content-start p-0 m-0"
                style={{ gap: "10px" }}
              >
                {" "}
                <div className="parent-complain-image">
                  <img src={ProfilePic} alt="userImg" />{" "}
                </div>{" "}
                <h5>{complaint?.titile}</h5>{" "}
              </Col>
              <Col
                md={8}
                className="d-flex align-items-center justify-content-end"
                style={{ fontSize: "18px" }}
              >
                Subject: {complaint?.category}
              </Col>
            </Row>
            <Col className="d-flex align-items-center justify-content-between p-2">
              <Col md={6}>
                {moment(complaint?.dateTime).format("DD MMM YYYY")}
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-end"
              >
                {complaint?.status === "open" ? (
                  <Chip
                    label="Open"
                    className="n-box-shadow"
                    style={{
                      color: "black",
                      borderColor: "var(--yellow-color)",
                      background: "var(--yellow-color)",
                    }}
                    color="primary"
                  />
                ) : (
                  <Chip
                    label="Close"
                    className="n-box-shadow"
                    style={{
                      color: "white",
                      borderColor: "yellow",
                      background: "red",
                    }}
                    color="primary"
                  />
                )}
              </Col>
            </Col>
            <hr />
            <Col className="p-2">{complaint?.description}</Col>
            <Col className="mt-3">
              <h6>Attachment</h6>
            </Col>
            <Row>
              <Col
                onClick={() => handleDownloadFile(complaint?.attachmentKey)}
                md={3}
                style={{ padding: "10px" }}
              >
                <div className="resorce-parent-item cursor-pointer">
                  <div className="name">
                    <FaFilePdf /> HC Verma
                  </div>
                  <MdOutlineFileDownload />
                </div>
              </Col>
            </Row>
          </Col>
        ))}

      <ParentFooter />
    </OftadehLayout>
  );
}

export default Complain;
