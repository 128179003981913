// Search
import PinBlogPage from "./PinBlogPage";

export const PinPageConfig = {
  routes: [
    {
      path: "/operation/pin",
      exact: true,
      component: PinBlogPage
    },
  ]
}; 