// Certificate
import CertificatePage from "./CertificatePage";

export const CertificatePageConfig = {
  routes: [
    {
      path: "/operation/certificate",
      exact: true,
      component: CertificatePage
    }
  ]
};
