import React from "react";
import { Row, Col, Container, Form, Modal } from "react-bootstrap";
import { Chart as ChartJS, registerables } from "chart.js";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiSettings5Line,
} from "react-icons/ri";
import CircularProgressBar from "../../screens/Admin/graph/CircularProgressBar";
import EChartsGraph from "../../screens/Admin/graph/EChartsGraph";
import CustomEChartsGraph from "../../screens/Admin/graph/CustomEChartsGraph";
import { useNavigate } from "react-router-dom";
import TaskManagementComponent from "./components/TaskManagementComponent";
import OprationEchart from "../../screens/Admin/graph/OprationEchart";

ChartJS.register(...registerables);
const DashboardPage = (props) => {
  const { history } = props;
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = [
    {
      name: "Sales",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
    },
    {
      name: "Leads",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
    },
    {
      name: "Total Leads",
      type: "line",
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value) {
          return value;
        },
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
    },
  ];

  const dataOne = {
    // Example data for ComponentOne
    dimensions: ["category", "value"],
    source: [
      { category: "A", value: 30 },
      { category: "B", value: 50 },
      { category: "C", value: 70 },
      { category: "D", value: 60 },
      { category: "E", value: 80 },
    ],
  };

  return (
    <OperationLayout>
      <Row>
        <Col>
          <h4>Dashboard</h4>
        </Col>
        <Col className="d-flex justify-content-end">
          <button
            onClick={handleShow}
            className="btn rounded-5"
            style={{
              backgroundColor: "var(--color-primary)",
              color: "#fff",
              border: "none",
            }}
          >
            Manual Lead
          </button>
        </Col>
      </Row>
      <Container className="mt-4">
        <Row>
          <Col
            onClick={() => navigate("/operation/classes")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div className="admin-card-items shadow card-1">
              <span>Active Classes </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#3C5DB5" size={27} />
                  <div className="count">
                    <h4>100+</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={20} color="#3C5DB5" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/classes")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div className="admin-card-items shadow card-2">
              <span>Canceled Classes </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowDownSLine color="#AB2043" size={27} />
                  <div className="count">
                    <h4>07</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={60} color="#AB2043" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/classes")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div className="admin-card-items shadow card-3">
              <span>Hold Classes </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#E6BB4E" size={27} />
                  <div className="count">
                    <h4>10</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={15} color="#E6BB4E" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/Leads")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div className="admin-card-items shadow card-4">
              <span>Active Parents </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#35AB77" size={27} />
                  <div className="count">
                    <h4>34 %</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={34} color="#35AB77" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/Leads")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow"
              style={{
                borderBottom: "3px solid #AB2043",
              }}
            >
              <span>InActive Parents </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#AB2043" size={27} />
                  <div className="count">
                    <h4>45 %</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={45} color="#AB2043" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/Leads")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div className="admin-card-items shadow card-4">
              <span>Active Teacher </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#35AB77" size={27} />
                  <div className="count">
                    <h4>89 %</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={89} color="#35AB77" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/Leads")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow"
              style={{
                borderBottom: "3px solid #AB2043",
              }}
            >
              <span>InActive Teacher </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#AB2043" size={27} />
                  <div className="count">
                    <h4>34 %</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={34} color="#AB2043" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/live-request")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-2"
              style={{ borderColor: "#9043d3" }}
            >
              <span>Teacher Requests </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowDownSLine color="#9043d3" size={27} />
                  <div className="count">
                    <h4>71 %</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={71} color="#9043d3" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/career")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-3"
              style={{ borderColor: "#8e3954" }}
            >
              <span>Career Requests </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#8e3954" size={27} />
                  <div className="count">
                    <h4>145 M</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={72} color="#8e3954" />
                </div>
              </div>
            </div>
          </Col>
          <Col
            onClick={() => navigate("/operation/blog/request")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-1"
              style={{ borderColor: "#4B545E" }}
            >
              <span>Blog Requests </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#4B545E" size={27} />
                  <div className="count">
                    <h4>76+</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={60} color="#4B545E" />
                </div>
              </div>
            </div>
          </Col>

          <Col
            onClick={() => navigate("/operation/blog/request")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-1"
              style={{ borderColor: "#E6BB4E" }}
            >
              <span>No. of Compaints </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#E6BB4E" size={27} />
                  <div className="count">
                    <h4>45+</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={60} color="#E6BB4E" />
                </div>
              </div>
            </div>
          </Col>

          <Col
            onClick={() => navigate("/operation/blog/request")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-1"
              style={{ borderColor: "#686ea8" }}
            >
              <span>No. of Suggetions </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#686ea8" size={27} />
                  <div className="count">
                    <h4>10+</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={60} color="#686ea8" />
                </div>
              </div>
            </div>
          </Col>

          <Col
            onClick={() => navigate("/operation/blog/request")}
            className="p-2 cursor-pointer"
            md={3}
          >
            <div
              className="admin-card-items shadow card-1"
              style={{ borderColor: "#ab9558" }}
            >
              <span>Live Requests </span>
              <div className="flex-admin-card">
                <div className="left">
                  <RiArrowUpSLine color="#ab9558" size={27} />
                  <div className="count">
                    <h4>10+</h4>
                  </div>
                </div>
                <div className="right">
                  <CircularProgressBar percentage={20} color="#ab9558" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col md={7} className="px-3">
            <div className="graph-container shadow rounded-3 py-3 mx-auto">
              <EChartsGraph id="main" data={data} />
            </div>
          </Col>
          <Col
            md={5}
            className="shadow rounded-3 p-0"
            style={{ overflow: "hidden" }}
          >
            <div className="heder-graph-2 mb-4 px-3 d-flex align-items-center justify-content-between">
              <h6>Total Revenue</h6>
              <Form.Group controlId="selectOption">
                <Form.Select className="shadow rounded-5">
                  <option value="1">Weekly</option>
                  <option value="2">Monthly</option>
                  <option value="3">Yearly</option>
                </Form.Select>
              </Form.Group>
            </div>
            {/* <CustomEChartsGraph height={250} data={dataOne} /> */}
            <OprationEchart />
          </Col>
        </Row>
      </Container>

      {/* manual Lead Modal */}

      <Modal show={show} centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Manual Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Name</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent Name"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Email</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="email"
                  placeholder="Enter Parent Email"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Phone</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent Phone"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Address</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent Address"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent City</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent City"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent State</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent State"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Country</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent Country"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="nameInput">
                <Form.Label>Parent Zip Code</Form.Label>
                <Form.Control
                  className="rounded-5"
                  type="text"
                  placeholder="Enter Parent Zip Code"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn rounded-5"
            style={{
              backgroundColor: "var(--color-primary)",
              color: "#fff",
              border: "none",
            }}
            onClick={handleClose}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <TaskManagementComponent />
    </OperationLayout>
  );
};

export default DashboardPage;
