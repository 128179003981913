import DemoPage from "./DemoPage";

export const TeacherDemoPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/demo",
      exact: true,
      component: DemoPage
    }
  ]
};
