import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Chart } from "chart.js";
import styled from "styled-components";

const data = [
  {
    id: 1,
    parentId: "parent#235",
    parentname: "John Doe",
    childId: "child#235",
    childName: "Jane Doe",
    sources: "Facebook",
    refrel: {
      code: "ref#235",
      name: "John Doe",
    },
    coupon: {
      code: "coupon#235",
      name: "Jane Doe",
    },
    phone: "1234567890",
    email: "test@test.com",
    country: {
      code: "IN",
      name: "India",
    },
    classStatus: "On hold",
    activityStatus: "Active",
    webisteStatus: "Active",
  },
  {
    id: 2,
    parentId: "parent#236",
    parentname: "John Doe",
    childId: "child#236",
    childName: "Jane Doe",
    sources: "Facebook",
    refrel: {
      code: "ref#236",
      name: "John Doe",
    },
    coupon: {
      code: "coupon#236",
      name: "Jane Doe",
    },
    phone: "1234567890",
    email: "test@test.com",
    country: {
      code: "IN",
      name: "India",
    },
    classStatus: "Late Join",
    activityStatus: "Active",
    webisteStatus: "InActive",
  },
  {
    id: 3,
    parentId: "parent#236",
    parentname: "John Doe",
    childId: "child#236",
    childName: "Jane Doe",
    sources: "Facebook",
    refrel: {
      code: "ref#236",
      name: "John Doe",
    },
    coupon: {
      code: "coupon#236",
      name: "Jane Doe",
    },
    phone: "1234567890",
    email: "test@test.com",
    country: {
      code: "IN",
      name: "India",
    },
    classStatus: "On Time",
    activityStatus: "InActive",
    webisteStatus: "Blocked",
  },
  {
    id: 4,
    parentId: "parent#236",
    parentname: "John Doe",
    childId: "child#236",
    childName: "Jane Doe",
    sources: "Facebook",
    refrel: {
      code: "ref#236",
      name: "John Doe",
    },
    coupon: {
      code: "coupon#236",
      name: "Jane Doe",
    },
    phone: "1234567890",
    email: "test@test.com",
    country: {
      code: "IN",
      name: "India",
    },
    classStatus: "Late Join",
    activityStatus: "Active",
    webisteStatus: "Deleted",
  },
];

const LeadManagmentPage = (props) => {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");

  const handleAddLead = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const createChart = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Maths I",
            "Maths II",
            "Maths III",
            "Maths IV",
            "Maths V",
            "Maths VI",
          ],
          datasets: [
            {
              label: "Number of Students",
              data: [50, 40, 90, 70, 30, 110],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const createChart2 = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Number of Leads",
              data: [50, 40, 90, 70, 30, 110, 50, 40, 90, 70, 30, 110],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const createChart3 = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Total Classes",
            "Active Classes",
            "Hold Classes",
            "Cancelled Classes",
          ],
          datasets: [
            {
              label: "Number of Classes",
              data: [50, 40, 90, 70],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const createChart4 = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Total Students", "Enrolled", "Retained", "Loss"],
          datasets: [
            {
              label: "Number of Students",
              data: [50, 40, 90, 70],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const createChart5 = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Total Classes", "On Time", "Late", "Reschedule", "No Show"],
          datasets: [
            {
              label: "Number of Attendance",
              data: [50, 40, 90, 70, 60],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const createChart6 = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Total Students",
            "On Time",
            "Late",
            "Reschedule",
            "No Show",
          ],
          datasets: [
            {
              label: "Number of Attendance",
              data: [50, 40, 90, 70, 60],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const chart1 = createChart("studentCourceGraph1");
    const chart2 = createChart2("studentCourceGraph2");
    const chart3 = createChart3("studentCourceGraph3");
    const chart4 = createChart4("studentCourceGraph4");
    const chart5 = createChart5("studentCourceGraph5");
    const chart6 = createChart6("studentCourceGraph6");

    return () => {
      chart1.destroy();
      chart2.destroy();
      chart3.destroy();
      chart4.destroy();
      chart5.destroy();
      chart6.destroy();
    };
  }, []);

  const handleSelectChange = (e) => {
    setCurrentTab(e.target.value);
    console.log(currentTab);
  };

  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={3}>
          <Form.Group controlId="selectOption">
            <Form.Select
              className="shadow rounded-5 mt-3"
              onChange={handleSelectChange}
              value={currentTab}
            >
              <option selected disabled>
                Sources
              </option>
              <option value="1">By Leads</option>
              <option value="2">Social Leads</option>
              <option value="3">Coupon Leads</option>
              <option value="4">Refral Leads</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
        <Col
          md={6}
          className="mt-3 d-flex align-content-center justify-content-end"
        >
          <button onClick={handleAddLead} className="new-button">
            Add Manual Lead
          </button>
        </Col>
      </Row>
      {currentTab === "1" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>By Leads</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              minWidth: 1900,
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Parent ID</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child ID</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Source</th>
                <th className="text-white">Referral</th>
                <th className="text-white">Coupon</th>
                <th className="text-white">Phone</th>
                <th className="text-white">Email</th>
                <th className="text-white">Country</th>
                <th className="text-white">Class Status</th>
                <th className="text-white">Activity Status</th>
                <th className="text-white">Website Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-light text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentname}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.sources}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.refrel.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.refrel.code}
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.coupon.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.coupon.code}
                    </div>
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.country.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.country.code}
                    </div>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.classStatus === "On hold"
                          ? "danger"
                          : item.classStatus === "Late Join"
                          ? "warning"
                          : "success"
                      }`}
                    >
                      {item.classStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.activityStatus === "Active" ? "success" : "warning"
                      }`}
                    >
                      {item.activityStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.webisteStatus === "Active"
                          ? "success"
                          : item.webisteStatus === "Deleted"
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {item.webisteStatus}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : currentTab === "2" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Social Leads</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              minWidth: 1900,
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Parent ID</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child ID</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Source</th>
                <th className="text-white">Referral</th>
                <th className="text-white">Coupon</th>
                <th className="text-white">Phone</th>
                <th className="text-white">Email</th>
                <th className="text-white">Country</th>
                <th className="text-white">Class Status</th>
                <th className="text-white">Activity Status</th>
                <th className="text-white">Website Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-light text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentname}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.sources}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.refrel.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.refrel.code}
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.coupon.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.coupon.code}
                    </div>
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.country.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.country.code}
                    </div>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.classStatus === "On hold"
                          ? "danger"
                          : item.classStatus === "Late Join"
                          ? "warning"
                          : "success"
                      }`}
                    >
                      {item.classStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.activityStatus === "Active" ? "success" : "warning"
                      }`}
                    >
                      {item.activityStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.webisteStatus === "Active"
                          ? "success"
                          : item.webisteStatus === "Deleted"
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {item.webisteStatus}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : currentTab === "3" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Coupon Leads</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              minWidth: 1900,
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Parent ID</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child ID</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Source</th>
                <th className="text-white">Referral</th>
                <th className="text-white">Coupon</th>
                <th className="text-white">Phone</th>
                <th className="text-white">Email</th>
                <th className="text-white">Country</th>
                <th className="text-white">Class Status</th>
                <th className="text-white">Activity Status</th>
                <th className="text-white">Website Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-light text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentname}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.sources}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.refrel.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.refrel.code}
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.coupon.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.coupon.code}
                    </div>
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.country.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.country.code}
                    </div>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.classStatus === "On hold"
                          ? "danger"
                          : item.classStatus === "Late Join"
                          ? "warning"
                          : "success"
                      }`}
                    >
                      {item.classStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.activityStatus === "Active" ? "success" : "warning"
                      }`}
                    >
                      {item.activityStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.webisteStatus === "Active"
                          ? "success"
                          : item.webisteStatus === "Deleted"
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {item.webisteStatus}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Refrrel Leads</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              minWidth: 1900,
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Parent ID</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child ID</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Source</th>
                <th className="text-white">Referral</th>
                <th className="text-white">Coupon</th>
                <th className="text-white">Phone</th>
                <th className="text-white">Email</th>
                <th className="text-white">Country</th>
                <th className="text-white">Class Status</th>
                <th className="text-white">Activity Status</th>
                <th className="text-white">Website Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="bg-light text-center" key={index}>
                  <td>{index + 1}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentname}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.sources}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.refrel.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.refrel.code}
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.coupon.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.coupon.code}
                    </div>
                  </td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Name : </span>{" "}
                      {item.country.name}
                    </div>
                    <br />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <span className="fw-bold">Code : </span>{" "}
                      {item.country.code}
                    </div>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.classStatus === "On hold"
                          ? "danger"
                          : item.classStatus === "Late Join"
                          ? "warning"
                          : "success"
                      }`}
                    >
                      {item.classStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.activityStatus === "Active" ? "success" : "warning"
                      }`}
                    >
                      {item.activityStatus}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      bg={`${
                        item.webisteStatus === "Active"
                          ? "success"
                          : item.webisteStatus === "Deleted"
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {item.webisteStatus}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {/* Modal for Adding Leads */}
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ marginTop: "10vh", zIndex: 142512001 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="leadName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter lead name" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="leadDateTime">
                <Form.Label>Date/Time</Form.Label>
                <Form.Control type="datetime-local" />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="leadName">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="number" placeholder="Enter Phone Number" />
                {/* checkbox for sending the ref link */}
                <Form.Check
                  type="checkbox"
                  id="sendRefLink"
                  className="mt-2"
                  label="Send Referral Link"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="leadDateTime">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" />

                <Form.Check
                  type="checkbox"
                  id="sendRefLink"
                  className="mt-2"
                  label="Send Referral Link"
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-3">
              <Form.Group controlId="leadDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter lead description"
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-3">
              <Form.Group controlId="leadDescription">
                <Form.Label>Select your name to share link</Form.Label>
                <Form.Select>
                  <option selected disabled>
                    Select Name
                  </option>
                  <option value={"1"}>John Doe</option>
                  <option value={"2"}>Jane Doe</option>
                  <option value={"3"}>John Smith</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="leadSource">
                <Form.Label>Lead From</Form.Label>
                <Form.Control as="select">
                  <option>Facebook</option>
                  <option>Instagram</option>
                  <option>Google</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="leadStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control as="select">
                  <option>Active</option>
                  <option>Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              /* Handle adding leads */
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <StyledContainer>
        <Row className="mt-4">
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Revenue Generation</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>
              <hr />
              <div className="d-flex w-100 align-items-center gap-5">
                <div>
                  <h2 className="m-0 mb-1">40%</h2>
                  <p className="m-0">Growth</p>
                </div>
                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                <div>
                  <h2 className="m-0 mb-1">12%</h2>
                  <p className="m-0">Loss</p>
                </div>
                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
              </div>
              <hr />
              <canvas id="studentCourceGraph1"></canvas>
            </div>
          </Col>
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Lead Generation</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Total Leads</button>
                  <button className="btns">Converted</button>
                  <button className="btns">Loss</button>
                </div>
              </div>
              <hr />
              <div className="d-flex w-100 align-items-center gap-5">
                <div>
                  <h2 className="m-0 mb-1">40%</h2>
                  <p className="m-0">Growth</p>
                </div>
                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                <div>
                  <h2 className="m-0 mb-1">12%</h2>
                  <p className="m-0">Loss</p>
                </div>
                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
              </div>
              <hr />
              <canvas id="studentCourceGraph2"></canvas>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center mb-4 justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Classes</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>

              <canvas id="studentCourceGraph3"></canvas>
            </div>
          </Col>
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center mb-4 justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Students</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>

              <canvas id="studentCourceGraph4"></canvas>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center mb-4 justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Teacher Attendance </h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>

              <canvas id="studentCourceGraph5"></canvas>
            </div>
          </Col>
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center mb-4 justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Student Attendance</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>

              <canvas id="studentCourceGraph6"></canvas>
            </div>
          </Col>
        </Row>
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .card {
    transition: all 0.3s ease-in-out;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      background: var(--color-primary);
      transform: translate(-6px, -6px);
    }
  }

  .btns {
    background: var(--color-primary);
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    color: #ffffff;
  }

  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 200px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }
`;

export default LeadManagmentPage;
