import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { FaRegFilePdf } from "react-icons/fa";
import { TiArrowForward } from "react-icons/ti";
import PieChart from "./PieChart";
// import Graph from "./Graph";
import { MdOutlineTableChart } from "react-icons/md";
import { FaChartColumn, FaEye } from "react-icons/fa6";
import TeacherGraph from "./TeacherGraph";
import FinanceGraph from "./FinanceGraph";
import BestTeacher from "./BestTeacher";
import { useNavigate } from "react-router-dom";
import ParentPaymentGraph from "./graphs/ParentPaymentGraph";
import IncomeBreakdownGraph from "./graphs/IncomeBreakdownGraph";
import MarketingBarGraph from "./graphs/MarketingBarGraph";

const TecherData = [
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
  {
    teacherId: "TEA#857",
    teacherName: "John Doe",
    subject: ["Maths", "Science"],
    grade: "10th",
    board: "CBSE",
    teacherRating: 4.5,
    noOfCourse: 5,
    hoursOfTeaching: 100,
    noOfStudents: 50,
    joiningDate: "12 January 2012 10:30 AM",
  },
];

const clientData = [
  {
    childId: "CHI234",
    parentId: "PAR2334",
    childName: "John Deo",
    parentName: "Chris Deo",
    grade: "10th",
    board: "CBSE",
    noOfClassEn: 6,
    status: "Active",
    dateOfJoining: "12 jan 2023",
  },
  {
    childId: "CHI234",
    parentId: "PAR2334",
    childName: "John Deo",
    parentName: "Chris Deo",
    grade: "10th",
    board: "CBSE",
    noOfClassEn: 6,
    status: "Active",
    dateOfJoining: "12 jan 2023",
  },
  {
    childId: "CHI234",
    parentId: "PAR2334",
    childName: "John Deo",
    parentName: "Chris Deo",
    grade: "10th",
    board: "CBSE",
    noOfClassEn: 6,
    status: "Active",
    dateOfJoining: "12 jan 2023",
  },
  {
    childId: "CHI234",
    parentId: "PAR2334",
    childName: "John Deo",
    parentName: "Chris Deo",
    grade: "10th",
    board: "CBSE",
    noOfClassEn: 6,
    status: "Active",
    dateOfJoining: "12 jan 2023",
  },
  {
    childId: "CHI234",
    parentId: "PAR2334",
    childName: "John Deo",
    parentName: "Chris Deo",
    grade: "10th",
    board: "CBSE",
    noOfClassEn: 6,
    status: "Active",
    dateOfJoining: "12 jan 2023",
  },
];

const ReportPage = (props) => {
  const { history } = props;
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState("1");
  const [currentSubTab, setCurrentSubTab] = useState("1");
  const [subTab, setSubTab] = useState("1");

  const legendData = [
    "Last 1 Day",
    "Last 2 Day",
    "Last 3 Day",
    "Last 4 Day",
    "Last 5 Day",
    "Last 6 Day",
    "Last 7 Day",
    "Last 8 Day",
  ];

  const seriesData = [
    {
      name: "Course Income",
      type: "pie",
      radius: [20, 140],
      center: ["25%", "50%"],
      roseType: "radius",
      itemStyle: {
        borderRadius: 5,
      },
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: true,
        },
      },
      data: [
        { value: 40, name: "Last 1 day" },
        { value: 33, name: "Last 2 day" },
        { value: 28, name: "Last 3 day" },
        { value: 22, name: "Last 4 day" },
        { value: 20, name: "Last 5 day" },
        { value: 15, name: "Last 6 day" },
        { value: 12, name: "Last 7 day" },
        { value: 10, name: "Last 8 day" },
      ],
    },
    {
      name: "Course Income",
      type: "pie",
      radius: [20, 140],
      center: ["75%", "50%"],
      roseType: "area",
      itemStyle: {
        borderRadius: 5,
      },
      data: [
        { value: 30, name: "Last 1 day" },
        { value: 28, name: "Last 2 day" },
        { value: 26, name: "Last 3 day" },
        { value: 24, name: "Last 4 day" },
        { value: 22, name: "Last 5 day" },
        { value: 20, name: "Last 6 day" },
        { value: 18, name: "Last 7 day" },
        { value: 16, name: "Last 8 day" },
      ],
    },
  ];

  const xAxisData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const seriesData1 = [
    {
      name: "Evaporation",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value + " ml";
        },
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
    },
    {
      name: "Precipitation",
      type: "bar",
      tooltip: {
        valueFormatter: function (value) {
          return value + " ml";
        },
      },
      data: [
        2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
      ],
    },
    {
      name: "Temperature",
      type: "line",
      yAxisIndex: 1,
      tooltip: {
        valueFormatter: function (value) {
          return value + " °C";
        },
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
    },
  ];

  const teacherReportData = [
    ["score", "demos", "product"],
    [89.3, 58212, "John Smith"],
    [57.1, 78254, "Emily Richardson"],
    [74.4, 41032, "Michael Johnson"],
    [50.1, 12755, "Sarah Thompson"],
    [89.7, 20145, "David Martinez"],
    [68.1, 79146, "Olivia Walker"],
    [19.6, 91852, "James Anderson"],
    [10.6, 101852, "Sophia Harris"],
    [32.7, 20112, "William Brown"],
  ];

  const clientReportData = [
    ["score", "Activity", "product"],
    [89.3, 58212, "Register"],
    [57.1, 78254, "Referral"],
    [74.4, 41032, "Joined"],
    [50.1, 12755, "Sarah Thompson"],
    [89.7, 20145, "David Martinez"],
    [68.1, 79146, "Olivia Walker"],
    [19.6, 91852, "James Anderson"],
    [10.6, 101852, "Sophia Harris"],
    [32.7, 20112, "William Brown"],
    [78.9, 33422, "Login"],
    [45.3, 76431, "Purchase"],
    [82.5, 44213, "Feedback"],
    [63.2, 98234, "Browse"],
    [51.8, 54122, "Logout"],
    [74.6, 87652, "Register"],
    [67.4, 31312, "Update Profile"],
    [22.5, 70125, "Delete Account"],
    [88.9, 45023, "Subscribe"],
    [29.3, 60121, "Unsubscribe"],
  ];

  const title = "Financial Report";
  const legend1Data = ["Target", "Revenue"];
  const yAxisData = [
    "Last 5 Day",
    "Last 4 Day",
    "Last 3 Day",
    "Last 2 Day",
    "Last 1 Day",
  ];
  const series1Data = [
    [
      { value: 157, symbolKey: "Last 5 Day" },
      { value: 21, symbolKey: "Last 4 Day" },
      { value: 66, symbolKey: "Last 3 Day" },
      { value: 78, symbolKey: "Last 2 Day" },
      { value: 123, symbolKey: "Last 1 Day" },
    ],
    [
      { value: 184 },
      { value: 29 },
      { value: 73 },
      { value: 91 },
      { value: 95 },
    ],
  ];

  const yAxisDataBestTeacher = [
    "Teacher A",
    "Teacher B",
    "Teacher C",
    "Teacher D",
    "Teacher E",
  ];
  const initialDataBestTeacher = Array.from({ length: 5 }, () =>
    Math.round(Math.random() * 200)
  );

  const incomeData = [
    { value: 1548, name: "Parent Payments" },
    { value: 735, name: "Leanne Mall Payments" },
    { value: 434, name: "Company Total Income" },
  ];

  const incomeData2 = [
    { value: 1548, name: "Lead Convertion" },
    { value: 735, name: "Total Leads" },
    { value: 434, name: "Top Performer" },
  ];

  return (
    <OperationLayout>
      <h5>Report</h5>

      <div className="d-flex align-items-center justify-content-start gap-2 overflow-x-auto mt-3 mb-3">
        <div
          onClick={() => setCurrentTab("1")}
          style={{
            backgroundColor: currentTab === "1" ? "var(--color-primary)" : "",
            color: currentTab === "1" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Teacher Report
        </div>
        <div
          onClick={() => setCurrentTab("2")}
          style={{
            backgroundColor: currentTab === "2" ? "var(--color-primary)" : "",
            color: currentTab === "2" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Client Report
        </div>
        <div
          onClick={() => setCurrentTab("3")}
          style={{
            backgroundColor: currentTab === "3" ? "var(--color-primary)" : "",
            color: currentTab === "3" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Financial Report
        </div>
        <div
          onClick={() => setCurrentTab("4")}
          style={{
            backgroundColor: currentTab === "4" ? "var(--color-primary)" : "",
            color: currentTab === "4" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Best Teacher Report
        </div>
        <div
          onClick={() => setCurrentTab("5")}
          style={{
            backgroundColor: currentTab === "5" ? "var(--color-primary)" : "",
            color: currentTab === "5" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Account Report
        </div>
        <div
          onClick={() => setCurrentTab("6")}
          style={{
            backgroundColor: currentTab === "6" ? "var(--color-primary)" : "",
            color: currentTab === "6" ? "#fff" : "#000",
          }}
          className="px-3 py-1 border rounded-5 cursor-pointer"
        >
          Marketing Report
        </div>
      </div>

      {currentTab === "5" && (
        <div className="d-flex align-items-center justify-content-start gap-2 overflow-x-auto mt-3 mb-3">
          <div
            onClick={() => setSubTab("1")}
            style={{
              backgroundColor: subTab === "1" ? "var(--color-primary)" : "",
              color: subTab === "1" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            Parent
          </div>
          <div
            onClick={() => setSubTab("2")}
            style={{
              backgroundColor: subTab === "2" ? "var(--color-primary)" : "",
              color: subTab === "2" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            Teacher
          </div>
          <div
            onClick={() => setSubTab("3")}
            style={{
              backgroundColor: subTab === "3" ? "var(--color-primary)" : "",
              color: subTab === "3" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            Employee Salary
          </div>
          <div
            onClick={() => setSubTab("4")}
            style={{
              backgroundColor: subTab === "4" ? "var(--color-primary)" : "",
              color: subTab === "4" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            App Payment
          </div>
          <div
            onClick={() => setSubTab("5")}
            style={{
              backgroundColor: subTab === "5" ? "var(--color-primary)" : "",
              color: subTab === "5" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            Income Breakdown
          </div>
          <div
            onClick={() => setSubTab("6")}
            style={{
              backgroundColor: subTab === "6" ? "var(--color-primary)" : "",
              color: subTab === "6" ? "#fff" : "#000",
            }}
            className="px-3 py-1 border rounded-5 cursor-pointer"
          >
            Expense Breakdown
          </div>
        </div>
      )}

      <Row className="justify-content-between">
        <Col md={4} className="ps-3 mt-3">
          <Button
            variant=""
            onClick={() => setCurrentSubTab("1")}
            style={{
              backgroundColor: currentSubTab === "1" && "var(--color-primary)",
              color: currentSubTab === "1" && "#fff",
            }}
            size="sm"
          >
            <MdOutlineTableChart size={22} />
          </Button>
          <Button
            onClick={() => setCurrentSubTab("2")}
            className="ms-2"
            style={{
              backgroundColor: currentSubTab === "2" && "var(--color-primary)",
              color: currentSubTab === "2" && "#fff",
            }}
            variant=""
            size="sm"
          >
            <FaChartColumn size={22} />
          </Button>
        </Col>
        {currentTab === "3" && (
          <>
            <Col md={4} className="mt-3">
              <Form.Group controlId="timeRangeSelect">
                <Form.Select className="shadow rounded-5">
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </>
        )}
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      {currentTab === "1" && (
        <>
          {currentSubTab === "1" && (
            <>
              <Table
                className="mt-3 text-center"
                responsive
                striped
                style={{
                  borderRadius: "25px",
                  backgroundColor: "var(--color-primary)",
                }}
              >
                <thead>
                  <tr>
                    <th className="text-white">Teacher Id</th>
                    <th className="text-white">Teacher Name</th>
                    <th className="text-white">Subject</th>
                    <th className="text-white">Grade</th>
                    <th className="text-white">Board</th>
                    <th className="text-white">Teacher Rating</th>
                    <th className="text-white">No. of Course</th>
                    <th className="text-white">Hours of Teaching</th>
                    <th className="text-white">No. of Students</th>
                    <th className="text-white">Joining Date</th>
                    <th className="text-white" colSpan={3}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {TecherData.map((data, index) => (
                    <tr className="bg-light" key={index}>
                      <td>{data.teacherId}</td>
                      <td>{data.teacherName}</td>
                      <td>{data.subject.join(", ")}</td>
                      <td>{data.grade}</td>
                      <td>{data.board}</td>
                      <td>{data.teacherRating}</td>
                      <td>{data.noOfCourse}</td>
                      <td>{data.hoursOfTeaching}</td>
                      <td>
                        <p
                          className="text-decoration-underline text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          {data.noOfStudents}
                        </p>
                      </td>
                      <td>{data.joiningDate}</td>
                      <td>
                        <IconButton aria-label="delete">
                          <FaRegFilePdf color="blue" />
                        </IconButton>
                      </td>
                      <td>
                        <IconButton aria-label="delete">
                          <FaEye color="blue" />
                        </IconButton>
                      </td>
                      <td>
                        <IconButton aria-label="delete">
                          <TiArrowForward color="blue" />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {currentSubTab === "2" && (
            <>
              <Container className="shadow border border-2 rounded-3 pb-4 mt-3">
                <TeacherGraph
                  data={teacherReportData}
                  name={"demos"}
                  height="400px"
                  key={"Hello"}
                />
              </Container>
            </>
          )}
        </>
      )}

      {currentTab === "2" && (
        <>
          {currentSubTab === "1" && (
            <>
              <Table
                className="mt-3 text-center"
                responsive
                striped
                style={{
                  backgroundColor: "var(--color-primary)",
                }}
              >
                <thead>
                  <tr>
                    <th className="text-white">Child Id</th>
                    <th className="text-white">Parent ID</th>
                    <th className="text-white">Child Name</th>
                    <th className="text-white">Parent Name</th>
                    <th className="text-white">Grade</th>
                    <th className="text-white">Board</th>
                    <th className="text-white">No. of Class En.</th>
                    <th className="text-white">Status</th>
                    <th className="text-white">Date of Joining</th>
                    <th className="text-white">Child Report</th>
                    <th className="text-white" colSpan={2}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientData.map((data, index) => (
                    <tr className="bg-light" key={index}>
                      <td>{data.childId}</td>
                      <td>{data.parentId}</td>
                      <td>{data.childName}</td>
                      <td>{data.parentName}</td>
                      <td>{data.grade}</td>
                      <td>{data.board}</td>
                      <td>{data.noOfClassEn}</td>
                      <td>{data.status}</td>
                      <td>{data.dateOfJoining}</td>
                      <td>
                        <button className="btn btn-sm btn-primary">View</button>
                      </td>

                      <td>
                        <IconButton aria-label="delete">
                          <FaRegFilePdf color="blue" />
                        </IconButton>
                      </td>
                      <td>
                        <IconButton aria-label="delete">
                          <TiArrowForward color="blue" />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {currentSubTab === "2" && (
            <>
              <Container className="shadow border border-2 rounded-3 pb-4 mt-3">
                <TeacherGraph
                  data={clientReportData}
                  name={"Clients"}
                  height="550px"
                  key={"Hello"}
                />
              </Container>
            </>
          )}
        </>
      )}

      {currentTab === "3" && (
        <>
          {currentSubTab === "1" && (
            <>
              <Table
                className="mt-3 text-center"
                responsive
                striped
                style={{
                  borderRadius: "25px",
                  backgroundColor: "var(--color-primary)",
                }}
              >
                <thead>
                  <tr>
                    <th className="text-white">Unique Id</th>
                    <th className="text-white">net Profit</th>
                    <th className="text-white">progress Profit</th>
                    <th className="text-white">revenue</th>
                    <th className="text-white">target</th>
                    <th className="text-white">customer</th>
                    <th className="text-white">Report Type</th>
                    <th className="text-white">Created At</th>
                    <th className="text-white" colSpan={2}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <td>uhydiauis#-0001</td>
                    <td>20,000</td>
                    <td>20%</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>40</td>
                    <td>
                      <Form.Group controlId="selectOption">
                        <Form.Select className="bg-transparent border-0">
                          <option value="option1">Daily</option>
                          <option value="option3">Weekly</option>
                          <option value="option2">Monthly</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

          {currentSubTab === "2" && (
            <>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <FinanceGraph
                  title={title}
                  legendData={legend1Data}
                  yAxisData={yAxisData}
                  seriesData={series1Data}
                />
              </Container>
              <Container
                fluid
                className="px-4 shadow rounded-3 pb-3 py-4 border border-2 mt-3"
              >
                <PieChart
                  title="Total Income"
                  subtext="last 8 days"
                  legendData={legendData}
                  seriesData={seriesData}
                />
              </Container>
            </>
          )}
        </>
      )}

      {currentTab === "4" && (
        <>
          <Row className="mt-3 mb-3">
            <h6>Best Teacher Report</h6>
          </Row>
          {currentSubTab === "1" && (
            <>
              <Table
                className="mt-3 text-center"
                responsive
                striped
                style={{
                  borderRadius: "25px",
                  backgroundColor: "var(--color-primary)",
                }}
              >
                <thead>
                  <tr>
                    <th className="text-white">Teacher Id</th>
                    <th className="text-white">Name</th>
                    <th className="text-white">Class</th>
                    <th className="text-white">Board</th>
                    <th className="text-white">Status</th>
                    <th className="text-white">activity</th>
                    <th className="text-white">Created At</th>
                    <th className="text-white" colSpan={2}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-light">
                    <td>teacher#-0001</td>
                    <td>Joh Smith</td>
                    <td>10th</td>
                    <td>CBSE</td>
                    <td>active</td>
                    <td>Joined</td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>
                      <IconButton aria-label="delete">
                        <FaRegFilePdf color="blue" />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton aria-label="delete">
                        <TiArrowForward color="blue" />
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          {currentSubTab === "2" && (
            <>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <BestTeacher
                  xAxisMax="dataMax"
                  yAxisData={yAxisDataBestTeacher}
                  initialData={initialDataBestTeacher}
                  name="Top 5 Best Teachers"
                />
              </Container>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <BestTeacher
                  xAxisMax="dataMax"
                  yAxisData={yAxisDataBestTeacher}
                  initialData={initialDataBestTeacher}
                  name="Lowest 5 Teachers"
                />
              </Container>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <BestTeacher
                  xAxisMax="dataMax"
                  yAxisData={yAxisDataBestTeacher}
                  initialData={initialDataBestTeacher}
                  name="courses highest paying parents"
                />
              </Container>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <BestTeacher
                  xAxisMax="dataMax"
                  yAxisData={yAxisDataBestTeacher}
                  initialData={initialDataBestTeacher}
                  name="lowest courses"
                />
              </Container>
            </>
          )}
        </>
      )}

      {currentTab === "5" && (
        <>
          {subTab === "1" && (
            <>
              <Row className="mt-3 mb-3">
                <h6>Parent Payment</h6>
              </Row>
              {currentSubTab === "1" && (
                <>
                  <Table
                    className="mt-3 text-center custom-table"
                    responsive
                    striped
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "var(--color-primary)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="text-white">Transaction Id</th>
                        <th className="text-white">Class Id</th>
                        <th className="text-white">Parent Id</th>
                        <th className="text-white">Parent Name</th>
                        <th className="text-white">Course Name</th>
                        <th className="text-white">Teacher Name</th>
                        <th className="text-white">Child Name</th>
                        <th className="text-white">Payment Date</th>
                        <th className="text-white">Cycle</th>
                        <th className="text-white">Total Class</th>
                        <th className="text-white">Total Amount</th>
                        <th className="text-white">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <td>transaction#-0001</td>
                        <td>class#-0001</td>
                        <td>parent#-0001</td>
                        <td>John Smith</td>
                        <td>Course A</td>
                        <td>Teacher A</td>
                        <td>Child A</td>
                        <td>12 January 2012 10:30 AM</td>
                        <td>Monthly</td>
                        <td>10</td>
                        <td>$100</td>
                        <td>
                          <a href="#invoice1">Invoice Link</a>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <td>transaction#-0002</td>
                        <td>class#-0002</td>
                        <td>parent#-0002</td>
                        <td>Jane Doe</td>
                        <td>Course B</td>
                        <td>Teacher B</td>
                        <td>Child B</td>
                        <td>15 February 2013 11:00 AM</td>
                        <td>Quarterly</td>
                        <td>15</td>
                        <td>$150</td>
                        <td>
                          <a href="#invoice2">Invoice Link</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {currentSubTab === "2" && (
                <>
                  <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                    <ParentPaymentGraph />
                  </Container>
                </>
              )}
            </>
          )}

          {subTab === "2" && (
            <>
              <Row className="mt-3 mb-3">
                <h6>Teacher Payment</h6>
              </Row>
              {currentSubTab === "1" && (
                <>
                  <Table
                    className="mt-3 text-center custom-table"
                    responsive
                    striped
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "var(--color-primary)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="text-white">Teacher Id</th>
                        <th className="text-white">Teacher Name</th>
                        <th className="text-white">No Classes</th>
                        <th className="text-white">Active Students</th>
                        <th className="text-white">Enrolled Students</th>
                        <th className="text-white">Total Amount</th>
                        <th className="text-white">Amount Credited</th>
                        <th className="text-white">Amount Withdraw</th>
                        <th className="text-white">Download Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <td>teacher#-0001</td>
                        <td>John Smith</td>
                        <td>10</td>
                        <td>20</td>
                        <td>30</td>
                        <td>$500</td>
                        <td>$300</td>
                        <td>$200</td>
                        <td>
                          <a href="#invoice1">Download</a>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <td>teacher#-0002</td>
                        <td>Jane Doe</td>
                        <td>15</td>
                        <td>25</td>
                        <td>35</td>
                        <td>$700</td>
                        <td>$400</td>
                        <td>$300</td>
                        <td>
                          <a href="#invoice2">Download</a>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <td>teacher#-0003</td>
                        <td>Michael Johnson</td>
                        <td>8</td>
                        <td>18</td>
                        <td>28</td>
                        <td>$400</td>
                        <td>$250</td>
                        <td>$150</td>
                        <td>
                          <a href="#invoice3">Download</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {currentSubTab === "2" && (
                <>
                  <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                    <ParentPaymentGraph isTeacher />
                  </Container>
                </>
              )}
            </>
          )}

          {subTab === "3" && (
            <>
              <Row className="mt-3 mb-3">
                <h6>Employee Salary</h6>
              </Row>
              {currentSubTab === "1" && (
                <>
                  <Table
                    className="mt-3 text-center custom-table"
                    responsive
                    striped
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "var(--color-primary)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="text-white">Employee ID</th>
                        <th className="text-white">Employee Name</th>
                        <th className="text-white">Designation</th>
                        <th className="text-white">Role</th>
                        <th className="text-white">Total Days</th>
                        <th className="text-white">Full Days</th>
                        <th className="text-white">Half Days</th>
                        <th className="text-white">Leaves</th>
                        <th className="text-white">Total Attendance</th>
                        <th className="text-white">Total Salary</th>
                        <th className="text-white">Payout Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <td>emp#001</td>
                        <td>John Doe</td>
                        <td>Senior Developer</td>
                        <td>Operation Person</td>
                        <td>30</td>
                        <td>20</td>
                        <td>5</td>
                        <td>5</td>
                        <td>25</td>
                        <td>$5000</td>
                        <td>2024-07-31</td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {currentSubTab === "2" && (
                <>
                  <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                    <ParentPaymentGraph />
                  </Container>
                </>
              )}
            </>
          )}

          {subTab === "4" && (
            <>
              <Row className="mt-3 mb-3">
                <h6>App Payment</h6>
              </Row>
              {currentSubTab === "1" && (
                <>
                  <Table
                    className="mt-3 text-center custom-table"
                    responsive
                    striped
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "var(--color-primary)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="text-white">Transaction ID</th>
                        <th className="text-white">App Name</th>
                        <th className="text-white">Purpose</th>
                        <th className="text-white">Payment Date</th>
                        <th className="text-white">Due Date</th>
                        <th className="text-white">Total Amount</th>
                        <th className="text-white">Amount Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <td>trans#001</td>
                        <td>My App</td>
                        <td>Subscription Renewal</td>
                        <td>2024-07-15</td>
                        <td>2024-08-15</td>
                        <td>$50.00</td>
                        <td>$50.00</td>
                      </tr>
                      <tr className="bg-light">
                        <td>trans#001</td>
                        <td>My App</td>
                        <td>Subscription Renewal</td>
                        <td>2024-07-15</td>
                        <td>2024-08-15</td>
                        <td>$50.00</td>
                        <td>$50.00</td>
                      </tr>
                      <tr className="bg-light">
                        <td>trans#001</td>
                        <td>My App</td>
                        <td>Subscription Renewal</td>
                        <td>2024-07-15</td>
                        <td>2024-08-15</td>
                        <td>$50.00</td>
                        <td>$50.00</td>
                      </tr>
                      <tr className="bg-light">
                        <td>trans#001</td>
                        <td>My App</td>
                        <td>Subscription Renewal</td>
                        <td>2024-07-15</td>
                        <td>2024-08-15</td>
                        <td>$50.00</td>
                        <td>$50.00</td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {currentSubTab === "2" && (
                <>
                  <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                    <ParentPaymentGraph />
                  </Container>
                </>
              )}
            </>
          )}

          {subTab === "5" && (
            <>
              <Row className="mt-3 mb-3">
                <h6>Parent Payment</h6>
              </Row>
              {currentSubTab === "1" && (
                <>
                  <Table
                    className="mt-3 text-center custom-table"
                    responsive
                    striped
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "var(--color-primary)",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="text-white">Teacher Id</th>
                        <th className="text-white">Teacher Name</th>
                        <th className="text-white">No Classes</th>
                        <th className="text-white">Active Students</th>
                        <th className="text-white">Enrolled Students</th>
                        <th className="text-white">Total Amount</th>
                        <th className="text-white">Amount Credited</th>
                        <th className="text-white">Amount Withdraw</th>
                        <th className="text-white">Download Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-light">
                        <td>teacher#-0001</td>
                        <td>John Smith</td>
                        <td>10</td>
                        <td>20</td>
                        <td>30</td>
                        <td>$500</td>
                        <td>$300</td>
                        <td>$200</td>
                        <td>
                          <a href="#invoice1">Download</a>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <td>teacher#-0002</td>
                        <td>Jane Doe</td>
                        <td>15</td>
                        <td>25</td>
                        <td>35</td>
                        <td>$700</td>
                        <td>$400</td>
                        <td>$300</td>
                        <td>
                          <a href="#invoice2">Download</a>
                        </td>
                      </tr>
                      <tr className="bg-light">
                        <td>teacher#-0003</td>
                        <td>Michael Johnson</td>
                        <td>8</td>
                        <td>18</td>
                        <td>28</td>
                        <td>$400</td>
                        <td>$250</td>
                        <td>$150</td>
                        <td>
                          <a href="#invoice3">Download</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
              {currentSubTab === "2" && (
                <>
                  <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                    <IncomeBreakdownGraph
                      title="Income Breakdown"
                      data={incomeData}
                    />
                  </Container>
                </>
              )}
            </>
          )}
        </>
      )}

      {currentTab === "6" && (
        <>
          <Row className="mt-3 mb-3">
            <h6>Best Marketing Report</h6>
          </Row>
          {currentSubTab === "1" && (
            <>
              <Table
                className="mt-3 text-center"
                responsive
                striped
                style={{
                  borderRadius: "25px",
                  backgroundColor: "var(--color-primary)",
                  minWidth: 1600,
                }}
              >
                <thead>
                  <tr>
                    <th className="text-white">Type</th>
                    <th className="text-white">ID</th>
                    <th className="text-white">Name</th>
                    <th className="text-white">Lead Name</th>
                    <th className="text-white">Lead ID</th>
                    <th className="text-white">Teacher</th>
                    <th className="text-white">Date of Convertion</th>
                    <th className="text-white">Profit</th>
                    <th className="text-white">Revenue</th>
                    <th className="text-white">NRI/I</th>
                    <th className="text-white">Type Class</th>
                    <th className="text-white">Payout Amt</th>
                    <th className="text-white">Payout Date</th>
                    <th className="text-white">Rank Performance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-light">
                    <td>Marketing</td>
                    <td>marketing#-0001</td>
                    <td>John Smith</td>
                    <td>Lead A</td>
                    <td>lead#-0001</td>
                    <td>Teacher A</td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>20,000</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>Class A</td>
                    <td>500</td>
                    <td>2024-07-31</td>
                    <td>1</td>
                  </tr>
                  <tr className="bg-light">
                    <td>Marketing</td>
                    <td>marketing#-0001</td>
                    <td>John Smith</td>
                    <td>Lead A</td>
                    <td>lead#-0001</td>
                    <td>Teacher A</td>
                    <td>12 January 2012 10:30 AM</td>
                    <td>20,000</td>
                    <td>10,000</td>
                    <td>30,000</td>
                    <td>Class A</td>
                    <td>500</td>
                    <td>2024-07-31</td>
                    <td>1</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          {currentSubTab === "2" && (
            <>
              <Container className="shadow border border-2 rounded-3 py-3 mt-3">
                <MarketingBarGraph />
              </Container>
              <Container className="shadow border border-2 rounded-3 py-3 mt-5">
                <IncomeBreakdownGraph
                  title="Convertion Breakdown"
                  data={incomeData2}
                />
              </Container>
            </>
          )}
        </>
      )}
    </OperationLayout>
  );
};

export default ReportPage;
