import React, { useState } from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import {
  Card,
  Col,
  Row,
  Modal,
  Form,
  Container,
  Accordion,
} from "react-bootstrap";
import { MdEdit, MdNavigateNext } from "react-icons/md";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { FaBookOpenReader } from "react-icons/fa6";
import { IoIosAdd } from "react-icons/io";

function TeachingFAQ(props) {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [like, setLike] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddShowModal = () => {
    setShowAddModal(true);
  };

  const handleAddCloseModal = () => {
    setShowAddModal(false);
  };

  const handleLike = () => {
    setLike(!like);
  };

  return (
    <>
      <h5>Web FAQ'S</h5>
      {/* {/* <OperationLocation path={history} /> */}
      <Row>
        <Col md={12} className="d-flex align-items-center justify-content-end">
          <Button
            onClick={handleAddShowModal}
            className="rounded-5 bg-warning border-warning text-white"
            startIcon={<IoIosAdd />}
            variant="outlined"
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-between">
        <Col md={3}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-5 mt-3 mb-3">
              <option value="option1">Teacher</option>
              <option value="option2">Parent</option>
              <option value="option2">Landing Page</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5 mt-3 mb-3"
              type="text"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h5 className="m-0">Guidelines and policies</h5>
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Accordion Item #1
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(0)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Accordion Item #2{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(1)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h5 className="m-0">Terms & Conditions</h5>
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Accordion Item #1{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(0)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Accordion Item #2{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(1)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h5 className="m-0">Policies Regarding</h5>
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Accordion Item #1{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(0)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Accordion Item #2{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(1)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h5 className="m-0">
            Expectations for professional conduct and communication with
            students and parents
          </h5>
          <Accordion defaultActiveKey="0" className="mt-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Accordion Item #1{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(0)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Accordion Item #2{" "}
                <button className="ms-3 btn btn-sm btn-danger">
                  Delete FAQ
                </button>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                <Button onClick={() => handleShowModal(1)}>
                  Edit <MdEdit />
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12}>
            <Form.Group controlId="nameInput">
              <Form.Control type="text" placeholder="Enter Title" />
            </Form.Group>
          </Col>

          <Col md={12} className="mt-3 mb-3">
            <Form.Group controlId="textareaInput">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Description...."
              />
            </Form.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="border-danger bg-danger text-white"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} onHide={handleAddCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12}>
            <Form.Group controlId="nameInput">
              <Form.Control type="text" placeholder="Enter Title" />
            </Form.Group>
          </Col>

          <Col md={12} className="mt-3 mb-3">
            <Form.Group controlId="textareaInput">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Description...."
              />
            </Form.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="border-danger bg-danger text-white"
            variant="outlined"
            onClick={handleAddCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleAddCloseModal}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TeachingFAQ;
