import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const PieChart = ({ title, subtext, legendData, seriesData }) => {
    useEffect(() => {
        const chartDom = document.getElementById('PieChartmain');
        const myChart = echarts.init(chartDom);
        const option = {
            title: {
                text: title,
                subtext: subtext,
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                left: 'center',
                top: 'bottom',
                data: legendData
            },
            toolbox: {
                show: true,
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true }
                }
            },
            series: seriesData
        };

        option && myChart.setOption(option);
    }, [title, subtext, legendData, seriesData]);

    return <div id="PieChartmain" style={{ width: '100%', height: '400px',position:'relative' }}></div>;
};

export default PieChart;
