import Reschedule from "./Reschedule";

export const ReschedulePageConfig = {
  routes: [
    {
      path: "/teacher/reschedule",
      exact: true,
      component: Reschedule
    }
  ]
};
