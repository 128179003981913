import React, { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";

const TeacherAccountDetails = () => {
  const [teacherActiveTab, setTeacherActiveTab] = useState(1);

  return (
    <AccountLayout>
      <h3>Teacher Account Details</h3>
      <Row className="mt-3 px-3 justify-content-end">
        <button
          className="btn rounded-4 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Add Amount
        </button>
        <button
          className="btn rounded-4 ms-3 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Withdraw Amount
        </button>
      </Row>
      <Row className="mt-3 px-3">
        <div
          onClick={() => setTeacherActiveTab(1)}
          className="py-1 px-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: teacherActiveTab === 1 ? "var(--color-primary)" : "",
            color: teacherActiveTab === 1 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Incoming Transaction
        </div>
        <div
          onClick={() => setTeacherActiveTab(2)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: teacherActiveTab === 2 ? "var(--color-primary)" : "",
            color: teacherActiveTab === 2 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Outgoing Transaction
        </div>
      </Row>

      <Row className="mt-4 px-2">
        <Col
          md={12}
          style={{
            background: "#d9ffcf",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Current Balance: <b>$1000</b>
        </Col>
      </Row>

      {teacherActiveTab === 1 ? (
        <Table className="mt-3" striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Parent ID</th>
              <th>Parent Name</th>
              <th>Child ID</th>
              <th>Child Name</th>
              <th>Course ID</th>
              <th>Course Name</th>
              <th>Transaction For</th>
              <th>Transaction Type</th>
              <th>Transaction Date</th>
              <th>Transaction Amount</th>
              <th>Transaction Status</th>
              <th>Transaction Method</th>
              <th>Transaction Description</th>
            </tr>
          </thead>
          <tbody>
            {allTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>{transaction.transactionId}</td>
                <td>{transaction.parentID}</td>
                <td>{transaction.parentName}</td>
                <td>{transaction.childId}</td>
                <td>{transaction.childName}</td>
                <td>{transaction.courseId}</td>
                <td>{transaction.courseName}</td>
                <td>{transaction.transactionFor}</td>
                <td>{transaction.transactionType}</td>
                <td>{transaction.transactionDate}</td>
                <td>{transaction.transactionAmount}</td>
                <td>{transaction.transactionStatus}</td>
                <td>{transaction.transactionMethod}</td>
                <td>{transaction.transactionDescription}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table className="mt-3" striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Transaction For</th>
              <th>Transaction Type</th>
              <th>Transaction Date</th>
              <th>Transaction Amount</th>
              <th>Transaction Status</th>
              <th>Transaction Method</th>
              <th>Transaction Description</th>
            </tr>
          </thead>
          <tbody>
            {allTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>{transaction.transactionId}</td>
                <td>Class Fine</td>
                <td>{transaction.transactionType}</td>
                <td>{transaction.transactionDate}</td>
                <td>{transaction.transactionAmount}</td>
                <td>{transaction.transactionStatus}</td>
                <td>{transaction.transactionMethod}</td>
                <td>Class Fine Payment</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </AccountLayout>
  );
};

export default TeacherAccountDetails;

const allTransactions = [
  {
    id: 1,
    transactionId: "TRN-001",
    parentID: "PRNT-001",
    parentName: "John Doe",
    childId: "CHLD-001",
    childName: "Jane Doe",
    courseId: "CRS-001",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 2,
    transactionId: "TRN-002",
    parentID: "PRNT-002",
    parentName: "John Doe",
    childId: "CHLD-002",
    childName: "Jane Doe",
    courseId: "CRS-002",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 3,
    transactionId: "TRN-003",
    parentID: "PRNT-003",
    parentName: "John Doe",
    childId: "CHLD-003",
    childName: "Jane Doe",
    courseId: "CRS-003",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 4,
    transactionId: "TRN-004",
    parentID: "PRNT-004",
    parentName: "John Doe",
    childId: "CHLD-004",
    childName: "Jane Doe",
    courseId: "CRS-004",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
  {
    id: 5,
    transactionId: "TRN-005",
    parentID: "PRNT-005",
    parentName: "John Doe",
    childId: "CHLD-005",
    childName: "Jane Doe",
    courseId: "CRS-005",
    courseName: "Mathematics",
    transactionFor: "Course Fee",
    transactionType: "Credit",
    transactionDate: "2022-04-05",
    transactionAmount: 1000,
    transactionStatus: "Success",
    transactionMethod: "Online",
    transactionDescription: "Course Fee Payment",
  },
];
