import SeoPage from "./SeoPage";

export const SeoPageConfig = {
  routes: [
    {
      path: "/operation/SEO",
      exact: true,
      component: SeoPage
    }
  ]
};
