import React from "react";
import { Button, Col, Row, Form, Badge } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import styled from "styled-components";

const Employee = () => {
  return (
    <>
      <Row className="">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select type
              </option>
              <option value={"1"}>Employee</option>
              <option value={"1"}>Student</option>
              <option value={"2"}>Teacher</option>
              <option value={"2"}>Parent</option>
              <option value={"2"}>Class</option>
              <option value={"2"}>Cources</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by I'D"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Name"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Email"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control className="shadow rounded-3" type="date" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select status
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>Inactive</option>
              <option value={"2"}>Block</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select live status
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>Inactive</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-5 mb-3">
        <h5>Search Result ( 3 )</h5>
      </Row>
      <StyleContainer>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="ms-5">
                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                <p>12 January 2023</p>
              </div>
              <Badge className="ms-3" bg="success">
                Active
              </Badge>
            </div>
            <p className="m-0 fw-bold text_yellow">
              view <FaAngleRight />
            </p>
          </div>
        </div>

        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="ms-5">
                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                <p>12 January 2023</p>
              </div>
              <Badge className="ms-3" bg="danger">
                In Active
              </Badge>
            </div>
            <p className="m-0 fw-bold text_yellow">
              view <FaAngleRight />
            </p>
          </div>
        </div>

        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-2 border `}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="ms-5">
                <h6 className="m-0 fw-bold">Kristin Watson</h6>
                <p>12 January 2023</p>
              </div>
              <Badge className="ms-3" bg="success">
                Active
              </Badge>
            </div>
            <p className="m-0 fw-bold text_yellow">
              view <FaAngleRight />
            </p>
          </div>
        </div>
      </StyleContainer>
    </>
  );
};

const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default Employee;
