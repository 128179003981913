import LeadsPage from "./LeadsPage";

export const LeadsPageConfig = {
  routes: [
    {
      path: "/operation/Leads",
      exact: true,
      component: LeadsPage
    }
  ]
};
