// Chats
import ChatsPage from "./ChatsPage";

export const ChatsPageConfig = {
  routes: [
    {
      path: "/operation/chats",
      exact: true,
      component: ChatsPage,
    },
  ],
};
