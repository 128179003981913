import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Row, Table } from "react-bootstrap";

const ParentAccountDetails = () => {
  const [parentActiveTab, setParentActiveTab] = useState(1);
  const [parentActiveSubTab, setParentActiveSubTab] = useState(1);
  return (
    <OperationLayout>
      <h4>Parent Account Details</h4>
      <Row className="mt-1 justify-content-end">
        <button
          className="btn rounded-4 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Add Amount
        </button>
        <button
          className="btn rounded-4 ms-3 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Withdraw Amount
        </button>
      </Row>
      <Row className="mt-3 px-3">
        <div
          onClick={() => setParentActiveTab(1)}
          className="py-1 px-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: parentActiveTab === 1 ? "var(--color-primary)" : "",
            color: parentActiveTab === 1 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Incoming Transaction
        </div>
        <div
          onClick={() => setParentActiveTab(2)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: parentActiveTab === 2 ? "var(--color-primary)" : "",
            color: parentActiveTab === 2 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Outgoing Transaction
        </div>
      </Row>
      <Row className="mt-3 px-3">
        {parentActiveTab === 1 ? (
          <>
            <div
              onClick={() => setParentActiveSubTab(1)}
              className="py-1 px-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  parentActiveSubTab === 1 ? "var(--color-primary)" : "",
                color: parentActiveSubTab === 1 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Bank
            </div>
            <div
              onClick={() => setParentActiveSubTab(2)}
              className="py-1 px-3 ms-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  parentActiveSubTab === 2 ? "var(--color-primary)" : "",
                color: parentActiveSubTab === 2 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Reffrel
            </div>
            <div
              onClick={() => setParentActiveSubTab(3)}
              className="py-1 px-3 ms-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  parentActiveSubTab === 3 ? "var(--color-primary)" : "",
                color: parentActiveSubTab === 3 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Refund Amount
            </div>
            <div
              onClick={() => setParentActiveSubTab(4)}
              className="py-1 px-3 ms-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  parentActiveSubTab === 4 ? "var(--color-primary)" : "",
                color: parentActiveSubTab === 4 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Gift Card Amount
            </div>
          </>
        ) : (
          <div
            onClick={() => setParentActiveSubTab(1)}
            className="py-1 px-3 rounded-5 border"
            style={{
              width: "fit-content",
              background:
                parentActiveSubTab === 1 ? "var(--color-primary)" : "",
              color: parentActiveSubTab === 1 ? "white" : "",
              cursor: "pointer",
            }}
          >
            Course Payment
          </div>
        )}
      </Row>

      <Row className="mt-4 px-2">
        <Col
          style={{
            background: "#d9ffcf",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Current Balance: <b>$1000</b>
        </Col>
      </Row>

      {parentActiveTab === 1 && parentActiveSubTab === 1 ? (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Bank Name</th>
              <th>Transaction Type</th>
              <th>Transaction Status</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill()
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>123456</td>
                  <td>$1000</td>
                  <td>Bank</td>
                  <td>12/12/2021</td>
                  <td>12:00</td>
                  <td>Bank of America</td>
                  <td>Bank</td>
                  <td>Success</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : parentActiveTab === 1 && parentActiveSubTab === 2 ? (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Refferal ID</th>
              <th>Refferal Name</th>
              <th>Refferal Email</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill()
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>123456</td>
                  <td>$1000</td>
                  <td>Refferal</td>
                  <td>12/12/2021</td>
                  <td>12:00</td>
                  <td>123456</td>
                  <td>John Doe</td>
                  <td>
                    <a href="#">test@tes.com</a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : parentActiveTab === 1 && parentActiveSubTab === 3 ? (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Refund ID</th>
              <th>Refund Name</th>
              <th>Refund Email</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill()
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>123456</td>
                  <td>$1000</td>
                  <td>Refund</td>
                  <td>12/12/2021</td>
                  <td>12:00</td>
                  <td>123456</td>
                  <td>John Doe</td>
                  <td>
                    <a href="#">test@test.com</a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : parentActiveTab === 1 && parentActiveSubTab === 4 ? (
        <Table striped bordered hover responsive className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Gift Card ID</th>
              <th>Gift Card Name</th>
              <th>Gift Card Email</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill()
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>123456</td>
                  <td>$1000</td>
                  <td>Gift Card</td>
                  <td>12/12/2021</td>
                  <td>12:00</td>
                  <td>123456</td>
                  <td>John Doe</td>
                  <td>
                    <a href="#">test@test.com</a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : parentActiveTab === 2 ? (
        <>
          <Row className="mt-1">
            <Col md={12} className="mt-3">
              <div
                style={{
                  background: "#ffcfcf",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                Every course will expire in 45 days
              </div>
            </Col>
          </Row>

          <Table striped bordered hover responsive className="mt-4">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Course Email</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill()
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>123456</td>
                    <td>$1000</td>
                    <td>Course</td>
                    <td>12/12/2021</td>
                    <td>12:00</td>
                    <td>123456</td>
                    <td>John Doe</td>
                    <td>
                      <a href="#">test@test.com</a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : null}
    </OperationLayout>
  );
};

export default ParentAccountDetails;
