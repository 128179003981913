import React from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Row } from 'react-bootstrap';
function Liquidity(props) {
    const { history } = props;
    return (
        <>
            <h5>Liquidity</h5>
         {/* <OperationLocation path={history} /> */} 
            <Container>
                <Row className='mt-3'>
                    <Col className='ps-5 shadow border rounded-3 d-flex align-items-center justify-content-start' md={4} style={{height:'7vh'}}><h6>Current Ratio</h6></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='ps-5 shadow border rounded-3 d-flex align-items-center justify-content-start' md={4} style={{height:'7vh'}}><h6>Quick Ratio</h6></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='ps-5 shadow border rounded-3 d-flex align-items-center justify-content-start' md={4} style={{height:'7vh'}}><h6>Cash Ratio</h6></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='ps-5 shadow border rounded-3 d-flex align-items-center justify-content-start' md={4} style={{height:'7vh'}}><h6>Operating Cycle</h6></Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='ps-5 shadow border rounded-3 d-flex align-items-center justify-content-start' md={4} style={{height:'7vh'}}><h6>Cash Conversion Cycle</h6></Col>
                </Row>
            </Container>
        </>
    )
}

export default Liquidity