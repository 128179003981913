import PaymentLogsPage from "./PayementLogsPage";

export const PaymentLogsPageConfig = {
  routes: [
    {
      path: "/account/dashboard/payment-logs",
      exact: true,
      component: PaymentLogsPage
    }
  ]
};
