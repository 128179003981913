import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { fade, makeStyles } from "@material-ui/core/styles";
import { InputBase, Badge } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MailIcon from "@material-ui/icons/Mail";
import TodayIcon from "@material-ui/icons/Today";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import NotificationsIcon from "@material-ui/icons/Notifications";
import OftadehAvatarMenu from "../OftadehAvatarMenu/OftadehAvatarMenu";
import clsx from "clsx";
import NavigationContext from "../../../context/NavigationContext";
import ThemeContext from "../../../context/ThemeContext";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { FaGift, FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { storeRole, storeToken } from "../../../slices/tokenSlice";
import { getCommonInfo } from "../../../utils/MyAPI";
const isLocalServer = process.env.NODE_ENV === "development";
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props) => props.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2)
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: "30px",
    fontFamily: "Poppins, sans-serif",
    backgroundColor:
      theme.palette.type === "dark"
        ? fade(theme.palette.common.white, 0.1)
        : fade(theme.palette.action.disabled, 0.1),
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? fade(theme.palette.common.white, 0.25)
          : fade(theme.palette.action.disabled, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
  appbarSection: {
    display: "flex",
    // display: "none",
    alignItems: "center",
    // [theme.breakpoints.up("sm")]: {
    //   display: "flex"
    // }
  },
  appbarToday: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const OftadehAppBar = (props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const navigate = useNavigate();
  const { open, handleDrawerToggle, handleRightPanelOpen } =
    React.useContext(NavigationContext);

  const { setThemeName, curThemeName } = React.useContext(ThemeContext);

  const [show, setShow] = useState(false);
  const [parentInfo, setParentInfo] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const parentToken = sessionStorage.getItem("parentToken");
  const dispatch = useDispatch();

  useEffect(() => {
    if (parentToken) {
      dispatch(storeToken(parentToken));
      dispatch(storeRole("parent"));
    }
  }, [parentToken]);

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const { data } = await getCommonInfo(parentToken);
        // console.log("Parent info: ", data);
        if (data.res_type === "success") {
          setParentInfo(data.data);
        }
      } catch (error) {
        console.log("Error fetching parent info: ", error);
      }
    };
    fetchParentInfo();
  }, [parentToken]);

  const handleGiftPage = () => {
    navigate("/parent/dashboard/pages/gift");
  };
  const handleFaverotePage = () => {
    navigate("/parent/dashboard/pages/favorites");
  };
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon style={{ color: "#838383" }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{ fontFamily: "Poppins, sans-serif" }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>

        <div className={classes.grow} />
        <Col md={1} className="me-3 d-none d-md-block d-lg-block d-xl-block">
          <Form.Select
            style={{
              outline: "none", // Remove outline
              boxShadow: "none", // Remove box shadow
            }}
            size="sm"
            onMouseOver={(e) => {
              e.target.style.background = "none";
            }} // Remove hover effect
          >
            {parentInfo?.children?.map((child, index) => (
              <option key={index} style={{ fontFamily: "Poppins, sans-serif" }}>
                Child {index + 1}
              </option>
            ))}
          </Form.Select>
        </Col>
        <span
          className="d-none d-md-block me-3"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Parent
        </span>
        <div className={classes.appbarSection}>
          {location.pathname.includes("/parent") && (
            <FormControlLabel
              onClick={handleShow}
              value={show}
              label={"Teacher"}
              style={{ fontFamily: "Poppins, sans-serif" }}
              control={<Switch defaultChecked />}
            />
          )}

          <span
            className="d-none d-md-block"
            onClick={handleGiftPage}
            style={{ cursor: "pointer" }}
          >
            <IconButton aria-label="delete">
              <FaGift size={22} color="#474747" />
            </IconButton>
          </span>

          <span
            className="d-none d-md-block"
            onClick={handleFaverotePage}
            style={{ cursor: "pointer" }}
          >
            <IconButton aria-label="delete">
              <FaHeart size={22} color="#474747" />
            </IconButton>
          </span>

          {isLocalServer ? (
            <IconButton
              aria-haspopup="true"
              onClick={() =>
                curThemeName === "dark"
                  ? setThemeName("light")
                  : setThemeName("dark")
              }
              color="inherit"
            >
              {curThemeName === "dark" ? (
                <Brightness7Icon style={{ color: "#474747" }} />
              ) : (
                <Brightness4Icon style={{ color: "#474747" }} />
              )}
            </IconButton>
          ) : null}

          <div className={classes.appbarToday}>
            {/* <IconButton
              aria-haspopup="true"
              onClick={(event) => handleRightPanelOpen(event, 0)}
              color="inherit"
            >
              <TodayIcon />
            </IconButton> */}
            {/* <IconButton
              aria-haspopup="true"
              onClick={(event) => handleRightPanelOpen(event, 2)}
              aria-label="show 4 new messages"
              color="inherit"
            >
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
          </div>
          <IconButton
            aria-haspopup="true"
            onClick={(event) => handleRightPanelOpen(event, 1)}
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon style={{ color: "#474747" }} />
            </Badge>
          </IconButton>
          <OftadehAvatarMenu />
        </div>
      </Toolbar>

      <Modal
        style={{ zIndex: 99999 }}
        show={show}
        onHide={handleClose}
        centered
        size="md"
      >
        <div className="p-3">
          <h4 className="text-center text_yellow">Registration</h4>

          <Form>
            <Row className="mt-5">
              <Col md={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="First Name"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Visible Name"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Control
                  className="rounded-3 shadow border-yellow"
                  type="date"
                />
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Select className="rounded-3 shadow border-yellow">
                    <option value="option1">Gender</option>
                    <option value="option1">Male</option>
                    <option value="option2">Female</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Select className="rounded-3 shadow border-yellow">
                    <option value="option1">Nationality</option>
                    <option value="option1">Hindu</option>
                    <option value="option2">Muslim</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Address"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Select className="rounded-3 shadow border-yellow">
                    <option value="option1">City</option>
                    <option value="option1">Mumbai</option>
                    <option value="option2">Indore</option>
                    <option value="option2">Delhi</option>
                    <option value="option2">Bhopal</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Select className="rounded-3 shadow border-yellow">
                    <option value="option1">Country</option>
                    <option value="option1">India</option>
                    <option value="option2">America</option>
                    <option value="option2">China</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Pincode"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Phone"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Whatsapp Number"
                  />
                </Form.Group>
              </Col>
              <Col md={12} className="mt-4">
                {/* textarea */}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    className="rounded-3 shadow border-yellow"
                    as="textarea"
                    rows={3}
                    placeholder="About you"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="text"
                    placeholder="Video Introduction Link"
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    className="rounded-3 px-3 shadow border-yellow"
                    type="file"
                    placeholder="Upload "
                  />
                </Form.Group>
              </Col>
              <Row className="d-flex align-items-center justify-content-end">
                <Col
                  md={6}
                  className="mt-4 d-flex align-items-center justify-content-end"
                >
                  <button className="py-2 px-5 bg-yellow text-white rounded-5 border-0">
                    Next
                  </button>
                </Col>
              </Row>
            </Row>
          </Form>
        </div>
      </Modal>
    </AppBar>
  );
};

OftadehAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default OftadehAppBar;
