import React from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaSearch } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";

const data = [
  {
    id: 1,
    transactionId: "TRAN#001",
    teacherId: "T001",
    teacherName: "John Doe",
    courseId: "C001",
    courseName: "Maths",
    parentId: "P001",
    parentName: "Alex Doe",
    childId: "C001",
    childName: "Alex Doe",
    currentCycle: "3",
    date: "05 Apr. 2022",
    time: "4:00 PM",
    teacherStatus: "Late Join",
    currentClass: "3/12",
    paymentStatus: "Paid",
    amount: "INR 40,000",
  },
  {
    id: 2,
    transactionId: "TRAN#002",
    teacherId: "T002",
    teacherName: "Jane Doe",
    courseId: "C002",
    courseName: "Science",
    parentId: "P002",
    parentName: "Alex Doe",
    childId: "C002",
    childName: "Alex Doe",
    currentCycle: "3",
    date: "05 Apr. 2022",
    time: "4:00 PM",
    teacherStatus: "On Time Joining",
    currentClass: "3/12",
    paymentStatus: "Pending",
    amount: "INR 40,000",
  },
  {
    id: 3,
    transactionId: "TRAN#003",
    teacherId: "T003",
    teacherName: "John Doe",
    courseId: "C003",
    courseName: "Maths",
    parentId: "P003",
    parentName: "Alex Doe",
    childId: "C003",
    childName: "Alex Doe",
    currentCycle: "3",
    date: "05 Apr. 2022",
    time: "4:00 PM",
    teacherStatus: "No Show",
    currentClass: "3/12",
    paymentStatus: "Paid",
    amount: "INR 40,000",
  },
];

function TeacherPayment(props) {
  const { history } = props;
  return (
    <>
      <h5>Teacher Payment</h5>
      {/* <OperationLocation path={history} /> */}

      <Row className="d-flex align-items-center justify-content-end">
        <Col md={4}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        responsive
        style={{ backgroundColor: "var(--color-primary)", minWidth: 2000 }}
        striped
        hover
        className="mt-3 custom-styled-table rounded-3 text-center"
      >
        <thead>
          <tr>
            <th className="text-white">S. No.</th>
            <th className="text-white">Transaction I'D</th>
            <th className="text-white">Teacher I'D</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Course I'D</th>
            <th className="text-white">Course Name</th>
            <th className="text-white">Parent I'D</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Child I'D</th>
            <th className="text-white">Child Name</th>
            <th className="text-white">Current Cycle</th>
            <th className="text-white">Current Class</th>
            <th className="text-white">Date</th>
            <th className="text-white">Time</th>
            <th className="text-white">Teacher Status</th>
            <th className="text-white">Payment Status</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} className="bg-light">
              <td>{index + 1}</td>
              <td>{item.transactionId}</td>
              <td>{item.teacherId}</td>
              <td>{item.teacherName}</td>
              <td>{item.courseId}</td>
              <td>{item.courseName}</td>
              <td>{item.parentId}</td>
              <td>{item.parentName}</td>
              <td>{item.childId}</td>
              <td>{item.childName}</td>
              <td>{item.currentCycle}</td>
              <td>{item.currentClass}</td>
              <td>{item.date}</td>
              <td>{item.time}</td>
              <td>
                {item.teacherStatus === "Late Join" ? (
                  <Badge bg="danger">Late Join</Badge>
                ) : item.teacherStatus === "On Time Joining" ? (
                  <Badge bg="success">On Time Joining</Badge>
                ) : (
                  <Badge bg="warning">No Show</Badge>
                )}
              </td>
              <td>
                {item.paymentStatus === "Paid" ? (
                  <Badge bg="success">Paid</Badge>
                ) : (
                  <Badge bg="warning">Pending</Badge>
                )}
              </td>
              <td className="text-primary">{item.amount}</td>
              <td>
                <button className="btn btn-sm btn-warning">Add Fine</button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default TeacherPayment;
