import Registration from "./Registration";
import RegistrationNext from "./RegistrationNext";

export const RegistrationPageConfig = {
  routes: [
    {
      path: "/teacher/registration",
      exact: true,
      component: Registration,
    },
    {
      path: "/teacher/registration-next",
      exact: true,
      component: RegistrationNext,
    },
  ],
};
