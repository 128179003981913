import AllInterviewRequest from "./AllInterviewRequest";
import CancelPage from "./CancelClassPage";
import HoldPage from "./HoldClassPage";

export const RequestPageConfig = {
  routes: [
    {
      path: "operation/class/hold",
      exact: true,
      component: HoldPage,
    },
    {
      path: "operation/class/cancel",
      exact: true,
      component: CancelPage,
    },
    {
      path: "operation/interview/request",
      exact: true,
      component: AllInterviewRequest,
    },

  ],
};
