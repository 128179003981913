import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Col, Form, Table, Modal, Button } from "react-bootstrap";
import { FaDownload, FaEye } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";

const users = [
  {
    id: 1,
    name: "John Doe",
    date: "12/12/2021",
    vName: "Web Developer",
    status: "Pending",
    count:'20+',
  },
  {
    id: 2,
    name: "John Doe",
    date: "12/12/2021",
    vName: "Web Developer",
    status: "Completed",
    count:'50+',
  },
  {
    id: 3,
    name: "John Doe",
    date: "12/12/2021",
    vName: "Web Developer",
    status: "Pending",
    count:'120+',
  },
  {
    id: 4,
    name: "John Doe",
    date: "12/12/2021",
    vName: "Web Developer",
    status: "Completed",
    count:'230+',
  },
  {
    id: 5,
    name: "John Doe",
    date: "12/12/2021",
    vName: "Web Developer",
    status: "Completed",
    count:'90+',
  },
];

const CareerPage = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  return (
    <OperationLayout>
      <h5>Career Management</h5>

      <Row className="mt-4 justify-content-between">
        <Col md={4}>
          <Form.Control
            type="text"
            className="rounded-5"
            placeholder="Search..."
          />
        </Col>

        <Col md={2} className="d-flex justify-content-end">
          <button
            className="btn rounded-5 text-white"
            style={{ background: "var(--color-primary)" }}
            onClick={handleShow2}
          >
            Add Vaccency
          </button>
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Name</th>
            <th className="text-white">Date </th>
            <th className="text-white">Vaccency Name</th>
            <th className="text-white">Applicant</th>
            <th className="text-white">Status</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.date}</td>
              <td>{user.vName}</td>
              <td>{user.count}</td>
              <td>
                {user.status === "Pending" ? (
                  <span className="badge bg-danger">{user.status}</span>
                ) : (
                  <span className="badge bg-success">{user.status}</span>
                )}
              </td>
              <td className="text-primary" style={{ cursor: "pointer" }}>
                <button
                  onClick={handleShow}
                  className="btn btn-sm btn-danger rounded-3"
                >
                  <MdDelete />
                </button>
                <button
                  onClick={handleShow3}
                  className="btn btn-sm rounded-3 ms-3 text-white"
                  style={{ background: "var(--color-primary)" }}
                >
                  <FaEye />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Are you sure you want to delete this user?</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-around">
            <Button
              variant="danger"
              className="rounded-5"
              onClick={handleClose}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              className="rounded-5"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        centered
        size="lg"
        style={{ zIndex: 9999 }}
        onHide={handleClose2}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Add Vaccency</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Vaccency Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Vaccency Name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">
                    Skills Required
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Skills Required"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-semibold">Job Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Job Description"
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">
                    Experience Required
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Experience Required"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">Salary</Form.Label>
                  <Form.Control type="text" placeholder="Enter Salary" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">Location</Form.Label>
                  <Form.Control type="text" placeholder="Enter Location" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">
                    Number of Vaccencies
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Number of Vaccencies"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">Apply Before</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="fw-semibold">Apply Link</Form.Label>
                  <Form.Control type="text" placeholder="Enter Apply Link" />
                </Form.Group>
              </Col>
            </Row>

            <Button
              style={{ background: "var(--color-primary)" }}
              className="rounded-5"
              onClick={handleClose2}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        centered
        size="lg"
        style={{ zIndex: 9999 }}
        onHide={handleClose3}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Vaccency Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <p>
                    Vaccency Name: <b>Web Developer</b>
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    Skills Required: <b>HTML, CSS, JS</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Job Description: <b>Web Developer</b>
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    Experience Required: <b>2 Years</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Salary: <b>50,000</b>
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    Location:<b> Lahore</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Number of Vaccencies:<b> 2</b>
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    Apply Before: <b>12/12/2021</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Apply Link:<b> www.google.com</b>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-4">
              <p className="fw-semibold">Applied Vaccency</p>
              <Table
                className="mt-2 mb-3 "
                style={{ backgroundColor: "var(--color-primary)" }}
                striped
                responsive
                hover
              >
                <thead>
                  <tr className="text-center">
                    <th className="text-white">S. No.</th>
                    <th className="text-white">Name</th>
                    <th className="text-white">Email</th>
                    <th className="text-white">Phone</th>
                    <th className="text-white">Download CV</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center bg-light">
                    <td>1</td>
                    <td>John Doe</td>
                    <td>test@test.com</td>
                    <td>03001234567</td>
                    <td><FaDownload className="cursor-pointer" size={22} /></td>
                  </tr>
                  <tr className="text-center bg-light">
                    <td>2</td>
                    <td>John Doe</td>
                    <td>test@test.com</td>
                    <td>03001234567</td>
                    <td><FaDownload className="cursor-pointer" size={22} /></td>
                  </tr>
                  <tr className="text-center bg-light">
                    <td>3</td>
                    <td>John Doe</td>
                    <td>test@test.com</td>
                    <td>03001234567</td>
                    <td><FaDownload className="cursor-pointer" size={22} /></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </OperationLayout>
  );
};

export default CareerPage;
