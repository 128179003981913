import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { LiaSortNumericUpSolid } from "react-icons/lia";

const data = [
  {
    id: 1,
    parentId: "PAR235",
    parentName: "Parent Name",
    childId: "CHI235",
    childName: "Child Name",
    teacherId: "TEA235",
    teacherName: "Teacher Name",
    courseId: "COURSE235",
    courseName: "Course Name",
    lastCycle: 4,
    classCount: 12,
    lastAmount: "$ 23",
    dateTime: "12/05/2021 12:00 PM",
  },
  {
    id: 2,
    parentId: "PAR235",
    parentName: "Parent Name",
    childId: "CHI235",
    childName: "Child Name",
    teacherId: "TEA235",
    teacherName: "Teacher Name",
    courseId: "COURSE235",
    courseName: "Course Name",
    lastCycle: 4,
    classCount: 12,
    lastAmount: "$ 23",
    dateTime: "12/05/2021 12:00 PM",
  },
  {
    id: 3,
    parentId: "PAR235",
    parentName: "Parent Name",
    childId: "CHI235",
    childName: "Child Name",
    teacherId: "TEA235",
    teacherName: "Teacher Name",
    courseId: "COURSE235",
    courseName: "Course Name",
    lastCycle: 4,
    classCount: 12,
    lastAmount: "$ 23",
    dateTime: "12/05/2021 12:00 PM",
  },
  {
    id: 4,
    parentId: "PAR235",
    parentName: "Parent Name",
    childId: "CHI235",
    childName: "Child Name",
    teacherId: "TEA235",
    teacherName: "Teacher Name",
    courseId: "COURSE235",
    courseName: "Course Name",
    lastCycle: 4,
    classCount: 12,
    lastAmount: "$ 23",
    dateTime: "12/05/2021 12:00 PM",
  },
];

const DuePayment = () => {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <AccountLayout>
      <h3>Due Payment</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search by name or ID"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              placeholder="All Transactions"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <button className="bg-transparent rounded-4 py-2 px-3 border-yellow fw-semibold">
            Reset
          </button>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col
          md={4}
          className="d-flex align-items-center gap-3 justify-content-end"
        >
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4">
            <CiFilter /> Filter
          </button>
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4 ">
            <LiaSortNumericUpSolid /> Sort
          </button>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Parent I'D</th>
                <th className="text-white">Parent Name</th>
                <th className="text-white">Child I'D</th>
                <th className="text-white">Child Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Last Cycle</th>
                <th className="text-white">Class Count</th>
                <th className="text-white">Last Amount</th>
                <th className="text-white">Date & Time</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.parentId}</td>
                  <td>{item.parentName}</td>
                  <td>{item.childId}</td>
                  <td>{item.childName}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.courseId}</td>
                  <td>{item.courseName}</td>
                  <td>{item.lastCycle}</td>
                  <td>{item.classCount}</td>
                  <td>{item.lastAmount}</td>
                  <td>{item.dateTime}</td>
                  <td>
                    <button
                      onClick={handleShow}
                      className="btn btn-sm btn-danger"
                    >
                      Send Reminder
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p
            className="m-0 fw-semibold text-decoration-underline text_yellow"
            style={{ cursor: "pointer" }}
          >
            View More
          </p>
        </Col>
      </Row>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="text"
              placeholder="Amount"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Message"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleClose}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default DuePayment;
