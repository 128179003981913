import React from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa6";

const Dropdown = ({ head, lis, active, onToggle }) => {
  const handleDropdownClick = () => {
    onToggle();
  };

  return (
    <StyledContainer>
      <div
        className="dropdown_custom d-flex align-items-center justify-content-between py-2 px-3 shadow position-relative"
        onClick={handleDropdownClick}
      >
        <p className="m-0">{head}</p>
        <FaAngleDown />

        <div
          className={`content overflow-hidden ${
            active === true ? "active" : ""
          }`}
        >
          <ul className="m-0 p-0 py-2 px-3">
            {lis.map((li) => (
              <li key={li.id} className="p-2 rounded-3 m-0">
                {li.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .dropdown_custom {
    border: 2px solid #e5e5e5;
    width: 100%;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease-in-out !important;
  }

  .content {
    position: absolute;
    background: #fff;
    width: fit-content;
    z-index: 999;
    transition: all 0.3s ease-in-out !important;
    height: 0%;
    border-radius: 10px;
    top: 110%;
    right: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    ul li {
      transition: all 0.3s ease-in-out;
      &:hover {
        background: var(--color-primary);
        color: #fff;
      }
    }
  }

  .active {
    height: auto !important;
    transition: all 0.3s ease-in-out !important;
  }
`;

export default Dropdown;
