import RefundPage from "./RefundPage";

export const RefundPagePaymentConfig = {
  routes: [
    {
      path: "/account/dashboard/refund",
      exact: true,
      component: RefundPage
    }
  ]
};
