import AddBlogPage from "./AddBlog";

export const TeacherAddBlogPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/blog/add",
      exact: true,
      component: AddBlogPage
    }
  ]
};
