import AddHomeWorkPage from "./AddHomeWorkPage";

export const AddHomeWorkPageConfig = {
  routes: [
    {
      path: "/teacher/hw/:coursesId/add",
      exact: true,
      component: AddHomeWorkPage
    }
  ]
};
