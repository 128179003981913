import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Col, Row, Modal, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import job from "../assets/job.json";
import Lottie from "react-lottie";
import { GiOfficeChair } from "react-icons/gi";
import { FaAward } from "react-icons/fa";
import Footer from "../components/Footer";
import { IoLocation } from "react-icons/io5";
import { MdCastForEducation } from "react-icons/md";

const Career = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleScrollDown = () => {
    window.scroll(0, 800);
  };
  return (
    <>
      <Navbar />
      <CareerContainer>
        <div className="d-md-flex align-items-center justify-content-center ">
          <div className="d-md-block d-flex flex-column align-items-md-start align-items-center">
            <h1 className="mb-3">Join Our Team</h1>
            <p className="text-center text-md-start">
              Work at the most dynamic and successful agency
            </p>
            <button onClick={handleScrollDown} className="mt-4 btn px-4 py-2 shadow rounded-5 border-0">
              View Openings
            </button>
          </div>
          <div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: job,
              }}
              height={500}
              width={500}
            />
          </div>
        </div>

        <Row className="align-items-center px-md-5 px-3">
          <Col md={6}>
            <Row>
              <Col md={6}>
                <div className="shadow rounded-4 p-3">
                  <GiOfficeChair className="fs-1 text_yellow mb-2" />
                  <h6 className="fw-bold">Large Beautiful Office</h6>
                  <p className="m-0 small_text">
                    Enjoy a comfortable office environment with the most modern
                    and stylish furnishing.
                  </p>
                </div>
              </Col>
              <Col md={6} className="mt-md-0 mt-4">
                <div className="shadow rounded-4 p-3">
                  <IoLocation className="fs-1 text_yellow mb-2" />
                  <h6 className="fw-bold">Easy Location</h6>
                  <p className="m-0 small_text">
                    Enjoy a comfortable office environment with the most modern
                    and stylish furnishing.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={6}>
                <div className="shadow rounded-4 p-3">
                  <MdCastForEducation className="fs-1 text_yellow mb-2" />
                  <h6 className="fw-bold">Education Opportunity</h6>
                  <p className="m-0 small_text">
                    Enjoy a comfortable office environment with the most modern
                    and stylish furnishing.
                  </p>
                </div>
              </Col>
              <Col md={6} className="mt-md-0 mt-4">
                <div className="shadow rounded-4 p-3">
                  <FaAward className="fs-1 text_yellow mb-2" />
                  <h6 className="fw-bold">Performance Award</h6>
                  <p className="m-0 small_text">
                    Enjoy a comfortable office environment with the most modern
                    and stylish furnishing.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="px-4 mt-md-0 mt-4">
            <h3 className="fw-bold">Join Our Team</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't any
            </p>
            <p>
              ul of model sentence structures, to generate Lorem Ipsum which
              looks reasonable. The generated Lorem Ipsum is therefore always
              free from repetition, injected humour, or non-characteristic words
              etc.
            </p>
            <button className="mt-4 btn px-4 py-2 shadow rounded-5 border-0">
              Learn More
            </button>
          </Col>
        </Row>

        <div className="job_sec mt-5 py-5 px-5">
          <h4 className="fw-bold mb-5">Job Opening</h4>
          <Row className="p-0 m-0">
            <Col className="bg-white shadow rounded-2 d-flex align-items-center justify-content-between py-3 px-4">
              <div>
                <h6 className="fw-bold">English Trainee</h6>
                <p className="m-0 text-secondary">Anywhere</p>
              </div>
              <div>
                <button
                  onClick={handleShow}
                  className="px-3 py-1 rounded-5 btn2 mb-2"
                >
                  Apply Now
                </button>
                <h6 className="fw-bold">
                  Deadline : 01 Fab, <br /> 2024{" "}
                </h6>
              </div>
            </Col>
          </Row>

          <Row className="p-0 m-0 mt-4">
            <Col className="bg-white shadow rounded-2 d-flex align-items-center justify-content-between py-3 px-4">
              <div>
                <h6 className="fw-bold">English Trainee</h6>
                <p className="m-0 text-secondary">Anywhere</p>
              </div>
              <div>
                <button
                  onClick={handleShow}
                  className="px-3 py-1 rounded-5 btn2 mb-2"
                >
                  Apply Now
                </button>
                <h6 className="fw-bold">
                  Deadline : 01 Fab, <br /> 2024{" "}
                </h6>
              </div>
            </Col>
          </Row>
          <Row className="p-0 m-0 mt-4">
            <Col className="bg-white shadow rounded-2 d-flex align-items-center justify-content-between py-3 px-4">
              <div>
                <h6 className="fw-bold">English Trainee</h6>
                <p className="m-0 text-secondary">Anywhere</p>
              </div>
              <div>
                <button
                  onClick={handleShow}
                  className="px-3 py-1 rounded-5 btn2 mb-2"
                >
                  Apply Now
                </button>
                <h6 className="fw-bold">
                  Deadline : 01 Fab, <br /> 2024{" "}
                </h6>
              </div>
            </Col>
          </Row>

          <Row className="p-0 m-0 mt-4">
            <Col className="bg-white shadow rounded-2 d-flex align-items-center justify-content-between py-3 px-4">
              <div>
                <h6 className="fw-bold">English Trainee</h6>
                <p className="m-0 text-secondary">Anywhere</p>
              </div>
              <div>
                <button
                  onClick={handleShow}
                  className="px-3 py-1 rounded-5 btn2 mb-2"
                >
                  Apply Now
                </button>
                <h6 className="fw-bold">
                  Deadline : 01 Fab, <br /> 2024{" "}
                </h6>
              </div>
            </Col>
          </Row>
        </div>
      </CareerContainer>

      <Footer />

      <Modal
        show={show}
        size="lg"
        style={{ zIndex: 9999 }}
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Apply for the job</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Full Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Phone</Form.Label>
                  <Form.Control type="text" placeholder="Enter your phone" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your address" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">City</Form.Label>
                  <Form.Control type="text" placeholder="Enter your city" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Country</Form.Label>
                  <Form.Control type="text" placeholder="Enter your country" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Experience</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your experience"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-semibold">Skills</Form.Label>
                  <Form.Control type="text" placeholder="Enter your skills" />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-semibold">Cover Letter</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your cover letter"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-semibold">Resume</Form.Label>
              <Form.Control type="file" />
            </Form.Group>
            <Button
              className="btn btn-block rounded-5"
              style={{ background: "var(--color-primary)" }}
              type="submit"
            >
              Apply
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const CareerContainer = styled.div`
  margin-top: 6vmax;
  overflow-x: hidden;
  .job_sec {
    background: #f9fdff;
  }
  .btn {
    background: var(--yellow-color);
  }
  .btn2 {
    border: 2px solid var(--color-primary);
    background: transparent;
  }

  @media (max-width: 768px) {
    margin-top: 15vmax;
  }
`;

export default Career;
