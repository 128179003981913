import React, { useState } from "react";
import { Row, Col, Table, Form, Modal, Button } from "react-bootstrap";
import { FaCheck, FaSearch } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";

function TaskStatus() {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <h5>Task Status</h5>

      <Row className="mt-4 justify-content-between">
        <Col md={4} className="shadow bg-body-tertiary rounded-5 px-3 py-2">
          <FaSearch />
          <input
            type="text"
            className="border-0 bg-transparent ms-3"
            placeholder="Search here..."
            style={{ outline: "none" }}
          />
        </Col>
        <Col md={2} className="d-flex justify-content-end">
          <button
            className="btn rounded-5 text-white"
            style={{ background: "var(--color-primary)" }}
            onClick={handleShow}
          >
            Add Task
          </button>
        </Col>
      </Row>

      <Col
        className="d-flex align-items-center justify-content-start p-0 mt-4 mb-3"
        style={{ borderBottom: "1px solid grey", gap: "20px" }}
      >
        <div
          className="p-0"
          style={{
            width: "fit-content",
            height: "100%",
            cursor: "pointer",
            borderBottom: "3px solid black",
          }}
        >
          <h5>View Task Status</h5>
        </div>
      </Col>
      <Table
        responsive
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        hover
        className="mt-3 custom-styled-table rounded-3 text-center"
      >
        <thead>
          <tr>
            <th className="text-white">S. No.</th>
            <th className="text-white">Emp Name</th>
            <th className="text-white">Date</th>
            <th className="text-white">Task</th>
            <th className="text-white">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>1</td>
            <td>John Doe</td>
            <td>05 Apr. 2022</td>
            <td className="text-primary">Task #001</td>
            <td>
              <FaCheck color="green" size={18} />
            </td>
          </tr>
          <tr className="bg-light">
            <td>2</td>
            <td>John Doe</td>
            <td>05 Apr. 2022</td>
            <td className="text-primary">Task #001</td>
            <td>
              <FaCheck color="green" size={18} />
            </td>
          </tr>
          <tr className="bg-light">
            <td>3</td>
            <td>John Doe</td>
            <td>05 Apr. 2022</td>
            <td className="text-primary">Task #001</td>
            <td>
              <MdWatchLater color="red" size={18} />
            </td>
          </tr>
          <tr className="bg-light">
            <td>4</td>
            <td>John Doe</td>
            <td>05 Apr. 2022</td>
            <td className="text-primary">Task #001</td>
            <td>
              <MdWatchLater color="red" size={18} />
            </td>
          </tr>
        </tbody>
      </Table>

      {/* Add Task Modal */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmployee">
              <Form.Label>Employee Name</Form.Label>
              <Form.Control as="select">
                <option>Select Employee</option>
                <option>John Doe</option>
                <option>John Doe</option>
                <option>John Doe</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Task Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Task Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Task Date</Form.Label>
              <Form.Control type="date" placeholder="Task Date" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Task Deadline</Form.Label>
              <Form.Control type="date" placeholder="Task Deadline" />
            </Form.Group>

            <Button
              type="submit"
              style={{ backgroundColor: "var(--color-primary)" }}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TaskStatus;
