// Classes
import TranscriptPage from "../transcript/TranscriptPage";
import ClassesPage from "./ClassesPage";
import RecordingsPage from "./Recordings";

export const ClassesPageConfig = {
  routes: [
    {
      path: "/operation/classes",
      exact: true,
      component: ClassesPage,
    },
    {
      path: "/operation/classes/recordings",
      exact: true,
      component: RecordingsPage,
    },
    {
      path: "/operation/transcript/report",
      exact: true,
      component: TranscriptPage,
    },
  ],
};
