import CourseSearchPage from "./SearchPage";

export const CourseSearchPageConfig = {
  routes: [
    {
      path: "/teacher/courses/search/:keyword?",
      exact: true,
      component: CourseSearchPage
    }
  ]
};
