import ParentPage from "./ParentPage";

export const ParentPageConfig = {
  routes: [
    {
      path: "/parent/dashboard",
      exact: true,
      component: ParentPage,
    },
  ],
};
