import AppPaymentPage from "./AppPaymentPage";

export const AppPaymentPageConfig = {
  routes: [
    {
      path: "/account/app-payment",
      exact: true,
      component: AppPaymentPage,
    },
  ],
};
