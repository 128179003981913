import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import PersonDetailsModal from "./PersonDetailsModal";
import { Button, Modal, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa6";
function AllInterviewRequest() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const people = [
    {
      id: 1,
      name: "John Doe",
      contact: "123-456-7890",
      email: "john@example.com",
      certificates: ["Certificate A", "Certificate B"],
      subject: "Mathematics",
      grade: "A",
      board: "CBSE",
      dateOfregistration: "2021-09-01",
    },
    {
      id: 2,
      name: "Jane Smith",
      contact: "987-654-3210",
      email: "jane@example.com",
      certificates: ["Certificate C", "Certificate D"],
      subject: "Mathematics",
      grade: "A",
      board: "CBSE",
      dateOfregistration: "2021-09-01",
    },
    // Add more people as needed
  ];

  const handleViewClick = (person) => {
    setSelectedPerson(person);
    setModalShow(true);
  };

  const handleGiveFeedback = () => {
    setModalShow2(true);
  };

  const handleGiveFeedbackClose = () => {
    setModalShow2(false);
  };

  return (
    <OperationLayout>
      <div className="container mt-3 mb-3">
        <h5>People List</h5>
        <Table
          striped
          hover
          responsive
          className="rounded-4"
          style={{ backgroundColor: "var(--color-primary)" }}
        >
          <thead>
            <tr>
              <th className="text-center text-white">S. No.</th>
              <th className="text-center text-white">Name</th>
              <th className="text-center text-white">Contact</th>
              <th className="text-center text-white">Email</th>
              <th className="text-center text-white">Date of Joining</th>
              <th className="text-center text-white">Subject</th>
              <th className="text-center text-white">Grade</th>
              <th className="text-center text-white">Board</th>
              <th className="text-center text-white">Action</th>
              <th className="text-center text-white">View</th>
            </tr>
          </thead>
          <tbody>
            {people.map((person, index) => (
              <tr className="bg-light text-center" key={person.id}>
                <td>{index + 1}</td>
                <td>{person.name}</td>
                <td>{person.contact}</td>
                <td>{person.email}</td>
                <td>{person.dateOfregistration}</td>
                <td>{person.subject}</td>
                <td>{person.grade}</td>
                <td>{person.board}</td>
                <td>
                  <button className="btn btn-sm btn-success rounded-4 me-2">
                    Accept
                  </button>
                  <button
                    onClick={handleGiveFeedback}
                    className="btn btn-sm btn-danger rounded-4"
                  >
                    Reject
                  </button>
                </td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="rounded-4"
                    onClick={() => handleViewClick(person)}
                  >
                    <FaEye size={22} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <PersonDetailsModal
          show={modalShow}
          handleClose={() => setModalShow(false)}
          person={selectedPerson}
        />

        <Modal centered show={modalShow2} onHide={handleGiveFeedbackClose}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="feedback">Feedback</label>
              <textarea
                className="form-control"
                id="feedback"
                rows="3"
              ></textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleGiveFeedbackClose}>
              Close
            </Button>
            <Button variant="primary">Save Changes</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </OperationLayout>
  );
}

export default AllInterviewRequest;
