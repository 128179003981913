import AddCoursePage from "./AddCourse";

export const AddCoursePageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/course-management/add-course",
      exact: true,
      component: AddCoursePage
    }
  ]
};
