import { FormControlLabel, Switch } from '@mui/material';
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { LuBook } from 'react-icons/lu';
import { MdOutlineReviews } from 'react-icons/md';
import { RiGraduationCapLine } from 'react-icons/ri';
import styled from 'styled-components';

function Mentors() {
    return (
        <>
            <Row className="mb-3 mt-3">
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseName">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentor name"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseStatus">
                        <Form.Control className="rounded-5 shadow" as="select">
                            <option value="">Select status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseCategory">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentor ratings"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseLevel">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentors course title"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseDuration">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentor course duration"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="coursePrice">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentor course price"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Form.Group controlId="courseTag">
                        <Form.Control
                            className="rounded-5 shadow"
                            type="text"
                            placeholder="Enter mentor course tag"
                        />
                    </Form.Group>
                </Col>
                <Col md={3} className="mt-3">
                    <Button
                        variant="primary"
                        className=" rounded-5 w-100 new-button"
                        block
                    >
                        Apply Filters
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3" md={4}>
                    <MentorCard />
                </Col>
                <Col className="mt-3" md={4}>
                    <MentorCard />
                </Col>
                <Col className="mt-3" md={4}>
                    <MentorCard />
                </Col>
                <Col className="mt-3" md={4}>
                    <MentorCard />
                </Col>
            </Row>
        </>
    )
}

export default Mentors


const MentorCard = () => {
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&::before, &::after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
            "&::before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme?.palette?.getContrastText(theme.palette.primary.main)
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            "&::after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme?.palette?.getContrastText(theme.palette.primary.main)
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
        },
    }));

    return (
        <div className="card p-3 rounded-3">
            <div className="d-flex gap-2">
                <div className="user_image">
                    <img
                        className="rounded-circle"
                        style={{ width: "120px", height: "120px" }}
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                        alt=""
                    />
                </div>
                <div className="d-flex flex-column">
                    <h4 className="mb-1">John Denial</h4>
                    <span>
                        <LuBook /> IIT Preparation Crash Course
                    </span>
                    <span>
                        <MdOutlineReviews /> 39 Review ( 4.7 )
                    </span>
                    <span>
                        <RiGraduationCapLine /> 62 Students
                    </span>
                    <span></span>
                </div>
            </div>
            <p className="m-0 mt-3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum
                libero error perferendis ipsam laudantium at debitis nesciunt eius saepe
                doloremque incidunt tempora .
            </p>

            <div className="heart">
                <FormControlLabel
                    control={<Android12Switch defaultChecked />}
                    label={""}
                />
            </div>
        </div>
    );
};