import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row } from "react-bootstrap";
import { MdFilterAltOff } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Card1 from "../../ParentPages/parent/Card1";
import card1 from "../../assets/parent/card1.png";
import { FaRegCalendarTimes } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { IoIosHeart } from "react-icons/io";
import { IoStarSharp } from "react-icons/io5";

import Img from "../../assets/parent/card3.png";

const SafetyPage = (props) => {
  const { history } = props;
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}

      <Row className="mt-3">
        <h5>Warning words</h5>
        <Col md={12} className="mt-3">
          <div className="shadow pb-5 border rounded-4 bg-white p-4">
            <h6 className="mb-5">
              Stupid, Stupid, Stupid, Stupid, Stupid, Stupid, Stupid,
              Stupid, Stupid, Stupid, Stupid,{" "}
            </h6>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6} className="mt-3">
          <h6 className="fw-semibold">All classes or courses</h6>
        </Col>

        <Col
          className="mt-3 d-flex align-items-center gap-3 justify-content-end"
        >
          <p className="m-0">
           <span className="d-none d-md-inline"> Apply filters</span> <MdFilterAltOff className="text_yellow ms-1" />
          </p>
          <div className="d-flex align-items-center px-3 border rounded-3 py-2">
            <input
              type="text"
              placeholder="Search..."
              className="bg-transparent border-0 "
            />
            <FiSearch className="text_yellow ms-5 fs-5" />
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
        <Col md={4}>
          <Card1
            img={card1}
            head={"Learn Mandarin - Beginner Level"}
            text1Icons={
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
            }
            text1={"18 Jul 2023, 6 pm"}
            text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
            text2={"26 students enrolled"}
            bottomText1Icon={<IoStarSharp className="text_yellow me-2 fs-6" />}
            bottomText1={"4.3"}
            bottomText2={"₹ 1299"}
            heart={<IoIosHeart className="text-danger fs-4" />}
          />
        </Col>
      </Row>

      <Row className="mt-5 px-4">
        <Col md={6}>
          <h6 className="m-0">Recording</h6>
        </Col>
        <Col md={6}>
          <h6 className="m-0">Transcript</h6>
        </Col>
      </Row>

      <Row className="mt-4 py-3 px-2 mx-4" style={{ border: "2px solid #FFD32B" }}>
        <Col md={6}>
          <img src={Img} alt="" className="img-fluid" />
        </Col>
        <Col md={6}>
          <p className="m-0">
            Lorem ipsum dolor sit amet consectetur. Tellus venenatis senectus a
            lectus risus. Tortor venenatis dictum netus quisque ultrices
            venenatis. Sed netus cras id posuere feugiat rhoncus nibh amet.
            Tellus imperdiet lectus suspendisse amet ipsum dictum aenean. Felis
            tellus in leo volutpat mattis adipiscing. Sit ut diam elit mauris.
            Posuere commodo nisl faucibus eros id pretium duis tincidunt.
            Hendrerit cursus morbi dolor at pellentesque congue sagittis lacus.
            Faucibus dui ullamcorper nulla fringilla arcu pellentesque
            pellentesque enim duis....View More
          </p>
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default SafetyPage;
