import React, { useEffect, useRef, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button, Col, Container, Row } from "react-bootstrap";
import Dropdown from "../courses/Dropdown";
import { IoIosHeart, IoMdArrowUp } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa6";
import Card3 from "../parent/Card3";
import card3 from "../../assets/parent/card3.png";
import SimplePeer from "simple-peer";
import styled from "styled-components";
import { FaAngleLeft, FaRegCalendarTimes } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";
import Card1 from "../parent/Card1";
import card1 from "../../assets/parent/card1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import ParentFooter from "../../components/ParentFooter";
const GroupLivePage = (props) => {
  const { history } = props;
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownChange = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  // const [isMeetingStarted, setIsMeetingStarted] = useState(false);
  // const [peerConnections, setPeerConnections] = useState([]);
  // const videoRef = useRef();

  // useEffect(() => {
  //   if (isMeetingStarted) {
  //     initializePeerConnection();
  //   }
  // }, [isMeetingStarted]);

  // const startMeeting = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: true,
  //       audio: true,
  //     });

  //     // Display user's video on the virtual screen
  //     if (videoRef.current) {
  //       videoRef.current.srcObject = stream;
  //     }

  //     setIsMeetingStarted(true);
  //   } catch (error) {
  //     console.error("Error starting the meeting:", error);
  //   }
  // };

  // const initializePeerConnection = () => {
  //   const peer = new SimplePeer({
  //     initiator: true,
  //     stream: videoRef.current.srcObject,
  //   });

  //   peer.on("stream", (remoteStream) => {
  //     const newVideoElement = document.createElement("video");
  //     newVideoElement.srcObject = remoteStream;
  //     newVideoElement.autoplay = true;
  //     newVideoElement.playsInline = true;

  //     videoRef.current.parentElement.appendChild(newVideoElement);
  //   });

  //   setPeerConnections((prevConnections) => [...prevConnections, peer]);
  // };
  return (
    <OftadehLayout>
      <h3>Group Live</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Row className="mt-3 pe-5">
        <Col md={6} className="mt-4">
          <Row className="d-flex align-items-center">
            <Col>
              {" "}
              <Dropdown
                head={"Sorting"}
                lis={[
                  {
                    name: "Active",
                    id: 1,
                  },
                  {
                    name: "Recent",
                    id: 2,
                  },
                  {
                    name: "Up Comming",
                    id: 3,
                  },
                ]}
                active={activeDropdown === "dropdown-sorting"}
                onToggle={() => handleDropdownChange("dropdown-sorting")}
              />
            </Col>
            <Col>
              <p className="m-0 fw-bold text_yellow d-flex align-items-center gap-2">
                Latest <IoMdArrowUp className="fs-6" />
              </p>
            </Col>
          </Row>
        </Col>
        <Col
          md={6}
          className="mt-4 d-flex align-items-center justify-content-end"
        >
          {/* <button className="py-2 px-4 bg-yellow text-black fw-semibold rounded-4 border-0">
            Go to live
          </button> */}
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h3 className="mb-4">Active</h3>
        </Col>
      </Row>

      <Row className="mt-3 pe-5">
        <Col md={6}>
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            // view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
          />
        </Col>
        <Col md={6}>
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            // view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h3 className="mb-4">Up Comming</h3>
        </Col>
      </Row>

      <Row className="mt-3 pe-5">
        <Col md={6}>
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            // view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
          />
        </Col>
        <Col md={6}>
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            // view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
          />
        </Col>
      </Row>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .input_parent {
    border: 2px solid #e5e5e5;
    width: fit-content;
    border-radius: 100px;

    input {
      width: 350px;
      background: transparent;
    }

    button {
      background: var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: none;
    }
  }
  .courses_right_arrow1,
  .courses_left_arrow1,
  .courses_right_arrow2,
  .courses_left_arrow2,
  .courses_right_arrow3,
  .courses_left_arrow3,
  .courses_right_arrow4,
  .courses_left_arrow4,
  .courses_right_arrow5,
  .courses_left_arrow5,
  .courses_right_arrow6,
  .courses_left_arrow6,
  .courses_right_arrow7,
  .courses_left_arrow7 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .courses_right_arrow1,
  .courses_right_arrow2,
  .courses_right_arrow3,
  .courses_right_arrow4,
  .courses_right_arrow5,
  .courses_right_arrow6,
  .courses_right_arrow7 {
    right: 5%;
  }
  .courses_left_arrow1,
  .courses_left_arrow2,
  .courses_left_arrow3,
  .courses_left_arrow4,
  .courses_left_arrow5,
  .courses_left_arrow6,
  .courses_left_arrow7 {
    left: -1%;
  }
`;

export default GroupLivePage;
