import React, { useState } from 'react'
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { MdCastForEducation, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { IoCalendarClearOutline, IoStarSharp } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import screenShot from "../../assets/online-tutorials-concept_52683-37480.avif";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaChalkboardUser } from 'react-icons/fa6';
import { GiCycle } from 'react-icons/gi';
import { FaCaretDown, FaRegStar, FaStar } from 'react-icons/fa';
import { FcRating } from 'react-icons/fc';
import { TiArrowSortedUp } from 'react-icons/ti';
function CourseProgress() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // This will navigate the user to the previous page
    };

    const [isLecturesOpen, setIsLecturesOpen] = useState(false);

    return (
        <>
            <OftadehLayout>
                <Container className="p-3">
                    <button onClick={handleGoBack} className='new-button mb-3'> <IoMdArrowRoundBack size={22} /> back</button>
                    <img
                        width={"100%"}
                        style={{ maxHeight: "50vh", objectFit: "cover" }}
                        height={"100%"}
                        className="rounded-3"
                        src={
                            "https://themegenix.com/demo/skillgro/assets/img/courses/courses_details.jpg"
                        }
                        alt=""
                    />
                    <h6 className='mt-3 text-bold'>This is Course Name.</h6>
                    <div className="d-flex align-items-center mt-3">
                        <img
                            src={screenShot}
                            className="border border-warning"
                            width={"60px"}
                            height={"60px"}
                            style={{ borderRadius: "50%" }}
                            alt=""
                        />
                        <span className="teacher-name ms-3">
                            <b>By John Smith</b>
                            <br />
                            <b><IoStarSharp className="text_yellow me-2 fs-6" /> 4.5</b>
                            <br />
                            <Link to="#">
                                <span className="text-decoration-underline text-primary">
                                    View Profile
                                </span>
                            </Link>
                        </span>
                        <div className="d-block ms-auto">
                            <span className="class-room-dated-flex align-items-center justify-content-center ms-3">
                                <IoCalendarClearOutline size={22} /> &nbsp;{" "}
                                <span>27 Jan 2022</span>
                            </span>
                        </div>
                    </div>
                </Container>
                <Container className='px-3 py-3 mt-4 shadow rounded-3 border'>
                    <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                        <span>
                            <FcRating size={22} /> &nbsp; <b>Your Rating</b>
                        </span>{" "}
                        <div className=''>
                            <FaStar color='#FFC224' /> &nbsp;
                            <FaStar color='#FFC224' /> &nbsp;
                            <FaStar color='#FFC224' /> &nbsp;
                            <FaStar color='#FFC224' /> &nbsp;
                            <FaRegStar />
                        </div>
                    </span>
                    <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                        <span>
                            <GiCycle size={22} /> &nbsp; <b>Cycle</b>
                        </span>{" "}
                        <b>2</b>
                    </span>
                    <span className="">
                        <div onClick={() => setIsLecturesOpen(!isLecturesOpen)} className="cursor-pointer d-flex align-items-center justify-content-between py-3 border-bottom">
                            <span>
                                <FaChalkboardUser size={22} /> &nbsp; <b>Lectures</b>
                            </span>{" "}
                            <b>12/32 &nbsp; {isLecturesOpen ? <TiArrowSortedUp size={22} /> : <FaCaretDown size={22} />} </b>
                        </div>
                        {
                            isLecturesOpen && (
                                <>
                                    <div className="p-0 m-o border mt-3">
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                            <div className="d-flex align-content-center justify-content-center gap-3">
                                                <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 11</h5>
                                            </div>
                                            <h6>12 Jan 2023</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                            <div className="d-flex align-content-center justify-content-center gap-3">
                                                <h5> <MdKeyboardArrowUp size={32} /> Class Topic Name - 10</h5>
                                            </div>
                                            <h6>12 Jan 2023</h6>
                                        </div>
                                        <div className="px-4 d-flex align-items-start justify-content-between flex-column py-3 border-bottom">
                                            <span>
                                                <b>Teacher Feedback</b>
                                            </span>{" "}
                                            <br />
                                            <div>
                                                <FaStar color='#FFC224' /> &nbsp;
                                                <FaStar color='#FFC224' /> &nbsp;
                                                <FaStar color='#FFC224' /> &nbsp;
                                                <FaStar color='#FFC224' /> &nbsp;
                                                <FaRegStar />
                                                &nbsp;
                                                <span className="ml-2">4 stars</span>
                                            </div>
                                            <p className='mt-2'>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor ad porro eos reiciendis similique quas accusantium, rerum ducimus veritatis neque? Necessitatibus consequuntur excepturi, exercitationem temporibus ex voluptatem obcaecati ducimus amet quod facere non accusamus quaerat fuga a porro maiores laudantium.
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                            <div className="d-flex align-content-center justify-content-center gap-3">
                                                <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 10</h5>
                                            </div>
                                            <h6>10 Jan 2023</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                            <div className="d-flex align-content-center justify-content-center gap-3">
                                                <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 9</h5>
                                            </div>
                                            <h6>09 Jan 2023</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                            <div className="d-flex align-content-center justify-content-center gap-3">
                                                <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 8</h5>
                                            </div>
                                            <h6>08 Jan 2023</h6>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </span>
                    <Col className="mt-3 border-bottom pb-3">
                        <span className="mt-3 mb-2">
                            <b>Course Completed</b>
                        </span>
                        <ProgressBar className="mt-2" now={90} label={`${90}%`} />
                    </Col>
                </Container>
            </OftadehLayout>
        </>
    )
}

export default CourseProgress