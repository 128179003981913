import PtaMeeting from "./PtaMeeting.jsx";

export const PtaMeetingPageConfig = {
  routes: [
    {
      path: "/teacher/pta/meeting",
      exact: true,
      component: PtaMeeting,
    },
  ],
};

