import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Dropdown } from "react-bootstrap";
import { Outlet, Link } from "react-router-dom";
import { FaChevronDown, FaBars, FaRegEdit } from "react-icons/fa";
import { IoIosWallet, IoMdClose } from "react-icons/io";
import StudentIcon from "@material-ui/icons/EmojiPeople";
import {
  AiOutlineLogout,
  AiOutlineUserAdd,
  AiOutlineHome,
} from "react-icons/ai";
import {
  MdCircleNotifications,
  MdDelete,
  MdManageHistory,
  MdNotificationsActive,
  MdOutlineHelp,
  MdOutlineNoteAlt,
} from "react-icons/md";
import { BiSolidContact } from "react-icons/bi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { TbBrandBlogger } from "react-icons/tb";
import { TbPasswordUser } from "react-icons/tb";
import {
  IoChatbubbleEllipsesOutline,
  IoDocumentText,
  IoLogOut,
} from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";
import { FaKeycdn } from "react-icons/fa";
import { SiOpenaccess } from "react-icons/si";
import { FaCcAmazonPay } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { MdBabyChangingStation } from "react-icons/md";
import { IoAnalytics } from "react-icons/io5";
import { SiQuantconnect } from "react-icons/si";
import { SiSpringsecurity } from "react-icons/si";
import { FaChalkboardTeacher } from "react-icons/fa";
import { CgProfile, CgWebsite } from "react-icons/cg";
import { HiLightBulb, HiOutlineSupport } from "react-icons/hi";
import { FaQuestion } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { FaGift } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";

import "../../assets/css/Admin/SuperAdminDashboard.css";
import "../../assets/css/Admin/AdminDashboard.css";
import { toast } from "react-toastify";
import { FaMapPin } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { removeToken, storeRole, storeToken } from "../../slices/tokenSlice";

export default function AdminPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);
  const role = useSelector((state) => state.token.role);

  useEffect(() => {
    if (!token && role !== "admin") {
      return navigate("/parent/sign-in");
    }
  }, [token, role]);

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      dispatch(storeToken(token));
      dispatch(storeRole("admin"));
    }
  }, []);

  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown");
    var flag = 1;
    dropdown.forEach((item) => {
      item.addEventListener("click", function () {
        if (flag === 1) {
          item.classList.add("active");
          flag = 0;
        } else {
          item.classList.remove("active");
          flag = 1;
        }
      });
    });
  }, []);

  useEffect(() => {
    var sidebar = document.querySelector(".sidebar");
    var flag2 = 0;
    const menu = document.querySelector(".bx-menu");
    menu.addEventListener("click", function () {
      if (flag2 === 0) {
        sidebar.classList.add("close");
        flag2 = 1;
      } else {
        sidebar.classList.remove("close");
        flag2 = 0;
      }
    });
  }, []);

  function logoutHandel() {
    localStorage.removeItem("adminToken");
    dispatch(removeToken());
    sessionStorage.removeItem("adminToken");
    toast.success("Logout successful");
    navigate("/");
  }

  const routesAll = [
    {
      name: "Home",
      path: "/",
      icon: <AiOutlineHome />,
    },
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      icon: <TbDeviceAnalytics />,
    },
    {
      name: "Activity Log",
      path: "/admin/activity-log",
      icon: <TbPasswordUser />,
      item: "group",
      child: [
        {
          name: "Instructor Suport",
          path: "/admin/instructor-suport",
          icon: <HiOutlineSupport />,
        },
      ],
    },
    {
      name: "Payments",
      icon: <RiSecurePaymentLine />,
      item: "group",
      child: [
        {
          name: "Parent Payment",
          path: "/admin/parent-payment",
          icon: <RiSecurePaymentLine />,
        },
        {
          name: "Teacher Payment",
          path: "/admin/teacher-payment",
          icon: <SiOpenaccess />,
        },
        // {
        //   name: "Payment",
        //   path: "/admin/payment",
        //   icon: <FaCcAmazonPay />,
        // },
      ],
    },
    {
      name: "Blogs",
      icon: <TbBrandBlogger />,
      item: "group",
      child: [
        {
          name: "Add Blog",
          path: "/admin/AdminBlog",
          icon: <TbBrandBlogger />,
        },
        {
          name: "Blog Request",
          path: "/admin/blog/request",
          icon: <TbBrandBlogger />,
        },
        {
          name: "All Blogs",
          path: "/admin/blog/all",
          icon: <HiOutlineSupport />,
        },
      ],
    },
    {
      name: "Manage Website",
      icon: <CgWebsite />,
      item: "group",
      child: [
        {
          name: "Edit Website",
          path: "/admin/edit-website",
          icon: <CgWebsite />,
        },
        {
          name: "Pin Post",
          path: "/admin/pin-post",
          icon: <FaMapPin />,
        },
      ],
    },
    {
      name: "Employee",
      icon: <MdManageAccounts />,
      item: "group",
      child: [
        {
          name: "Employee",
          path: "/admin/employee",
          icon: <TbBrandBlogger />,
        },
        {
          name: "Employee Management",
          path: "/admin/employee-managment",
          icon: <MdManageAccounts />,
        },
      ],
    },
    {
      name: "Demos & Classes",
      icon: <TbBrandBlogger />,
      item: "group",
      child: [
        {
          name: "Manual Demos",
          path: "/admin/manual-demos",
          icon: <TbBrandBlogger />,
        },
        {
          name: "All Classes",
          path: "/admin/all-classes",
          icon: <MdManageAccounts />,
        },
      ],
    },
    {
      name: "Learniee Mall",
      path: "/admin/learniee-mall",
      icon: <StudentIcon />,
    },
    {
      name: "Parent Management",
      path: "/admin/parent-management",
      icon: <HiOutlineSupport />,
    },
    // {
    //   name: "Instructor Suport",
    //   path: "/admin/instructor-suport",
    //   icon: <HiOutlineSupport />,
    // },
    {
      // name: "Teaching T&C",
      name: "FAQ'S",
      path: "/admin/teaching-faq",
      icon: <FaQuestion />,
    },
    {
      name: "Password Manager",
      path: "/admin/password-manager",
      icon: <TbPasswordUser />,
    },
    {
      name: "Company Documents",
      path: "/admin/company-documents",
      icon: <IoDocumentText />,
    },

    // {
    //   name: "Key Responsiblity",
    //   path: "/admin/key-responsiblity",
    //   icon: <FaKeycdn />,
    // },
    // {
    //   name: "Authorise",
    //   path: "/admin/authorize",
    //   icon: <SiOpenaccess />,
    // },
    {
      name: "Gift",
      path: "/admin/gift",
      icon: <FaGift />,
    },
    // {
    //   name: "Allocate Task",
    //   path: "/admin/allocate-task",
    //   icon: <MdPayments />,
    // },
    {
      name: "Project Details",
      path: "/admin/project-details",
      icon: <TbListDetails />,
    },
    {
      name: "Task Status",
      path: "/admin/task-status",
      icon: <MdBabyChangingStation />,
    },
    {
      name: "Operations Analytics",
      path: "/admin/operations-analytics",
      icon: <IoAnalytics />,
    },
    {
      name: "Liquidity",
      path: "/admin/liquidity",
      icon: <SiQuantconnect />,
    },
    {
      name: "Security",
      path: "/admin/Security",
      icon: <SiSpringsecurity />,
    },
    {
      name: "Information Technology",
      path: "/admin/information-technology",
      icon: <FaChalkboardTeacher />,
    },
    {
      name: "Teacher Verification",
      path: "/admin/teacher-verification",
      icon: <FaChalkboardTeacher />,
    },
    {
      name: "All Newsletters",
      path: "/admin/all-newsletters",
      icon: <IoAnalytics />,
    },
    {
      name: "Get A Consultation",
      path: "/admin/get-a-consultation",
      icon: <TbPasswordUser />,
    },
    {
      name: "All Demos",
      path: "/admin/all-demos",
      icon: <TbBrandBlogger />,
    },
    {
      name: "History",
      path: "/admin/history",
      icon: <FaHistory />,
    },
    {
      name: "Suggestion",
      path: "/admin/suggestion",
      icon: <HiLightBulb />,
    },
    {
      name: "Chat",
      path: "/admin/chat",
      icon: <IoChatbubbleEllipsesOutline />,
    },
    {
      name: "Chat Report",
      path: "/admin/chat-report",
      icon: <IoChatbubbleEllipsesOutline />,
    },
    {
      name: "Access Operations",
      path: "/operation/dashboard",
      icon: <SiOpenaccess />,
    },
    {
      name: "Account Deletion Requests",
      path: "/admin/account-deletion-requests",
      icon: <MdDelete />,
    },
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <div className="admin_dashboard">
        <div className={"sidebar"}>
          <Link to="/" className="logo-box" style={{ textDecoration: "none" }}>
            <div className="logo-name">
              <h6
                style={{
                  color: "#333",
                  fontFamily: "poppins",
                  fontWeight: "bold",
                  fontSize: 25,
                  textAlign: "center",
                  marginTop: 30,
                }}
              >
                Super Admin Dashboard
              </h6>
            </div>
          </Link>
          <ul className="sidebar-list">
            {/* all new routes goose here */}

            {routesAll.map((route, index) =>
              route.item === "group" ? (
                <li
                  className="dropdown"
                  style={{ overflow: "hidden" }}
                  key={index}
                >
                  <div className="title">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <i className="bx bx-book-alt text-dark">{route.icon}</i>
                      <span className="name">{route.name}</span>
                    </div>
                    <i className="bx bxs-chevron-down bx-none-width text-dark">
                      <FaChevronDown size={18} />
                    </i>
                  </div>
                  <div className="submenu">
                    {route.child.map((submenu, subIndex) => (
                      <Link
                        to={submenu.path}
                        className="link submenu-class"
                        style={{ textDecoration: "none" }}
                        key={subIndex}
                      >
                        {submenu.name}
                      </Link>
                    ))}
                  </div>
                </li>
              ) : (
                <li key={index}>
                  <Link
                    to={route.path}
                    className="link admin-link-hover-none"
                    style={{ textDecoration: "none", width: "100%" }}
                  >
                    <div
                      className="title d-flex align-items-center justify-content-start bg-transparent"
                      style={{ gap: "10px", height: "8vh" }}
                    >
                      <i className="bx bx-grid-alt">{route.icon}</i>
                      <span className="name">{route.name}</span>
                    </div>
                  </Link>
                </li>
              )
            )}

            <li>
              <div className="title">
                <Link
                  to="/operation/dashboard"
                  className="link"
                  style={{ textDecoration: "none" }}
                >
                  <i className="bx bx-grid-alt">
                    <MdManageHistory />
                  </i>
                  <span className="name">Manage Operation</span>
                </Link>
              </div>
            </li>

            {/* all function routes goose here */}

            <li>
              <div className="title">
                <Link
                  to="/"
                  className="link"
                  style={{ textDecoration: "none" }}
                  onClick={() => logoutHandel()}
                >
                  <i className="bx bx-grid-alt">
                    <AiOutlineLogout />
                  </i>
                  <span className="name">Log out</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
        <section className="home">
          <div className="toggle-sidebar bg-white d-flex align-items-center shadow">
            <FaBars
              className={"bx bx-menu"}
              style={{
                width: 30,
                height: 30,
                marginLeft: 10,
                color: "#000",
              }}
            />

            <Form className="mt-3 ms-3">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control type="text" placeholder="Search..." />
              </Form.Group>
            </Form>

            <div className="d-flex h-100 w-100 justify-content-end align-items-center gap-3">
              <MdOutlineHelp className="fs-3" style={{ cursor: "pointer" }} />
              <MdCircleNotifications
                className="fs-3"
                onClick={() => setIsDrawerOpen(true)}
                style={{ cursor: "pointer" }}
              />

              <Dropdown
                className="admin_pro "
                style={{ border: "none !important" }}
              >
                <Dropdown.Toggle
                  variant=""
                  className="d-flex align-items-center"
                  id="dropdown-basic"
                  style={{ backgroundImage: "none", outline: "none" }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: "50%",
                      backgroundColor: "#FD7D72",
                    }}
                  >
                    <img
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <CgProfile className="me-2 text-secondary" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    {" "}
                    <IoIosWallet className="me-2 text-secondary" />
                    Wallet
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => logoutHandel()}>
                    {" "}
                    <IoLogOut className="me-2 text-secondary" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Container style={{ padding: "20px" }} fluid>
            <Row>
              <Col md={12}>
                <Outlet />
              </Col>
            </Row>
          </Container>
        </section>
        <div
          className={`rightdrawer-admin-panel ${isDrawerOpen ? "open" : ""}`}
        >
          <div className="inner-notification-drawer">
            <div
              className="close-btn-notification"
              onClick={() => setIsDrawerOpen(false)}
            >
              <IoMdClose style={{ cursor: "pointer" }} size={24} />
            </div>
            <center>
              <h4>Notifications</h4>
            </center>
            <hr />
            {/* Notification List */}
            <div className="notification-box">
              <MdNotificationsActive size={28} />
              <div
                className="details-notification-admin"
                style={{ lineHeight: "4px" }}
              >
                <h6>Incoming Notification</h6>
                <p style={{ fontSize: "13px" }}>12 Jan 2022</p>
              </div>
            </div>
            <div className="notification-box">
              <MdNotificationsActive size={28} />
              <div
                className="details-notification-admin"
                style={{ lineHeight: "4px" }}
              >
                <h6>Incoming Notification</h6>
                <p style={{ fontSize: "13px" }}>12 Jan 2022</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
