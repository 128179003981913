import React from 'react'
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from 'react-bootstrap';
function Attendance() {
    return (
        <AccountLayout>
            <Row className="mt-3 mb-3">
                <h5>Employee Attendance</h5>
            </Row>
            <Row className="mt-3 mb-3">
                <Col md={4} className="mt-3">
                    <Form.Group controlId="nameInput">
                        <Form.Control
                            className="shadow rounded-3"
                            type="text"
                            placeholder="Search by I'D"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                    <Form.Group controlId="nameInput">
                        <Form.Control
                            className="shadow rounded-3"
                            type="text"
                            placeholder="Search by Name"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                    <Form.Group controlId="nameInput">
                        <Form.Control
                            className="shadow rounded-3"
                            type="text"
                            placeholder="Search by Email"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                    <Form.Group controlId="nameInput">
                        <Form.Control className="shadow rounded-3" type="date" />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="shadow rounded-3 mt-3">
                            <option selected disabled>
                                select status
                            </option>
                            <option value={"1"}>Active</option>
                            <option value={"2"}>Inactive</option>
                            <option value={"2"}>Block</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="shadow rounded-3 mt-3">
                            <option selected disabled>
                                select live status
                            </option>
                            <option value={"1"}>Active</option>
                            <option value={"2"}>Inactive</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Employee Name</th>
                        <th>Present full day</th>
                        <th>Present half day</th>
                        <th>Absent</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                    <tr >
                        <td>employee#001</td>
                        <td>John Smith</td>
                        <td>
                            14
                        </td>
                        <td>
                            2
                        </td>
                        <td>
                            10
                        </td>
                        <td>Jan 2024 - Feb 2024</td>
                    </tr>
                </tbody>
            </Table>
        </AccountLayout>
    )
}

export default Attendance