import React from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { PiMicrosoftWordLogoFill } from "react-icons/pi";
import { IoCloudUploadOutline } from "react-icons/io5";
import { BsQrCodeScan } from "react-icons/bs";

import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button } from '@mui/material';
import { LuBoxSelect } from 'react-icons/lu';
import { FiEdit3 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { IoMdCreate, IoMdDownload, IoMdMore, IoMdShare, IoMdTrash } from 'react-icons/io';
import { FaFolder, FaPlus } from 'react-icons/fa6';
function CompanyDocument(props) {
    const { history } = props;
    const cardStyle = {
        minHeight: '20vh',
    };
    const mediaItems = [
        {
            id: 1,
            name: 'HC Verma',
            type: 'folder'
        },
        {
            id: 2,
            name: 'DC Pandey',
            type: 'folder'
        },
        {
            id: 3,
            name: 'HC Verma',
            type: 'folder'
        },
        {
            id: 4,
            name: 'DC Pandey',
            type: 'folder'
        },
    ];
    return (
        <>
            <h5>Company Document</h5>
            {/* <OperationLocation path={history} /> */}
            <Col className='d-flex align-items-center justify-content-start shadow rounded-5 border  p-2 ps-4'>
                <FaSearch />
                <input type="text" className='border-0 ms-3' placeholder='Search here...' style={{ outline: 'none' }} />
            </Col>

            <Col className='d-flex align-items-center justify-content-start p-0 mt-3 mb-3' style={{borderBottom:'1px solid grey',gap:'20px'}}>
                <div className="p-0" style={{width:'fit-content',cursor:'pointer',height:'100%',borderBottom:'3px solid black'}}> <h5>Files</h5> </div>
                <div className="p-0" style={{width:'fit-content',cursor:'pointer',height:'100%'}}> <h5>Activities</h5> </div>
            </Col>

            <Row className='mt-3 mb-3'>
                <Col md={6}>
                    <h6>Folders</h6>
                </Col>
                <Col md={6} className='d-flex align-items-center justify-content-end'>
                    <Button className='black-outline-button rounded-5' variant="outlined" startIcon={<LuBoxSelect />}> Select </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<FiEdit3 />}> Edit </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<MdDelete />}> Delete </Button>
                </Col>
            </Row>

            <Row className='mt-3 mb-3'>
            {mediaItems.map((media, index) => (
                    <>
                        <Col md={4} key={index} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFolder />{media.name}</div>
                                <div className="more-option-icon">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" toggleIcon={null} className="custom-dropdown-toggle">
                                            <IoMdMore />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <IoMdDownload color="#007BFF" />
                                                &nbsp; Download
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdCreate color="#28A745" />
                                                &nbsp; Rename
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdShare color="#17A2B8" />
                                                &nbsp; Share
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdCreate color="#6610F2" />
                                                &nbsp; Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdTrash color="#DC3545" />
                                                &nbsp; Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </>
                ))}
            </Row>

            <Row className='mt-3 mb-3'><h6>Recent Documents</h6></Row>


            <Row className="mt-4 mb-4">
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <PiMicrosoftWordLogoFill color='blue' size={52} />
                            <h5 className="font-weight-bold">Book - 1</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <PiMicrosoftWordLogoFill color='blue' size={52} />
                            <h5 className="font-weight-bold">Book - 1</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <PiMicrosoftWordLogoFill color='blue' size={52} />
                            <h5 className="font-weight-bold">Book - 1</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <PiMicrosoftWordLogoFill color='blue' size={52} />
                            <h5 className="font-weight-bold">Book - 1</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <PiMicrosoftWordLogoFill color='blue' size={52} />
                            <h6 className="font-weight-bold">Book - 1</h6>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className='mt-3 mb-3'><h6>Scan Documents</h6></Row>


            <Row className="mt-4 mb-4">
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <IoCloudUploadOutline color='blue' size={52} />
                            <h5 className="font-weight-bold">Upload Documents</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-2">
                    <Card className="rounded-4 shadow text-center" style={cardStyle}>
                        <Card.Body className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px' }}>
                            <BsQrCodeScan color='blue' size={52} />
                            <h5 className="font-weight-bold">Scan Document</h5>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mt-5">
                    <Button className='black-outline-button' variant="outlined">Save</Button>
                </Col>
            </Row>

        </>
    )
}

export default CompanyDocument