import React from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import { SlCalender } from "react-icons/sl";
import { GoStopwatch } from "react-icons/go";
import { Chip, IconButton } from "@mui/material";
import { VscLiveShare } from "react-icons/vsc";
import { CiHeart } from "react-icons/ci";
import { AiOutlineFileDone } from "react-icons/ai";
import { FaUsers } from "react-icons/fa6";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { MdCastForEducation } from "react-icons/md";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import ParentFooter from "../../components/ParentFooter";

const screenShot = require("../../assets/online-tutorials-concept_52683-37480.avif");
const BlogCommentImg1 = require("../../assets/blog-comment-01.png");
function CoursesDetails(props) {
  const { history } = props;

  return (
    <OftadehLayout>
      <h3>Course Details</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Container className="p-3">
        <Row>
          <Col md={8}>
            <Col md={12}>
              <img
                width={"100%"}
                style={{ maxHeight: "50vh", objectFit: "cover" }}
                height={"100%"}
                className="rounded-3"
                src={screenShot}
                alt=""
              />
            </Col>
            <br />
            <Col>
              <h4>
                1:1 Tutor- Personalized Reading Intervention: A Multisensory,
                Comprehensive Reading Program for Struggling, Dyslexic or
                Emerging Readers Class StrongMinds for Kids by Teacher Maggi
              </h4>
            </Col>
            <br />
            <Row className="border border-2 rounded-3 p-3">
              <Col md={2} className="d-flex align-items-center">
                <img
                  src={screenShot}
                  className="border border-warning"
                  width={"60px"}
                  height={"60px"}
                  style={{ borderRadius: "50%" }}
                  alt=""
                />
              </Col>
              <Col md={10}>
                <h5>
                  John Smith <MdOutlineStarPurple500 color="#FFD846" /> (4.5){" "}
                </h5>
                <span>
                  Don't waste anymore time. Join a tutoring program especially
                  designed for your child by an experienced Reading Specialist.
                  Currently offering code FORKIDZ25 for $25 off!
                </span>
              </Col>
            </Row>
            <br />
            <Col>
              <h4>Class experience</h4>
            </Col>
            <hr />
            <Col>
              <MdCastForEducation size={32} />
              <h5>US Grade 1 - 5</h5>
            </Col>
            <br />
            <Col>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt
              amet, itaque voluptates libero recusandae expedita laborum.
              Deserunt rerum voluptatum error minus. Porro inventore distinctio
              rem nulla consequatur, quibusdam est voluptate.
            </Col>
            <Col>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis sed
              quod dignissimos adipisci minus blanditiis expedita magni quae
              inventore porro? Expedita, amet eum doloribus cum placeat sapiente
              corrupti earum repellat, blanditiis est tenetur molestias vitae
              explicabo rerum saepe reprehenderit aut totam ratione a nemo
              aliquam fugit delectus! Animi, culpa nobis!
            </Col>
            <br />
            <Col className="mt-5 bload_heading">2 Comments</Col>
            <Col className="p-2">
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Marrion Willsoriam
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
              <Col className="mt-3 blog-comment-user-box">
                <Row>
                  <Col md className="d-flex" style={{ gap: "10px" }}>
                    <div className="blog-comment-image">
                      <img src={BlogCommentImg1} alt="" />
                    </div>
                    <Col
                      className="d-flex align-items-start justify-content-center"
                      style={{ flexDirection: "column", gap: "0" }}
                    >
                      <span
                        style={{ height: "fit-content", fontWeight: "600" }}
                      >
                        Carolyn Wallace
                      </span>
                      <span style={{ height: "fit-content" }}>
                        January 27, 2023
                      </span>
                    </Col>
                  </Col>
                  <Col
                    md
                    className="d-flex align-items-center justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <span className="blog_comment_like_icon">
                      <BiLike />
                    </span>
                    <span className="blog_comment_like_icon">
                      <BiSolidDislike />
                    </span>
                  </Col>
                </Row>
                <Col className="mt-3 p-2">
                  Platea dictumst vestibulum rhoncus est pellentesque elit
                  ullamcorper dignissim cras. Vitae ultricies leo integer
                  malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                  Convallis a cras semper auctor neque vitae tempus. Mattis
                  molestie a iaculis at erat pellentesque adipiscing.
                </Col>
              </Col>
            </Col>
          </Col>
          <Col md={4}>
            <div
              className="border border-2 rounded-3"
              style={{ overflow: "hidden" }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "7vh", backgroundColor: "#FFD846" }}
              >
                <h5>1-ON-1 TUTORING</h5>
              </div>
              <div className="ps-3 p-2">
                <span className="d-flex align-items-center">
                  <h2>₹ 500</h2>&nbsp; weekly
                </span>
                <span className="d-flex">
                  {" "}
                  <SlCalender size={22} /> &nbsp; <b>1x week</b>{" "}
                </span>
                <span className="d-flex mt-3">
                  {" "}
                  <GoStopwatch size={22} /> &nbsp; <b>30 min</b>{" "}
                </span>
              </div>
              <hr />
              <div className="ps-3 p-2">
                <span className="d-flex">
                  {" "}
                  <AiOutlineFileDone size={22} /> &nbsp;{" "}
                  <b>140 hours classes.</b>{" "}
                </span>
                <span className="d-flex mt-3">
                  {" "}
                  <FaUsers size={22} /> &nbsp; <b>200+ Students</b>{" "}
                </span>
              </div>
              <hr />
              {/* all available time slots */}
              <div className="d-flex align-items-center justify-content-between ps-3 pe-3">
                <div className="left-box p-0">
                  <h6>Feb 26 – Apr 22</h6>
                  <p>Classroom opens: 10:30 PM</p>
                </div>
                <div className="right">
                  <Chip
                    style={{ cursor: "pointer" }}
                    label="Enroll"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between ps-3 pe-3">
                <div className="left-box p-0">
                  <h6>Feb 26 – Apr 22</h6>
                  <p>Classroom opens: 10:30 PM</p>
                </div>
                <div className="right">
                  <Chip
                    style={{ cursor: "pointer" }}
                    label="Enroll"
                    variant="outlined"
                  />
                </div>
              </div>
              <hr />
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "10px" }}
              >
                <IconButton aria-label="delete">
                  <CiHeart size={32} />
                </IconButton>
                {/* <Chip
                  label='Enroll'
                  className='bg-warning text-white border-0'
                  variant='outlined'
                /> */}
                <IconButton aria-label="delete">
                  <VscLiveShare size={32} />
                </IconButton>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default CoursesDetails;
