import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import "./Complain.css";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button, Chip } from "@mui/material";
import { Add } from "@material-ui/icons";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ProfilePic from "../../assets/parent/profile-user.jpg";
import ParentFooter from "../../components/ParentFooter";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";

function Complain() {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [complaints, setComplaints] = useState([]);
  const [complaintId, setComplaintId] = useState("");

  const token = useSelector((state) => state.token.value);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal1 = (id) => {
    setShowModal1(true);
    setComplaintId(id);
  };
  const handleCloseModal1 = () => setShowModal1(false);

  const handleRaiseComplaint = async () => {
    handleCloseModal();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("complaint_type", type);
    formData.append("description", description);
    formData.append("attachment", file);

    // send data to server with axios and token

    try {
      const response = await axios.post("/teacher/complaint", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        setComplaints([...complaints, response.data.data]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTitle("");
      setType("");
      setFile(null);
      setDescription("");
    }
  };

  useEffect(() => {
    const fetchComplaints = async () => {
      try {
        const response = await axios.get("/teacher/complaint", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response.data);

        if (response.data.res_type === "success") {
          setComplaints(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchComplaints();
  }, []);

  const [reply, setReply] = useState("");

  const handleAddReply = async () => {
    try {
      const response = await axios.patch(
        `teacher/complaint?complaint_id=${complaintId}`,
        {
          reply,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setReply("");
      handleCloseModal1();
    }
  };

  const handleCloseComplaint = async (id) => {
    try {
      const response = await axios.put(
        `teacher/complaint?complaint_id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TeacherLayout>
      <h3>Complain</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Col className="d-flex align-items-center justify-content-between">
        <Col md={6}>
          <Button
            variant="outlined"
            onClick={handleShowModal}
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              borderColor: "var(--color-primary)",
              color: "#fff",
            }}
            endIcon={<Add color="#fff" />}
          >
            {" "}
            Raise Complaint{" "}
          </Button>
        </Col>
        <Col className="d-flex align-items-center justify-content-end" md={6}>
          <Button
            variant="outlined"
            style={{
              borderColor: "black",
              borderRadius: "25px",
              color: "black",
            }}
            startIcon={<FilterAltOffIcon />}
          >
            {" "}
            Filter{" "}
          </Button>
        </Col>
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Raise Complaint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="complaintTitle">
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-2" controlId="dropdownForm">
            <Form.Select
              aria-label="Select complaint type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option>Select complaint type</option>
              <option value="Payment Related">Payment Related</option>
              <option value="Previous Class">Previous Class</option>
              <option value="Live Related">Live Related</option>
              <option value="Technical Issue">Technical Issue</option>
              <option value="Teacher Related">Teacher Related</option>
              <option value="Offer and Coupons">Offer and Coupons</option>
              <option value="Learniee Mall">Learniee Mall</option>
              <option value="General FAQ">General FAQ</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mt-3" controlId="complaintTitle">
            <Form.Control
              type="file"
              placeholder="upload file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Form.Group>
          <Form.Group controlId="complaintDescription" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger rounded-5 text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3 rounded-5"
            variant="outlined"
            onClick={handleRaiseComplaint}
          >
            Raise Complaint
          </Button>
        </Modal.Footer>
      </Modal>

      {complaints.map((c, i) => (
        <Col className="mt-3 rounded-2 p-2 m-0 n-box-shadow">
          <Row className="p-2 m-0">
            <Col
              md={4}
              className="d-flex align-items-center justify-content-start p-0 m-0"
              style={{ gap: "10px" }}
            >
              {" "}
              <div className="parent-complain-image">
                <img src={ProfilePic} alt="userImg" />{" "}
              </div>{" "}
              <h5>Cody Fisher</h5>{" "}
            </Col>
            <Col
              md={8}
              className="d-flex align-items-center justify-content-end parent-compaint-subject-heading-responsive"
              style={{ fontSize: "18px" }}
            >
              {c.title}
            </Col>
          </Row>
          <Col className="d-flex align-items-center justify-content-between p-2">
            <Col md={6}>{moment(c.createdAt).format("DD-MM-YYYY")}</Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              {c.status === "open" ? (
                <>
                  <Chip
                    label="Reply"
                    className="n-box-shadow me-2"
                    style={{
                      color: "white",
                      borderColor: "gray",
                      background: "gray",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShowModal1(c._id)}
                    color="primary"
                  />

                  <Chip
                    label="Close"
                    className="n-box-shadow"
                    style={{
                      color: "white",
                      borderColor: "yellow",
                      background: "red",
                      cursor: "pointer",
                    }}
                    color="primary"
                    onClick={() => handleCloseComplaint(c._id)}
                  />
                </>
              ) : c.status === "close" ? (
                <Chip
                  label="closed"
                  className="n-box-shadow"
                  style={{
                    color: "black",
                    borderColor: "gray",
                    background: "gray",
                    cursor: "pointer",
                  }}
                  color="primary"
                />
              ) : (
                <Chip
                  label="Open"
                  className="n-box-shadow"
                  style={{
                    color: "black",
                    borderColor: "var(--yellow-color)",
                    background: "var(--yellow-color)",
                    cursor: "pointer",
                  }}
                  color="primary"
                />
              )}
            </Col>
          </Col>
          <hr />
          <Col className="p-2">{c.description}</Col>
          <Col className="mt-3">
            <h6>Attachment</h6>
          </Col>
          <Row className="ps-3">
            <Col md={3} style={{ padding: "10px" }}>
              <div className="resorce-parent-item cursor-pointer">
                <div className="name">
                  <FaFilePdf /> HC Verma
                </div>
                <MdOutlineFileDownload />
              </div>
            </Col>
          </Row>
          {c.replies?.length > 0 &&
            c.replies.map((r, i) => (
              <>
                <hr />
                <Col className="p-2">
                  <h6>Reply {">"} </h6>
                  {r.reply}
                </Col>
              </>
            ))}
        </Col>
      ))}

      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="complaintDescription" className="mt-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              onChange={(e) => setReply(e.target.value)}
              value={reply}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger rounded-5 text-white border-0"
            variant="outlined"
            onClick={handleCloseModal1}
          >
            Close
          </Button>
          <Button
            className="ms-3 rounded-5"
            variant="outlined"
            onClick={handleAddReply}
          >
            Add Reply
          </Button>
        </Modal.Footer>
      </Modal>

      <ParentFooter />
    </TeacherLayout>
  );
}

export default Complain;
