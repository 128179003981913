import ManualPage from "./ManualPage";

export const ManualPageConfig = {
  routes: [
    {
      path: "/account/dashboard/manual",
      exact: true,
      component: ManualPage
    }
  ]
};
