import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Col, Row } from "react-bootstrap";
import { FaAngleDoubleRight } from "react-icons/fa";
import Footer from "../components/Footer";

const Security = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <PolicyContainer>
        <div className="hero px-5 pb-4">
          <h2 className=" fw-bold mb-3">Security Policy</h2>
          <p className="m-0">
            Our Privacy Policy forms part of and must be read in conjunction
            with, website Terms and Conditions. <br /> We reserve the right to
            change this Privacy Policy at any time.
          </p>
        </div>

        <Row className="mt-5 px-5">
          <Col>
            <h4>Learn about the company</h4>
            <p className="m-0">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <h4 className="mt-4 ">
              We collect the following types of personal information:
            </h4>
            <p className="m-0">
              In contrast, by utilizing our service, the website may ask you for
              some specific information that will allow the website to identify
              you or contact you. To determine purposes, further information may
              include:
            </p>
            <h4 className="mt-4">
              <FaAngleDoubleRight className="fs-4" /> Personal Data
            </h4>
            <p className="m-0">
              Whereas, by using our service, the website may ask you for some
              specific data that will allow the website to identify you or to
              get in touch with you. Additionally, in order to identify purposes
              information may include:
            </p>
            <p className="mt-3">- Your email address</p>
            <p>- Your first and last name</p>
            <p>- Your Phone number</p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
          </Col>
        </Row>
      </PolicyContainer>
      <Footer />
    </>
  );
};

const PolicyContainer = styled.div`
  overflow-x: hidden;
  h4 {
    font-weight: 600;
  }
  .hero {
    padding-top: 10vmax;
    height: fit-content;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }
`;

export default Security;
