import RefePreference from "./Preferences.jsx";

export const PreferencePageConfig = {
  routes: [
    {
      path: "/parent/preference",
      exact: true,
      component: RefePreference,
    },
  ],
};
