import { Button } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";
// const playVideo = require('../../assets/videos/pexels-ahmet-kurt-19922381 (Original).mp4');
function VideoModal({ showModal, handleClose }) {
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const videoId = "9je3jbgBYiY"; // Replace this with your actual YouTube video ID

  return (
    <Modal
      style={{ overflow: "hidden", zIndex: 99999 }}
      size="lg"
      centered
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body className="p-1">
        <YouTube className="rounded-2" videoId={videoId} opts={opts} />
        {/* <video style={{width:'100%',height:'100%'}} controls autoPlay muted loop src={playVideo}></video> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="border-0 bg-danger text-white me-3"
          variant="outlined"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VideoModal;
