import React from "react";
import { Navigate } from "react-router-dom";
import { ParentsFeesPageConfig } from "../AccoundPages/ParentsFees/ParentsFeesPageConfig";
import { PaymentPageConfig } from "../AccoundPages/payment/PaymentPageConfig";
import { BankDetailsPageConfig } from "../AccoundPages/BankDetails/BankDetailsPageConfig";
import { ParentsFeesWithIDPageConfig } from "../AccoundPages/ParentsFeesWithID/ParentsFeesWithIDPageConfig";
import { RefundPageConfig } from "../AccoundPages/Refund/RefundPageConfig";
import { CurrencyConversionPageConfig } from "../AccoundPages/CurrencyConversion/CurrencyConversionPageConfig";
import { PaymentGatewayPageConfig } from "../AccoundPages/PaymentGateway/PaymentGatewayPageConfig";
import { AdditionalLinksPageConfig } from "../AccoundPages/AdditionalLinks/AdditionalLinksPageConfig";
import { SalaryPaymentPageConfig } from "../AccoundPages/SalaryPayment/SalaryPaymentPageConfig";
import { PaymentLogsPageConfig } from "../AccoundPages/payment/PaymentLogsConfig";
import { UpcomingPaymentPageConfig } from "../AccoundPages/payment/UpcomingConfig";
import { QueryPageConfig } from "../AccoundPages/payment/QueryPageConfig";
import { OtherReqPageConfig } from "../AccoundPages/payment/OtherReqConfig";
import { CalculationPageConfig } from "../AccoundPages/calculation/CalculationPageConfig";
import { ChatPageConfig } from "../AccoundPages/chat/ChatPageConfig";
import { ManualPageConfig } from "../AccoundPages/manualPages/ManualPageConfig";
import { ClientAccountPageConfig } from "../AccoundPages/clientAccount/ClientAccountConfig";
import { ClientAccountDetailsPageConfig } from "../AccoundPages/clientAccount/AccountDetailsConfig";
import { NotificationPageConfig } from "../AccoundPages/notification/NotificationPageConfig";
import { IncomingPageConfig } from "../AccoundPages/payment/IncomingConfig";
import { DuePaymentPageConfig } from "../AccoundPages/payment/DuePaymentConfig";
import { DashboardPageConfig } from "../AccoundPages/Dashboard/DashboardPageConfig";
import { RefundPagePaymentConfig } from "../AccoundPages/payment/RefundPageConfig";
import { WalletPageConfig } from "../AccoundPages/wallet/walletPageConfig";
import { AccountPageConfig } from "../AccoundPages/Account/AccountPageConfig";
import { AppPaymentPageConfig } from "../AccoundPages/AppPayment/AppPaymentPageConfig";
import { ExpenseConfig } from "../AccoundPages/Expenses/ExpenseConfig";

const AccountRouteConfigs = [
  ...PaymentPageConfig.routes,
  ...PaymentLogsPageConfig.routes,
  ...UpcomingPaymentPageConfig.routes,
  ...QueryPageConfig.routes,
  ...OtherReqPageConfig.routes,
  ...CalculationPageConfig.routes,
  ...ChatPageConfig.routes,
  ...ManualPageConfig.routes,
  ...ClientAccountPageConfig.routes,
  ...ClientAccountDetailsPageConfig.routes,
  ...NotificationPageConfig.routes,
  ...IncomingPageConfig.routes,
  ...ParentsFeesPageConfig.routes,
  ...BankDetailsPageConfig.routes,
  ...ParentsFeesWithIDPageConfig.routes,
  ...RefundPageConfig.routes,
  ...CurrencyConversionPageConfig.routes,
  ...PaymentGatewayPageConfig.routes,
  ...AdditionalLinksPageConfig.routes,
  ...SalaryPaymentPageConfig.routes,
  ...DuePaymentPageConfig.routes,
  ...DashboardPageConfig.routes,
  ...RefundPagePaymentConfig.routes,
  ...WalletPageConfig.routes,
  ...AccountPageConfig.routes,
  ...AppPaymentPageConfig.routes,
  ...ExpenseConfig.routes,
];

const AccountRoutes = [
  ...AccountRouteConfigs,

  {
    element: <Navigate to="/ParentPages/errors/error-404" replace />,
  },
];

export default AccountRoutes;
