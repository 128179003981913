import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const user_1 = require("../../assets/user_1.jpg");

const HomeWorkPage = (props) => {
  const { history } = props;

  const navigate = useNavigate();

  const navigateToStudentHomeWork = () => {
    navigate("/operation/HomeWork/student/112121");
  };

  return (
    <OperationLayout>
      <h5>Home Work & Test</h5>
      {/* <OperationLocation path={history} /> */}

      <Row className="d-flex align-items-center justify-content-between">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select status
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>In Active</option>
              <option value={"2"}>Both</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formItemName">
            <Form.Control
              className="shadow rounded-3 mt-3"
              type="text"
              placeholder="Search By ID or Name..."
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <h5>Search Result ( 1 )</h5>
      </Row>

      <Container className="mt-3 mb-3 p-3 shadow rounded-3">
        <Row>
          <Col
            md={3}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              className="border border-dark"
              style={{ width: "120px", height: "120px", borderRadius: "50%" }}
              src={user_1}
              alt=""
              srcset=""
            />
          </Col>
          <Col md={9}>
            <Row>
              <Col>
                <h4>John Smith &nbsp;</h4>
                <h6>
                  <Badge bg="primary">Active</Badge>
                </h6>
              </Col>
              <Col>
                <b>parent id : </b> parent#001
                <br />
                <b>no. child : </b> 2
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <div className="d-flex align-items-center justify-content-start mt-2">
          <Container
            style={{ width: "80%" }}
            className="p-1 rounded-3 shadow border border-secondary"
          >
            <Row>
              <Col
                md={2}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  className="border border-dark"
                  style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                  src={user_1}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col md={10}>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center">
                      <h4 className="m-0">John Smith &nbsp;</h4>
                      <Badge bg="primary">Active</Badge>
                    </div>

                    <h6 className="mt-2">ChildId : child@4598j</h6>
                    <h6 className="d-flex align-items-center gap-3">
                      <span>Age : 14y</span>
                      <span>Grade : 7th</span>
                      <span>Board : CBSE</span>
                    </h6>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pe-2">
                    <b
                      onClick={navigateToStudentHomeWork}
                      className="me-3"
                      style={{ cursor: "pointer" }}
                    >
                      View H/W
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="d-flex align-items-center justify-content-start mt-3">
          <Container
            style={{ width: "80%" }}
            className="p-1 rounded-3 shadow border border-secondary"
          >
            <Row>
              <Col
                md={2}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  className="border border-dark"
                  style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                  src={user_1}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col md={10}>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center">
                      <h4 className="m-0">John Smith &nbsp;</h4>
                      <Badge bg="primary">Active</Badge>
                    </div>

                    <h6 className="mt-2">ChildId : child@4598j</h6>
                    <h6 className="d-flex align-items-center gap-3">
                      <span>Age : 14y</span>
                      <span>Grade : 7th</span>
                      <span>Board : CBSE</span>
                    </h6>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pe-2">
                    <b
                      onClick={navigateToStudentHomeWork}
                      className="me-3"
                      style={{ cursor: "pointer" }}
                    >
                      View H/W
                    </b>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </OperationLayout>
  );
};

export default HomeWorkPage;
