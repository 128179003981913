import FixedConstExpense from "./FixedConstExpense";
import VariableConstExpenses from "./VariableConstExpenses";

export const ExpenseConfig = {
  routes: [
    {
      path: "/account/fixed-const-expense",
      exact: true,
      component: FixedConstExpense,
    },
    {
      path: "/account/variable-const-expense",
      exact: true,
      component: VariableConstExpenses,
    },
  ],
};
