import ClientAccountPage from "./ClientAccountPage";
import ClientComplaint from "./ClientComplaint";
import TeacherComplaint from "./TeacherComplaint";

export const ClientAccountPageConfig = {
  routes: [
    {
      path: "/account/dashboard/client",
      exact: true,
      component: ClientAccountPage,
    },
    {
      path: "/account/client/complaint",
      exact: true,
      component: ClientComplaint,
    },
    {
      path: "/account/teacher/complaint",
      exact: true,
      component: TeacherComplaint,
    },
  ],
};
