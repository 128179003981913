import React, { useState } from "react";
import { Button } from "@mui/material";
import { Badge, Col, Form, Modal } from "react-bootstrap";

function TranscriptModal({ showModal, handleClose }) {
  // Dummy transcript data
  const transcriptData = [
    "User 1: Hello everyone, welcome to the meeting.",
    "User 2: Good morning, glad to be here.",
    "User 3: Hi everyone, hope you're all doing well.",
    "User 4: Hello, thank you for organizing this meeting.",
    "User 5: Hi, I'm excited to discuss the agenda items today.",
  ];

  const [showModalA, setShowModal] = useState(false);
  const [reportText, setReportText] = useState("");

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleReportTextChange = (e) => setReportText(e.target.value);

  const handleReportSubmit = () => {
    // Handle report submission here
    console.log("Report submitted:", reportText);
    // You can add your logic to handle the report submission
    // For now, let's just close the modal
    handleCloseModal();
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        style={{ zIndex: 1111 }}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {transcriptData.map((line, index) => (
            <div className="d-flex align-items-center" key={index}>{line} <h6><Badge onClick={handleShowModal} className="cursor-pointer" bg="danger">Reported</Badge></h6>  </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="border-danger text-white bg-danger"
            variant="outlined"
            onClick={handleClose}
          >
            Close
          </Button>
          {/* <Button
            className="border-warning bg-warning text-white ms-3"
            variant="outlined"
            onClick={handleShowModal}
          >
            Report
          </Button> */}

          <Button className="ms-3" variant="outlined" onClick={handleClose}>
            Download
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalA}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Report Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className="border border-0 border-bottom pb-3">
            <h5>Rohit Kumar</h5>
            <p>id#142512001</p>
            <hr />
            Report :-  <br />
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore reprehenderit fugit, aut recusandae impedit iure eius eligendi sed, nesciunt ex ducimus saepe. Impedit, nobis vel! Provident dolorum ad debitis sint?
          </Col>
          <Form.Group className="mt-" controlId="reportTextArea">
            <Form.Label>Edit Line Report:</Form.Label>
            <Form.Control as="textarea" placeholder="Report..." rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white border-0"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            variant="outlined"
            onClick={handleReportSubmit}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TranscriptModal;
