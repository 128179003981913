import TeacherWallet from "./WalletPage";

export const TeacherWalletPageConfig = {
  routes: [
    {
      path: "/teacher/TeacherWallet",
      exact: true,
      component: TeacherWallet
    }
  ]
};
