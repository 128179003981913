import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { useNavigate, useParams } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import { Table, Row, Col, Form } from "react-bootstrap";
import { FaClipboardCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MyAPI, truncateText } from "../../utils/MyAPI";

const AllTest = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const token = useSelector((state) => state.token.value);
  const [allTest, setAllTest] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [testsPerPage] = useState(10); // 10 records per page

  const fetchAllTest = async (token, course_id) => {
    try {
      setDataLoading(true);
      let res = await MyAPI.GET(
        `/teacher/mcq-test?course_id=${course_id}`,
        token
      );
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setAllTest(apiData);
      } else {
        toast.error(message || "Error fetching test.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (courseId && token) {
      fetchAllTest(token, courseId);
    }
  }, [token, courseId]);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options); // Format to day-month-year
  };

  const filteredTests = allTest.filter((test) =>
    test.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastTest = currentPage * testsPerPage;
  const indexOfFirstTest = indexOfLastTest - testsPerPage;
  const currentTests = filteredTests.slice(indexOfFirstTest, indexOfLastTest);
  const totalPages = Math.ceil(filteredTests?.length / testsPerPage);

  return (
    <TeacherLayout>
      <h3>All Test</h3>
      <Row className="mt-3">
        <Col md={3}>
          <Form.Select className="shadow rounded-5" aria-label="Select option">
            <option>Select</option>
            <option value="Mathematics">Mathematics</option>
            <option value="History">History</option>
            <option value="Physics">Physics</option>
          </Form.Select>
        </Col>
        <Col md={6}></Col>
        <Col md={3}>
          <Form.Group controlId="searchBox">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search by Title"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">Title</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Completed</th>
            <th className="text-white">Pending</th>
            <th className="text-white">Incomplete</th>
            <th className="text-white">Deadline</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {dataLoading ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                Loading...
              </td>
            </tr>
          ) : currentTests?.length > 0 ? (
            currentTests.map((course) => (
              <tr
                className="text-start text-truncate bg-light"
                key={course._id}
              >
                <td>{truncateText(course.title, 5)}</td>
                <td>{truncateText(course?.course_id?.subject, 5)}</td>
                <td>20 / 0</td>
                <td>20 / 0</td>
                <td>20 / 0</td>
                <td>{formatDate(course.for_date)}</td>{" "}
                {/* Format the date here */}
                <td>
                  <FaClipboardCheck
                    onClick={() =>
                      navigate(`/teacher/HwandTest/test/${course._id}`)
                    }
                    className="cursor-pointer"
                    size={22}
                    color="green"
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center bg-light text-dark">
                No Tests Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between mt-3">
        <button
          className="btn btn-secondary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default AllTest;
