import React, { useEffect, useState } from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import {
  Badge,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Button, FormControlLabel, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { CiEdit } from "react-icons/ci";
import { MdDelete, MdOutlineReviews } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import { IoSchoolOutline, IoShareSocialOutline, IoStar } from "react-icons/io5";
import { TiBook } from "react-icons/ti";
import { IoMdHeart, IoMdHeartEmpty, IoMdTime } from "react-icons/io";
import { LuBook } from "react-icons/lu";
import { RiGraduationCapLine } from "react-icons/ri";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";

import brain_img_1 from "../../assets/brain_img_1.png";
import brain_img_2 from "../../assets/brain_img_2.png";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";
import { useSelector } from "react-redux";

const user_1 = require("../../assets/user_1.jpg");
const user_2 = require("../../assets/user_2.jpg");
const user_3 = require("../../assets/user_3.jpg");

const banner_1 = require("../../assets/banner-1.png");

function EditWebsite(props) {
  const { history } = props;
  const [selectedOption, setSelectedOption] = useState("alert words");
  const [banner, setBanner] = useState([]);

  const optionsArrays = [
    {
      label: "Banner",
      options: banner,
    },
    {
      label: "Landing Page Banner",
      options: [],
    },
    {
      label: "Parent Page Banner",
      options: [],
    },
    {
      label: "alert words",
      options: ["Option 1", "Option 2", "Option 3"],
    },
    {
      label: "Exam preparation courses",
      options: [
        "Jee preparation",
        "Neet preparation",
        "Cat preparation",
        "Upsc preparation",
        "Gate preparation",
        "Ias preparation",
        "Bank exams preparation",
        "Olympiad preparation",
        "School exams preparation",
      ],
    },
    {
      label: "Boards",
      options: [
        "Cbse board",
        "Icse board",
        "State boards",
        "International boards",
        "Isc (indian school certificate)",
        "Hsc (higher secondary certificate)",
      ],
    },
    {
      label: "Classes",
      options: [
        "Class 1-12",
        "Jee (joint entrance examination)",
        "Neet (national eligibility cum entrance test)",
        "Cbse (central board of secondary education)",
        "Icse (indian certificate of secondary education)",
        "State board classes",
      ],
    },
    {
      label: "Exams",
      options: [
        "Jee main",
        "Jee advanced",
        "Neet ug",
        "Cat (common admission test)",
        "Upsc (union public service commission)",
        "Ias (indian administrative services)",
        "Gate (graduate aptitude test in engineering)",
        "Gre (graduate record examination)",
        "Gmat (graduate management admission test)",
      ],
    },
    {
      label: "Courses",
      options: [
        "Maths",
        "Science",
        "Physics",
        "Chemistry",
        "Biology",
        "English",
        "Coding",
        "Data science",
        "Commerce",
        "Humanities",
      ],
    },
    {
      label: "Footer Links",
      options: [
        "Learn",
        "Teach",
        "Help",
        "Popular class",
        "Class by grade",
        "Class by age",
        "About us",
        "Icons",
        "Currency country language",
        "Terms of service",
        "Privacy policy",
        "Cookies policy",
        "Refund policy",
        "Social media links",
        "Explore classes",
        "Give a gift card",
        "Book a free demo",
        "Blog",
        "How it works",
      ],
    },
    {
      label: "additional Links",
      options: [
        "Blog",
        "Careers",
        "Partnerships",
        "Trust and security",
        "Security certifications",
        "Payment gateways",
        "Trust badges",
        "Language selector",
        "Regional settings",
        "Copyright and legal information",
        "Copyright statement",
        "Intellectual property",
        "Disclaimer",
        "Careers",
        "Terms of service",
        "Press releases",
        "Courses or subjects offered",
        "How it works",
        "Pricing",
        "Refund policy",
        "Teacher resources",
        "Review",
        "Support or help center",
        "Affiliate program",
        "Careers",
        "Investors",
        "Social initiative",
        "Student stories",
        "Classes by age",
        "Classes by grade",
        "Popular classes",
      ],
    },
    {
      label: "Navigation Links",
      options: [
        "About us",
        "Contact us",
        "FAQs",
        "Help centre",
        "Policies and legal information",
        "Terms of service",
        "Privacy policy",
        "Cookies policy",
        "Refund policy",
        "Social media links",
      ],
    },
    {
      label: "Hamburger menu parent page",
      options: [
        "Login",
        "Profile",
        "Preference",
        "Notifications",
        "Setting",
        "Referral status",
        "Demo",
        "T+c",
        "Chat",
        "Communities",
        "Search course",
        "My courses",
        "Wallet",
        "Manual help leads",
        "Calendar",
        "Favourite",
        "FAQ",
        "Reschedule",
        "Class count",
        "Resources",
        "Blog",
        "Transcript",
        "Homework",
        "Test",
        "Notifications page",
      ],
    },
    {
      label: "1 on 1",
      options: [],
    },
    {
      label: "Extra Acadmics",
      options: [],
    },
    {
      label: "Hobby",
      options: [],
    },
    {
      label: "Competitive Exams",
      options: [],
    },
    {
      label: "mentors",
      options: [],
    },
    {
      label: "testimonials",
      options: [],
    },
    {
      label: "clients",
      options: [],
    },
    {
      label: "brain bits",
      options: [],
    },
  ];

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const [showModal, setShowModal] = useState(false);
  const [showBannerModal, setBannerShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [status, setStatus] = useState("pending");

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleBannerShowModal = () => {
    setBannerShowModal(true);
  };

  const handleBannerCloseModal = () => {
    setBannerShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log("Input Value:", inputValue);
    console.log("Status:", status);
    // You can perform further actions here, such as sending data to backend
    handleCloseModal();
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const token = useSelector((state) => state.token.value);

  const handleAddBanner = async () => {
    if (!title) {
      toast.warn("Please Enter Title.");
    }

    if (!description) {
      toast.warn("Please Enter Description.");
    }

    if (!image) {
      toast.warn("Please Select Image.");
    }

    //new form data
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);
    setBtnLoading(true);

    try {
      let res = await MyAPI.POST("/banner", formData, token);
      let { res_type, message, error, data: resData } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        setTitle("");
        setDescription("");
        setImage(null);
        handleCloseModal();
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUpdateBanner = async () => {
    if (!title) {
      toast.warn("Please Enter Title.");
    }

    if (!description) {
      toast.warn("Please Enter Description.");
    }

    //new form data
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("status", status);
    if (image) {
      formData.append("image", image);
    }
    setBtnLoading(true);

    try {
      let res = await MyAPI.PUT(`/banner?id=${editData._id}`, formData, token);
      let { res_type, message, error, data: resData } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        setTitle("");
        setDescription("");
        setImage(null);
        setEditData(null);
        fetchBanners(token);
        setBannerShowModal(false);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const fetchBanners = async (token) => {
    try {
      setLoading(true);
      let res = await MyAPI.GET("/banner", token);
      let { res_type, message, data, error } = res.data || res;
      if (res_type === "success") {
        setBanner(data);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBannerDelete = async (id, token) => {
    try {
      let res = await MyAPI.DELETE(`/banner?id=${id}`, token);
      let { res_type, message, error, data: resData } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        fetchBanners(token);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [editData, setEditData] = useState(null);
  const [isImageEdit, setIsImageEdit] = useState(false);

  const setBtnEditData = (data) => {
    setEditData(data);
    setTitle(data.title);
    setStatus(data.status);
    setDescription(data.description);
    setBannerShowModal(true);
  };

  useEffect(() => {
    if (token) {
      fetchBanners(token);
    }
  }, [token]);

  return (
    <>
      <h5>Edit Website</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="d-flex align-items-center justify-content-between">
        <Col className="mt-3" md={4}>
          <Form.Group controlId={`selectOption1`}>
            <Form.Select
              onChange={(e) => setSelectedOption(e.target.value)}
              className="rounded-5 shadow"
            >
              <option selected disabled>
                Edit Website
              </option>
              {optionsArrays.map((data, index) => (
                <option key={index} value={`${data.label}`}>
                  {data.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>

        {/* this is for add button  */}
        {selectedOption !== "1 on 1" &&
          selectedOption !== "Extra Acadmics" &&
          selectedOption !== "mentors" &&
          selectedOption !== "Competitive Exams" &&
          selectedOption !== "Hobby" && (
            <Col
              md={8}
              className="d-flex align-items-center justify-content-end mt-4 "
            >
              <Button
                onClick={handleShowModal}
                className="shadow rounded-5"
                variant="outlined"
              >
                Add {selectedOption}{" "}
              </Button>
            </Col>
          )}
      </Row>

      {/* this is for option and action */}
      {selectedOption !== "Hobby" &&
        selectedOption !== "Banner" &&
        selectedOption !== "testimonials" &&
        selectedOption !== "brain bits" &&
        selectedOption !== "clients" &&
        selectedOption !== "mentors" &&
        selectedOption !== "Competitive Exams" &&
        selectedOption !== "1 on 1" &&
        selectedOption !== "Extra Acadmics" && (
          <Container className="mt-3">
            <Row className="mt-3">
              <Col md={6}>
                <b>Options</b>
              </Col>
              <Col md={6}>
                <b>status</b>
              </Col>
            </Row>
            {optionsArrays
              .filter((data) => data.label === selectedOption)
              .map((item, i) =>
                item.options.map((option, index) => (
                  <Row key={i} className="mt-3 mb-3">
                    <Col md={6}>{option}</Col>
                    <Col md={6}>
                      <FormControlLabel
                        key={index}
                        control={<Android12Switch defaultChecked />}
                        label={""}
                      />
                    </Col>
                  </Row>
                ))
              )}
          </Container>
        )}

      {selectedOption === "Banner" && (
        <>
          <Row className="mt-3 mb-3">
            <h5>Banners</h5>
          </Row>

          {banner &&
            !loading &&
            banner?.length > 0 &&
            banner.map((item, index) => (
              <Container
                key={index}
                fluid
                className="p-3 ps-0 shadow rounded-3 mt-3"
              >
                <Row>
                  <Col className="p-0" style={{ height: "40vh" }} md={6}>
                    <img
                      src={item.image_key || banner_1}
                      className="rounded-3"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "top",
                        borderRadius: "7px",
                      }}
                      alt=""
                      srcset=""
                    />
                  </Col>
                  <Col className="pt-2 text-justify">
                    <h5>
                      {item.title ?? "Title Not Available"}{" "}
                      <sub>
                        <sub>
                          <Badge
                            bg={
                              item.status === "active" ? "primary" : "warning"
                            }
                          >
                            {item.status}
                          </Badge>
                        </sub>
                      </sub>
                    </h5>
                    <p>{item.description ?? "Description Not Available."}</p>
                    <br />
                    <div
                      className="d-flex align-items-center justify-content-start"
                      style={{ gap: "10px" }}
                    >
                      <IconButton
                        disabled={btnLoading}
                        onClick={() => setBtnEditData(item)}
                        className="bg-primary text-white"
                        aria-label="edit"
                      >
                        <CiEdit />
                      </IconButton>
                      <IconButton
                        disabled={btnLoading}
                        onClick={() => handleBannerDelete(item._id, token)}
                        className="bg-danger text-white"
                        aria-label="edit"
                      >
                        <MdDelete />
                      </IconButton>
                    </div>
                  </Col>
                </Row>
              </Container>
            ))}
          {!loading && !banner?.length && <p>No banners available.</p>}
          {loading && <Spinner variant="primary" size="lg" />}
        </>
      )}

      {selectedOption === "testimonials" && (
        <>
          <Row className="mt-3 mb-3">
            <h5>Testimonials</h5>
          </Row>

          <Container fluid className="p-3 ps-0 shadow rounded-3 mt-3">
            <Row>
              <Col className="p-0" style={{ height: "40vh" }} md={6}>
                <img
                  src={banner_1}
                  className="rounded-3"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "top",
                    borderRadius: "7px",
                  }}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col className="pt-2 text-justify">
                <h5>
                  Learnie’s Individualized One-on-One Academic Classes: Shaping
                  Future Success, One Student at a Time
                </h5>
                <p>
                  Unleash your full potential with personalized one-on-one
                  academic classes available on our website. Benefit from
                  budget-friendly options while nurturing brilliance under the
                  expert guidance of top faculty. Embark on an exciting journey
                  of transformative learning now.
                </p>
                <br />
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "10px" }}
                >
                  <IconButton
                    onClick={handleBannerShowModal}
                    className="bg-primary text-white"
                    aria-label="edit"
                  >
                    <CiEdit />
                  </IconButton>
                  <IconButton
                    className="bg-danger text-white"
                    aria-label="edit"
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="p-3 ps-0 shadow rounded-3 mt-3">
            <Row>
              <Col className="p-0" style={{ height: "40vh" }} md={6}>
                <img
                  src={banner_1}
                  className="rounded-3"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "top",
                    borderRadius: "7px",
                  }}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col className="pt-2 text-justify">
                <h5>
                  Learnie’s Individualized One-on-One Academic Classes: Shaping
                  Future Success, One Student at a Time
                </h5>
                <p>
                  Unleash your full potential with personalized one-on-one
                  academic classes available on our website. Benefit from
                  budget-friendly options while nurturing brilliance under the
                  expert guidance of top faculty. Embark on an exciting journey
                  of transformative learning now.
                </p>
                <br />
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "10px" }}
                >
                  <IconButton
                    onClick={handleBannerShowModal}
                    className="bg-primary text-white"
                    aria-label="edit"
                  >
                    <CiEdit />
                  </IconButton>
                  <IconButton
                    className="bg-danger text-white"
                    aria-label="edit"
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="p-3 ps-0 shadow rounded-3 mt-3">
            <Row>
              <Col className="p-0" style={{ height: "40vh" }} md={6}>
                <img
                  src={banner_1}
                  className="rounded-3"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "top",
                    borderRadius: "7px",
                  }}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col className="pt-2 text-justify">
                <h5>
                  Learnie’s Individualized One-on-One Academic Classes: Shaping
                  Future Success, One Student at a Time
                </h5>
                <p>
                  Unleash your full potential with personalized one-on-one
                  academic classes available on our website. Benefit from
                  budget-friendly options while nurturing brilliance under the
                  expert guidance of top faculty. Embark on an exciting journey
                  of transformative learning now.
                </p>
                <br />
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "10px" }}
                >
                  <IconButton
                    onClick={handleBannerShowModal}
                    className="bg-primary text-white"
                    aria-label="edit"
                  >
                    <CiEdit />
                  </IconButton>
                  <IconButton
                    className="bg-danger text-white"
                    aria-label="edit"
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="p-3 ps-0 shadow rounded-3 mt-3">
            <Row>
              <Col className="p-0" style={{ height: "40vh" }} md={6}>
                <img
                  src={banner_1}
                  className="rounded-3"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "top",
                    borderRadius: "7px",
                  }}
                  alt=""
                  srcset=""
                />
              </Col>
              <Col className="pt-2 text-justify">
                <h5>
                  Learnie’s Individualized One-on-One Academic Classes: Shaping
                  Future Success, One Student at a Time
                </h5>
                <p>
                  Unleash your full potential with personalized one-on-one
                  academic classes available on our website. Benefit from
                  budget-friendly options while nurturing brilliance under the
                  expert guidance of top faculty. Embark on an exciting journey
                  of transformative learning now.
                </p>
                <br />
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ gap: "10px" }}
                >
                  <IconButton
                    onClick={handleBannerShowModal}
                    className="bg-primary text-white"
                    aria-label="edit"
                  >
                    <CiEdit />
                  </IconButton>
                  <IconButton
                    className="bg-danger text-white"
                    aria-label="edit"
                  >
                    <MdDelete />
                  </IconButton>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {selectedOption === "1 on 1" && (
        <>
          <Row className="mb-3 mt-3">
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseName">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course name"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseStatus">
                <Form.Control className="rounded-5 shadow" as="select">
                  <option value="">Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseCategory">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course category"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseLevel">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course level"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseDuration">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course duration"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="coursePrice">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course price"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseTag">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course tag"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Button
                variant="primary"
                className=" rounded-5 w-100 new-button"
                block
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Cards img={c1} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c3} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
          </Row>
        </>
      )}

      {selectedOption === "Extra Acadmics" && (
        <>
          <Row className="mb-3 mt-3">
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseName">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course name"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseStatus">
                <Form.Control className="rounded-5 shadow" as="select">
                  <option value="">Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseCategory">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course category"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseLevel">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course level"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseDuration">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course duration"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="coursePrice">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course price"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseTag">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course tag"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Button
                variant="primary"
                className=" rounded-5 w-100 new-button"
                block
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Cards img={c1} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c3} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
          </Row>
        </>
      )}

      {selectedOption === "Hobby" && (
        <>
          <Row className="mb-3 mt-3">
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseName">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course name"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseStatus">
                <Form.Control className="rounded-5 shadow" as="select">
                  <option value="">Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseCategory">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course category"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseLevel">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course level"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseDuration">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course duration"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="coursePrice">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course price"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseTag">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course tag"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Button
                variant="primary"
                className=" rounded-5 w-100 new-button"
                block
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Cards img={c1} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c3} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
          </Row>
        </>
      )}

      {selectedOption === "Competitive Exams" && (
        <>
          <Row className="mb-3 mt-3">
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseName">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course name"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseStatus">
                <Form.Control className="rounded-5 shadow" as="select">
                  <option value="">Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseCategory">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course category"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseLevel">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course level"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseDuration">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course duration"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="coursePrice">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course price"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseTag">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter course tag"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Button
                variant="primary"
                className=" rounded-5 w-100 new-button"
                block
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <Cards img={c1} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c3} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c4} />
            </Col>
            <Col md={4} className="mb-4">
              <Cards img={c2} />
            </Col>
          </Row>
        </>
      )}

      {selectedOption === "mentors" && (
        <>
          <Row className="mb-3 mt-3">
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseName">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentor name"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseStatus">
                <Form.Control className="rounded-5 shadow" as="select">
                  <option value="">Select status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseCategory">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentor ratings"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseLevel">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentors course title"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseDuration">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentor course duration"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="coursePrice">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentor course price"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Form.Group controlId="courseTag">
                <Form.Control
                  className="rounded-5 shadow"
                  type="text"
                  placeholder="Enter mentor course tag"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-3">
              <Button
                variant="primary"
                className=" rounded-5 w-100 new-button"
                block
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3" md={4}>
              <MentorCard />
            </Col>
            <Col className="mt-3" md={4}>
              <MentorCard />
            </Col>
            <Col className="mt-3" md={4}>
              <MentorCard />
            </Col>
            <Col className="mt-3" md={4}>
              <MentorCard />
            </Col>
          </Row>
        </>
      )}

      {selectedOption === "clients" && (
        <Row className="mt-5 mb-3">
          <Col md={4} className="p-5">
            <div className="testimonial-cards-item shadow rounded-2">
              <div className="testimonial-cards-top-img">
                <img src={user_1} alt="" />
              </div>
              <br />
              <br />
              <center>
                <h5>DIANNA KIMWEALTH</h5>
              </center>
              <p className="profession-here">Robo Constuction</p>
              <p className="text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
                deserunt quas laborum rem. Doloremque, dolores?
              </p>
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ gap: "10px" }}
              >
                <IconButton
                  onClick={handleBannerShowModal}
                  className="bg-primary text-white"
                  aria-label="edit"
                >
                  <CiEdit />
                </IconButton>
                <IconButton className="bg-danger text-white" aria-label="edit">
                  <MdDelete />
                </IconButton>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-5">
            <div className="testimonial-cards-item shadow rounded-2">
              <div className="testimonial-cards-top-img">
                <img src={c1} alt="" />
              </div>
              <br />
              <br />
              <center>
                <h5>DIANNA KIMWEALTH</h5>
              </center>
              <p className="profession-here">Robo Constuction</p>
              <p className="text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
                deserunt quas laborum rem. Doloremque, dolores?
              </p>
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ gap: "10px" }}
              >
                <IconButton
                  onClick={handleBannerShowModal}
                  className="bg-primary text-white"
                  aria-label="edit"
                >
                  <CiEdit />
                </IconButton>
                <IconButton className="bg-danger text-white" aria-label="edit">
                  <MdDelete />
                </IconButton>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-5">
            <div className="testimonial-cards-item shadow rounded-2">
              <div className="testimonial-cards-top-img">
                <img src={user_2} alt="" />
              </div>
              <br />
              <br />
              <center>
                <h5>DIANNA KIMWEALTH</h5>
              </center>
              <p className="profession-here">Robo Constuction</p>
              <p className="text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
                deserunt quas laborum rem. Doloremque, dolores?
              </p>
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ gap: "10px" }}
              >
                <IconButton
                  onClick={handleBannerShowModal}
                  className="bg-primary text-white"
                  aria-label="edit"
                >
                  <CiEdit />
                </IconButton>
                <IconButton className="bg-danger text-white" aria-label="edit">
                  <MdDelete />
                </IconButton>
              </div>
            </div>
          </Col>
          <Col md={4} className="p-5">
            <div className="testimonial-cards-item shadow rounded-2">
              <div className="testimonial-cards-top-img">
                <img src={user_3} alt="" />
              </div>
              <br />
              <br />
              <center>
                <h5>DIANNA KIMWEALTH</h5>
              </center>
              <p className="profession-here">Robo Constuction</p>
              <p className="text-center">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio
                deserunt quas laborum rem. Doloremque, dolores?
              </p>
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ gap: "10px" }}
              >
                <IconButton
                  onClick={handleBannerShowModal}
                  className="bg-primary text-white"
                  aria-label="edit"
                >
                  <CiEdit />
                </IconButton>
                <IconButton className="bg-danger text-white" aria-label="edit">
                  <MdDelete />
                </IconButton>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {selectedOption === "brain bits" && (
        <>
          <Row className="mt-3 mb-3">
            <Col md={4}>
              <BrainBitCard
                img={brain_img_1}
                handleBannerShowModal={handleBannerShowModal}
              />
            </Col>
            <Col md={4}>
              <BrainBitCard
                img={brain_img_2}
                handleBannerShowModal={handleBannerShowModal}
              />
            </Col>
            <Col md={4}>
              <BrainBitCard
                img={brain_img_1}
                handleBannerShowModal={handleBannerShowModal}
              />
            </Col>
          </Row>
        </>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{
          marginTop:
            selectedOption === "Banner"
              ? "10vh"
              : selectedOption === "testimonials"
              ? "6vh"
              : selectedOption === "clients"
              ? "6vh"
              : selectedOption === "brain bits"
              ? "6vh"
              : "20vh",
          zIndex: "1111",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {selectedOption}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formInput">
              <Form.Control
                type="text"
                placeholder={
                  selectedOption === "testimonials"
                    ? "Enter Name"
                    : "Enter Title"
                }
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            {selectedOption === "Banner" && (
              <>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Select banner</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Enter value"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Enter Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter text..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </>
            )}

            {selectedOption === "testimonials" && (
              <>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Enter Location</Form.Label>
                  <Form.Control type="text" placeholder="Enter location" />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Select profile</Form.Label>
                  <Form.Control type="file" placeholder="Enter value" />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Enter Text</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter text..."
                  />
                </Form.Group>
              </>
            )}

            {selectedOption === "clients" && (
              <>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    type="text"
                    placeholder="Enter sub title"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    type="file"
                    placeholder="Enter value"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Label>Enter Desc.</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter desc..."
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}

            {selectedOption === "brain bits" && (
              <>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    type="text"
                    placeholder="Enter Author Name"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    type="text"
                    placeholder="Enter Topic"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    type="file"
                    placeholder="select image"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mt-3" controlId="formInput">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter desc..."
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </>
            )}

            {selectedOption !== "Banner" && (
              <Form.Group controlId="formStatus" className="mt-3">
                <Form.Select value={status} onChange={handleStatusChange}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </Form.Group>
            )}

            <Col>
              <Button
                className="mt-3 border-danger bg-danger text-white"
                variant="outlined"
                type="submit"
              >
                Cancel
              </Button>
              {selectedOption === "Banner" && (
                <Button
                  disabled={btnLoading}
                  onClick={handleAddBanner}
                  className="mt-3 ms-3"
                  variant="outlined"
                  type="submit"
                >
                  {btnLoading ? "Adding..." : "Add Banner"}
                </Button>
              )}
            </Col>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showBannerModal}
        onHide={handleBannerCloseModal}
        style={{
          marginTop:
            selectedOption === "Banner"
              ? "10vh"
              : selectedOption === "testimonials"
              ? "6vh"
              : selectedOption === "clients"
              ? "6vh"
              : selectedOption === "brain bits"
              ? "6vh"
              : "20vh",
          zIndex: "1111",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {selectedOption}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formInput">
            <Form.Label>Enter Title</Form.Label>
            <Form.Control
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="Enter Title"
            />
          </Form.Group>
          {selectedOption === "Banner" && (
            <>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Select banner</Form.Label>
                <Form.Control
                  onChange={(e) => setImage(e.target.files[0])}
                  type="file"
                  placeholder="Enter value"
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Enter Text</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  as="textarea"
                  rows={3}
                  placeholder="Enter text..."
                />
              </Form.Group>
            </>
          )}

          {selectedOption === "testimonials" && (
            <>
              <Form.Group controlId="formInput">
                <Form.Label>Enter Location</Form.Label>
                <Form.Control type="text" placeholder="Enter location" />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Select profile</Form.Label>
                <Form.Control type="file" placeholder="Enter value" />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Enter Text</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter text..."
                />
              </Form.Group>
            </>
          )}

          {selectedOption === "clients" && (
            <>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  type="text"
                  placeholder="Enter sub title"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  type="file"
                  placeholder="Enter value"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Enter Desc.</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter desc..."
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </>
          )}

          {selectedOption === "brain bits" && (
            <>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  type="text"
                  placeholder="Enter Author Name"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  type="text"
                  placeholder="Enter Topic"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  type="file"
                  placeholder="select image"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter desc..."
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </>
          )}

          <Form.Group controlId="formStatus" className="mt-3">
            <Form.Label>Status</Form.Label>
            <Form.Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="active">Active</option>
              <option value="pending">Inactive</option>
            </Form.Select>
          </Form.Group>

          <Col>
            <Button
              onClick={handleBannerCloseModal}
              className="mt-3 border-danger bg-danger text-white"
              variant="outlined"
              type="submit"
            >
              Cancel
            </Button>
            {selectedOption === "Banner" && (
              <Button
                onClick={handleUpdateBanner}
                disabled={btnLoading}
                className="mt-3 ms-3"
                variant="outlined"
                type="button"
              >
                {btnLoading ? "Updating..." : "Update Banner"}
              </Button>
            )}
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Cards = ({ img }) => {
  const navigate = useNavigate();
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  return (
    <div
      // onClick={() => navigate("/course")}
      className="card_c pt-3 px-0 py-2 bg-white rounded-3 overflow-hidden position-relative"
    >
      <div className="d-flex align-items-center justify-content-between admin-1-on-1-switch-top px-3 w-100 pe-0">
        {/* <FiHeart className="fs-5" /> */}
        <h7>Active</h7>
        <FormControlLabel
          control={<Android12Switch defaultChecked />}
          label={""}
        />
      </div>

      <img className="cource_img rounded-3 px-3 mt-3" src={img} alt="" />

      <div className="d-flex px-3 align-items-center justify-content-between mt-3">
        <button className="px-3">Business</button>
        <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
      </div>

      <h5 className="mt-3 px-3">
        Learn Mandarin - Beginner <br /> Level
      </h5>

      <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3 pb-3">
        <div className="user d-flex align-items-center gap-2">
          <div className="user_img">
            <img
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
              alt=""
            />
          </div>
          <p className="m-0 text-secondary">Robert Fox</p>
        </div>
        <div className="d-flex align-items-center gap-2">
          <IoStar className="star" /> (4 Reviews)
        </div>
      </div>

      <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
        <p className="m-0">
          <TiBook className="fs-5 me-1 mb-1" /> 05
        </p>
        <p className="m-0">
          <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
        </p>
        <p className="m-0">
          <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
        </p>
      </div>
    </div>
  );
};

const MentorCard = () => {
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  return (
    <div className="card p-3 rounded-3">
      <div className="d-flex gap-2">
        <div className="user_image">
          <img
            className="rounded-circle"
            style={{ width: "120px", height: "120px" }}
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
            alt=""
          />
        </div>
        <div className="d-flex flex-column">
          <h4 className="mb-1">John Denial</h4>
          <span>
            <LuBook /> IIT Preparation Crash Course
          </span>
          <span>
            <MdOutlineReviews /> 39 Review ( 4.7 )
          </span>
          <span>
            <RiGraduationCapLine /> 62 Students
          </span>
          <span></span>
        </div>
      </div>
      <p className="m-0 mt-3">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum
        libero error perferendis ipsam laudantium at debitis nesciunt eius saepe
        doloremque incidunt tempora .
      </p>

      <div className="heart">
        <FormControlLabel
          control={<Android12Switch defaultChecked />}
          label={""}
        />
      </div>
    </div>
  );
};

const BrainBitCard = ({ img, handleBannerShowModal }) => {
  return (
    <>
      <div className="card_c py-3 px-3 bg-white rounded-3 overflow-hidden position-relative">
        <img className="cource_img rounded-3" src={img} alt="" />

        <h5 className="mt-3 fw-semibold">Top 10 Books for JEE Prep!</h5>

        <p className="mt-3">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          expedita corrupti quia, voluptas quas rem saepe deleniti iste mollitia
          in quisquam placeat distinctio explicabo earum possimus modi cum
          dolores? Recusandae, rerum iusto.
        </p>

        <div className="row">
          <div className="col-9">
            <p className="m-0">
              <b>Author:</b> Sunny Dhiman
            </p>
            <p className="m-0">
              <b>Topic:</b> Competitive Exam Prep
            </p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center gap-2">
            <div
              className="d-flex align-items-center justify-content-start pe-3"
              style={{ gap: "10px" }}
            >
              <IconButton
                onClick={handleBannerShowModal}
                className="bg-primary text-white"
                aria-label="edit"
              >
                <CiEdit />
              </IconButton>
              <IconButton className="bg-danger text-white" aria-label="edit">
                <MdDelete />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWebsite;
