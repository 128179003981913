import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import navigationConfig from "../../../oftadeh-configs/operationNavigationConfig";

import OftadehNavGroup from "../../parentComponents/OftadehNavigation/sections/OftadehNavGroup";
import OftadehNavCollapse from "../../parentComponents/OftadehNavigation/sections/OftadehNavCollapse";
import OftadehNavItem from "../../parentComponents/OftadehNavigation/sections/OftadehNavItem";
import OftadehNavLink from "../../parentComponents/OftadehNavigation/sections/OftadehNavLink";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logoBg: {
    // backgroundColor: theme.palette.type !== "dark" && "#18202c",
    // backgroundColor: "var(--color-primary)",
    // backgroundColor: "linear-gradient(to right, var(--color-primary), red)",
  },
  logo: {
    padding: "1rem",
    "& span": {
      display: "block",
      color: "red",
    },
  },
  navCustom: {
    "& .MuiTypography-root": {
      fontSize: ".75rem",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
      paddingBottom: "8px",
    },
    "& .MuiCollapse-wrapperInner a": {
      paddingLeft: "50px",
    },
  },
}));

const OperationNavigation = (props) => {
  const classes = useStyles(props);

  return (
    <div>
      <div className={clsx(classes.toolbar, classes.logoBg)}>
        <Typography
          className={classes.logo}
          variant="h6"
          component="h1"
          align="center"
        >
          {/* &copy; OFTADEH
          <span>React Admin</span> */}
          Operation Dashboard
        </Typography>
        {/* <iframe
          title="star repo"
          src="https://ghbtns.com/github-btn.html?user=mohammadoftadeh&repo=oftadeh-react-admin&type=star&size=large"
          frameworker="0"
          scrolling="0"
          width="75px"
          height="30px"
          frameBorder="none"
          style={{ margin: "0 0 20px 80px" }}
        /> */}
      </div>
      <Divider />
      <List className={classes.navCustom}>
        {navigationConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && <OftadehNavGroup item={item} />}

            {item.type === "collapse" && <OftadehNavCollapse item={item} />}

            {item.type === "item" && <OftadehNavItem item={item} />}

            {item.type === "link" && <OftadehNavLink item={item} />}

            {item.type === "divider" && <Divider className="my-16" />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default OperationNavigation;
