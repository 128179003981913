// People
import PeoplePage from "./PeoplePage";

export const PeoplePageConfig = {
  routes: [
    {
      path: "/operation/people",
      exact: true,
      component: PeoplePage
    }
  ]
};
