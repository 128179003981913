import TraficReportPage from "./TraficReportPage";

export const TraficReportPageConfig = {
  routes: [
    {
      path: "/operation/TraficReport",
      exact: true,
      component: TraficReportPage
    }
  ]
};
