import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ParentAccountPage = () => {
  const navigate = useNavigate();
  return (
    <OperationLayout>
      <h4>Parent Account</h4>

      <h5 className="mt-4">All Parents</h5>

      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Teacher Name</th>
            <th>Student Name</th>
            <th>Last Paid Date</th>
            <th>Next Paid Date</th>
            <th>Class Count</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Parent ID</th>
            <th>Student ID</th>
            <th>Teacher ID</th>
            <th>Total Payable Amount</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>CRS345</td>
                <td>Course Name</td>
                <td>Teacher Name</td>
                <td>Student Name</td>
                <td>12/09/2021</td>
                <td>12/09/2021</td>
                <td>5</td>
                <td>12/09/2021</td>
                <td>12/09/2021</td>
                <td>PAR345</td>
                <td>STU345</td>
                <td>TEA345</td>
                <td>$5,000</td>

                <td>
                  <p
                    onClick={() =>
                      navigate("/operation/account/parentAccount/parentDetails")
                    }
                    className="text-decoration-underline text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default ParentAccountPage;
