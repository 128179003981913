import React, { useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import '../../assets/css/Admin/AdminDashboard.css';
import '../../assets/css/Admin/SuperAdminDashboardView.css';
import { RiArrowUpSLine, RiSettings5Line } from "react-icons/ri";
import CircularProgressBar from './graph/CircularProgressBar';
import { RiArrowDownSLine } from "react-icons/ri";
import EChartsGraph from './graph/EChartsGraph';
import CustomEChartsGraph from './graph/CustomEChartsGraph';

function DashBoard(props) {
    const { history } = props;

    const data = [
        {
            name: 'Evaporation',
            type: 'bar',
            tooltip: {
                valueFormatter: function (value) {
                    return value + ' ml';
                }
            },
            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
        },
        {
            name: 'Precipitation',
            type: 'bar',
            tooltip: {
                valueFormatter: function (value) {
                    return value + ' ml';
                }
            },
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
        },
        {
            name: 'Temperature',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
                valueFormatter: function (value) {
                    return value + ' °C';
                }
            },
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
        }
    ];

    const dataOne = {
        // Example data for ComponentOne
        dimensions: ['category', 'value'],
        source: [
            { category: 'A', value: 30 },
            { category: 'B', value: 50 },
            { category: 'C', value: 70 },
            { category: 'D', value: 60 },
            { category: 'E', value: 80 }
        ]
    };

    return (
        <>
            <h5>Dashboard</h5>
            <Container>
                <Row>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow card-1">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#3C5DB5' size={27} />
                                    <div className="count"><h4>234 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={60} color="#3C5DB5" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow card-2">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowDownSLine color='#AB2043' size={27} />
                                    <div className="count"><h4>71 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={62} color="#AB2043" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow card-3">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#E6BB4E' size={27} />
                                    <div className="count"><h4>145 M</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={72} color="#E6BB4E" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow card-4">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#35AB77' size={27} />
                                    <div className="count"><h4>34 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={81} color="#35AB77" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className='mt-5'>
                <Row>
                    <Col md={7} className='px-3'>
                        <div className="graph-container shadow rounded-3 py-3 mx-auto">
                            <EChartsGraph id="main" data={data} />
                        </div>
                    </Col>
                    <Col md={5} className='shadow rounded-3 p-0' style={{ overflow: 'hidden' }}>
                        <div className="heder-graph-2 mb-4 px-3 d-flex align-items-center justify-content-between">
                            <h6>Income</h6>
                            <RiSettings5Line size={22} />
                        </div>
                        <CustomEChartsGraph height={250} data={dataOne} />
                    </Col>
                </Row>
            </Container>
            <Container className='mt-5'>
                <Row>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#25B875' size={27} />
                                    <div className="count"><h4>234 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={60} color="#007bff" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowDownSLine color='#AB2043' size={27} />
                                    <div className="count"><h4>71 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={62} color="#AB2043" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#E6BB4E' size={27} />
                                    <div className="count"><h4>145 M</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={72} color="#E6BB4E" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className=' p-2' md={3}>
                        <div className="admin-card-items shadow">
                            <span>New Accounts </span>
                            <div className="flex-admin-card">
                                <div className="left">
                                    <RiArrowUpSLine color='#35AB77' size={27} />
                                    <div className="count"><h4>34 %</h4></div>
                                </div>
                                <div className="right">
                                    <CircularProgressBar percentage={81} color="#35AB77" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DashBoard;