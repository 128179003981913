import React, { useState } from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Button, IconButton } from '@mui/material';
import { MdEdit } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";

function ProjectDetails(props) {
    const { history } = props;
    const [viewProject, setViewProject] = useState(true);
    const [addProject, setAddProject] = useState(false);
    const handleAddProject = () => {
        setViewProject(false);
        setAddProject(true);
    };
    const handleViewProject = () => {
        setViewProject(true);
        setAddProject(false);
    };
    return (
        <>
            <h5>Project Details</h5>
            {/* <OperationLocation path={history} /> */}
            <Col className='d-flex align-items-center justify-content-start shadow rounded-5 border  p-2 ps-4'>
                <FaSearch />
                <input type="text" className='border-0 ms-3' placeholder='Search here...' style={{ outline: 'none' }} />
            </Col>
            <Col className='d-flex align-items-center justify-content-start p-0 mt-3 mb-3' style={{ borderBottom: '1px solid grey', gap: '20px' }}>
                <div
                    onClick={handleViewProject}
                    className="p-0"
                    style={{
                        width: 'fit-content',
                        height: '100%',
                        cursor:'pointer',
                        borderBottom: viewProject ? '3px solid black' : ''
                    }}
                >
                    <h5>View Projects</h5>
                </div>
                <div
                    onClick={handleAddProject}
                    className="p-0"
                    style={{
                        width: 'fit-content',
                        height: '100%',
                        cursor:'pointer',
                        borderBottom: addProject ? '3px solid black' : ''
                    }}
                >
                    <h5>Add Projects</h5>
                </div>
            </Col>

            {
                viewProject === true ? (
                    <>
                        <Container className='px-4 py-3 pt-4 shadow rounded-3 border'>
                            <Row>
                                <Col><h5>Project #121</h5></Col>
                                <Col className='d-flex align-items-center justify-content-end'><Button className='border-0 text-dark' variant="outlined" startIcon={<MdEdit />}>Edit</Button></Col>
                            </Row>
                            <hr />
                            <Row className='mt-1'>
                                <Col md={4}><h6>Name:</h6></Col>
                                <Col md={8}>ABCD Username</Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col md={4}><h6>Deadline</h6></Col>
                                <Col md={8}>07 Jun 2002</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Delay</h6></Col>
                                <Col md={8}>No Delay</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Reason</h6></Col>
                                <Col md={8}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Achivement</h6></Col>
                                <Col md={8}>vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Rating</h6></Col>
                                <Col md={8}>
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                </Col>
                            </Row>
                        </Container>

                        <Container className='px-4 py-3 pt-4 mt-5 shadow rounded-3 border'>
                            <Row>
                                <Col><h5>Project #121</h5></Col>
                                <Col className='d-flex align-items-center justify-content-end'><Button className='border-0 text-dark' variant="outlined" startIcon={<MdEdit />}>Edit</Button></Col>
                            </Row>
                            <hr />
                            <Row className='mt-1'>
                                <Col md={4}><h6>Name:</h6></Col>
                                <Col md={8}>ABCD Username</Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col md={4}><h6>Deadline</h6></Col>
                                <Col md={8}>07 Jun 2002</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Delay</h6></Col>
                                <Col md={8}>No Delay</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Reason</h6></Col>
                                <Col md={8}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Achivement</h6></Col>
                                <Col md={8}>vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col md={4}><h6>Rating</h6></Col>
                                <Col md={8}>
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                    <FaStar size={22} color='red' />
                                </Col>
                            </Row>
                        </Container>
                    </>
                ) : ''
            }


            {
                addProject === true ? (
                    <>
                        <Row>
                            <Col md={4} className='mt-3'>
                                <Form.Group controlId="nameInput">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control className='shadow rounded-5' type="text" placeholder="Enter name" />
                                </Form.Group>
                            </Col>
                            <Col md={4} className='mt-3 '>
                                <Form.Group controlId="nameInput">
                                    <Form.Label>Deadline</Form.Label>
                                    <Form.Control className='shadow rounded-5' type="date" placeholder="date" />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-4'>
                                <Form.Group controlId="nameInput">
                                    <Form.Label>Delay</Form.Label>
                                    <Form.Control className='shadow rounded-5' type="date" placeholder="Enter Delay" />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-4'>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control className='shadow' as="textarea" placeholder='Enter Reason' rows={5} />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-4'>
                                <Form.Group controlId="nameInput">
                                    <Form.Label>Achivement</Form.Label>
                                    <Form.Control className='shadow rounded-5' type="text" placeholder="Enter Achivement" />
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mt-4'>
                                <Form.Label>Rating</Form.Label>
                            </Col>
                            <Col md={12}>
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <FaRegStar />
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <FaRegStar />
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <FaRegStar />
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <FaRegStar />
                                </IconButton>
                                <IconButton color="primary" aria-label="add to shopping cart">
                                    <FaRegStar />
                                </IconButton>
                            </Col>
                            <Col className='d-flex align-items-center justify-content-end'>
                                <Button className='rounded-5 shadow border-0 text-white bg-dark' variant="outlined" size="medium">
                                    Done
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : ''
            }


        </>
    )
}

export default ProjectDetails