import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../components/Navbar";
import "../assets/css/HomeDonate.css";
import { FaHandHoldingHeart } from "react-icons/fa";
import { AiTwotoneSafetyCertificate } from "react-icons/ai";
import { HiLightBulb } from "react-icons/hi";
import Footer from "../components/Footer";
import Button from "../components/Button";
import HomeFaqImg from "../assets/donate-img-1.png";
import HomeFaqImg2 from "../assets/donate-img-2.png";
import Testimonial from "../components/Testimonial";

function HomeDonate() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <Container fluid className="home-faq-container mt-3">
          <Row>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div
                className=" d-flex align-items-start justify-content-center p-5"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <h5 className="fw-bold">
                  Revolutiontizing educational <br /> funding
                </h5>
                <p className="text-start">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type
                </p>

                <Button text="Donate Now" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end home-faq-img-section"
            >
              <div className="home-faq-img-area p-4">
                <img src={HomeFaqImg} className="" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <center>
            <h5 className="fw-bold">Crowdfunding for Education</h5>
          </center>
          <center>
            <hr style={{ width: "20vw" }} />
          </center>
          <center>
            <p style={{ width: "70%" }}>
              The term “Education Crowdfunding” defines itself as a process of
              raising money by seeking small donations from several people
              towards an education-related cause.
            </p>
          </center>
        </Container>
        <Container className="mt-5 mb-3">
          <Row>
            <Col md={7} className="pt-4">
              <h5 className="fw-semibold">
                What is Education Crowdfunding in India?
              </h5>
              <p className="mt-3">
                Education Crowdfunding helps teachers, students and NGOs raise
                funds to buy classroom supplies, take children on special field
                trips and build toilets. Crowdfunding can also provide funds for
                distributing daily meals, upgrade school infrastructures like
                gymnasium, play areas, classroom renovation and expansion.
                Online crowdfunding for education is gaining more and more
                recognition. It is safe, easy and effective, and can reach a
                much larger pool of people in far less time.
              </p>
            </Col>
            <Col
              md={5}
              className="d-flex align-items-center justify-content-center pt-3"
            >
              <img
                className="rounded-2"
                src={HomeFaqImg}
                style={{ width: "320px", boxShadow: "10px 10px 0px #C6C6C6" }}
                alt=""
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center pt-3"
            >
              <img
                className="rounded-2"
                src={HomeFaqImg2}
                style={{ width: "320px", boxShadow: "10px 10px 0px #C6C6C6" }}
                alt=""
              />
            </Col>
            <Col md={8} className="pt-4">
              <h5 className="fw-semibold">
                What is Education Crowdfunding in India?
              </h5>
              <p className="mt-3">
                Education Crowdfunding helps teachers, students and NGOs raise
                funds to buy classroom supplies, take children on special field
                trips and build toilets. Crowdfunding can also provide funds for
                distributing daily meals, upgrade school infrastructures like
                gymnasium, play areas, classroom renovation and expansion.
                Online crowdfunding for education is gaining more and more
                recognition. It is safe, easy and effective, and can reach a
                much larger pool of people in far less time.
              </p>
              <p>
                Education Crowdfunding helps teachers, students and NGOs raise
                funds to buy classroom supplies, take children on special field
                trips and build toilets. Crowdfunding can also provide funds for
                distributing daily meals, upgrade school infrastructures like
                gymnasium, play areas, classroom renovation and expansion.
                Online crowdfunding for education is gaining more and more
                recognition. It is safe, easy and effective, and can reach a
                much larger pool of people in far less time.
              </p>
            </Col>
          </Row>
        </Container>
        <Testimonial />
        <Container className="mt-3 mb-5">
          <Row>
            <Col className="p-4" md={4}>
              <div className="donate-tesmonial-body shadow rounded-4 py-4 px-4 donate-tesmonial-body-1">
                <div
                 
                  className="donate-text-icon-area donate-text-icon-area-1"
                >
                  <FaHandHoldingHeart  />
                </div>
                <h5 className="mt-3">Test Heading for Donation.</h5>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
                <p className="mb-0">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </Col>
            <Col className="p-4" md={4}>
              <div className="donate-tesmonial-body shadow rounded-4 py-4 px-4  donate-tesmonial-body-2">
                <div className="donate-text-icon-area donate-text-icon-area-2">
                  <AiTwotoneSafetyCertificate />
                </div>
                <h5 className="mt-3">Test Heading for Donation.</h5>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
                <p className="mb-0">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </Col>
            <Col className="p-4" md={4}>
              <div className="donate-tesmonial-body shadow rounded-4 py-4 px-4  donate-tesmonial-body-3">
                <div className="donate-text-icon-area donate-text-icon-area-3">
                  <HiLightBulb />
                </div>
                <h5 className="mt-3">Test Heading for Donation.</h5>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
                <p className="mb-0">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default HomeDonate;
