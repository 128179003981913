import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "../../../oftadeh-configs/AccountRouteConfig";

const AccountRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
          {...route}
        />
      ))}
    </Routes>
  );
};

export default AccountRoutes;
