import TranscriptPage from "./TranscriptPage";

export const TranscriptConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/transcript",
      exact: true,
      component: TranscriptPage,
    },
  ],
};
