import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";

const VariableConstExpenses = () => {
  const [active, setActive] = useState("Transaction Fees Of Razorpay");

  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditClose = () => setEditShow(false);
  const handleEditShow = () => setEditShow(true);

  return (
    <AccountLayout>
      <h3>Variable Const Expense</h3>

      <Row className="mt-4">
        <Col md={6}>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => setActive(e.target.value)}
            value={active}
          >
            <option value="Transaction Fees Of Razorpay">
              Transaction Fees Of Razorpay
            </option>
            <option value="Learniee Amazon Affilliate Marketing Programe">
              Learniee Amazon Affilliate Marketing Programe
            </option>
            <option value="Server Cost">Server Cost</option>
            <option value="Teacher Payment">Teacher Payment</option>
            <option value="Office Expense">Office Expense</option>
            <option value="Refferal Amount">Refferal Amount</option>
          </Form.Select>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-end">
          <button
            onClick={handleShow}
            className="btn rounded-4 text-white"
            style={{ background: "var(--color-primary)" }}
          >
            Add {active}
          </button>
        </Col>
      </Row>

      <h5 className="mt-5">All {active}</h5>

      <Table className="mt-3" striped bordered hover>
        <thead>
          <tr className="bg-yellow ">
            <th className="text-white">Expense Name</th>
            <th className="text-white">Expense Type</th>
            <th className="text-white">Amount</th>
            <th className="text-white">Expense Date</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill()
            .map((_, i) => (
              <tr key={i}>
                <td>{active}</td>
                <td>Variable</td>
                <td>2000</td>
                <td>12/12/2021</td>
                <td>
                  <button
                    onClick={handleEditShow}
                    className="btn btn-sm rounded-4 text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Edit
                  </button>
                  <button className="btn btn-sm rounded-4 btn-danger ms-2">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {/* Dynamic modal on the basis of active */}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {active}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Expense Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Expense Name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Amount</Form.Label>
            <Form.Control type="number" placeholder="Enter Amount" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Expense Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn rounded-4 text-white"
            style={{ background: "var(--color-primary)" }}
          >
            Add {active}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal
        show={editShow}
        onHide={handleEditClose}
        centered
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {active}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Expense Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Expense Name"
              value={active}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              value={3000}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Expense Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn rounded-4 text-white"
            style={{ background: "var(--color-primary)" }}
          >
            Update {active}
          </button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default VariableConstExpenses;
