import React, { useState } from 'react'
import { Table, Button, Modal, Form, Col, Row } from 'react-bootstrap';
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { TiTick } from 'react-icons/ti';
import { IoCloseOutline } from 'react-icons/io5';
import { SlCalender } from 'react-icons/sl';
function PtaMeeting() {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const handleShowModal = (appointment) => {
        setModalData(appointment);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData({});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        handleCloseModal();
    };

    const appointments = [
        { id: 1, parentId: 'parent#142512001', parentName: 'John Smith', date: '2024-06-18', time: '10:00 AM', description: 'Meeting with John', status: 'Verified' },
        { id: 2, parentId: 'parent#142512001', parentName: 'Emmy', date: '2024-06-19', time: '11:00 AM', description: 'Meeting with Smith', status: 'Rejected' },
        // Add more appointments as needed
    ];

    return (
        <TeacherLayout>
            <Row className='mt-3 mb-3'>
                <Col md={6}>
                    <h5>PT Meeting</h5>
                </Col>
                <Col md={3}></Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Search..." />
                    </Form.Group>
                </Col>
            </Row>
            <Table
                className="mt-4 mb-3 rounded-4"
                style={{ backgroundColor: "var(--color-primary)" }}
                striped bordered hover>
                <thead>
                    <tr>
                        <th className='text-center text-white'>Parent ID</th>
                        <th className='text-center text-white'>Parent Name</th>
                        <th className='text-center text-white'>Date</th>
                        <th className='text-center text-white'>Time</th>
                        <th className='text-center text-white'>Description</th>
                        <th className='text-center text-white'>Reschedule</th>
                        <th className='text-center text-white'>Status</th>
                        {/* <th className='text-center' colSpan={2}>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {appointments.map((appointment) => (
                        <tr key={appointment.id}>
                            <td className="text-center bg-light">{appointment.parentId}</td>
                            <td className="text-center bg-light">{appointment.parentName}</td>
                            <td className="text-center bg-light">{appointment.date}</td>
                            <td className="text-center bg-light">{appointment.time}</td>
                            <td className="text-center bg-light">{appointment.description}</td>
                            <td className="text-center bg-light">
                                <center>
                                    <Button variant="warning" onClick={() => handleShowModal(appointment)}>
                                        <SlCalender size={22} />
                                    </Button>
                                </center>
                            </td>
                            <td className="text-center bg-light">{appointment.status}</td>
                            {/* <td className="text-center">
                                <center>
                                    <Button variant="success">
                                        <TiTick size={22} />
                                    </Button>
                                </center>
                            </td>
                            <td className="text-center">
                                <center>
                                    <Button variant="danger">
                                        <IoCloseOutline size={22} />
                                    </Button>
                                </center>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} style={{ marginTop: '10vh', zIndex: '142512001' }} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reschedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>

                        <Col md={12} className='mt-2'>
                            <Form.Group controlId="formDate">
                                <Form.Label>Date</Form.Label>
                                <Form.Control type="date" defaultValue={modalData.date} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <Form.Group controlId="formTime">
                                <Form.Label>Time</Form.Label>
                                <Form.Control type="time" defaultValue={modalData.time} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className='mt-2'>
                            <Form.Group controlId="formReason">
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter reason" />
                            </Form.Group>
                        </Col>
                        <Button variant="primary" className='mt-2' type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </TeacherLayout>
    )
}

export default PtaMeeting