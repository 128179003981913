import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Col, Table } from "react-bootstrap";

const TeacherAccountDetails = () => {
  const [teacherActiveTab, setTeacherActiveTab] = useState(1);
  const [teacherActiveSubTab, setTeacherActiveSubTab] = useState(1);
  return (
    <OperationLayout>
      <h4>Teacher Account Details</h4>
      <Row className="mt-1 justify-content-end">
        <button
          className="btn rounded-4 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Add Amount
        </button>
        <button
          className="btn rounded-4 ms-3 text-white"
          style={{ background: "var(--color-primary)", width: "fit-content" }}
        >
          Withdraw Amount
        </button>
      </Row>
      <Row className="mt-3 px-3">
        <div
          onClick={() => setTeacherActiveTab(1)}
          className="py-1 px-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: teacherActiveTab === 1 ? "var(--color-primary)" : "",
            color: teacherActiveTab === 1 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Incoming Transaction
        </div>
        <div
          onClick={() => setTeacherActiveTab(2)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: teacherActiveTab === 2 ? "var(--color-primary)" : "",
            color: teacherActiveTab === 2 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Outgoing Transaction
        </div>
      </Row>
      <Row className="mt-3 px-3">
        {teacherActiveTab === 1 ? (
          <>
            <div
              onClick={() => setTeacherActiveSubTab(1)}
              className="py-1 px-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  teacherActiveSubTab === 1 ? "var(--color-primary)" : "",
                color: teacherActiveSubTab === 1 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Class Payment
            </div>
            <div
              onClick={() => setTeacherActiveSubTab(2)}
              className="py-1 px-3 ms-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  teacherActiveSubTab === 2 ? "var(--color-primary)" : "",
                color: teacherActiveSubTab === 2 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Reffrel Amount
            </div>
          </>
        ) : (
          <>
            <div
              onClick={() => setTeacherActiveSubTab(1)}
              className="py-1 px-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  teacherActiveSubTab === 1 ? "var(--color-primary)" : "",
                color: teacherActiveSubTab === 1 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Fine
            </div>
            <div
              onClick={() => setTeacherActiveSubTab(2)}
              className="py-1 px-3 ms-3 rounded-5 border"
              style={{
                width: "fit-content",
                background:
                  teacherActiveSubTab === 2 ? "var(--color-primary)" : "",
                color: teacherActiveSubTab === 2 ? "white" : "",
                cursor: "pointer",
              }}
            >
              Amount Withdrawal
            </div>
          </>
        )}
      </Row>

      <Row className="mt-4 px-2">
        <Col
          style={{
            background: "#d9ffcf",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          Current Balance: <b>$1000</b>
        </Col>
      </Row>

      {teacherActiveTab === 1 && teacherActiveSubTab === 1 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Class Name</th>
              <th>Type</th>
              <th>Student Name</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>10th</td>
                  <td>Class</td>
                  <td>John Deo</td>
                  <td>$100</td>
                  <td>12/12/2021</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : teacherActiveTab === 1 && teacherActiveSubTab === 2 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Reffrel ID</th>
              <th>Reffrel Person Name</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>REF495874</td>
                  <td>John Deo</td>
                  <td>$100</td>
                  <td>12/02/2021</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : teacherActiveTab === 2 && teacherActiveSubTab === 1 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Teacher ID</th>
              <th>Teacher Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>TEA495874</td>
                  <td>John Deo</td>
                  <td>Fine</td>
                  <td>$100</td>
                  <td>12/02/2021</td>
                  <td>For Late Coming</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : teacherActiveTab === 2 && teacherActiveSubTab === 2 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Teacher ID</th>
              <th>Teacher Name</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>TEA495874</td>
                  <td>John Deo</td>
                  <td>Amount Withdrawal</td>
                  <td>$100</td>
                  <td>12/02/2021</td>
                  <td>For Personal Use</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : null}
    </OperationLayout>
  );
};

export default TeacherAccountDetails;
