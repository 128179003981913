// RecheduleLeave
import RecheduleLeavePage from "./RecheduleLeavePage";

export const RecheduleLeavePageConfig = {
  routes: [
    {
      path: "/operation/reshedule-leave",
      exact: true,
      component: RecheduleLeavePage
    }
  ]
};
