import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  SplitButton,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Button } from "@mui/material";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import AllTransaction from "./components/AllTransaction";
import OverDue from "./components/OverDue";
const AccountPage = (props) => {
  const { history } = props;
  const [activeTab, setActiveTab] = useState(0);
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h4>Account</h4>
      <Col
        md={12}
        sm={12}
        className="d-flex align-items-center justify-content-center p-2 ps-3 pe-3 rounded-2"
        style={{
          height: "8vh",
          maxHeight: "auto",
          backgroundColor: "var(--color-primary)",
          color: "#fff",
        }}
      >
        <Col
          onClick={() => setActiveTab(0)}
          className={
            activeTab === 0
              ? "d-flex align-items-center justify-content-center ms-1 rounded-2 bg-light text-dark"
              : "d-flex align-items-center justify-content-center ms-1 rounded-2"
          }
          style={{ height: "100%" }}
          md={4}
        >
          All Transaction
        </Col>
        <Col
          onClick={() => setActiveTab(1)}
          className={
            activeTab === 1
              ? "d-flex align-items-center justify-content-center ms-1 rounded-2 bg-light text-dark"
              : "d-flex align-items-center justify-content-center ms-1 rounded-2"
          }
          style={{ height: "100%" }}
          md={4}
        >
          Overdue
        </Col>
        <Col
          onClick={() => setActiveTab(2)}
          className={
            activeTab === 2
              ? "d-flex align-items-center justify-content-center ms-1 rounded-2 bg-light text-dark"
              : "d-flex align-items-center justify-content-center ms-1 rounded-2"
          }
          style={{ height: "100%" }}
          md={4}
        >
          Upcoming Payments
        </Col>
      </Col>

      {activeTab === 0 && <AllTransaction />}

      {activeTab === 1 && <OverDue />}
    </OperationLayout>
  );
};

export default AccountPage;
