import TeacherProfile from "./TeacherProfile";
import TeacherSetting from "./TeacherSetting";
import EditPage from "./EditPage";
import EditNext from "./EditNext";

export const TeacherProfilePageConfig = {
  routes: [
    {
      path: "/teacher/TeacherProfile",
      exact: true,
      component: TeacherProfile,
    },
    {
      path: "/teacher/setting",
      exact: true,
      component: TeacherSetting,
    },
    {
      path: "/teacher/TeacherProfile/edit",
      exact: true,
      component: EditPage,
    },
    {
      path: "/teacher/TeacherProfile/edit/next",
      exact: true,
      component: EditNext,
    },
  ],
};
