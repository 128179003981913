// ServiceProvider
import ServiceProviderPage from "./ServiceProviderPage";

export const ServiceProviderPageConfig = {
  routes: [
    {
      path: "/operation/ServiceProvider",
      exact: true,
      component: ServiceProviderPage
    }
  ]
};
