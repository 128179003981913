import ParentRegistration from "./ParentRegistration.jsx";

export const ParentRegistrationPageConfig = {
  routes: [
    {
      path: "/parent/registration",
      exact: true,
      component: ParentRegistration,
    },
  ],
};
