import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Container, Badge, Modal,Card } from "react-bootstrap";
import "./HomeWork.css";
import { Box, Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import { Search } from "@material-ui/icons";
import { FaFilePdf } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { MdOutlineStarOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";


function ViewTest(props) {

    const [currentQuestion, setCurrentQuestion] = useState(0);
  const totalQuestions = 12;
    return (
        <OftadehLayout>
            <h3>Test Result</h3>
            <Container className="mt-4">
                <Card className="my-3">
                    <Card.Body>
                        <h5>Student Name: John Doe</h5>
                        <h5>Test Score: 85%</h5>
                        <p>Test Date: 10/04/2024</p>
                        <p>Test Duration: 60 minutes</p>
                        <hr />
                        <p><b>Question {currentQuestion + 1}</b>. Question text goes here...</p>

                        <Col md={7}>
                            <Row>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(A) Objective 1</Col>
                                <Col className="border rounded-3 py-2 mt-2 ms-2 bg-success text-white border-success" md={5}>(B) Objective 2</Col>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(C) Objective 3</Col>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(D) Objective 4</Col>
                            </Row>
                        </Col>
                        <br />
                        <p><b>Question {currentQuestion + 2}.</b> Question text goes here...</p>

                        <Col md={7}>
                            <Row>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(A) Objective 1</Col>
                                <Col className="border border-danger bg-danger text-white rounded-3 py-2 mt-2 ms-2" md={5}>(B) Objective 2</Col>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(C) Objective 3</Col>
                                <Col className="border rounded-3 py-2 mt-2 ms-2" md={5}>(D) Objective 4</Col>
                            </Row>
                        </Col>

                    </Card.Body>
                </Card>

                <div className="text-center">
                    <p>Total Questions: {totalQuestions}</p>
                    <p>Maximum Obtainable Score: 100%</p>
                    <p>Failed Questions: 2</p>
                </div>
            </Container>

            <ParentFooter />
        </OftadehLayout>
    );
}

export default ViewTest;
