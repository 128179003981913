// ParentDatabase
import ParentDatabase from "./ParentDatabasePage";
import ParentProfile from "./ParentProfile";

export const ParentDatabasePageConfig = {
  routes: [
    {
      path: "/operation/ParentDatabase",
      exact: true,
      component: ParentDatabase
    },
    {
      path: "/operation/parent/:id",
      exact: true,
      component: ParentProfile
    },
  ]
};
