import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Button, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import SendIcon from "@mui/icons-material/Send";
import Calendar from 'react-calendar';
import ParentFooter from "../../components/ParentFooter";
import { FaCheckCircle, FaRegCalendarTimes } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
const Reschedule = (props) => {
  const { history } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setSelectedTime("");
    setDate(newDate);
  };

  const [selectedTime, setSelectedTime] = useState("");

  const [isDexliend, setIsDeclined] = useState(false);

  return (
    <TeacherLayout>
      <h3>Reschedule</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row>
        <Col className="d-flex align-items-center justify-content-between">
          <Col md={4}>
            <h6>
              <b>Parent/Student</b>
            </h6>
          </Col>
          <Col md={8}>
            <h6>
              <b>Class</b>
            </h6>
          </Col>
        </Col>
        <Col className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <h6>
              <b>Scheduled time</b>
            </h6>
          </Col>
          <Col md={6}>
            <h6>
              <b>Reschedule request</b>
            </h6>
          </Col>
        </Col>
        <Col md={2}>
          <h6>
            <b>action</b>
          </h6>
        </Col>
      </Row>
      <Row className="p-2 rounded-2 align-items-center pt-3 mt-3 border-yellow">
        <Col className="d-flex align-items-center justify-content-between">
          <Col md={4}>
            <h6>
              <b>sudhakar Rao</b>
            </h6>
          </Col>
          <Col md={8}>
            <h6>
              <b>Induction System</b>
            </h6>
          </Col>
        </Col>
        <Col className="d-flex align-items-center justify-content-between">
          <Col md={6}>
            <h6>
              <b>wed, 6 Jul 16:30</b>
            </h6>
          </Col>
          <Col md={6}>
            <h6>
              <b>wed, 6 Jul 18:30</b>
            </h6>
          </Col>
        </Col>

        <Col md={2}>
          <h6>
            <b
              className="d-flex align-items-center justify-content-center"
              style={{ gap: "10px" }}
            >
              <IconButton
                onClick={() => setIsDeclined(true)}
                style={{ backgroundColor: "#FCD3D2", color: "red" }}
                className="rounded-1 p-1"
                aria-label="delete"
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "#D6FFDA", color: "green" }}
                className="rounded-1 p-1"
                aria-label="delete"
              >
                <DoneIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "#FDF2D2", color: "#FFD32D" }}
                className="rounded-1 p-1"
                aria-label="delete"
              >
                <SendIcon />
              </IconButton>
            </b>
          </h6>
        </Col>
      </Row>

      {/* ------------------------ if declined ----------------------- */}

      {isDexliend && (
        <Container className="n-box-shadow p-3 mt-3 rounded-2 p-0">
          <Row className="p-2 rounded-5 align-items-center">
            <Col className="d-flex align-items-center justify-content-between">
              <Col md={4}>
                <h6>
                  <b>sudhakar Rao</b>
                </h6>
              </Col>
              <Col md={8}>
                <h6>
                  <b>Induction System</b>
                </h6>
              </Col>
            </Col>
            <Col className="d-flex align-items-center justify-content-between">
              <Col md={6}>
                <h6>
                  <b>wed, 6 Jul 16:30</b>
                </h6>
              </Col>
              <Col md={6}>
                <h6>
                  <b>wed, 6 Jul 18:30</b>
                </h6>
              </Col>
            </Col>

            <Col md={2}>
              <h6>
                <b
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <IconButton
                    onClick={() => setIsDeclined(false)}
                    style={{ backgroundColor: "#FCD3D2", color: "red" }}
                    className="rounded-1 p-1"
                    aria-label="delete"
                  >
                    <ClearIcon />
                  </IconButton>
                  <IconButton
                    style={{ backgroundColor: "#D6FFDA", color: "green" }}
                    className="rounded-1 p-1"
                    aria-label="delete"
                  >
                    <DoneIcon />
                  </IconButton>
                  <IconButton
                    style={{ backgroundColor: "#FDF2D2", color: "#FFD32D" }}
                    className="rounded-1 p-1"
                    aria-label="delete"
                  >
                    <SendIcon />
                  </IconButton>
                </b>
              </h6>
            </Col>
            <Row>
              <Col md={10}>
                <div class="form-group col-md-8 mt-3">
                  <label for="FirstName">Re-reschedule</label>
                  <input
                    type="date"
                    class="form-control border-yellow"
                    id="FirstName"
                    placeholder=""
                  />
                </div>
              </Col>
              <Col md={2}>
                <div class="form-group col-md-4 mt-3">
                  <label for="FirstName">&nbsp;</label>
                  <Button
                    className="teacher-button rounded-2"
                    variant="contained"
                  >
                    Request
                  </Button>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      )}

      <Row className="mt-4">
        <h4>Upcoming lectures</h4>
      </Row>

      {/* ------------------------ Upcoming lectures ----------------------- */}

      <Row className="mt-3">
        <Col md={4}>
          <Card className="shadow rounded-4 p-3">
            <img
              className="rounded-4"
              style={{ height: "200px", width: "100%" }}
              src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>

            <p className="mt-3 mb-2 d-flex align-items-center">
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
              2023, 6 pm
            </p>
            <div className="mt-3 d-flex gap-3 align-items-center">
              <div className="parent_user_img">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="small_text line_height m-0 mb-1">XYZ</p>
                  <p className="small_text line_height m-0 mb-1">Group Class</p>
                  <p className="small_text line_height m-0 d-flex align-items-center">
                    <IoMdStar className="me-1 text_yellow" />
                    4.3
                  </p>
                </div>
              </div>

              <button
                onClick={handleShow}
                className="start_btn_card1 py-1 px-3 border-0"
              >
                Send Request
              </button>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow rounded-4 p-3">
            <img
              className="rounded-4"
              style={{ height: "200px", width: "100%" }}
              src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>

            <p className="mt-3 mb-2 d-flex align-items-center">
              <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
              2023, 6 pm
            </p>
            <div className="mt-3 d-flex gap-3 align-items-center">
              <div className="parent_user_img">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="small_text line_height m-0 mb-1">XYZ</p>
                  <p className="small_text line_height m-0 mb-1">Group Class</p>
                  <p className="small_text line_height m-0 d-flex align-items-center">
                    <IoMdStar className="me-1 text_yellow" />
                    4.3
                  </p>
                </div>
              </div>

              <button className="start_btn_card1 py-1 px-3 border-0">
                Send Request
              </button>
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ zIndex: 9999 }}
      >
        <Modal.Header>
          <Modal.Title className="w-100 h-100">
            <h5 className="text-center">Reschedule Request</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {" "}
          <Row className="mt-2 mb-2">
            <Col md={3}>
              <b>Sudhakar Rao</b>
            </Col>
            <Col md={3}>
              <b>Induction System</b>
            </Col>
            <Col md={3}>
              <b>From: Teacher</b>
            </Col>
            <Col md={3}>
              <b>To: Parent</b>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Calendar
                className="rounded-3 mt-3 "
                onChange={onChange}
                value={date}
              />
            </Col>
            <Col md={6} className="pt-2">
              <div
                onClick={() => setSelectedTime("11")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "11" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">10:00 AM - 11:00 AM</p>
                {selectedTime === "11" && <FaCheckCircle color="green" />}
              </div>
              <div
                onClick={() => setSelectedTime("12")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "12" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">11:00 AM - 12:00 PM</p>
                {selectedTime === "12" && <FaCheckCircle color="green" />}
              </div>
              <div
                onClick={() => setSelectedTime("13")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "13" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">12:00 PM - 01:00 PM</p>
                {selectedTime === "13" && <FaCheckCircle color="green" />}
              </div>
              <div
                onClick={() => setSelectedTime("14")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "14" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">01:00 PM - 02:00 PM</p>
                {selectedTime === "14" && <FaCheckCircle color="green" />}
              </div>
              <div
                onClick={() => setSelectedTime("15")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "15" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">02:00 PM - 03:00 PM</p>
                {selectedTime === "15" && <FaCheckCircle color="green" />}
              </div>
              <div
                onClick={() => setSelectedTime("16")}
                className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${
                  selectedTime === "16" ? "border-success" : null
                }`}
              >
                <p className=" m-0 p-0">03:00 PM - 04:00 PM</p>
                {selectedTime === "16" && <FaCheckCircle color="green" />}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-3">
              <Form.Group controlId="selectOption">
                <Form.Label>Select Course</Form.Label>
                <Form.Select>
                  <option value="option1">Course - 1</option>
                  <option value="option2">Course - 2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="selectOption">
                <Form.Label>Select Subject</Form.Label>
                <Form.Select>
                  <option value="option1">Hindi</option>
                  <option value="option2">English</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Col md={12} className="mt-3">
            <Form.Group controlId="formReason">
              <Form.Label>Reason</Form.Label>
              <Form.Control as="textarea" placeholder="Reason..." rows={3} />
            </Form.Group>
          </Col>
          <Button
            className="mt-3 d-md-none reshedule-display-none-on-laptop-desktop bg-dark text-white"
            variant="contained"
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-black text-white fw-semibold py-2 px-4 border-0 rounded-3"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="bg-yellow text-white fw-semibold py-2 px-4 border-0 rounded-3"
            onClick={handleClose}
          >
            Send Request
          </button>
        </Modal.Footer>
      </Modal>

      <ParentFooter />
    </TeacherLayout>
  );
};

export default Reschedule;
