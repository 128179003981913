import React, { useState } from "react";
import styled from "styled-components";
import { IoPlaySharp } from "react-icons/io5";
import VideoModal from "../transcript/VideoModal ";
import TranscriptModal from "../transcript/TranscriptModal";

const Card3 = ({
  img,
  head,
  time,
  date,
  viewRecording,
  name,
  view,
  viewIcon,
  number,
}) => {
  const [showModalVideo, setShowModalVideo] = useState(false);

  const [showTranscript, setTranscript] = useState(false);

  const handleVideoClose = () => setShowModalVideo(false);
  const handleVideoShow = () => setShowModalVideo(true);

  const handleTranscriptClose = () => setTranscript(false);
  const handleTranscriptShow = () => setTranscript(true);
  return (
    <StyledContainer>
      <VideoModal showModal={showModalVideo} handleClose={handleVideoClose} />
      <TranscriptModal
        showModal={showTranscript}
        handleClose={handleTranscriptClose}
      />
      <div className="card3 shadow rounded-4">
        <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
          <img src={img} className="rounded-4" alt="" />
          <div
            className="play d-flex align-items-center justify-content-center"
            onClick={() => handleVideoShow()}
          >
            <IoPlaySharp className="fs-4" />
          </div>
        </div>
        <div className="bottom px-4 pb-3">
          <div className="d-flex align-items-center justify-content-between">
            {head && <h6 className="m-0 fw-bold">{head}</h6>}
            {time && <p className="m-0">{time}</p>}
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <p className="m-0">{name}</p>
            <p className="m-0">{date}</p>
          </div>
          {view && number && (
            <div className="d-flex align-items-center justify-content-between mt-3">
              <p
                className="m-0 text_yellow fw-bold cursor-pointer"
                onClick={() => handleTranscriptShow()}
              >
                {view} {viewIcon}
              </p>
              {viewRecording && (
                <p className="m-0 text_yellow fw-bold">
                  View Recording {viewIcon}
                </p>
              )}
              <p className="m-0">{number}</p>
            </div>
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .card3 {
    width: 100%;
    height: 100%;

    .top {
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid var(--color-dark);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-primary);
        border: none;
      }
    }
  }
`;

export default Card3;
