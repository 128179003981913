// LeadManagment
import LeadManagmentPage from "./LeadManagmentPage";

export const LeadManagmentPageConfig = {
  routes: [
    {
      path: "/operation/lead-managment",
      exact: true,
      component: LeadManagmentPage
    }
  ]
};
