import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Container, Badge, Modal, Form } from "react-bootstrap";
import "./HomeWork.css";
import { Box, Button, ButtonGroup, Chip, IconButton } from "@mui/material";
import { Search } from "@material-ui/icons";
import { FaFilePdf } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { MdOutlineStarOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";

const screenShot = require("../../assets/screenShot.png");

function HomeWork(props) {
  const { history } = props;
  const [currentTab, setCurrentTab] = useState(1);
  const [sectionOpen, setSectionOpen] = useState(1);
  const navigate = useNavigate();

  const handleOnlineTest = () => {
    navigate("/parent/HomeWork/test#001");
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Function to handle click on an image
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const handleSectionOneOpen = () => {
    setSectionOpen(1);
    setCurrentTab(1);
  };

  const handleSectionTwoOpen = () => {
    setSectionOpen(2);
    setCurrentTab(5);
  };

  const buttons = [
    <button
    className="px-3 py-2"
      onClick={handleSectionOneOpen}
      // className={sectionOpen === 1 ? 'bg-primary text-white' : ''}
      style={
        sectionOpen === 1
          ? {
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              background: "var(--yellow-color)",
              color: "black",
              border: "1px solid var(--yellow-color)",
            }
          : {
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              border: "1px solid var(--yellow-color)",
              color: "black",
            }
      }
      key="one"
    >
      Home work
    </button>,
    <button
    className="px-3 py-2"
      onClick={handleSectionTwoOpen}
      style={
        sectionOpen === 2
          ? {
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              background: "var(--yellow-color)",
              color: "black",
              border: "1px solid var(--yellow-color)",
            }
          : {
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              border: "1px solid var(--yellow-color)",
              color: "black",
            }
      }
      key="two"
    >
      test
    </button>,
  ];

  return (
    <OftadehLayout>
      <h3>Home Works/tests</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Modal
        style={{ zIndex: "1111", marginTop: "10vh" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
      >
        <Modal.Body className="p-1">
          <img
            className="rounded-2"
            src={selectedImage}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
      </Modal>

      <Col className="homework-parent-tab-responsive">
        <Col
          md={4}
          className="d-flex align-items-center justify-content-start"
          style={{ gap: "10px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            {" "}
            <ButtonGroup size="large" aria-label="Large button group">
              {buttons}
            </ButtonGroup>
          </Box>
        </Col>
        <Col md={3} >
          <Form.Group controlId="formDate">
            <Form.Control className="rounded-5 shadow"
              type="date"
            />
          </Form.Group>
        </Col>
        <Col md={3} >
          <Form.Group controlId="formDate">
            <Form.Control className="rounded-5 shadow"
              type="text"
              placeholder="Search"
            />
          </Form.Group>
        </Col>
      </Col>

      <Row className="mt-3">
        <Col>
          {sectionOpen === 1 && (
            <>
              <button
                onClick={() => setCurrentTab(1)}
                className={
                  currentTab === 1
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                Up Coming{" "}
              </button>
              <button
                onClick={() => setCurrentTab(2)}
                className={
                  currentTab === 2
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                Due{" "}
              </button>
              <button
                onClick={() => setCurrentTab(3)}
                className={
                  currentTab === 3
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                Completed{" "}
              </button>
              <button
                onClick={() => setCurrentTab(4)}
                className={
                  currentTab === 4
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                All H/W{" "}
              </button>
            </>
          )}

          {sectionOpen === 2 && (
            <>
              <button
                onClick={() => setCurrentTab(7)}
                className={
                  currentTab === 7
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                Upcoming{" "}
              </button>
              <button
                onClick={() => setCurrentTab(8)}
                className={
                  currentTab === 8
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                Due{" "}
              </button>
              <button
                onClick={() => setCurrentTab(5)}
                className={
                  currentTab === 5
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                All{" "}
              </button>
              <button
                onClick={() => setCurrentTab(6)}
                className={
                  currentTab === 6
                    ? "home-work-switch-button home-work-switch-button-active mx-2 px-3 py-1"
                    : "border-0 text-dark rounded-5 mx-2 px-3 py-1"
                }
                variant="outlined"
                size="small"
              >
                {" "}
                completed{" "}
              </button>
            </>
          )}
        </Col>
      </Row>

      {currentTab === 1 && (
        <>
          <Col className="mt-5">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>30 points</span>
                </div>
              </Col>
            </Row>
          </Container>

          <Col className="mt-4">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>Re Submited.</span>
                </div>
              </Col>
            </Row>
          </Container>

          <Col className="mt-4">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3 ">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2" style={{ color: "red" }}>
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>30 points</span>
                </div>
              </Col>
            </Row>
          </Container>

          {/* ----------------------- test area */}

          <Container className="shadow border border-2 rounded-2 p-3 mt-4">
            <Row className="mt-3">
              <Col md={8}>
                <h4>Class Assignment 4</h4>
                <h6>Teacher Name</h6>
                <h6>Introduction to Optics</h6>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <span className="text-danger">Due Mar 23,2023 1:00 pm</span>
              </Col>
              <Col md={2}>
                <h6>
                  <b>Points</b>
                </h6>
                <span>10</span>
              </Col>
              <Col md={2}>
                <button className="new-button" variant="contained" size="large">
                  Submit
                </button>
              </Col>
            </Row>
            <Row className="mt-3">
              {" "}
              <h5>Reference/questions</h5>{" "}
            </Row>
            <Col md={4} style={{ padding: "10px" }}>
              <div className="resorce-parent-item">
                <div className="name">
                  <FaFilePdf /> DC Pandey
                </div>
                <MdOutlineFileDownload className="cursor-pointer" />
              </div>
            </Col>
            <Row className="mt-3">
              {" "}
              <h5>My Work</h5>{" "}
            </Row>
            <Col className="mt-3 cursor-pointer" style={{ color: "blue" }}>
              {" "}
              <AddLinkIcon /> Attach
            </Col>
          </Container>
        </>
      )}

      {currentTab === 2 && (
        <>
          <Col className="mt-5">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>30 points</span>
                </div>
              </Col>
            </Row>
          </Container>

          <Col className="mt-4">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>Re Submited.</span>
                </div>
              </Col>
            </Row>
          </Container>

          <Col className="mt-4">
            <b className="d-flex">
              <h4>
                Mar 23 <sup>rd</sup>{" "}
              </h4>{" "}
              <h5> Thursday</h5>{" "}
            </b>
          </Col>

          <Container className="mt-2 shadow border border-2 p-3 rounded-3 ">
            <Row>
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-2" style={{ color: "red" }}>
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col md={3}>
                <div className="home-work-point">
                  <span>30 points</span>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 3 && (
        <>
          <Col className="mt-3">
            <b className="d-flex">
              <h6>
                Mar 22 <sup>rd</sup>{" "}
              </h6>{" "}
              <h6> Thursday</h6>{" "}
            </b>
          </Col>

          <Container className="mt-2 rounded-3 border border-success">
            <Row className="p-3">
              <Col md={9}>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-1">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col
                md={3}
                className="d-flex align-items-center justify-content-end pe-5"
              >
                <span style={{ fontSize: "22px" }}>30 points</span>
              </Col>
            </Row>
          </Container>

          <Col className="mt-3">
            <b className="d-flex">
              <h6>
                Mar 22 <sup>rd</sup>{" "}
              </h6>{" "}
              <h6> Thursday</h6>{" "}
            </b>
          </Col>

          <Container className="mt-2 rounded-3 border border-success">
            <Row className="p-3 pb-0">
              <Col md={9}>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-1">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-1 mb-2"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
              </Col>
              <Col
                md={3}
                className="d-flex align-items-center justify-content-end pe-5"
              >
                <span style={{ fontSize: "22px" }}>30 points</span>
              </Col>
              <hr />
            </Row>
            <Row className="mt-2 mb-3 p-3 pb-0 pt-0">
              <Col md={4}>
                <b>Teacher Name : </b> John Smith
              </Col>
              <Col md={4}>
                <b>Review Date : </b> 12 January 2012
              </Col>
              <Col md={4}>
                <Badge className="float-end" bg="success">completed</Badge>
              </Col>
              <Col md={12}>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Laudantium, totam! Id tenetur eaque non totam quos, dicta alias
                dolorum! Voluptatum incidunt temporibus laborum molestias illo
                unde cumque perferendis porro magni.
              </Col>
              <Col md={12} className="mt-2 mb-2">
                <b>Feedback : </b>
                <Col md={4} style={{ padding: "10px" }}>
                  <div className="resorce-parent-item">
                    <div className="name">
                      <FaFilePdf /> Feedback file
                    </div>
                    <MdOutlineFileDownload className="cursor-pointer" />
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 4 && (
        <>
          <Container className="mt-3 border rounded-3 border-success shadow">
            <Row className="ps-3 pb-2 pt-2">
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-1">
                  Submitted at Mar 10 2024 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
                <Col className="class-assisment mt-1 mb-2">
                  <b>status : </b>{" "}
                  <Badge className="p-1" bg="success">
                    Submited
                  </Badge>{" "}
                </Col>
              </Col>
              <Col
                md={3}
                className="d-flex align-items-center justify-content-end pe-5"
              >
                <span style={{ fontSize: "22px" }}>30 points</span>
              </Col>
            </Row>
          </Container>
          <Container className="mt-3 border rounded-3 border-warning shadow">
            <Row className="ps-3 pb-2 pt-2">
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-1">Submitted at Mar 10 2024 11:30 pm </Col>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
                <Col className="class-assisment mt-1 mb-2">
                  <b>status : </b>{" "}
                  <Badge className="p-1" bg="warning">
                    Pending
                  </Badge>{" "}
                </Col>
              </Col>
              <Col
                md={3}
                className="d-flex align-items-center justify-content-end pe-5"
              >
                <span style={{ fontSize: "22px" }}>30 points</span>
              </Col>
            </Row>
          </Container>
          <Container className="mt-3 border rounded-3 border-danger shadow">
            <Row className="ps-3 pb-2 pt-2">
              <Col md={9}>
                <Col
                  className="class-assisment mt-2"
                  style={{ fontSize: "18px" }}
                >
                  Topic Title Here. <br />
                  <h6>Teacher Name </h6>
                </Col>
                <span className="text-primary">
                  Received Mar 20,2023 1:00 pm
                </span>
                <br />
                <span className="text-primary">Submission Date Mar 20,2023 1:00 pm</span>
                <br />
                <Col className="class-assisment mt-1">
                  Due at 12 January 2023 11:30 pm{" "}
                </Col>
                <Col
                  className="class-assisment mt-1"
                  style={{ fontSize: "20px" }}
                >
                  Introduction to Optics{" "}
                </Col>
                <Col className="class-assisment mt-1 mb-2">
                  <b>status : </b>{" "}
                  <Badge className="p-1" bg="danger">
                    Due 12 January, 2023
                  </Badge>{" "}
                </Col>
              </Col>
              <Col
                md={3}
                className="d-flex align-items-center justify-content-end pe-5"
              >
                <span style={{ fontSize: "22px" }}>30 points</span>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 5 && (
        <>
          <Container>
            <Row>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="shadow border border-2 rounded-2 p-3 mt-3">
            <Row className="mt-3">
              <Col md={8}>
                <h4>Class Test 4</h4>
                <span style={{ color: "red" }}>Due Mar 23,2023 1:00 pm</span>
              </Col>
              <Col md={2}>
                <h6>
                  <b>Points</b>
                </h6>
                <span>10</span>
              </Col>
              <Col md={2}>
                <button className="new-button" variant="contained" size="large">
                  {" "}
                  Submit{" "}
                </button>
              </Col>
            </Row>
            <Row className="mt-3">
              {" "}
              <h5>Reference/questions</h5>{" "}
            </Row>

            <p className="mt-3 ">
              <b>Course Name:</b> English
            </p>

            <p className="mt-3 ">
              <b>Topic Name:</b> English grammar
            </p>

            <p className="mt-3 ">
              <b>Teacher Name:</b> John Smith
            </p>

            <p className="mt-3 ">
              <b>Test Date:</b> 12 January 2023
            </p>

            <p className="mt-3 ">
              <b>Submission Date:</b> 17 January 2023
            </p>

            <Col md={4} style={{ padding: "10px" }}>
              <div className="resorce-parent-item">
                <div className="name">
                  <FaFilePdf /> DC Pandey
                </div>
                <MdOutlineFileDownload />
              </div>
            </Col>
            <Row className="mt-3">
              {" "}
              <h5>My Work</h5>{" "}
            </Row>
            <Col className="mt-3" style={{ color: "blue" }}>
              {" "}
              <AddLinkIcon /> Attach
            </Col>
          </Container>
        </>
      )}

      {currentTab === 6 && (
        <>
          <Container>
            <Row>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <hr />
                  <Col>
                    <b>Teacher Name :</b> John Smith
                  </Col>
                  <Col>
                    <b>Marks :</b> 30 / 100
                  </Col>
                  <Col>
                    <b>Date :</b> 12 January 2023
                  </Col>
                  <Col>
                    <b>Feed Back :</b>
                  </Col>
                  <Col>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Corporis facilis natus placeat aliquid provident perferendis
                    molestias, iste veritatis quibusdam eum vel. Facere dolorum
                    quam natus iusto voluptatum ipsum incidunt. Eius.
                  </Col>
                  <br />
                  <Row>
                    <Col className="d-flex" style={{ gap: "10px" }}>
                      <img
                        onClick={() => handleImageClick(screenShot)}
                        className="rounded-3"
                        width="155px"
                        height={"100px"}
                        src={screenShot}
                        alt=""
                      />
                      <img
                        onClick={() => handleImageClick(screenShot)}
                        className="rounded-3"
                        width="155px"
                        height={"100px"}
                        src={screenShot}
                        alt=""
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <Chip label="Re Submit" variant="outlined" />
                      <Chip
                        onClick={() => navigate("/parent/test/121221")}
                        className="ms-3"
                        label="view result"
                        variant="outlined"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <hr />
                  <Col>
                    <b>Teacher Name :</b> John Smith
                  </Col>
                  <Col>
                    <b>Marks :</b> 30 / 100
                  </Col>
                  <Col>
                    <b>Date :</b> 12 January 2023
                  </Col>
                  <Col>
                    <b>Feed Back :</b>
                  </Col>
                  <Col>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Corporis facilis natus placeat aliquid provident perferendis
                    molestias, iste veritatis quibusdam eum vel. Facere dolorum
                    quam natus iusto voluptatum ipsum incidunt. Eius.
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 7 && (
        <>
          <Container>
            <Row>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="shadow border border-2 rounded-2 p-3 mt-3">
            <Row className="mt-3">
              <Col md={8}>
                <h4>Class Test 4</h4>
                <span style={{ color: "red" }}>Due Mar 23,2023 1:00 pm</span>
              </Col>
              <Col md={2}>
                <h6>
                  <b>Points</b>
                </h6>
                <span>10</span>
              </Col>
              <Col md={2}>
                <button className="new-button" variant="contained" size="large">
                  Submit
                </button>
              </Col>
            </Row>
            <Row className="mt-3">
              {" "}
              <h5>Reference/questions</h5>{" "}
            </Row>
            <Col md={4} style={{ padding: "10px" }}>
              <div className="resorce-parent-item">
                <div className="name">
                  <FaFilePdf /> DC Pandey
                </div>
                <MdOutlineFileDownload />
              </div>
            </Col>
            <Row className="mt-3">
              {" "}
              <h5>My Work</h5>{" "}
            </Row>
            <Col className="mt-3" style={{ color: "blue" }}>
              {" "}
              <AddLinkIcon /> Attach
            </Col>
          </Container>
        </>
      )}

      {currentTab === 8 && (
        <>
          <Container>
            <Row>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
              <Col md={12} className="p-2">
                <div className="shadow rounded-3 border p-3 ps-3 mt-3">
                  <h5>Received Test Title </h5>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Exercitationem similique voluptatibus nam obcaecati rerum
                    modi porro labore nesciunt praesentium. Ab?
                  </p>
                  <button
                    onClick={handleOnlineTest}
                    className="new-button"
                    variant="outlined"
                    size="medium"
                  >
                    Submit Test
                  </button>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="shadow border border-2 rounded-2 p-3 mt-3">
            <Row className="mt-3">
              <Col md={8}>
                <h4>Class Test 4</h4>
                <span style={{ color: "red" }}>Due Mar 23,2023 1:00 pm</span>
              </Col>
              <Col md={2}>
                <h6>
                  <b>Points</b>
                </h6>
                <span>10</span>
              </Col>
              <Col md={2}>
                <button className="new-button" variant="contained" size="large">
                  {" "}
                  Submit{" "}
                </button>
              </Col>
            </Row>
            <Row className="mt-3">
              {" "}
              <h5>Reference/questions</h5>{" "}
            </Row>
            <Col md={4} style={{ padding: "10px" }}>
              <div className="resorce-parent-item">
                <div className="name">
                  <FaFilePdf /> DC Pandey
                </div>
                <MdOutlineFileDownload />
              </div>
            </Col>
            <Row className="mt-3">
              {" "}
              <h5>My Work</h5>{" "}
            </Row>
            <Col className="mt-3" style={{ color: "blue" }}>
              {" "}
              <AddLinkIcon /> Attach
            </Col>
          </Container>
        </>
      )}

      <ParentFooter />
    </OftadehLayout>
  );
}

export default HomeWork;
