// PaymentGatewayGateway
import PaymentGatewayPage from "./PaymentGatewayPage";

export const PaymentGatewayPageConfig = {
  routes: [
    {
      path: "/account/payment-getway",
      exact: true,
      component: PaymentGatewayPage
    }
  ]
};
