import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from "react-bootstrap";

const TeacherAccount = () => {
  return (
    <AccountLayout>
      <h3>Teacher Account</h3>

      <Row className="mt-4">
        <Col md={4}>
          <Form.Select aria-label="Default select example">
            <option>Filter</option>
            <option value="1">By Parent</option>
            <option value="2">By Child</option>
            <option value="3">By Teacher</option>
          </Form.Select>
        </Col>
      </Row>

      <Table className="mt-4" striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Parent ID</th>
            <th>Parent Name</th>
            <th>Teacher ID</th>
            <th>Teacher Name</th>
            <th>Child ID</th>
            <th>Child Name</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Class Start Date</th>
            <th>Last Class Date</th>
            <th>Next Class Date</th>
            <th>Class End Date</th>
            <th>Total No of Day Entended</th>
            <th>Subject</th>
            <th>Grade</th>
            <th>Board</th>
            <th>Type</th>
            <th>Per Class Rate of Teacher</th>
            <th>Total No of Class (Parent Pay For)</th>
            <th>Total No of Amount Pay Till Date</th>
            <th>Total No of Amount Pay (Remaining To Pay Teacher) </th>
            <th>Total Tax</th>
            <th>Total Fine</th>
            <th>No of Reffrel (Teacher Reffre To Parent)</th>
            <th>Total Amount of Reffrel</th>
            <th>Total Amount of Affiliate</th>
            <th>Gift Card Amount</th>
            <th>Last Date of Withdraw (From Teacher Wallet)</th>
            <th>Total Amount WIthdraw</th>
            <th>Transaction ID</th>
            <th>Bank Details</th>
            <th>Download Report</th>
            <th>Add Comment</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td>PAR4545</td>
                <td>John Deo</td>
                <td>TEA4545</td>
                <td>John Deo</td>
                <td>CHI4545</td>
                <td>John Deo</td>
                <td>COR4545</td>
                <td>John Deo</td>
                <td>12/12/2021</td>
                <td>12/12/2021</td>
                <td>12/12/2021</td>
                <td>12/12/2021</td>
                <td>12</td>
                <td>Math</td>
                <td>A</td>
                <td>CBSE</td>
                <td>One-On-One</td>
                <td>₹ 200</td>
                <td>12</td>
                <td>₹ 2000</td>
                <td>₹ 2000</td>
                <td>₹ 200</td>
                <td>₹ 200</td>
                <td>2</td>
                <td>₹ 200</td>
                <td>₹ 200</td>
                <td>₹ 200</td>
                <td>12/12/2021</td>
                <td>₹ 200</td>
                <td>454545</td>
                <td>Bank of India</td>
                <td>
                  <button className="btn btn-sm rounded-4 btn-warning">
                    Download
                  </button>
                </td>
                <td>
                  <input
                    type="text"
                    className="py-1 px-3"
                    placeholder="Add Comment"
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm rounded-4 text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Send For Approval
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AccountLayout>
  );
};

export default TeacherAccount;
