import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaCaretRight,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { CiGlobe } from "react-icons/ci";
import { PiCurrencyInr } from "react-icons/pi";
import { LuTimer } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ParentFooter = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        fluid
        className="footer_main m-0 mt-0 position-relative overflow-hidden mt-5 rounded-3"
      >
        <Row className="footer_bottom px-2" style={{backgroundColor:'#01001D'}}>
          {/* <div className="d-flex justify-content-around flex-md-row flex-column"> */}
          <Col md={3}>
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Urja Talents</h5>
              <p>
                Invest in your child’s future.{" "}
                <br className="d-md-block d-none" /> Embark on a journey of{" "}
                <br className="d-md-block d-none" />
                transformational learning. Get{" "}
                <br className="d-md-block d-none" />
                started now!
              </p>

              <div className="social_media_main mt-5 d-flex align-items-center gap-md-2 gap-4">
                <div className="social_media ">
                  <div className="social_icon_inner d-flex align-items-center justify-content-center ">
                    <FaFacebookF className="text-black fs-5" />
                  </div>
                </div>
                <div className="social_media ">
                  <div className="social_icon_inner d-flex align-items-center justify-content-center ">
                    <FaYoutube className="text-black fs-5" />
                  </div>
                </div>
                <div className="social_media ">
                  <div className="social_icon_inner d-flex align-items-center justify-content-center ">
                    <FaTwitter className="text-black fs-5" />
                  </div>
                </div>
                <div className="social_media ">
                  <div className="social_icon_inner d-flex align-items-center justify-content-center ">
                    <FaLinkedinIn className="text-black fs-5" />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Links</h5>
              <Link to={"/about"} className="text-white" >
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                About Us
              </p>
              </Link>
              <Link to="/blog" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Blog
                </p>
              </Link>
              <Link to="/donate" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Donate to educate <br className="d-md-block d-none" />
                  underpriviledged
                </p>
              </Link>
            </div>
          </Col>

          <Col md={3}>
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Learn</h5>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Browser Classes
              </p>
              <Link to="/gift" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Give a Gift Card
                </p>
              </Link>
              <Link to="/book-demo" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Book a free demo
                </p>
              </Link>
              <Link to="/how-it-works" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  How it Works
                </p>
              </Link>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Class By Grade
              </p>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Popular Class
              </p>
            </div>
          </Col>

          <Col md={3}>
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Teach</h5>
              <Link to={'/mentor'} className="text-white" >
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Become a mentor
                </p>
              </Link>
              {/* <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Benefits with <br className="d-md-block d-none" /> Learnie
              </p> */}
              <Link to="/instraction-join" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Instructions <br className="d-md-block d-none" />
                  to join
                </p>
              </Link>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Help
              </p>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Class By Age
              </p>
            </div>
          </Col>
          <Col md={3}></Col>
          <Col md={3} className="mt-2">
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Security</h5>
              <Link to="/security-policy" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Security
                </p>
              </Link>
              <Link to="/privacy-policy" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Policy
                </p>
              </Link>
              <Link to="/cookie-policy" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Cookie policy
                </p>
              </Link>
              <Link to="/refund-policy" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Refund Policy
                </p>
              </Link>
            </div>
          </Col>

          <Col md={3} className="mt-2">
            <div className="links_div mb-md-0 mb-5">
              <h5 className="fw-bold mb-3">Referral</h5>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Referral Program
              </p>
              <h5 className="fw-bold my-3">Copyright & legal</h5>
              <Link to='/copyright' className="text-white">
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Copyright statement
              </p>
              </Link>
              <Link to={'/disclaimer'} className="text-white" >
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Intellectual Property <br className="d-md-block d-none" />{" "}
                Disclaimer
              </p>
              </Link>
              <Link to={'/term&conditions'} className="text-white" >
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Terms & Conditions
              </p>
              </Link>
            </div>
          </Col>

          <Col md={3} className="mt-2">
            <div className="links_div">
              <h5 className="fw-bold mb-3">Additional</h5>
              <Link to="/career" className="text-white">
                <p className="d-flex m-0 mb-1">
                  <FaCaretRight className="text_yellow mt-1 me-2" />
                  Career
                </p>
              </Link>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Partnership
              </p>
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Press release
              </p>
              <Link to={'/testimonials'} className="text-white">
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Testimonials
              </p>
              </Link>
              <Link to={"/feed-back"} className="text-white" >
              <p className="d-flex m-0 mb-1">
                <FaCaretRight className="text_yellow mt-1 me-2" />
                Feedback
              </p>
              </Link>
            </div>
          </Col>
          {/* </div> */}

          <hr className="mt-4" />

          <Row className="px-md-5 pb-3">
            <Col md={6} className="d-flex align-items-center mb-md-0 mb-4">
              <p className="m-0">
                © Copyright All Rights Reserved Urja Talents
              </p>
            </Col>

            <Col
              md={6}
              className="d-flex align-items-center justify-content-md-end justify-content-start  gap-4 "
            >
              <p className="m-0 d-flex align-items-center ">
                <CiGlobe className="me-2" /> English
              </p>

              <p className="m-0 d-flex align-items-center ">
                <PiCurrencyInr className="me-2" /> INR
              </p>

              <p className="m-0 d-flex align-items-center ">
                <LuTimer className="me-2" /> Asia (Calcutta)
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default ParentFooter;
