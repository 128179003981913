import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Accordion,
} from "react-bootstrap";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { MdCallMade, MdCallReceived } from "react-icons/md";
import { Button as MaterialButton } from "@mui/material";
import { FiDownload } from "react-icons/fi";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

const WalletPage = () => {
  // Dummy data for balance and transactions
  const balance = "$500.00";
  const recentTransactions = [
    { id: 1, description: "Payment received", amount: "$100.00" },
    { id: 2, description: "Payment received", amount: "$50.00" },
    // Add more transactions as needed
  ];

  const [customPaymentShow, setCustomPaymentShow] = useState(false);

  const [currentTab, setCurrentTab] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const parentToken = useSelector((state) => state.token.value);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getParentWallet = async () => {
    try {
      const response = await axios.get("/parent/wallet", {
        headers: {
          Authorization: `Bearer ${parentToken}`,
        },
      });
      setData(response?.data?.data?.wallet);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getParentWallet();
  }, []);

  return (
    <OftadehLayout>
      <h3>Your Wallet</h3>
      <Container>
        <Row className="mb-4">
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Your Balance</Card.Title>
                <Card.Text>
                  <h2>${data?.value}</h2>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Recent Transactions</Card.Title>
                <Card.Text>
                  <ul>
                    {recentTransactions.map((transaction) => (
                      <li key={transaction.id}>
                        {transaction.description} - {transaction.amount}
                      </li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Add Gift-Card</Card.Title>
                <Row>
                  <Col md={4} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Control
                        className=" rounded-2"
                        type="text"
                        placeholder="Enter Gift voucher  Code"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mt-3 mb-3">
                    <Form.Group controlId="nameInput">
                      <Form.Control
                        className=" rounded-2"
                        type="text"
                        placeholder="Enter Gift voucher Pin"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mt-3 mb-3">
                    <MaterialButton variant="outlined">
                      Add Gift voucher
                    </MaterialButton>
                  </Col>
                  <Col>
                    <p className="text-primary ps-1">buy gift card ?</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="d-flex gap-2 flex-wrap">
            <span className="px-2 py-1 border rounded-2 cursor-pointer">
              ₹ 100
            </span>
            <span className="px-2 py-1 border rounded-2 cursor-pointer ms-2">
              ₹ 200
            </span>
            <span className="px-2 py-1 border rounded-2 cursor-pointer ms-2">
              ₹ 300
            </span>
            <span className="px-2 py-1 border rounded-2 cursor-pointer ms-2">
              ₹ 400
            </span>
            {customPaymentShow && (
              <span
                style={{ width: "200px" }}
                className="px-2 py-1 border rounded-2 cursor-pointer ms-2 overflow-hidden"
              >
                <input
                  type="text"
                  placeholder="Enter Custom Amount"
                  style={{ border: "none", backgroundColor: "transparent" }}
                />
              </span>
            )}
            <span
              onClick={() => setCustomPaymentShow(true)}
              className="px-2 py-1 border rounded-2 cursor-pointer ms-2 text-white bg-primary border-0"
            >
              {customPaymentShow ? "Pay" : "custom payment"}
            </span>
          </Col>
          <Col className="mt-3" md={12}>
            <Card>
              <Card.Body>
                <Card.Title>Actions</Card.Title>
                <button className="me-3 py-2 px-4 text-white rounded-5 border-0 bg-yellow">
                  Add Funds
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Note : -</Card.Title>
                <p>
                  Please be advised that in the event of a class cancellation,
                  the amount paid will not be refunded but will be credited to
                  your wallet. The credited amount can be adjusted towards
                  future classes or services within our platform. We appreciate
                  your understanding and cooperation in this matter.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h5>Upcoming payments</h5>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col className="mt-3 mt-md-0" md={4}>
            <div className="p-3 rounded-3 border bg-white shadow">
              <p>
                Course I'D : <b>course#0001</b>
              </p>
              <p>
                Course name : <b>English</b>
              </p>
              <p>
                Teacher name : <b>John Deo</b>
              </p>
              <p>
                Student name : <b>John Deo</b>
              </p>
              <p className="text-success">
                Last paid date : <b>23 March 2024</b>
              </p>
              <p className="text-danger">
                Next paid date : <b>23 June 2024</b>
              </p>
              <p>
                Class count : <b>12/32</b>
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  Start date : <b>12 jan 2023</b>
                </p>
                <p>
                  End date : <b>12 feb 2023</b>
                </p>
              </div>
              <p>
                Parent ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Student ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Teacher ID : <b>fg#$56bjo!@</b>
              </p>

              <div className="d-flex align-items-center justify-content-between pe-2">
                <h6>
                  Total Payable Amount : <b>₹ 5000</b>
                </h6>
                <button className="btn btn-sm btn-primary rounded-5 px-3">
                  Pay
                </button>
              </div>
            </div>
          </Col>
          <Col className="mt-3 mt-md-0" md={4}>
            <div className="p-3 rounded-3 border bg-white shadow">
              <p>
                Course I'D : <b>course#0001</b>
              </p>
              <p>
                Course name : <b>English</b>
              </p>
              <p>
                Teacher name : <b>John Deo</b>
              </p>
              <p>
                Student name : <b>John Deo</b>
              </p>
              <p className="text-success">
                Last paid date : <b>23 March 2024</b>
              </p>
              <p className="text-danger">
                Next paid date : <b>23 June 2024</b>
              </p>
              <p>
                Class count : <b>12/32</b>
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  Start date : <b>12 jan 2023</b>
                </p>
                <p>
                  End date : <b>12 feb 2023</b>
                </p>
              </div>
              <p>
                Parent ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Student ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Teacher ID : <b>fg#$56bjo!@</b>
              </p>

              <div className="d-flex align-items-center justify-content-between pe-2">
                <h6>
                  Total Payable Amount : <b>₹ 5000</b>
                </h6>
                <button className="btn btn-sm btn-primary rounded-5 px-3">
                  Pay
                </button>
              </div>
            </div>
          </Col>
          <Col className="mt-3 mt-md-0" md={4}>
            <div className="p-3 rounded-3 border bg-white shadow">
              <p>
                Course I'D : <b>course#0001</b>
              </p>
              <p>
                Course name : <b>English</b>
              </p>
              <p>
                Teacher name : <b>John Deo</b>
              </p>
              <p>
                Student name : <b>John Deo</b>
              </p>
              <p className="text-success">
                Last paid date : <b>23 March 2024</b>
              </p>
              <p className="text-danger">
                Next paid date : <b>23 June 2024</b>
              </p>
              <p>
                Class count : <b>12/32</b>
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  Start date : <b>12 jan 2023</b>
                </p>
                <p>
                  End date : <b>12 feb 2023</b>
                </p>
              </div>
              <p>
                Parent ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Student ID : <b>fg#$4567nbuj</b>
              </p>
              <p>
                Teacher ID : <b>fg#$56bjo!@</b>
              </p>

              <div className="d-flex align-items-center justify-content-between pe-2">
                <h6>
                  Total Payable Amount : <b>₹ 5000</b>
                </h6>
                <button className="btn btn-sm btn-primary rounded-5 px-3">
                  Pay
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <Col className="mt-5 mb-3 d-flex gap-3 flex-wrap">
          <span
            className={
              currentTab === 1
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer"
                : "all-transaction cursor-pointer"
            }
            onClick={() => setCurrentTab(1)}
          >
            Completed
          </span>

          <span
            className={
              currentTab === 2
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(2)}
          >
            Incoming
          </span>
          <span
            className={
              currentTab === 5
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(5)}
          >
            Outgoing
          </span>
          <span
            className={
              currentTab === 4
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(4)}
          >
            Refund
          </span>

          <span
            className={
              currentTab === 3
                ? "all-transaction border-bottom border-3 border-dark text-bold cursor-pointer ms-3"
                : "all-transaction cursor-pointer ms-3"
            }
            onClick={() => setCurrentTab(3)}
          >
            Dues
          </span>
        </Col>

        {currentTab === 1 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-danger rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-danger me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">failed transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent I'D</b> : 87ehjw76dsahjgh
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Id</b> : ijhsadhih
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {currentTab === 4 && (
          <>
            <Col className="d-flex align-items-center justify-content-between px-4 py-3 shadow border border-success rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-success me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>

            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">success transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Transaction I'D</b> : 87ehjw76dsahjgh
                </Col>

                <Col className="mt-1" md={6}>
                  <b>Course ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Teacher ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Lecture Count</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Course Id</b> : ijhsadhih
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>

            <Col className="mt-3  mb-3 d-flex align-items-center justify-content-between px-4 py-3 shadow border border-warning rounded-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="p-2 rounded-3 bg-warning me-3">
                  <MdCallReceived size={22} color="white" />
                </span>
                <div>
                  <h5 className="mt-1">pending transaction</h5>
                  <span>24 Jan, 2023</span>
                </div>
              </div>
              <h5> ₹ 500</h5>
            </Col>
          </>
        )}

        {currentTab === 2 && (
          <>
            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">Incoming transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">Success</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>
                <Col md={12} className="mt-3">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Beatae tenetur voluptatibus iste eos maxime unde totam
                  suscipit error voluptatum, sapiente reprehenderit a, expedita
                  voluptates tempora. Suscipit fugiat necessitatibus nesciunt
                  reprehenderit.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>
            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-warning rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-warning me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">Incoming transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-warning">Pending</span>{" "}
                </Col>
                <Col md={12} className="mt-3">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Beatae tenetur voluptatibus iste eos maxime unde totam
                  suscipit error voluptatum, sapiente reprehenderit a, expedita
                  voluptates tempora. Suscipit fugiat necessitatibus nesciunt
                  reprehenderit.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>
          </>
        )}

        {currentTab === 5 && (
          <>
            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-danger rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-danger me-3">
                    <MdCallMade size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">outgoing transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>

                <Col className="mt-1" md={6}>
                  <b>Course ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Teacher ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Lecture Count</b> : 10
                </Col>

                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-danger">Failed</span>{" "}
                </Col>

                <Col md={12} className="mt-3">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Beatae tenetur voluptatibus iste eos maxime unde totam
                  suscipit error voluptatum, sapiente reprehenderit a, expedita
                  voluptates tempora. Suscipit fugiat necessitatibus nesciunt
                  reprehenderit.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>
            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-success rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-success me-3">
                    <MdCallMade size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">outgoing transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>

                <Col className="mt-1" md={6}>
                  <b>Course ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Teacher ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Parent ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Student ID</b> : #142512001
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Lecture Count</b> : 10
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Download Invoice</b> :
                  <MaterialButton
                    className="ms-3 border-success text-success"
                    size="small"
                    variant="outlined"
                    startIcon={<FiDownload />}
                  >
                    Download
                  </MaterialButton>
                </Col>

                <Col className="mt-1" md={6}>
                  <b>Status</b> :{" "}
                  <span className="badge  bg-success">success</span>{" "}
                </Col>

                <Col md={12} className="mt-3">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Beatae tenetur voluptatibus iste eos maxime unde totam
                  suscipit error voluptatum, sapiente reprehenderit a, expedita
                  voluptates tempora. Suscipit fugiat necessitatibus nesciunt
                  reprehenderit.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>
          </>
        )}

        {currentTab === 3 && (
          <>
            <Col className="mt-3  mb-3 px-4 py-3 shadow border border-danger rounded-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <span className="p-2 rounded-3 bg-danger me-3">
                    <MdCallReceived size={22} color="white" />
                  </span>
                  <div>
                    <h5 className="mt-1">Due transaction</h5>
                    <span>24 Jan, 2023</span>
                  </div>
                </div>
                <h5> ₹ 500</h5>
              </div>
              <Row className="mt-3">
                <Col className="mt-1" md={6}>
                  <b>Payee</b> : John Doe
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Transaction Date</b> : 24 January 2023
                </Col>
                {/* <Col className="mt-1" md={6}>
                  <b>UPI I'D</b> : upi@upi.com
                </Col> */}
                <Col className="mt-1" md={6}>
                  <b>Bank Name</b> : City Bank India
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Status</b> : <span className="badge  bg-danger">due</span>{" "}
                </Col>
                <Col className="mt-1" md={6}>
                  <b>Action</b> :{" "}
                  <span className="btn btn-sm btn-success">Pay</span>{" "}
                </Col>
                <Col md={12} className="mt-3">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Beatae tenetur voluptatibus iste eos maxime unde totam
                  suscipit error voluptatum, sapiente reprehenderit a, expedita
                  voluptates tempora. Suscipit fugiat necessitatibus nesciunt
                  reprehenderit.
                </Col>
                <center>
                  <p
                    className="mt-3 text-primary cursor-pointer"
                    onClick={toggleModal}
                  >
                    need help ?
                  </p>
                </center>
              </Row>
            </Col>
          </>
        )}

        <Row className="mt-5">
          <Col md={12}>
            <h3 className="m-0">
              Frequently Asked Questions (FAQs) about Wallet
            </h3>
            <Accordion defaultActiveKey="0" className="mt-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Modal
          show={showModal}
          onHide={toggleModal}
          style={{ zIndex: "1111", marginTop: "20vh" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Raise Complaint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col className="mt-1">
              <b>Transaction I'D</b> : ijhdas786wqeh87ye
            </Col>
            <Col className="mt-1">
              <b>Transaction Date</b> : 24 January, 2023
            </Col>
            <Col className="mt-1">
              <b>Transaction Ammount</b> : ₹ 500
            </Col>

            <Col className="mt-3" md={12}>
              <Form.Group controlId="nameInput">
                <Form.Label>choose file</Form.Label>
                <Form.Control type="file" placeholder="Enter Email ID" />
              </Form.Group>
            </Col>

            <Col md={12} className="mt-3 mb-3">
              <Form.Group controlId="textareaInput">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className="rounded-2"
                  as="textarea"
                  rows={3}
                  placeholder="description."
                />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="secondary" onClick={toggleModal}>
              Close
            </Button>
            <Button variant="primary" onClick={toggleModal}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <ParentFooter />
    </OftadehLayout>
  );
};

export default WalletPage;
