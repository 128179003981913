//  operationNavigationConfig
// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";
import AppsIcon from "@material-ui/icons/Apps";
import HomeIcon from "@mui/icons-material/Home";
import { RiParentFill } from "react-icons/ri";
import {
  FaMapPin,
  FaPeopleGroup,
  FaPersonChalkboard,
  FaUsers,
  FaUsersRays,
} from "react-icons/fa6";
import { FiGitPullRequest } from "react-icons/fi";
import {
  MdCancel,
  MdInterpreterMode,
  MdMoney,
  MdOutlineBugReport,
  MdOutlineTaskAlt,
  MdPayments,
} from "react-icons/md";
import { CgTranscript, CgWebsite } from "react-icons/cg";
import { GiTeacher } from "react-icons/gi";
import { MdRebaseEdit } from "react-icons/md";
import { GoGitCompare } from "react-icons/go";
import { TfiControlEject } from "react-icons/tfi";
import { GiPayMoney } from "react-icons/gi";
import { AiFillDatabase } from "react-icons/ai";
import { CiBank, CiCalculator2 } from "react-icons/ci";
import { MdContacts } from "react-icons/md";
import { MdOutlineSocialDistance } from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { ImUsers } from "react-icons/im";
import { MdAccountBalanceWallet } from "react-icons/md";
import { MdPermMedia } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { RiLeafFill } from "react-icons/ri";
import { MdWebhook } from "react-icons/md";
import { TbCirclesRelation } from "react-icons/tb";
import { FcSalesPerformance } from "react-icons/fc";
import { SiCoinmarketcap, SiConvertio, SiGooglemeet } from "react-icons/si";
import { BiSolidVideoRecording } from "react-icons/bi";
import { IoEllipseOutline } from "react-icons/io5";
import { SiGoogleclassroom } from "react-icons/si";
import { BsPersonWorkspace } from "react-icons/bs";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { GiVibratingShield } from "react-icons/gi";
import { MdLeaderboard } from "react-icons/md";
import { FaBloggerB, FaBook, FaRegLightbulb } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineMenuBook } from "react-icons/md";
import { SiKdenlive } from "react-icons/si";
import { MdNotificationsActive } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import { PiCertificateDuotone } from "react-icons/pi";
import { TbAlignBoxCenterMiddle } from "react-icons/tb";
import StudentIcon from "@material-ui/icons/EmojiPeople";
import { TbReportAnalytics } from "react-icons/tb";
import { IoMdInformationCircleOutline } from "react-icons/io";

const TeacherNavigationConfig = [
  {
    id: "Main",
    title: "",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: <AppsIcon />,
        url: "/operation/dashboard",
        exact: true,
      },
      {
        id: "Search",
        title: "Search",
        type: "item",
        icon: <IoSearch />,
        url: "/operation/Search",
        exact: true,
      },
      {
        id: "notification",
        title: "Notification",
        type: "item",
        icon: <MdNotificationsActive />,
        url: "/operation/notification",
        exact: true,
      },
      {
        id: "PT Meeting",
        title: "PT Meeting",
        type: "item",
        icon: <SiGooglemeet />,
        url: "/operation/pta/meeting",
        exact: true,
      },
      {
        id: "Edit Website",
        title: "Edit Website",
        type: "item",
        icon: <CgWebsite />,
        url: "/operation/edit/website",
        exact: true,
      },
      {
        id: "Report",
        title: "Report",
        type: "item",
        icon: <TbReportAnalytics />,
        url: "/operation/Report",
        exact: true,
      },
      {
        id: "IoChatbubbleEllipsesOutline",
        title: "Chats",
        type: "item",
        icon: <IoChatbubbleEllipsesOutline />,
        url: "/operation/chats",
        exact: true,
      },
      {
        id: "Certificate",
        title: "",
        type: "group",
        icon: <MdRebaseEdit />,
        children: [
          {
            id: "Certificate",
            title: "Manage",
            type: "collapse",
            icon: <MdRebaseEdit />,
            children: [
              {
                id: "Employee",
                title: "Employee",
                type: "item",
                icon: <FaUsers />,
                url: "/operation/employee",
                exact: true,
              },
              {
                id: "Term",
                title: "Term & Conditions",
                type: "item",
                icon: <TbAlignBoxCenterMiddle />,
                url: "/operation/term-condition",
                exact: true,
              },
              {
                id: "CourcesPage",
                title: "Manage Courses",
                type: "item",
                icon: <MdOutlineMenuBook />,
                url: "/operation/courses",
                exact: true,
              },
              {
                id: "IoPricetagsOutline",
                title: "Manage Tags",
                type: "item",
                icon: <IoPricetagsOutline />,
                url: "/operation/tags",
                exact: true,
              },
              {
                id: "IoPricetagsOutline",
                title: "Rate Calculator",
                type: "item",
                icon: <CiCalculator2 />,
                url: "/operation/rate",
                exact: true,
              },
              {
                id: "LeadManagmentPage",
                title: "Lead Managment",
                type: "item",
                icon: <MdLeaderboard />,
                url: "/operation/lead-managment",
                exact: true,
              },
              {
                id: "LeadsPage",
                title: "Leads",
                type: "item",
                icon: <MdOutlineSocialDistance />,
                url: "/operation/Leads",
                exact: true,
              },
              {
                id: "Course Request",
                title: "Course Request",
                type: "item",
                icon: <MdOutlineMenuBook />,
                url: "/operation/courses/request",
                exact: true,
              },
            ],
          },
        ],
      },

      {
        id: "Certificate",
        title: "",
        type: "group",
        icon: <RiParentFill />,
        children: [
          {
            id: "Certificate",
            title: "Parent",
            type: "collapse",
            icon: <RiParentFill />,
            children: [
              {
                id: "Certificate",
                title: "Certificate",
                type: "item",
                icon: <PiCertificateDuotone />,
                url: "/operation/certificate",
                exact: true,
              },
              {
                id: "learnieMall",
                title: "Learnie Mall",
                type: "item",
                icon: <StudentIcon />,
                url: "/operation/learnieMall",
                exact: true,
              },
              {
                id: "HomeWork",
                title: "Home Work & Tests",
                type: "item",
                icon: <BsPersonWorkspace />,
                url: "/operation/HomeWork",
                exact: true,
              },
              {
                id: "Parent Database",
                title: "Parent Database",
                type: "item",
                icon: <AiFillDatabase />,
                url: "/operation/ParentDatabase",
                exact: true,
              },
              {
                id: "FaBook",
                title: "Book & Resources",
                type: "item",
                icon: <FaBook />,
                url: "/operation/book-resources",
                exact: true,
              },
              {
                id: "FaRegLightbulb ",
                title: "Parent Suggestions",
                type: "item",
                icon: <FaRegLightbulb />,
                url: "/operation/suggestions",
                exact: true,
              },
            ],
          },
        ],
      },
      {
        id: "Certificate",
        title: "",
        type: "group",
        icon: <GiTeacher />,
        children: [
          {
            id: "Certificate",
            title: "Teacher",
            type: "collapse",
            icon: <GiTeacher />,
            children: [
              {
                id: "Teachers",
                title: "Teachers",
                type: "item",
                icon: <FaChalkboardTeacher />,
                url: "/operation/Teachers",
                exact: true,
              },
              {
                id: "Classes",
                title: "Classes",
                type: "item",
                icon: <SiGoogleclassroom />,
                url: "/operation/classes",
                exact: true,
              },
              {
                id: "Live Request",
                title: "Live Request",
                type: "item",
                icon: <SiKdenlive />,
                url: "/operation/live-request",
                exact: true,
              },
              {
                id: "Class recordings",
                title: "Class recordings",
                type: "item",
                icon: <BiSolidVideoRecording />,
                url: "/operation/classes/recordings",
                exact: true,
              },
              {
                id: "Transcript Report",
                title: "Transcript Report",
                type: "item",
                icon: <MdOutlineBugReport />,
                url: "/operation/v1/transcript/report",
                exact: true,
              },
            ],
          },
        ],
      },
      {
        id: "Blogs",
        title: "",
        type: "group",
        icon: <FaBloggerB />,
        children: [
          {
            id: "Certificate",
            title: "Blogs",
            type: "collapse",
            icon: <FaBloggerB />,
            children: [
              {
                id: "BlogPage",
                title: "Blog",
                type: "item",
                icon: <MdOutlineSocialDistance />,
                url: "/operation/Blogs",
                exact: true,
              },
              {
                id: "Req Blogs",
                title: "Request Blogs",
                type: "item",
                icon: <FaChalkboardTeacher />,
                url: "/operation/blog/request",
                exact: true,
              },
              {
                id: "Pin",
                title: "Pin Blog",
                type: "item",
                icon: <FaMapPin />,
                url: "/operation/pin",
                exact: true,
              },
              {
                id: "Req Blogs",
                title: "All Blogs",
                type: "item",
                icon: <FaChalkboardTeacher />,
                url: "/operation/blog/all",
                exact: true,
              },
            ],
          },
        ],
      },
      {
        id: "Certificate",
        title: "",
        type: "group",
        icon: <MdPayments />,
        children: [
          {
            id: "Certificate",
            title: "Payment & Account",
            type: "collapse",
            icon: <MdPayments />,
            children: [
              {
                id: "Payments",
                title: "Payments",
                type: "item",
                icon: <GiPayMoney />,
                url: "/operation/payments",
                exact: true,
              },
              {
                id: "Account",
                title: "Account ",
                type: "item",
                icon: <MdAccountBalanceWallet />,
                url: "/operation/account",
                exact: true,
              },
              {
                id: "Learnie Account",
                title: "Learnie Account",
                type: "item",
                icon: <MdMoney />,
                url: "/operation/account/learnieAccount",
                exact: true,
              },
              {
                id: "Parent Account",
                title: "Parent Account",
                type: "item",
                icon: <GiPayMoney />,
                url: "/operation/account/parentAccount",
                exact: true,
              },
              {
                id: "Teacher Account",
                title: "Teacher Account",
                type: "item",
                icon: <MdMoney />,
                url: "/operation/account/teacherAccount",
                exact: true,
              },
              {
                id: "Employee Account",
                title: "Employee Account",
                type: "item",
                icon: <MdAccountBalanceWallet />,
                url: "/operation/account/employeeAccount",
                exact: true,
              },
            ],
          },
        ],
      },
      {
        id: "Reqeuest",
        title: "",
        type: "group",
        icon: <FiGitPullRequest />,
        children: [
          {
            id: "Request",
            title: "Requests",
            type: "collapse",
            icon: <FiGitPullRequest />,
            children: [
              {
                id: "Class",
                title: "Class hold request",
                type: "item",
                icon: <SiGoogleclassroom />,
                url: "/operation/class/hold",
                exact: true,
              },
              {
                id: "Class1",
                title: "Class cancel request",
                type: "item",
                icon: <MdCancel />,
                url: "/operation/class/cancel",
                exact: true,
              },
              {
                id: "All Interview Request",
                title: "All Interview Request",
                type: "item",
                icon: <MdInterpreterMode />,
                url: "/operation/interview/request",
                exact: true,
              },
            ],
          },
        ],
      },
      // {
      //   id: "roles-permissions",
      //   title: "Roles & Permissions",
      //   type: "item",
      //   icon: <FaPersonChalkboard />,
      //   url: "/operation/roles-permissions",
      //   exact: true,
      // },

      {
        id: "Complaint Page",
        title: "Complaint Page",
        type: "item",
        icon: <GoGitCompare />,
        url: "/operation/complaint",
        exact: true,
      },
      {
        id: "Reshedule & Leave",
        title: "Reshedule & Leave",
        type: "item",
        icon: <FaBusinessTime />,
        url: "/operation/reshedule-leave",
        exact: true,
      },
      {
        id: "GiVibratingShield",
        title: "Review & Ratings",
        type: "item",
        icon: <GiVibratingShield />,
        url: "/operation/ReviewRating",
        exact: true,
      },
      // {
      //   id: "KRA",
      //   title: "KRA",
      //   type: "item",
      //   icon: <TfiControlEject />,
      //   url: "/operation/KRA",
      //   exact: true,
      // },

      // {
      //   id: "OPSBanksPage",
      //   title: "OPS Banks",
      //   type: "item",
      //   icon: <CiBank />,
      //   url: "/operation/OPSBanks",
      //   exact: true,
      // },
      // {
      //   id: "contactPage",
      //   title: "Contact",
      //   type: "item",
      //   icon: <MdContacts />,
      //   url: "/operation/contact",
      //   exact: true,
      // },
      // {
      //   id: "managePage",
      //   title: "Manage",
      //   type: "item",
      //   icon: <MdContacts />,
      //   url: "/operation/manage",
      //   exact: true,
      // },
      // {
      //   id: "communitiesPage",
      //   title: "Communities",
      //   type: "item",
      //   icon: <FaPeopleGroup />,
      //   url: "/operation/communities",
      // },
      // {
      //   id: "FbAndWatsappPage",
      //   title: "Fb & Whatsapp",
      //   type: "item",
      //   icon: <MdOutlineSocialDistance />,
      //   url: "/operation/FbAndWatsapp",
      //   exact: true,
      // },

      // {
      //   id: "CodePage",
      //   title: "Coupon code ",
      //   type: "item",
      //   icon: <CiDiscount1 />,
      //   url: "/operation/code",
      //   exact: true,
      // },
      {
        id: "people",
        title: "Top Performance",
        type: "item",
        icon: <ImUsers />,
        url: "/operation/people",
        exact: true,
      },
      {
        id: "AllDemo",
        title: "All Demos ",
        type: "item",
        icon: <FaBook />,
        url: "/operation/AllDemo",
        exact: true,
      },
      {
        id: "Media",
        title: "Storage & Media ",
        type: "item",
        icon: <MdPermMedia />,
        url: "/operation/media",
        exact: true,
      },
      {
        id: "TaskManagement",
        title: "Task Management",
        type: "item",
        icon: <MdOutlineTaskAlt />,
        url: "/operation/TaskManagement",
        exact: true,
      },
      {
        id: "Vacancy",
        title: "Public vacancy",
        type: "item",
        icon: <IoMdInformationCircleOutline />,
        url: "/operation/Vacancy",
        exact: true,
      },
      {
        id: "CareerPage",
        title: "Career Management",
        type: "item",
        icon: <FaUsersRays />,
        url: "/operation/career",
        exact: true,
      },
      // {
      //   id: "ServiceProviderPage",
      //   title: "Service Provider ",
      //   type: "item",
      //   icon: <GrServices />,
      //   url: "/operation/ServiceProvider",
      //   exact: true,
      // },
      // {
      //   id: "AffliatePage",
      //   title: "Affliate",
      //   type: "item",
      //   icon: <RiLeafFill />,
      //   url: "/operation/Affliate",
      //   exact: true,
      // },
      // {
      //   id: "WebsiteSetting",
      //   title: "Website Setting",
      //   type: "item",
      //   icon: <MdWebhook />,
      //   url: "/operation/WebsiteSetting",
      //   exact: true,
      // },
      // {
      //   id: "BehaviourPage",
      //   title: "Behaviour",
      //   type: "item",
      //   icon: <TbCirclesRelation />,
      //   url: "/operation/Behaviour",
      //   exact: true,
      // },
      // {
      //   id: "communitiesPage",
      //   title: "Communities",
      //   type: "item",
      //   icon: <FaPeopleGroup />,
      //   url: "/operation/communities",
      //   exact: true,
      // },
      // {
      //   id: "FbAndWatsappPage",
      //   title: "Fb & Whatsapp",
      //   type: "item",
      //   icon: <MdOutlineSocialDistance />,
      //   url: "/operation/FbAndWatsapp",
      //   exact: true,
      // },

      // {
      //   id: "SafetyPage",
      //   title: "Safety",
      //   type: "item",
      //   icon: <MdOutlineSocialDistance />,
      //   url: "/operation/Safety",
      //   exact: true,
      // },

      // {
      //   id: "SalesReportPage",
      //   title: "Sales Report",
      //   type: "item",
      //   icon: <FcSalesPerformance />,
      //   url: "/operation/SalesReport",
      //   exact: true,
      // },
      // {
      //   id: "ConversionPage",
      //   title: "Conversion",
      //   type: "item",
      //   icon: <SiConvertio />,
      //   url: "/operation/Conversion",
      //   exact: true,
      // },
      // {
      //   id: "TraficReportPage",
      //   title: "Trafic Report",
      //   type: "item",
      //   icon: <FcSalesPerformance />,
      //   url: "/operation/TraficReport",
      //   exact: true,
      // },
      // {
      //   id: "SeoPage",
      //   title: "SEO",
      //   type: "item",
      //   icon: <IoEllipseOutline />,
      //   url: "/operation/SEO",
      //   exact: true,
      // },
      // {
      //   id: "MarketingPage",
      //   title: "Marketing",
      //   type: "item",
      //   icon: <SiCoinmarketcap />,
      //   url: "/operation/Marketing",
      //   exact: true,
      // },
    ],
  },
];

export default TeacherNavigationConfig;
