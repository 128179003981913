import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import styled from "styled-components";
import { Col, Row, Form, Dropdown } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import card2_img from "../../assets/parent/card2_img.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
import Card2 from "../parent/Card2";
import { IoStarSharp } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import ParentFooter from "../../components/ParentFooter";

const TeacherPage = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownChange = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };
  return (
    <OftadehLayout>
      <h1>Teacher</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <StyledContainer>
        <Row className="d-flex justify-content-end pe-5">
          <div className="input_container py-2 px-3">
            <input
              type="text"
              placeholder="Search Teacher"
              className="border-0"
            />
            <button>
              <CiSearch className="fs-5 text_yellow" />
            </button>
          </div>
        </Row>
        {/* <Row className="pe-5">
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Sort By</option>
              <option value="1">Popular Teacher</option>
              <option value="2">Active Teacher</option>
              <option value="3">High To Low</option>
              <option value="3">Low To High</option>
            </Form.Select>
          </Col>
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Subject</option>
              <option value="1">Maths</option>
              <option value="2">Science</option>
              <option value="3">English</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Grade</option>
              <option value="1">A+</option>
              <option value="2">A</option>
              <option value="3">B+</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Board</option>
              <option value="1">CBSE</option>
              <option value="2">ICSE</option>
              <option value="3">State Board</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Experience</option>
              <option value="1">1 Year</option>
              <option value="2">2 Year</option>
              <option value="3">3 Year</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Rating</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Type</option>
              <option value="1">1-on-1</option>
              <option value="2">Group Classes</option>
              <option value="3">Both</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Days</option>
              <option value="1">Sunday</option>
              <option value="2">Monday</option>
              <option value="3">Tuesday</option>
              <option value="4">Wednesday</option>
              <option value="5">Thursday</option>
              <option value="6">Friday</option>
              <option value="7">Saturday</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Qualification</option>
              <option value="1">B.Tech</option>
              <option value="2">M.Tech</option>
              <option value="3">Phd</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Competitive</option>
              <option value="1">JEE</option>
              <option value="2">NEET</option>
            </Form.Select>
          </Col>
        </Row> */}

<Row className="pe-5">
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Sort By</option>
              <option value="1">Popular Teacher</option>
              <option value="3">High To Low (Price)</option>
              <option value="3">High To Low (Rating)</option>
            </Form.Select>
          </Col>
          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Subject
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Subject</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Hindi</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>English</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Maths</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Grade
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Grade</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>A+</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>A</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>B+</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
          <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Board
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Board</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>CBSE</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>ICSE</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>State Board</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Experience
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Experience</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>1-3 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>3-5 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>5-8 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>8-12 Years</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>12+ Years</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Rating
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Rating</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>1</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>2</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>3</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>4</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>5</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Class Type</option>
              <option value="1">1-on-1</option>
              <option value="2">Group Classes</option>
              <option value="2">Both </option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Type</option>
              <option value="2">Academic </option>
              <option value="2">Extra Academic </option>
              <option value="2">Curriculum </option>
              <option value="2">Competitive Exams</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Type</option>
              <option value="2">Ongoing </option>
              <option value="2">One Time</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Days</option>
              <option value="1">Sunday</option>
              <option value="2">Monday</option>
              <option value="3">Tuesday</option>
              <option value="4">Wednesday</option>
              <option value="5">Thursday</option>
              <option value="6">Friday</option>
              <option value="7">Saturday</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Time</option>
              <option value="1">Morning</option>
              <option value="2">Evening</option>
              <option value="3">Afternoon</option>
            </Form.Select>
          </Col>

          <Col md={3} className="mt-4">
            <Dropdown className="d-flex align-items-center">
              <Dropdown.Toggle
                className="w-100 rounded-4"
                style={{
                  background: "white",
                  color: "black",
                  borderColor: "#DEE2E6",
                  textAlign: "left",
                }}
              >
                Qualification
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: 230, position: "absolute" }}
                className="px-3"
              >
                <h5 className="mb-4 fw-semibold">Qualification</h5>

                <div className="d-flex align-items-center flex-shrink-0">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Bachelor</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Master</label>
                </div>
                <div className="d-flex align-items-center flex-shrink-0 mt-3">
                  <input type="checkbox" className="me-2 filter_check" />
                  <label>Phd</label>
                </div>

                <hr />
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <button
                    className="mt-2 border-0 fw-bold bg-transparent"
                    style={{ color: "var(--color-primary)" }}
                  >
                    Clear
                  </button>
                  <button
                    className="mt-2 border-0 py-2 px-4 rounded-5 fw-bold text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Apply
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>


        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">Popular Teachers</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={window.innerWidth <= 768 ? 1 : 3}
            spaceBetween={20}
            navigation={{
              nextEl: ".teacher_right_arrow1",
              prevEl: ".teacher_left_arrow1",
            }}
            modules={[Navigation]}
            className="mySwiper"
            style={{
              paddingRight: "3.8vmax",
            }}
          >
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
          </Swiper>

          <div className="teacher_left_arrow1">
            <FaAngleLeft />
          </div>
          <div className="teacher_right_arrow1">
            <FaAngleRight />
          </div>
        </Row>


        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">School Teachers</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={window.innerWidth <= 768 ? 1 : 3}
            spaceBetween={20}
            navigation={{
              nextEl: ".teacher_right_arrow1",
              prevEl: ".teacher_left_arrow1",
            }}
            modules={[Navigation]}
            className="mySwiper"
            style={{
              paddingRight: "3.8vmax",
            }}
          >
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
          </Swiper>

          <div className="teacher_left_arrow1">
            <FaAngleLeft />
          </div>
          <div className="teacher_right_arrow1">
            <FaAngleRight />
          </div>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">IIT JEE Teachers</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={window.innerWidth <= 768 ? 1 : 3}
            spaceBetween={20}
            navigation={{
              nextEl: ".teacher_right_arrow2",
              prevEl: ".teacher_left_arrow2",
            }}
            modules={[Navigation]}
            className="mySwiper"
            style={{
              paddingRight: "3.8vmax",
            }}
          >
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
          </Swiper>

          <div className="teacher_left_arrow2">
            <FaAngleLeft />
          </div>
          <div className="teacher_right_arrow2">
            <FaAngleRight />
          </div>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">NEET Teachers</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={window.innerWidth <= 768 ? 1 : 3}
            spaceBetween={20}
            navigation={{
              nextEl: ".teacher_right_arrow3",
              prevEl: ".teacher_left_arrow3",
            }}
            modules={[Navigation]}
            className="mySwiper"
            style={{
              paddingRight: "3.8vmax",
            }}
          >
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
              />
            </SwiperSlide>
          </Swiper>

          <div className="teacher_left_arrow3">
            <FaAngleLeft />
          </div>
          <div className="teacher_right_arrow3">
            <FaAngleRight />
          </div>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="mb-4">Suggested Teachers</h3>
          </Col>
        </Row>

        <Row className="position-relative pe-5">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={window.innerWidth <= 768 ? 1 : 3}
            spaceBetween={20}
            navigation={{
              nextEl: ".teacher_right_arrow1",
              prevEl: ".teacher_left_arrow1",
            }}
            modules={[Navigation]}
            className="mySwiper"
            style={{
              paddingRight: "3.8vmax",
            }}
          >
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card2
                teacherDetails={true}
                img={card2_img}
                bottomRowText1={"Vishal Seth"}
                bottomRowText2={"4.3"}
                bottomRowText2Icon={
                  <IoStarSharp className="text_yellow me-2 fs-6" />
                }
                redirect={"/parent/teacher/123456"}
              />
            </SwiperSlide>
          </Swiper>

          <div className="teacher_left_arrow1">
            <FaAngleLeft />
          </div>
          <div className="teacher_right_arrow1">
            <FaAngleRight />
          </div>
        </Row>

      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .teacher_right_arrow2,
  .teacher_left_arrow2,
  .teacher_right_arrow1,
  .teacher_left_arrow1,
  .teacher_right_arrow3,
  .teacher_left_arrow3 {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    background: var(--yellow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    > svg {
      color: #000000;
    }
  }
  .teacher_right_arrow2,
  .teacher_right_arrow1,
  .teacher_right_arrow3 {
    right: 5%;
  }
  .teacher_left_arrow2,
  .teacher_left_arrow1,
  .teacher_left_arrow3 {
    left: -1%;
  }
  .input_container {
    border: 2px solid #e5e5e5;
    border-radius: 100px;
    width: fit-content;

    input {
      background: transparent;
      outline: none;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .search_btn_blog {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default TeacherPage;
