import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { CiSearch } from "react-icons/ci";
import Dropdown from "../courses/Dropdown";
import { FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";
import Card2 from "../parent/Card2";
import ParentFooter from "../../components/ParentFooter";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const BlogPage = (props) => {
  const { history } = props;
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownChange = (dropdownId) => {
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };
  return (
    <OftadehLayout>
      <h1>Blogs</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <StyledContainer>
        <Row className="d-flex justify-content-end pe-5">
          <div className="input_container py-2 px-3">
            <input type="text" placeholder="Search Blog" className="border-0" />
            <button>
              <CiSearch className="fs-5 text_yellow" />
            </button>
          </div>
        </Row>

        <Row className="mt-3 pe-5">
          <Col md={3} className="mt-4">
            <Dropdown
              head={"Subject"}
              lis={[
                {
                  name: "Maths",
                  id: 1,
                },
                {
                  name: "Science",
                  id: 2,
                },
                {
                  name: "English",
                  id: 3,
                },
              ]}
              active={activeDropdown === "dropdown-subject"}
              onToggle={() => handleDropdownChange("dropdown-subject")}
            />
          </Col>
          <Col md={3} className="mt-4">
            <Dropdown
              head={"Grade"}
              lis={[
                {
                  name: "1",
                  id: 1,
                },
                {
                  name: "2",
                  id: 2,
                },
                {
                  name: "3",
                  id: 3,
                },
              ]}
              active={activeDropdown === "dropdown-grade"}
              onToggle={() => handleDropdownChange("dropdown-grade")}
            />
          </Col>
          <Col md={3} className="mt-4">&nbsp;</Col>
          <Col md={3} className="mt-4">
            <div className="search_btn_blog">
              <button className="py-2 new-button">Search</button>
            </div>
          </Col>
        </Row>


        <Row className="mt-5">
          <Col>
            <h3 className="mb-2">Most Visited Blogs</h3>
          </Col>
        </Row>

        <Row className="mt-3 pe-5">
          <Col md={4} className="mt-4 pe-4">
            <Cards img={card2} />
          </Col>
          <Col md={4} className="mt-4 ps-4">
            <Cards img={card2} />
          </Col>
          <Col md={4} className="mt-4 ps-4">
            <Cards img={card2} />
          </Col>
        </Row>


       

        {/* <Row className="mt-5">
          <Col>
            <h3 className="mb-2">Latest Blogs</h3>
          </Col>
        </Row> */}

        {/* <Row className="mt-3 pe-5">
          <Col md={4} className="mt-4 pe-4">
            <Cards img={card2} />
          </Col>
          <Col md={4} className="mt-4 pe-4">
            <Cards img={card2} />
          </Col>
          <Col md={4} className="mt-4 ps-4">
            <Card2
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              blogLikeDislike={true}
            />
          </Col>
        </Row> */}
      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .input_container {
    border: 2px solid #e5e5e5;
    border-radius: 100px;
    width: fit-content;

    input {
      background: transparent;
      outline: none;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .search_btn_blog {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default BlogPage;

const Cards = ({ img }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [activeHeart, setActiveHeart] = useState(false);
  return (
    <>
      <div className="card_c py-3 px-3 bg-white rounded-3 overflow-hidden position-relative">
        <img className="cource_img rounded-3" src={img} alt="" />

        <h5 className="mt-3 fw-semibold">Top 10 Books for JEE Prep!</h5>

        <p className="mt-3">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          expedita corrupti quia, voluptas quas rem saepe deleniti iste mollitia
          in quisquam placeat distinctio explicabo earum possimus modi cum
          dolores? Recusandae, rerum iusto.
        </p>

        <div className="d-flex align-items-center justify-content-between py-2 border-top">
          <div className="">
            250+ Reviews <br />
            2 days ago
          </div>
          <span onClick={() => navigate('/blog/142512001')} className="cursor-pointer text-primary"><b>view blog {'>>'}</b></span>
        </div>

        <div className="row pt-2 border-top">
          <div className="col-9">
            <p className="m-0">
              <b>Author:</b> Sunny Dhiman
            </p>
            <p className="m-0">
              <b>Topic:</b> Competitive Exam Prep
            </p>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center gap-2">
            {activeHeart ? (
              <IoMdHeart
                className="fs-5 text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveHeart(!activeHeart)}
              />
            ) : (
              <IoMdHeartEmpty
                className="fs-5"
                style={{ cursor: "pointer" }}
                onClick={() => setActiveHeart(!activeHeart)}
              />
            )}

            <IoShareSocialOutline
              className="fs-5"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            />
          </div>
        </div>
      </div>

      <Modal show={show} size="md" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share With Your Friends</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-4 ">
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#3B5998",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaFacebookF className="fs-4 text-white " />
            </div>
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#007FB1",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaLinkedinIn className="fs-4 text-white " />
            </div>

            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaWhatsapp className="fs-4 text-white " />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
              <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                https://www.urjatalents.com/brain-bits
              </p>

              <button className="btn btn-sm btn-primary mt-md-0 mt-2">
                copy
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
