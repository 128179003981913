import React, { useEffect } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Row, Table } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { LiaSortNumericUpSolid } from "react-icons/lia";
import Chart from "chart.js/auto";

const CalculationPage = () => {
  useEffect(() => {
    const createChart = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Maths I",
            "Maths II",
            "Maths III",
            "Maths IV",
            "Maths V",
            "Maths VI",
          ],
          datasets: [
            {
              label: "Number of Students",
              data: [50, 40, 90, 70, 30, 110],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const chart1 = createChart("studentCourceGraph1");

    return () => {
      chart1.destroy();
    };
  }, []);
  return (
    <AccountLayout>
      <h3>Calculation</h3>

      <Row className="mt-5">
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search by name or ID"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="number"
              placeholder="All Transactions"
              className="bg-transparent border-yellow rounded-4 shadow"
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <button className="bg-transparent rounded-4 py-2 px-3 border-yellow fw-semibold">
            Reset
          </button>
        </Col>
        <Col md={3}>
          {/* dropdown */}
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Select className="rounded-5 shadow border-yellow">
              <option value="option1">Month</option>
              <option value="option1">Week</option>
              <option value="option1">Day</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col
          md={4}
          className="d-flex align-items-center gap-3 justify-content-end"
        >
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4">
            <CiFilter /> Filter
          </button>
          <button className="d-flex align-items-center gap-2 bg-transparent border-yellow py-2 px-3 rounded-4 ">
            <LiaSortNumericUpSolid /> Sort
          </button>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr className="bg-yellow ">
                <th className="text-white">#</th>
                <th className="text-white">Transaction ID</th>
                <th className="text-white">Amount</th>
                <th className="text-white">Payment Type</th>
                <th className="text-white">Payment Status</th>
                <th className="text-white">Payment Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>2</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>3</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>4</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>5</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>6</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>7</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
              <tr>
                <td>8</td>
                <td>123456789</td>
                <td>1000</td>
                <td>UPI</td>
                <td>Success</td>
                <td>12/05/2021</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4 justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <p className="m-0 fw-semibold text-decoration-underline text_yellow" style={{ cursor: "pointer" }}>
            View More
          </p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <div className="shadow p-3 rounded-4">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Profits vs Gross Profits
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border-0 fw-semibold py-1 px-2 rounded-3">
                  Day
                </button>
                <button className=" border-0 fw-semibold py-1 px-2 rounded-3">
                  Week
                </button>
                <button className="border-0 bg-yellow fw-semibold py-1 px-2 rounded-3 text-white">
                  Month
                </button>
              </div>
            </div>

            <hr />
            <canvas id="studentCourceGraph1"></canvas>
          </div>
        </Col>
      </Row>
    </AccountLayout>
  );
};

export default CalculationPage;
