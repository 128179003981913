import React from 'react'
import OftadehLayout from '../../components/parentComponents/OftadehLayout/OftadehLayout'
import OftadehBreadcrumbs from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import { BsSearch } from 'react-icons/bs';
import { FaFilePdf, FaArrowAltCircleDown } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";

import { Row, Col, Form, InputGroup } from 'react-bootstrap'
import './Resources.css';
import ParentFooter from '../../components/ParentFooter';
function Resources(props) {
    const { history } = props;

    return (
        <OftadehLayout>
            <h3>E-Books</h3>
            {/* <OftadehBreadcrumbs path={history} /> */}

            <Row>
                {/* <Col md={3} className='d-flex align-items-center justify-content-between mt-3'>
                    <Form.Group controlId="gradeFilter">
                        <Form.Select className='shadow rounded-5'>
                        <option value="">Select Resource Type</option>
                            <option value="">E-Books</option>
                        </Form.Select>
                    </Form.Group>
                </Col> */}

                <Col md={3}>
                    <Form.Group controlId="gradeFilter">
                        <Form.Select className='shadow rounded-5 mt-4'>
                            <option value="">Select Grade</option>
                            <option value="1">Grade 1</option>
                            <option value="2">Grade 2</option>
                            <option value="3">Grade 3</option>
                            {/* Add more options as needed */}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="topicFilter">
                        <Form.Select className='shadow rounded-5 mt-4'>
                            <option value="">Select Subject</option>
                            <option value="Math">Math</option>
                            <option value="Science">Science</option>
                            <option value="History">History</option>
                            {/* Add more options as needed */}
                        </Form.Select>
                    </Form.Group>
                </Col>
                {/* <Col md={3}>
                    <Form.Group controlId="courseFilter">
                        <Form.Select className='shadow rounded-5 mt-4'>
                            <option value="">Select Course</option>
                            <option value="English">English</option>
                            <option value="Physics">Physics</option>
                            <option value="Geography">Geography</option>
                        </Form.Select>
                    </Form.Group>
                </Col> */}
                <Col md={3}>
                    <Form.Group controlId="searchInput">
                       <Form.Control placeholder='Search by title' className='shadow rounded-5 mt-4'/>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <button className="new-button mt-4">Apply Filters</button>
                </Col>
            </Row>

            {/* <Row className='mt-5'>
                <h5>E-Books</h5>
            </Row> */}

            <Row className='mt-4'>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> HC Verma</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
                <Col md={4} style={{ padding: '10px' }}>
                    <div className="resorce-parent-item">
                        <div className="name"><FaFilePdf /> DC Pandey</div>
                        <MdOutlineFileDownload />
                    </div>
                </Col>
            </Row>
            <ParentFooter />
        </OftadehLayout>
    )
}

export default Resources