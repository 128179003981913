import { Button } from '@mui/material'
import React from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap'

function OverDue() {
    return (
        <>
            <Table className="mt-3" responsive striped hover bordered>
                <thead>
                    <tr>
                        <th>Transaction ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>User</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Urja#1234</td>
                        <td>John Smith</td>
                        <td>Saving</td>
                        <td>Primary</td>
                        <td>end user</td>
                        <td>12 Jun 2012</td>
                    </tr>
                    <tr>
                        <td>Urja#1234</td>
                        <td>John Smith</td>
                        <td>Saving</td>
                        <td>Primary</td>
                        <td>end user</td>
                        <td>12 Jun 2012</td>
                    </tr>
                    <tr>
                        <td>Urja#1234</td>
                        <td>John Smith</td>
                        <td>Saving</td>
                        <td>Primary</td>
                        <td>end user</td>
                        <td>12 Jun 2012</td>
                    </tr>
                </tbody>
            </Table>
            <Col className="d-flex align-items-center justify-content-end"><Button className="teacher-button shadow border-0 rounded-5" variant="outlined">View More</Button></Col>
            <Container className='mt-3 mb-3 rounded-2 shadow border-yellow p-0' style={{ overflow: 'hidden' }}>
                <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '7vh' }}>
                    <Col md={6} className="d-flex align-items-center justify-content-center p-0 text-center" style={{ borderBottom: '3px solid #FFD32B', height: '100%' }}>
                        <h6>High Profit customer</h6>
                    </Col>
                    <Col md={6} className="d-flex align-items-center justify-content-center p-0 text-center" style={{ borderBottom: '3px solid grey', height: '100%' }}>
                        <h6>Low Profit customer</h6>
                    </Col>
                </div>
                <Row className='ps-2 pe-2 pt-4'>
                    <Col md={3} className="d-flex justify-content-center">
                        <Card className='shadow' style={{ width: '18rem', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
                            <div className="text-center p-4">
                                <div style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden', margin: '0 auto' }}>
                                    <Card.Img variant="top" src="https://via.placeholder.com/150" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                </div>
                                <Card.Body>
                                    <h6>User Name</h6>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center">
                        <Card className='shadow' style={{ width: '18rem', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
                            <div className="text-center p-4">
                                <div style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden', margin: '0 auto' }}>
                                    <Card.Img variant="top" src="https://via.placeholder.com/150" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                </div>
                                <Card.Body>
                                    <h6>User Name</h6>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center">
                        <Card className='shadow' style={{ width: '18rem', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
                            <div className="text-center p-4">
                                <div style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden', margin: '0 auto' }}>
                                    <Card.Img variant="top" src="https://via.placeholder.com/150" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                </div>
                                <Card.Body>
                                    <h6>User Name</h6>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center">
                        <Card className='shadow' style={{ width: '18rem', margin: '10px', borderRadius: '15px', overflow: 'hidden' }}>
                            <div className="text-center p-4">
                                <div style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden', margin: '0 auto' }}>
                                    <Card.Img variant="top" src="https://via.placeholder.com/150" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                                </div>
                                <Card.Body>
                                    <h6>User Name</h6>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Col className='mt-2 mb-2'>
                    <center><h6><u>See more Customors</u></h6></center>
                </Col>

            </Container>
        </>
    )
}

export default OverDue