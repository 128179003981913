import React, { useState } from 'react'
import OftadehLayout from '../../components/parentComponents/OftadehLayout/OftadehLayout'
import OftadehBreadcrumbs from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import './Notifications.css';
import { Col, Row } from 'react-bootstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import ParentFooter from '../../components/ParentFooter';
function Notifications(props) {
    const { history } = props;

    return (
        <OftadehLayout>
            <h1>Notifications</h1>
            {/* <OftadehBreadcrumbs path={history} /> */}
            
            <Row>
                <span className="notification-title-heading-parent">Select Notification for</span>
            </Row>

            <Row className='mt-3'>
                <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="Class Remainders"
                        control={<Checkbox style={{color:'black'}} />}
                        label="Class Remainders"
                        labelPlacement="Class Remainders"
                    />
                </Col>
                {/* <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="Marketing"
                        control={<Checkbox style={{color:'black'}} />}
                        label="Marketing"
                        labelPlacement="Marketing"
                    />
                </Col> */}
                <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="New Classes"
                        control={<Checkbox style={{color:'black'}} />}
                        label="New Classes"
                        labelPlacement="New Classes"
                    />
                </Col>
                <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="Favourite Teachers"
                        control={<Checkbox style={{color:'black'}} />}
                        label="Favourite Teachers"
                        labelPlacement="Favorate Teachers"
                    />
                </Col>
                {/* <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="Marketing"
                        control={<Checkbox style={{color:'black'}} />}
                        label="Marketing"
                        labelPlacement="Marketing"
                    />
                </Col> */}
                <Col md={3} className='d-flex align-items-center justify-content-start' style={{gap:'10px',color:'black'}}>
                    <FormControlLabel
                        value="Offering New Courses"
                        control={<Checkbox style={{color:'blue'}}/>}
                        label="Offering New Courses"
                        labelPlacement="Offering New Courses"
                    />
                </Col>
            </Row>

            <Row className='mt-3'>
                <span className="notification-title-heading-parent">WhatsApp Notifications log</span>
            </Row>

            <Row className='mt-3'>
                <Col md={12} > <span className="date-time-for-parent-notification">01/01/2024 09:00 am</span> <span className="log-item-for-parent-notification">Lorem ipsum dolor sit amet.</span> </Col>
                <Col md={12} > <span className="date-time-for-parent-notification">01/01/2024 09:00 am</span> <span className="log-item-for-parent-notification">Lorem ipsum  Lorem ipsum dolor sit amet.</span> </Col>
                <Col md={12} > <span className="date-time-for-parent-notification">01/01/2024 09:00 am</span> <span className="log-item-for-parent-notification">Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.</span> </Col>
                <Col md={12} > <span className="date-time-for-parent-notification">01/01/2024 09:00 am</span> <span className="log-item-for-parent-notification">Lorem ipsum dolor sit um dolor sit amet.</span> </Col>
                <Col md={12} > <span className="date-time-for-parent-notification">01/01/2024 09:00 am</span> <span className="log-item-for-parent-notification">Lorem ipsum dolor sit amet.</span> </Col>
            </Row>
            <ParentFooter />
        </OftadehLayout>
    )
}

export default Notifications