// Tags
import TagsPage from "./TagsPage";

export const TagsPageConfig = {
  routes: [
    {
      path: "/operation/tags",
      exact: true,
      component: TagsPage
    }
  ]
};