import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { IoPlaySharp } from "react-icons/io5";
import VideoModal from "../../ParentPages/transcript/VideoModal ";
import img from "../../assets/parent/card3.png";
import { Row, Col, Dropdown, Form, InputGroup } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import ParentFooter from "../../components/ParentFooter";

const TeacherLivePage = () => {
  const [showModalVideo, setShowModalVideo] = useState(false);

  const handleVideoClose = () => setShowModalVideo(false);
  const handleVideoShow = () => setShowModalVideo(true);
  return (
    <TeacherLayout>
      {/* <h3>Live</h3> */}

      <StyledContainer>
        <h4 className="my-4">Live Sessions</h4>
        <Row className="mt-4">
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p
                    className="m-0 text_yellow fw-bold"
                    style={{ cursor: "pointer" }}
                  >
                    Join live <FaAngleRight />
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <h4 className="mb-4 mt-5">Recorded Live Sessions</h4>

        <Row className="mt-4">
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <VideoModal
              showModal={showModalVideo}
              handleClose={handleVideoClose}
            />

            <div className="card3 shadow rounded-4">
              <div className="top p-3 d-flex align-items-center justify-content-center position-relative">
                <img src={img} className="rounded-4" alt="" />
                <div
                  className="play d-flex align-items-center justify-content-center"
                  onClick={() => handleVideoShow()}
                >
                  <IoPlaySharp className="fs-4" />
                </div>
              </div>
              <div className="bottom px-4 pb-3 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="m-0 fw-bold">Mathematics</h6>
                  <p className="m-0">3pm- 4pm ist</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="m-0">Vishal seth </p>
                  <p className="m-0">13 June 2023</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={8} className="d-flex align-items-center gap-3">
            <h5>Enter topic title:</h5>
            <Form.Control
              type="text"
              placeholder="Enter topic title"
              style={{
                width: "20vw",
                border: "1px solid var(--color-primary)"
              }}
              className="bg-transparent rounded-4"
            />
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <button
              className="rounded-4 btn text-white"
              style={{ background: "var(--color-primary)" }}
            >
              Go Live
            </button>
          </Col>
        </Row>

        <h4 className="mb-4 mt-5">Schedule Live</h4>

        <Row className="mt-5">
          <Col md={8} className="d-flex align-items-center gap-3">
            <h5>Enter topic title:</h5>
            <Form.Control
              type="text"
              placeholder="Enter topic title"
              style={{
                width: "20vw",
                border: "1px solid var(--color-primary)"
              }}
              className="bg-transparent rounded-4"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={8} className="d-flex align-items-center gap-3">
            <h5>Select Schedule:</h5>
            <InputGroup
              style={{
                width: "20vw",
                border: "1px solid var(--color-primary)",
                boxShadow: "none",
              }}
              className="bg-transparent rounded-4"
            >
              <Form.Control
                style={{
                  boxShadow: "none",
                }}
                type="date"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
                className="bg-transparent border-0 rounded-4"
              />
            </InputGroup>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <button
              className="rounded-4 btn text-white"
              style={{ background: "var(--color-primary)" }}
            >
              Schedule live
            </button>
          </Col>
        </Row>
      </StyledContainer>

      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .card3 {
    width: 100%;
    height: 100%;

    .top {
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid var(--color-dark);
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-primary);
        border: none;
        color: white;
      }
    }
  }
`;

export default TeacherLivePage;
