import ChatPageNew from "./ChatPageNew";

export const ChatPageConfig = {
  routes: [
    {
      path: "/teacher/chat",
      exact: true,
      component: ChatPageNew
    }
  ]
};
