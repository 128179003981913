import GoLivePage from "./GoLivePage";
import GroupLivePage from "./GroupLive";

export const GoLivePageConfig = {
  routes: [
    {
      path: "/parent/dashboard/live/1-on-1",
      exact: true,
      component: GoLivePage,
    },
    {
      path: "/parent/dashboard/live/group",
      exact: true,
      component: GroupLivePage,
    },
  ],
};
