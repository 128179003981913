// Affliate
import AffliatePage from "./AffliatePage";

export const AffliatePageConfig = {
  routes: [
    {
      path: "/operation/Affliate",
      exact: true,
      component: AffliatePage
    }
  ]
};
