import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Row, Col, Container, Table } from "react-bootstrap";
import { Button, ButtonGroup, Checkbox, FormControlLabel } from "@mui/material";
import { Edit } from "@material-ui/icons";
import { MdCloudDownload } from "react-icons/md";
import ParentFooter from "../../components/ParentFooter";

const PaymentPage = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [usage, setUsage] = useState(false);
  return (
    <TeacherLayout>
      <h3>Payment</h3>

      <div className="py-3 px-4 rounded-4 shadow bg-white mt-4 mb-5 row">
        <h3 className="mb-4">Payment History</h3>
        <div className="col-12 mb-3 d-flex align-items-center justify-content-between">
          <p>
            1. Payment for <b>English Course</b>
          </p>
          <button className="btn btn-success rounded-5">
            Download Invoice <MdCloudDownload className=" fs-5 ms-2" />
          </button>
        </div>
        <div className="col-12 mb-3 d-flex align-items-center justify-content-between">
          <p>
            2. Payment for <b>Math Course</b>
          </p>
          <button className="btn btn-success rounded-5">
            Download Invoice <MdCloudDownload className=" fs-5 ms-2" />
          </button>
        </div>
      </div>

      <div className="top-payment-tab-action">
        <div
          onClick={() => setCurrentTab(1)}
          className={
            currentTab === 1
              ? "top-payment-tab-items active-payment-parent-tab"
              : "top-payment-tab-items"
          }
        >
          Account Details
        </div>
        <div
          onClick={() => setCurrentTab(2)}
          className={
            currentTab === 2
              ? "top-payment-tab-items active-payment-parent-tab"
              : "top-payment-tab-items"
          }
        >
          Payment Gateway
        </div>
        <div
          onClick={() => setCurrentTab(3)}
          className={
            currentTab === 3
              ? "top-payment-tab-items active-payment-parent-tab"
              : "top-payment-tab-items"
          }
        >
          Check Chat
        </div>
        <div
          onClick={() => setCurrentTab(4)}
          className={
            currentTab === 4
              ? "top-payment-tab-items active-payment-parent-tab"
              : "top-payment-tab-items"
          }
        >
          Refund Payment
        </div>
        {/* <div className="top-payment-tab-items">&nbsp;</div> */}
      </div>

      {currentTab === 1 && (
        <>
          <Container className="payment-parent-container mt-3 p-0 m-0 ">
            <Col className="payment-parent-container-navbar d-flex align-items-center justify-content-between">
              <span className="bank-details-title-payment">Bank Details</span>
              <span className="parent-payment-account-details-button-responsive">
                <Button
                  variant="contained"
                  className="bg-dark text-white rounded-5"
                  startIcon={<Edit />}
                >
                  {" "}
                  Add Account{" "}
                </Button>
                <Button
                  className="ms-2 bg-dark text-white rounded-5"
                  variant="contained"
                  startIcon={<Edit />}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </span>
            </Col>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Country Code</b>
              </Col>
              <Col md={8}>IND</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Currency Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Status</b>
              </Col>
              <Col md={8}>Active</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Bank Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Branch Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Type</b>
              </Col>
              <Col md={8}>Saving</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Number</b>
              </Col>
              <Col md={8}>XXXXXXXX3354</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Name</b>
              </Col>
              <Col md={8}>Learniee</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Branch</b>
              </Col>
              <Col md={8}>Ahjdhajd</Col>
            </Row>
            <hr />
            <Row className="ps-3 pb-3">
              <Col>
                <b>Updated By : </b> XYZ
              </Col>
              <Col>
                <b>Last Updated : </b> 04/12/23
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 2 && (
        <>
          <Container className="payment-parent-container mt-3 p-0 m-0 ">
            <Col className="payment-parent-container-navbar d-flex align-items-center justify-content-between">
              <span className="bank-details-title-payment">RazorPay</span>
              <span>
                <span style={{ color: "blue" }}>Help</span>
                <ArrowDropDownIcon />
              </span>
            </Col>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>Key*</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>Shared Secret*</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>URL</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pb-3">
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Default payment provider - This will become your default payment provider."
              />
            </Row>
            <Col className="ps-3 pb-3">
              <button className="new-button" variant="contained">
                Update & Connect
              </button>
            </Col>
          </Container>
        </>
      )}

      {/* ------------------- Third Page 1 ----------------------- */}

      {currentTab === 3 && usage === false && (
        <>
          <ButtonGroup
            className="mt-3"
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              className="text-white"
              style={{
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderColor: "var(--color-primary)",
                backgroundColor: "var(--color-primary)",
              }}
            >
              Payment Information
            </Button>
            <Button
              onClick={() => setUsage(true)}
              style={{
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderColor: "var(--color-primary)",
                color: "black",
              }}
              variant="outlined"
            >
              Usage
            </Button>
          </ButtonGroup>

          <Col
            md={8}
            className="p-2 ps-3 rounded-5 mt-3 mb-3 text-white"
            style={{ background: "var(--color-primary)" }}
          >
            Ad HOC Payment Information
          </Col>

          <Row className="ps-3">
            <Col md={4}>Company</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Bank Account</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payee</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>New Ad Hoc Payee</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Currency</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payment Date</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="date"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payment Type</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
        </>
      )}

      {/* ------------------- Third Page 2 ----------------------- */}

      {currentTab === 3 && usage === true && (
        <>
          <ButtonGroup
            className="mt-3"
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              onClick={() => setUsage(false)}
              variant="outlined"
              style={{
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderColor: "var(--color-primary)",
                color: "black",
              }}
            >
              Payment Information
            </Button>
            <Button
              className="text-white"
              style={{
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderColor: "var(--color-primary)",
                backgroundColor: "var(--color-primary)",
              }}
              variant="outlined"
            >
              Usage
            </Button>
          </ButtonGroup>

          <Col
            md={8}
            className=" p-2 ps-3 rounded-5 mt-3 mb-3 text-white"
            style={{ background: "var(--color-primary)" }}
          >
            Usage
          </Col>

          <Row className="ps-3">
            <Col md={4}>Type</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Primary</Col>
            <Col md={4}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label=""
              />
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Use For</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Visibility</Col>
            <Col md={4}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label=""
              />
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>

          <Row className="ps-3 mt-3">
            <Col md={4}>Comments</Col>
            <Col md={4}>
              <div className="col-md-auto">
                {/* Replace the input with a textarea */}
                <textarea
                  className="form-control"
                  id="FirstName"
                  placeholder=""
                ></textarea>
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Col
            className="responsive-parent-payment-check-chat-button  mt-3"
            style={{ gap: "10px" }}
          >
            <button className="new-button" variant="contained">
              Submit
            </button>
            <button className="new-button" variant="contained">
              Save For Later
            </button>
            <button className="new-button" variant="contained">
              Get Approval From Admin
            </button>
            <button className="new-button bg-danger" variant="contained">
              Cancel
            </button>
          </Col>
        </>
      )}

      {
        // ------------------- Fourth Page -----------------------
        currentTab === 4 && (
          <Row className="mt-5">
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr className="bg-yellow ">
                    <th className="text-white">#</th>
                    <th className="text-white">Transaction ID</th>
                    <th className="text-white">Amount</th>
                    <th className="text-white">Payment Type</th>
                    <th className="text-white">Payment Status</th>
                    <th className="text-white">Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>123456789</td>
                    <td>1000</td>
                    <td>UPI</td>
                    <td>Success</td>
                    <td>12/05/2021</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        )
      }
      <ParentFooter />
    </TeacherLayout>
  );
};

export default PaymentPage;
