import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import navigationConfig from "../../../oftadeh-configs/navigationConfig";
import axios from "../../../utils/axios";
import OftadehNavGroup from "./sections/OftadehNavGroup";
import OftadehNavCollapse from "./sections/OftadehNavCollapse";
import OftadehNavItem from "./sections/OftadehNavItem";
import OftadehNavLink from "./sections/OftadehNavLink";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getCommonInfo } from "../../../utils/MyAPI";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logoBg: {
    // backgroundColor: theme.palette.type !== "dark" && "#18202c",
    // backgroundColor: "var(--color-primary)",
    // backgroundColor: "linear-gradient(to right, var(--color-primary), red)",
  },
  logo: {
    padding: "1rem",
    "& span": {
      display: "block",
      color: "red",
    },
  },
  navCustom: {
    "& .MuiTypography-root": {
      fontSize: ".75rem",
      // color: "#4B4B5A",
      // fontWeight: "600",
      fonfFamily: "Poppins, sans-serif",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
      paddingBottom: "8px",
    },
    "& .MuiCollapse-wrapperInner a": {
      paddingLeft: "50px",
    },
  },
  navCustom2: {
    pointerEvents: "none",
    "& .MuiTypography-root": {
      fontSize: ".75rem",
      color: "#a6a6b1",
      fontWeight: "600",
      fonfFamily: "Poppins, sans-serif",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
      paddingBottom: "8px",
    },
    "& .MuiCollapse-wrapperInner a": {
      paddingLeft: "50px",
    },
  },
}));

const OftadehNavigation = (props) => {
  const classes = useStyles(props);
  const [currentStep, setCurrentStep] = useState(1);
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const { data } = await getCommonInfo(token);
        // console.log("Parent info: ", data);
        if (data.res_type === "success") {
          if (data.data.profileState.isRegistered === true) {
            setCurrentStep(2);
          }
          if (data.data.profileState.hasChildren === true) {
            // navigate("/parent/dashboard");
            setCurrentStep(3);
          }
        }
      } catch (error) {
        console.log("Error fetching parent info: ", error);
      }
    };
    fetchParentInfo();
  }, [token]);

  return (
    <div>
      <div className={clsx(classes.toolbar, classes.logoBg)}>
        <Typography
          className={classes.logo}
          variant="h6"
          component="h1"
          align="center"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          Akshay Dangi <br />
          <span style={{ fontSize: 13, color: "#404040" }}>
            <b>ID :</b>
            83487
          </span>
        </Typography>
      </div>
      <Divider />
      <List
        className={currentStep === 3 ? classes.navCustom : classes.navCustom2}
      >
        {navigationConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && <OftadehNavGroup item={item} />}

            {item.type === "collapse" && <OftadehNavCollapse item={item} />}

            {item.type === "item" && <OftadehNavItem item={item} />}

            {item.type === "link" && <OftadehNavLink item={item} />}

            {item.type === "divider" && <Divider className="my-16" />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default OftadehNavigation;
