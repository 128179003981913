import React, { useState } from "react";
import { Row, Col, Form, Table, Badge } from "react-bootstrap";
import { SlOptionsVertical } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import { IoEye } from "react-icons/io5";
import { FcAcceptDatabase, FcCancel } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
function ReqBlogs() {
  const [selectedBlogCat, setSelectedBlogCat] = useState("admin");
  const navigate = useNavigate();
  const blogs = [
    {
      id: 1,
      publisherName: "John Doe",
      blogTitle: "Lorem Ipsum",
      authorName: "John Doe",
      status: "Accepted",
      dateOfRequest: "2022-04-11",
      publishDate: "2022-04-12",
      actionDate: "2022-04-13",
    },
    {
      id: 2,
      publisherName: "Jane Smith",
      blogTitle: "Dolor Sit Amet",
      authorName: "John Doe",
      status: "Rejected",
      dateOfRequest: "2022-04-11",
      publishDate: "2022-04-13",
      actionDate: "2022-04-14",
    },
    // Add more dummy data here
  ];

  return (
    <>
      <OperationLayout>
        <Row className="mt-3 mb-3">
          <Col>
            <h5 className="text-uppercase">{selectedBlogCat} Blogs</h5>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Select
              onChange={(e) => setSelectedBlogCat(e.target.value)}
              className="rounded-5 shadow"
            >
              <option value="teacher">Teacher</option>
              <option value="parent">Parent</option>
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Select className="rounded-5 shadow">
              <option value="Active">Accepted</option>
              <option value="Inactive">Rejected</option>
              <option value="Inactive">Pending</option>
            </Form.Select>
          </Col>
          <Col md={6}></Col>
        </Row>
        <Table
          className="mt-5 mb-3 rounded-4"
          style={{ backgroundColor: "var(--color-primary)" }}
          striped
          responsive
          hover
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">No.</th>
              <th className="text-white">Publisher Name</th>
              <th className="text-white">Blog Title</th>
              <th className="text-white">Author Name</th>
              <th className="text-white">Status</th>
              <th className="text-white">Date of Request</th>
              <th className="text-white">Publish Date</th>
              <th className="text-white">Action Date</th>
              <th className="text-white" colSpan={3}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog, index) => (
              <tr key={blog.id} className="text-center bg-light">
                <td>{index + 1}</td>
                <td>{blog.publisherName}</td>
                <td>{blog.blogTitle}</td>
                <td>{blog.authorName}</td>
                <td>
                  <Badge
                    bg={
                      blog.status.toLowerCase() === "accepted"
                        ? "success"
                        : blog.status.toLowerCase() === "rejected"
                        ? "danger"
                        : "warning"
                    }
                  >
                    {blog.status}
                  </Badge>
                </td>
                <td>{blog.dateOfRequest}</td>
                <td>{blog.publishDate}</td>
                <td>{blog.actionDate}</td>
                <td>
                  <IconButton
                    className="cursor-pointer"
                    size="small"
                    aria-label="edit"
                    onClick={() => navigate("/blog/652367836")}
                  >
                    <IoEye size={22} />
                  </IconButton>
                </td>
                <td>
                  <IconButton
                    className="cursor-pointer"
                    size="small"
                    aria-label="accept"
                  >
                    <FcAcceptDatabase size={22} />
                  </IconButton>
                </td>
                <td>
                  <IconButton
                    className="cursor-pointer"
                    size="small"
                    aria-label="reject"
                  >
                    <FcCancel size={22} />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </OperationLayout>
    </>
  );
}

export default ReqBlogs;
