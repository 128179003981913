import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row, Button, Form } from "react-bootstrap";
import { FaAngleLeft, FaPlus } from "react-icons/fa6";

const ManagePage = (props) => {
  const { history } = props;
  const [activeAppointment, setActiveAppointment] = React.useState(false);
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h3>Manage </h3>

      {activeAppointment === false ? (
        <>
          <Row className="mt-5">
            <Col md={6}>
              <h5 className="fw-bold">Appointments</h5>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-md-end justify-content-start pe-5"
            >
              <button
                className="py-2 px-4 fw-bold text_yellow rounded-4 border-yellow bg-transparent"
                onClick={() => setActiveAppointment(true)}
              >
                <FaPlus className="me-2" /> Create Appointment
              </button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <h5 className="fw-bold">Invoices</h5>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-start justify-content-md-end pe-5"
            >
              <button className="py-2 px-4 fw-bold text_yellow rounded-4 border-yellow bg-transparent">
                <FaPlus className="me-2" /> Create Invoice
              </button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-5">
            <p
              className="m-0"
              onClick={() => setActiveAppointment(false)}
              style={{ cursor: "pointer" }}
            >
              <FaAngleLeft />
              Create appointment
            </p>
            <Col md={6} className="mt-4">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Select className="rounded-5 shadow border-yellow">
                  <option value="option1">Appointment to</option>
                  <option value="option1">1</option>
                  <option value="option1">2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12} className="pe-5 mt-4">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="text"
                  placeholder="Appointment Title"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-4">
              {/* input for select date with react bootstrap date input*/}
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="date"
                  placeholder="Appointment Title"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mt-4">
              {/* input for select time with react bootstrap time input*/}
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Control
                  className="rounded-5 shadow border-yellow"
                  type="time"
                  placeholder="Appointment Title"
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </OperationLayout>
  );
};

export default ManagePage;
