// Code
import CodePage from "./CodePage";

export const CodePageConfig = {
  routes: [
    {
      path: "/operation/code",
      exact: true,
      component: CodePage
    }
  ]
};
