import React, { useState } from "react";
import { Col, Form, Row, Table, Button, Modal } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { IconButton } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

const BookResourcesPage = (props) => {
  const { history } = props;
  const [selectedOption, setSelectedOption] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([
    {
      id: 1,
      type: "book",
      title: "topic",
      author: "Course Name",
      publishDate: "12 January 2023",
      edition: "10th",
    },
    {
      id: 2,
      type: "book",
      title: "topic",
      author: "Course Name",
      publishDate: "12 January 2023",
      edition: "10th",
    },
    { id: 3, type: "resource", title: "Resource 1", link: "Link 1" },
    { id: 4, type: "resource", title: "Resource 2", link: "Link 2" },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedAuthor, setEditedAuthor] = useState("");
  const [editedLink, setEditedLink] = useState("");
  const [editedEdition, setEditedEdition] = useState("");
  const [editedPublished, setEditedPublished] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setEditedTitle(item.title);
    setEditedEdition(item.edition);
    setEditedPublished(item.publishDate);
    setEditedAuthor(item.author || "");
    setEditedLink(item.link || "");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingItem(null);
    setEditedTitle("");
    setEditedAuthor("");
    setEditedLink("");
  };

  const handleSaveChanges = () => {
    // Update the item in the data array
    const updatedData = data.map((item) => {
      if (item.id === editingItem.id) {
        return {
          ...item,
          title: editedTitle,
          author: editedAuthor,
          link: editedLink,
          edition: editedEdition,
          publishDate: editedPublished,
        };
      }
      return item;
    });
    setData(updatedData);
    handleCloseModal();
  };

  return (
    <OperationLayout>
      <h4>Books and Resources</h4>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={3}>
          <Form.Group controlId="selectOption">
            <Form.Select
              className="shadow rounded-5 mt-3"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value={"1"}>Books</option>
              <option value={"2"}>Resources</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <button
            onClick={() => setShowAddModal(true)}
            className="new-button mt-3"
          >
            Add {selectedOption == "1" ? "Book" : "Resources"}
          </button>
        </Col>
      </Row>
      <Table
        className="mt-5 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">Topic</th>
            {selectedOption === "1" && (
              <>
                <th className="text-white">Course Name</th>
                <th className="text-white">Grade</th>
                <th className="text-white">Published Date</th>
              </>
            )}
            {selectedOption === "2" && <th className="text-white">Link</th>}
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter(
              (item) =>
                item.type === (selectedOption === "1" ? "book" : "resource")
            )
            .filter((item) =>
              item.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((item) => (
              <tr key={item.id} className="bg-light">
                <td>{item.title}</td>
                {selectedOption === "1" && (
                  <>
                    <td>{item.author}</td>
                    <td>{item.edition}</td>
                    <td>{item.publishDate}</td>
                  </>
                )}
                {selectedOption === "2" && <td>{item.link}</td>}
                <td>
                  <IconButton
                    onClick={() => handleEdit(item)}
                    aria-label="delete"
                  >
                    <CiEdit color="blue" />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdDelete color="red" />
                  </IconButton>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", paddingTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="editedTitle">
            <Form.Label>Topic</Form.Label>
            <Form.Control
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
          </Form.Group>
          {selectedOption === "1" && (
            <>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Course Name</Form.Label>
                <Form.Control
                  type="text"
                  value={editedAuthor}
                  onChange={(e) => setEditedAuthor(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Grade</Form.Label>
                <Form.Control
                  type="text"
                  value={editedEdition}
                  onChange={(e) => setEditedEdition(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Published Date</Form.Label>
                <Form.Control
                  type="text"
                  value={editedPublished}
                  onChange={(e) => setEditedPublished(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Upload document</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </>
          )}
          {selectedOption === "2" && (
            <Form.Group controlId="editedLink">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                value={editedLink}
                onChange={(e) => setEditedLink(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        style={{ zIndex: "1111", paddingTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add {selectedOption == "1" ? "Book" : "Resources"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="editedTitle">
            <Form.Label>Topic</Form.Label>
            <Form.Control type="text" value={""} />
          </Form.Group>
          {selectedOption === "1" && (
            <>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Course Name</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Grade</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Published Date</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
              <Form.Group controlId="editedAuthor">
                <Form.Label>Upload document</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </>
          )}
          {selectedOption === "2" && (
            <Form.Group controlId="editedLink">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => setShowAddModal(false)}>
            Add {selectedOption == "1" ? "Book" : "Resources"}
          </Button>
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

export default BookResourcesPage;
