// ParentsFeesWithID
import ParentsFeesWithIDPage from "./ParentsFeesWithIDPage";

export const ParentsFeesWithIDPageConfig = {
  routes: [
    {
      path: "/account/bank-details/:id",
      exact: true,
      component: ParentsFeesWithIDPage
    }
  ]
};
