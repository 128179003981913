import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const FinanceGraph = ({ title, legendData, yAxisData, seriesData }) => {
  const chartRef = useRef(null);

  const labelSetting = {
    show: true,
    position: "right",
    offset: [10, 0],
    fontSize: 16,
  };

  const pathSymbols = {
    reindeer: "path://M0,10 L10,10 L5,0 L0,10",
    ship: "path://M0,10 L10,10 L5,0 L0,10",
    plane: "path://M0,10 L10,10 L5,0 L0,10",
    train: "path://M0,10 L10,10 L5,0 L0,10",
    car: "path://M0,10 L10,10 L5,0 L0,10",
  };

  const makeOption = (type, seriesData, symbol) => {
    return {
      title: {
        text: title,
      },
      legend: {
        data: legendData,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        containLabel: true,
        left: 20,
      },
      yAxis: {
        data: yAxisData,
        inverse: true,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          margin: 30,
          fontSize: 14,
        },
        axisPointer: {
          label: {
            show: true,
            margin: 30,
          },
        },
      },
      xAxis: {
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { show: false },
      },
      animationDurationUpdate: 500,
      series: seriesData.map((series, idx) => ({
        name: legendData[idx],
        id: `bar${idx + 1}`,
        type: type,
        label: labelSetting,
        symbolRepeat: true,
        symbolSize: ["80%", "60%"],
        barCategoryGap: "40%",
        universalTransition: {
          enabled: true,
          delay: function (idx, total) {
            return (idx / total) * 1000;
          },
        },
        data: series.map((item) => ({
          value: item.value,
          symbol: item.symbol || pathSymbols[item.symbolKey] || symbol,
        })),
      })),
    };
  };

  const options = [
    makeOption("pictorialBar", seriesData),
    makeOption("bar", seriesData),
    makeOption("pictorialBar", seriesData, "diamond"),
  ];

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);
    let optionIndex = 0;

    const updateChart = () => {
      optionIndex = (optionIndex + 1) % options?.length;
      chartInstance.setOption(options[optionIndex]);
    };

    const interval = setInterval(updateChart, 2500);

    chartInstance.setOption(options[optionIndex]);

    return () => {
      clearInterval(interval);
      chartInstance.dispose();
    };
  }, [title, legendData, yAxisData, seriesData]);

  return <div ref={chartRef} style={{ width: "100%", height: "450px" }} />;
};

export default FinanceGraph;
