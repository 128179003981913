import React from "react";
import { Col, Container, Row } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { Edit } from "@material-ui/icons";


const OPSBanksPage = (props) => {
    const { history } = props;

    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h5>OPS Banks</h5>
            <Row className="p-1 rounded-3" style={{ border: '1px solid #ffc107' }}>
                <Col md={6}>
                    <input
                        type="text"
                        className="border-0 ps-3"
                        placeholder="http://localhost:3000/pay/uysdy7ys544652sd"
                        style={{ width: '100%', height: '6vh', border: 'none', outline: 'none', backgroundColor: 'transparent' }}
                    />
                </Col>
                <Col md={6} className="d-flex justify-content-end p-1">
                    <Button className="teacher-button shadow rounded-5 border-0" style={{ width: 'fit-content' }} variant="outlined" size="small" startIcon={<IoMdAdd />}>
                        Add Account
                    </Button>
                    <Button className="ms-3 teacher-button shadow rounded-5 border-0" variant="outlined" size="small">
                        Copy
                    </Button>
                </Col>
            </Row>
            <Container className='payment-parent-container mt-3 p-0 m-0 '>
                <Col className='payment-parent-container-navbar d-flex align-items-center justify-content-between'>
                    <span className="bank-details-title-payment">Bank Information</span>
                    <span className='parent-payment-account-details-button-responsive'>
                        <Button variant="contained" className='bg-dark text-white' startIcon={<Edit />}> Add Account </Button>
                        <Button className='ms-2 bg-dark text-white' variant="contained" startIcon={<Edit />}> Edit </Button>
                    </span>
                </Col>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Country Code</b></Col>
                    <Col md={8}>IND</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Currency Code</b></Col>
                    <Col md={8}>INR</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Status</b></Col>
                    <Col md={8}>Active</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Bank Code</b></Col>
                    <Col md={8}>INR</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Branch Code</b></Col>
                    <Col md={8}>INR</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Account Type</b></Col>
                    <Col md={8}>Saving</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Account Number</b></Col>
                    <Col md={8}>XXXXXXXX3354</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Account Name</b></Col>
                    <Col md={8}>Learniee</Col>
                </Row>
                <Row className='ps-3 pt-3'>
                    <Col md={4}><b>Branch</b></Col>
                    <Col md={8}>Ahjdhajd</Col>
                </Row>
                <hr />
                <Row className='ps-3 pb-3'>
                    <Col><b>Updated By : </b> XYZ</Col>
                    <Col><b>Last Updated : </b> 04/12/23</Col>
                </Row>
            </Container>
        </OperationLayout>
    );
};

export default OPSBanksPage;
