import React from "react";
import { Link } from "react-router-dom";

const Button = ({ text }) => {
  return (
    <Link to="#" class="btn arrow-btn">
      {text}{" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        data-inject-url="https://themegenix.com/demo/skillgro/assets/img/icons/right_arrow.svg"
        class="injectable"
      >
        <path
          d="M1 7L15 7M15 7L9 1M15 7L9 13"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M1 7L15 7M15 7L9 1M15 7L9 13"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </Link>
  );
};

export default Button;
