import React, { useState } from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonGroup, Checkbox, FormControlLabel } from "@mui/material";
import { Edit } from "@material-ui/icons";

function Payment(props) {
  const { history } = props;
  const [currentTab, setCurrentTab] = useState(1);
  const [usage, setUsage] = useState(false);
  return (
    <>
      <h5>Payment</h5>
      {/* <OperationLocation path={history} /> */}
      <div className="top-payment-tab-action">
        <div
          onClick={() => setCurrentTab(1)}
          className={
            currentTab === 1
              ? "top-payment-tab-items active-payment-parent-tab another"
              : "top-payment-tab-items another"
          }
        >
          Account Details
        </div>
        <div
          onClick={() => setCurrentTab(2)}
          className={
            currentTab === 2
              ? "top-payment-tab-items active-payment-parent-tab another"
              : "top-payment-tab-items another"
          }
        >
          Payment Gateway
        </div>
        <div
          onClick={() => setCurrentTab(3)}
          className={
            currentTab === 3
              ? "top-payment-tab-items active-payment-parent-tab another"
              : "top-payment-tab-items another"
          }
        >
          Check Chat
        </div>
        {/* <div className="top-payment-tab-items">&nbsp;</div> */}
      </div>

      {currentTab === 1 && (
        <>
          <Container className="payment-parent-container mt-3 p-0 m-0 ">
            <Col
              className="payment-parent-container-navbar d-flex align-items-center justify-content-between "
              style={{ backgroundColor: "#ffd32b" }}
            >
              <span className="bank-details-title-payment">Bank Details</span>
              <span className="parent-payment-account-details-button-responsive">
                <Button
                  variant="contained"
                  className="bg-dark text-white"
                  startIcon={<Edit />}
                >
                  {" "}
                  Add Account{" "}
                </Button>
                <Button
                  className="ms-2 bg-dark text-white"
                  variant="contained"
                  startIcon={<Edit />}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </span>
            </Col>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Country Code</b>
              </Col>
              <Col md={8}>IND</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Currency Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Status</b>
              </Col>
              <Col md={8}>Active</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Bank Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Branch Code</b>
              </Col>
              <Col md={8}>INR</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Type</b>
              </Col>
              <Col md={8}>Saving</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Number</b>
              </Col>
              <Col md={8}>XXXXXXXX3354</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Account Name</b>
              </Col>
              <Col md={8}>Learniee</Col>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={4}>
                <b>Branch</b>
              </Col>
              <Col md={8}>Ahjdhajd</Col>
            </Row>
            <hr />
            <Row className="ps-3 pb-3">
              <Col>
                <b>Updated By : </b> XYZ
              </Col>
              <Col>
                <b>Last Updated : </b> 04/12/23
              </Col>
            </Row>
          </Container>
        </>
      )}

      {currentTab === 2 && (
        <>
          <Container className="payment-parent-container mt-3 p-0 m-0 ">
            <Col
              className="payment-parent-container-navbar d-flex align-items-center justify-content-between"
              style={{ backgroundColor: "#ffd32b" }}
            >
              <span className="bank-details-title-payment">RazorPay</span>
              <span>
                <span style={{ color: "blue" }}>Help</span>
                <ArrowDropDownIcon />
              </span>
            </Col>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>Key*</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>Shared Secret*</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pt-3">
              <Col md={12}>
                {" "}
                <b>URL</b>{" "}
              </Col>
              <div class="form-group col-md-11 mt-2">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Row>
            <Row className="ps-3 pb-3">
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Default payment provider - This will become your default payment provider."
              />
            </Row>
            <Col className="ps-3 pb-3">
              <Button
                className="bg-dark text-white rounded-5"
                variant="contained"
              >
                Update & Connect
              </Button>
            </Col>
          </Container>
        </>
      )}

      {/* ------------------- Third Page 1 ----------------------- */}

      {currentTab === 3 && usage === false && (
        <>
          <ButtonGroup
            className="mt-3"
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              className="bg-dark text-white"
              style={{
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderColor: "black",
              }}
            >
              Payment Information
            </Button>
            <Button
              onClick={() => setUsage(true)}
              style={{
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderColor: "black",
                color: "black",
              }}
              variant="outlined"
            >
              Usage
            </Button>
          </ButtonGroup>

          <Col
            md={8}
            className="p-2 ps-3 rounded-5 mt-3 mb-3"
            style={{ background: "#ffd32b" }}
          >
            Ad HOC Payment Information
          </Col>

          <Row className="ps-3">
            <Col md={4}>Company</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Bank Account</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payee</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>New Ad Hoc Payee</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Currency</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payment Date</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="date"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Payment Type</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
        </>
      )}

      {/* ------------------- Third Page 2 ----------------------- */}

      {currentTab === 3 && usage === true && (
        <>
          <ButtonGroup
            className="mt-3"
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              onClick={() => setUsage(false)}
              variant="outlined"
              style={{
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderColor: "black",
                color: "black",
              }}
            >
              Payment Information
            </Button>
            <Button
              className="bg-dark text-white"
              style={{
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderColor: "black",
              }}
              variant="outlined"
            >
              Usage
            </Button>
          </ButtonGroup>

          <Col
            md={8}
            className=" p-2 ps-3 rounded-5 mt-3 mb-3"
            style={{ background: "#ffd32b" }}
          >
            Usage
          </Col>

          <Row className="ps-3">
            <Col md={4}>Type</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Primary</Col>
            <Col md={4}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label=""
              />
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Use For</Col>
            <Col md={4}>
              <div class="col-md-auto">
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder=""
                />
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Row className="ps-3 mt-3">
            <Col md={4}>Visibility</Col>
            <Col md={4}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label=""
              />
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>

          <Row className="ps-3 mt-3">
            <Col md={4}>Comments</Col>
            <Col md={4}>
              <div className="col-md-auto">
                {/* Replace the input with a textarea */}
                <textarea
                  className="form-control"
                  id="FirstName"
                  placeholder=""
                ></textarea>
              </div>
            </Col>
            <Col md={4}>&nbsp;</Col>
          </Row>
          <Col
            className="responsive-parent-payment-check-chat-button  mt-3"
            style={{ gap: "10px" }}
          >
            <Button
              className="bg-dark text-white rounded-5"
              variant="contained"
            >
              Submit
            </Button>
            <Button
              className="bg-dark text-white rounded-5"
              variant="contained"
            >
              Save For Later
            </Button>
            <Button
              className="bg-dark text-white rounded-5"
              variant="contained"
            >
              Get Approval From Admin
            </Button>
            <Button
              className="bg-dark text-white rounded-5"
              variant="contained"
            >
              Cancel
            </Button>
          </Col>
        </>
      )}
    </>
  );
}

export default Payment;
