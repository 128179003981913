import FAQParent from "./FAQParent.jsx";

export const FAQParentPageConfig = {
  routes: [
    {
      path: "/parent/FAQParent",
      exact: true,
      component: FAQParent,
    },
  ],
};
