import Resources from "./Resources.jsx";

export const ResourcesPageConfig = {
  routes: [
    {
      path: "/parent/resources",
      exact: true,
      component: Resources,
    },
  ],
};
