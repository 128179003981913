import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import card2 from "../../assets/parent/card2.png";
import Card2 from "./Card2";
import { IoMdAdd } from "react-icons/io";
import { FaAnglesRight } from "react-icons/fa6";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { formattedDate, MyAPI } from "../../utils/MyAPI";
import { useSelector } from "react-redux";
import axios from "../../utils/axios";

const LearneeMalPage = () => {
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state.token.value);
  const [searchValue, setSearchValue] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [learneeMal, setLearneeMal] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleAddLearneeMal = async () => {
    // validate filleds
    if (!title || !description || !image || !link) {
      return toast.error("Please fill all fields");
    }

    //new form data
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("external_link", link);
    setBtnLoading(true);
    try {
      let res = await MyAPI.POST("mall", formData, token);
      let { res_type, message, data, error } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        setTitle("");
        setDescription("");
        setImage(null);
        setLink("");
        handleCloseModal();
        fetchLearneeMal(token);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const fetchLearneeMal = async (token) => {
    try {
      setLoading(true);
      let res = await MyAPI.GET("/mall", token);
      let { res_type, message, data, error } = res.data || res;
      if (res_type === "success") {
        setLearneeMal(data);
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchLearneeMal(token);
    }
  }, [token]);

  useEffect(() => {
    if (searchValue === "") {
      fetchLearneeMal(token);
    }
  }, [searchValue]);

  const searchHandler = async (e) => {
    e.preventDefault();

    if (searchValue) {
      try {
        const res = await axios.get(
          `/mall/search?keyword=${searchValue}&page=1&limit=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.data.res_type === "success") {
          setLearneeMal(res.data.data.items);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <OperationLayout>
      <h5>Learnie Mall</h5>
      {/* <OperationLocation path={history} /> */}
      <StyledContainer>
        <Row className="d-flex mt-4 align-items-center justify-content-end">
          <Col
            md={12}
            className="d-flex align-items-center justify-content-between"
          >
            <Form onSubmit={searchHandler}>
              <Form.Control
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                type="text"
                placeholder="Search"
                style={{ width: "300px" }}
                className="rounded-5 me-4 shadow"
              />
            </Form>

            <Button
              onClick={handleShowModal}
              startIcon={<IoMdAdd />}
              className="rounded-5 shadow bg-warning border-warning text-dark"
              variant="outlined"
            >
              Add
            </Button>
          </Col>
        </Row>

        <Modal
          show={showModal}
          style={{ marginTop: "10vh", zIndex: "1111" }}
          onHide={handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Learnie Mall</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col className="mt-3">
              <Form.Group controlId="formItemName">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  placeholder="Enter Title"
                />
              </Form.Group>
            </Col>
            <Col className="mt-3">
              <Form.Group controlId="formItemName">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  type="text"
                  placeholder="Link"
                />
              </Form.Group>
            </Col>
            <Col className="mt-3">
              <Form.Group controlId="formItemName">
                <Form.Label>File</Form.Label>
                <Form.Control
                  onChange={(e) => setImage(e.target.files[0])}
                  type="file"
                  placeholder="Enter Title"
                />
              </Form.Group>
            </Col>
            <Col className="mt-3">
              <Form.Group className="mt-3" controlId="formInput">
                <Form.Label>Enter Text</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  as="textarea"
                  rows={3}
                  placeholder="Enter text..."
                />
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="bg-danger border-danger text-white"
              variant="outlined"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              disabled={btnLoading}
              className="ms-2 bg-primary text-white"
              variant="outlined"
              onClick={handleAddLearneeMal}
            >
              {btnLoading ? "Adding..." : "Add"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Row className="mt-3 pe-5">
          {!loading && learneeMal && learneeMal?.length > 0 ? (
            learneeMal.map((item, index) => (
              <Col md={6} key={index} className="mt-4 pe-4">
                <Card2
                  img={item.image_key || card2}
                  head={item.title || "Title Not Available"}
                  review={"267 views"}
                  time={formattedDate(item.createdAt)}
                  blogIcon={<FaAnglesRight />}
                  blog={"View blog"}
                  editButton={item}
                  deleteButton={item._id}
                  link={item.external_link}
                  fetchLearneeMal={fetchLearneeMal}
                />
              </Col>
            ))
          ) : (
            <h5 className="text-center mt-5">No data found</h5>
          )}
        </Row>
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .search_btn_learnie {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default LearneeMalPage;
