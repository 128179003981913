// RolesAndPermissionsPage
import RolesAndPermissionsPage from "./RolesAndPermissionsPage";

export const RolesAndPermissionsPageConfig = {
  routes: [
    {
      path: "/operation/roles-permissions",
      exact: true,
      component: RolesAndPermissionsPage
    }
  ]
};
