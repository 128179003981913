import React from "react";
import { Navigate } from "react-router-dom";

// import { DashboardPageConfig } from "../ParentPages/dashboard/DashboardPageConfig";
import { DashboardPageConfig } from "../OperationalPages/dashboard/DashboardPageConfig.jsx";
import { RolesAndPermissionsPageConfig } from "../OperationalPages/RolesAndPermissions/RolesAndPermissionsPageConfig.jsx";
import { ComplaintPageConfig } from "../OperationalPages/Complaint/ComplaintPageConfig.jsx";
import { KRAPageConfig } from "../OperationalPages/KRA/KRAPageConfig.jsx";
import { PaymentsPageConfig } from "../OperationalPages/Payments/PaymentsPageConfig.jsx";
import { ParentDatabasePageConfig } from "../OperationalPages/ParentDatabase/ParentDatabasePageConfig.jsx";
import { OPSBanksPageConfig } from "../OperationalPages/OPSBanks/OPSBanksPageConfig.jsx";
import { contactPageConfig } from "../OperationalPages/contact/contactPageConfig.jsx";
import { ManagePageConfig } from "../OperationalPages/Manage/ManagePageConfig.jsx";
import { CommunitiesPageConfig } from "../OperationalPages/Communities/CommunitiesPageConfig.jsx";
import { FbAndWatsappPageConfig } from "../OperationalPages/FbAndWatsapp/FbAndWatsappPageConfig.jsx";
import { CodePageConfig } from "../OperationalPages/Code/CodePageConfig.jsx";
import { PeoplePageConfig } from "../OperationalPages/People/PeoplePageConfig.jsx";
import { AccountPageConfig } from "../OperationalPages/Account/AccountPageConfig.jsx";
import { MediaPageConfig } from "../OperationalPages/Media/MediaPageConfig.jsx";
import { ServiceProviderPageConfig } from "../OperationalPages/ServiceProvider/ServiceProviderPageConfig.jsx";
import { AffliatePageConfig } from "../OperationalPages/Affliate/AffliatePageConfig.jsx";
import { WebsiteSettingPageConfig } from "../OperationalPages/WebsiteSetting/WebsiteSettingPageConfig.jsx";
import { BehaviourPageConfig } from "../OperationalPages/Behaviour/BehaviourPageConfig.jsx";
import { LeadsPageConfig } from "../OperationalPages/leads/LeadsPageConfig.jsx";
import { SafetyPageConfig } from "../OperationalPages/Safety/SafetyPageConfig.jsx";
import { BlogPageConfig } from "../OperationalPages/Blogs/BlogPageConfig.jsx";
import { SalesReportPageConfig } from "../OperationalPages/salesReports/SalesReportConfig.jsx";
import { ConversionPageConfig } from "../OperationalPages/Conversation/ConversationConfig.jsx";
import { TraficReportPageConfig } from "../OperationalPages/TraficReports/TraficReportConfig.jsx";
import { SeoPageConfig } from "../OperationalPages/Seo/SeoPageConfig.jsx";
import { MarketingPageConfig } from "../OperationalPages/Marketing/MarketingConfig.jsx";
import { WalletConfig } from "../ParentPages/wallet/WalletPageConfig.jsx";
import { ManualDemoPageConfig } from "../ParentPages/ManualDemo/ManualDemoPageConfig.jsx";
import { ClassesPageConfig } from "../OperationalPages/Classes/ClassesPageConfig.jsx";
import { HomeWorkPageConfig } from "../OperationalPages/HomeWork/HomeWorkPageConfig.jsx";
import { ChatsPageConfig } from "../OperationalPages/Chats/ChatsPageConfig.jsx";
import { ReviewRatingPageConfig } from "../OperationalPages/ReviewRating/ReviewRatingPageConfig.jsx";
import { LeadManagmentPageConfig } from "../OperationalPages/LeadManagment/LeadManagmentPageConfig.jsx";
import { BookResourcesPageConfig } from "../OperationalPages/BookResources/BookResourcesPageConfig.jsx";
import { TeachersPageConfig } from "../OperationalPages/Teachers/TeachersPageConfig.jsx";
import { RecheduleLeavePageConfig } from "../OperationalPages/RecheduleLeave/RecheduleLeavePageConfig.jsx";
import { TagsPageConfig } from "../OperationalPages/Tags/TagsPageConfig.jsx";
import { CourcesPageConfig } from "../OperationalPages/Cources/CourcesPageConfig.jsx";
import { LiveRequestsPageConfig } from "../OperationalPages/LiveRequests/LiveRequestsPageConfig.jsx";
import { SearchPageConfig } from "../OperationalPages/Search/SearchPageConfig.jsx";
import { CertificatePageConfig } from "../OperationalPages/Certificate/CertificatePageConfig.jsx";
import { TermConditionPageConfig } from "../OperationalPages/TermCondition/TermConditionPageConfig.jsx";
import { LearneeMalPageConfig } from "../OperationalPages/LearneeMal/LearneeMalPageConfig.jsx";
import { ReportPageConfig } from "../OperationalPages/Report/ReportPageConfig.jsx";
import { EmployeePageConfig } from "../OperationalPages/employee/employeePageConfig.jsx";
import { PinPageConfig } from "../OperationalPages/PinBlog/PinBlogPageConfig.jsx";
import { AllDemoPageConfig } from "../OperationalPages/AllDemo/AllDemoPageConfig.jsx";
import { TaskManagementPageConfig } from "../OperationalPages/TaskManagement/TaskManagementPageConfig.jsx";
import { CareerPageConfig } from "../OperationalPages/Career/CareerPageConfig.jsx";
import { RequestPageConfig } from "../OperationalPages/Request/RequestPageConfig.jsx";
import { VacancyPageConfig } from "../OperationalPages/Vaccency/VacancyPageConfig.jsx";
import { RatePageConfig } from "../OperationalPages/Rate/RatePageConfig.jsx";
import { PtaMeetingPageConfig } from "../OperationalPages/PTAMeeting/PtaMeetingPageConfig.jsx";
import { EditWebsitePageConfig } from "../OperationalPages/EditWebsite/EditWebsitePageConfig.jsx";

const OperationRouteConfigs = [
  ...DashboardPageConfig.routes,
  ...RolesAndPermissionsPageConfig.routes,
  ...ComplaintPageConfig.routes,
  ...KRAPageConfig.routes,
  ...PaymentsPageConfig.routes,
  ...ParentDatabasePageConfig.routes,
  ...OPSBanksPageConfig.routes,
  ...contactPageConfig.routes,
  ...ManagePageConfig.routes,
  ...CommunitiesPageConfig.routes,
  ...FbAndWatsappPageConfig.routes,
  ...CodePageConfig.routes,
  ...PeoplePageConfig.routes,
  ...AccountPageConfig.routes,
  ...MediaPageConfig.routes,
  ...ServiceProviderPageConfig.routes,
  ...AffliatePageConfig.routes,
  ...WebsiteSettingPageConfig.routes,
  ...BehaviourPageConfig.routes,
  ...LeadsPageConfig.routes,
  ...SafetyPageConfig.routes,
  ...BlogPageConfig.routes,
  ...SalesReportPageConfig.routes,
  ...ConversionPageConfig.routes,
  ...TraficReportPageConfig.routes,
  ...SeoPageConfig.routes,
  ...MarketingPageConfig.routes,
  ...WalletConfig.routes,
  ...ClassesPageConfig.routes,
  ...HomeWorkPageConfig.routes,
  ...ChatsPageConfig.routes,
  ...ReviewRatingPageConfig.routes,
  ...LeadManagmentPageConfig.routes,
  ...BookResourcesPageConfig.routes,
  ...TeachersPageConfig.routes,
  ...RecheduleLeavePageConfig.routes,
  ...TagsPageConfig.routes,
  ...CourcesPageConfig.routes,
  ...LiveRequestsPageConfig.routes,
  ...SearchPageConfig.routes,
  ...CertificatePageConfig.routes,
  ...TermConditionPageConfig.routes,
  ...LearneeMalPageConfig.routes,
  ...ReportPageConfig.routes,
  ...EmployeePageConfig.routes,
  ...PinPageConfig.routes,
  ...AllDemoPageConfig.routes,
  ...TaskManagementPageConfig.routes,
  ...CareerPageConfig.routes,
  ...RequestPageConfig.routes,
  ...VacancyPageConfig.routes,
  ...RatePageConfig.routes,
  ...PtaMeetingPageConfig.routes,
  ...EditWebsitePageConfig.routes,
];

const operationRoutes = [
  ...OperationRouteConfigs,
  {
    element: <Navigate to="/ParentPages/errors/error-404" replace />,
  },
];

export default operationRoutes;
