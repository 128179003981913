import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Badge, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { FaEye } from "react-icons/fa6";
import Chart from "chart.js/auto";

const groupClasses = [
  {
    id: 1,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    teacherId: "teacher@01112562376",
    courseName: "Mathematics",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudents: 60,
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 2,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    teacherId: "teacher@01112562376",
    courseName: "Mathematics",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudents: 60,
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 3,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    teacherId: "teacher@01112562376",
    courseName: "Mathematics",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudents: 60,
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 4,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    teacherId: "teacher@01112562376",
    courseName: "Mathematics",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudents: 60,
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
];

const oneOnOneClasses = [
  {
    id: 1,
    classId: "class@01112562376",
    className: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    studentId: "student@01112562376",
    studentName: "John Doe",
    subject: "Mathematics",
    grade: "10th",
    board: "CBSE",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 2,
    classId: "class@01112562376",
    className: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    studentId: "student@01112562376",
    studentName: "John Doe",
    subject: "Mathematics",
    grade: "10th",
    board: "CBSE",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 3,
    classId: "class@01112562376",
    className: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    studentId: "student@01112562376",
    studentName: "John Doe",
    subject: "Mathematics",
    grade: "10th",
    board: "CBSE",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
  {
    id: 4,
    classId: "class@01112562376",
    className: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    studentId: "student@01112562376",
    studentName: "John Doe",
    subject: "Mathematics",
    grade: "10th",
    board: "CBSE",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    currentCycle: 1,
    currentClassNo: 1,
    status: "Live",
  },
];

const previousClasses = [
  {
    id: 1,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    courseName: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudentsAttented: 60,
    currentCycle: 1,
    currentClassNo: 1,
    teacherStatus: "On Time Joining",
  },
  {
    id: 2,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    courseName: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudentsAttented: 60,
    currentCycle: 1,
    currentClassNo: 1,
    teacherStatus: "Late Joining",
  },
  {
    id: 3,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    courseName: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudentsAttented: 60,
    currentCycle: 1,
    currentClassNo: 1,
    teacherStatus: "No Show",
  },
  {
    id: 4,
    classId: "class@01112562376",
    courseId: "course@01112562376",
    courseName: "Mathematics",
    teacherId: "teacher@01112562376",
    teacherName: "John Doe",
    classDate: "12 January 2023",
    classTiming: "10:30 AM - 01:30 PM",
    totalNoOfStudentsAttented: 60,
    currentCycle: 1,
    currentClassNo: 1,
    teacherStatus: "On Time Joining",
  },
];

const BlogPage = (props) => {
  const { history } = props;
  const [currentTab, setCurrentTab] = useState("1");
  const [showclasses, setShowclasses] = useState("");
  const handleSelectChange = (e) => {
    setShowclasses("");
    setCurrentTab(e.target.value);
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const handleAddClassClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const handleCloseModal3 = () => {
    setShowModal3(false);
  };

  useEffect(() => {
    var ctx = document.getElementById("studentCourceGraph").getContext("2d");
    var ctx2 = document.getElementById("studentCourceGraph2").getContext("2d");
    var ctx3 = document.getElementById("studentCourceGraph3").getContext("2d");
    var ctx4 = document.getElementById("studentCourceGraph4").getContext("2d");
    var ctx5 = document.getElementById("studentCourceGraph5").getContext("2d");
    var ctx6 = document.getElementById("studentCourceGraph6").getContext("2d");

    var studentCourseGraph = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Number of Active Classes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#f4e880",
            borderColor: "#FFD32B",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph2 = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Number of Hold Classes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#fbaf4b",
            borderColor: "#ffaa2b",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph3 = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Number of New Classes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#7df849",
            borderColor: "#17f710",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph4 = new Chart(ctx4, {
      type: "bar",
      data: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "Number of Discount Classes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#f86949",
            borderColor: "#ff4101",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph5 = new Chart(ctx5, {
      type: "bar",
      data: {
        labels: ["Hindi", "English", "Science", "Maths", "History", "Marathi"],
        datasets: [
          {
            label: "Avg. Time per Course",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#5a49f8",
            borderColor: "#4530ff",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph6 = new Chart(ctx6, {
      type: "bar",
      data: {
        labels: ["Hindi", "English", "Science", "Maths", "History", "Marathi"],
        datasets: [
          {
            label: "Total No. of Returning Classes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#f8e149",
            borderColor: "#ffe120",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      studentCourseGraph.destroy();
      studentCourseGraph2.destroy();
      studentCourseGraph3.destroy();
      studentCourseGraph4.destroy();
      studentCourseGraph5.destroy();
      studentCourseGraph6.destroy();
    };
  }, []);

  return (
    <OperationLayout>
      <h4>Classes</h4>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between align-items-center">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select
              onChange={handleSelectChange}
              className="shadow rounded-3 mt-3"
              value={currentTab}
            >
              <option disabled>select class type</option>
              <option value={"1"}>On Going</option>
              <option value={"2"}>Hold</option>
              <option value={"3"}>Close</option>
              <option value={"4"}>Live</option>
              <option value={"5"}>Previous Classes</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="d-flex align-items-center gap-4">
          <button
            onClick={() => setShowclasses("group")}
            className="mt-3 py-2 px-3 rounded-2"
            style={{
              backgroundColor: "transparent",
              color: "var(--color-primary)",
              border: "1px solid var(--color-primary)",
            }}
          >
            Group Class
          </button>
          <button
            onClick={() => setShowclasses("1on1")}
            className="mt-3 py-2 px-3 rounded-2"
            style={{
              backgroundColor: "transparent",
              color: "var(--color-primary)",
              border: "1px solid var(--color-primary)",
            }}
          >
            1-on-1 Class
          </button>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      {currentTab == 1 && showclasses == "" ? (
        <>
          <Row className="mt-3 mb-3">
            <Col className="d-flex align-items-center justify-content-between">
              <h5>OnGoing Classes</h5>
              <Button
                onClick={handleAddClassClick}
                className="shadow rounded-5 border-yellow"
                variant="outlined"
              >
                Add Class
              </Button>
            </Col>
          </Row>

          <Modal
            show={showModal}
            onHide={handleCloseModal}
            style={{ zIndex: "1111", marginTop: "10vh" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Class Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Enter Class Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="date"
                      placeholder="Enter Class Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="date"
                      placeholder="Enter Class Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="time"
                      placeholder="Enter Class Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="date"
                      placeholder="Enter Class Name"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="nameInput">
                    <Form.Label>Number Of Students</Form.Label>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Enter Student Count"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outlined" onClick={handleCloseModal}>
                Close
              </Button>
              {/* Add additional buttons as needed */}
            </Modal.Footer>
          </Modal>

          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
              color: "#fff",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">Class I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Start Date</th>
                <th className="text-white">End Date</th>
                <th className="text-white">Total Student</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Status</th>
                <th className="text-white">Join</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="warning">Live</Badge>
                </td>
                <td>
                  <button
                    style={{
                      border: "1px solid var(--color-primary)",
                      color: "var(--color-primary)",
                    }}
                    className="btn btn-sm rounded-4 px-3 fw-semibold"
                  >
                    Join
                  </button>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="warning">Live</Badge>
                </td>
                <td>
                  <button
                    style={{
                      border: "1px solid var(--color-primary)",
                      color: "var(--color-primary)",
                    }}
                    className="btn btn-sm rounded-4 px-3 fw-semibold"
                  >
                    Join
                  </button>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="warning">Live</Badge>
                </td>
                <td>
                  <button
                    style={{
                      border: "1px solid var(--color-primary)",
                      color: "var(--color-primary)",
                    }}
                    className="btn btn-sm rounded-4 px-3 fw-semibold"
                  >
                    Join
                  </button>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="warning">Live</Badge>
                </td>
                <td>
                  <button
                    style={{
                      border: "1px solid var(--color-primary)",
                      color: "var(--color-primary)",
                    }}
                    className="btn btn-sm rounded-4 px-3 fw-semibold"
                  >
                    Join
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : null}

      {currentTab == 2 && showclasses == "" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Hold Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center text-white">
                <th className="text-white">Class I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Start Date</th>
                <th className="text-white">End Date</th>
                <th className="text-white">Total Student</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="secondary">Hold</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="secondary">Hold</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="secondary">Hold</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="secondary">Hold</Badge>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : null}

      {currentTab == 3 && showclasses == "" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Close Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">Class I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Start Date</th>
                <th className="text-white">End Date</th>
                <th className="text-white">Total Student</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="danger">Closed</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="danger">Closed</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="danger">Closed</Badge>
                </td>
              </tr>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="danger">Closed</Badge>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : null}

      {currentTab == 4 && showclasses == "" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Live Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">Class I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Start Date</th>
                <th className="text-white">End Date</th>
                <th className="text-white">Total Student</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Status</th>
                <td className="text-white">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light text-center">
                <td>class#01112562376</td>
                <td>John Doe</td>
                <td>12 January 2023</td>
                <td>12 March 2023</td>
                <td>60</td>
                <td>10:30 AM - 01:30 PM</td>
                <td>
                  <Badge bg="success">Live</Badge>
                </td>
                <td>
                  <Button
                    className="rounded-5"
                    variant="contained"
                    size="small"
                  >
                    Join Now
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      ) : null}

      {currentTab == 5 && showclasses == "" ? (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Previous Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S. No.</th>
                <th className="text-white">Class I'D</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Class Date</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Total Student Attended</th>
                <th className="text-white">Current Cycle</th>
                <th className="text-white">Current Class No</th>
                <th className="text-white">Teacher Status</th>
                <th className="text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {previousClasses.map((previousClass, index) => (
                <tr key={index} className="bg-light text-center">
                  <td>{index + 1}</td>
                  <td>{previousClass.classId}</td>
                  <td>{previousClass.courseId}</td>
                  <td>{previousClass.courseName}</td>
                  <td>{previousClass.teacherId}</td>
                  <td>{previousClass.teacherName}</td>
                  <td>{previousClass.classDate}</td>
                  <td>{previousClass.classTiming}</td>
                  <td>{previousClass.totalNoOfStudentsAttented}</td>
                  <td>{previousClass.currentCycle}</td>
                  <td>{previousClass.currentClassNo}</td>
                  <td>
                    <Badge
                      bg={`${
                        previousClass.teacherStatus == "On Time Joining"
                          ? "success"
                          : previousClass.teacherStatus == "Late Joining"
                          ? "warning"
                          : "danger"
                      }`}
                    >
                      {previousClass.teacherStatus}
                    </Badge>
                  </td>
                  <td>
                    <button
                      onClick={() => setShowModal3(true)}
                      className="btn btn-sm text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal
            show={showModal3}
            onHide={handleCloseModal3}
            style={{ zIndex: "1111", marginTop: "10vh" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={6}>
                  <h6 className="fw-semibold">Student ID :-</h6>
                  <h6 className="fw-semibold">Student Name :-</h6>
                  <h6 className="fw-semibold">Subject :-</h6>
                  <h6 className="fw-semibold">Grade :-</h6>
                  <h6 className="fw-semibold">Board :-</h6>
                  <h6 className="fw-semibold">Age :-</h6>
                  <h6 className="fw-semibold">Status :-</h6>
                </Col>
                <Col sm={6}>
                  <h6>123456789</h6>
                  <h6>John Doe</h6>
                  <h6>Mathematics</h6>
                  <h6>10th</h6>
                  <h6>CBSE</h6>
                  <h6>15</h6>
                  <span>
                    <Badge bg="success">Present</Badge>
                  </span>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outlined" onClick={handleCloseModal3}>
                Close
              </Button>
              {/* Add additional buttons as needed */}
            </Modal.Footer>
          </Modal>
        </>
      ) : null}

      {showclasses == "group" && (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>Group Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Class I'D</th>
                <th className="text-white">Course I'D</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Class Date</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Total Student</th>
                <th className="text-white">Current Cycle</th>
                <th className="text-white">Current Class No</th>
                <th className="text-white">Status</th>
                <td className="text-white">Join</td>
                <td className="text-white">Action</td>
              </tr>
            </thead>
            <tbody>
              {groupClasses.map((groupClass, index) => (
                <tr key={index} className="bg-light text-center">
                  <td>{index + 1}</td>
                  <td>{groupClass.classId}</td>
                  <td>{groupClass.courseId}</td>
                  <td>{groupClass.teacherId}</td>
                  <td>{groupClass.courseName}</td>
                  <td>{groupClass.teacherName}</td>
                  <td>{groupClass.classDate}</td>
                  <td>{groupClass.classTiming}</td>
                  <td>{groupClass.totalNoOfStudents}</td>
                  <td>{groupClass.currentCycle}</td>
                  <td>{groupClass.currentClassNo}</td>
                  <td>
                    <Badge bg="warning">{groupClass.status}</Badge>
                  </td>
                  <td>
                    <button
                      style={{
                        border: "1px solid var(--color-primary)",
                        color: "var(--color-primary)",
                      }}
                      className="btn btn-sm rounded-4 px-3 fw-semibold"
                    >
                      Join
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => setShowModal2(true)}
                      className="btn btn-sm text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal
            show={showModal2}
            onHide={handleCloseModal2}
            style={{ zIndex: "1111", marginTop: "10vh" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={6}>
                  <h6 className="fw-semibold">Student ID :-</h6>
                  <h6 className="fw-semibold">Student Name :-</h6>
                  <h6 className="fw-semibold">Subject :-</h6>
                  <h6 className="fw-semibold">Grade :-</h6>
                  <h6 className="fw-semibold">Board :-</h6>
                  <h6 className="fw-semibold">Age :-</h6>
                </Col>
                <Col sm={6}>
                  <h6>123456789</h6>
                  <h6>John Doe</h6>
                  <h6>Mathematics</h6>
                  <h6>10th</h6>
                  <h6>CBSE</h6>
                  <h6>15</h6>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outlined" onClick={handleCloseModal2}>
                Close
              </Button>
              {/* Add additional buttons as needed */}
            </Modal.Footer>
          </Modal>
        </>
      )}

      {showclasses == "1on1" && (
        <>
          <Row className="mt-3 mb-3">
            {" "}
            <h5>1-on-1 Classes</h5>
          </Row>
          <Table
            responsive
            striped
            style={{
              borderRadius: "25px",
              backgroundColor: "var(--color-primary)",
            }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S.No.</th>
                <th className="text-white">Class I'D</th>
                <th className="text-white">Class Name</th>
                <th className="text-white">Teacher I'D</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Student I'D</th>
                <th className="text-white">Student Name</th>
                <th className="text-white">Subject</th>
                <th className="text-white">Grade</th>
                <th className="text-white">Board</th>
                <th className="text-white">Class Date</th>
                <th className="text-white">Class Timing</th>
                <th className="text-white">Current Cycle</th>
                <th className="text-white">Current Class No</th>
                <th className="text-white">Status</th>
                <td className="text-white">Join</td>
              </tr>
            </thead>
            <tbody>
              {oneOnOneClasses.map((oneOnOneClass, index) => (
                <tr key={index} className="bg-light text-center">
                  <td>{index + 1}</td>
                  <td>{oneOnOneClass.classId}</td>
                  <td>{oneOnOneClass.className}</td>
                  <td>{oneOnOneClass.teacherId}</td>
                  <td>{oneOnOneClass.teacherName}</td>
                  <td>{oneOnOneClass.studentId}</td>
                  <td>{oneOnOneClass.studentName}</td>
                  <td>{oneOnOneClass.subject}</td>
                  <td>{oneOnOneClass.grade}</td>
                  <td>{oneOnOneClass.board}</td>
                  <td>{oneOnOneClass.classDate}</td>
                  <td>{oneOnOneClass.classTiming}</td>
                  <td>{oneOnOneClass.currentCycle}</td>
                  <td>{oneOnOneClass.currentClassNo}</td>

                  <td>
                    <Badge bg="warning">{oneOnOneClass.status}</Badge>
                  </td>
                  <td>
                    <button
                      style={{
                        border: "1px solid var(--color-primary)",
                        color: "var(--color-primary)",
                      }}
                      className="btn btn-sm rounded-4 px-3 fw-semibold"
                    >
                      Join
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <Row className="mt-5">
        <Col md={6}>
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Total No. of Active Classes
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph"></canvas>
          </div>
        </Col>
        <Col md={6}>
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Total No. of Hold Classes
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph2"></canvas>
          </div>
        </Col>
        <Col md={6} className="mt-4">
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Total No. of New Classes
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph3"></canvas>
          </div>
        </Col>
        <Col md={6} className="mt-4">
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Total No. of Discount Classes
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph4"></canvas>
          </div>
        </Col>
        <Col md={6} className="mt-4">
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Avg. Time per Courses Retention
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph5"></canvas>
          </div>
        </Col>
        <Col md={6} className="mt-4">
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">
                Total No. of Returning Classes
              </h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph6"></canvas>
          </div>
        </Col>
      </Row>
    </OperationLayout>
  );
};

export default BlogPage;
