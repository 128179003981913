import React, { useEffect, useRef } from 'react';
import './CircularProgressBar.css'; // Import CSS file for styling

const CircularProgressBar = ({ percentage, color }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = canvas.width / 2 - 2.5; // Adjust for padding to get a circle with 5px width
        const circleWidth = 5; // Width of the circular progress bar

        const drawProgressCircle = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);

            // Draw background circle
            context.beginPath();
            context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            context.strokeStyle = '#ddd'; // Background color
            context.lineWidth = circleWidth;
            context.stroke();

            // Draw progress circle
            context.beginPath();
            context.arc(centerX, centerY, radius, -0.5 * Math.PI, (2 * Math.PI * percentage) / 100 - 0.5 * Math.PI);
            context.strokeStyle = color;
            context.lineWidth = circleWidth;
            context.stroke();

            // Set text size based on percentage
            const textSize = 14 + 2 * Math.min(percentage, 50) / 100;
            context.font = `bold ${textSize}px Arial`;

            // Draw percentage text
            context.fillStyle = color;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(`${percentage}%`, centerX, centerY);
        };
        drawProgressCircle();
    }, [percentage, color]);

    return <canvas ref={canvasRef} className="progress-bar" width={50} height={50} />;
};

export default CircularProgressBar;
