import React, { useState } from "react";
import { Table, Button, Modal, Form, Col, Row, Badge } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { TiTick } from "react-icons/ti";
import { IoCloseOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";

const appointments = [
  {
    id: 1,
    classId: "CLASS-12345",
    courseTitle: "Mathematics",
    teacherName: "John Doe",
    parentName: "Jane Doe",
    childName: "John Doe",
    date: "2021-09-01",
    time: "10:00",
    description: "Parent Teacher Meeting",
    acceptedByTeacher: true,
    acceptedByParent: false,
  },
  {
    id: 2,
    classId: "CLASS-12345",
    courseTitle: "Mathematics",
    teacherName: "John Doe",
    parentName: "Jane Doe",
    childName: "John Doe",
    date: "2021-09-01",
    time: "10:00",
    description: "Parent Teacher Meeting",
    acceptedByTeacher: false,
    acceptedByParent: false,
  },
  {
    id: 3,
    classId: "CLASS-12345",
    courseTitle: "Mathematics",
    teacherName: "John Doe",
    parentName: "Jane Doe",
    childName: "John Doe",
    date: "2021-09-01",
    time: "10:00",
    description: "Parent Teacher Meeting",
    acceptedByTeacher: true,
    acceptedByParent: true,
  },
];

function PtaMeeting() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [tab, setTab] = useState(null);

  const handleShowModal = (appointment) => {
    setModalData(appointment);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    handleCloseModal();
  };

  return (
    <OperationLayout>
      <Row className="mt-3 mb-3">
        <Col md={6}>
          <h5>PT Meeting</h5>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Select onChange={(e) => setTab(e.target.value)} value={tab}>
              <option disabled selected>
                Filters
              </option>
              <option value="1">Request</option>
              <option value="2">Pending</option>
              <option value="3">Upcoming PTA</option>
              <option value="4">Previous PTA</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Control type="text" placeholder="Search..." />
          </Form.Group>
        </Col>
      </Row>
      {tab === "1" ? (
        <Table
          className="mt-4 mb-3 rounded-4"
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
          striped
          responsive
          bordered
          hover
        >
          <thead>
            <tr>
              <th className="text-center text-white">S.No.</th>
              <th className="text-center text-white">Class Id</th>
              <th className="text-center text-white">Course Title</th>
              <th className="text-center text-white">Teacher Name</th>
              <th className="text-center text-white">Parent Name</th>
              <th className="text-center text-white">Child Name</th>
              <th className="text-center text-white">Date</th>
              <th className="text-center text-white">Time</th>
              <th className="text-center text-white">Description</th>
              <th className="text-center text-white">Accepted By Teacher</th>
              <th className="text-center text-white">Accepted By Parent</th>
              <th className="text-center text-white">Reschedule</th>
              <th className="text-center text-white" colSpan={2}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="text-center bg-light">{appointment.id}</td>
                <td className="text-center bg-light">{appointment.classId}</td>
                <td className="text-center bg-light">
                  {appointment.courseTitle}
                </td>
                <td className="text-center bg-light">
                  {appointment.teacherName}
                </td>
                <td className="text-center bg-light">
                  {appointment.parentName}
                </td>
                <td className="text-center bg-light">
                  {appointment.childName}
                </td>
                <td className="text-center bg-light">{appointment.date}</td>
                <td className="text-center bg-light">{appointment.time}</td>
                <td className="text-center bg-light">
                  {appointment.description}
                </td>
                <td className="text-center bg-light">
                  <Badge
                    bg={`${
                      appointment.acceptedByTeacher ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByTeacher
                      ? "Accepted"
                      : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-white">
                  <Badge
                    bg={`${
                      appointment.acceptedByParent ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByParent ? "Accepted" : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-light">
                  <center>
                    <Button
                      variant="warning"
                      onClick={() => handleShowModal(appointment)}
                    >
                      <SlCalender size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="success">
                      <TiTick size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="danger">
                      <IoCloseOutline size={22} />
                    </Button>
                  </center>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : tab === "2" ? (
        <Table
          className="mt-4 mb-3 rounded-4"
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
          striped
          responsive
          bordered
          hover
        >
          <thead>
            <tr>
              <th className="text-center text-white">S.No.</th>
              <th className="text-center text-white">Class Id</th>
              <th className="text-center text-white">Course Title</th>
              <th className="text-center text-white">Teacher Name</th>
              <th className="text-center text-white">Parent Name</th>
              <th className="text-center text-white">Child Name</th>
              <th className="text-center text-white">Date</th>
              <th className="text-center text-white">Time</th>
              <th className="text-center text-white">Description</th>
              <th className="text-center text-white">Accepted By Teacher</th>
              <th className="text-center text-white">Accepted By Parent</th>
              <th className="text-center text-white">Reschedule</th>
              <th className="text-center text-white" colSpan={2}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="text-center bg-light">{appointment.id}</td>
                <td className="text-center bg-light">{appointment.classId}</td>
                <td className="text-center bg-light">
                  {appointment.courseTitle}
                </td>
                <td className="text-center bg-light">
                  {appointment.teacherName}
                </td>
                <td className="text-center bg-light">
                  {appointment.parentName}
                </td>
                <td className="text-center bg-light">
                  {appointment.childName}
                </td>
                <td className="text-center bg-light">{appointment.date}</td>
                <td className="text-center bg-light">{appointment.time}</td>
                <td className="text-center bg-light">
                  {appointment.description}
                </td>
                <td className="text-center bg-light">
                  <Badge
                    bg={`${
                      appointment.acceptedByTeacher ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByTeacher
                      ? "Accepted"
                      : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-white">
                  <Badge
                    bg={`${
                      appointment.acceptedByParent ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByParent ? "Accepted" : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-light">
                  <center>
                    <Button
                      variant="warning"
                      onClick={() => handleShowModal(appointment)}
                    >
                      <SlCalender size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="success">
                      <TiTick size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="danger">
                      <IoCloseOutline size={22} />
                    </Button>
                  </center>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : tab === "3" ? (
        <Table
          className="mt-4 mb-3 rounded-4"
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
          striped
          responsive
          bordered
          hover
        >
          <thead>
            <tr>
              <th className="text-center text-white">S.No.</th>
              <th className="text-center text-white">Class Id</th>
              <th className="text-center text-white">Course Title</th>
              <th className="text-center text-white">Teacher Name</th>
              <th className="text-center text-white">Parent Name</th>
              <th className="text-center text-white">Child Name</th>
              <th className="text-center text-white">Date</th>
              <th className="text-center text-white">Time</th>
              <th className="text-center text-white">Description</th>
              <th className="text-center text-white">Accepted By Teacher</th>
              <th className="text-center text-white">Accepted By Parent</th>
              <th className="text-center text-white">Reschedule</th>
              <th className="text-center text-white">Join PTA</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="text-center bg-light">{appointment.id}</td>
                <td className="text-center bg-light">{appointment.classId}</td>
                <td className="text-center bg-light">
                  {appointment.courseTitle}
                </td>
                <td className="text-center bg-light">
                  {appointment.teacherName}
                </td>
                <td className="text-center bg-light">
                  {appointment.parentName}
                </td>
                <td className="text-center bg-light">
                  {appointment.childName}
                </td>
                <td className="text-center bg-light">{appointment.date}</td>
                <td className="text-center bg-light">{appointment.time}</td>
                <td className="text-center bg-light">
                  {appointment.description}
                </td>
                <td className="text-center bg-light">
                  <Badge
                    bg={`${
                      appointment.acceptedByTeacher ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByTeacher
                      ? "Accepted"
                      : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-white">
                  <Badge
                    bg={`${
                      appointment.acceptedByParent ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByParent ? "Accepted" : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-light">
                  <center>
                    <Button
                      variant="warning"
                      onClick={() => handleShowModal(appointment)}
                    >
                      <SlCalender size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="primary">Join</Button>
                  </center>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table
          className="mt-4 mb-3 rounded-4"
          style={{ backgroundColor: "var(--color-primary)", minWidth: 1800 }}
          striped
          responsive
          bordered
          hover
        >
          <thead>
            <tr>
              <th className="text-center text-white">S.No.</th>
              <th className="text-center text-white">Class Id</th>
              <th className="text-center text-white">Course Title</th>
              <th className="text-center text-white">Teacher Name</th>
              <th className="text-center text-white">Parent Name</th>
              <th className="text-center text-white">Child Name</th>
              <th className="text-center text-white">Date</th>
              <th className="text-center text-white">Time</th>
              <th className="text-center text-white">Description</th>
              <th className="text-center text-white">Accepted By Teacher</th>
              <th className="text-center text-white">Accepted By Parent</th>
              <th className="text-center text-white">Reschedule</th>
              <th className="text-center text-white">View Feedback</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment) => (
              <tr key={appointment.id}>
                <td className="text-center bg-light">{appointment.id}</td>
                <td className="text-center bg-light">{appointment.classId}</td>
                <td className="text-center bg-light">
                  {appointment.courseTitle}
                </td>
                <td className="text-center bg-light">
                  {appointment.teacherName}
                </td>
                <td className="text-center bg-light">
                  {appointment.parentName}
                </td>
                <td className="text-center bg-light">
                  {appointment.childName}
                </td>
                <td className="text-center bg-light">{appointment.date}</td>
                <td className="text-center bg-light">{appointment.time}</td>
                <td className="text-center bg-light">
                  {appointment.description}
                </td>
                <td className="text-center bg-light">
                  <Badge
                    bg={`${
                      appointment.acceptedByTeacher ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByTeacher
                      ? "Accepted"
                      : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-white">
                  <Badge
                    bg={`${
                      appointment.acceptedByParent ? "success" : "danger"
                    }`}
                  >
                    {appointment.acceptedByParent ? "Accepted" : "Not Accepted"}
                  </Badge>
                </td>

                <td className="text-center bg-light">
                  <center>
                    <Button
                      variant="warning"
                      onClick={() => handleShowModal(appointment)}
                    >
                      <SlCalender size={22} />
                    </Button>
                  </center>
                </td>
                <td className="text-center bg-light">
                  <center>
                    <Button variant="success">View</Button>
                  </center>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        show={showModal}
        style={{ marginTop: "10vh", zIndex: "142512001" }}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Col md={12} className="mt-2">
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" defaultValue={modalData.date} />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-2">
              <Form.Group controlId="formTime">
                <Form.Label>Time</Form.Label>
                <Form.Control type="time" defaultValue={modalData.time} />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-2">
              <Form.Group controlId="formReason">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter reason"
                />
              </Form.Group>
            </Col>
            <Button variant="primary" className="mt-2" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </OperationLayout>
  );
}

export default PtaMeeting;
