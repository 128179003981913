import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import styled from "styled-components";

const TraficReportPage = (props) => {
  const { history } = props;
  useEffect(() => {
    var ctx = document.getElementById("studentFrequencyGraph").getContext("2d");

    var studentFrequencyGraph = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"],
        datasets: [
          {
            label: "Data",
            borderColor: "#f4e880",
            pointBorderColor: "#FFD536",
            pointBackgroundColor: "#80b6f4",
            pointHoverBackgroundColor: "#80b6f4",
            pointHoverBorderColor: "#80b6f4",
            pointBorderWidth: 10,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 4,
            data: [100, 120, 150, 170, 180, 170, 160],
          },
        ],
      },
      options: {
        legend: {
          position: "bottom",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20,
              },
              gridLines: {
                drawTicks: false,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
              },
            },
          ],
        },
      },
    });

    return () => studentFrequencyGraph.destroy();
  }, []);

  useEffect(() => {
    const createChart = (canvasId) => {
      const ctx = document.getElementById(canvasId).getContext("2d");
      return new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Maths I",
            "Maths II",
            "Maths III",
            "Maths IV",
            "Maths V",
            "Maths VI",
          ],
          datasets: [
            {
              label: "Number of Students",
              data: [50, 40, 90, 70, 30, 110],
              backgroundColor: "#f4e880",
              borderColor: "#FFD32B",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    };

    const chart1 = createChart("studentCourceGraph1");
    const chart2 = createChart("studentCourceGraph2");

    return () => {
      chart1.destroy();
      chart2.destroy();
    };
  }, []);
  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h3>Traffic Reports</h3>

      <StyledContainer>
        <Row className="mt-5">
          <Col md={3}>
            <Card className="shadow card p-3 rounded-4">
              <h6 className="text-center">Total Courses</h6>
              <h5 className="fw-bold text-center m-0">19</h5>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow card p-3 rounded-4">
              <h6 className="text-center">Number of minutes taught </h6>
              <h5 className="fw-bold text-center m-0">999</h5>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow card p-3 rounded-4">
              <h6 className="text-center">Number of Students taught </h6>
              <h5 className="fw-bold text-center m-0">199</h5>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow card p-3 rounded-4">
              <h6 className="text-center">Teacher Rating </h6>
              <h5 className="fw-bold text-center m-0">4.5</h5>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>Overall Reports</h3>
          </Col>
        </Row>

        <Row className="mt-5 align-items-center">
          <Col md={9}>
            <h6 className="fw-bold m-0">No of Students enrolled Frequency</h6>
          </Col>
          <Col md={3}>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
              >
                Select
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Month</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Week</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Day</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={11} className="mt-5">
            {" "}
            <canvas id="studentFrequencyGraph"></canvas>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h6 className="fw-bold m-0">
              no of students with ratings averagely per course
            </h6>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Revenue Generation</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>
              <hr />
              <div className="d-flex w-100 align-items-center gap-5">
                <div>
                  <h2 className="m-0 mb-1">40%</h2>
                  <p className="m-0">Growth</p>
                </div>
                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                <div>
                  <h2 className="m-0 mb-1">12%</h2>
                  <p className="m-0">Loss</p>
                </div>
                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
              </div>
              <hr />
              <canvas id="studentCourceGraph1"></canvas>
            </div>
          </Col>
          <Col md={6}>
            <div className="shadow p-3 rounded-4">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="m-0 fw-bold text_yellow">Revenue Generation</h6>
                <div className="d-flex align-items-center gap-3">
                  <button className="btns">Day</button>
                  <button className="btns">Week</button>
                  <button className="btns">Month</button>
                </div>
              </div>
              <hr />
              <div className="d-flex w-100 align-items-center gap-5">
                <div>
                  <h2 className="m-0 mb-1">40%</h2>
                  <p className="m-0">Growth</p>
                </div>
                <h6 className="fw-bold m-0 text-success">+21.2%</h6>
                <div>
                  <h2 className="m-0 mb-1">12%</h2>
                  <p className="m-0">Loss</p>
                </div>
                <h6 className="fw-bold m-0 text-danger">-12.2%</h6>
              </div>
              <hr />
              <canvas id="studentCourceGraph2"></canvas>
            </div>
          </Col>
        </Row>

    
      </StyledContainer>
    </OperationLayout>
  );
};

const StyledContainer = styled.div`
  .card {
    transition: all 0.3s ease-in-out;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      background: var(--color-primary);
      transform: translate(-6px, -6px);
    }
  }

  .btns {
    background: var(--color-primary);
    border: none;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    color: #404040;
  }

  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 200px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }
`;

export default TraficReportPage;
