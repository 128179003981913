import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Row, Container, Modal, Form, Table, Badge, Button, Card } from "react-bootstrap";
import { IoCalendarClearOutline, IoStarSharp } from "react-icons/io5";
import { GiCycle } from "react-icons/gi";
import { IconButton } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BiLike, BiSolidDislike } from "react-icons/bi";
import { FaAngleRight, FaCalendarAlt, FaEye, FaFilePdf, FaUser, FaUserGraduate, FaUsers } from "react-icons/fa";
import { MdCastForEducation, MdEdit, MdOutlineFileDownload, MdOutlineStarBorderPurple500, MdOutlineStarPurple500, MdOutlineTopic } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { GoStopwatch } from "react-icons/go";
import { AiOutlineFileDone } from "react-icons/ai";
import { CiHeart } from "react-icons/ci";
import { VscLiveShare } from "react-icons/vsc";
import { FaChalkboardUser } from "react-icons/fa6";
import screenShot from "../../assets/online-tutorials-concept_52683-37480.avif";
import BlogCommentImg1 from "../../assets/blog-comment-01.png";
import { SiGoogleclassroom, SiSinglestore } from "react-icons/si";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CourseWithActive = () => {
    const navigate = useNavigate();
    const { id, classType } = useParams();
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleYes = () => {
        setShow(false);
        setShow2(false);
        alert("Your request has been sent successfully to the admin.");
    };

    const students = [
        {
            parentName: 'John Doe',
            studentId: 'S001',
            studentName: 'Jane Doe',
            dateOfEnrollment: '2020-09-01',
            age: 12,
            gender: 'Female'
        },
        {
            parentName: 'Anna Smith',
            studentId: 'S002',
            studentName: 'Tom Smith',
            dateOfEnrollment: '2019-08-21',
            age: 14,
            gender: 'Male'
        },
        // Add more student data here
    ];

    const studentsAttendance = [
        {
            studentId: '001',
            studentName: 'John Doe',
            classDate: '2024-06-10',
            classTime: '10:00 AM',
            cycle: 'Cycle 1',
            classCount: 5,
            attendance: 'present',
        },
        {
            studentId: '001',
            studentName: 'John Doe',
            classDate: '2024-06-10',
            classTime: '10:00 AM',
            cycle: 'Cycle 1',
            classCount: 5,
            attendance: 'absent',
        },
        {
            studentId: '001',
            studentName: 'John Doe',
            classDate: '2024-06-10',
            classTime: '10:00 AM',
            cycle: 'Cycle 1',
            classCount: 5,
            attendance: 'noShow',
        },
        // Add more student objects here
    ];

    const homeworks = [
        {
            studentName: 'John Doe',
            studentId: '001',
            homeworkDate: '2024-06-10',
            deadlineDate: '2024-06-15',
            marks: 85,
            status: 'Submitted',
        },
        {
            studentName: 'Jane Smith',
            studentId: '002',
            homeworkDate: '2024-06-11',
            deadlineDate: '2024-06-16',
            marks: 90,
            status: 'Not Submitted',
        },
        // Add more homework objects here
    ];

    const tests = [
        {
            studentName: 'John Doe',
            studentId: '001',
            testDate: '2024-06-10',
            deadlineDate: '2024-06-15',
            marks: 85,
            status: 'Submitted',
        },
        {
            studentName: 'Jane Smith',
            studentId: '002',
            testDate: '2024-06-11',
            deadlineDate: '2024-06-16',
            marks: 90,
            status: 'Not Submitted',
        },
        // Add more test objects here
    ];


    const recordings = [
        {
            title: 'Math Class 101',
            date: '2024-06-10',
            duration: '1h 30m',
            link: 'https://example.com/recording1',
        },
        {
            title: 'History Class 202',
            date: '2024-06-11',
            duration: '1h 15m',
            link: 'https://example.com/recording2',
        },
        {
            title: 'Science Class 303',
            date: '2024-06-12',
            duration: '2h 00m',
            link: 'https://example.com/recording3',
        },
        {
            title: 'English Class 404',
            date: '2024-06-13',
            duration: '1h 45m',
            link: 'https://example.com/recording3',
        }
    ]

    const [showPTAMeet, setShowPTAMeet] = useState(false);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [textarea, setTextarea] = useState('');

    const handlePTAShow = () => setShowPTAMeet(true);
    const handlePTAClose = () => setShowPTAMeet(false);

    return (
        <TeacherLayout>
            {/* <h5>Courses {">"} laravel pro</h5> */}
            <Container className="p-3">
                <Row>
                    <Col md={8}>
                        <img
                            width={"100%"}
                            style={{ maxHeight: "50vh", objectFit: "cover" }}
                            height={"100%"}
                            className="rounded-3"
                            src={
                                "https://themegenix.com/demo/skillgro/assets/img/courses/courses_details.jpg"
                            }
                            alt=""
                        />

                        <div className="mt-4 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center gap-3">
                                <h5>Course Name</h5>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <button
                                    onClick={handlePTAShow}
                                    className="ms-3 mb-3 new-button"
                                >
                                    PT Meeting
                                </button>
                                <Modal style={{ marginTop: '10vh', zIndex: '142512001' }} show={showPTAMeet} onHide={handlePTAClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>PT Meeting</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group controlId="formDate">
                                                <Form.Label>Choose Date</Form.Label>
                                                <DatePicker
                                                    selected={date}
                                                    onChange={(date) => setDate(date)}
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formTime" className="mt-3">
                                                <Form.Label>Choose Time</Form.Label>
                                                <DatePicker
                                                    selected={time}
                                                    onChange={(time) => setTime(time)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Parent ID</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Parent ID" />
                                            </Form.Group>
                                            <Form.Group controlId="formTextarea" className="mt-3">
                                                <Form.Label>Details</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    value={textarea}
                                                    onChange={(e) => setTextarea(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handlePTAClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={handlePTAClose}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <button
                                    onClick={() => navigate("/teacher/dashboard/pages/class/edit-class-details")}
                                    className="ms-3 mb-3 new-button"
                                >
                                    Edit
                                </button>
                                {/* <button
                                    onClick={() => navigate("/teacher/transcript")}
                                    className="ms-3 mb-3 new-button"
                                >
                                    View Transcript
                                </button>
                                <button
                                    onClick={() => navigate("/teacher/courses/142512/course-id")}
                                    className="ms-3 mb-3 new-button"
                                >
                                    Progress
                                </button>
                                <button
                                    onClick={handleShow}
                                    className="ms-3 mb-3 new-button"
                                >
                                    Work sheets
                                </button> */}

                            </div>
                        </div>

                        <div className="d-flex align-items-center mt-4">
                            <img
                                src={screenShot}
                                className="border border-warning"
                                width={"60px"}
                                height={"60px"}
                                style={{ borderRadius: "50%" }}
                                alt=""
                            />
                            <span className="teacher-name ms-3">
                                <b>By John Smith</b>
                                <br />
                                <b><IoStarSharp className="text_yellow me-2 fs-6" /> 4.5</b>
                                <br />
                                <Link to="#">
                                    <span className="text-decoration-underline text-primary">
                                        View Profile
                                    </span>
                                </Link>
                            </span>
                            <div className="d-block ms-auto">
                                <span className="class-room-dated-flex align-items-center justify-content-center ms-3">
                                    <IoCalendarClearOutline size={22} /> &nbsp;{" "}
                                    <span>27 Jan 2022</span>
                                </span>
                                <span className="number-of-students align-items-center justify-content-center ms-3">
                                    <MdCastForEducation size={22} /> &nbsp;{" "}
                                    <span>2,250 Students Enrolled</span>
                                </span>
                            </div>
                        </div>


                        {
                            classType && classType === 'group' && (
                                <>
                                    <h5 className="mt-4 mb-3">Students</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Parent Name</th>
                                                <th>Student ID</th>
                                                <th>Student Name</th>
                                                <th>Date of enrollment</th>
                                                <th>Age</th>
                                                <th>Gender</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                students.map((student, index) => (
                                                    <tr key={index}>
                                                        <td>{student.parentName}</td>
                                                        <td>{student.studentId}</td>
                                                        <td>{student.studentName}</td>
                                                        <td>{student.dateOfEnrollment}</td>
                                                        <td>{student.age}</td>
                                                        <td>{student.gender}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Attendance</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student ID</th>
                                                <th>Student Name</th>
                                                <th>Class Date</th>
                                                <th>Class Time</th>
                                                <th>Cycle</th>
                                                <th>Class Count</th>
                                                <th>Attendance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                studentsAttendance.map((student, index) => (
                                                    <tr key={index}>
                                                        <td>{student.studentId}</td>
                                                        <td>{student.studentName}</td>
                                                        <td>{student.classDate}</td>
                                                        <td>{student.classTime}</td>
                                                        <td>{student.cycle}</td>
                                                        <td>{student.classCount}</td>
                                                        <td>{
                                                            student.attendance === 'present' ? <Badge bg="success"> present </Badge>
                                                                : student.attendance === 'absent' ? <Badge bg="danger"> absent </Badge>
                                                                    : <Badge bg="warning"> no show </Badge>
                                                        }</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Home Work</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Student ID</th>
                                                <th>Homework Date</th>
                                                <th>Deadline Date</th>
                                                <th>Marks</th>
                                                <th>Homework Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {homeworks.map((homework, index) => (
                                                <tr key={index}>
                                                    <td>{homework.studentName}</td>
                                                    <td>{homework.studentId}</td>
                                                    <td>{homework.homeworkDate}</td>
                                                    <td>{homework.deadlineDate}</td>
                                                    <td>{homework.marks}</td>
                                                    <td>
                                                        {homework.status === 'Submitted' ? (
                                                            <Badge bg="success">Submitted</Badge>
                                                        ) : (
                                                            <Badge bg="danger">Not Submitted</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button variant="primary">
                                                            <FaEye />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Test</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Student ID</th>
                                                <th>Test Date</th>
                                                <th>Deadline Date</th>
                                                <th>Marks</th>
                                                <th>Test Status</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tests.map((test, index) => (
                                                <tr key={index}>
                                                    <td>{test.studentName}</td>
                                                    <td>{test.studentId}</td>
                                                    <td>{test.testDate}</td>
                                                    <td>{test.deadlineDate}</td>
                                                    <td>{test.marks}</td>
                                                    <td>
                                                        {test.status === 'Submitted' ? (
                                                            <Badge bg="success">Submitted</Badge>
                                                        ) : (
                                                            <Badge bg="danger">Not Submitted</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button variant="primary">
                                                            <FaEye />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Recordings</h5>
                                    <RecordingsCard recordings={recordings} />
                                </>
                            )
                        }


                        {
                            classType && classType === '1-on-1' && (
                                <>

                                    <h5 className="mt-4 mb-3">Attendance</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student ID</th>
                                                <th>Student Name</th>
                                                <th>Class Date</th>
                                                <th>Class Time</th>
                                                <th>Cycle</th>
                                                <th>Class Count</th>
                                                <th>Attendance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                studentsAttendance.map((student, index) => (
                                                    <tr key={index}>
                                                        <td>{student.studentId}</td>
                                                        <td>{student.studentName}</td>
                                                        <td>{student.classDate}</td>
                                                        <td>{student.classTime}</td>
                                                        <td>{student.cycle}</td>
                                                        <td>{student.classCount}</td>
                                                        <td>{
                                                            student.attendance === 'present' ? <Badge bg="success"> present </Badge>
                                                                : student.attendance === 'absent' ? <Badge bg="danger"> absent </Badge>
                                                                    : <Badge bg="warning"> no show </Badge>
                                                        }</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Home Work</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Student ID</th>
                                                <th>Homework Date</th>
                                                <th>Deadline Date</th>
                                                <th>Marks</th>
                                                <th>Homework Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {homeworks.map((homework, index) => (
                                                <tr key={index}>
                                                    <td>{homework.studentName}</td>
                                                    <td>{homework.studentId}</td>
                                                    <td>{homework.homeworkDate}</td>
                                                    <td>{homework.deadlineDate}</td>
                                                    <td>{homework.marks}</td>
                                                    <td>
                                                        {homework.status === 'Submitted' ? (
                                                            <Badge bg="success">Submitted</Badge>
                                                        ) : (
                                                            <Badge bg="danger">Not Submitted</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button variant="primary">
                                                            <FaEye />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Test</h5>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Student ID</th>
                                                <th>Test Date</th>
                                                <th>Deadline Date</th>
                                                <th>Marks</th>
                                                <th>Test Status</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tests.map((test, index) => (
                                                <tr key={index}>
                                                    <td>{test.studentName}</td>
                                                    <td>{test.studentId}</td>
                                                    <td>{test.testDate}</td>
                                                    <td>{test.deadlineDate}</td>
                                                    <td>{test.marks}</td>
                                                    <td>
                                                        {test.status === 'Submitted' ? (
                                                            <Badge bg="success">Submitted</Badge>
                                                        ) : (
                                                            <Badge bg="danger">Not Submitted</Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button variant="primary">
                                                            <FaEye />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <h5 className="mt-4 mb-3">Recordings</h5>
                                    <RecordingsCard recordings={recordings} />
                                </>
                            )

                        }


                        <Container
                            className="shadow rounded-3 mt-4 px-5 py-4"
                            style={{ border: "1px solid #EEEEEE" }}
                        >
                            <h5>Course Description</h5>
                            <p>
                                Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                                ipsum suspendisse ultrices gravida. Risus commodo viverra
                                maecenas accumsan lacus vel facilisis.dolor sit amet,
                                consectetur adipiscing elited do eiusmod tempor incididunt ut
                                labore et dolore magna aliqua.
                            </p>
                            <h5>What you'll learn in this course?</h5>
                            <p>
                                Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                                ipsum suspendisse ultrices gravida. Risus commodo viverra
                                maecenas accumsan.
                            </p>

                            <Col className="mt-3">
                                {" "}
                                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                                    <FaAngleRight />
                                </span>{" "}
                                <b>Work with color & Gradients & Grids</b>
                            </Col>
                            <Col className="mt-3">
                                {" "}
                                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                                    <FaAngleRight />
                                </span>{" "}
                                <b>All the useful shortcuts</b>
                            </Col>
                            <Col className="mt-3">
                                {" "}
                                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                                    <FaAngleRight />
                                </span>{" "}
                                <b>Be able to create Flyers, Brochures, Advertisements</b>
                            </Col>
                            <Col className="mt-3">
                                {" "}
                                <span className="right-arrow-symbol px-2 py-1 rounded-5 bg-warning border mt-3">
                                    <FaAngleRight />
                                </span>{" "}
                                <b>How to work with Images & Text</b>
                            </Col>
                            <br />
                            <p>
                                Morem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua Quis
                                ipsum suspendisse ultrices gravida. Risus commodo viverra
                                maecenas accumsan.Dorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                dolore magn.
                            </p>
                        </Container>

                        <Col className="mt-5 bload_heading">2 Comments</Col>
                        <Col className="p-2">
                            <Col className="mt-3 blog-comment-user-box">
                                <Row>
                                    <Col md className="d-flex" style={{ gap: "10px" }}>
                                        <div className="blog-comment-image">
                                            <img src={BlogCommentImg1} alt="" />
                                        </div>
                                        <Col
                                            className="d-flex align-items-start justify-content-center"
                                            style={{ flexDirection: "column", gap: "0" }}
                                        >
                                            <span
                                                style={{ height: "fit-content", fontWeight: "600" }}
                                            >
                                                Carolyn Wallace
                                            </span>
                                            <span style={{ height: "fit-content" }}>
                                                January 27, 2023
                                            </span>
                                        </Col>
                                    </Col>
                                    <Col
                                        md
                                        className="d-flex align-items-center justify-content-end"
                                        style={{ gap: "10px" }}
                                    >
                                        <span className="blog_comment_like_icon">
                                            <BiLike />
                                        </span>
                                        <span className="blog_comment_like_icon">
                                            <BiSolidDislike />
                                        </span>
                                    </Col>
                                </Row>
                                <Col className="mt-3 p-2">
                                    Platea dictumst vestibulum rhoncus est pellentesque elit
                                    ullamcorper dignissim cras. Vitae ultricies leo integer
                                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                                    Convallis a cras semper auctor neque vitae tempus. Mattis
                                    molestie a iaculis at erat pellentesque adipiscing.
                                </Col>
                            </Col>
                            <Col className="mt-3 blog-comment-user-box">
                                <Row>
                                    <Col md className="d-flex" style={{ gap: "10px" }}>
                                        <div className="blog-comment-image">
                                            <img src={BlogCommentImg1} alt="" />
                                        </div>
                                        <Col
                                            className="d-flex align-items-start justify-content-center"
                                            style={{ flexDirection: "column", gap: "0" }}
                                        >
                                            <span
                                                style={{ height: "fit-content", fontWeight: "600" }}
                                            >
                                                Marrion Willsoriam
                                            </span>
                                            <span style={{ height: "fit-content" }}>
                                                January 27, 2023
                                            </span>
                                        </Col>
                                    </Col>
                                    <Col
                                        md
                                        className="d-flex align-items-center justify-content-end"
                                        style={{ gap: "10px" }}
                                    >
                                        <span className="blog_comment_like_icon">
                                            <BiLike />
                                        </span>
                                        <span className="blog_comment_like_icon">
                                            <BiSolidDislike />
                                        </span>
                                    </Col>
                                </Row>
                                <Col className="mt-3 p-2">
                                    Platea dictumst vestibulum rhoncus est pellentesque elit
                                    ullamcorper dignissim cras. Vitae ultricies leo integer
                                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                                    Convallis a cras semper auctor neque vitae tempus. Mattis
                                    molestie a iaculis at erat pellentesque adipiscing.
                                </Col>
                            </Col>
                            <Col className="mt-3 blog-comment-user-box">
                                <Row>
                                    <Col md className="d-flex" style={{ gap: "10px" }}>
                                        <div className="blog-comment-image">
                                            <img src={BlogCommentImg1} alt="" />
                                        </div>
                                        <Col
                                            className="d-flex align-items-start justify-content-center"
                                            style={{ flexDirection: "column", gap: "0" }}
                                        >
                                            <span
                                                style={{ height: "fit-content", fontWeight: "600" }}
                                            >
                                                Carolyn Wallace
                                            </span>
                                            <span style={{ height: "fit-content" }}>
                                                January 27, 2023
                                            </span>
                                        </Col>
                                    </Col>
                                    <Col
                                        md
                                        className="d-flex align-items-center justify-content-end"
                                        style={{ gap: "10px" }}
                                    >
                                        <span className="blog_comment_like_icon">
                                            <BiLike />
                                        </span>
                                        <span className="blog_comment_like_icon">
                                            <BiSolidDislike />
                                        </span>
                                    </Col>
                                </Row>
                                <Col className="mt-3 p-2">
                                    Platea dictumst vestibulum rhoncus est pellentesque elit
                                    ullamcorper dignissim cras. Vitae ultricies leo integer
                                    malesuada nunc vel. Nibh cras pulvinar mattis nunc sed.
                                    Convallis a cras semper auctor neque vitae tempus. Mattis
                                    molestie a iaculis at erat pellentesque adipiscing.
                                </Col>
                            </Col>
                        </Col>
                    </Col>

                    <Col md={4}>
                        <div
                            className="border border-2 rounded-3 px-3 py-3 position-sticky"
                            style={{ overflow: "hidden", top: "10vh", right: "0" }}
                        >
                            <div
                                className="d-flex align-items-center rounded-3 justify-content-center"
                                style={{
                                    height: "15vh",
                                    background: "linear-gradient(to right, #5751E0, #5751E1)",
                                    flexDirection: "column",
                                }}
                            >
                                <div>
                                    <h5 className="font-popppins text-white">
                                        This is Course fee
                                    </h5>
                                    <h5 className="text-white">
                                        ₹ 500 <sup>weekly</sup>{" "}
                                    </h5>
                                </div>
                            </div>

                            <br />
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <SlCalender size={22} /> &nbsp; <b>Subject</b>
                                </span>{" "}
                                <b>English</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <MdOutlineTopic size={22} /> &nbsp; <b>Topic</b>
                                </span>{" "}
                                <b>Course Topic</b>
                            </span>
                            {
                                classType && classType === '1-on-1' && (
                                    <>
                                        <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                            <span>
                                                <FaUser size={22} /> &nbsp; <b>Parent ID</b>
                                            </span>{" "}
                                            <b>Parent#0001</b>
                                        </span>
                                        <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                            <span>
                                                <FaUserGraduate size={22} /> &nbsp; <b>Student ID</b>
                                            </span>{" "}
                                            <b>Student#0001</b>
                                        </span>
                                        <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                            <span>
                                                <FaUserGraduate size={22} /> &nbsp; <b>Student Name</b>
                                            </span>{" "}
                                            <b>John Smith</b>
                                        </span>
                                    </>
                                )
                            }
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <GoStopwatch size={22} /> &nbsp; <b>Age group</b>
                                </span>{" "}
                                <b>14-17 years</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <SlCalender size={22} /> &nbsp; <b>Grade</b>
                                </span>{" "}
                                <b>
                                    <span>9th - 12th</span>
                                </b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <SiSinglestore size={22} /> &nbsp; <b>Type</b>
                                </span>{" "}
                                <b>{classType && classType} Class</b>
                            </span>

                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <FaUsers size={22} /> &nbsp; <b>Students</b>
                                </span>{" "}
                                <b>200+</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <SiGoogleclassroom size={22} /> &nbsp; <b>Class Count</b>
                                </span>{" "}
                                <b>06/30</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <GiCycle size={22} /> &nbsp; <b>Cycle Start Date</b>
                                </span>{" "}
                                <b>22 March 2024</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <GiCycle size={22} /> &nbsp; <b>Cycle Count</b>
                                </span>{" "}
                                <b>5</b>
                            </span>
                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <GiCycle size={22} /> &nbsp; <b>Cycle End Date</b>
                                </span>{" "}
                                <b>22 April 2024</b>
                            </span>

                            <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                                <span>
                                    <FaCalendarAlt size={22} /> &nbsp; <b>Class enrolled</b>
                                </span>{" "}
                                <b>22 April 2024</b>
                            </span>

                            <Col className="mt-3">
                                <span className="mt-3 mb-2">
                                    <b>Course Completed</b>
                                </span>
                                <ProgressBar className="mt-2" now={30} label={`${30}%`} />
                            </Col>
                            <div
                                className="d-flex align-items-center justify-content-between mt-3"
                                style={{ gap: "10px" }}
                            >
                                <IconButton aria-label="delete">
                                    <CiHeart size={32} />
                                </IconButton>

                                <IconButton aria-label="delete">
                                    <VscLiveShare size={32} />
                                </IconButton>
                            </div>
                            {/* <button
                                className="w-100 py-2 rounded-3 mt-2 fw-bold text-white border-0"
                                style={{ background: "var(--color-primary)" }}
                            >
                                Take a Free Demo
                            </button> */}
                        </div>
                    </Col>
                </Row>

            </Container>
            <ParentFooter />

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Work sheets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-2'>
                        <Col md={6} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFilePdf /> HC Verma</div>
                                <MdEdit className="cursor-pointer" size={18} />
                            </div>
                        </Col>
                        <Col md={6} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFilePdf /> HC Verma</div>
                                <MdEdit className="cursor-pointer" size={18} />
                            </div>
                        </Col>
                        <Col md={6} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFilePdf /> HC Verma</div>
                                <MdEdit className="cursor-pointer" size={18} />
                            </div>
                        </Col>
                        <Col md={6} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFilePdf /> HC Verma</div>
                                <MdEdit className="cursor-pointer" size={18} />
                            </div>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-between mt-3">
                            <Form.Group>
                                <Form.Control className="shadow border border-dark" type="file" />
                            </Form.Group>
                            <button className="btn btn-primary shadow">upload</button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-warning rounded-5" onClick={handleYes}>
                        Edit
                    </button>
                    <button className="btn btn-danger rounded-5" onClick={handleClose}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to cancel this course?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-warning rounded-5" onClick={handleYes}>
                        Yes
                    </button>
                    <button className="btn btn-danger rounded-5" onClick={handleClose2}>
                        No
                    </button>
                </Modal.Footer>
            </Modal>
        </TeacherLayout>
    );
};




const RecordingsCard = ({ recordings }) => {
    return (
        <Row xs={1} md={2} className="g-4">
            {recordings.map((recording, index) => (
                <Col key={index}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{recording.title}</Card.Title>
                            <Card.Text>
                                <strong>Class Date:</strong> {recording.date}<br />
                                <strong>Duration:</strong> {recording.duration}
                            </Card.Text>
                            <Button variant="primary" className="text-white" href={recording.link} target="_blank">Watch Recording</Button>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};


export default CourseWithActive;

