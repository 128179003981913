import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import axios from "../../utils/axios.js";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";
import PhoneInput from "react-phone-number-input";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { countries } from "@nexisltd/country";

const Registration = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [viewButtonVisible, setViewButtonVisible] = useState(false);
  const [teacherData, setTeacherData] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [allCountries, setAllCountries] = useState([]);

  // form variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [nationality, setNationality] = useState("");
  const [timezone, setTimezone] = useState("");
  const [about, setAbout] = useState("");
  const [video, setVideo] = useState("");
  const [teacherImage, setTeacherImage] = useState("");
  const [visibleName, setVisibleName] = useState("");
  const [indianCitizen, setIndianCitizen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [criminalCase, setCriminalCase] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB in bytes

  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    const allCountries = countries();
    setAllCountries(allCountries);
  }, [token]);

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const { data } = await axios.get("/teacher/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log(data);

        if (data.res_type === "success") {
          const { teacher } = data.data;

          setTeacherData(teacher);
          setFirstName(teacher?.firstName);
          setLastName(teacher?.lastName);
          setEmail(teacher?.email);
          setPhone(teacher?.phone);
          setVisibleName(teacher?.visibleName);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeacherData();
  }, []);

  useEffect(() => {
    if (teacherData?.status === "active") {
      navigate("/teacher/dashboard/pages/under-review");
    } else if (teacherData?.status === "reg-one") {
      navigate("/teacher/registration-next");
    } else if (teacherData?.status === "reg-two") {
      navigate("/teacher/dashboard/pages/under-review");
    }
  }, [teacherData?.status]);

  const navigate = useNavigate();
  const handlePreviewImage = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setTeacherImage(file);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setViewButtonVisible(true);
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("File size exceeds 30MB");
        setVideo(null); // reset the file input if file is too large
      } else {
        setError(""); // clear any previous errors
        setVideo(file);
        const videoUrl = URL.createObjectURL(file);
        setVideoPreview(videoUrl); // to preview the video if needed
      }
    }
  };

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleVideoModalClose = () => setShowVideoModal(false);
  const handleVideoModalShow = () => setShowVideoModal(true);

  const handleRegistrationOne = async (e) => {
    e.preventDefault();

    if (!video || error) {
      return toast.error("Please upload a valid video");
    }

    const formData = new FormData();
    formData.append("whatsApp", whatsapp);
    formData.append("dob", dob);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("address", address);
    formData.append("country", country);
    formData.append("pincode", pincode);
    formData.append("city", city);
    formData.append("nationality", nationality);
    formData.append("timezone", timezone);
    formData.append("about", about);
    formData.append("is_indian_citizen", indianCitizen);
    formData.append("is_criminal_case", criminalCase);
    formData.append("video", video);
    formData.append("image", teacherImage);

    try {
      setLoading(true);
      const res = await axios.put("/teacher/reg-one", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      console.log(res.data);

      // check if status code is 200 then show success message

      if (res.data.data !== null) {
        toast.success(res.data.message);
        navigate("/teacher/registration-next");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeacherLayout>
      <h3>Registration</h3>

      <div className="container mt-4 bg-white rounded-5 shadow py-4 px-3">
        <Form onSubmit={handleRegistrationOne}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                disabled
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Last name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                disabled
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col md={3} className=" mb-3 ">
              <Form.Label>Phone</Form.Label>
              <div className=" px-3 py-2 border rounded-3">
                <PhoneInput
                  international
                  style={{ border: "none !important" }}
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  countryCodeEditable={true}
                  value={phone}
                  onChange={setPhone}
                  disabled
                />
              </div>
            </Col>
            <Col md={3} className=" mb-3 ">
              <Form.Label>Whatsapp</Form.Label>
              <div className=" px-3 py-2 border rounded-3">
                <PhoneInput
                  international
                  style={{ border: "none !important" }}
                  defaultCountry="IN"
                  placeholder="Enter phone number"
                  countryCodeEditable={true}
                  value={whatsapp}
                  onChange={setWhatsapp}
                  required
                />
              </div>
            </Col>
            {/* </Row> */}

            {/* <Row className="mb-3"> */}

            {/* DOB */}
            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                placeholder="DOB"
                required
                onChange={(e) => setDob(e.target.value)}
                value={dob}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label>Age</Form.Label>
              <Form.Control
                type="text"
                placeholder="Age"
                required
                onChange={(e) => setAge(e.target.value)}
                value={age}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Form.Group>

            {/* GENDER */}

            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label>Gender</Form.Label>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                required
              >
                <option>Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Form.Select>
            </Form.Group>

            {/* Address */}
            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                required
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid state.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="validationCustom03">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                placeholder="Country"
                required
                onChange={(e) => setCountry(e.target.value)}
                value={country}
              >
                <option value="">Select a country</option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select a country.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Pincode */}
            <Form.Group as={Col} md="3" controlId="validationCustom05">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="number"
                placeholder="Pincode"
                required
                onChange={(e) => setPincode(e.target.value)}
                value={pincode}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid zip.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md="3"
              className="mt-2"
              controlId="validationCustom03"
            >
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                required
                onChange={(e) => setCity(e.target.value)}
                value={city}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid city.
              </Form.Control.Feedback>
            </Form.Group>

            {/* NATIONALITY */}

            <Form.Group
              as={Col}
              md="3"
              className="mt-2"
              controlId="validationCustom04"
            >
              <Form.Label>Nationality</Form.Label>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setNationality(e.target.value)}
                value={nationality}
                required
              >
                <option>Select</option>
                {allCountries.map((country, index) => (
                  <option key={index} value={country.nationality}>
                    {country.nationality}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              as={Col}
              md="3"
              className="mt-2"
              controlId="validationCustom04"
            >
              <Form.Label>Select Timezone</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setTimezone(e.target.value)}
                value={timezone}
                required
              >
                <option value="">Select</option>
                {allCountries.map((country) =>
                  country?.tz.map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))
                )}
              </Form.Select>
            </Form.Group>

            {/* About you */}
            <Form.Group
              className="mt-2"
              as={Col}
              md="12"
              controlId="validationCustom03"
            >
              <Form.Label>About you</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="About you"
                required
                onChange={(e) => setAbout(e.target.value)}
                value={about}
              />
              <Form.Control.Feedback type="invalid">
                Please provide the details.
              </Form.Control.Feedback>
            </Form.Group>
            {/* </Row> */}

            {/* <Row className="mb-3"> */}
            {/* Introducation Video */}
            <Form.Group
              as={Col}
              className="mt-2"
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Introducation Video</Form.Label>
              <Form.Control
                type="file"
                placeholder="Introducation Video"
                required
                onChange={handleVideoChange}
              />
              {error && <p style={{ color: "red", marginTop: 10 }}>{error}</p>}
              {uploadProgress > 0 && (
                <div className="progress mt-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadProgress}%` }}
                    aria-valuenow={uploadProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {uploadProgress}%
                  </div>
                </div>
              )}
              <Form.Label
                onClick={handleVideoModalShow}
                className="text-primary cursor-pointer mt-2"
              >
                view guidelines
              </Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Video.
              </Form.Control.Feedback>
            </Form.Group>

            {/* user image */}
            <Form.Group
              className="mt-2"
              as={Col}
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Teacher Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="User Image"
                required
                onChange={handleImageChange}
              />
              {viewButtonVisible && (
                <Button
                  className="btn btn-sm btn-primary mt-2"
                  onClick={handlePreviewImage}
                >
                  View
                </Button>
              )}
              <Form.Control.Feedback type="invalid">
                Please provide a valid Image.
              </Form.Control.Feedback>
            </Form.Group>

            {/* visible name */}
            <Form.Group
              className="mt-2"
              as={Col}
              md="4"
              controlId="validationCustom03"
            >
              <Form.Label>Visible Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Visible Name"
                required
                onChange={(e) => setVisibleName(e.target.value)}
                value={visibleName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Name.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mt-4">
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                required
                label="Are you a Indian Citizen?"
                feedback="You must agree before submitting."
                feedbackType="invalid"
                onChange={(e) => setIndianCitizen(e.target.checked)}
                value={indianCitizen}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                required
                label="Agree to terms and conditions"
                feedback="You must agree before submitting."
                feedbackType="invalid"
                onChange={(e) => setTerms(e.target.checked)}
                value={terms}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Check
                required
                label="Criminal court case (if any) ?"
                feedback="You must agree before submitting."
                feedbackType="invalid"
                onChange={(e) => setCriminalCase(e.target.checked)}
                value={criminalCase}
              />
            </Form.Group>
          </Row>

          <button
            className="border-0 bg-yellow px-4 py-2 rounded-3 mt-3 text-white"
            type="submit"
          >
            {loading ? "Loading..." : "Next"}
          </button>
        </Form>
      </div>
      <Modal
        style={{ zIndex: "142512001" }}
        show={showVideoModal}
        onHide={handleVideoModalClose}
        size="lg"
        centered
      >
        <Modal.Body className="p-4 m-0">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                  background: "#000",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={videoPreview}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Full Video"
                ></iframe>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ParentFooter />

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imagePreview && (
            <img src={imagePreview} alt="preview" style={{ width: "100%" }} />
          )}
        </Modal.Body>
      </Modal>
    </TeacherLayout>
  );
};

export default Registration;
