import React from "react";
import { Col, Form, Row } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";

const WebsiteSettingPage = (props) => {
    const { history } = props;


    return (
        <OperationLayout>
            <h4>Website Setting</h4>
            {/* <OperationLocation path={history} /> */}
            <Row>
                <Col xs={12} className="mb-3">
                    <Form>
                        <Form.Group as={Col} md={4} controlId="formSelect">
                            <Form.Label>Select Option</Form.Label>
                            <Form.Control className="shadow border-yellow rounded-5" as="select">
                                <option>Option 1</option>
                                <option>Option 2</option>
                                <option>Option 3</option>
                                <option>Option 4</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="formHeading">
                            <Form.Label>Heading Text</Form.Label>
                            <Form.Control className="shadow border-yellow rounded-5" type="text" placeholder="Enter heading text" />
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="formFont">
                            <Form.Label>Font Family</Form.Label>
                            <Form.Control className="shadow border-yellow rounded-5" as="select">
                                <option>Font 1</option>
                                <option>Font 2</option>
                                <option>Font 3</option>
                                <option>Font 4</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md={3} controlId="formContact">
                            <Form.Label>Contact</Form.Label>
                            <Form.Control className="shadow border-yellow rounded-5" type="tel" placeholder="Enter contact number" />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </OperationLayout>
    );
};

export default WebsiteSettingPage;
