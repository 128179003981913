import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

const SearchPage = (props) => {
  const { history } = props;
  const navigate = useNavigate();
  const navigateToProfilePage = () => {
    navigate("/operation/Teachers/fgsdfghah");
  };
  return (
    <OperationLayout>
      <h5>Search</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select teachers type
              </option>
              <option value={"1"}>Acedemics</option>
              <option value={"1"}>Extra Carriculam</option>
              <option value={"2"}>Extra Acedemics</option>
              <option value={"2"}>IITian</option>
              <option value={"2"}>Hobby Classes</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by I'D"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Name"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search by Email"
            />
          </Form.Group>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control className="shadow rounded-3" type="date" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select status
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>Inactive</option>
              <option value={"2"}>Block</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-3 mt-3">
              <option selected disabled>
                select live status
              </option>
              <option value={"1"}>Active</option>
              <option value={"2"}>Inactive</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3 mb-3">
        <h5>Search Result ( 3 )</h5>
      </Row>

      <StyleContainer>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
          style={{
            height: "auto",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ flexShrink: 0 }} className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  flexWrap: "wrap",
                }}
              >
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Kristin Watson</h6>
                  <p className="m-0">12 January 2023</p>
                </div>
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Status</h6>
                  <Badge bg="success">Active</Badge>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Id</h6>
                  <p>TEACH-123456</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Date of Joining</h6>
                  <p>12 January 2023</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Phone</h6>
                  <p>4556677654</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Email</h6>
                  <p>Test@test.com</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Subject</h6>
                  <p>Maths</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Grade</h6>
                  <p>1st</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Board</h6>
                  <p>CBSE</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Type</h6>
                  <p>Acedemics</p>
                </div>
              </div>
            </div>
            <p
              onClick={navigateToProfilePage}
              className="m-0 d-flex align-items-center fw-bold text_yellow"
            >
              view <FaAngleRight />
            </p>
          </div>
        </div>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
          style={{
            height: "auto",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ flexShrink: 0 }} className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  flexWrap: "wrap",
                }}
              >
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Kristin Watson</h6>
                  <p className="m-0">12 January 2023</p>
                </div>
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Status</h6>
                  <Badge bg="danger">InActive</Badge>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Id</h6>
                  <p>TEACH-123456</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Date of Joining</h6>
                  <p>12 January 2023</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Phone</h6>
                  <p>4556677654</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Email</h6>
                  <p>Test@test.com</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Subject</h6>
                  <p>Maths</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Grade</h6>
                  <p>1st</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Board</h6>
                  <p>CBSE</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Type</h6>
                  <p>Acedemics</p>
                </div>
              </div>
            </div>
            <p
              onClick={navigateToProfilePage}
              className="m-0 d-flex align-items-center fw-bold text_yellow"
            >
              view <FaAngleRight />
            </p>
          </div>
        </div>
        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 mt-3 shadow rounded-4 `}
          style={{
            height: "auto",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ flexShrink: 0 }} className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div
                className="d-flex align-items-center"
                style={{
                  flexWrap: "wrap",
                }}
              >
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Kristin Watson</h6>
                  <p className="m-0">12 January 2023</p>
                </div>
                <div className="ms-5">
                  <h6 className="m-0 fw-bold">Status</h6>
                  <Badge bg="success">Active</Badge>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Id</h6>
                  <p>TEACH-123456</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Date of Joining</h6>
                  <p>12 January 2023</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Phone</h6>
                  <p>4556677654</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Email</h6>
                  <p>Test@test.com</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Subject</h6>
                  <p>Maths</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Grade</h6>
                  <p>1st</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Board</h6>
                  <p>CBSE</p>
                </div>
                <div className="ms-5 mt-1">
                  <h6 className="m-0 fw-bold">Teacher Type</h6>
                  <p>Acedemics</p>
                </div>
              </div>
            </div>
            <p
              onClick={navigateToProfilePage}
              className="m-0 d-flex align-items-center fw-bold text_yellow"
            >
              view <FaAngleRight />
            </p>
          </div>
        </div>
      </StyleContainer>
    </OperationLayout>
  );
};

const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export default SearchPage;
