import React, { useState } from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { IoShareSocial } from "react-icons/io5";
import { Row, Col, Form, Table, Badge } from "react-bootstrap";
import "./Referral.css";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";
import { MdOutlineDevicesOther, MdScreenSearchDesktop } from "react-icons/md";
import { FcAdvertising } from "react-icons/fc";
import { FaChalkboardTeacher, FaUserFriends } from "react-icons/fa";
function Referral(props) {
  const { history } = props;

  let ReferralQA = [
    {
      id: 1,
      title: "What is Learnie.",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae voluptatum libero exercitationem. Soluta voluptatibus quaerat architecto tempora. Ipsa rerum aliquid maiores impedit. Nihil voluptatibus mollitia nulla ab minima beatae exercitationem!",
    },
    {
      id: 2,
      title: "What is Learnie.",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae voluptatum libero exercitationem. Soluta voluptatibus quaerat architecto tempora. Ipsa rerum aliquid maiores impedit. Nihil voluptatibus mollitia nulla ab minima beatae exercitationem!",
    },
    {
      id: 3,
      title: "Can I eligible for Referral.",
      desc: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae voluptatum libero exercitationem. Soluta voluptatibus quaerat architecto tempora. Ipsa rerum aliquid maiores impedit. Nihil voluptatibus mollitia nulla ab minima beatae exercitationem!",
    },
  ];

  const [expandedCols, setExpandedCols] = useState(
    new Array(ReferralQA?.length).fill(false)
  );

  const toggleExpand = (index) => {
    const newExpandedCols = [...expandedCols];
    newExpandedCols[index] = !newExpandedCols[index];
    setExpandedCols(newExpandedCols);
  };

  const copyToClipboard = () => {
    const referralLinkText = document.querySelector(".referral-link-text");

    if (referralLinkText) {
      const textArea = document.createElement("textarea");
      textArea.value = referralLinkText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  };

  return (
    <OftadehLayout>
      <h1>Referral</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row>
        <Col md={6}>
          <div className="referral-container-area shadow">
            <span className="title-referral-card">Referral</span>
            <Col className="mt-3 ammount-area">
              <CurrencyRupeeIcon /> <b style={{ fontSize: "22px" }}>0</b>
            </Col>
            <p className="mt-2">Your Pending Rewards </p>
            <hr />
            <Col className="mt-3 ammount-area">
              <CurrencyRupeeIcon /> <b style={{ fontSize: "22px" }}>0</b>
            </Col>
            <p className="mt-2">Encashed Referral Credit</p>
            <hr />
            <Col className="mt-3 ammount-area">
              <CurrencyRupeeIcon /> <b style={{ fontSize: "22px" }}>0</b>
            </Col>
            <p className="mt-2">
              Your total amount you have earned by referring people to Urja
              Telants!{" "}
            </p>

            {/* <div className="mt-3 referral-progress-btn-area">
              Check your progress
            </div> */}
          </div>
        </Col>
        <Col md={6} className="mt-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0 ">
          <div className="referral-container-area shadow">
            <span className="title-referral-card">
              Share through social media
            </span>
            <Col className="mt-5 referral-link" md={12}>
              <center>
                {" "}
                <span className="referral-link-text">
                  https://localhost:3000/referral?Invite=NIT14578A7P
                </span>{" "}
                <span
                  className="ms-2 icon-referral-page"
                  onClick={copyToClipboard}
                >
                  <ContentCopyIcon />
                </span>
              </center>
              <hr />
              <center className="d-flex align-items-center justify-content-center">
                <h6>
                  <b>Your Code </b>: NIT152512A78
                </h6>
                <span
                  className="ms-2 icon-referral-page"
                  onClick={copyToClipboard}
                >
                  <ContentCopyIcon />
                </span>
              </center>
            </Col>
            <Col
              className="referral-social-link mt-5 d-flex align-items-center justify-content-center"
              style={{ gap: "20px", color: "white" }}
            >
              <FacebookIcon />
              <TwitterIcon />
              <LinkedInIcon />
              <WhatsAppIcon />
            </Col>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h4>How you here about us</h4>
        </Col>
      </Row>
      <Row className="p-3 shadow rounded-3 mt-3 mb-3">
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Search Engine"
          />
          <MdScreenSearchDesktop className="fs-5" />
        </Col>
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Social Media"
          />
          <IoShareSocial className="fs-5" />
        </Col>
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Online Advertising"
          />
          <FcAdvertising className="fs-5" />
        </Col>
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Referral From Friends and Family"
          />
          <FaUserFriends className="fs-5" />
        </Col>
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Referral From School and Teacher"
          />
          <FaChalkboardTeacher className="fs-5" />
        </Col>
        <Col md={4} className="d-flex align-items-center gap-1">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Other"
          />
          <MdOutlineDevicesOther className="fs-5" />
        </Col>
        <Col md={7}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3 mt-3 mb-3"
              type="text"
              placeholder="specify"
            />
          </Form.Group>
        </Col>
        {/* <Col md={5}>
        <Form.Group controlId='nameInput'>
          <Form.Control
            className='shadow rounded-3 mt-3 mb-3'
            type='text'
            placeholder='Enter Your Referral Code.'
          />
        </Form.Group>
        </Col> */}
        <Col>
          <Button className="mt-3" variant="outlined">
            Save
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <h6>Referral's</h6>
      </Row>
      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">Student Id</th>
            <th className="text-white">Referral Id</th>
            <th className="text-white">Name</th>
            {/* <th className="text-white">Class</th>
            <th className="text-white">Board</th> */}
            <th className="text-white">Status</th>
            <th className="text-white">Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>referral#-0001</td>
            <td>Joh Smith</td>
            {/* <td>10th</td> */}
            {/*  <td>CBSE</td> */}
            <td>
              <Badge bg="warning" className="text-black">
                Pending
              </Badge>
            </td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>referral#-0001</td>
            <td>Joh Smith</td>
            {/* <td>10th</td> */}
            {/*  <td>CBSE</td> */}
            <td>
              <Badge bg="warning" className="text-black">
                Pending
              </Badge>
            </td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>referral#-0001</td>
            <td>Joh Smith</td>
            {/* <td>10th</td> */}
            {/*  <td>CBSE</td> */}
            <td>
              <Badge bg="warning" className="text-black">
                Pending
              </Badge>
            </td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>referral#-0001</td>
            <td>Joh Smith</td>
            {/* <td>10th</td> */}
            {/*  <td>CBSE</td> */}
            <td>
              <Badge bg="success">success</Badge>
            </td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>referral#-0001</td>
            <td>Joh Smith</td>
            {/* <td>10th</td> */}
            {/*  <td>CBSE</td> */}
            <td>
              <Badge bg="warning" className="text-black">
                Pending
              </Badge>
            </td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
        </tbody>
      </Table>

      <Row className="mt-5 ">
        <h4>Referral Q&A</h4>
      </Row>
      <Row className="p-3">
        {ReferralQA.map((column, index) => (
          <Col
            key={index}
            md={12}
            className="mb-3 referral-q-a-item rounded-2 shadow border"
          >
            <Col
              onClick={() => toggleExpand(index)}
              className="referral-area-heading p-0 m-0 d-flex align-items-center justify-content-between"
            >
              <Col sm={10} md={10} className="referral-q-a-heading">
                {column.title}
              </Col>
              <Col
                sm={1}
                md={2}
                className="referral-q-a-drop-icon d-flex align-items-center justify-content-end"
              >
                {!expandedCols[index] && <ArrowDropDownIcon />}
                {expandedCols[index] && <ArrowDropUpIcon />}
              </Col>
            </Col>
            {expandedCols[index] && (
              <Col onClick={() => toggleExpand(index)} md={12} className="pb-3">
                <hr />
                {column.desc}
              </Col>
            )}
          </Col>
        ))}
      </Row>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default Referral;
