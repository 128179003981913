import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { MdOutlineStarHalf, MdStar } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa";
import { Badge, Col, Form, Row } from "react-bootstrap";
import Card1 from "../../ParentPages/parent/Card1";
import { IoStarSharp } from "react-icons/io5";
import { IoIosHeart } from "react-icons/io";
import card1 from "../../assets/parent/card1.png";
import ParentFooter from "../../components/ParentFooter";
const ReviewPage = () => {
    const [active1, setActive1] = React.useState(false);
    const [active2, setActive2] = React.useState(true);

  return (
    <TeacherLayout>
      <h3>Review</h3>
      <Row className='justify-content-between'>
        <Col md={4}>
          <Form.Group controlId='selectOption'>
            <Form.Select
            style={{
              borderColor:"var(--color-primary)"
            }}
            className='shadow rounded-3 mt-3'>
              <option selected disabled>
                select review type
              </option>
              <option value={'1'}>Blog</option>
              <option value={'2'}>Corses</option>
              <option value={'2'}>Teachers</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className='mt-3'>
          <Form.Group controlId='nameInput'>
            <Form.Control
             style={{
              borderColor:"var(--color-primary)"
            }}
              className='shadow rounded-3'
              type='text'
              placeholder='Search...'
            />
          </Form.Group>
        </Col>
      </Row>
      <StyleContainer>

        <div
          className={`mt-4 position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border ${
            active1 === true ? "active" : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="ms-5">
              <h6 className="m-0 fw-bold">Kristin Watson</h6>
              <p>12 January 2023</p>
              </div>
              <Badge className="ms-3" bg="success">Accepted</Badge>
            </div>
            <p
              className="m-0 fw-bold text_yellow"
              onClick={() => setActive1(!active1)}
            >
              view <FaAngleRight />
            </p>
          </div>
          <div className="content mt-3">
            <p className="m-0 d-flex align-items-center">
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
              4.5 Rating
            </p>
            <p className="m-0 mt-3">
              Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
              nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
              porttitor. Fermentum scelerisque pretium mi convallis ac eget
              ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
              dignissim congue ultrices dui diam. Convallis ut eu elit
              imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
              fringilla. Augue condimentum nulla in velit nascetur tempor
              venenatis cras. Auctor mi nulla non neque.
            </p>
          </div>
        </div>

        <div
          className={`position-relative pe-5 feedback_review py-2 px-3 shadow rounded-3 border mt-4 ${
            active2 === true ? "active" : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="review_user_image">
                <img
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt=""
                />
              </div>
              <div className="ms-5">
              <h6 className="m-0 fw-bold">Kristin Watson</h6>
              <p>12 January 2023</p>
              </div>
              <Badge className="ms-3" bg="warning">Pending</Badge>
            </div>
            <p
              className="m-0 fw-bold text_yellow"
              onClick={() => setActive2(!active2)}
            >
              view <FaAngleRight />
            </p>
          </div>
          <div className="content mt-3">
            <p className="m-0 d-flex align-items-center">
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdStar className="text_yellow fs-6 me-1" />
              <MdOutlineStarHalf className="text_yellow fs-6 me-1" />
              4.5 Rating
            </p>
            <p className="m-0 mt-3">
              Lorem ipsum dolor sit amet consectetur. Magna ut in maecenas
              nascetur lorem semper. Vitae fermentum eu dictum at auctor mi est
              porttitor. Fermentum scelerisque pretium mi convallis ac eget
              ultricies libero. Cursus tempus ut nulla sit. Augue placerat nulla
              dignissim congue ultrices dui diam. Convallis ut eu elit
              imperdiet. Commodo pulvinar dignissim blandit sed odio bibendum
              fringilla. Augue condimentum nulla in velit nascetur tempor
              venenatis cras. Auctor mi nulla non neque.
            </p>
          </div>
        </div>
      </StyleContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};
const StyleContainer = styled.div`
  .feedback_review {
    cursor: pointer;
    height: 70px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .feedback_review.active {
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  .review_user_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export default ReviewPage;
