import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Col,  Row } from "react-bootstrap";
import Footer from "../components/Footer";

const TermAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <PolicyContainer>
        <div className="hero px-5 pb-4">
          <br />
          <h2 className=" fw-bold mb-3">TERMS AND CONDITIONS</h2>
          <li className="m-0">
            Please read this TERMS AND CONDITIONS carefully before using the
            Urja Talents website and services.
          </li>
          <p className="mt-3">Last Updated: 18 January 2024</p>
        </div>

        <Row className="mt-5 px-5 px-sm-3 px-md-5 px-lg-5 px-xl-5 px-xxl-5">
          <Col className="m-0 p-0">
            <h4>Background</h4>
            <ul>
              <li className="mt-md-3 alfabet-list">
                Urja Talents operates an online platform ("Platform") that
                enables connection between teachers and students for teaching
                services.{" "}
              </li>
              <li className="mt-md-3 alfabet-list">
                Teacher wishes to register on the Platform to provide teaching
                services to students
              </li>
              <li className="mt-md-3 alfabet-list">
                This Agreement sets out the terms and conditions governing the
                relationship and use of the Platform between Teacher and Urja
                Talents
              </li>
              <li className="mt-md-3 alfabet-list">
                This Agreement shall come into effect from the date of
                acceptance by both Parties.{" "}
              </li>
              <li className="mt-md-3 alfabet-list">
                The laws of India shall govern this Agreement and its
                interpretation
              </li>
              <li className="mt-md-3 alfabet-list">
                Urja Talents reserves the right to amend the terms of this
                Agreement from time to time by providing due communication to
                Teacher. Teacher's continued use of the Platform after such
                communication shall be deemed as consent to the amended terms.
              </li>
            </ul>
            <h4 className="mt-4">Definitions</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                Teacher means an individual registered on the Platform as
                defined in clause 1.8 to provide teaching/tutoring services to
                users.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                Content means all material including profile details, courses,
                study materials, feedback etc. uploaded by a Teacher on the
                Platform.
              </li>
              <li className="mt-md-3 circle-list">
                Services means teaching, tutoring or any other educational
                services provided by a Teacher to users through the Platform
              </li>
              <li className="mt-md-3 circle-list">
                Parties means the Teacher and Urja Talents collectively referred
                to in this Agreement
              </li>
              <li className="mt-md-3 circle-list">
                Terms means these terms and conditions including any annexures,
                schedules, exhibits and/or amendments thereto and the Privacy
                Policy collectively
              </li>
              <li className="mt-md-3 circle-list">
                Website means the website owned and operated by Urja Talents at
                [WEBSITE URL] and any other websites/applications owned/operated
                by Urja Talents
              </li>
              <li className="mt-md-3 circle-list">
                Urja Talents means Urja Talents Private Limited, a company
                incorporated under the Companies Act, 2013 and having its
                registered office at [REGISTERED ADDRESS].
              </li>
              <li className="mt-md-3 circle-list">
                Platform means the online platform owned and operated by Urja
                Talents to enable connection between Teachers and users for
                educational services
              </li>
              <li className="mt-md-3 circle-list">
                Online hours shall encompass the total duration the Teacher
                marks themselves as available for classes on the Urja Talents
                platform. This includes both active teaching time and periods
                spent waiting for student connections
              </li>
            </ul>
            <h4 className="mt-4">Scope and Applicability</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                These Terms shall be binding upon the Teacher and Urja Talents
                upon the Teacher registering on the Platform
              </li>
              <li className="mt-md-3 circle-list">
                These Terms govern the relationship between the Teacher and Urja
                Talents and use of the Platform by the Teacher
              </li>
              <li className="mt-md-3 circle-list">
                3 By continuing to use the Platform, the Teacher agrees to be
                bound by the latest version of these Terms as may be in force
                from time to time.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                Urja Talents reserves the right to amend these Terms from time
                to time. An updated version will be binding upon notification to
                the Teacher through the Platform or email. Continued use of the
                Platform after such notification will constitute consent by the
                Teacher.
              </li>
              <li className="mt-md-3 circle-list">
                These Terms cover the Service Terms and Privacy Policy governing
                use of the Platform. Together they constitute the legal terms
                between the Teacher and Urja Talents.
              </li>
              <li className="mt-md-3 circle-list">
                A Teacher shall mean an individual who has submitted verified
                credentials of a recognised degree/diploma in the subjects being
                taught and whose profile has been approved and activated on the
                Platform by Urja Talents for the purpose of providing teaching
                services
              </li>
            </ul>
            <h4 className="mt-4">Obligations of the Parties</h4>
            <ul>
              <li className="mt-md-3 number-list">
                The Teacher affirms and undertakes that:
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  All information provided by the Teacher to Urja Talents
                  including qualifications,experience, skills etc. shall be
                  true, accurate and complete{" "}
                </li>
                <li className="mt-md-3 circle-list">
                  The Teacher shall maintain the declared qualifications and
                  skills and update Urja Talents of any changes.
                </li>
                <li className="mt-md-3 circle-list">
                  3 The Teacher shall not perform any services for which they
                  are not duly qualified or authorized.
                </li>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not provide any career or vocational
                  counselling services to students without proper certification
                  or license.
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                The Teacher affirms and undertakes that:
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Maintaining proper decorum while interacting and teaching
                  students on the Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  Not uploading any unlawful, harmful, abusive or objectionable
                  content on the Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  Maintaining respectful communications with students, parents
                  and Urja Talents.
                </li>
                <li className="mt-md-3 circle-list">
                  Not engaging in any acts that harm the reputation or business
                  interests of Urja Talents.
                </li>
                <li className="mt-md-3 circle-list">
                  Not promoting any competing platforms or services while
                  associated with Urja Talents.
                </li>
                <li className="mt-md-3 circle-list">
                  Not sharing any sensitive or internal information with
                  unauthorized parties.
                </li>
                <li className="mt-md-3 circle-list">
                  Not disclosing internal policies or contractual details of
                  Urja Talents.
                </li>
              </ul>
              <li className="mt-md-3 number-list">The Teacher shall</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Not share any personal details of themselves, friends or
                  contacts with students.
                </li>
                <li className="mt-md-3 circle-list">
                  Not charge students for free services provided through the
                  Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  3 Not receive any payments directly from students and shall
                  route all transactions through the Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  4 Not contact or solicit students met through the Platform
                  outside of the Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  5 Maintain privacy and confidentiality of any student
                  information shared.
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                The Teacher shall spend a minimum of 4 hours daily being online
                on the Platform.
              </li>
              <li className="mt-md-3 number-list">The Teacher shall</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Not use the Platform to promote any personal services without
                  verification.
                </li>
                <li className="mt-md-3 circle-list">
                  Not force or impose any personal services on students.
                </li>
                <li className="mt-md-3 circle-list">
                  Submit to penalties for misusing the Platform for personal
                  gain.
                </li>
              </ul>
              <li className="mt-md-3 number-list">The Teacher shall</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Not obtain student contact information or solicit students
                  personally.
                </li>
                <li className="mt-md-3 circle-list">
                  Inform students to route queries only through the Platform.
                </li>
                <li className="mt-md-3 circle-list">
                  Refer other teachers only by their Platform profile, without
                  sharing other contact details
                </li>
                <li className="mt-md-3 circle-list">
                  Not use or disclose any student personal information received
                  through the Platform.
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                The Teacher shall maintain services at fair prices as determined
                by Urja Talents without associating with any competitors.
              </li>
              <li className="mt-md-3 number-list">
                While associated with Urja Talents, the Teacher shall not work
                with or assist any competing platforms in any manner.
              </li>
            </ul>
            <h4 className="mt-4">Usage Terms</h4>
            <ul>
              <li className="mt-md-3 number-list">Profile Details</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall ensure that all details provided in their
                  profile are true and accurate. The profile cannot be changed
                  without prior approval from Urja Talents. Urja Talents shall
                  have the right in perpetuity to use, reproduce and publish the
                  Teacher's profile details for marketing and promotional
                  purposes.{" "}
                </li>
              </ul>
              <li className="mt-md-3 number-list">Conduct</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall maintain proper decorum and conduct when
                  using the platform. The Teacher shall not post any unlawful,
                  harmful, threatening, abusive, harassing, defamatory or
                  objectionable content. The Teacher shall not promote any
                  competitors of Urja Talents or disclose any internal policies
                  of Urja Talents
                </li>
                <li className="mt-1">
                  Teachers shall treat all users approaching through Urja
                  Talents with courtesy and respect. Any instance of abusive
                  language, threats, or other unprofessional behavior will
                  result in temporary deactivation (minimum 3 days) and a
                  penalty of Rs. 5,100 for reactivation. Failure to pay the
                  penalty within 3 days will result in an additional daily
                  penalty of Rs. 100 until payment is received.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Personal Information </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not share any personal details of themselves
                  or customers with any third party. The Teacher shall not
                  charge customers for any free services provided through the
                  platform. The Teacher shall not collect any payments from
                  customers outside the platform.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Time Commitment</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall spend a minimum of 4 hours daily being
                  online and available on the platform. In case the Teacher
                  fails to meet the minimum hours requirement, Urja Talents may
                  reduce priority and allocation of customer leads to the
                  Teacher.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Platform Misuse </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not use the platform for any services or
                  products not listed or approved on the platform. The Teacher
                  shall not force any services or products upon customers
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                Direct Contact Prohibition
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not obtain customer contact details from the
                  platform. The Teacher can only refer other Teachers or
                  services via their profiles on the platform.{" "}
                </li>
              </ul>
              <li className="mt-md-3 number-list">Credential Misuse</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not share or misuse their login credentials.
                  The Teacher shall not impersonate any other users of the
                  platform. The Teacher shall disclose all devices used to
                  access the platform.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Pricing Commitment</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall adhere to agreed pricing for services. The
                  Teacher shall not associate or provide services to any
                  competitors of Urja Talents.{" "}
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                Non-solicitation, Non-compete
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher shall not work with or induce others to work with
                  competitors of Urja Talents in any manner during association
                  and post-termination with Urja Talents.
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                Feedback and Communication Decorum
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Teachers agree to maintain a professional and respectful tone
                  when responding to feedback from students, parents, or Urja
                  Talents staff. Failure to do so may result in disciplinary
                  action, including temporary or permanent deactivation.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Minimum Online Presence</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Teacher agrees to maintain a minimum online presence of four
                  (4) hours per week on the Urja Talents website. This minimum
                  shall be calculated based on the cumulative time spent online
                  by the Teacher during each week.
                </li>
                <li className="mt-md-1">
                  Maintaining this minimum online presence will enhance the
                  Teacher's credibility and visibility among users, potentially
                  leading to increased student leads and opportunities
                </li>
              </ul>
              <li className="mt-md-3 number-list">
                Non-Compliance Consequences
              </li>
              <ul>
                <li className="mt-md-3 circle-list">
                  In the event the Teacher fails to meet the minimum weekly
                  online requirement, their profile priority for receiving
                  student leads may be diminished. This means they may be less
                  likely to receive assigned students as compared to Teachers
                  who demonstrate higher online engagement.
                </li>
              </ul>
            </ul>
            <h4 className="mt-4">Intellectual Property</h4>
            <ul>
              <li className="mt-md-3 number-list">Ownership of Content</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Urja Talents shall solely and exclusively own all rights,
                  title and interests in the Teacher Profile and any Content
                  uploaded by the Teacher on the Platform. The Teacher hereby
                  assigns in perpetuity all its rights, title and interests in
                  the Teacher Profile and Content, if any, to Urja Talents.{" "}
                </li>
              </ul>
              <li className="mt-md-3 number-list">Licence to Urja Talents</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  The Teacher grants to Urja Talents a non-exclusive,
                  royalty-free, worldwide, perpetual licence to use, reproduce,
                  distribute, modify, adapt, publish, translate, create
                  derivative works from, and publicly display the Teacher
                  Profile and Content.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Usage Rights</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  Urja Talents shall have the right to use the Teacher Profile
                  and Content for marketing, promotional and branding activities
                  on any medium. Urja Talents may also permit its contractors,
                  vendors and partners to use the Teacher Profile and Content.
                </li>
              </ul>
              <li className="mt-md-3 number-list">Survival of Rights</li>
              <ul>
                <li className="mt-md-3 circle-list">
                  All rights of Urja Talents over the Teacher Profile and
                  Content shall survive the termination of this Agreement and
                  Urja Talents can continue to use such Teacher Profile and
                  Content post termination of this Agreement
                </li>
              </ul>
            </ul>
            <h4 className="mt-4">Confidentiality</h4>
            <li className="mt-md-3 circle-list">
              Confidential Information shall mean all information whether in
              oral, written, electronic or other form relating to the disclosing
              party's business, users, pricing, deals, know-how, designs,
              products, processes, accounts, customers and suppliers, disclosed
              to or otherwise acquired by the receiving party pursuant to or
              under this Agreement.
            </li>
            <li className="mt-md-3 circle-list">
              Teacher shall use Confidential Information solely for the purposes
              of performing its obligations under this Agreement and shall not
              disclose, disseminate, publish or release any Confidential
              Information to any third party without prior written consent from
              Urja Talents.{" "}
            </li>
            <li className="mt-md-3 circle-list">
              Teacher shall protect Confidential Information from unauthorized
              use or disclosure using the same degree of care as it takes to
              preserve and safeguard its own confidential and proprietary
              information of a similar nature, but in no event less than
              reasonable care
            </li>
            <li className="mt-md-3 circle-list">
              The obligations of confidentiality shall not apply to information
              which
            </li>
            <ul>
              <li className="mt-md-3 alfabet-list">
                is or becomes generally available to the public other than as a
                result of disclosure by the receiving party or its
                representatives,{" "}
              </li>
              <li className="mt-md-3 alfabet-list">
                is independently developed by the receiving party without use or
                reference to the Confidential Information,{" "}
              </li>
              <li className="mt-md-3 alfabet-list">
                is obtained from a third party which represented to the
                receiving party that it has the right to disclose it, or
              </li>
              <li className="mt-md-3 alfabet-list">
                is required to be disclosed by law or pursuant to an order of a
                court or regulatory authority.
              </li>
            </ul>
            <li className="mt-md-3 circle-list">
              The obligations under this clause shall survive the termination of
              this Agreement.{" "}
            </li>
            <li className="mt-md-3 circle-list">
              The parties agree that disclosure or use of Confidential
              Information in violation of this Agreement may cause irreparable
              harm, and the disclosing party shall have the right to seek
              equitable relief, including injunctive relief, as may be necessary
              and appropriate to prevent any threatened or actual breach of any
              provision of this clause without the necessity of posting bond or
              other security.
            </li>
            <h4 className="mt-4">Non-Competition and Non-Solicitation</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                During the term of this Agreement, Teachers are strictly
                prohibited from using the same profile uploaded on Urja Talents
                on any other platform or website. This prohibition applies to
                any platform or website on the internet, regardless of its
                purpose or function.
              </li>
              <li className="mt-md-3 circle-list">
                Violation of this non-competition clause will result in the
                immediate and permanent deactivation of your profile on Urja
                Talents without prior warning. Additionally, you will be subject
                to a penalty of INR 1,000, which will be imposed by the
                Compliance team at Urja Talents.
              </li>
              <li className="mt-md-3 circle-list">
                By creating a profile on Urja Talents, you automatically grant
                Urja Talents an exclusive, royalty-free, perpetual, irrevocable,
                worldwide license to your profile and its content. This license
                grants Urja Talents the following rights
              </li>
              <ul>
                <li className="mt-md-3 alfabet-list">
                  Use, reproduce, modify, adapt, publish, translate, create
                  derivative works from, distribute, perform, transmit, and
                  display any parts of your profile, including but not limited
                  to your profile picture, rating, reviews, and other
                  information.
                </li>
                <li className="mt-md-3 alfabet-list">
                  Incorporate your profile content into other works, in any
                  form, media, or technology now known or later developed.
                </li>
                <li className="mt-md-3 alfabet-list">
                  Display your profile online and offline, and use it in any
                  marketing, promotional, or branding activity on any medium,
                  including the internet, print, television, and other
                  platforms.
                </li>
                <li className="mt-md-3 alfabet-list">
                  Display portions of your profile content online or offline,
                  and permit others (contractors, vendors, partners, etc.) to do
                  the same.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">
                You acknowledge and agree that you own all copyrights and other
                intellectual property rights in your profile content. However,
                by granting this license, you agree to allow Urja Talents to
                exercise the rights provided above without any further
                compensation or approval from you.
              </li>
              <li className="mt-md-3 circle-list">
                This license will remain in effect for the entire term of this
                Agreement and will survive any termination of this Agreement.
              </li>
              <li className="mt-md-3 circle-list">
                The Teacher agrees not to provide similar services on any
                competing online platform during their engagement with Urja
                Talents.
              </li>
              <li className="mt-md-3 circle-list">
                The Teacher is prohibited from using a disguised or alias name
                to deliver similar services on competing platforms.
              </li>
              <li className="mt-md-3 circle-list">
                The Teacher shall not use their Urja Talents Display Name or
                profile picture on their personal social media handles,
                websites, or anywhere else on the internet.
              </li>
              <li className="mt-md-3 circle-list">
                Violation of this clause shall result in immediate profile
                deactivation without warning and a fine of INR 21,000.
              </li>
              <li className="mt-md-3 circle-list">
                The Teacher agrees not to establish a similar platform for a
                period of five (5) years following the cessation of their
                relationship with Urja Talents.
              </li>
              <li className="mt-md-3 circle-list">
                The Teacher is prohibited from using the Urja Talents brand to
                solicit customers acquired through the platform.
              </li>
              <li className="mt-md-3 circle-list">
                After leaving Urja Talents, the Teacher shall not work for a
                similar organization for a period of one (1) year.
              </li>
              <li className="mt-md-3 circle-list">
                Violation of this clause shall result in strict legal action and
                a fine of INR 21,000.
              </li>
              <li className="mt-md-3 circle-list">
                Upon verification of the Teacher's details, Urja Talents shall
                create and activate the Teacher's profile.
              </li>
              <li className="mt-md-3 circle-list">
                Following termination of this Agreement, Urja Talents reserves
                the right to display the Teacher's profile for a continuous
                period of three (3) years, provided the profile has not been
                deactivated or removed due to violations of the Terms and
                Conditions.
              </li>
            </ul>
            <h4 className="mt-4">Limitation of Liability</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                Teacher expressly understands and agrees that Urja Talents, its
                affiliates, directors, officers, employees, agents or
                representatives shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages,
                including but not limited to damages for loss of profits,
                goodwill, use, data or other intangible losses (even if Urja
                Talents has been advised of the possibility of such damages),
                resulting from or arising out of use of the platform; the
                conduct or actions of other teachers or users of the platform;
                or any other matter relating to use of the platform.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                2 The use of the platform is at the sole risk of the Teacher. To
                the maximum extent permitted by law, Urja Talents disclaims all
                warranties, express or implied, in connection with the platform
                and your use thereof. The platform and the content are provided
                on an "as is" and "as available" basis.
              </li>
              <li className="mt-md-3 circle-list">
                Teacher shall indemnify and hold harmless Urja Talents, its
                affiliates, directors, officers, employees, agents or
                representatives from and against any and all claims, disputes,
                demands, liabilities, damages, losses, suits, actions, decrees,
                judgments, penalties, charges, costs and expenses that Urja
                Talents may incur or be subjected to arising out of or relating
                to (i) any breach or non-compliance by Teacher of its
                obligations, representations or warranties under these Terms;
                (ii) any act or omission of Teacher in relation to use of the
                platform; or (iii) any violation by Teacher of any law, rules or
                regulations or the rights (including but not limited to
                intellectual property rights) of any third party.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                If any provision of this Section 7 is held to be invalid or
                unenforceable, then such provision shall be construed, as nearly
                as possible, to reflect the intentions of the parties and the
                remainder of this Section shall remain in effect.
              </li>
              <li className="mt-md-3 circle-list">
                To the fullest extent permitted by applicable law, Urja Talents'
                total liability under this Agreement, whether arising from
                breach of contract, negligence, strict liability, or otherwise,
                shall be limited to the greater of: (a) $10 (USD); or (b) INR
                500.
              </li>
              <li className="mt-md-3 circle-list">
                Notwithstanding the foregoing, if applicable law does not allow
                the limitation of liability as set forth above, the limitation
                of liability shall be deemed modified solely to the extent
                necessary to comply with applicable law.
              </li>
              <li className="mt-md-3 circle-list">
                This Section 7 (Limitation of Liability) shall survive any
                termination or expiration of this Agreement. The limitation of
                liability applies to the fullest extent permitted by law.
              </li>
              <li className="mt-md-3 circle-list">
                . If the law prohibits the initial limitation, it will be
                automatically modified to comply with the minimum allowed by
                law. This section remains in effect even after the Agreement is
                terminated or expires.
              </li>
            </ul>
            <h4 className="mt-4">Indemnification</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                Teacher shall indemnify and hold harmless Urja Talents, its
                affiliates, directors, employees and other officers from and
                against any and all claims, suits, actions, demands, damages,
                liabilities, expenses, costs (including legal costs on a full
                indemnity basis), or losses of any kind whatsoever, resulting
                from
              </li>
              <ul>
                <li className="mt-md-3 alfabet-list">
                  any breach of this Agreement by the Teacher;{" "}
                </li>
                <li className="mt-md-3 alfabet-list">
                  the use of the Platform by the Teacher;{" "}
                </li>
                <li className="mt-md-3 alfabet-list">
                  any unauthorized use or violation of the profile created for
                  the Teacher on the Platform;
                </li>
                <li className="mt-md-3 alfabet-list">
                  the content provided by the Teacher;{" "}
                </li>
                <li className="mt-md-3 alfabet-list">
                  the services provided by the Teacher to any user of the
                  Platform.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">
                The indemnified party shall notify the Teacher in writing of any
                claim for indemnification and shall provide all documents
                related to such claim.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                If the indemnified party fails to notify the Teacher, the
                indemnification obligations shall reduce to the extent such
                failure has prejudiced the Teacher.
              </li>
            </ul>
            <h4 className="mt-4">Term and Termination</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                You are bound to provide your services through the Platform
                until the end of one academic year from the date of onboarding.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                In the event of an unforeseen situation arising where you
                require to discontinue your services prior to the period in
                Clause 9.1, you shall provide a notice period of two (2) months
                to Urja Talents.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                Failure to provide the requisite notice as per Clause 9.2 shall
                render you liable to pay fees equivalent to the consideration
                you would have received through customer leads during the notice
                period of two (2) months.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                Alternatively, you may find a replacement for yourself to
                provide services on the Platform during the notice period, with
                the assistance of Urja Talents.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                Urja Talents reserves the right to terminate this Agreement at
                any time without notice or liability in the event of your breach
                of any terms hereof.
              </li>
              <li className="mt-md-3 circle-list">
                Even after termination or discontinuation, Urja Talents shall
                have the right to display your Profile information on the
                Platform for a period of three (3) years.
              </li>
              <li className="mt-md-3 circle-list">
                Urja Talents reserves the right to monitor all communication and
                activity on the platform and take appropriate disciplinary
                action against any teacher found to be in violation of these
                terms. This may include temporary or permanent deactivation,
                financial penalties, or legal action.
              </li>
            </ul>
            <h4 className="mt-4">Usage of Features</h4>
            <ul>
              <li className="mt-md-3 circle-list">Next Online Time:</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Requirement: Upon logging out, Teachers must specify their
                  expected return time ("Next Online Time") using the platform's
                  feature. This promotes user loyalty and personal growth.
                </li>
                <li className="mt-md-3 number-list">
                  Non-Compliance: Failure to comply will negatively impact your
                  profile by increasing your penalty count (+1), potentially
                  placing it lower on the website search results
                </li>
              </ul>
              <li className="mt-md-3 circle-list">Reviews</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Customer Feedback: Customers can submit positive or negative
                  reviews based on your service.
                </li>
                <li className="mt-md-3 number-list">
                  Modification Restrictions: You cannot remove/delete reviews.
                  To request revision, submit a "My Review" tab request for
                  technical team review and action.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">Pricing</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Initial Pricing: Standard pricing is determined during
                  onboarding based on performance and qualifications. After 6
                  months with excellent performance, Teachers can request price
                  adjustments via their dashboard, subject to Urja Talents
                  review and potential re-evaluation (including re-interview) to
                  assess skill upgrades or language additions. Profile
                  deactivation is possible without notice if re-evaluation
                  fails.
                </li>
                <li className="mt-md-3 number-list">
                  Price Increase: Price increases are only valid after
                  completing 350 hours with a 4+ star customer rating, allowing
                  a 10% raise upon eligibility.
                </li>
                <li className="mt-md-3 number-list">
                  Exclusive Work Agreement: For teachers working exclusively
                  with Urja Talents, 35% of customer payments will be retained
                  by Urja Talents, with the remaining 65% constituting the
                  full-time teacher's share.
                </li>
                <li className="mt-md-3 number-list">
                  Promotion Impact: Standard pricing applies only to full-price
                  orders. Discounts may apply during company offers or
                  teacher-activated promotions (buyback offers, promotional
                  schemes, memberships, etc.). Teachers must adhere to standard
                  customer promotions and Urja Talents reserves the right to
                  withhold payment for demo sessions, introductory discounts,
                  loyalty/referral discounts, fixed sessions, or any
                  customer-activated offers chosen by the teacher.
                </li>
                <li className="mt-md-3 number-list">
                  Payment Schedule: Teachers will be paid according to Urja
                  Talents policy upon request after completing their class
                  cycle, on the 15th or 30th of the following month
                </li>
                <li className="mt-md-3 number-list">
                  Billing Errors: Urja Talents is not responsible for billing
                  system errors, malfunctions, or payment processing issues.
                  Report any suspected errors within 7 days. In cases of
                  customer refund (partial or full) or charged-back payments,
                  the teacher will not be paid for the transaction and may be
                  required to repay Urja Talents if funds were already
                  disbursed. Disputed services may cause payment withholding
                  until resolution, with Urja Talents having the final say.
                </li>
                <li className="mt-md-3 number-list">
                  Suspicious Transactions: Urja Talents reserves the right to
                  withhold payments partially or fully for up to 60 days if
                  suspicious or extraordinary transactions/services are detected
                  by company systems or audits, as communicated to the teacher,
                  until investigation and resolution.
                </li>
                <li className="mt-md-3 number-list">
                  Tax Deductions: Teacher payments are subject to withholding
                  taxes payable to the government.
                </li>
                <li className="mt-md-3 number-list">
                  GST Submission: If Urja Talents payments to a teacher exceed
                  20 Lacs in a financial year, the teacher must submit their GST
                  number. GST will be paid by the company in such cases.
                </li>
                <li className="mt-md-3 number-list">
                  GST Default: If the company pays GST on the teacher's behalf
                  and the teacher fails to submit it to the government, the
                  company may withhold all future payments and
                  deactivate/terminate the teacher's profile until the default
                  is rectified.
                </li>
                <li className="mt-md-3 number-list">
                  Promotional Offer Adjustment: For consultations with customer
                  discounts, the teacher's payment will be proportionally
                  reduced (below 50% of standard rate) with notification of the
                  deducted amount due to the promotional offer.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">Profile Update</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Verified profiles cannot be changed without prior approval and
                  re-verification by the Compliance team. Profile content change
                  requests must be submitted via platform tickets.
                </li>
                <li className="mt-md-3 number-list">
                  Major changes (profile picture, bio) are not allowed after
                  onboarding. This information can only be updated during
                  onboarding.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">Feature Updates</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Urja Talents reserves the right to update any teacher
                  features. Updates, additions, or deletions will be announced
                  on your notice board/dashboard. Continued platform use
                  signifies your acceptance of such updates.
                </li>
              </ul>
              <li className="mt-md-3 circle-list">Training</li>
              <ul>
                <li className="mt-md-3 number-list">
                  Mandatory Training: Teachers must complete all provided
                  training materials and sessions, including customer review
                  chats, within 7 days (14 days from receiving welcome mail).
                  Failure to comply will result in permanent profile
                  deactivation.
                </li>
                <li className="mt-md-3 number-list">
                  Manipulation Penalty: Playing around with performance
                  parameters (using solicited users for improved reviews) incurs
                  a Rs. 21,000 penalty and temporary profile deactivation for up
                  to{" "}
                </li>
              </ul>
              <li className="mt-md-3 circle-list">
                working days during the audit period.
              </li>
            </ul>
            <h4 className="mt-4">Dispute Resolution</h4>
            <ul>
              <li className="mt-md-3 circle-list">
                Any dispute, controversy or claim arising out of or relating to
                this Agreement or the breach, termination or invalidity thereof,
                shall be settled by arbitration in accordance with the
                Arbitration and Conciliation Act, 1996.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                The arbitral tribunal shall consist of a sole arbitrator
                appointed in accordance with the rules of the Indian Council of
                Arbitration.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                The seat of arbitration shall be Mumbai.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                The arbitration proceedings shall be conducted in the English
                language.
              </li>
              <li className="mt-md-3 circle-list">
                The arbitral award shall be final and binding on the Parties.
                The Parties expressly agree to waive the right to any form of
                appeal, review or recourse to any court or other judicial
                authority, insofar as such waiver may be validly made.
              </li>
              <li className="mt-md-3 circle-list">
                The Parties shall attempt in good faith to resolve any dispute
                or claim arising out of or relating to this Agreement through
                negotiations between authorized representatives of each Party.
                If the dispute cannot be settled amicably within thirty (30)
                days from the date on which a Party has served written notice on
                the other of the dispute, then the dispute shall be referred to
                and finally resolved by arbitration.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                The arbitration shall be conducted in accordance with the Rules
                of Arbitration of the International Chamber of Commerce. The
                place of arbitration shall be Mumbai.{" "}
              </li>
              <li className="mt-md-3 circle-list">
                The courts in Mumbai shall have exclusive jurisdiction with
                respect to any matters arising out of or relating to this
                Agreement.
              </li>
            </ul>
            <h4 className="mt-4">Governing Law and Jurisdiction</h4>
            <li className="mt-md-3 number-list">1 Governing Law</li>
            <ul>
              <li className="mt-md-3 circle-list">
                These Terms shall be governed by and construed in accordance
                with the laws of India.
              </li>
            </ul>
            <li className="mt-md-3 number-list">Jurisdiction </li>
            <ul>
              <li className="mt-md-3 circle-list">
                The parties agree to submit to the exclusive jurisdiction of the
                courts located in Mumbai, Maharashtra, India to resolve any
                dispute arising out of these Terms, without reference to its
                conflicts of law principles.{" "}
              </li>
            </ul>
            <li className="mt-md-3 number-list">Severability</li>
          </Col>
        </Row>
      </PolicyContainer>
      <Footer />
    </>
  );
};

const PolicyContainer = styled.div`
  overflow-x: hidden;
  h4 {
    font-weight: 600;
  }
  .hero {
    padding-top: 10vmax;
    height: fit-content;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }
`;

export default TermAndConditions;
