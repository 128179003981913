import ManagePage from "./ManagePage";

export const ManagePageConfig = {
  routes: [
    {
      path: "/operation/manage",
      exact: true,
      component: ManagePage
    }
  ]
};
