import React from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { CiSearch } from "react-icons/ci";
import { Row, Col } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import CardCourse from "./CardCourse";
import card1 from "../../assets/parent/card1.png";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter";

const CourseManagement = () => {
  const navigate = useNavigate();
  return (
    <TeacherLayout>
      <h1>Course Management </h1>
      <StyledContainer>
        <Row className="mt-4">
          <Col md={3}>
            <div className="input_container">
              <input type="text" placeholder="Search Courses" />
              <CiSearch className="fs-6" />
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <h3>Active courses</h3>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button
              onClick={() =>
                navigate(
                  "/teacher/dashboard/pages/course-management/add-course"
                )
              }
              className="add_btn_course py-2 text_yellow fw-bold px-4 rounded-5"
            >
              <FaPlus className=" fs-5 me-2" />
              New Course
            </button>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              view={true}
            />
          </Col>
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              view={true}
            />
          </Col>
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              view={true}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <h3>Active Subjects</h3>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <button
              onClick={() =>
                navigate(
                  "/teacher/dashboard/pages/course-management/add-subject"
                )
              }
              className="add_btn_course py-2 text_yellow fw-bold px-4 rounded-5"
            >
              <FaPlus className=" fs-5 me-2" />
              New Subjects
            </button>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              deleteIcon={true}
            />
          </Col>
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              deleteIcon={true}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <h3>Applied courses</h3>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              deleteIcon={true}
              bottomRow={true}
              success={"Successful"}
            />
          </Col>
          <Col md={4}>
            <CardCourse
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              startCount={4.3}
              date={"16 - 18 Jul 2023 "}
              students={"26 students enrolled"}
              editIcon={true}
              deleteIcon={true}
              bottomRow={true}
              reject={"Rejected"}
              feedback={"View feedback"}
            />
          </Col>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .input_container {
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 10px;
    height: 40px;
    input {
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }

  .add_btn_course {
    border: 1.5px solid var(--color-primary);
    background: transparent;
  }
`;

export default CourseManagement;
