// HomeWork
import HomeWorkPage from "./HomeWorkPage";
import HomeWorkWithID from "./HomeWorkWithID";

export const HomeWorkPageConfig = {
  routes: [
    {
      path: "/operation/HomeWork",
      exact: true,
      component: HomeWorkPage
    },
    {
      path: "/operation/HomeWork/student/:id?",
      exact: true,
      component: HomeWorkWithID
    },
  ]
};
