import companyAccount from "./CompanyAccount";
import teacherAccount from "./TeacherAccount";
import parentAccount from "./ParentAccount";
import employeeAccount from "./EmployeeAccount";

export const AccountPageConfig = {
  routes: [
    {
      path: "/account/company-account",
      exact: true,
      component: companyAccount,
    },
    {
      path: "/account/teacher-account",
      exact: true,
      component: teacherAccount,
    },
    {
      path: "/account/parent-account",
      exact: true,
      component: parentAccount,
    },
    {
      path: "/account/employee-account",
      exact: true,
      component: employeeAccount,
    },
  ],
};
