import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Button } from "@mui/material";
import { TbFileCertificate } from "react-icons/tb";

const CertificatePage = (props) => {
  const { history } = props;

  return (
    <OperationLayout>
      <h5>Certificate</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={4} className="mt-3">
          <Button
            className="rounded-3 shadow"
            variant="outlined"
            startIcon={<TbFileCertificate />}
          >
            Genrate Certificate
          </Button>
        </Col>
        <Col md={4} className="mt-3">
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-3"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        className="mt-5 text-center"
        responsive
        striped
        style={{ borderRadius: "25px", backgroundColor: "#f7ff62" }}
      >
        <thead>
          <tr>
            <th>Student Id</th>
            <th>Name</th>
            <th>Class</th>
            <th>Board</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>Joh Smith</td>
            <td>10th</td>
            <td>CBSE</td>
            <td>active</td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>Joh Smith</td>
            <td>10th</td>
            <td>CBSE</td>
            <td>active</td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>Joh Smith</td>
            <td>10th</td>
            <td>CBSE</td>
            <td>active</td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>Joh Smith</td>
            <td>10th</td>
            <td>CBSE</td>
            <td>active</td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>student#-0001</td>
            <td>Joh Smith</td>
            <td>10th</td>
            <td>CBSE</td>
            <td>active</td>
            <td>12 January 2012 10:30 AM</td>
          </tr>
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default CertificatePage;
