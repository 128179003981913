import React from "react";
import { Col } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";

const Dropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: onDrop,
  });

  return (
    <Col
      md={6}
      className="rounded-2 d-flex align-items-center justify-content-center"
      style={{
        minHeight: "20vh",
        border: "2px dashed #ccc",
        flexDirection: "column",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <span className="drop-icon">
        <FaCloudUploadAlt />
      </span>
      <p>Drop child profile image files here</p>
    </Col>
  );
};

export default Dropzone;
