import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";

const PersonDetailsModal = ({ show, handleClose, person }) => {
  return (
    <Modal
      style={{ zIndex: "142512001", marginTop: "10vh" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Person Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {person ? (
          <>
            <p>
              <strong>Name:</strong> {person.name}
            </p>
            <p>
              <strong>Contact:</strong> {person.contact}
            </p>
            <p>
              <strong>Email:</strong> {person.email}
            </p>
            <p>
              <strong>Date of Joining:</strong> {person.dateOfregistration}
            </p>
            <p>
              <strong>Subject:</strong> {person.subject}
            </p>
            <p>
              <strong>Grade:</strong> {person.grade}
            </p>
            <p>
              <strong>Board:</strong> {person.board}
            </p>
            <p>
              <strong>Certificates:</strong>
            </p>
            <ul>
              {person.certificates.map((certificate, index) => (
                <li key={index}>{certificate}</li>
              ))}
            </ul>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonDetailsModal;
