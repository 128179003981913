import React, { useState } from 'react'
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Button, Col, Container, Form, Modal, ProgressBar, Row, Table } from 'react-bootstrap';
import { Chip } from '@material-ui/core';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FaCaretDown, FaRegStar, FaStar } from 'react-icons/fa';
import { FaChalkboardUser } from 'react-icons/fa6';
import { GiCycle } from 'react-icons/gi';
import { FcRating } from 'react-icons/fc';
import { TiArrowSortedUp } from 'react-icons/ti';
function StudentProfile() {
    const student = {
        id: 1,
        name: 'John Doe',
        dates: {
            '2024-06-01': 'Present',
            '2024-06-02': 'Absent',
            '2024-06-03': 'Present'
        }
    };

    const [isLecturesOpen, setIsLecturesOpen] = useState(false);

    return (
        <TeacherLayout>
            <Container className="shadow border rounded-2 p-3">
                <Col className="d-flex align-items-center justify-content-between">
                    <Col md={6}>
                        <Chip className="bg-dark text-white" label="Student Details" />
                    </Col>
                    <Col md={6} className="d-flex align-items-center justify-content-end">

                    </Col>
                </Col>

                <Row className="mt-3 p-2">
                    <Col
                        md={3}
                        className="d-flex align-items-center justify-content-center mt-3"
                    >
                        {" "}
                        <div className="profile-parent-page-image-area">
                            <img
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                                alt=""
                            />
                        </div>{" "}
                    </Col>
                    <Col md={5} className="mt-3">
                        <Col md={6} className="mt-1">
                            <b>Student ID </b> : 9821hjwhuy8y
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Visible Name </b> : Hello Name
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Full Name </b> : Kishan Jhadav
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Email </b> : parent@gmail.com
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Phone </b> : +91-123456789
                        </Col>
                        <Col md={6} className="mt-1">
                            <b>Whatsapp no.</b> : +91-123456789
                        </Col>
                    </Col>
                    <Col md={4} className="mt-3">
                        <Col className="mt-1">
                            <b>Nationality </b> : Indian
                        </Col>
                        <Col className="mt-1">
                            <b>Address </b> : Lorem ipsum dolor sit amet.
                        </Col>
                        <Col className="mt-1">
                            <b>City </b> : Bhopal
                        </Col>
                        <Col className="mt-1">
                            <b>Pin Code</b> : 12345
                        </Col>
                        <Col className="mt-1">
                            <b>Country </b> : India
                        </Col>
                        <Col className="mt-1">
                            <b>Residency </b> : India
                        </Col>
                        <Col className="mt-1">
                            <b>Education Approach </b> : IIT Preparation
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Container className='px-3 py-3 mt-5 shadow rounded-3 border'>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                    <span>
                        <FcRating size={22} /> &nbsp; <b>Your Rating</b>
                    </span>{" "}
                    <div className=''>
                        <FaStar color='#FFC224' /> &nbsp;
                        <FaStar color='#FFC224' /> &nbsp;
                        <FaStar color='#FFC224' /> &nbsp;
                        <FaStar color='#FFC224' /> &nbsp;
                        <FaRegStar />
                    </div>
                </span>
                <span className="d-flex align-items-center justify-content-between py-3 border-bottom">
                    <span>
                        <GiCycle size={22} /> &nbsp; <b>Cycle</b>
                    </span>{" "}
                    <b>2</b>
                </span>
                <span className="">
                    <div onClick={() => setIsLecturesOpen(!isLecturesOpen)} className="cursor-pointer d-flex align-items-center justify-content-between py-3 border-bottom">
                        <span>
                            <FaChalkboardUser size={22} /> &nbsp; <b>Lectures</b>
                        </span>{" "}
                        <b>12/32 &nbsp; {isLecturesOpen ? <TiArrowSortedUp size={22} /> : <FaCaretDown size={22} />} </b>
                    </div>
                    {
                        isLecturesOpen && (
                            <>
                                <div className="p-0 m-o border mt-3">
                                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                        <div className="d-flex align-content-center justify-content-center gap-3">
                                            <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 11</h5>
                                        </div>
                                        <h6>12 Jan 2023</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                        <div className="d-flex align-content-center justify-content-center gap-3">
                                            <h5> <MdKeyboardArrowUp size={32} /> Class Topic Name - 10</h5>
                                        </div>
                                        <h6>12 Jan 2023</h6>
                                    </div>
                                    <div className="px-4 d-flex align-items-start justify-content-between flex-column py-3 border-bottom">
                                        <span>
                                            <b>Teacher Feedback</b>
                                        </span>{" "}
                                        <br />
                                        <div>
                                            <FaStar color='#FFC224' /> &nbsp;
                                            <FaStar color='#FFC224' /> &nbsp;
                                            <FaStar color='#FFC224' /> &nbsp;
                                            <FaStar color='#FFC224' /> &nbsp;
                                            <FaRegStar />
                                            &nbsp;
                                            <span className="ml-2">4 stars</span>
                                        </div>
                                        <p className='mt-2'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor ad porro eos reiciendis similique quas accusantium, rerum ducimus veritatis neque? Necessitatibus consequuntur excepturi, exercitationem temporibus ex voluptatem obcaecati ducimus amet quod facere non accusamus quaerat fuga a porro maiores laudantium.
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                        <div className="d-flex align-content-center justify-content-center gap-3">
                                            <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 10</h5>
                                        </div>
                                        <h6>10 Jan 2023</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                        <div className="d-flex align-content-center justify-content-center gap-3">
                                            <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 9</h5>
                                        </div>
                                        <h6>09 Jan 2023</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between py-2 border-bottom px-3">
                                        <div className="d-flex align-content-center justify-content-center gap-3">
                                            <h5> <MdKeyboardArrowDown size={32} /> Class Topic Name - 8</h5>
                                        </div>
                                        <h6>08 Jan 2023</h6>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </span>
                <Col className="mt-3 border-bottom pb-3">
                    <span className="mt-3 mb-2">
                        <b>Course Completed</b>
                    </span>
                    <ProgressBar className="mt-2" now={90} label={`${90}%`} />
                </Col>
            </Container>
            <Container className='mt-5'>
                <h5>{student.name}'s Attendance</h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(student.dates).map((date) => (
                            <tr key={date}>
                                <td>{date}</td>
                                <td>{student.dates[date]}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

        </TeacherLayout>
    )
}

export default StudentProfile