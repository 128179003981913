// Refund
import RefundPage from "./RefundPage";

export const RefundPageConfig = {
  routes: [
    {
      path: "/account/refund",
      exact: true,
      component: RefundPage
    }
  ]
};
