import TeacherFAQ from "./TeacherFAQ";

export const TeacherFAQPageConfig = {
  routes: [
    {
      path: "/teacher/TeacherFAQ",
      exact: true,
      component: TeacherFAQ
    }
  ]
};
