// AdditionalLinks
import AdditionalLinksPage from "./AdditionalLinksPage";

export const AdditionalLinksPageConfig = {
  routes: [
    {
      path: "/account/additional-link",
      exact: true,
      component: AdditionalLinksPage
    }
  ]
};
