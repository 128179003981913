import React, { useEffect } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles((them) => ({
  paddingPaper: {
    padding: "10px 5px 5px 10px",
  },
  mt: {
    marginTop: 13,
  },
  titlePaper: {
    marginBottom: "16px",
  },
  visitorChart: {
    // height: "150px"
  },
}));

const DashboardPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    if (!token) {
      navigate("/parent/sign-in");
    }
  }, [token, navigate]);

  return <OftadehLayout></OftadehLayout>;
};

export default DashboardPage;
