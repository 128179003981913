import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const TeacherGraph = ({ data, name, height }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const option = {
            dataset: {
                source: data,
            },
            grid: { containLabel: true },
            xAxis: { name },
            yAxis: { type: 'category' },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 10,
                max: 100,
                text: ['High Score', 'Low Score'],
                dimension: 0,
                inRange: {
                    color: ['#65B581', '#FFCE34', '#FD665F'],
                },
            },
            series: [
                {
                    type: 'bar',
                    encode: {
                        x: 'amount',
                        y: 'product',
                    },
                },
            ],
        };

        chartInstance.setOption(option);

        return () => {
            chartInstance.dispose();
        };
    }, [data]);

    return <div ref={chartRef} style={{ width: '100%', height }} />;
};

export default TeacherGraph;
