import Complain from "./Complain.jsx";

export const ComplainPageConfig = {
  routes: [
    {
      path: "/parent/complain",
      exact: true,
      component: Complain,
    },
  ],
};
