import ManualDemo from "./ManualDemoPage.jsx";
import ManualHelpPage from "./ManualHelpPage.jsx";

export const ManualDemoPageConfig = {
  routes: [
    {
      path: "/parent/manual-demo",
      exact: true,
      component: ManualDemo,
    },
    {
      path: "/parent/manual-help",
      exact: true,
      component: ManualHelpPage,
    },
  ],
};
