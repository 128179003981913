import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const BlogPage = (props) => {
  const { history } = props;
  const navigatse = useNavigate();

  useEffect(() => {
    var ctx = document.getElementById("studentFrequencyGraph").getContext("2d");

    var studentFrequencyGraph = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL"],
        datasets: [
          {
            label: "Data",
            borderColor: "#f4e880",
            pointBorderColor: "#FFD536",
            pointBackgroundColor: "#80b6f4",
            pointHoverBackgroundColor: "#80b6f4",
            pointHoverBorderColor: "#80b6f4",
            pointBorderWidth: 10,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 4,
            data: [100, 120, 150, 170, 180, 170, 160],
          },
        ],
      },
      options: {
        legend: {
          position: "bottom",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 20,
              },
              gridLines: {
                drawTicks: false,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 20,
                fontColor: "rgba(0,0,0,0.5)",
                fontStyle: "bold",
              },
            },
          ],
        },
      },
    });

    return () => studentFrequencyGraph.destroy();
  }, []);

  useEffect(() => {
    var ctxx = document.getElementById("studentCourceGraphh").getContext("2d");
    var ctx2 = document.getElementById("studentCourceGraph2").getContext("2d");
    var ctx3 = document.getElementById("studentCourceGraph3").getContext("2d");

    var studentCourseGraphh = new Chart(ctxx, {
      type: "bar",
      data: {
        labels: [
          "Blog I",
          "Blog II",
          "Blog III",
          "Blog IV",
          "Blog V",
          "Blog VI",
        ],
        datasets: [
          {
            label: "Number of Visitors",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#f4e880",
            borderColor: "#FFD32B",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph2 = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: [
          "Blog I",
          "Blog II",
          "Blog III",
          "Blog IV",
          "Blog V",
          "Blog VI",
        ],
        datasets: [
          {
            label: "Number of Visitors",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#f4cd80",
            borderColor: "#ffa02b",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    var studentCourseGraph3 = new Chart(ctx3, {
      type: "bar",
      data: {
        labels: [
          "Blog I",
          "Blog II",
          "Blog III",
          "Blog IV",
          "Blog V",
          "Blog VI",
        ],
        datasets: [
          {
            label: "Number of Likes",
            data: [50, 40, 90, 70, 30, 110],
            backgroundColor: "#c0f480",
            borderColor: "#79ff2b",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      studentCourseGraphh.destroy();
      studentCourseGraph2.destroy();
      studentCourseGraph3.destroy();
    };
  }, []);

  const handleAddNewBlog = () => {
    navigatse("/operation/Blogs/add");
  };

  const handleUpdateBlog = () => {
    navigatse("/operation/Blogs/update/jhkadjhk");
  };

  return (
    <OperationLayout>
      {/* <OperationLocation path={history} /> */}
      <h4>Top Blogs</h4>

      <Row>
        <Col className="d-flex align-items-center justify-content-end">
          <Button
            variant="outlined"
            style={{ background: "var(--color-primary)" }}
            className="me-3 text-white"
          >
            Weakly
          </Button>
          <Button variant="outlined" className="me-3">
            Monthly
          </Button>
          <Button variant="outlined" className="me-3">
            Yearly
          </Button>
          <Button
            onClick={handleAddNewBlog}
            variant="outlined"
            startIcon={<IoMdAdd />}
          >
            Add Blog
          </Button>
        </Col>
      </Row>

      <Row className="mt-3" style={{ overflowX: "hidden" }}>
        <Col md={3}>
          <div className="mt-3 border rounded-2 py-3 d-flex align-items-center justify-content-center bg-yellow text-white">
            <h6 className="fw-bold m-0">Blog name</h6>
          </div>
        </Col>
        <Col md={3}>
          <div className="mt-3 border rounded-2 py-3 d-flex align-items-center justify-content-center bg-body-tertiary">
            <h6 className="fw-bold m-0">Blog name</h6>
          </div>
        </Col>
        <Col md={3}>
          <div className="mt-3 border rounded-2 py-3 d-flex align-items-center justify-content-center bg-body-tertiary">
            <h6 className="fw-bold m-0">Blog name</h6>
          </div>
        </Col>
        <Col md={3}>
          <div className="mt-3 border rounded-2 py-3 d-flex align-items-center justify-content-center bg-body-tertiary">
            <h6 className="fw-bold m-0">Blog name</h6>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 shadow rounded-4 p-3">
        <Col md={4}>
          <div>
            <h5>Number of Views</h5>
            <h6 className="mt-2">87,987</h6>
            <h6 className="mt-2 text-success">+54.76%</h6>
          </div>
          <div className="mt-3">
            <h5>Number of Likes</h5>
            <h6 className="mt-2 text-success">+54.76%</h6>

            <p className="m-0 mt-3">Since last month</p>
          </div>
        </Col>
        <Col md={8}>
          {" "}
          <canvas id="studentFrequencyGraph"></canvas>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12} className="shadow rounded-4 p-3">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="m-0 fw-bold text_yellow">Top Rating Blogs</h6>
            <div className="d-flex align-items-center gap-3">
              <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                Day
              </button>
              <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                Week
              </button>
              <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                Month
              </button>
            </div>
          </div>
          <hr />
          <canvas id="studentCourceGraphh"></canvas>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={6}>
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">Total No. of Visitors</h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph2"></canvas>
          </div>
        </Col>
        <Col md={6}>
          <div className="shadow rounded-4 p-3">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="m-0 fw-bold text_yellow">Total No. of Likes</h6>
              <div className="d-flex align-items-center gap-3">
                <button className=" border fw-semibold py-1 px-2 rounded-2 text-black">
                  Day
                </button>
                <button className="border fw-semibold py-1 px-2 rounded-2 text-black">
                  Week
                </button>
                <button className="bg-yellow border-0 fw-semibold py-1 px-2 rounded-2 text-white">
                  Month
                </button>
              </div>
            </div>
            <hr />
            <canvas id="studentCourceGraph3"></canvas>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={12}>
          <h3>Blogs Activity</h3>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={1} className="d-flex justify-content-end align-items-start">
          <input type="checkbox" />
        </Col>
        <Col sm={11}>
          <div className="shadow rounded-4 p-3 " style={{ height: 300 }}></div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={1}></Col>
        <Col sm={11}>
          <h3>Title Of The Post Abc</h3>
          <p className="m-0 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Duis aute
            irure dolor. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum
          </p>
          <p className="m-0 mt-2 text_yellow text-decoration-underline">
            Read More
          </p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={1} className="d-flex justify-content-end align-items-start">
          <input type="checkbox" />
        </Col>
        <Col sm={11}>
          <div className="shadow rounded-4 p-3 " style={{ height: 300 }}></div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={1}></Col>
        <Col sm={11}>
          <h3>Title Of The Post Abc</h3>
          <p className="m-0 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Duis aute
            irure dolor. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum
          </p>
          <Row className="mt-3">
            <Col className="d-flex">
              <p className="m-0 mt-2 text_yellow text-decoration-underline">
                Read More
              </p>
              <Button
                onClick={handleUpdateBlog}
                className="rounded-5 ms-3"
                variant="outlined"
                size="small"
              >
                update
              </Button>
              <Button
                className="rounded-5 ms-3 bg-danger text-white border-danger"
                variant="outlined"
                size="small"
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col sm={1} className="d-flex justify-content-end align-items-start">
          <input type="checkbox" />
        </Col>
        <Col sm={11}>
          <div className="shadow rounded-4 p-3 " style={{ height: 300 }}></div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={1}></Col>
        <Col sm={11}>
          <h3>Title Of The Post Abc</h3>
          <p className="m-0 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. Duis aute
            irure dolor. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum
          </p>
          <Row className="mt-3">
            <Col className="d-flex">
              <p className="m-0 mt-2 text_yellow text-decoration-underline">
                Read More
              </p>
              <Button
                onClick={handleUpdateBlog}
                className="rounded-5 ms-3"
                variant="outlined"
                size="small"
              >
                update
              </Button>
              <Button
                className="rounded-5 ms-3 bg-danger text-white border-danger"
                variant="outlined"
                size="small"
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <p className="mt-4 fw-semibold text-decoration-underline text-end text_yellow">
        View More
      </p>

      <button className="py-2 mt-3 border-0 px-4 rounded-3 bg-yellow shadow text-white">
        Pin Blog
      </button>
    </OperationLayout>
  );
};

export default BlogPage;
