import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';

// Create the pathname context
export const PathnameContext = createContext();

// Create a PathnameProvider component
export const PathnameProvider = ({ children }) => {
    const location = useLocation();

    return (
        <PathnameContext.Provider value={location.pathname}>
            {children}
        </PathnameContext.Provider>
    );
};
