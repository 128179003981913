import GiftPage from "./GiftPage";

export const GiftPageConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/gift",
      exact: true,
      component: GiftPage,
    },
  ],
};
