import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { IoPricetagsOutline } from "react-icons/io5";

const TagsPage = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([
    { id: 1, name: "Tag 1" },
    { id: 2, name: "Tag 2" },
    { id: 3, name: "Tag 3" },
  ]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTagClick = () => {
    // setSelectedTag(tag);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddTag = () => {
    // Implement functionality to add tag to course or profile
    setShowModal(false);
  };

  return (
    <OperationLayout>
      <h5>Manage Tags</h5>
      {/* <OperationLocation path={history} /> */}
      <Row className="justify-content-between">
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select
              value={selectedOption}
              onChange={handleOptionChange}
              className="shadow rounded-3 mt-3"
            >
              <option disabled>Select Tag</option>
              <option value={"1"}>Course Related Tags</option>
              <option value={"2"}>Teacher Related Tags</option>
              <option value={"2"}>Pictures Related Tags</option>
              <option value={"3"}>Blogs Related Tags</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col
          md={4}
          className="mt-3 d-flex align-items-center justify-content-end"
        >
          <Button
            onClick={handleTagClick}
            className="border-primary text-primary"
            variant="outlined"
            startIcon={<IoPricetagsOutline />}
          >
            Add Tag
          </Button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <h6>Tag's</h6>
      </Row>
      <Container className="mt-3 mb-3">
        <Row>
          <Col>
            <Button
              className="bg-primary border-primary text-white"
              variant="outlined"
              startIcon={<IoPricetagsOutline />}
            >
              This is Tag 1
            </Button>
            <Button
              className="bg-primary border-primary text-white ms-3"
              variant="outlined"
              startIcon={<IoPricetagsOutline />}
            >
              This is Tag 2
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Modal for Adding Tags */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className="mt-3 mb-3">
            <Form.Group controlId="nameInput">
              <Form.Label>Tag Title</Form.Label>
              <Form.Control type="text" placeholder="Tag Title" />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select Category</Form.Label>
              <Form.Select>
                <option value="option1">Student</option>
                <option value="option2">Teacher</option>
                <option value="option3">Parent</option>
                <option value="option3">Blog</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select Status</Form.Label>
              <Form.Select>
                <option value="option1">Active</option>
                <option value="option2">In Active</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTag}>
            Add Tag
          </Button>
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

export default TagsPage;
