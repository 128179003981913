import React from 'react'
import AccountLayout from '../../components/accountComponents/AccountLayout/AccountLayout'
import AccountLocation from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import '../../assets/css/parent/parent.css'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { IoMdCloudUpload } from "react-icons/io";

const userImg = require('../../assets/parent/profile-user.jpg')
const ParentsFeesWithIDPage = props => {
  const { history } = props
  return (
    <AccountLayout>
      <h3>Parents Fee Request</h3>
      {/* <AccountLocation path={history} /> */}
      <Row className='mt-3 mb-3'>
        <h6>Requester Details</h6>
      </Row>
      <Row className='mt-3 mb-3'>
        <Col className='d-flex align-items-center justify-content-start'>
          <img
            src={userImg}
            className='rounded-5 border shadow'
            style={{ width: '60px', height: '60px', objectFit: 'contain' }}
            alt=''
          />
          <div className='ms-3 p-0'>
            <h6 className='p-0 m-0'>User Name</h6>
            <span>user#000001</span>
          </div>
        </Col>
      </Row>
      <Row className='mt-3 mb-3'>
        <Col md={6}>
          <Row className='mt-2'>
            <Col>
              <b>First Name</b>
            </Col>
            <Col>Username1</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>User ID</b>
            </Col>
            <Col>#12345</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Country</b>
            </Col>
            <Col>India</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Account No.</b>
            </Col>
            <Col>0111212</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Address</b>
            </Col>
            <Col>local address</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Aadhar No.</b>
            </Col>
            <Col>00000000</Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row className='mt-2'>
            <Col>
              <b>Last Name</b>
            </Col>
            <Col> Username1</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Department</b>
            </Col>
            <Col>Abc</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Phone No.</b>
            </Col>
            <Col>9876543210</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>Status</b>
            </Col>
            <Col>Active</Col>
          </Row>
          <Row className='mt-2'>
            <Col>
              <b>IFSC</b>
            </Col>
            <Col>001212</Col>
          </Row>
        </Col>
      </Row>
      <Col md={12} className='mt-3 mb-3'>
        <Form.Group controlId='nameInput'>
          <Form.Label>Purpose Of Request</Form.Label>
          <Form.Control
            className='shadow rounded-3'
            type='text'
            placeholder='Purpose Of Request'
          />
        </Form.Group>
      </Col>
      <Row>
        <Col md={4} className='mt-3 mb-3'>
          <Form.Group controlId='nameInput'>
            <Form.Label>Amount Requested</Form.Label>
            <Form.Control
              className='shadow rounded-3'
              type='text'
              placeholder='Ammount'
            />
          </Form.Group>
        </Col>
        <Col md={4} className='mt-3 mb-3'>
          <Form.Group controlId='nameInput'>
            <Form.Label>Date Of Request</Form.Label>
            <Form.Control className='shadow rounded-3' type='date' />
          </Form.Group>
        </Col>

        <Col md={4} className='mt-3 mb-3'>
          <Form.Group controlId='selectOption'>
            <Form.Label>Specify Approver</Form.Label>
            <Form.Select className='shadow rounded-3'>
              <option value='option1'>Admin</option>
              <option value='option2'>Accountant</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Col className='d-flex align-items-center justify-content-center shadow rounded-3 border' style={{height:'25vh',flexDirection:'column'}}>
        <IoMdCloudUpload size={52} />
        <br />
        <h6>Supporting Documents</h6>
      </Col>

      <Col md={12} className='mt-3 mb-3'>
        <Form.Group controlId='textareaInput'>
          <Form.Label>Note/Comment</Form.Label>
          <Form.Control
            className='shadow rounded-3'
            as='textarea'
            rows={5}
            placeholder='Comment...'
          />
        </Form.Group>
      </Col>
      <Row className='mt-3 mb-3'>
        <Col>
          <Button
            className='rounded-3 shadow text-dark border-dark'
            variant='outlined'
            size='medium'
          >
            Save
          </Button>
          <Button
            className='rounded-3 shadow text-dark ms-3 border-dark'
            variant='outlined'
            size='medium'
          >
            Submit
          </Button>
        </Col>
      </Row>
    </AccountLayout>
  )
}

export default ParentsFeesWithIDPage
