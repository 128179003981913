import DuePaymentPage from "./DuePayment";

export const DuePaymentPageConfig = {
  routes: [
    {
      path: "/account/dashboard/due-payment",
      exact: true,
      component: DuePaymentPage
    }
  ]
};
