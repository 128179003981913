import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../assets/css/Help.css";
import { FaChevronRight } from "react-icons/fa6";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoCallOutline, IoMailOpenOutline } from "react-icons/io5";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../components/Button";

const Help = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="help_main">
        <Navbar />

        <div className="top_bar_help d-flex flex-column justify-content-center px-5">
          <h3 className="fw-bold">Help</h3>
          <p>
            Home <FaChevronRight style={{ fontSize: 12 }} /> Help
          </p>
        </div>

        <div className="container-fluid px-5 help_inner mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="row bg_help m-0 py-4 px-2 rounded-3 border">
                <div className="col-12 d-flex align-items-center gap-3">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <MdOutlineLocationOn className="fs-3 text-white" />
                  </div>
                  <div>
                    <h5 className="fw-semibold">Address</h5>
                    <p className="m-0 text-secondary">
                      1234 Street Name, City Name, United States
                    </p>
                  </div>
                </div>
              </div>
              <div className="row bg_help m-0 mt-4 py-4 px-2 rounded-3 border">
                <div className="col-12 d-flex align-items-center gap-3">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <IoCallOutline className="fs-3 text-white" />
                  </div>
                  <div>
                    <h5 className="fw-semibold">Phone</h5>
                    <p className="m-0 text-secondary">
                      +123 456 7890, +123 456 7890
                    </p>
                  </div>
                </div>
              </div>
              <div className="row bg_help m-0 mt-4 py-4 px-2 rounded-3 border">
                <div className="col-12 d-flex align-items-center gap-3">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <IoMailOpenOutline className="fs-3 text-white" />
                  </div>
                  <div>
                    <h5 className="fw-semibold">E-mail Address</h5>
                    <p className="m-0 text-secondary">info@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 bg_help p-4 rounded-3 border">
              <h4 className="fw-bold">Send Us Message</h4>
              <p className="m-0 text-secondary">
                Your email address will not be published. Required fields are
                marked *
              </p>
              <Row className="mt-3">
                <Col md={12}>
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    rows="5"
                  />
                </Col>
              </Row>
              <Row>
                <Form.Group as={Col} md={6} className="mt-3">
                  <Form.Control
                    type="text"
                    id="FirstName"
                    placeholder="Name*"
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} className="mt-3">
                  <Form.Control
                    type="text"
                    id="FirstName"
                    placeholder="E-mail*"
                  />
                </Form.Group>
              </Row>
              <Row className="mt-4">
                <div>
                  <Button text="Submit Now" />
                </div>
              </Row>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Help;
