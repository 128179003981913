import React, { useState } from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";

const CompanyAccount = () => {
  const [companyTab, setCompanyTab] = useState(1);
  const [childTab, setChildTab] = useState(1);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <AccountLayout>
      <h3>Company Account</h3>

      <Row className="mt-3 px-3">
        <div
          onClick={() => setCompanyTab(1)}
          className="py-1 px-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 1 ? "var(--color-primary)" : "",
            color: companyTab === 1 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Course Wise
        </div>
        <div
          onClick={() => setCompanyTab(2)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 2 ? "var(--color-primary)" : "",
            color: companyTab === 2 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Teacher Wise
        </div>
        <div
          onClick={() => setCompanyTab(3)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 3 ? "var(--color-primary)" : "",
            color: companyTab === 3 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Parent Wise
        </div>
        <div
          onClick={() => setCompanyTab(4)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 4 ? "var(--color-primary)" : "",
            color: companyTab === 4 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Affiliate Wise
        </div>
        <div
          onClick={() => setCompanyTab(5)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 5 ? "var(--color-primary)" : "",
            color: companyTab === 5 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Overall
        </div>
        <div
          onClick={() => setCompanyTab(6)}
          className="py-1 px-3 ms-3 rounded-5 border"
          style={{
            width: "fit-content",
            background: companyTab === 6 ? "var(--color-primary)" : "",
            color: companyTab === 6 ? "white" : "",
            cursor: "pointer",
          }}
        >
          Incoming Payments
        </div>
      </Row>

      {companyTab === 3 && (
        <Row className="mt-3 px-3">
          <div
            onClick={() => setChildTab(1)}
            className="py-1 px-3 rounded-5 border"
            style={{
              width: "fit-content",
              background: childTab === 1 ? "var(--color-primary)" : "",
              color: childTab === 1 ? "white" : "",
              cursor: "pointer",
            }}
          >
            Child 1
          </div>
          <div
            onClick={() => setChildTab(2)}
            className="py-1 px-3 ms-3 rounded-5 border"
            style={{
              width: "fit-content",
              background: childTab === 2 ? "var(--color-primary)" : "",
              color: childTab === 2 ? "white" : "",
              cursor: "pointer",
            }}
          >
            Child 2
          </div>
        </Row>
      )}

      {companyTab === 1 && (
        <>
          <Form className="mt-4">
            <Form.Select className="w-25" aria-label="Default select example">
              <option>Filter</option>
              <option value="daily">Daily</option>
              <option value="weakly">Weakly</option>
              <option value="monthly">Monthly</option>
            </Form.Select>
          </Form>
          <Table className="mt-2" striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Courses Id </th>
                <th>Courses Name</th>
                <th>Teacher Id</th>
                <th>Teacher name</th>
                <th>Total no of Student Enroll </th>
                <th>Total no of Active Student </th>
                <th>Total Revenue from parents </th>
                <th>Total no of demo </th>
                <th>Total no of paid demo</th>
                <th>Revenue from paid Demo</th>
                <th>Overall revenue</th>
                <th>Tax deducted </th>
                <th>Profi</th>
                <th>Comment</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>COR3434</td>
                    <td>Course 1</td>
                    <td>TEA3434</td>
                    <td>John Doe</td>
                    <td>100</td>
                    <td>100</td>
                    <td>₹ 10000</td>
                    <td>5</td>
                    <td>5</td>
                    <td>₹ 5000</td>
                    <td>₹ 15000</td>
                    <td>₹ 1000</td>
                    <td>₹ 4000</td>
                    <td>
                      <textarea
                        type="text"
                        className="p-1"
                        placeholder="Comment"
                      />
                      <br />
                      <button
                        className="btn mt-1 btn-sm w-100 text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Save
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={handleShow}
                        className="btn btn-sm text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {companyTab === 2 && (
        <>
          <Form className="mt-4">
            <Form.Select className="w-25" aria-label="Default select example">
              <option>Filter</option>
              <option value="daily">Daily</option>
              <option value="weakly">Weakly</option>
              <option value="monthly">Monthly</option>
            </Form.Select>
          </Form>
          <Table className="mt-2" striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Teacher ID </th>
                <th>Teacher Name </th>
                <th>Total no of courses</th>
                <th>Total no of active Courses</th>
                <th>Total no of Student Enroll</th>
                <th>Total No of Active Student</th>
                <th>Total no of Revenue from Parents </th>
                <th>Total no of pay to teacher</th>
                <th>Total fine </th>
                <th>Tax deducted</th>
                <th>Overall revenue </th>
                <th>Profit</th>
                <th>
                  Last date of teacher payment (learniee to teacher wallet)
                </th>
                <th>Last withdraw for teacher wallet to bank (date)</th>
                <th>Comment </th>
                <th>Edit </th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>TEA3434</td>
                    <td>John Doe</td>
                    <td>5</td>
                    <td>5</td>
                    <td>100</td>
                    <td>100</td>
                    <td>₹ 10000</td>
                    <td>₹ 5000</td>
                    <td>₹ 1000</td>
                    <td>₹ 1000</td>
                    <td>₹ 15000</td>
                    <td>₹ 4000</td>
                    <td>12/12/2021</td>
                    <td>12/12/2021</td>
                    <td>
                      <textarea
                        type="text"
                        className="p-1"
                        placeholder="Comment"
                      />
                      <br />
                      <button
                        className="btn mt-1 btn-sm w-100 text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Save
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={handleShow}
                        className="btn btn-sm text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {companyTab === 3 && (
        <>
          <Form className="mt-4">
            <Form.Select className="w-25" aria-label="Default select example">
              <option>Filter</option>
              <option value="daily">Daily</option>
              <option value="weakly">Weakly</option>
              <option value="monthly">Monthly</option>
            </Form.Select>
          </Form>
          <Table className="mt-4" striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Parents Id </th>
                <th>Parents name </th>
                <th>No of Child </th>
                <th>Total no of courses Enroll </th>
                <th>Total no of Classes pays (overall)</th>
                <th>Total no of Active Classes </th>
                <th>Last date of payment </th>
                <th>Last amount pays </th>
                <th>total no of revenue </th>
                <th>tax </th>
                <th>payment getway Charges </th>
                <th>total no of amount pays to teacher </th>
                <th>profit</th>
                <th> comment</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PAR3434</td>
                    <td>John Doe</td>
                    <td>2</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>12/12/2021</td>
                    <td>₹ 10000</td>
                    <td>₹ 10000</td>
                    <td>₹ 1000</td>
                    <td>₹ 1000</td>
                    <td>₹ 5000</td>
                    <td>₹ 4000</td>
                    <td>
                      <textarea
                        type="text"
                        className="p-1"
                        placeholder="Comment"
                      />
                      <br />
                      <button
                        className="btn mt-1 btn-sm w-100 text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Save
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={handleShow}
                        className="btn btn-sm text-white"
                        style={{ background: "var(--color-primary)" }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {companyTab === 4 && (
        <>
          <Table className="mt-4" striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Total Sales</th>
                <th>Total Amount Of Sales</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>PRO3434</td>
                    <td>Product 1</td>
                    <td>5</td>
                    <td>₹ 10000</td>
                    <td>12/12/2021</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}

      {companyTab === 5 && (
        <Table className="mt-4" striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Total Revenue</th>
              <th>Total Amount Goes To Teacher</th>
              <th>Profit</th>
              <th>Taxes Deducted</th>
              <th>Extra Revenue</th>
              <th>Total Profit</th>
              <th>Total Amount Recieved From Parents</th>
              <th>Total Amount Goes To Teachers</th>
              <th>Download Report</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(1)
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>₹ 10000</td>
                  <td>₹ 7000</td>
                  <td>₹ 3000</td>
                  <td>₹ 1000</td>
                  <td>₹ 1000</td>
                  <td>₹ 4000</td>
                  <td>₹ 10000</td>
                  <td>₹ 7000</td>
                  <td>
                    <button
                      className="btn btn-sm text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Download
                    </button>
                  </td>
                  <td>
                    <textarea
                      type="text"
                      className="p-1"
                      placeholder="Comment"
                    />
                    <br />
                    <button
                      className="btn mt-1 btn-sm w-100 text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      {companyTab === 6 && (
        <Table className="mt-4" striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Payment ID</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Payment Method</th>
              <th>Payment For</th>
              <th>Payer Name</th>
              <th>Payer ID</th>
              <th>Payer Email</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(1)
              .map((_, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>PAY3434</td>
                  <td>₹ 10000</td>
                  <td>12/12/2021</td>
                  <td>UPI</td>
                  <td>Course</td>
                  <td>John Doe</td>
                  <td>JOH3434</td>
                  <td>john@gmail.com</td>
                  <td>
                    <textarea
                      type="text"
                      className="p-1"
                      placeholder="Comment"
                    />
                    <br />
                    <button
                      className="btn mt-1 btn-sm w-100 text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ zIndex: 9990 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {companyTab === 1 ? (
            <>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Label>Course Id</Form.Label>
                  <Form.Control type="text" placeholder="Course Id" />
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Label>Course Name</Form.Label>
                  <Form.Control type="text" placeholder="Course Name" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Teacher Id</Form.Label>
                  <Form.Control type="text" placeholder="Teacher Id" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Teacher Name</Form.Label>
                  <Form.Control type="text" placeholder="Teacher Name" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Total no of Student Enroll</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Total no of Student Enroll"
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Total no of Active Student</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Total no of Active Student"
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Total Revenue from parents</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Total Revenue from parents"
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Total no of demo</Form.Label>
                  <Form.Control type="text" placeholder="Total no of demo" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Total no of paid demo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Total no of paid demo"
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Revenue from paid Demo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Revenue from paid Demo"
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Overall revenue</Form.Label>
                  <Form.Control type="text" placeholder="Overall revenue" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Tax deducted</Form.Label>
                  <Form.Control type="text" placeholder="Tax deducted" />
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Label>Profit</Form.Label>
                  <Form.Control type="text" placeholder="Profit" />
                </Col>

                <Col md={12} className="mb-3">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Comment" />
                </Col>
              </Row>
            </>
          ) : companyTab === 2 ? (
            <Row>
              <Col md={6} className="mb-3">
                <Form.Label>Teacher ID</Form.Label>
                <Form.Control type="text" placeholder="Teacher ID" />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>Teacher Name</Form.Label>
                <Form.Control type="text" placeholder="Teacher Name" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of courses</Form.Label>
                <Form.Control type="text" placeholder="Total no of courses" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of active Courses</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of active Courses"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of Student Enroll</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of Student Enroll"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total No of Active Student</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total No of Active Student"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of Revenue from Parents</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of Revenue from Parents"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of pay to teacher</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of pay to teacher"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total fine</Form.Label>
                <Form.Control type="text" placeholder="Total fine" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Tax deducted</Form.Label>
                <Form.Control type="text" placeholder="Tax deducted" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Overall revenue</Form.Label>
                <Form.Control type="text" placeholder="Overall revenue" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Profit</Form.Label>
                <Form.Control type="text" placeholder="Profit" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>
                  Last date of teacher payment (learniee to teacher wallet)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last date of teacher payment (learniee to teacher wallet)"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>
                  Last withdraw for teacher wallet to bank (date)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last withdraw for teacher wallet to bank (date)"
                />
              </Col>

              <Col md={12} className="mb-3">
                <Form.Label>Comment</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Comment" />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md={6} className="mb-3">
                <Form.Label>Parents Id</Form.Label>
                <Form.Control type="text" placeholder="Parents Id" />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>Parents Name</Form.Label>
                <Form.Control type="text" placeholder="Parents Name" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>No of Child</Form.Label>
                <Form.Control type="text" placeholder="No of Child" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of courses Enroll</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of courses Enroll"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of Classes pays (overall)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of Classes pays (overall)"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of Active Classes</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total no of Active Classes"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Last date of payment</Form.Label>
                <Form.Control type="text" placeholder="Last date of payment" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Last amount pays</Form.Label>
                <Form.Control type="text" placeholder="Last amount pays" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>Total no of revenue</Form.Label>
                <Form.Control type="text" placeholder="Total no of revenue" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>tax</Form.Label>
                <Form.Control type="text" placeholder="tax" />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>payment getway Charges</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="payment getway Charges"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>total no of amount pays to teacher</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="total no of amount pays to teacher"
                />
              </Col>

              <Col md={6} className="mb-3">
                <Form.Label>profit</Form.Label>
                <Form.Control type="text" placeholder="profit" />
              </Col>

              <Col md={12} className="mb-3">
                <Form.Label>Comment</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Comment" />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn text-white"
            style={{ background: "var(--color-primary)" }}
            onClick={handleClose}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </AccountLayout>
  );
};

export default CompanyAccount;
