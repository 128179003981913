import HomeWork from "./HomeWork.jsx";
import OnlineTest from "./OnlineTest.jsx";
import ViewTest from "./ViewTest.jsx";

export const HomeWorkPageConfig = {
  routes: [
    {
      path: "/parent/HomeWork",
      exact: true,
      component: HomeWork,
    },
    {
      path: "/parent/HomeWork/:id",
      exact: true,
      component: OnlineTest,
    },
    {
      path: "/parent/test/:id",
      exact: true,
      component: ViewTest,
    },
  ],
};
