import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../assets/css/bookADemo.css";
import { FaChevronRight } from "react-icons/fa6";
import Button from "../components/Button.jsx";
import axios from "../utils/axios.js";
import { toast } from "react-toastify";

function BookADemo() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    time: "",
    subject: "",
    description: "",
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const res = await axios.post("/home/demo-request", formData);

      // console.log(res.data);

      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          time: "",
          subject: "",
          description: "",
        });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bookademo">
        <Navbar />

        <div className="top_bar_book d-flex flex-column justify-content-center px-5">
          <h3 className="fw-bold">Book A Demo</h3>
          <p>
            Home <FaChevronRight style={{ fontSize: 12 }} /> Book A Demo
          </p>
        </div>

        <Container className="d-flex align-items-center justify-content-center pt-5">
          <div className="book_form p-4 rounded-3 border">
            <h4 className="text-center mb-4 fw-semibold">Book A Demo</h4>
            <Row>
              <Form.Group as={Col} md={6} className="mt-3">
                <Form.Label htmlFor="FirstName" className="fw-semibold">
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id="FirstName"
                  placeholder="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={(e) =>
                    setFormData({ ...formData, firstname: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} md={6} className="mt-3">
                <Form.Label htmlFor="LastName" className="fw-semibold">
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id="LastName"
                  placeholder="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={(e) =>
                    setFormData({ ...formData, lastname: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Group as={Col} md={12}>
                <Form.Label htmlFor="Email" className="fw-semibold">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  id="Email"
                  placeholder="example@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Group as={Col} md={6}>
                <Form.Label htmlFor="MobileNo" className="fw-semibold">
                  Mobile No.
                </Form.Label>
                <Form.Control
                  type="number"
                  id="MobileNo"
                  placeholder="+91 1234567890"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label htmlFor="Time" className="fw-semibold">
                  Time
                </Form.Label>
                <Form.Control
                  type="time"
                  id="Time"
                  placeholder="Time"
                  name="time"
                  value={formData.time}
                  onChange={(e) =>
                    setFormData({ ...formData, time: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Group as={Col} md={12}>
                <Form.Label htmlFor="Subject" className="fw-semibold">
                  Subject
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Subject"
                  placeholder="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={(e) =>
                    setFormData({ ...formData, subject: e.target.value })
                  }
                />
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  rows="5"
                  name="description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <div onClick={handleSubmit}>
                <Button text={loading ? "Loading.." : "Book A Demo"} />
              </div>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default BookADemo;
