import AllClasses from "./AllClasses";
import CertificateManagement from "./CertificateManagement";

export const CertificateManagementPageConfig = {
  routes: [
    {
      path: "/teacher/CertificateManagement",
      exact: true,
      component: AllClasses
    },
    {
      path: "/teacher/:classId/certificates",
      exact: true,
      component: CertificateManagement
    },
  ]
};
