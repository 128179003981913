import React from 'react'
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import ParentFooter from "../../components/ParentFooter";
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { GrCertificate } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { TbCertificate } from "react-icons/tb";

function AllClasses() {
    const navigate = useNavigate();
    return (
        <>
            <TeacherLayout>
                <h5 className='mt-3 mb-3 text-black'>All Classes</h5>
                <Row className='mt-3 mb-3 d-flex align-items-center justify-content-center'>
                    <Col md={6} className='pt-3'>

                    </Col>
                    <Col
                        md={6}
                        className="d-flex align-items-center justify-content-end"
                    >
                        {/* <Col md={3}> */}
                            <button className='w-100 mt-3 px-0 text-white bg-primary py-2 border rounded-3 me-2' size='sm'>
                                <TbCertificate size={22} />
                            </button>
                        {/* </Col> */}
                        <div className="form-group col-md-3 mt-3">
                            <select
                                className='form-control border-yellow'
                                id='gender'
                            >
                                <option value="" disabled selected>
                                    All
                                </option>

                            </select>
                        </div>
                        <div className="form-group ms-2 col-md-6 mt-3 ">
                            <Form.Group controlId="searchInput">
                                <InputGroup>
                                    <Form.Control
                                        className="border-yellow"
                                        type="text"
                                        placeholder="Search"
                                    />
                                    <InputGroup.Text className="border-yellow">
                                        <BsSearch />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Table striped bordered hover responsive>
                    <thead style={{ background: "var(--color-primary)" }}>
                        <tr>
                            <th className="text-white">S. No.</th>
                            <th className="text-white">Course ID</th>
                            <th className="text-white">Class Name</th>
                            <th className="text-white">Grade</th>
                            <th className="text-white">Subject/Course</th>
                            <th className="text-white">No. Classes</th>
                            <th className="text-white">Class Start</th>
                            <th className="text-white">Class End</th>
                            <th className="text-white">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...Array(6)].map((_, index) => (
                            <tr key={index}>
                                <td>{index + 1}.</td>
                                <td>Co@#fd34</td>
                                <td>Dummy Class {index + 1}</td>
                                <td>9</td>
                                <td>Mathematics</td>
                                <td>30</td>
                                <td>15 Jan 2020</td>
                                <td>15 May 2020</td>
                                <td className="student-managment-teacher-view-btn">
                                    <center onClick={() => navigate('/teacher/142512001/certificates')}><GrCertificate /></center>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ParentFooter />
            </TeacherLayout >
        </>
    )
}

export default AllClasses