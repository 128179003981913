import React from "react";
import { Navigate } from "react-router-dom";

import { DashboardPageConfig } from "../ParentPages/dashboard/DashboardPageConfig";
import { TeacherDashboardPageConfig } from "../TeacherPages/dashboard/TeacherDashboardConfig.jsx";
import { TeacherCalendarPageConfig } from "../TeacherPages/calendar/TeacherCalendarPageConfig.jsx";
import { CourseManagementPageConfig } from "../TeacherPages/courseManagement/CourseManagementConfig.jsx";
import { AddCoursePageConfig } from "../TeacherPages/courseManagement/AddCourseConfig.jsx";
import { AddSubjectPageConfig } from "../TeacherPages/courseManagement/AddSubjectConfig.jsx";
import { TeacherVacancydPageConfig } from "../TeacherPages/vacancy/VacancyPageConfig.jsx";
import { ReschedulePageConfig } from "../TeacherPages/Reschedule/ReschedulePageConfig.jsx";
import { RateCalculatorPageConfig } from "../TeacherPages/RateCalculator/RateCalculatorPageConfig.jsx";
import { StudentsManagementPageConfig } from "../TeacherPages/StudentsManagement/StudentsManagementPageConfig.jsx";
import { TeacherProfilePageConfig } from "../TeacherPages/TeacherProfile/TeacherProfilePageConfig.jsx";
import { TeacherReportsPageConfig } from "../TeacherPages/TecherReports/TeacherReportConfig.jsx";
import { ClassPageConfig } from "../TeacherPages/class/ClassPageConfig.jsx";
import { EditClassDetailsPageConfig } from "../TeacherPages/class/EditClassDetailsConfig.jsx";
import { TeacherBlogPageConfig } from "../TeacherPages/blog/BlogPageConfig.jsx";
import { TeacherAddBlogPageConfig } from "../TeacherPages/blog/AddBlogConfig.jsx";
import { TeacherDemoPageConfig } from "../TeacherPages/demo/DemoPageConfig.jsx";
import { CertificateManagementPageConfig } from "../TeacherPages/CertificateManagement/CertificateManagementPageConfig.jsx";
import { HwandTestPageConfig } from "../TeacherPages/HwandTest/HwandTestPageConfig.jsx";
import { TeacherLeavePageConfig } from "../TeacherPages/TeacherLeave/TeacherLeavePageConfig.jsx";
import { RegistrationPageConfig } from "../TeacherPages/registration/RegistrationConfig.jsx";
import { TeacherFAQPageConfig } from "../TeacherPages/teacherFAQ/TeacherFAQConfig.jsx";
import { TeacherWalletPageConfig } from "../TeacherPages/wallet/WalletConfigPage.jsx";
import { ReviewPageConfig } from "../TeacherPages/Review/ReviewPageConfig.jsx";
import { ChatPageConfig } from "../TeacherPages/Chat/ChatPageConfig.jsx";
import { ParentProfilePageConfig } from "../TeacherPages/StudentsManagement/ParentProfileConfig.jsx";
import { CoursePageConfig } from "../TeacherPages/cources/CourcesPageConfig.jsx";
import { CourseSearchPageConfig } from "../TeacherPages/cources/SearchPageConfig.jsx";
import { AddHomeWorkPageConfig } from "../TeacherPages/HwandTest/AddHomeWorkPageConfig.jsx";
import { AddTestPageConfig } from "../TeacherPages/HwandTest/AddTestPageConfig.jsx";
import { TeacherPaymentPageConfig } from "../TeacherPages/Payment/PaymentPageConfig.jsx";
import { TeacherUnderReviewPageConfig } from "../TeacherPages/underReview/UnderreviewConfig.jsx";
import { TeacherLivePageConfig } from "../TeacherPages/Live/TeacherLivePageConfig.jsx";
import { LearnieMallConfig } from "../TeacherPages/learnieMall/LearnieMallConfig.jsx";
import { ReferralPageConfig } from "../TeacherPages/Referral/ReferralPageConfig.jsx";
import { ComplainPageConfig } from "../TeacherPages/Complain/ComplainPageConfig.jsx";
import { PtaMeetingPageConfig } from "../TeacherPages/PTAMeeting/PtaMeetingPageConfig.jsx";

const TeacherRouteConfigs = [
  ...DashboardPageConfig.routes,
  ...TeacherDashboardPageConfig.routes,
  ...TeacherCalendarPageConfig.routes,
  ...CourseManagementPageConfig.routes,
  ...AddCoursePageConfig.routes,
  ...AddSubjectPageConfig.routes,
  ...TeacherVacancydPageConfig.routes,
  ...ReschedulePageConfig.routes,
  ...RateCalculatorPageConfig.routes,
  ...StudentsManagementPageConfig.routes,
  ...TeacherProfilePageConfig.routes,
  ...TeacherReportsPageConfig.routes,
  ...ClassPageConfig.routes,
  ...EditClassDetailsPageConfig.routes,
  ...TeacherBlogPageConfig.routes,
  ...TeacherAddBlogPageConfig.routes,
  ...TeacherDemoPageConfig.routes,
  ...CertificateManagementPageConfig.routes,
  ...HwandTestPageConfig.routes,
  ...TeacherLeavePageConfig.routes,
  ...RegistrationPageConfig.routes,
  ...TeacherFAQPageConfig.routes,
  ...TeacherWalletPageConfig.routes,
  ...ReviewPageConfig.routes,
  ...ChatPageConfig.routes,
  ...ParentProfilePageConfig.routes,
  ...CoursePageConfig.routes,
  ...CourseSearchPageConfig.routes,
  ...AddHomeWorkPageConfig.routes,
  ...AddTestPageConfig.routes,
  ...TeacherPaymentPageConfig.routes,
  ...TeacherUnderReviewPageConfig.routes,
  ...TeacherLivePageConfig.routes,
  ...LearnieMallConfig.routes,
  ...ReferralPageConfig.routes,
  ...ComplainPageConfig.routes,
  ...PtaMeetingPageConfig.routes,
];

const teacherRoutes = [
  ...TeacherRouteConfigs,
  {
    element: <Navigate to="/ParentPages/errors/error-404" replace />,
  },
  // {
  //   path: "/test",
  //   exact: true,
  //   component: <Example />
  // }
];

export default teacherRoutes;
