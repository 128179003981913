/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import Swal from 'sweetalert2';
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import styled from "styled-components";
import { Col, Container, Row, Modal, Form, Badge } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Paper } from "@material-ui/core";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import Card2 from "../parent/Card2";
import { IoSchoolOutline, IoStar, IoStarOutline, IoStarSharp } from "react-icons/io5";
import { Button, IconButton } from "@mui/material";
import { FaHeart, FaRegHeart, FaTrophy } from "react-icons/fa";
import { Star } from "@material-ui/icons";
import { IoMdClose, IoMdStar, IoMdTime } from "react-icons/io";
import { MdStarHalf } from "react-icons/md";
import { FaRegBookmark } from "react-icons/fa";
import { MdOutlineStarOutline } from "react-icons/md";

import {
  CalendarToday,
  MyLocation,
  StarHalfTwoTone,
  SupervisorAccount,
} from "@material-ui/icons";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SchoolIcon from "@mui/icons-material/School";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { GiPostOffice } from "react-icons/gi";
import { IoTimeSharp } from "react-icons/io5";
import ParentFooter from "../../components/ParentFooter";
import { TiBook, TiTick } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";
import Youtube from "react-youtube";
import card1 from "../../assets/parent/card1.png";
import UserProfile from "../../assets/user_1.jpg";

const localizer = momentLocalizer(moment);

const TeacherProfilePage = (props) => {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [eventFormData, setEventFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  // Define the events list
  const [myEventsList, setMyEventsList] = useState([
    {
      id: 0,
      title: "Your Remainder",
      start: new Date(2020, 0, 29, 9, 0, 0),
      end: new Date(2020, 0, 29, 13, 0, 0),
      resourceId: 1,
    },
    {
      id: 1,
      title: "Parent Meeting",
      allDay: true,
      start: new Date(2020, 13, 29, 14, 0, 0),
      end: new Date(2020, 0, 29, 16, 30, 0),
      resourceId: 2,
    },
    {
      id: 2,
      title: "Upcoming class",
      start: new Date(2020, 0, 29, 8, 30, 0),
      end: new Date(2020, 0, 29, 12, 30, 0),
      resourceId: 3,
    },
    {
      id: 11,
      title: "Birthday",
      start: new Date(2020, 0, 30, 7, 0, 0),
      end: new Date(2020, 0, 30, 10, 30, 0),
      resourceId: 4,
    },
    {
      id: 11,
      title: "Demo Class",
      start: new Date(2020, 0, 30, 7, 0, 0),
      end: new Date(2020, 0, 30, 10, 30, 0),
      resourceId: 4,
    },
  ]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventFormData({
      ...eventFormData,
      [name]: value,
    });
  };

  const handleDateChange = (field, date) => {
    setEventFormData({
      ...eventFormData,
      [field]: date,
    });
  };

  const handleAddEvent = () => {
    // Add the new event to the list of events
    // For simplicity, we'll use a random id here
    const newEvent = {
      id: Math.random(),
      title: eventFormData.title,
      start: eventFormData.start,
      end: eventFormData.end,
    };

    // Update the state with the new event
    setMyEventsList([...myEventsList, newEvent]);

    // Close the modal
    handleCloseModal();
  };

  const [showSubjectModal, setShowSubjectModal] = useState(false);

  const handleShowSubjectModal = () => setShowSubjectModal(true);
  const handleCloseSubjectModal = () => setShowSubjectModal(false);

  const [isAwardOpen, setIsAwardOpen] = useState(false)

  return (
    <OftadehLayout>
      <h5>Teacher Profile</h5>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <StyledContainer>
        <Container className="mt-5 bg-white shadow rounded-4 p-4">
          <Row>
            <Col md={6} className="d-flex flex-column ">
              <div className="d-flex gap-4 align-items-center flex-wrap">
                <div className="shadow rounded-pill">
                  <img style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'contain' }} src={UserProfile} alt="" />
                </div>
                <div className="d-flex flex-column">
                  <div className="like d-flex align-items-center flex-wrap">
                    <h5>John Deo</h5>
                    <IconButton className="m-0 p-0 ms-3" aria-label="delete">
                      <FaRegHeart />
                    </IconButton>
                    <sup className="ms-2">50+ </sup>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} /> (4.5) Rating <sup>250 +</sup>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2">
                    <h5><Badge style={{ backgroundColor: '#3498db' }} > Recommended by learniee </Badge></h5>
                    <h5><Badge style={{ backgroundColor: '#e74c3c' }} bg="danger" > Top Teacher </Badge></h5>
                  </div>
                  <div className="mt-2">
                    <DriveFileRenameOutlineIcon /> &nbsp; 39 reviews
                  </div>
                </div>
              </div>
              <Row className="mt-5">
                <Col md={4}>
                  <b>Top Qualification: </b>
                </Col>
                <Col md={8}>Teacher Top Qualification.</Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <b>Schools They Teach: </b>
                </Col>
                <Col md={8}>Lorem ipsum dolor sit amat.</Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <b>Language : </b>
                </Col>
                <Col md={8}>Hindi, English</Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <b>Subjects : </b>
                </Col>
                <Col md={8} className="d-flex gap-1 text-decoration-underline"> <p className="cursor-pointer" onClick={handleShowSubjectModal}>Hindi,</p> <p className="cursor-pointer" onClick={handleShowSubjectModal}>English,</p> <p className="cursor-pointer" onClick={handleShowSubjectModal}>Math,</p><p className="cursor-pointer" onClick={handleShowSubjectModal}>Science,</p> </Col>
              </Row>

              <Modal show={showSubjectModal} onHide={handleCloseSubjectModal} style={{ marginTop: '15vh' }}>
                <Modal.Header closeButton>
                  <Modal.Title>Hindi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Add content for your modal body here */}
                  <p>This is the content of your subject.</p>
                </Modal.Body>
                <Modal.Footer>
                  <button className="new-button">
                    Close
                  </button>
                </Modal.Footer>
              </Modal>


              <Row className="mt-3">
                <Col md={4}>
                  <b>Competitive Exams: </b>
                </Col>
                <Col md={8}>JEE, NEET</Col>
              </Row>
            </Col>
            <Col md={6} className="p-0 m-0">
              <div className="intro_video position-relative">
                <video
                  autoPlay
                  loop
                  muted
                  src="https://videos.pexels.com/video-files/3252424/3252424-uhd_3840_2160_25fps.mp4"
                ></video>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <h5>About Me:</h5>
            <p>
              {" "}
              Lorem ipsum dolor sit amet consectetur. Sed ut dictumst penatibus
              eu nisi in. Cursus elit nibh nisl facilisis. Ullamcorper lacus
              massa volutpat sagittis scelerisque purus. Fringilla nisl maecenas
              cursus tincidunt pellentesque quam.
            </p>
            <p>
              {" "}
              Lorem ipsum dolor sit amet consectetur. Sed ut dictumst penatibus
              eu nisi in. Cursus elit nibh nisl facilisis. Ullamcorper lacus
              massa volutpat sagittis scelerisque purus. Fringilla nisl maecenas
              cursus tincidunt pellentesque quam.
            </p>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <div className="teacher-profile-items-item mt-2">
                <GiPostOffice size={22} /> <span>Teacher Designation</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <LocalLibraryIcon /> <span>100 Classes Completed</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <MyLocation /> <span>Lives in Mumbai</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <IoTimeSharp size={22} /> <span>120+ Hours of Classes</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <CalendarToday /> <span>Joined on 24th Jan, 2023</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SupervisorAccount /> <span>10+ Years of Experience</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SchoolIcon /> <span>1000+ Students Taught</span>
              </div>
              <div className="teacher-profile-items-item mt-2 cursor-pointer" onClick={() => setIsAwardOpen(!isAwardOpen)}>
                <FaTrophy size={22} /> <span>Awards</span>
              </div>

              {
                isAwardOpen && (
                  <div className="awards mt-3">
                    <div className="line ms-2 rounded-5" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                    <div className="line ms-2" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                    <div className="content-award ms-1 d-flex align-items-center">
                      <div className="dot" style={{ width: 10, height: 10, background: '#0000FF', borderRadius: '50%' }}></div>
                      <div className="ms-3">
                        <div className="award-title">This is Award Title</div>
                        <div className="award-desc"></div>
                      </div>
                    </div>
                    <div className="line ms-2" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                    <div className="line ms-2" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                    <div className="content-award ms-1 d-flex align-items-center">
                      <div className="dot" style={{ width: 10, height: 10, background: '#0000FF', borderRadius: '50%' }}></div>
                      <div className="ms-3">
                        <div className="award-title">This is Award Title</div>
                        <div className="award-desc"></div>
                      </div>
                    </div>
                    <div className="line ms-2" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                    <div className="line ms-2" style={{ width: 3, height: 20, background: '#0000FF' }}></div>
                  </div>
                )
              }

              <div className="slide_video mt-4 mb-5">
                <Swiper
                  slidesPerView={window.innerWidth > 768 ? 2 : 1}
                  spaceBetween={20}
                  modules={[Navigation, Autoplay, Pagination]}
                  grabCursor={true}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper pt-4 pb-5"
                >
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <Row className="mt-3">
                <Col md={12}>
                  <b>Your Ratings: </b>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
                <Col md={12} className="ps-3">
                  <b>Punctuality</b>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <MdOutlineStarOutline />
                  </IconButton>
                </Col>
              </Row>
              <Form.Group controlId="reviewTextarea">
                {/* <Form.Label>Write your review:</Form.Label> */}
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your review here..."
                />
              </Form.Group>
              <button className="mt-3 new-button mb-3" variant="primary">
                Submit
              </button>
              <h4>Top Reviews</h4>
              <div className="teacter-profile-top-review-itme mt-3 n-box-shadow" >
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="">
                      <span className="name text-bold">Adam Shah</span>
                      <br />
                      <span className="time">5 min ago</span>
                      <br />
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <div className="profile-name-for-review ps-4">
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                  <span className="d-flex align-items-center justify-content-start this-is-review-star">
                    <b className="text-dark">Punctuality :</b> &nbsp;{" "} <Star /> <Star /> <Star /> <Star /> <Star />{" "}
                  </span>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>

            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h5 className="mt-4 mb-2">Qualification</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Error,
                tempora. Voluptate dolorem voluptates reiciendis! Quas, ab quo
                corporis optio rerum dolorem blanditiis. Quaerat?
              </p>
              <h5 className="mt-4 mb-2">Special Achievement</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Error,
                tempora. Voluptate dolorem voluptates reiciendis! Quas, ab quo
                corporis optio rerum dolorem blanditiis. Quaerat?
              </p>
            </Col>
          </Row>
        </Container>

        <h5 className="mt-5 mb-4">Calender</h5>

        <Row>
          <Col>
            <Paper style={{ padding: "5px" }}>
              <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                defaultDate={new Date(2020, 1, 1)}
                style={{ height: 500 }}
              />
            </Paper>

            {/* Add Event Modal */}
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              centered
              style={{ zIndex: 99999 }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Event</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter event title"
                      name="title"
                      value={eventFormData.title}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formStart">
                    <Form.Label>Start Date and Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="start"
                      value={moment(eventFormData.start).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(e) =>
                        handleDateChange("start", new Date(e.target.value))
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="formEnd">
                    <Form.Label>End Date and Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="end"
                      value={moment(eventFormData.end).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(e) =>
                        handleDateChange("end", new Date(e.target.value))
                      }
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="me-3 py-2 px-4 rounded-5 color-yellow fw-bold bg-transparent"
                  style={{ border: "1px solid var(--yellow-color)" }}
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className="py-2 px-4 rounded-5 border-0"
                  style={{ background: "var(--yellow-color)" }}
                  onClick={handleAddEvent}
                >
                  Add Event
                </button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={3} className="mt-4">
            <Form.Group controlId="nameInput">
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="Search Course by Name, Subject, Grade, Board..."
              />
            </Form.Group>
          </Col>
          <Col md={1} className="mt-4">
            <button className="new-button">Search</button>
          </Col>
          <Col md={2}></Col>
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Sort By</option>
              <option value="3">Heigh To Low</option>
              <option value="3">Low To Heigh</option>
            </Form.Select>
          </Col>
          <Col md={3} className="mt-4">
            <Form.Select
              className="rounded-4 w-100"
              aria-label="Default select example"
            >
              <option>Subject</option>
              <option value="1">Maths</option>
              <option value="2">Science</option>
              <option value="3">English</option>
            </Form.Select>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className="mt-4 mb-2">Topics</h5>
            <Row className="p-md-3 d-flex align-items-center justify-content-start">
              <Col md={4}>
                <New_Cards
                  img={c1}
                  New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
              <Col md={4}>
                <New_Cards
                  img={c2}
                  New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
              <Col md={4}>
                <New_Cards
                  img={c3}
                  New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
            </Row>
            <h5 className="mt-4 mb-2">Courses</h5>
            <Row className="p-md-3 d-flex align-items-center justify-content-start">
              <Col md={4}>
                <New_Cards
                  img={c1}
                  New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
              <Col md={4}>
                <New_Cards
                  img={c2}
                  New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
              <Col md={4}>
                <New_Cards
                  img={c3}

                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                />
              </Col>
            </Row>

            <h5 className="mt-4 mb-2">Upcoming Courses</h5>
            <Row className="p-md-3 d-flex align-items-center justify-content-start">
              <Col md={4}>
                <New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true}
                  img={c1} />
              </Col>
              <Col md={4}>
                <New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true} img={c2} />
              </Col>
              <Col md={4}>
                <New_Cards
                  completed={true}
                  age="17+"
                  type="1-on-1"
                  type2="Academic"
                  type3="Short course"
                  board="CBSE"
                  grade="A+"
                  demoFree={true} img={c3} />
              </Col>
            </Row>

            <h5 className="mt-4 mb-2">Blogs</h5>

            <Row>
              <Col md={6} className="mt-4 pe-4">
                <Card2
                  img={card2}
                  head={"Why is Gravity? | Class 11 | Physics"}
                  review={"267 views"}
                  time={"3 days ago"}
                  blogIcon={<FaAnglesRight />}
                  blog={"View blog"}
                  blogLikeDislike={true}
                />
              </Col>
              <Col md={6} className="mt-4 pe-4">
                <Card2
                  img={card2}
                  head={"Why is Gravity? | Class 11 | Physics"}
                  review={"267 views"}
                  time={"3 days ago"}
                  blogIcon={<FaAnglesRight />}
                  blog={"View blog"}
                  blogLikeDislike={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .intro_video {
    height: 90%;
    width: 95%;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .slide_video {
    height: 230px;
    width: 90%;

    .slide_video_inner {
      height: 230px;
      width: 100%;
      background-color: #000;
      img {
        height: 230px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

const Cards = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
}) => {
  const [showModalHold, setShowModalHold] = useState(false);

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };
  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          className="cource_img rounded-3 px-3"
          style={{ width: "100%", borderRadius: "7px" }}
          src={img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
        </div>

        <h5 className="mt-3 px-3">
          Learn Mandarin - Beginner <br /> Level
        </h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
            <p className="m-0 text-secondary">Robert Fox</p>
          </div>

          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        {badge && (
          <div className="d-flex align-items-center justify-content-between mt-3 px-3 pb-3">
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Online
            </span>
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Paid
            </span>

            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Fixed
            </span>
          </div>
        )}

        <div className="d-flex flex-wrap px-2 gap-2 mt-3">
          <div
            className="px-3 py-1 border rounded-5 cursor-pointer"
            style={{ width: "fit-content" }}
          >
            18 +
          </div>
          <div
            className="px-3 py-1 border rounded-5 cursor-pointer"
            style={{ width: "fit-content" }}
          >
            1 on 1
          </div>
        </div>

        <div
          className={`${holdbtn && "bottom"} ${paybtn && "bottom"} ${startCBtn && "bottom"
            } ${completed && "bottom"
            } mt-2 py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
          {completed && (
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              36% Completed
            </span>
          )}
        </div>

        {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )}

        {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
          </p>
        </div>
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const New_Cards = ({
  img,
  badge,
  holdbtn,
  paybtn,
  completed,
  startCBtn,
  joinbtn,
  continuebtn,
  onCourseClick,
  demoBtn,
  isEnrollCourse,
  age,
  type,
  type2,
  type3,
  board,
  grade,
  demoFree,
  demoPaid,
  joinNow,
  joinNowDisabled,
  restartBtn,
}) => {
  const [showModalHold, setShowModalHold] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const handleButtonClickHold = () => {
    setShowModalHold(true);
  };

  const handleCloseModelHold = () => {
    setShowModalHold(false);
  };

  const HandleCancel = () => {
    setFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModal(false);
  };

  const handlePaymentSuccess = () => {
    Swal.fire({
      title: 'Payment',
      text: 'Redirecting to payment page, please wait...',
      icon: 'success',
      timer: 5000, // 2 seconds
      timerProgressBar: true,
      // didClose: () => {
      //   history.push('/payment'); // Redirect to payment page
      // }
    });
  };


  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          onClick={onCourseClick ? onCourseClick : null}
          className="cource_img cursor-pointer rounded-3 px-3"
          style={{ width: "100%" }}
          src={img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Subject</button>

        </div>

        <h5 className="mt-3 px-3">
          Course Name
        </h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
          <p className="m-0 text-black">Robert Fox</p>

          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        {badge && (
          <div className="d-flex align-items-center justify-content-between mt-3 px-3 pb-3">
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Online
            </span>
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Paid
            </span>

            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              Fixed
            </span>
          </div>
        )}

        <div className="d-flex px-2 align-items-center  justify-content-between mt-2">
          <div className="d-flex flex-wrap gap-3">
            {age && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {age}
              </div>
            )}
            {type && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type}
              </div>
            )}
            {type2 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mt-3"
                style={{ width: "fit-content" }}
              >
                {type2}
              </div>
            )}
            {type3 && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {type3}
              </div>
            )}
            {board && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {board}
              </div>
            )}
            {grade && (
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer mb-3"
                style={{ width: "fit-content" }}
              >
                {grade}
              </div>
            )}
          </div>
        </div>
        {completed && (
          <div className="w-100 d-flex justify-content-end ">
            <span
              className="badge text-black me-3"
              style={{
                background: "var(--yellow-color)",
                height: "fit-content",
              }}
            >
              36% Completed
            </span>
          </div>
        )}

        <div
          className={`${holdbtn ? "bottom" : ""} ${paybtn ? "bottom" : ""} ${startCBtn ? "bottom" : ""
            } mt-2 py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {holdbtn && (
            <button className="py-1 px-3" onClick={handleButtonClickHold}>
              hold
            </button>
          )}
          {paybtn && <button className="py-1 px-3">pay</button>}
          {demoBtn && <button className="py-1 px-3">Book Demo</button>}
          {startCBtn && <button className="py-1 px-3">Start Course</button>}
        </div>

        {joinbtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Join Now</button>
          </div>
        )}

        {continuebtn && (
          <div className="px-3 pb-2 d-flex align-items-center justify-content-between">
            <button className="py-1 px-3">Continue</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          {/* <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p> */}
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          {/* <p className="m-0"> */}
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
          {/* </p> */}
        </div>

        {demoFree && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Take a Demo ( Free )
            </button>
          </div>
        )}

        {restartBtn && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Restart Now
            </button>
          </div>
        )}

        {joinNow && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-between mb-2 px-2">
            <button
              className="py-1 px-3"
            >
              Enroll Now
            </button>
            <div className="action">
              <span onClick={handlePaymentSuccess} className="px-1 cursor-pointer py-1 rounded-3 border" style={{ backgroundColor: '#b8e3b8' }}><TiTick size={22} color="green" /></span>
              <span onClick={() => setFeedbackModal(true)} className="px-1 cursor-pointer py-1 rounded-3 border ms-2 me-3" style={{ backgroundColor: '#f2c1b8' }}><IoMdClose size={22} color="red" /></span>
            </div>
          </div>
        )}

        {joinNowDisabled && (
          <div className="h-100 w-100">
            <button
              disabled
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Enroll Now
            </button>
          </div>
        )}

        {/* {demoPaid && (
          <div className="h-100 border-top  px-3 py-2 w-100 d-flex align-items-center gap-2">
            <button className="py-1 px-2">Enrol Now</button>
            <button className="py-1 px-2" onClick={() => HandleCancel()}>
              Cancel
            </button>
          </div>
        )} */}

        {demoPaid && (
          <div className="h-100 w-100">
            <button
              className="py-2"
              style={{ height: "100%", width: "100%", borderRadius: 0 }}
            >
              Book a Demo (₹ 100 )
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showModalHold}
        onHide={handleCloseModelHold}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hold Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to hold this class?</p>
          <div className="mb-3">
            <label htmlFor="start-date" className="form-label">
              Start Date
            </label>
            <input type="date" className="form-control" id="start-date" />
          </div>
          <div className="mb-3">
            <label htmlFor="end-date" className="form-label">
              End Date
            </label>
            <input type="date" className="form-control" id="end-date" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseModelHold}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseModelHold}>
            Hold
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModal}
        onHide={handleCloseFeedbackModal}
        size="small"
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
              <IoStarOutline className="ms-1" size={22} />
            </Col>
          </Row>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            {/* <Form.Label>Feedback</Form.Label> */}
            <Form.Control className="mt-3" as="textarea" placeholder="Enter Desc..." rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="bg-danger text-white me-3 border-0"
            variant="outlined"
            onClick={handleCloseFeedbackModal}
          >
            Close
          </Button>
          <Button variant="outlined" onClick={handleCloseFeedbackModal}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeacherProfilePage;
