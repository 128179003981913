import React, { useState } from 'react'
import OperationLayout from '../../components/operationComponents/OperationLayout/OperationLayout'
import OperationLocation from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Editor from 'react-simple-wysiwyg'
import { createBlog } from '../../Api/AdminApi/blogApi'

const TermEditPage = props => {
  const { history } = props

  return (
    <OperationLayout>
      <h5>Add Term & Condition</h5>
      {/* <OperationLocation path={history} /> */}

      <Container>
        <Row>
          <Col md={4}>
          <Form.Group as={Col} md={6} className='mb-3 mt-3'>
            <Form.Control required type='text' placeholder='Enter title' />
          </Form.Group>
          </Col>
          <Col md={4}>
          <Form.Group controlId='selectOption'>
            <Form.Select className='mt-3 mb-3'>
              <option selected disabled>
                status
              </option>
              <option value={'1'}>Active</option>
              <option value={'2'}>In Active</option>
            </Form.Select>
          </Form.Group>
        </Col>
          <Col md={12} className='mb-3'>
            <Editor
              containerProps={{
                style: {
                  resize: 'vertical',
                  minHeight: '40vh',
                  marginInline: 'auto'
                }
              }}
            />
          </Col>

        </Row>
        <Row>
          <Col>
            <Button type='submit' className='d-block mx-auto'>
              Add Term & Condition
            </Button>
          </Col>
        </Row>
      </Container>
    </OperationLayout>
  )
}

export default TermEditPage
