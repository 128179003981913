import React, { useState } from "react";
import { Button, Col, FormControl, InputGroup, Modal } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import user_image_url from "../../assets/user_1.jpg";
import card_img from "../../assets/brain_img_1.png";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaSearch, FaTimes } from "react-icons/fa";
function LearnieMallCard({ name, desc, image, key }) {
  const [showModal, setShowModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setOpenChat(false);
  };

  const copyToClipboard = () => {
    const referralLinkText = document.querySelector(".referral-link-text");

    if (referralLinkText) {
      const textArea = document.createElement("textarea");
      textArea.value = referralLinkText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    }
  };
  return (
    <>
      <Col md={4} key={key}>
        <div className="px-1 py-4 shadow rounded-4">
          <img
            src={image}
            style={{ width: "100%", height: "22vh", objectFit: "contain" }}
            alt=""
            srcset=""
          />
          <h4 className="mt-3 ps-2">{name}</h4>
          <p className="ps-2" style={{ textAlign: "justify" }}>
            {desc}
          </p>
          <button className="new-button ms-2">Buy Now</button>
          <button onClick={handleShowModal} className="new-button ms-2">
            share
          </button>
        </div>
      </Col>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ marginTop: "15vh" }}
      >
        <Modal.Body className="p-0 m-0">
          {!openChat && (
            <Col md={12}>
              <div className="referral-container-area shadow">
                <span className="title-referral-card">Share learnie mall</span>
                <Col className="mt-5 referral-link" md={12}>
                  <center>
                    {" "}
                    <span className="referral-link-text">
                      https://localhost:3000/product?Product=NIT14578A7P
                    </span>{" "}
                    <span
                      className="ms-2 icon-referral-page"
                      onClick={copyToClipboard}
                    >
                      <ContentCopyIcon />
                    </span>
                  </center>
                  <hr />
                </Col>
                <Col
                  className="referral-social-link mt-3 d-flex align-items-center justify-content-center"
                  style={{ gap: "20px", color: "white" }}
                >
                  <FacebookIcon />
                  <TwitterIcon />
                  <LinkedInIcon />
                  <WhatsAppIcon />
                  <IoChatbubbleEllipsesOutline
                    onClick={() => setOpenChat(true)}
                  />
                </Col>
              </div>
            </Col>
          )}

          {openChat && (
            <Col md={12} className="forward-message-container px-3 py-3">
              <div className="forward-message-header">
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <FaSearch />
                  </InputGroup.Text>
                  <FormControl placeholder="Search for teacher..." />
                  <Button
                    variant="light"
                    className="border"
                    onClick={() => setOpenChat(false)}
                  >
                    <FaTimes />
                  </Button>
                </InputGroup>
              </div>
              <div className="forward-message-content">
                <div className="user-list">
                  {/* Example user card */}
                  <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                    <div className="user-details">
                      <img
                        width={45}
                        height={45}
                        src={user_image_url}
                        alt="User"
                        className="user-image rounded-5"
                      />
                      <span className="user-name ms-3 text-bold">
                        User Name
                      </span>
                    </div>
                    <input type="checkbox" className="user-checkbox" />
                  </div>
                  <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                    <div className="user-details">
                      <img
                        width={45}
                        height={45}
                        src={user_image_url}
                        alt="User"
                        className="user-image rounded-5"
                      />
                      <span className="user-name ms-3 text-bold">
                        User Name
                      </span>
                    </div>
                    <input type="checkbox" className="user-checkbox" />
                  </div>
                  <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                    <div className="user-details">
                      <img
                        width={45}
                        height={45}
                        src={user_image_url}
                        alt="User"
                        className="user-image rounded-5"
                      />
                      <span className="user-name ms-3 text-bold">
                        User Name
                      </span>
                    </div>
                    <input type="checkbox" className="user-checkbox" />
                  </div>
                  <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                    <div className="user-details">
                      <img
                        width={45}
                        height={45}
                        src={user_image_url}
                        alt="User"
                        className="user-image rounded-5"
                      />
                      <span className="user-name ms-3 text-bold">
                        User Name
                      </span>
                    </div>
                    <input type="checkbox" className="user-checkbox" />
                  </div>
                  <div className="mt-2 user-card d-flex align-items-center justify-content-between border px-3 py-2 rounded-3">
                    <div className="user-details">
                      <img
                        width={45}
                        height={45}
                        src={user_image_url}
                        alt="User"
                        className="user-image rounded-5"
                      />
                      <span className="user-name ms-3 text-bold">
                        User Name
                      </span>
                    </div>
                    <input type="checkbox" className="user-checkbox" />
                  </div>
                </div>
              </div>
              <button className="new-button mt-3">send</button>
            </Col>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LearnieMallCard;
