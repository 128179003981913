import React, { useEffect, useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import "../../assets/css/parent/chat.css";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import ChatPageCommon from "../../components/chat/ChatPageCommon";

const ChatsPage = () => {
  const [user, setUser] = useState({});
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get("/common/info", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log(res);
        if (res.data.res_type === "success") {
          setUser(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [token]);

  return (
    <OperationLayout>
      <h5>Home Work</h5>

      <ChatPageCommon user={user} token={token} addgroup={true} />
    </OperationLayout>
  );
};

export default ChatsPage;
