import React from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaSearch } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";

function GiftPage(props) {
  const { history } = props;
  return (
    <>
      <h5>Gift</h5>
      {/* <OperationLocation path={history} /> */}

      <Row className="d-flex align-items-center justify-content-end">
        <Col md={4}>
          <Form.Group controlId="nameInput">
            <Form.Control
              className="shadow rounded-5"
              type="text"
              placeholder="Search..."
            />
          </Form.Group>
        </Col>
      </Row>

      <Table
        responsive
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        hover
        className="mt-3 custom-styled-table rounded-3 text-center"
      >
        <thead>
          <tr>
            <th className="text-white">S. No.</th>
            <th className="text-white">Gift To</th>
            <th className="text-white">Gift From</th>
            <th className="text-white">Gift I'D</th>
            <th className="text-white">Ammount</th>
            <th className="text-white">Status</th>
            <th className="text-white">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>1</td>
            <td>user#001</td>
            <td>user#001</td>
            <td>gift#001</td>
            <td className="text-primary">INR 40,000</td>
            <td>
              <Badge bg="secondary">Buy</Badge>
            </td>
            <td>05 Apr. 2022 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>1</td>
            <td>user#001</td>
            <td>user#001</td>
            <td>gift#001</td>
            <td className="text-primary">INR 40,000</td>
            <td>
              <Badge bg="success">gifted</Badge>
            </td>
            <td>05 Apr. 2022 10:30 AM</td>
          </tr>
          <tr className="bg-light">
            <td>1</td>
            <td>user#001</td>
            <td>user#001</td>
            <td>gift#001</td>
            <td className="text-primary">INR 40,000</td>
            <td>
              <Badge bg="danger">error</Badge>
            </td>
            <td>05 Apr. 2022 10:30 AM</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default GiftPage;
