import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Col, Row } from "react-bootstrap";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaDotCircle } from "react-icons/fa";
import Footer from "../components/Footer";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Navbar />
      <PolicyContainer>
        <div className="hero px-5 pb-4">
          <div className="responsive-box-hidden-for-copy"></div>
          <h2 className=" fw-bold mb-3">Disclaimer</h2>
          <li className="m-0">
            Please read this disclaimer carefully before using the Urja Talents
            website and services.
          </li>
          <p className="mt-3">Last Updated: 18 January 2024</p>
        </div>

        <Row className="mt-5 px-5">
          <Col>
            <h4>Information and Content : </h4>
            <ul
              className="ps-md-5"
              style={{ listStyleType: "none", padding: 0 }}
            >
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                The information and content provided on the Urja Talents
                website, including but not limited to academic resources,
                teacher profiles, and testimonials, are for informational
                purposes only and should not be construed as professional advice
                or endorsement.
              </li>
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                Urja Talents makes no warranties or representations, express or
                implied, about the accuracy, completeness, or reliability of the
                information and content contained on the website.
              </li>
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                Urja Talents disclaims any liability for any errors, omissions,
                or inaccuracies in the information and content provided on the
                website.
              </li>
              <li className="circle-list" style={{ marginBottom: "1em" }}>
                Users are responsible for conducting their own due diligence and
                seeking professional advice as needed before making any
                decisions based on the information and content provided on the
                website
              </li>
            </ul>
            <h4 className="mt-4 ">Third-Party Links :</h4>
            <ul className="ms-md-3">
              <li className="m-0 mt-2 circle-list">
                The Urja Talents website may contain links to third-party
                websites or resources. These links are provided for your
                convenience only and do not constitute an endorsement or
                affiliation by Urja Talents of the third-party website or
                resources
              </li>
              <li className="m-0 mt-2 circle-list">
                Urja Talents is not responsible for the content, accuracy, or
                privacy practices of any third-party website or resource
              </li>
              <li className="m-0 mt-2 circle-list">
                You access and use any third-party website or resource at your
                own risk.
              </li>
            </ul>
            <h4 className="mt-4">User Conduct :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                You agree to use the Urja Talents website and services in a
                lawful and responsible manner
              </li>
              <li className="mt-2 circle-list">
                {" "}
                You agree not to use the website or services for any purpose
                that is illegal, harmful, or offensive.
              </li>
              <li className="mt-2 circle-list">
                {" "}
                You agree not to upload, post, or transmit any content that is
                unlawful, harmful, threatening, abusive, harassing, tortious,
                defamatory, vulgar, obscene, libellous, invasive of another's
                privacy, hateful, or racially, ethnically, or otherwise
                objectionable
              </li>
              <li className="mt-2 circle-list">
                {" "}
                You agree not to infringe on the intellectual property rights of
                others.
              </li>
              <li className="mt-2 circle-list">
                {" "}
                Urja Talents reserves the right to terminate your access to the
                website and services if you violate any of these terms.
              </li>
            </ul>
            <h4 className="mt-4">Limitation of Liability :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                To the fullest extent permitted by law, Urja Talents disclaims
                all liability for any damages resulting from your use of the
                website and services, including but not limited to direct,
                indirect, incidental, consequential, and punitive damages.
              </li>
            </ul>
            <h4 className="mt-4">Indemnification :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                You agree to indemnify and hold harmless Urja Talents and its
                affiliates, officers, directors, employees, agents, and
                licensors from and against any and all claims, losses, damages,
                liabilities, costs, and expenses (including attorney's fees)
                arising out of your use of the website and services or your
                violation of these terms.
              </li>
            </ul>
            <h4 className="mt-4">Governing Law :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                These terms shall be governed by and construed in accordance
                with the laws of India
              </li>
            </ul>
            <h4 className="mt-4">Changes to Terms :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                Urja Talents reserves the right to modify these terms at any
                time. You agree to be bound by the then-current version of these
                terms by continuing to use the website and services.
              </li>
            </ul>
            <h4 className="mt-4">Contact :</h4>
            <ul className="ms-md-3">
              <li className="mt-2 circle-list">
                {" "}
                If you have any questions about these terms, please contact Urja
                Talents at urjatalents@gmail.com
              </li>
            </ul>
          </Col>
        </Row>
      </PolicyContainer>
      <Footer />
    </>
  );
};

const PolicyContainer = styled.div`
  overflow-x: hidden;
  h4 {
    font-weight: 600;
  }
  .hero {
    padding-top: 10vmax;
    height: fit-content;
    background: linear-gradient(to right, #f0ecfa, #f7f7f9, #f7ebf9);
  }
`;

export default Disclaimer;
