import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";
import { GiUpgrade } from "react-icons/gi";

import {
  MdEdit,
  MdOutlineClose,
  MdOutlineDeleteOutline,
  MdOutlineMessage,
} from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { FaAngleDown, FaPlus } from "react-icons/fa6";
import Card1 from "../../ParentPages/parent/Card1";
import card1 from "../../assets/parent/card1.png";
import { TiTick } from "react-icons/ti";
import {
  FaBook,
  FaChalkboardTeacher,
  FaRegCalendarTimes,
  FaStar,
} from "react-icons/fa";
import ParentFooter from "../../components/ParentFooter";
import { useNavigate } from "react-router-dom";
import AssignmentModal from "./AssignmentModal";
import { GrUserWorker, GrWorkshop } from "react-icons/gr";
import { IoMdStar } from "react-icons/io";

const TeacherDashboard = () => {
  const navigate = useNavigate();

  const navigateToaddNewClasses = () => {
    navigate("/teacher/dashboard/pages/course-management/add-course");
  };

  const [showModall, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleScrollToAssigment = () => {
    window.scrollTo(0, 900);
  };

  const [showFeedbackModel, setShowFeedbackModel] = useState(false);

  const handleShowFeedbackModel = () => setShowFeedbackModel(true);
  const handleCloseFeedbackModel = () => setShowFeedbackModel(false);

  return (
    <TeacherLayout>
      <h3 className="mt-3">Dashboard</h3>

      <AssignmentModal showModal={showModall} closeModal={handleCloseModal} />

      <StyledContainer>
        <Row className="mt-5">
          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/chat")}
              className="shadow rounded-4 cursor-pointer py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">3</h4>
                <p className="m-0">Unread Messages</p>
              </div>
              <MdOutlineMessage className="text_yellow fs-4" />
            </Card>
          </Col>
          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/dashboard/pages/demo")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">17</h4>
                <p className="m-0">Requested Demos</p>
              </div>
              <FaChalkboardTeacher className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/reschedule")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">117</h4>
                <p className="m-0">Requested Reschedulement</p>
              </div>
              <GrWorkshop className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/HwandTest")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">08</h4>
                <p className="m-0">Homework to Given</p>
              </div>
              <GrWorkshop className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/HwandTest")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">108</h4>
                <p className="m-0">Pending homework for Checking</p>
              </div>
              <GrUserWorker className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/HwandTest")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">18</h4>
                <p className="m-0">Test to Given</p>
              </div>
              <GrUserWorker className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/HwandTest")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">08</h4>
                <p className="m-0">Pending Test for Checking</p>
              </div>
              <GrUserWorker className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/StudentsManagement")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">08</h4>
                <p className="m-0">Active Student</p>
              </div>
              <GrUserWorker className="text_yellow fs-4" />
            </Card>
          </Col>

          <Col md={4} className="pe-3 mt-4">
            <Card
              onClick={() => navigate("/teacher/courses")}
              className="shadow cursor-pointer rounded-4 py-2 px-3 d-flex align-items-center justify-content-between flex-row"
            >
              <div>
                <h4 className="m-0">08</h4>
                <p className="m-0">Active Classes</p>
              </div>
              <GrUserWorker className="text_yellow fs-4" />
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col md={6}>
            <h3>Active Classes</h3>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-end align-items-center pe-5"
          >
            <button
              onClick={() => navigate("/teacher/reschedule")}
              className="me-2 add_btn py-1 px-3 rounded-5"
            >
              Reschedulment <FaPlus className="text_yellow fs-6 ms-1" />
            </button>

            {/* <button
              onClick={navigateToaddNewClasses}
              className="me-2 add_btn py-1 px-3 rounded-5"
            >
              Add New <FaPlus className='text_yellow fs-6 ms-1' />
            </button>{' '} */}
            {/* <FaAngleDown className='text_yellow fs-5' /> */}
          </Col>
          <hr className="mt-2" />
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <Card className="shadow rounded-4 p-3">
              <img
                className="rounded-4"
                style={{ height: "200px", width: "100%" }}
                src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>
              <h6 className="mt-2 fw-semibold">Student Name</h6>
              <div className="row">
                <div className="col-6">
                  <p className="m-0 mb-2">Grade :</p>
                  <p className="m-0 mb-2">Board :</p>
                  <p className="m-0 mb-2">Cycle :</p>
                  <p className="m-0 mb-2">Next Class :</p>
                  <p className="m-0">Class Count :</p>
                </div>
                <div className="col-6">
                  <p className="m-0 mb-2">Grade 1</p>
                  <p className="m-0 mb-2">CBSE</p>
                  <p className="m-0 mb-2">4 th</p>
                  <p className="m-0 mb-2">45 Minutes</p>
                  <p className="m-0">23/30</p>
                </div>
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <button className="start_btn py-1 px-3 rounded-5 ms-5">
                  Start
                </button>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow rounded-4 p-3">
              <img
                className="rounded-4"
                style={{ height: "200px", width: "100%" }}
                src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>
              <h6 className="mt-2 fw-semibold">Student Name</h6>
              <div className="row">
                <div className="col-6">
                  <p className="m-0 mb-2">Grade :</p>
                  <p className="m-0 mb-2">Board :</p>
                  <p className="m-0 mb-2">Cycle :</p>
                  <p className="m-0 mb-2">Next Class :</p>
                  <p className="m-0">Class Count :</p>
                </div>
                <div className="col-6">
                  <p className="m-0 mb-2">Grade 1</p>
                  <p className="m-0 mb-2">CBSE</p>
                  <p className="m-0 mb-2">5 th</p>
                  <p className="m-0 mb-2">45 Minutes</p>
                  <p className="m-0">23/30</p>
                </div>
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <button className="start_btn py-1 px-3 rounded-5 ms-5">
                  Start
                </button>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow rounded-4 p-3">
              <img
                className="rounded-4"
                style={{ height: "200px", width: "100%" }}
                src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>
              <h6 className="mt-2 fw-semibold">Student Name</h6>
              <div className="row">
                <div className="col-6">
                  <p className="m-0 mb-2">Grade :</p>
                  <p className="m-0 mb-2">Board :</p>
                  <p className="m-0 mb-2">Cycle :</p>
                  <p className="m-0 mb-2">Next Class :</p>
                  <p className="m-0">Class Count :</p>
                </div>
                <div className="col-6">
                  <p className="m-0 mb-2">Grade 1</p>
                  <p className="m-0 mb-2">CBSE</p>
                  <p className="m-0 mb-2">2 nd</p>
                  <p className="m-0 mb-2">45 Minutes</p>
                  <p className="m-0">23/30</p>
                </div>
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <button className="start_btn py-1 px-3 rounded-5 ms-5">
                  Start
                </button>
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>All Demos</h3>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <Card className="shadow rounded-4 p-3">
              <img
                className="rounded-4"
                style={{ height: "200px", width: "100%" }}
                src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>
              <h6 className="mt-2 fw-semibold">Student Name</h6>
              <div className="row">
                <div className="col-6">
                  <p className="m-0 mb-2">Grade :</p>
                  <p className="m-0">Board :</p>
                </div>
                <div className="col-6">
                  <p className="m-0 mb-2">Grade 1</p>
                  <p className="m-0">CBSE</p>
                </div>
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <div>
                  <span className="badge bg-success fw-bold ms-4">Completed</span>
                  <br />
                  <Button onClick={handleShowFeedbackModel} className="ms-4 mt-2" size="sm">
                    Feedback
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow rounded-4 p-3">
              <img
                className="rounded-4"
                style={{ height: "200px", width: "100%" }}
                src="https://images.unsplash.com/photo-1523289333742-be1143f6b766?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
              <h5 className="mt-3 fw-bold">Learn Mandarin - Beginner Level</h5>
              <h6 className="mt-2 fw-semibold">Student Name</h6>
              <div className="row">
                <div className="col-6">
                  <p className="m-0 mb-2">Grade :</p>
                  <p className="m-0">Board :</p>
                </div>
                <div className="col-6">
                  <p className="m-0 mb-2">Grade 1</p>
                  <p className="m-0">CBSE</p>
                </div>
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <span className="badge bg-danger fw-bold ms-4">
                  Not Completed
                </span>
              </div>
            </Card>
          </Col>
          <Col md={4}>
            <Card1
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              close={<MdOutlineDeleteOutline className="fs-4" />}
              edit={<MdEdit className="fs-4" />}
              text1Icons={
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
              }
              text1={"18 Jul 2023, 6 pm"}
              text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
              text4Icons={<GiUpgrade className="fs-5 text_yellow me-2" />}
              text3Icons={<FaBook className="fs-5 text_yellow me-2" />}
              text2={"26 students enrolled"}
              text3={"English"}
              text4={"A+"}
              startBtn={"start"}
            />
          </Col>
        </Row>

        <Modal style={{ marginTop: '10vh', zIndex: '142512001' }} show={showFeedbackModel} onHide={handleCloseFeedbackModel}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center">
              <FaStar color="gold" />
              <FaStar color="gold" />
              <FaStar color="gold" />
              <FaStar color="gold" />
              <FaStar color="gold" />
            </div>
            <div className="mt-3">
              <p>This is a sample feedback description. It provides detailed feedback on the service received.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFeedbackModel}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Row className="mt-5">
          <Col>
            <h3>Upcoming Demos</h3>
          </Col>
        </Row> */}

        {/* <Row className="mt-4">
          <Col md={4}>
            <Card1
              img={card1}
              head={"Learn Mandarin - Beginner Level"}
              close={<MdOutlineDeleteOutline className="fs-4" />}
              edit={<MdEdit className="fs-4" />}
              text1Icons={
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" />
              }
              text1={"18 Jul 2023, 6 pm"}
              text2Icons={<PiStudent className="fs-5 text_yellow me-2" />}
              text4Icons={<GiUpgrade className="fs-5 text_yellow me-2" />}
              text3Icons={<FaBook className="fs-5 text_yellow me-2" />}
              text2={"26 students enrolled"}
              text3={"English"}
              text4={"A+"}
              startBtn={"start"}
            />
          </Col>
        </Row> */}

        {/* <Row id='assigment' className='mt-5'>
          <Col md={6}>
            <Card className='shadow rounded-4 py-2 px-3'>
              <div className='d-flex align-items-center justify-content-between'>
                <h4 className='m-0'>Homework & Test</h4>
                
              </div>
              <p className="mt-3 mb-2 d-flex align-items-center">
                <FaRegCalendarTimes className="fs-5 text_yellow me-2" /> 18 Jul
                2023, 6 pm
              </p>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <div className="parent_user_img">
                  <img
                    src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=700&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="small_text line_height m-0 mb-1">XYZ</p>
                    <p className="small_text line_height m-0 mb-1">
                      Group Class
                    </p>
                    <p className="small_text line_height m-0 d-flex align-items-center">
                      <IoMdStar className="me-1 text_yellow" />
                      4.3
                    </p>
                  </div>
                </div>

                <button className="start_btn_card1 py-1 px-3 border-0">
                  Start
                </button>
              </div>
            </Card>
          </Col>
        </Row> */}
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .add_btn {
    border: 1.5px solid var(--color-primary);
    background-color: transparent;
    color: var(--color-primary);
  }

  .start_btn {
    background: var(--color-primary);
    color: white;
    border: none;
  }

  .calsses_circ {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--color-primary);
  }
`;

export default TeacherDashboard;
