import React from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row, Table } from 'react-bootstrap';
import { Button } from '@mui/material';
import { IoIosAdd } from 'react-icons/io';
import { MdDelete, MdOutlineFilterAltOff } from 'react-icons/md';
import { LuBoxSelect } from 'react-icons/lu';
import { FiEdit3 } from 'react-icons/fi';
function AlocateTask(props) {
    const { history } = props;
    return (
        <>
            <h5>AlocateTask</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className='mt-3 mb-3'>
                <Col md={4}>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Select custom className='shadow rounded-5 border'>
                            <option value="1">Category</option>
                            <option value="2">Option 2</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Select custom className='shadow rounded-5 border'>
                            <option value="1">Specification</option>
                            <option value="2">Option 2</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Button className='rounded-5 bg-dark text-white border-0 shadow' variant="outlined">Set</Button>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'>
                <Col md={4}>
                    <Form.Group controlId="nameInput">
                        <Form.Label>Name</Form.Label>
                        <Form.Control className='shadow rounded-5' type="text" placeholder="Enter name" />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group controlId="idInput">
                        <Form.Label>ID</Form.Label>
                        <Form.Control className='shadow rounded-5' type="text" placeholder="Enter ID" />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'>
                <Col md={4}>
                    <Button className='rounded-5 bg-dark text-white border-0 shadow' variant="outlined">Set</Button>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'>
                <Col md={4}>
                    <Form.Group controlId="idInput">
                        <Form.Label>Dead-line</Form.Label>
                        <Form.Control className='shadow rounded-5' type="date" placeholder="Dead-line" />
                    </Form.Group>
                </Col>
                <Col md={4} className='pt-2'>
                    <br />
                    <Button className='rounded-5 bg-dark text-white border-0 shadow' variant="outlined">Set</Button>
                </Col>
            </Row>
            <Col className='min-vh-7  border d-flex align-items-center justify-content-start ps-3' style={{ borderTopLeftRadius: '25px', borderTopRightRadius: '25px', height: '7vh' }}>
                <h6>Task Title</h6>
            </Col>
            <Col className=''>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control className='border-0 shadow' as="textarea" placeholder='task details' rows={5} style={{ borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px' }} />
                </Form.Group>
            </Col>
            <Col className='float-end mt-3'>
                <Button className='rounded-5 bg-dark text-white border-0 shadow' variant="outlined">Send</Button>
            </Col>
            <br />
            <br />
            <Row className='mt-3 mb-3'>
                <Col md={6}>
                    <Button className='black-outline-button rounded-5' variant="outlined" endIcon={<IoIosAdd />}> Add </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<MdOutlineFilterAltOff />}> Filter </Button>
                </Col>
                <Col md={6} className='d-flex align-items-center justify-content-end'>
                    <Button className='black-outline-button rounded-5' variant="outlined" startIcon={<LuBoxSelect />}> Select </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<FiEdit3 />}> Edit </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<MdDelete />}> Delete </Button>
                </Col>
            </Row>
            <Table className='mt-5 mb-3 rounded-4' striped responsive hover style={{backgroundColor:'#FE9A52'}}>
                <thead>
                    <tr className='text-center'>
                        <th>To User</th>
                        <th>Date Allocated</th>
                        <th>Deadline</th>
                        <th>View Task</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='text-center bg-light'>
                        <td>User Name</td>
                        <td>04 April, 2020</td>
                        <td>04 April, 2020</td>
                        <td>
                            <span className="test-primary">View</span>
                        </td>
                    </tr>
                    <tr className='text-center bg-light'>
                        <td>User Name</td>
                        <td>04 April, 2020</td>
                        <td>04 April, 2020</td>
                        <td>
                            <span className="test-primary">View</span>
                        </td>
                    </tr>
                    <tr className='text-center bg-light'>
                        <td>User Name</td>
                        <td>04 April, 2020</td>
                        <td>04 April, 2020</td>
                        <td>
                            <span className="test-primary">View</span>
                        </td>
                    </tr>
                    <tr className='text-center bg-light'>
                        <td>User Name</td>
                        <td>04 April, 2020</td>
                        <td>04 April, 2020</td>
                        <td>
                            <span className="test-primary">View</span>
                        </td>
                    </tr>
                    <tr className='text-center bg-light'>
                        <td>User Name</td>
                        <td>04 April, 2020</td>
                        <td>04 April, 2020</td>
                        <td>
                            <span className="test-primary">View</span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default AlocateTask