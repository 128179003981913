import TeacherLeave from "./TeacherLeave";

export const TeacherLeavePageConfig = {
  routes: [
    {
      path: "/teacher/TeacherLeave",
      exact: true,
      component: TeacherLeave
    }
  ]
};
