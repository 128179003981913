import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { FaMapPin } from "react-icons/fa6";
import { CiMapPin } from "react-icons/ci";

const Card2 = ({
  img,
  head,
  review,
  time,
  blogIcon,
  blog,
  bottomRowText1,
  bottomRowText2,
  bottomRowText2Icon,
  deleteButton,
  blogLikeDislike,
  redirect,
}) => {
  let navigate = useNavigate();

  const [like, setLike] = useState(false);

  const handlenavigatetoviewblog = () => {
    navigate("/blog/2354");
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <div
      className="parent_card2 shadow rounded-4 mb-3 ms-3"
      onClick={redirect ? () => handleNavigate(redirect) : null}
    >
      <div className="parent_card2_top d-flex align-items-center justify-content-center pe-2 py-2 overflow-hidden position-relative">
        <img src={img} className="rounded-3" alt="" />
      </div>
      <div className="parent_card2_bottom p-3">
        {head && (
          <h5 className="fw-bold mt-1">
            {head}
            {blogLikeDislike && (
              <IconButton
                onClick={() => setLike(!like)}
                className="ms-5"
                aria-label="delete"
              >
                {like ? <FaMapPin color="red" /> : <CiMapPin />}
              </IconButton>
            )}
          </h5>
        )}
        {review && time && blogIcon && blog && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center gap-4">
              <p className="m-0">{review}</p>
              <p className="m-0">{time}</p>
            </div>
            {deleteButton && <MdDeleteOutline className="fs-5 text-danger" />}
            <p
              className="m-0 fw-bold text_yellow"
              onClick={handlenavigatetoviewblog}
            >
              {blog} {blogIcon}
            </p>
          </div>
        )}

        {bottomRowText1 && bottomRowText2 && bottomRowText2Icon && (
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 fw-bold d-flex align-items-center">
              {bottomRowText2Icon} {bottomRowText2}
            </p>
            <p className="m-0 fw-bold">{bottomRowText1}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card2;
