import ReviewPage from "./ReviewPage";
import Sugessions from "./Sugessions";

export const ReviewPageConfig = {
  routes: [
    {
      path: "/teacher/review",
      exact: true,
      component: ReviewPage
    },
    {
      path: "/teacher/suggestion",
      exact: true,
      component: Sugessions
    },
  ]
};
