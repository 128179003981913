import React, { useState } from "react";
import { Modal, Row, Table } from "react-bootstrap";

const data = [
  {
    id: 1,
    classId: "C001",
    className: "Class 1",
    classType: "Type 1",
    CourseId: "C001",
    CourseName: "Course 1",
    teacherId: "T001",
    teacherName: "Teacher 1",
    startDate: "2021-09-01 12:00:00",
    endDate: "2021-09-01 12:00:00",
    status: "Active",
  },
  {
    id: 2,
    classId: "C002",
    className: "Class 2",
    classType: "Type 2",
    CourseId: "C002",
    CourseName: "Course 2",
    teacherId: "T002",
    teacherName: "Teacher 2",
    startDate: "2021-09-01 12:00:00",
    endDate: "2021-09-01 12:00:00",
    status: "Active",
  },
  {
    id: 3,
    classId: "C003",
    className: "Class 3",
    classType: "Type 3",
    CourseId: "C003",
    CourseName: "Course 3",
    teacherId: "T003",
    teacherName: "Teacher 3",
    startDate: "2021-09-01 12:00:00",
    endDate: "2021-09-01 12:00:00",
    status: "Active",
  },
];

const AllClasses = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <h5>All Active Classes</h5>

      <Table
        className="mt-4"
        style={{ minWidth: 1600 }}
        striped
        bordered
        hover
        responsive
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Class ID</th>
            <th>Class Name</th>
            <th>Class Type</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Teacher ID</th>
            <th>Teacher Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.classId}</td>
              <td>{item.className}</td>
              <td>{item.classType}</td>
              <td>{item.CourseId}</td>
              <td>{item.CourseName}</td>
              <td>{item.teacherId}</td>
              <td>{item.teacherName}</td>
              <td>{item.startDate}</td>
              <td>{item.endDate}</td>
              <td>{item.status}</td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => handleShow()}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-md-6">
              <label>Class ID</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Class Name</label>
              <input type="text" className="form-control" />
            </div>
          </Row>
          <Row className="mt-3">
            <div className="col-md-6">
              <label>Class Type</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Course ID</label>
              <input type="text" className="form-control" />
            </div>
          </Row>
          <Row className="mt-3">
            <div className="col-md-6">
              <label>Course Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Teacher ID</label>
              <input type="text" className="form-control" />
            </div>
          </Row>
          <Row className="mt-3">
            <div className="col-md-6">
              <label>Teacher Name</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Start Date</label>
              <input type="text" className="form-control" />
            </div>
          </Row>
          <Row className="mt-3">
            <div className="col-md-6">
              <label>End Date</label>
              <input type="text" className="form-control" />
            </div>
            <div className="col-md-6">
              <label>Status</label>
              <input type="text" className="form-control" />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleClose}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllClasses;
