/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FaStar, FaStarHalfStroke } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";

const ActiveDemo = [
  {
    id: 1,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent1",
    teacherId: "teacher@1234",
    teacherName: "teacher1",
    childId: "child@1234",
    childName: "child1",
    subject: "Maths",
    grade: "5th",
    board: "CBSE",
    parentEmail: "testEmail@gmail.com",
    description: "test",
  },
  {
    id: 2,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent2",
    teacherId: "teacher@1234",
    teacherName: "teacher2",
    childId: "child@1234",
    childName: "child2",
    subject: "Science",
    grade: "6th",
    board: "ICSE",
    parentEmail: "testEmail@gmail.com",
    description: "test",
  },
  {
    id: 3,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent2",
    teacherId: "teacher@1234",
    teacherName: "teacher2",
    childId: "child@1234",
    childName: "child2",
    subject: "Science",
    grade: "6th",
    board: "ICSE",
    parentEmail: "testEmail@gmail.com",
    description: "test",
  },
  {
    id: 4,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent2",
    teacherId: "teacher@1234",
    teacherName: "teacher2",
    childId: "child@1234",
    childName: "child2",
    subject: "Science",
    grade: "6th",
    board: "ICSE",
    parentEmail: "testEmail@gmail.com",
    description: "test",
  },
];

const upcomingDemo = [
  {
    id: 1,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent1",
    teacherId: "teacher@1234",
    teacherName: "teacher1",
    childId: "child@1234",
    childName: "child1",
    subject: "Maths",
    grade: "5th",
    board: "CBSE",
    date: "12/12/2021",
    time: "10:00 AM",
  },
  {
    id: 2,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent1",
    teacherId: "teacher@1234",
    teacherName: "teacher1",
    childId: "child@1234",
    childName: "child1",
    subject: "Maths",
    grade: "5th",
    board: "CBSE",
    date: "12/12/2021",
    time: "10:00 AM",
  },
  {
    id: 3,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent1",
    teacherId: "teacher@1234",
    teacherName: "teacher1",
    childId: "child@1234",
    childName: "child1",
    subject: "Maths",
    grade: "5th",
    board: "CBSE",
    date: "12/12/2021",
    time: "10:00 AM",
  },
  {
    id: 4,
    demoId: "demo@12234",
    parentId: "parent@1234",
    parentName: "parent1",
    teacherId: "teacher@1234",
    teacherName: "teacher1",
    childId: "child@1234",
    childName: "child1",
    subject: "Maths",
    grade: "5th",
    board: "CBSE",
    date: "12/12/2021",
    time: "10:00 AM",
  },
];

const AllDemoPage = () => {
  const [selectOption, setSelectOption] = useState("active demo");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEditCloseModal = () => {
    setShowEditModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleOpenEditModal = () => {
    setShowEditModal(true);
  };

  return (
    <OperationLayout>
      <h4>
        All Demos {">"} {selectOption}{" "}
      </h4>
      <Row>
        <Col md={3}>
          <Form.Select
            onChange={(e) => setSelectOption(e.target.value)}
            className="shadow rounded-5"
          >
            <option>Select</option>
            <option value="active demo">Active Demo</option>
            <option value="upcoming demo">UpComing Demo</option>
            <option value="completed demo">Completed Demo</option>
          </Form.Select>
        </Col>
      </Row>
      {selectOption === "active demo" && (
        <Table
          className="mt-4 mb-3 rounded-4"
          striped
          responsive
          hover
          style={{ backgroundColor: "var(--color-primary)" }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S. No.</th>
              <th className="text-white">Demo ID</th>
              <th className="text-white">Parent ID</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Teacher ID</th>
              <th className="text-white">Teacher Name</th>
              <th className="text-white">Child ID</th>
              <th className="text-white">Child Name</th>
              <th className="text-white">Subject</th>
              <th className="text-white">Grade</th>
              <th className="text-white">Board</th>
              <th className="text-white">Parent Email</th>
              <th className="text-white">Description</th>
              <th className="text-white" colSpan={3}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {ActiveDemo.map((demo, index) => (
              <tr className="text-center bg-light" key={demo.id}>
                <td>{index + 1}</td>
                <td>{demo.demoId}</td>
                <td>{demo.parentId}</td>
                <td>{demo.parentName}</td>
                <td>{demo.teacherId}</td>
                <td>{demo.teacherName}</td>
                <td>{demo.childId}</td>
                <td>{demo.childName}</td>
                <td>{demo.subject}</td>
                <td>{demo.grade}</td>
                <td>{demo.board}</td>
                <td>{demo.parentEmail}</td>
                <td>{demo.description}</td>
                <td>
                  <Badge
                    bg="primary"
                    onClick={handleOpenModal}
                    className="cursor-pointer"
                  >
                    Feed Back
                  </Badge>
                </td>
                <td>
                  <CiEdit
                    onClick={handleOpenEditModal}
                    className="cursor-pointer"
                    size={22}
                  />
                </td>
                <td>
                  <a href="#" className="badge-link">
                    <Badge bg="primary">Join</Badge>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {selectOption === "completed demo" && (
        <Table
          className="mt-4 mb-3 rounded-4"
          striped
          responsive
          hover
          style={{ backgroundColor: "var(--color-primary)" }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S. No.</th>
              <th className="text-white">Demo ID</th>
              <th className="text-white">Parent ID</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Teacher ID</th>
              <th className="text-white">Teacher Name</th>
              <th className="text-white">Child ID</th>
              <th className="text-white">Child Name</th>
              <th className="text-white">Subject</th>
              <th className="text-white">Grade</th>
              <th className="text-white">Board</th>
              <th className="text-white">Date</th>
              <th className="text-white">Time</th>
              <th className="text-white">Teacher Status</th>
              <th className="text-white">Student Status</th>
              <th className="text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {upcomingDemo.map((demo, index) => (
              <tr className="text-center bg-light" key={demo.id}>
                <td>{index + 1}</td>
                <td>{demo.demoId}</td>
                <td>{demo.parentId}</td>
                <td>{demo.parentName}</td>
                <td>{demo.teacherId}</td>
                <td>{demo.teacherName}</td>
                <td>{demo.childId}</td>
                <td>{demo.childName}</td>
                <td>{demo.subject}</td>
                <td>{demo.grade}</td>
                <td>{demo.board}</td>
                <td>{demo.date}</td>
                <td>{demo.time}</td>
                <td>
                  <Badge bg="warning">Late Joining</Badge>
                </td>
                <td>
                  <Badge bg="success">Present</Badge>
                </td>

                <td>
                  <Badge
                    bg="primary"
                    onClick={handleOpenModal}
                    className="cursor-pointer"
                  >
                    Feed Back
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ marginTop: "10vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Row> */}
          <Col md={12}>
            <Form.Group>
              <Form.Label>Attitude :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Presentability :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Doubt Clearing :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Communication Skill :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Punctuality :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label>Knowledge Level :</Form.Label>
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStar color="#ffd700" size={18} />
              <FaStarHalfStroke color="#ffd700" size={18} />
            </Form.Group>
          </Col>
          {/* </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* <Button variant="primary">
            Submit Feedback
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={handleEditCloseModal}
        style={{ marginTop: "10vh" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="teacherId">
            <Form.Label>Teacher ID</Form.Label>
            <Form.Control type="text" placeholder="Enter teacher ID" />
          </Form.Group>
          <Form.Group controlId="time">
            <Form.Label>Time</Form.Label>
            <Form.Control type="time" />
          </Form.Group>
          <Form.Group controlId="date">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary">save changes</Button>
        </Modal.Footer>
      </Modal>

      {selectOption === "upcoming demo" && (
        <Table
          className="mt-4 mb-3 rounded-4"
          striped
          responsive
          hover
          style={{ backgroundColor: "var(--color-primary)" }}
        >
          <thead>
            <tr className="text-center">
              <th className="text-white">S. No.</th>
              <th className="text-white">Demo ID</th>
              <th className="text-white">Parent ID</th>
              <th className="text-white">Parent Name</th>
              <th className="text-white">Teacher ID</th>
              <th className="text-white">Teacher Name</th>
              <th className="text-white">Child ID</th>
              <th className="text-white">Child Name</th>
              <th className="text-white">Subject</th>
              <th className="text-white">Grade</th>
              <th className="text-white">Board</th>
              <th className="text-white">Date</th>
              <th className="text-white">Time</th>
              <th className="text-white" colSpan={2}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {upcomingDemo.map((demo, index) => (
              <tr className="text-center bg-light" key={demo.id}>
                <td>{index + 1}</td>
                <td>{demo.demoId}</td>
                <td>{demo.parentId}</td>
                <td>{demo.parentName}</td>
                <td>{demo.teacherId}</td>
                <td>{demo.teacherName}</td>
                <td>{demo.childId}</td>
                <td>{demo.childName}</td>
                <td>{demo.subject}</td>
                <td>{demo.grade}</td>
                <td>{demo.board}</td>
                <td>{demo.date}</td>
                <td>{demo.time}</td>

                <td>
                  <CiEdit
                    onClick={handleOpenEditModal}
                    className="cursor-pointer"
                    size={22}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm rounded-5"
                    style={{
                      backgroundColor: "transparent",
                      color: "var(--color-primary)",
                      border: "1px solid var(--color-primary)",
                    }}
                  >
                    Join
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </OperationLayout>
  );
};

export default AllDemoPage;
