import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Badge, Table, Modal } from "react-bootstrap";
function StudentAttandace() {
  const [show, setShow] = useState(false);
  const handleView = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  return (
    <TeacherLayout>
      <h5>Student Attendance Group Classes</h5>
      <Table className="mt-5" striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Lecture Attended</th>
            <th>No. of Lecture</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Sample Data */}
          <tr>
            <td>1.</td>
            <td>12345</td>
            <td>John Doe</td>
            <td>21</td>
            <td>23</td>
            <td>
              <button
                onClick={handleView}
                className="btn btn-sm rounded-5"
                style={{
                  backgroundColor: "var(--color-primary)",
                  color: "white",
                }}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>12345</td>
            <td>John Doe</td>
            <td>21</td>
            <td>23</td>
            <td>
              <button
                onClick={handleView}
                className="btn btn-sm rounded-5"
                style={{
                  backgroundColor: "var(--color-primary)",
                  color: "white",
                }}
              >
                View
              </button>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>12345</td>
            <td>John Doe</td>
            <td>21</td>
            <td>23</td>
            <td>
              <button
                onClick={handleView}
                className="btn btn-sm rounded-5"
                style={{
                  backgroundColor: "var(--color-primary)",
                  color: "white",
                }}
              >
                View
              </button>
            </td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </Table>

      <Modal show={show} size="lg" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Lecture No</th>
                <th>Lecture Title</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample Data */}
              <tr>
                <td>1.</td>
                <td>01-01-2021</td>
                <td>1</td>
                <td>Introduction to React</td>
                <td>
                  <Badge
                    pill
                    className="bg-success text-white"
                  >
                    Present
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>02-01-2021</td>
                <td>1</td>
                <td>Introduction to React</td>
                <td>
                  <Badge
                    pill
                    className="bg-danger text-white"
                  >
                    Absent
                  </Badge>
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>03-01-2021</td>
                <td>1</td>
                <td>Introduction to React</td>
                <td>
                  <Badge
                    pill
                    className="bg-warning"
                  >
                    Late
                  </Badge>
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn rounded-5"
            style={{
              backgroundColor: "var(--color-primary)",
              color: "white",
            }}
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </TeacherLayout>
  );
}

export default StudentAttandace;
