import TeacherVacancydPage from "./VacancyPage";

export const TeacherVacancydPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/vacancy",
      exact: true,
      component: TeacherVacancydPage
    }
  ]
};
