import CareerPage from "./CareerPage";

export const CareerPageConfig = {
  routes: [
    {
      path: "/operation/career",
      exact: true,
      component: CareerPage
    }
  ]
};
