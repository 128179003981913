import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { FaRegStar } from 'react-icons/fa';

const FeedbackModal = ({ show, onHide }) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const handleRatingChange = (event) => {
        setRating(parseInt(event.target.value));
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = () => {
        // Implement your submit logic here
        console.log("Rating:", rating);
        console.log("Comment:", comment);
        // Reset form state
        setRating(0);
        setComment('');
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} style={{marginTop:'15vh'}}>
            <Modal.Header closeButton>
                <Modal.Title>Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Rating:</Form.Label>
                    <div>
                        {[1, 2, 3, 4, 5].map((value) => (
                            
                            <IconButton onClick={handleRatingChange} aria-label="delete">
                            <FaRegStar size={22} color="blue" />
                          </IconButton>
                        ))}
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Comment:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={comment}
                        onChange={handleCommentChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={onHide}>Close</button>
                <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
            </Modal.Footer>
        </Modal>
    );
};

export default FeedbackModal;
