import React, { useEffect } from 'react'
import Navbar from '../components/Navbar.js';
import job from "../assets/mentor.png";
import gsap from 'gsap';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { Col, Container, Row } from 'react-bootstrap';
import { FaAward } from 'react-icons/fa6';
import { MdCastForEducation } from 'react-icons/md';
import { IoLocation } from 'react-icons/io5';
import { GiOfficeChair } from 'react-icons/gi';
import Footer from '../components/Footer.js';

const user_1 = require('../assets/user_1.jpg');
const user_2 = require('../assets/user_2.jpg');
const user_3 = require('../assets/user_3.jpg');

function BecomeAMentore() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []); 

    return (
        <>
            <div className="main">
                <Navbar />
                <br />
                <br />
                <br />
                <CareerContainer>
                    <div className="d-md-flex align-items-center justify-content-around" style={{ gap: '20px' }}>
                        <div className="d-md-block d-flex flex-column align-items-md-start align-items-center">
                            <h1 className="mb-3">Become A Mentor</h1>
                            <p className="text-center text-md-start">
                                Work at the most dynamic and successful agency
                            </p>
                            <button className="mt-4 btn px-4 py-2 shadow rounded-5 border-0">
                                Apply for mentor
                            </button>
                        </div>
                        <div>
                            <img src={job} alt="" />
                        </div>
                    </div>

                    <Row className="align-items-center px-md-5 px-3 mt-md-5">
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <div className="shadow rounded-4 p-3">
                                        <GiOfficeChair className="fs-1 text_yellow mb-2" />
                                        <h6 className="fw-bold">Large Beautiful Office</h6>
                                        <p className="m-0 small_text">
                                            Enjoy a comfortable office environment with the most modern
                                            and stylish furnishing.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} className="mt-md-0 mt-4">
                                    <div className="shadow rounded-4 p-3">
                                        <IoLocation className="fs-1 text_yellow mb-2" />
                                        <h6 className="fw-bold">Easy Location</h6>
                                        <p className="m-0 small_text">
                                            Enjoy a comfortable office environment with the most modern
                                            and stylish furnishing.
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={6}>
                                    <div className="shadow rounded-4 p-3">
                                        <MdCastForEducation className="fs-1 text_yellow mb-2" />
                                        <h6 className="fw-bold">Education Opportunity</h6>
                                        <p className="m-0 small_text">
                                            Enjoy a comfortable office environment with the most modern
                                            and stylish furnishing.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={6} className="mt-md-0 mt-4">
                                    <div className="shadow rounded-4 p-3">
                                        <FaAward className="fs-1 text_yellow mb-2" />
                                        <h6 className="fw-bold">Performance Award</h6>
                                        <p className="m-0 small_text">
                                            Enjoy a comfortable office environment with the most modern
                                            and stylish furnishing.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} className="px-4 mt-md-0 mt-4">
                            <h3 className="fw-bold">Join Our Team</h3>
                            <p>
                                There are many variations of passages of Lorem Ipsum available,
                                but the majority have suffered alteration in some form, by
                                injected humour, or randomised words which don't look even
                                slightly believable. If you are going to use a passage of Lorem
                                Ipsum, you need to be sure there isn't any
                            </p>
                            <p>
                                ul of model sentence structures, to generate Lorem Ipsum which
                                looks reasonable. The generated Lorem Ipsum is therefore always
                                free from repetition, injected humour, or non-characteristic words
                                etc.
                            </p>
                            <button className="mt-4 btn px-4 py-2 shadow rounded-5 border-0">
                                Learn More
                            </button>
                        </Col>
                    </Row>
                    <br />
                    <Container className='mt-5'>
                        <center><h4 className='testimonial-heading'>WHAT CLIENTS SAY</h4></center>
                        <center><div className='horizontal-hr-testimonial'>&nbsp;</div></center>
                        <center>
                            <p className='testimonials-peragraph mt-3'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Non sed aspernatur molestiae. Ea animi ullam repellendus alias, culpa itaque delectus dolorem blanditiis praesentium perspiciatis molestiae iste ipsum consequuntur sunt commodi!</p>
                        </center>
                    </Container>

                    <Container className='mt-5'>
                        <Row>
                            <Col md={4} className='p-5'>
                                <div className="testimonial-cards-item shadow rounded-2">
                                    <div className="testimonial-cards-top-img">
                                        <img src={user_1} alt="" />
                                    </div>
                                    <br />
                                    <br />
                                    <center><h5>DIANNA KIMWEALTH</h5></center>
                                    <p className="profession-here">Robo Constuction</p>
                                    <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                                </div>
                            </Col>
                            <Col md={4} className='p-5'>
                                <div className="testimonial-cards-item shadow rounded-2">
                                    <div className="testimonial-cards-top-img">
                                        <img src={user_2} alt="" />
                                    </div>
                                    <br />
                                    <br />
                                    <center><h5>DIANNA KIMWEALTH</h5></center>
                                    <p className="profession-here">Robo Constuction</p>
                                    <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                                </div>
                            </Col>
                            <Col md={4} className='p-5'>
                                <div className="testimonial-cards-item shadow rounded-2">
                                    <div className="testimonial-cards-top-img">
                                        <img src={user_3} alt="" />
                                    </div>
                                    <br />
                                    <br />
                                    <center><h5>DIANNA KIMWEALTH</h5></center>
                                    <p className="profession-here">Robo Constuction</p>
                                    <p className='text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio deserunt quas laborum rem. Doloremque, dolores?</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Footer />
                </CareerContainer>
            </div>
        </>
    )
}


const CareerContainer = styled.div`
  margin-top: 6vmax;
  overflow-x: hidden;
  .job_sec {
    background: #f9fdff;
  }
  .btn {
    background: var(--yellow-color);
  }
  .btn2 {
    border: 2px solid var(--yellow-color);
    background: transparent;
  }

  @media (max-width: 768px) {
    margin-top: 15vmax;
  }
`;

export default BecomeAMentore