// Behaviour
import AllDemoPage from "./AllDemoPage";

export const AllDemoPageConfig = {
  routes: [
    {
      path: "/operation/AllDemo",
      exact: true,
      component: AllDemoPage
    }
  ]
};
