import StudentsManagement from "./StudentsManagement";

export const StudentsManagementPageConfig = {
  routes: [
    {
      path: "/teacher/StudentsManagement",
      exact: true,
      component: StudentsManagement
    }
  ]
};
