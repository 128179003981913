import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/axios.js";
import Navbar from "../../components/Navbar.js";
import "../../assets/css/parent/SignIn.css";
import { useDispatch, useSelector } from "react-redux";
import { storeRole, storeToken } from "../../slices/tokenSlice.js";
import { MyAPI } from "../../utils/MyAPI.js";
import Footer from "../../components/Footer.js";

function SignIn() {
  const [loginType, setLoginType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const role = useSelector((state) => state.token.role);
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    if (role === "teacher") {
      navigate("/teacher/registration");
      return;
    }

    if (role === "parent") {
      navigate("/parent/registration");
      return;
    }

    if (token && role) {
      navigate(`/${role}/dashboard`);
    }
  }, [role, token]);

  const handleParentLogin = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/parent/login", { email, password });
      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        sessionStorage.setItem("parentToken", res.data.data.token);
        dispatch(storeToken(res.data.data.token));
        dispatch(storeRole("parent"));
        navigate("/parent/registration");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleTeacherLogin = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/teacher/login", { email, password });
      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        sessionStorage.setItem("teacherToken", res.data.data.token);
        dispatch(storeToken(res.data.data.token));
        dispatch(storeRole("teacher"));
        navigate("/teacher/registration");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleAdminLogin = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/admin/login", {
        username: email,
        password: password,
      });

      // console.log(res.data);
      if (res.data.res_type === "success") {
        toast.success(res.data.message);
        localStorage.setItem("adminToken", res.data.data);
        sessionStorage.setItem("adminToken", res.data.data);
        dispatch(storeToken(res.data.data));
        dispatch(storeRole("admin"));
        navigate("/admin/dashboard");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleOperationLogin = async () => {
    try {
      setLoading(true);
      const res = await MyAPI.POST("/operation/login", {
        email,
        password,
      });
      let {
        res_type,
        message,
        error,
        data: resData,
        employee,
      } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        sessionStorage.setItem("operationToken", resData.token);
        dispatch(storeToken(resData.token));
        dispatch(storeRole(resData?.employee?.role));
        navigate("/operation/dashboard");
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAccountLogin = async () => {
    try {
      setLoading(true);
      const res = await MyAPI.POST("/account/login", {
        email,
        password,
      });
      let {
        res_type,
        message,
        error,
        data: resData,
        employee,
      } = res.data || res;
      if (res_type === "success") {
        toast.success(message);
        sessionStorage.setItem("accountToken", resData.token);
        dispatch(storeToken(resData.token));
        dispatch(storeRole(resData?.employee?.role));
        navigate("/account/dashboard");
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    if (!loginType) {
      toast.error("Please select login type");
    } else if (!email) {
      toast.error("Please enter email");
    } else if (!password) {
      toast.error("Please enter password");
    } else if (loginType === "Parent") {
      handleParentLogin();
    } else if (loginType === "Teacher") {
      handleTeacherLogin();
    } else if (loginType === "Admin") {
      handleAdminLogin();
    } else if (loginType === "Operation") {
      handleOperationLogin();
    } else if (loginType === "Account") {
      handleAccountLogin();
    } else {
      toast.error("Please select valid login type");
    }
  };

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className="" style={{ overflowY: "hidden" }}>
        <Navbar />
        <br />
        <br />
        <br />
        <Container className="mt-5 d-flex align-items-center justify-content-center p-2">
          <Row>
            <Col></Col>
            <Col
              md={5}
              className="login-container-semi-p shadow mt-3 p-5 pt-4 pb-2 border-0"
              style={{ overflowY: "hidden" }}
            >
              <Row>
                <Col md={12}>
                  <center>
                    <h2>Sign-In</h2>
                  </center>
                </Col>
                {/* dropdown for login as a parent or teacher or employee */}
                <Col md={12} className="mt-3 mb-3">
                  <Form.Select
                    className="shadow rounded-2 border-2"
                    value={loginType}
                    onChange={(e) => setLoginType(e.target.value)}
                    required
                  >
                    <option>Login As</option>
                    <option value="Parent">Parent</option>
                    <option value="Teacher">Teacher</option>
                    <option value="Account">Account</option>
                    <option value="Operation">Operation</option>
                    <option value="Admin">Admin</option>
                  </Form.Select>
                </Col>

                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      required
                      className="shadow rounded-2 border-2"
                      type="email"
                      placeholder="Enter Email Or Phone Number"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <Form.Group controlId="nameInput">
                    <Form.Control
                      required
                      className="shadow rounded-2 border-2"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" required label="Remember Me" />
                  </Form.Group>
                </Col>
                <Row className="px-2">
                  <Col sm={12} md={12} className="text-start">
                    Don't have a account?{" "}
                    <Link className="text-primary" to={"/parent/sign-up"}>
                      sign-up
                    </Link>{" "}
                  </Col>
                  <Col sm={12} md={12} className="text-start text-primary mt-2">
                    {" "}
                    <Link
                      className="text-primary"
                      to={"/parent/forgot-password"}
                    >
                      Reset Password?
                    </Link>{" "}
                  </Col>
                </Row>
              </Row>
              <Button
                onClick={handleLogin}
                style={{ width: "100%" }}
                className="p-2 bg-dark text-white border-0"
              >
                {loading ? "Loading..." : "Login"}
              </Button>
              <Button
                style={{ width: "100%", border: "1px solid #000" }}
                className="p-2 bg-transparent text-black"
              >
                Login With Google
              </Button>
              <Button
                style={{ width: "100%" }}
                className="p-2 bg-primary text-white border-0"
              >
                Login With Facebook
              </Button>
              {/* <center><p className="text-primary">need help ?</p></center> */}
              <center>
                <p className="text-primary">&nbsp;</p>
              </center>
            </Col>
            <Col></Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default SignIn;
