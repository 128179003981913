import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { FaUsers } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const VacencyPage = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <OperationLayout>
      <h4>Public vacancy</h4>

      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          <button
            onClick={handleShow}
            className="btn rounded-5 px-3 text-white fw-semibold"
            style={{ background: "var(--color-primary)" }}
          >
            Add Vacancy
          </button>
        </Col>
      </Row>

      <h5 className="my-4">Past applied vacancies</h5>

      <Table
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
          color: "#fff",
        }}
        className="mt-4"
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">#</th>
            <th className="text-white">Vacancy Title</th>
            <th className="text-white">Description</th>
            <th className="text-white">Type</th>
            <th className="text-white">Deadline</th>
            <th className="text-white">Salary</th>
            <th className="text-white">Experience</th>
            <th className="text-white">Applicant</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light text-center">
            <td>1</td>
            <td>Frontend Developer</td>
            <td>Frontend Developer</td>
            <td>Permanent</td>
            <td>01/01/2021</td>
            <td>30K/Month</td>
            <td>1+ year</td>
            <td>
              {" "}
              <FaUsers
                className="cursor-pointer"
                onClick={() => navigate("/operation/Vacancy/142512001")}
                size={22}
              />
            </td>
            <td>
              <button className="btn btn-sm btn-danger rounded-4 px-3 fw-semibold">
                Delete
              </button>
            </td>
          </tr>
          <tr className="bg-light text-center">
            <td>2</td>
            <td>Backend Developer</td>
            <td>Backend Developer</td>
            <td>Part-Time</td>

            <td>01/01/2021</td>
            <td>30K/Month</td>
            <td>1+ year</td>
            <td>
              {" "}
              <FaUsers
                className="cursor-pointer"
                onClick={() => navigate("/operation/Vacancy/142512001")}
                size={22}
              />
            </td>
            <td>
              <button className="btn btn-sm btn-danger rounded-4 px-3 fw-semibold">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose} centered style={{ zIndex: 9999 }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Vacancy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Vacancy Title
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>

            <div className="row">
              <div className="col-6">
                {" "}
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Vacancy Type
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">Permanent</option>
                    <option value="2">Part-Time</option>
                    <option value="3">Contract</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                {" "}
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Vacancy Deadline
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Vacancy Salary
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Vacancy Experience
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn rounded-5 text-white fw-semibold"
            style={{ background: "var(--color-primary)" }}
          >
            Add Vacancy
          </button>
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

export default VacencyPage;
