/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  InputGroup,
  Container,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import "./StudentsManagement.css";
import UserProfile from "../../assets/user_1.jpg";
import CardCourse from "../courseManagement/CardCourse";
import card1 from "../../assets/parent/card1.png";
import ParentFooter from "../../components/ParentFooter";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa6";

const StudentsManagement = (props) => {
  const { history } = props;
  const [profileOpen, setProfileopen] = useState(false);

  const [showModelPerformance, setShowModelPerformance] = useState(false);

  const handleCloseModelPerformance = () => setShowModelPerformance(false);
  const handleShowModelPerformance = () => setShowModelPerformance(true);

  const navigate = useNavigate();

  const navigateToAttendance = () => {
    navigate("/teacher/student/attendance/142512001");
  };
  return (
    <TeacherLayout>
      <h3>Students Management</h3>

      {/* <OftadehBreadcrumbs path={history} /> */}

      {!profileOpen && (
        <>
          <Row className="mt-4 d-flex align-items-center justify-content-around">
            <Col md={3}>
              <div className="rounded-4 bg-white shadow p-3 d-flex flex-column align-items-center justify-content-center">
                <h3>16</h3>
                <h5>Current Students</h5>
              </div>
            </Col>
            <Col md={3}>
              <div className="rounded-4 bg-white shadow p-3 d-flex flex-column align-items-center justify-content-center">
                <h3>23</h3>
                <h5>Active Students</h5>
              </div>
            </Col>
            <Col md={3}>
              <div className="rounded-4 bg-white shadow p-3 d-flex flex-column align-items-center justify-content-center">
                <h3>564</h3>
                <h5>Old Students</h5>
              </div>
            </Col>
            <Col md={3}>
              <div className="rounded-4 bg-white shadow p-3 d-flex flex-column align-items-center justify-content-center">
                <h3>67</h3>
                <h5>Lost Students</h5>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 mb-3 d-flex align-items-center justify-content-center">
            <Col md={6} className="pt-3">
              <h4>Students</h4>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="form-group col-md-3 mt-3">
                <select
                  className="form-control"
                  style={{ width: "100%", height: "6vh" }}
                  id="gender"
                >
                  <option value="" disabled selected>
                    Active Students
                  </option>
                  <option value="On-hold">On-hold</option>
                  <option value="Discontinued">Discontinued</option>
                </select>
              </div>
              <div className="form-group ms-2 col-md-6 mt-3">
                <Form.Group controlId="searchInput">
                  <InputGroup>
                    <Form.Control type="text" placeholder="Search" />
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Student Id</th>
                <th>Name</th>
                <th>Age</th>
                <th>gender</th>
                <th>subject/course</th>
                <th>classes/enrolled</th>
                <th>Cycle Count</th>
                <th>classes left</th>
                <th>date of enrollment</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>stu1#dent23</td>
                <td>Benerjee</td>
                <td>16</td>
                <td>Male</td>
                <td>XYZ</td>
                <td>12</td>
                <td>3/6</td>
                <td>2</td>
                <td>12/03/24</td>
                <td
                  onClick={() => setProfileopen(true)}
                  className="student-managment-teacher-view-btn"
                >
                  <center>view</center>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>stu1#dent23</td>
                <td>Benerjee</td>
                <td>16</td>
                <td>Male</td>
                <td>XYZ</td>
                <td>12</td>
                <td>3/6</td>
                <td>2</td>
                <td>12/03/24</td>
                <td
                  onClick={() => setProfileopen(true)}
                  className="student-managment-teacher-view-btn"
                >
                  <center>view</center>
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>stu1#dent23</td>
                <td>Benerjee</td>
                <td>16</td>
                <td>Male</td>
                <td>XYZ</td>
                <td>12</td>
                <td>3/6</td>
                <td>2</td>
                <td>12/03/24</td>
                <td
                  onClick={() => setProfileopen(true)}
                  className="student-managment-teacher-view-btn"
                >
                  <center>view</center>
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>stu1#dent23</td>
                <td>Benerjee</td>
                <td>16</td>
                <td>Male</td>
                <td>XYZ</td>
                <td>12</td>
                <td>3/6</td>
                <td>2</td>
                <td>12/03/24</td>
                <td
                  onClick={() => setProfileopen(true)}
                  className="student-managment-teacher-view-btn"
                >
                  <center>view</center>
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>stu1#dent23</td>
                <td>Benerjee</td>
                <td>16</td>
                <td>Male</td>
                <td>XYZ</td>
                <td>12</td>
                <td>3/6</td>
                <td>2</td>
                <td>12/03/24</td>
                <td
                  onClick={() => setProfileopen(true)}
                  className="student-managment-teacher-view-btn"
                >
                  <center>view</center>
                </td>
              </tr>
            </tbody>
          </Table>

          <Row className="mt-3 mb-3 d-flex align-items-center justify-content-center">
            <Col md={6} className="pt-3">
              <h4>Students Attendance (By Group Classes)</h4>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <div className="form-group ms-2 col-md-6 mt-3">
                <Form.Group controlId="searchInput">
                  <InputGroup>
                    <Form.Control type="text" placeholder="Search" />
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Course Start Date</th>
                <th>No. of Student Enrolled</th>
                <th>No. of Class Taken Till Now</th>
                <th>Average Attendance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample Data */}
              <tr>
                <td>1.</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>10</td>
                <td>13</td>
                <td>09</td>
                <td>
                  <Button
                    onClick={navigateToAttendance}
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </Table>

          <Row className="mt-3 mb-3 d-flex align-items-center justify-content-center">
            <Col md={6} className="pt-3">
              <h4>Students Attendance (By 1-On-1 Classes)</h4>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <div className="form-group ms-2 col-md-6 mt-3">
                <Form.Group controlId="searchInput">
                  <InputGroup>
                    <Form.Control type="text" placeholder="Search" />
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Course Start Date</th>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>No. of Class</th>
                <th>No. of Attendance</th>
                <th>No Show</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample Data */}
              <tr>
                <td>1.</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>Stu23@#fhj</td>
                <td>John Doe</td>
                <td>10</td>
                <td>13</td>
                <td>--</td>
                <td>
                  <Button
                    onClick={() =>
                      navigate("/teacher/StudentsManagement/Attendance/1-on-1")
                    }
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>Stu23@#fhj</td>
                <td>John Doe</td>
                <td>10</td>
                <td>13</td>
                <td>--</td>
                <td>
                  <Button
                    onClick={() =>
                      navigate("/teacher/StudentsManagement/Attendance/1-on-1")
                    }
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Co@13#$fj</td>
                <td>Mathematics</td>
                <td>12 May 2024</td>
                <td>Stu23@#fhj</td>
                <td>John Doe</td>
                <td>10</td>
                <td>13</td>
                <td>--</td>
                <td>
                  <Button
                    onClick={() =>
                      navigate("/teacher/StudentsManagement/Attendance/1-on-1")
                    }
                    variant="primary"
                    size="sm"
                  >
                    <FaEye />
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>

          <Row className="mt-3 mb-3 d-flex align-items-center justify-content-center">
            <Col md={6} className="pt-3">
              <h4>Students Cancellation </h4>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <div className="form-group ms-2 col-md-6 mt-3">
                <Form.Group controlId="searchInput">
                  <InputGroup>
                    <Form.Control type="text" placeholder="Search" />
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Course ID</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>No. of Class Attendance</th>
                <th>No. of Students Not Attendance</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {/* Sample Data */}
              <tr>
                <td>12345</td>
                <td>101</td>
                <td>2024-05-01</td>
                <td>2024-07-01</td>
                <td>20</td>
                <td>5</td>
                <td>On Hold</td>
              </tr>
              <tr>
                <td>67890</td>
                <td>102</td>
                <td>2024-06-01</td>
                <td>2024-08-01</td>
                <td>18</td>
                <td>7</td>
                <td>On Hold</td>
              </tr>
              <tr>
                <td>23456</td>
                <td>103</td>
                <td>2024-07-01</td>
                <td>2024-09-01</td>
                <td>15</td>
                <td>10</td>
                <td>Cancelled</td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </Table>
        </>
      )}

      {profileOpen && (
        <>
          <Container className="mt-3 p-3 n-box-shadow rounded-2">
            <Row className="mt-0 p-3">
              <Col
                md={3}
                className="d-flex align-items-start justify-content-center"
              >
                <div className="student-managent-student-profile n-box-shadow">
                  <img src={UserProfile} alt="" />
                </div>
              </Col>
              <Col md={9} className="mt-3">
                <Row>
                  <Col md={6} className="mt-1">
                    <b>Name : </b> John Smith{" "}
                    <button
                      onClick={() => navigate("/teacher/ParentProfile")}
                      className="btn btn-sm ms-3 rounded-5 px-3 text-white"
                      style={{ background: "var(--color-primary)" }}
                    >
                      {" "}
                      View
                    </button>
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Age : </b> 18 Years
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Gender : </b> Male
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Standard : </b> 8 <sup>th</sup>{" "}
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Subjects : </b> Hindi , English
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Learning Defficulties : </b> Hindi , English
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Age : </b> 18 Years
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Grade : </b> 8
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>Board : </b> CBSE
                  </Col>
                  <Col md={6} className="mt-1">
                    <b>School Name : </b> Lorem ipsum dolor sit amet.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Row className="mt-5 mb-3">
            <h5>Subjects/Courses Enrolled:</h5>

            <Col md={4} className="mt-4">
              <CardCourse
                img={card1}
                head={"Learn Mandarin - Beginner Level"}
                date={"16 - 18 Jul 2023 "}
                students={"26 students enrolled"}
                viewHW={"View Hw and Test"}
                joinClass={"Join Class"}
                feedbackP={"Provide feedback"}
                viewDetails={true}
              />
            </Col>
            <Col md={4} className="mt-4">
              <CardCourse
                img={card1}
                head={"Learn Mandarin - Beginner Level"}
                date={"16 - 18 Jul 2023 "}
                students={"26 students enrolled"}
                viewHW={"View Hw and Test"}
                joinClass={"Join Class"}
                feedbackP={"Provide feedback"}
                viewDetails={true}
              />
            </Col>
            <Col md={4} className="mt-4">
              <CardCourse
                img={card1}
                head={"Learn Mandarin - Beginner Level"}
                date={"16 - 18 Jul 2023 "}
                students={"26 students enrolled"}
                viewHW={"View Hw and Test"}
                joinClass={"Join Class"}
                feedbackP={"Provide feedback"}
                viewDetails={true}
              />
            </Col>
          </Row>

          <h2 className="text-center mb-4 mt-5">Student Performance</h2>
          <h4 className="mb-3">By Test:</h4>
          <Row className="justify-content-center">
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Course ID</th>
                        <th>Course Name</th>
                        <th>Student Name</th>
                        <th>Test Topic</th>
                        <th>Assign Date</th>
                        <th>Date of Submission</th>
                        <th>Total Marks</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>C@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                            onClick={handleShowModelPerformance}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>C@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            onClick={handleShowModelPerformance}
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>C@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            onClick={handleShowModelPerformance}
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <h4 className="mb-3 mt-5">By HomeWork:</h4>
          <Row className="justify-content-center">
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>Course Id</th>
                        <th>Course Name</th>
                        <th>Student Name</th>
                        <th>HomeWork Topic</th>
                        <th>Assign Date</th>
                        <th>Date of Submission</th>
                        <th>Total Marks</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>HW@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            onClick={handleShowModelPerformance}
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>HW@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            onClick={handleShowModelPerformance}
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>HW@3546ID</td>
                        <td>Mathematics</td>
                        <td>Anu</td>
                        <td>Algebra</td>
                        <td>12/03/24</td>
                        <td>20/40</td>
                        <td>7/12</td>
                        <td>
                          <button
                            onClick={handleShowModelPerformance}
                            className="btn btn-sm rounded-5"
                            style={{
                              backgroundColor: "var(--color-primary)",
                              color: "white",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Request />

      <Modal
        show={showModelPerformance}
        onHide={handleCloseModelPerformance}
        centered
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Performance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* show details */}

          <div className="row px-3 mt-4">
            <div className="row">
              <div className="col-6">
                <h5 className="mb-3">Student Name</h5>
                <p>Student Roll No.</p>
                <p>Course ID</p>
                <p>Course Name</p>
                <p>Topic</p>
                <p>Assign Date</p>
                <p>Date of Submission</p>
                <p>Total Marks</p>
              </div>
              <div className="col-6">
                <h5 className="mb-3">Anu Choudhary</h5>
                <p>12345</p>
                <p>C@3546ID</p>
                <p>Mathematics</p>
                <p>Algebra</p>
                <p>12/03/24</p>
                <p>15/03.24</p>
                <p>7/12</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModelPerformance}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </TeacherLayout>
  );
};

export default StudentsManagement;

const Request = () => {
  const [demoRequests, setDemoRequests] = useState([
    { id: 1, kidName: "John Doe", date: "2024-02-10", status: "Pending" },
    { id: 2, kidName: "Jane Doe", date: "2024-02-15", status: "Pending" },
  ]);

  const [previousDemos, setPreviousDemos] = useState([
    { id: 3, kidName: "Alex Smith", date: "2024-01-20", result: "Good" },
    { id: 4, kidName: "Emma Johnson", date: "2024-01-25", result: "Excellent" },
  ]);

  const [showDemoModal, setShowDemoModal] = useState(false);
  const [showModalReschedule, setShowModalReschedule] = useState(false);
  const [selectedDemo, setSelectedDemo] = useState(null);

  const handleAcceptDemo = (demoId) => {
    // Implement logic to accept demo (update status, schedule, etc.)
    console.log(`Accepted demo with ID: ${demoId}`);
  };

  const handleRescheduleDemo = (demoId) => {
    // Implement logic to reschedule demo
    console.log(`Rescheduled demo with ID: ${demoId}`);
    setShowModalReschedule(true);
  };

  const handleShowDemoDetails = (demo) => {
    setSelectedDemo(demo);
    setShowDemoModal(true);
  };

  const handleShowModalReschedule = () => {
    setShowModalReschedule(true);
  };

  const handleCloseModalReschdule = () => {
    setShowModalReschedule(false);
  };

  const handleCloseDemoModal = () => {
    setShowDemoModal(false);
  };

  return (
    <Container className="mt-4m p-0">
      <br />
      {/* <h4 className="text-start mb-4">Demo Requests</h4> */}
      <Row className="justify-content-center">
        <Col md={12}>
          {/* <Card>
            <Card.Body>
              <h5 className="mb-3">Pending Demo Requests</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Kid Name</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {demoRequests.map((demo) => (
                    <tr key={demo.id}>
                      <td>{demo.id}</td>
                      <td>{demo.kidName}</td>
                      <td>{demo.date}</td>
                      <td>{demo.status}</td>
                      <td>
                        <button
                          className="border-0 bg-yellow rounded-5 text-white py-2 px-4 me-3"
                          variant="success"
                          onClick={() => handleAcceptDemo(demo.id)}
                        >
                          Accept
                        </button>
                        <button
                          className="border-yellow bg-transparent text_yellow rounded-5 py-2 px-4"
                          variant="info"
                          onClick={() => handleRescheduleDemo(demo.id)}
                        >
                          Reschedule
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card> */}

          <Card className="mt-4">
            <Card.Body>
              <h5 className="mb-3">Previous Demos</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Kid Name</th>
                    <th>Date</th>
                    <th>Result</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {previousDemos.map((demo) => (
                    <tr key={demo.id}>
                      <td>{demo.id}</td>
                      <td>{demo.kidName}</td>
                      <td>{demo.date}</td>
                      <td>{demo.result}</td>
                      <td>
                        <button
                          className="border-0 bg-yellow rounded-5 text-white py-2 px-4 me-3"
                          variant="info"
                          onClick={() => handleShowDemoDetails(demo)}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Demo Details Modal */}
      <Modal
        show={showDemoModal}
        onHide={handleCloseDemoModal}
        centered
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Demo Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDemo && (
            <>
              <p>
                <strong>Kid Name:</strong> {selectedDemo.kidName}
              </p>
              <p>
                <strong>Date:</strong> {selectedDemo.date}
              </p>
              <p>
                <strong>Result:</strong> {selectedDemo.result}
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDemoModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalReschedule}
        onHide={handleCloseModalReschdule}
        centered
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="rescheduleDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
          <Form.Group className="mt-2" controlId="rescheduleTime">
            <Form.Label>Time</Form.Label>
            <Form.Control type="time" />
          </Form.Group>
          <Form.Group className="mt-2" controlId="rescheduleNote">
            <Form.Label>Note</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalReschdule}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ParentFooter />
    </Container>
  );
};
