import React from "react";
import { Badge, Col, Container, Form, Row, Table } from "react-bootstrap";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";

const data = [
  {
    id: 1,
    coueseId: "course@123",
    courseName: "Maths",
    teacherId: "teacher@123",
    teacherName: "Mr. John",
    noOfHomeWorkGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
    noOfTestGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
  },
  {
    id: 2,
    coueseId: "course@123",
    courseName: "Science",
    teacherId: "teacher@123",
    teacherName: "Mr. John",
    noOfHomeWorkGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
    noOfTestGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
  },
  {
    id: 3,
    coueseId: "course@123",
    courseName: "English",
    teacherId: "teacher@123",
    teacherName: "Mr. John",
    noOfHomeWorkGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
    noOfTestGiven: {
      total: 12,
      pending: 2,
      completed: 10,
    },
  },
];

function HomeWorkWithID() {
  return (
    <>
      <OperationLayout>
        <Row className="justify-content-between">
          <Col md={4}>
            <Form.Group controlId="selectOption">
              <Form.Select className="shadow rounded-3 mt-3">
                <option selected disabled>
                  select home work type
                </option>
                <option value={"1"}>Done</option>
                <option value={"2"}>Pending</option>
                <option value={"2"}>Completed</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mt-3">
            <Form.Group controlId="nameInput">
              <Form.Control
                className="shadow rounded-3"
                type="text"
                placeholder="Search..."
              />
            </Form.Group>
          </Col>
        </Row>

        <Container className="mt-4">
          <Table
            className="mt-4 mb-3 rounded-4"
            striped
            responsive
            hover
            style={{ backgroundColor: "var(--color-primary)" }}
          >
            <thead>
              <tr className="text-center">
                <th className="text-white">S. No.</th>
                <th className="text-white">Course ID</th>
                <th className="text-white">Course Name</th>
                <th className="text-white">Teacher ID</th>
                <th className="text-white">Teacher Name</th>
                <th className="text-white">Total Home Work</th>
                <th className="text-white">Total Test</th>
                <th className="text-white" colSpan={2}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="text-center bg-light" key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.coueseId}</td>
                  <td>{item.courseName}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.teacherName}</td>
                  <td>
                    <Badge bg="primary" style={{ width: "100%" }}>
                      Total: {item.noOfHomeWorkGiven.total}
                    </Badge>{" "}
                    <br />
                    <Badge bg="warning" style={{ width: "100%" }}>
                      Pending: {item.noOfHomeWorkGiven.pending}
                    </Badge>{" "}
                    <br />
                    <Badge bg="success" style={{ width: "100%" }}>
                      Completed: {item.noOfHomeWorkGiven.completed}
                    </Badge>
                  </td>
                  <td>
                    <Badge style={{ width: "100%" }} bg="primary">
                      Total: {item.noOfTestGiven.total}
                    </Badge>{" "}
                    <br />
                    <Badge style={{ width: "100%" }} bg="warning">
                      Pending: {item.noOfTestGiven.pending}
                    </Badge>{" "}
                    <br />
                    <Badge style={{ width: "100%" }} bg="success">
                      Completed: {item.noOfTestGiven.completed}
                    </Badge>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm"
                      style={{
                        backgroundColor: "var(--color-primary)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      View HomeWork
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm "
                      style={{
                        backgroundColor: "var(--color-primary)",
                        color: "white",
                        border: "none",
                      }}
                    >
                      View Test
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>

        {/* <Container className="mt-3 border rounded-3 border-success shadow">
          <Row className="ps-3 pb-2 pt-2">
            <Col md={9}>
              <Col
                className="class-assisment mt-2"
                style={{ fontSize: "18px" }}
              >
                Class Assisment 4{" "}
              </Col>
              <Col className="class-assisment mt-1">Submitted at 11:30 pm </Col>
              <Col
                className="class-assisment mt-1"
                style={{ fontSize: "20px" }}
              >
                Introduction to Optics{" "}
              </Col>
              <Col className="class-assisment mt-1 mb-2">
                <b>status : </b>{" "}
                <Badge className="p-1" bg="success">
                  Submited
                </Badge>{" "}
              </Col>
            </Col>
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end pe-5"
            >
              <span style={{ fontSize: "22px" }}>30 points</span>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3 border rounded-3 border-warning shadow">
          <Row className="ps-3 pb-2 pt-2">
            <Col md={9}>
              <Col
                className="class-assisment mt-2"
                style={{ fontSize: "18px" }}
              >
                Class Assisment 4{" "}
              </Col>
              <Col className="class-assisment mt-1">Pending at 11:30 pm </Col>
              <Col
                className="class-assisment mt-1"
                style={{ fontSize: "20px" }}
              >
                Introduction to Optics{" "}
              </Col>
              <Col className="class-assisment mt-1 mb-2">
                <b>status : </b>{" "}
                <Badge className="p-1" bg="warning">
                  Pending
                </Badge>{" "}
              </Col>
            </Col>
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end pe-5"
            >
              <span style={{ fontSize: "22px" }}>30 points</span>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3 border rounded-3 border-danger shadow">
          <Row className="ps-3 pb-2 pt-2">
            <Col md={9}>
              <Col
                className="class-assisment mt-2"
                style={{ fontSize: "18px" }}
              >
                Class Assisment 4{" "}
              </Col>
              <Col className="class-assisment mt-1">
                Due at 12 January 2023 11:30 pm{" "}
              </Col>
              <Col
                className="class-assisment mt-1"
                style={{ fontSize: "20px" }}
              >
                Introduction to Optics{" "}
              </Col>
              <Col className="class-assisment mt-1 mb-2">
                <b>status : </b>{" "}
                <Badge className="p-1" bg="danger">
                  Due 12 January, 2023
                </Badge>{" "}
              </Col>
            </Col>
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end pe-5"
            >
              <span style={{ fontSize: "22px" }}>30 points</span>
            </Col>
          </Row>
        </Container> */}
      </OperationLayout>
    </>
  );
}

export default HomeWorkWithID;
