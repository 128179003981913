import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { IoMdArrowUp } from "react-icons/io";
import card3 from "../../assets/parent/card3.png";
import { FaAngleRight } from "react-icons/fa6";
import { Button } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";
import Card3 from "../../ParentPages/parent/Card3";

const TranscriptPage = (props) => {
  const { history } = props;
  const [showModal, setShowModal] = useState(false);
  const [reportText, setReportText] = useState('');

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleReportTextChange = (e) => setReportText(e.target.value);

  const handleReportSubmit = () => {
    // Handle report submission here
    console.log('Report submitted:', reportText);
    // You can add your logic to handle the report submission
    // For now, let's just close the modal
    handleCloseModal();
  };

  const [showModalVideo, setShowModalVideo] = useState(false);

  const handleVideoClose = () => setShowModalVideo(false);
  const handleVideoShow = () => setShowModalVideo(true);


  return (
    <TeacherLayout>
      <h3>Transcript & Recording</h3>
      
      <Row>
        <Col md={4}>
          <Form.Group controlId="nameInput">
            <Form.Control className="shadow rounded-5" type="text" placeholder="Search" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="nameInput">
            <Form.Control className="shadow rounded-5" type="date" />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="selectOption">
            <Form.Select className="shadow rounded-5">
              <option disabled value="option1">Select transcript</option>
              <option value="option2">All</option>
              <option value="option3">lecture-wise</option>
              <option value="option3">recent</option>
              <option value="option3">last month</option>
              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} style={{ zIndex:111111111111111, marginTop: '20vh' }}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Report Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col md={12} className='mt-3 mb-3'>
            <Form.Group controlId='nameInput'>
              <Form.Label>Line No.</Form.Label>
              <Form.Control
                type='text'
                placeholder='Line No.'
              />
            </Form.Group>
          </Col>
          <Form.Group controlId="reportTextArea">
            <Form.Label>Enter your report:</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Report..."
              rows={3}
              value={reportText}
              onChange={handleReportTextChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button className="bg-danger text-white border-0" variant="outlined" onClick={handleCloseModal}>
            Close
          </Button>
          <Button className="ms-3" variant="outlined" onClick={handleReportSubmit}>
            Submit Report
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="mt-3 pe-5">
        <Col md={6} className="mt-4">
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            // viewRecording={true}
            number={"3/12"}
          />
        </Col>
        <Col md={6} className="mt-4">
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
            // viewRecording={true}
          />
        </Col>
        <Col md={6} className="mt-4">
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
            // viewRecording={true}
          />
        </Col>
        <Col md={6} className="mt-4">
          <Card3
            img={card3}
            head={"Methematics"}
            time={"2pm - 4pm ist"}
            date={"13 June 2023"}
            name={"Vishal Seth"}
            view={"View Transcript"}
            viewIcon={<FaAngleRight />}
            number={"3/12"}
            // viewRecording={true}
          />
        </Col>
      </Row>
      <ParentFooter />
    </TeacherLayout>
  );
};

export default TranscriptPage;
