import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import "../assets/css/FAQs.css";

import { Col, Container, Row } from "react-bootstrap";
import Accordian from "../components/homepage/Accordian";
import Footer from "../components/Footer";
import HomeFaqImg from "../assets/home-faq-image.png";

function FAQs() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <Navbar />
        <br />
        <br />
        <br />
        <Container fluid className="home-faq-container mt-3">
          <Row>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div
                className=" d-flex align-items-start justify-content-center p-5"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <h5>FAQ's</h5>
                <p className="text-start" style={{ width: "80%" }}>
                  Have questions? Here You’ll find the answer most valued by our
                  partners, alongs with access to step-by-step instructions and
                  support.
                </p>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center home-faq-img-section"
            >
              <div className="home-faq-img-area">
                <img src={HomeFaqImg} className="" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="m-0 p-0">
          <Accordian isSearch={true} />
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default FAQs;
