import TeacherUnderReviewPage from "./UnderReview";

export const TeacherUnderReviewPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard/pages/under-review",
      exact: true,
      component: TeacherUnderReviewPage
    }
  ]
};
