import React from "react";
import { Col, Form, Row } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { TiArrowForward } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";

const CodePage = (props) => {
    const { history } = props;


    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h4>Coupon code</h4>
            <Row className="mb-3 mt-3">
                <Form.Group as={Col} md={4} controlId="codeName">
                    <Form.Label>Code Name</Form.Label>
                    <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Enter code name" />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="expiryDate">
                    <Form.Label>Expiry Date</Form.Label>
                    <Form.Control className="border-yellow shadow rounded-5" type="date" />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="priceCode">
                    <Form.Label>Price Code</Form.Label>
                    <Form.Control className="border-yellow shadow rounded-5" type="text" placeholder="Enter price code" />
                </Form.Group>
            </Row>
            <Row className="mb-3 mt-5 d-flex align-items-center justify-content-end">
                <Form.Group as={Col} md={4} controlId="couponCodeShare">
                    <Form.Control className="border-yellow shadow rounded-5" as="select">
                        <option disabled selected value=""> <TiArrowForward /> Share Coupon Code </option>
                        <option value="email">Email</option>
                        <option value="sms">SMS</option>
                        <option value="socialMedia">Social Media</option>
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <h5>Refferals</h5>
            </Row>
            <Row>
                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Teacher</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>

                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Parent</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>

                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Admin</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>

                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Teacher</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>

                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Parent</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>

                <Col md={4} className="mt-3">
                    <div className="d-flex align-items-center justify-content-between border border-yellow shadow rounded-3 p-4">
                        <Col md={10} className="text-center">
                            <h6>Admin</h6>
                        </Col>
                        <Col md={2}>
                            <h6><MdArrowForwardIos /></h6>
                        </Col>
                    </div>
                </Col>
                
            </Row>
        </OperationLayout>
    );
};

export default CodePage;
