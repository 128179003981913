import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const BestTeacher = ({ xAxisMax, yAxisData, initialData, name }) => {
    const chartRef = useRef(null);
    let myChart = useRef(null);

    useEffect(() => {
        myChart.current = echarts.init(chartRef.current);

        const option = {
            xAxis: {
                max: xAxisMax || 'dataMax'
            },
            yAxis: {
                type: 'category',
                data: yAxisData,
                inverse: true,
                animationDuration: 300,
                animationDurationUpdate: 300,
                max: 5 // only the largest 3 bars will be displayed
            },
            series: [
                {
                    realtimeSort: true,
                    name,
                    type: 'bar',
                    data: initialData,
                    label: {
                        show: true,
                        position: 'right',
                        valueAnimation: true
                    }
                }
            ],
            legend: {
                show: true
            },
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear'
        };

        myChart.current.setOption(option);

        return () => {
            myChart.current.dispose();
        };
    }, [xAxisMax, yAxisData, initialData]);

    const run = () => {
        const newData = initialData.map(value => {
            if (Math.random() > 0.9) {
                return value + Math.round(Math.random() * 2000);
            } else {
                return value + Math.round(Math.random() * 200);
            }
        });

        myChart.current.setOption({
            series: [
                {
                    type: 'bar',
                    data: newData
                }
            ]
        });
    };

    useEffect(() => {
        const interval = setInterval(run, 3000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};

export default BestTeacher;
