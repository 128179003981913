import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Paper } from "@material-ui/core";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const token = useSelector((state) => state.token.value);
  const [showModal, setShowModal] = useState(false);
  const [showModalReminder, setShowModalReminder] = useState(false);
  const [eventFormData, setEventFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  const [reminderFormData, setReminderFormData] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  const [myEventsList, setMyEventsList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const res = await axios.get("/parent/calendar-stamp", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data.data) {
          setMyEventsList(
            res.data.data.map((event) => ({
              ...event,
              start: new Date(event.start_datetime),
              end: new Date(event.end_datetime),
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, [token]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModalReminder = () => setShowModalReminder(true);
  const handleCloseModalReminder = () => setShowModalReminder(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventFormData({
      ...eventFormData,
      [name]: value,
    });
  };

  const handleReminderInputChange = (e) => {
    const { name, value } = e.target;
    setReminderFormData({
      ...reminderFormData,
      [name]: value,
    });
  };

  const handleDateChange = (field, date) => {
    setEventFormData({
      ...eventFormData,
      [field]: date,
    });
  };

  const handleReminderDateChange = (field, date) => {
    setReminderFormData({
      ...reminderFormData,
      [field]: date,
    });
  };

  const handleAddEvent = async () => {
    if (eventFormData.title === "") {
      toast.error("Please fill the title");
      return;
    }

    try {
      const res = await axios.post(
        "/parent/calendar-stamp",
        {
          stamp_type: "EVENT",
          title: eventFormData.title,
          start_datetime: eventFormData.start,
          end_datetime: eventFormData.end,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.res_type === "success") {
        const newEvent = {
          ...res.data.event,
          start: new Date(res.data.event.start_datetime),
          end: new Date(res.data.event.end_datetime),
        };
        setMyEventsList([...myEventsList, newEvent]);
      } else {
        console.log("Failed to add event");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Reset event form data
      setEventFormData({
        title: "",
        start: new Date(),
        end: new Date(),
      });
    }

    handleCloseModal();
  };

  const handleAddReminder = async () => {
    if (reminderFormData.title === "") {
      toast.error("Please fill the title");
      return;
    }

    try {
      const res = await axios.post(
        "/parent/calendar-stamp",
        {
          stamp_type: "REMINDER",
          title: reminderFormData.title,
          start_datetime: reminderFormData.start,
          end_datetime: reminderFormData.end,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.res_type === "success") {
        const newEvent = {
          ...res.data.event,
          start: new Date(res.data.event.start_datetime),
          end: new Date(res.data.event.end_datetime),
        };
        setMyEventsList([...myEventsList, newEvent]);
      } else {
        console.log("Failed to add reminder");
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Reset reminder form data
      setReminderFormData({
        title: "",
        start: new Date(),
        end: new Date(),
      });
    }

    handleCloseModalReminder();
  };

  const handleDeleteEvent = async () => {
    if (!selectedEvent) return;

    console.log("selectedEvent", selectedEvent);

    handleCloseDeleteModal();
  };

  const handleSelectEvent = (event) => {
    console.log("selected event", event);
    setSelectedEvent(event);
    handleShowDeleteModal();
  };

  return (
    <OftadehLayout>
      <h1>Calendar</h1>
      <button
        className="mb-3 py-2 px-4 rounded-5 border-0 bg-yellow new-button"
        onClick={handleShowModal}
      >
        Add Event
      </button>
      <button
        className="mb-3 ms-3 py-2 px-4 rounded-5 border-0 bg-yellow new-button"
        onClick={handleShowModalReminder}
      >
        Add Reminder
      </button>

      <Paper style={{ padding: "5px" }}>
        <Calendar
          localizer={localizer}
          events={myEventsList}
          startAccessor="start"
          endAccessor="end"
          defaultDate={new Date(2024, 4, 29)}
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
        />
      </Paper>

      <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent?.stamp_type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Title: <b>{selectedEvent?.title}</b>
          </p>
          <p>
            Start: <b>{moment(selectedEvent?.start_datetime).format("LLL")}</b>
          </p>
          <p>
            End: <b>{moment(selectedEvent?.end_datetime).format("LLL")}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteEvent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event title"
                name="title"
                value={eventFormData.title}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formStart">
              <Form.Label>Start Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                name="start"
                value={moment(eventFormData.start).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleDateChange("start", new Date(e.target.value))
                }
              />
            </Form.Group>
            <Form.Group controlId="formEnd">
              <Form.Label>End Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                name="end"
                value={moment(eventFormData.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleDateChange("end", new Date(e.target.value))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="me-3 py-2 px-4 rounded-5 color-yellow fw-bold bg-transparent"
            style={{ border: "1px solid var(--yellow-color)" }}
            onClick={handleCloseModal}
          >
            Close
          </button>
          <button
            className="py-2 px-4 rounded-5 border-0"
            style={{ background: "var(--yellow-color)" }}
            onClick={handleAddEvent}
          >
            Add Event
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalReminder}
        onHide={handleCloseModalReminder}
        centered
        style={{ zIndex: 99999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter reminder title"
                name="title"
                value={reminderFormData.title}
                onChange={handleReminderInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formStart">
              <Form.Label>Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                name="start"
                value={moment(reminderFormData.start).format(
                  "YYYY-MM-DDTHH:mm"
                )}
                onChange={(e) =>
                  handleReminderDateChange("start", new Date(e.target.value))
                }
              />
            </Form.Group>
            <Form.Group controlId="formEnd">
              <Form.Label>End Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                name="end"
                value={moment(reminderFormData.end).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) =>
                  handleReminderDateChange("end", new Date(e.target.value))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="me-3 py-2 px-4 rounded-5 color-yellow fw-bold bg-transparent"
            style={{ border: "1px solid var(--yellow-color)" }}
            onClick={handleCloseModalReminder}
          >
            Close
          </button>
          <button
            className="py-2 px-4 rounded-5 border-0"
            style={{ background: "var(--yellow-color)" }}
            onClick={handleAddReminder}
          >
            Add Reminder
          </button>
        </Modal.Footer>
      </Modal>
      <ParentFooter />
    </OftadehLayout>
  );
};

export default CalendarPage;
