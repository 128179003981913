import React, { useState } from "react";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { CiSearch } from "react-icons/ci";
import { FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";
import Dropdown from "./Dropdown";
import Card2 from "./Card2";
function PinPost(props) {
    const { history } = props;
    const [activeDropdown, setActiveDropdown] = useState(null);

    const handleDropdownChange = (dropdownId) => {
      setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
    };
    return (
        <>
            <h5>Pin Post</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className="d-flex align-items-center justify-content-between">
                <Col md={3}>
                    <Form.Group controlId="selectOption">
                        <Form.Select className="shadow rounded-5 mt-3 mb-3">
                            <option value="option1">Pin Blog</option>
                            <option value="option2">Pin Limitless Learning</option>
                            <option value="option2">Pin Meet Our Mentors</option>
                            <option value="option2">Pin Learniee</option>
                            <option value="option2">Pin Growth Journeys</option>
                            <option value="option2">Pin Brain Bites</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="nameInput">
                        <Form.Control className="shadow rounded-5 mt-3 mb-3" type="text" placeholder="Search" />
                    </Form.Group>
                </Col>
            </Row>

            <StyledContainer>
        

        <Row className="mt-3 pe-5">
          <Col md={3} className="mt-4">
            <Dropdown
              head={"Subject"}
              lis={[
                {
                  name: "Maths",
                  id: 1,
                },
                {
                  name: "Science",
                  id: 2,
                },
                {
                  name: "English",
                  id: 3,
                },
              ]}
              active={activeDropdown === "dropdown-subject"}
              onToggle={() => handleDropdownChange("dropdown-subject")}
            />
          </Col>
          <Col md={3} className="mt-4">
            <Dropdown
              head={"Grade"}
              lis={[
                {
                  name: "1",
                  id: 1,
                },
                {
                  name: "2",
                  id: 2,
                },
                {
                  name: "3",
                  id: 3,
                },
              ]}
              active={activeDropdown === "dropdown-grade"}
              onToggle={() => handleDropdownChange("dropdown-grade")}
            />
          </Col>
          <Col md={3} className="mt-4">
            <Dropdown
              head={"Board"}
              lis={[
                {
                  name: "CBSE",
                  id: 1,
                },
                {
                  name: "ICSE",
                  id: 2,
                },
                {
                  name: "State Board",
                  id: 3,
                },
              ]}
              active={activeDropdown === "dropdown-board"}
              onToggle={() => handleDropdownChange("dropdown-board")}
            />
          </Col>
          <Col md={3} className="mt-4">
            <div className="search_btn_blog">
              <button className="py-2 shadow text-white">Search</button>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3 className="mb-2">Suggested Blogs</h3>
          </Col>
        </Row>

        <Row className="mt-3 pe-5">
          <Col md={6} className="mt-4 pe-4">
            <Card2
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              blogLikeDislike={true}
            />
          </Col>
          <Col md={6} className="mt-4 ps-4">
            <Card2
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              blogLikeDislike={true}
            />
          </Col>
        </Row>

        <Row className="mt-3 pe-5">
          <Col md={6} className="mt-4 pe-4">
            <Card2 img={card2} head={"Why is Gravity? | Class 11 | Physics"} blogLikeDislike={true} />
          </Col>
          <Col md={6} className="mt-4 ps-4">
            <Card2 img={card2} head={"Why is Gravity? | Class 11 | Physics"} blogLikeDislike={true} />
          </Col>
        </Row>
        

        <Row className="mt-5">
          <Col>
            <h3 className="mb-2">Latest Blogs</h3>
          </Col>
        </Row>

        <Row className="mt-3 pe-5">
          <Col md={6} className="mt-4 pe-4">
            <Card2
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              blogLikeDislike={true}
            />
          </Col>
          <Col md={6} className="mt-4 ps-4">
            <Card2
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              blogLikeDislike={true}
            />
          </Col>
        </Row>

       
      </StyledContainer>

        </>
    );
}


const StyledContainer = styled.div`
  .input_container {
    border: 2px solid #e5e5e5;
    border-radius: 100px;
    width: fit-content;

    input {
      background: transparent;
      outline: none;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }

  .search_btn_blog {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;


export default PinPost;
