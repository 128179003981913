import React, { useState } from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { MdOutlineDevices } from "react-icons/md";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { SlOptionsVertical } from "react-icons/sl";

function SecurityAdmin(props) {
    const { history } = props;

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const [showVirusModal, setShowVirusModal] = useState(false);
    const handleVirusModalClose = () => setShowVirusModal(false);
    const handleModalVirusShow = () => setShowVirusModal(true);

    return (
        <>
            <h5>Security</h5>
            {/* <OperationLocation path={history} /> */}
            <Row className='d-flex align-items-start justify-content-between'>
                <Col md={7}  >
                    <Container className='shadow rounded-3 p-4 '>
                        <Row className='align-items-start'>
                            <Col md={12} className='d-flex align-items-center justify-content-between p-1 border rounded-5 ps-2 pe-2'>
                                &nbsp; Activate Login
                                <Button onClick={handleModalShow} className='rounded-5 shadow bg-dark text-white border-0' variant="outlined" size="medium">
                                    Here
                                </Button>
                            </Col>
                            <Col md={12} className='mt-3 d-flex align-items-center justify-content-between p-1 border rounded-5 ps-2 pe-2'>
                                &nbsp; Virus Alert
                                <Button onClick={handleModalVirusShow} className='rounded-5 shadow bg-dark text-white border-0' variant="outlined" size="medium">
                                    Here
                                </Button>
                            </Col>
                            <Col md={12} className='mt-3 d-flex align-items-center justify-content-between p-1 border rounded-5 ps-2 pe-2'>
                                &nbsp; Issue New Password
                                <Button className='rounded-5 shadow bg-dark text-white border-0' variant="outlined" size="medium">
                                    Here
                                </Button>
                            </Col>
                            <Col md={12} className='mt-3 d-flex align-items-center justify-content-between p-1 border rounded-5 ps-2 pe-2'>
                                &nbsp; Fraud Alert
                                <Button className='rounded-5 shadow bg-dark text-white border-0' variant="outlined" size="medium">
                                    Here
                                </Button>
                            </Col>
                            <Col md={12} className='mt-3 d-flex align-items-center justify-content-between p-1 border rounded-5 ps-2 pe-2'>
                                &nbsp; Alert Words
                                <Button className='rounded-5 shadow bg-dark text-white border-0' variant="outlined" size="medium">
                                    Here
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={4}>
                    <Container className='shadow rounded-3 p-4'>
                        <Row>
                            <Col><MdOutlineDevices style={{ padding: '10px', backgroundColor: '#FE9A52', borderRadius: '50%', fontSize: '42px' }} size={47} color='white' /></Col>
                            <h5 className='mt-2 mb-2'><b>Devices</b></h5>
                            <p className="mt-2 mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                            <Button className='rounded-5 mt-2 mb-2' style={{ borderColor: '#FE9A52', color: '#FE9A52' }} variant="outlined" size="medium">
                                Log Out From All Devices
                            </Button>
                        </Row>
                        <hr />
                        <Col className='border-bottom d-flex align-items-start jsutify-content-between'>
                            <Col sm={2} md={2}><HiDevicePhoneMobile size={22} color='#FE9A52' /></Col>
                            <Col sm={8} md={8}>
                                <h6 className='p-0'>iPhone 11</h6>
                                <p style={{ fontSize: '12px' }} className='p-0'>place 1 <br /> October 1, 2022</p>
                            </Col>
                            <Col sm={2} md={2}><SlOptionsVertical /></Col>
                        </Col>
                        <Col className='mt-2 border-bottom d-flex align-items-start jsutify-content-between'>
                            <Col sm={2} md={2}><HiDevicePhoneMobile size={22} color='#FE9A52' /></Col>
                            <Col sm={8} md={8}>
                                <h6 className='p-0'>iPhone 11</h6>
                                <p style={{ fontSize: '12px' }} className='p-0'>place 1 <br /> October 1, 2022</p>
                            </Col>
                            <Col sm={2} md={2}><SlOptionsVertical /></Col>
                        </Col>
                        <br />
                        <center> <p style={{ color: '#FE9A52' }}>need help ?</p> </center>
                    </Container>
                </Col>
            </Row>
            <Modal className='shadow' show={showModal} onHide={handleModalClose}>
                <Modal.Header className='text-center' closeButton style={{ borderBottom: 'none' }}>
                    <Modal.Title className="text-center">Activate Login ID</Modal.Title>
                </Modal.Header>
                <Modal.Body className='align-items-center text-center'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>In order to access your services, you need to activate your login ID.</p>
                    <p>Please enter your email address you used to access your business account.</p>
                    <Col md={12}>
                        <Form.Group controlId="nameInput">
                            <Form.Control type="text" placeholder="Enter Email ID" />
                        </Form.Group>
                    </Col>
                    <Button className='rounded-5 mt-3 mb-2' style={{ borderColor: '#FE9A52', color: '#FE9A52' }} variant="outlined" size="medium">
                        Activate ID
                    </Button>
                </Modal.Body>
            </Modal>

            <Modal className='shadow' show={showVirusModal} onHide={handleVirusModalClose}>
                <Modal.Header className='text-center' closeButton style={{ borderBottom: 'none' }}>
                    <Modal.Title className="text-center text-success">No Virus Detected!</Modal.Title>
                </Modal.Header>
                <Modal.Body className='align-items-center text-center'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>In order to access your services, you need to activate your login ID.</p>
                    <p>Please enter your email address you used to access your business account.</p>
                
                    <Row>
                        <Col><Button className='rounded-5 mt-3 mb-2' style={{ borderColor: '#FE9A52', color: '#FE9A52' }} variant="outlined" size="medium">
                        Go Back
                        </Button></Col>
                        <Col><Button className='rounded-5 mt-3 mb-2' style={{ borderColor: '#FE9A52', color: '#FE9A52' }} variant="outlined" size="medium">
                        More Information
                        </Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default SecurityAdmin