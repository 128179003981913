import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const AddTestPage = () => {
  const token = useSelector((state) => state.token.value);
  const { courseId } = useParams();
  const navigate = useNavigate();

  // State for test details
  const [title, setTitle] = useState("");
  const [forDate, setForDate] = useState("");

  // State for current question
  const [currentQuestion, setCurrentQuestion] = useState({
    question: "",
    options: { a: "", b: "", c: "", d: "" },
    correct_answer: "",
  });

  // State for all MCQs
  const [mcqs, setMcqs] = useState([]);

  // Handle input changes for the current question
  const handleQuestionChange = (e) => {
    setCurrentQuestion({
      ...currentQuestion,
      question: e.target.value,
    });
  };

  const handleOptionChange = (e, option) => {
    setCurrentQuestion({
      ...currentQuestion,
      options: {
        ...currentQuestion.options,
        [option]: e.target.value,
      },
    });
  };

  const handleCorrectAnswerChange = (e) => {
    setCurrentQuestion({
      ...currentQuestion,
      correct_answer: e.target.value,
    });
  };

  const handleAddQuestion = () => {
    const { question, options, correct_answer } = currentQuestion;
    if (
      !question ||
      !options.a ||
      !options.b ||
      !options.c ||
      !options.d ||
      !correct_answer
    ) {
      toast.error("Please fill in all question fields.");
      return;
    }

    setMcqs([...mcqs, currentQuestion]);
    // Reset current question
    setCurrentQuestion({
      question: "",
      options: { a: "", b: "", c: "", d: "" },
      correct_answer: "",
    });
  };

  // Format date to mm-dd-yyyy
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };

  const handleSubmit = async () => {
    if (!title || !forDate || mcqs?.length === 0) {
      toast.error("Please fill in all fields and add at least one question.");
      return;
    }

    const formattedDate = formatDate(forDate);

    const payload = {
      course_id: courseId, // Ensure courseId is correctly obtained from params
      title: title,
      for_date: formattedDate,
      mcqs: mcqs,
    };

    try {
      const res = await MyAPI.POST("/teacher/mcq-test", payload, token);
      const { res_type, message } = res.data || res;

      if (res_type === "success") {
        toast.success(message || "Test added successfully.");
        // Reset form
        setTitle("");
        setForDate("");
        setMcqs([]);
        navigate("/teacher/HwandTest");
      } else {
        toast.error(message || "Error adding test.");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred.");
    }
  };

  return (
    <TeacherLayout>
      <h3>Add Test</h3>

      <Row className="mt-3">
        <Col md={8}>
          <Form className="p-4 rounded-4 shadow bg-body-tertiary mb-3">
            <Form.Group controlId="testTitle">
              <Form.Control
                type="text"
                placeholder="Enter Test Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Row className="mt-4">
              <Col md={6}>
                <Form.Group controlId="testDate">
                  <Form.Control
                    type="date"
                    placeholder="Select Date"
                    value={forDate}
                    onChange={(e) => setForDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <Form className="p-4 rounded-4 shadow bg-body-tertiary">
            <Form.Group controlId="questionText">
              <Form.Control
                type="text"
                placeholder="Type your question"
                value={currentQuestion.question}
                onChange={handleQuestionChange}
              />
            </Form.Group>

            <Row>
              <Col md={6} className="mt-4">
                <Form.Group controlId="optionA">
                  <Form.Control
                    type="text"
                    placeholder="Option A"
                    value={currentQuestion.options.a}
                    onChange={(e) => handleOptionChange(e, "a")}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="optionB">
                  <Form.Control
                    type="text"
                    placeholder="Option B"
                    value={currentQuestion.options.b}
                    onChange={(e) => handleOptionChange(e, "b")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mt-4">
                <Form.Group controlId="optionC">
                  <Form.Control
                    type="text"
                    placeholder="Option C"
                    value={currentQuestion.options.c}
                    onChange={(e) => handleOptionChange(e, "c")}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mt-4">
                <Form.Group controlId="optionD">
                  <Form.Control
                    type="text"
                    placeholder="Option D"
                    value={currentQuestion.options.d}
                    onChange={(e) => handleOptionChange(e, "d")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mt-4">
                <Form.Group controlId="correctAnswer">
                  <Form.Select
                    value={currentQuestion.correct_answer}
                    onChange={handleCorrectAnswerChange}
                  >
                    <option value="">Select Correct Answer</option>
                    <option value="a">Option A</option>
                    <option value="b">Option B</option>
                    <option value="c">Option C</option>
                    <option value="d">Option D</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-end">
              <Button
                variant="primary"
                className="mt-4"
                onClick={handleAddQuestion}
              >
                Add Question
              </Button>
            </Row>
          </Form>

          <Row className="mt-4 justify-content-end">
            <Button variant="success" onClick={handleSubmit}>
              Submit Test
            </Button>
          </Row>
        </Col>

        <Col md={4}>
          <div className="p-4 rounded-4 shadow bg-body-tertiary">
            <h5>Preview</h5>
            {mcqs?.length === 0 ? (
              <p>No questions added yet.</p>
            ) : (
              mcqs.map((q, index) => (
                <div key={index} className="mt-3">
                  <p>
                    Q{index + 1}: <b>{q.question}</b>
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>A: {q.options.a}</p>
                    <p>B: {q.options.b}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>C: {q.options.c}</p>
                    <p>D: {q.options.d}</p>
                  </div>
                  <p>
                    Correct Answer: <b>{q.correct_answer.toUpperCase()}</b>
                  </p>
                </div>
              ))
            )}
          </div>
        </Col>
      </Row>
    </TeacherLayout>
  );
};

export default AddTestPage;
