import BlogPage from "./BlogPage";

export const BlogConfig = {
  routes: [
    {
      path: "/parent/dashboard/pages/blogs",
      exact: true,
      component: BlogPage,
    },
  ],
};
