import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Container, Form, Modal } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import "./Reschedule.css";
import { FaBookOpenReader } from "react-icons/fa6";
import Button from "@mui/material/Button";
import card_img1 from "../../assets/card_img1.png";
import ParentFooter from "../../components/ParentFooter";
import Calendar from 'react-calendar';
import { FaCheckCircle } from "react-icons/fa";

function Reschedule(props) {
  const { history } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setSelectedTime("");
    setDate(newDate);
  };

  const [selectedTime, setSelectedTime] = useState("");

  return (
    <OftadehLayout>
      <h1>Reschedule</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      {/* Course cards */}



      <Container>
        <h4 className="mt-4 mb-2">Your Courses</h4>

        <Row>
          <Col md={4} className="mt-3">
            <div className="n-box-shadow py-3 px-4 rounded-4 position-relative">
              <img
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                className="position-relative rounded-4 mb-3"
                src={card_img1}
                alt=""
              />
              <h6>Maths</h6>
              <p className="mb-2">12 January 2012</p>
              <p>2pm - 4pm ist</p>
              <button className="new-button" onClick={handleShow}>
                Send Request
              </button>
            </div>
          </Col>
          <Col md={4} className="mt-3">
            <div className="n-box-shadow py-3 px-4 rounded-4 position-relative">
              <img
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                className="position-relative rounded-4 mb-3"
                src={card_img1}
                alt=""
              />
              <h6>Maths</h6>
              <p className="mb-2">12 January 2012</p>
              <p>2pm - 4pm ist</p>
              <button className="new-button" onClick={handleShow}>
                Send Request
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <h4 className="mt-5">Your All Requests</h4>

        <Row className="mt-3 mb-3 p-3 n-box-shadow rounded-3">
          <Col md={8} className=" d-flex">
            <span
              className="p-3 bg-warning rounded-3 d-flex align-items-center"
              style={{ height: "fit-content" }}
            >
              <FaBookOpenReader size={22} />
            </span>

            <div className="ms-3">
              <h6>Learn Math's By John Smith</h6>
              <p>
                <b>Leave at</b> : 12 January 2012
              </p>
            </div>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <span className="badge bg-danger">Rejected</span>
          </Col>
        </Row>
      </Container>

      <Container>
        <h4 className="mt-5">Teacher Requests</h4>
        <Row className="mt-3 mb-3 p-3 n-box-shadow rounded-3">
          <Col md={8} className=" d-flex">
            <span
              className="p-3 bg-warning rounded-3 d-flex align-items-center"
              style={{ height: "fit-content" }}
            >
              <FaBookOpenReader size={22} />
            </span>

            <div className="ms-3">
              <h6>Learn Math's By John Smith</h6>
              <p>
                <b>Leave at</b> : 12 January 2012
              </p>
              <p>
                <b>Reason</b> : Lorem ipsum, dolor sit amet consectetur
                adipisicing elit. Quisquam exercitationem porro odit?
              </p>
            </div>
          </Col>
          <Col md={4} className="d-flex align-items-center justify-content-end">
            <Button
              className="bg-success me-3 rounded-5 text-white border-0"
              variant="outlined"
            >
              Accept
            </Button>
            <Button
              className="bg-danger rounded-5 text-white border-0"
              variant="outlined"
            >
              Reject
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        style={{ zIndex: 9999 }}
      >
        <Modal.Header>
          <Modal.Title className="w-100 h-100">
            <h5 className="text-center">Reschedule Request</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {" "}
          <Row className="mt-2 mb-2">
            <Col md={3}>
              <b>Sudhakar Rao</b>
            </Col>
            <Col md={3}>
              <b>Induction System</b>
            </Col>
            <Col md={3}>
              <b>From: Parent</b>
            </Col>
            <Col md={3}>
              <b>To: Teacher</b>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Calendar
                className="rounded-3 mt-3 "
                onChange={onChange}
                value={date}
              />
            </Col>
            <Col md={6} className="pt-2">
              <div onClick={() => setSelectedTime('11')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '11' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">10:00 AM - 11:00 AM</p>
                {
                  selectedTime === "11" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>
              <div onClick={() => setSelectedTime('12')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '12' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">11:00 AM - 12:00 PM</p>
                {
                  selectedTime === "12" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>
              <div onClick={() => setSelectedTime('13')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '13' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">12:00 PM - 01:00 PM</p>
                {
                  selectedTime === "13" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>
              <div onClick={() => setSelectedTime('14')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '14' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">01:00 PM - 02:00 PM</p>
                {
                  selectedTime === "14" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>
              <div onClick={() => setSelectedTime('15')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '15' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">02:00 PM - 03:00 PM</p>
                {
                  selectedTime === "15" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>
              <div onClick={() => setSelectedTime('16')} className={`cursor-pointer mt-1 px-3 py-2 border rounded-2 d-flex align-items-center justify-content-between ${selectedTime === '16' ? 'border-success' : null}`}>
                <p className=" m-0 p-0">03:00 PM - 04:00 PM</p>
                {
                  selectedTime === "16" && (
                    <FaCheckCircle color="green" />
                  )
                }
              </div>

            </Col>
          </Row>
          <Row>
            <Col md={6} className="mt-3">
              <Form.Group controlId="selectOption">
                <Form.Label>Select Course</Form.Label>
                <Form.Select>
                  <option value="option1">Course - 1</option>
                  <option value="option2">Course - 2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="selectOption">
                <Form.Label>Select Subject</Form.Label>
                <Form.Select>
                  <option value="option1">Hindi</option>
                  <option value="option2">English</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Col md={12} className="mt-3">
            <Form.Group controlId="formReason">
              <Form.Label>Reason</Form.Label>
              <Form.Control as="textarea" placeholder="Reason..." rows={3} />
            </Form.Group>
          </Col>
          <Button
            className="mt-3 d-md-none reshedule-display-none-on-laptop-desktop bg-dark text-white"
            variant="contained"
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-black text-white fw-semibold py-2 px-4 border-0 rounded-3"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="bg-yellow text-white fw-semibold py-2 px-4 border-0 rounded-3"
            onClick={handleClose}
          >
            Send Request
          </button>
        </Modal.Footer>
      </Modal>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default Reschedule;
