import React from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Badge, Table } from "react-bootstrap";

const StudentAttandenceOneOnOne = () => {
  return (
    <TeacherLayout>
      <h5>Student Attendance 1-On-1</h5>
      <Table className="mt-5" striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Lecture Date</th>
            <th>Timing</th>
            <th>Lecture Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* Sample Data */}
          <tr>
            <td>1.</td>
            <td>12/12/2021</td>
            <td>12:00 PM</td>
            <td>1</td>
            <td>
              <Badge bg="success">Present</Badge>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>12/12/2021</td>
            <td>12:00 PM</td>
            <td>2</td>
            <td>
              <Badge bg="success">Present</Badge>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>12/12/2021</td>
            <td>12:00 PM</td>
            <td>3</td>
            <td>
              <Badge bg="success">Present</Badge>
            </td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </Table>
    </TeacherLayout>
  );
};

export default StudentAttandenceOneOnOne;
