import React from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import styled from "styled-components";
import { Col, Dropdown, Row } from "react-bootstrap";
import Upload from "../courseManagement/Upload";
import Progress from "../courseManagement/Progress";
import ParentFooter from "../../components/ParentFooter";

const EditClassDetails = () => {
  return (
    <TeacherLayout>
      <h3>Edit Course</h3>

      <StyledContainer>
        <Row className="mt-5">
          <Col md={6}>
            <Row>
              <Col>
                <p className="m-0">Category:</p>
              </Col>
            </Row>
            <Row className="pe-5">
              <Col className="d-flex align-items-center jsutify-content-between mt-3 pe-5">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                  >
                    Select
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Extra Academic
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Extra Curricular
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Row className="pe-5 d-flex align-items-start flex-column">
              <Col>
                <p className="m-0">Time:</p>
              </Col>
              <Col className="d-flex align-items-center jsutify-content-end mt-3 pe-5">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                  >
                    Time Slot
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>Course Content</h3>
          </Col>
        </Row>

        <form>
          <Row className="mt-3">
            <Col md={3}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Subject
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Hidi</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">English</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Maths</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Grade
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Board
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">CBSE</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">ICSE</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">State</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3}>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Experience
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">1 Year</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2 Year</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3 Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Duration
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">1 Year</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2 Year</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3 Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Type
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Online</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Offline</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Language
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Hindi</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">English</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Frequency
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Daily</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Weekly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={9} className="mt-4">
              <input
                type="text"
                className="form-control shadow"
                placeholder="Course Title"
              />
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Rating
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={12} className="mt-4">
              <input
                type="text"
                className="form-control shadow"
                placeholder="Objective"
              />
            </Col>
            <Col md={12} className="mt-4">
              <textarea class="form-control shadow" rows="5"></textarea>
            </Col>
            <Col md={3} className="mt-4">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-basic shadow rounded-4 py-2 px-3 position-relative"
                >
                  Modules
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={6} className="mt-4">
              <input
                type="text"
                className="form-control shadow"
                placeholder="Course Tags"
              />
            </Col>
            <Col md={3} className="mt-4">
              <input
                type="text"
                className="form-control shadow"
                placeholder="Price"
              />
            </Col>
          </Row>
        </form>

        <Row className="mt-5">
          <Col>
            <h3>Course Thumbnail</h3>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Upload />
          </Col>
          <Col md={6} className="pt-5">
            <Progress />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>Course intro video</h3>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <Upload />
          </Col>
          <Col md={6} className="pt-5">
            <Progress />
          </Col>
        </Row>

        <Row className="mt-5 d-flex justify-content-end pe-3">
          <button className="approval_btn text-white">Increase Price</button>
          <button className="approval_btn text-white ms-3">Send For Approval</button>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .dropdown-basic {
    background-color: transparent;
    border: 1px solid var(--color-primary) !important;
    border: none;
    width: 220px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .dropdown-toggle::after {
    position: absolute !important;
    right: 6% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .dropdown-basic:active {
    background-color: transparent !important;
  }

  .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .form-control {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .approval_btn {
    background-color: var(--color-primary);
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
  }
`;

export default EditClassDetails;
