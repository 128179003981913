import React from "react";
import ChatPageCommon from "../../components/chat/ChatPageCommon";
import { useSelector } from "react-redux";

function Chat() {
  const token = useSelector((state) => state.token.value);
  return (
    <ChatPageCommon
      user={{ _id: "66daaf636243ce732a6ec404" }}
      token={token}
      addgroup={true}
    />
  );
}

export default Chat;
