import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styled from "styled-components";
import { Col, Row, Container } from "react-bootstrap";
import { TiArrowRightThick } from "react-icons/ti";
import icon1 from "../assets/icons/icon1.png";
import icon2 from "../assets/icons/icon2.png";
import icon3 from "../assets/icons/icon3.png";
import icon4 from "../assets/icons/icon4.png";
import { FaChevronRight } from "react-icons/fa6";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <AboutContainer>
        {/* <div className="hero_about d-flex align-items-center justify-content-center">
          <h2 className="fw-bold text-white">ABOUT US</h2>
        </div> */}
        <br />
        <br />
        <br />
        <Container
          fluid
          className="d-flex align-items-center"
          style={{ height: "40vh", background: "#F7F6F9" }}
        >
          <div className="top_bar_help d-flex flex-column justify-content-center px-5">
            <h3 className="fw-bold">ABOUT US</h3>
            <p>
              Home <FaChevronRight style={{ fontSize: 12 }} /> about
            </p>
          </div>
        </Container>

        <div className="section2">
          <Row className="px-5 mt-5 align-items-center">
            <Col md={6}>
              <h1 className="fw-bold mb-4">
                Welcome to <br />{" "}
                <span style={{ color: "#FFC224" }}>Urja Talent</span>
              </h1>
              <p className="mb-4">
                Morbi vel augue et metus pellentesque lacinia eu non odio. Sed
                id purus urna. Maecenas ultrices ligula a nisi dictum tristique.
                Suspendisse potenti. Curabitur egestas ultrices sem, eu
                vestibulum leo sagittis et.
              </p>
              <div onClick={() => navigate("/course")}>
                <Button text="Classes" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end pe-md-5 mb-3 mt-md-0 mt-5"
            >
              <div className="sec2_img_circ shadow">
                <img
                  src="https://plus.unsplash.com/premium_photo-1682088221071-ddd6d6de4191?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />

                <div className="small_img_circ">
                  <img
                    src="https://images.unsplash.com/photo-1619852182277-79aa23f82c8e?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="small_img_circ2">
                  <img
                    src="https://images.unsplash.com/photo-1534643960519-11ad79bc19df?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section3">
          <Row className="px-5 mt-5 align-items-center flex-md-row flex-column-reverse">
            <Col md={6} className="mb-3 mt-md-0 mt-5 pe-md-0 pe-5">
              <div className="sec3_img_circ shadow">
                <img
                  src="https://images.unsplash.com/photo-1571260899304-425eee4c7efc?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />

                <div className="small_img_circ3">
                  <img
                    src="https://images.unsplash.com/photo-1619852182277-79aa23f82c8e?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </div>
                <div className="small_img_circ4">
                  <img
                    src="https://images.unsplash.com/photo-1534643960519-11ad79bc19df?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <h1 className="fw-bold mb-4">Our Mission</h1>
              <p className="mb-4">
                Praesent rhoncus justo erat, sed sollicitudin arcu malesuada
                vel. Etiam scelerisque justo ut purus luctus ullamcorper.
                Vivamus vitae elit ligula. Fusce eu rutrum nisl.
              </p>
              <ul className="m-0 p-0">
                <li className="mb-2">
                  <TiArrowRightThick className="fs-5 text_yellow" /> Etiam ante
                  nisl, maximus vitae sem non, dignissim
                </li>
                <li className="mb-2">
                  <TiArrowRightThick className="fs-5 text_yellow" /> Donec
                  blandit, sapien eu porttitor blandit
                </li>
                <li className="mb-2">
                  <TiArrowRightThick className="fs-5 text_yellow" /> Sed at urna
                  at massa viverra feugiat non
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className="section4 mt-5">
          <Row className="px-5 mt-5 align-items-center mb-4">
            <Col md={6} className="pe-5">
              <h1 className="fw-bold mb-4">Our Benefits</h1>
              <p className="mb-4">
                Praesent rhoncus justo erat, sed sollicitudin arcu malesuada
                vel. <br /> Etiam scelerisque justo ut purus luctus ullamcorper.{" "}
                <br />
                Vivamus vitae elit ligula. Fusce eu rutrum nisl.
              </p>
              <div onClick={() => navigate("/help")}>
                <Button text="Contact Us" />
              </div>
            </Col>

            <Col md={6} className="mt-md-0 mt-4">
              <Row>
                <Col md={6}>
                  <div className="shadow rounded-4 p-3 mb-4">
                    <img src={icon1} alt="icon" className="mb-2" />
                    <h5>Awesome Teachers</h5>
                    <p className="m-0">
                      Vivamus interdum, mauris interdum quis curdum sodales
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="shadow rounded-4 p-3 mb-4">
                    <img src={icon2} alt="icon" className="mb-2" />
                    <h5>Global Certificate</h5>
                    <p className="m-0">
                      Pelleneget tespharetra que fringilla egugue id eget
                      pharetra
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="shadow rounded-4 p-3">
                    <img src={icon3} alt="icon" className="mb-2" />
                    <h5>Student Support Service</h5>
                    <p className="m-0">
                      Mauris nec mi fequis ugiat, cursus tortor nec, pharetra
                      tellus
                    </p>
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-4">
                  <div className="shadow rounded-4 p-3">
                    <img src={icon4} alt="icon" className="mb-2" />
                    <h5>Best Programm</h5>
                    <p className="m-0">
                      Etiam risus neque, volutpat vel laoreet a, finibus
                      volutpat
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </AboutContainer>

      <Footer />
    </>
  );
};

const AboutContainer = styled.div`
  .hero_about {
    position: relative;
    margin-top: 7.5vmax;
    height: 50vh;
    width: 100%;
    background-image: url("https://images.unsplash.com/photo-1494949649109-ecfc3b8c35df?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    > h2 {
      z-index: 99;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      height: inherit;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .section2 {
    overflow-x: hidden;

    .sec2_img_circ {
      height: 38vmax;
      width: 38vmax;
      border-radius: 50%;
      position: relative;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .small_img_circ {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 12vmax;
        width: 12vmax;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #fff;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .small_img_circ2 {
        position: absolute;
        top: 50%;
        left: 0%;
        height: 8vmax;
        transform: translateX(-50%);
        width: 8vmax;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #fff;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }
  .section3 {
    overflow-x: hidden;

    .sec3_img_circ {
      height: 38vmax;
      width: 38vmax;
      border-radius: 50%;
      position: relative;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .small_img_circ3 {
        position: absolute;
        top: 0%;
        right: 0%;
        height: 12vmax;
        width: 12vmax;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #fff;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .small_img_circ4 {
        position: absolute;
        top: 50%;
        right: 0%;
        height: 8vmax;
        transform: translateX(50%);
        width: 8vmax;
        border-radius: 50%;
        overflow: hidden;
        border: 6px solid #fff;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  .section4 {
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    .hero_about {
      margin-top: 12vmax;
      height: 30vh;
    }

    .sec2_img_circ,
    .sec3_img_circ {
      height: 300px !important;
      width: 300px !important;
      flex-shrink: 0 !important;
    }
  }
`;

export default About;
