import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { MyAPI } from "../../utils/MyAPI";

const Accordian = ({ isSearch, isTitle }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [allFAQ, setAllFAQ] = useState([]);
  const [searchFAQ, setSearchFAQ] = useState("");
  const [loading, setLoading] = useState(true); // For loading state

  const fetchAllFaq = async (key_word) => {
    setLoading(true); // Set loading to true when fetching data
    try {
      let res = await MyAPI.GET(`/common/show-faq?keyword=${key_word}`);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setAllFAQ(apiData);
      } else {
        toast.error(message || "Error fetching FAQ.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Set loading to false once the fetching is done
    }
  };

  useEffect(() => {
    fetchAllFaq(searchFAQ);
  }, [searchFAQ]);

  const toggleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Container
      fluid
      className="accordian_main m-0 py-5 position-relative overflow-hidden mt-3"
    >
      <Row className="d-flex align-items-center justify-content-between">
        {!isTitle && (
          <Col>
            <h3 className="fw-bold">Frequently Asked Questions</h3>
          </Col>
        )}
        {isSearch && (
          <Col md={3} className="d-flex align-items-center justify-content-end">
            <Form.Group controlId="searchInput">
              <Form.Control
                className="rounded-5 shadow"
                type="search"
                placeholder="Search FAQ"
                value={searchFAQ}
                onChange={(e) => setSearchFAQ(e.target.value)}
              />
            </Form.Group>
          </Col>
        )}
      </Row>

      {/* Show loading spinner or message */}
      {loading ? (
        <div className="text-center my-5">Loading FAQs...</div>
      ) : allFAQ?.length === 0 ? (
        <div className="text-center my-5">No FAQ's Found</div>
      ) : (
        allFAQ.map((faq, index) => (
          <React.Fragment key={faq.id}>
            <motion.div
              className="accordian_custome d-flex flex-column py-3 px-3 justify-content-start mt-5"
              initial={{ height: "50px" }}
              animate={{ height: activeIndex === index ? "auto" : "60px" }}
              transition={{ duration: 0.2, type: "keyframes" }}
              style={{ background: "#EFEEFE" }}
            >
              <Row style={{ transition: "all 0.3s ease" }}>
                <Col>
                  <h6 className="fw-bold">{faq.title}</h6>
                </Col>
                <Col className="d-flex justify-content-end">
                  <div
                    onClick={() => toggleActiveIndex(index)}
                    className="accordian_icons d-flex align-items-center justify-content-center"
                  >
                    {activeIndex === index ? (
                      <FaMinus className="text-white" />
                    ) : (
                      <FaPlus className="text-white" />
                    )}
                  </div>
                </Col>
              </Row>

              {activeIndex === index && (
                <Row className="mt-3">
                  <Col>
                    <p>{faq.description}</p>
                  </Col>
                </Row>
              )}
            </motion.div>
            <div className="row_line"></div>
          </React.Fragment>
        ))
      )}
    </Container>
  );
};

export default Accordian;
