import React from "react";
import { Table } from "react-bootstrap";

const data = [
  {
    id: 1,
    name: "test1",
    email: "test@test.com",
    phone: "1234567890",
    time: "12:00",
    subject: "test",
    description: "test",
  },
  {
    id: 2,
    name: "test2",
    email: "test2@test2.com",
    phone: "1234567890",
    time: "12:00",
    subject: "test",
    description: "test",
  },
  {
    id: 3,
    name: "test2",
    email: "test2@test2.com",
    phone: "1234567890",
    time: "12:00",
    subject: "test",
    description: "test",
  },
  {
    id: 4,
    name: "test2",
    email: "test2@test2.com",
    phone: "1234567890",
    time: "12:00",
    subject: "test",
    description: "test",
  },
  {
    id: 5,
    name: "test2",
    email: "test2@test2.com",
    phone: "1234567890",
    time: "12:00",
    subject: "test",
    description: "test",
  },
];

const AllDemos = () => {
  return (
    <>
      <h5 className="mt-3">All Demos</h5>

      <Table
        className="mt-4 mb-3 rounded-4"
        striped
        responsive
        hover
        style={{ backgroundColor: "var(--color-primary)" }}
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Name</th>
            <th className="text-white">Email</th>
            <th className="text-white">Phone</th>
            <th className="text-white">Time</th>
            <th className="text-white">Subject</th>
            <th className="text-white">Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((demo, index) => (
            <tr className="text-center bg-light" key={demo.id}>
              <td>{index + 1}</td>
              <td>{demo.name}</td>
              <td>{demo.email}</td>
              <td>{demo.phone}</td>
              <td>{demo.time}</td>
              <td>{demo.subject}</td>
              <td>{demo.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AllDemos;
