import ParentProfile from "./ParentProfile";
import StudentAttandace from "./StudentAttandace";
import StudentAttandaceOneOnOne from "./StudentAttandenceOneOnOne";

export const ParentProfilePageConfig = {
  routes: [
    {
      path: "/teacher/ParentProfile",
      exact: true,
      component: ParentProfile,
    },
    {
      path: "/teacher/student/attendance/:id?",
      exact: true,
      component: StudentAttandace,
    },
    {
      path: "/teacher/StudentsManagement/Attendance/1-on-1",
      exact: true,
      component: StudentAttandaceOneOnOne,
    },
  ],
};
