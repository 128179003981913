import WalletPage from "./WalletPage";
import LearnieMallAcc from "./LearnieMallAcc";
import ParentAccountDetails from "./ParentAccountDetails";
import TeacherAccountDetails from "./TeacherAccountDetails";
import EmployeeAccountDetails from "./EmployeeAccountDetails";

export const WalletPageConfig = {
  routes: [
    {
      path: "/account/wallet",
      exact: true,
      component: WalletPage,
    },
    {
      path: "/account/learnie-account",
      exact: true,
      component: LearnieMallAcc,
    },
    {
      path: "/account/parentAccount/parentDetails",
      exact: true,
      component: ParentAccountDetails,
    },
    {
      path: "/teacherAccount/teacherDetails",
      exact: true,
      component: TeacherAccountDetails,
    },
    {
      path: "/employeeAccount/employeeDetails",
      exact: true,
      component: EmployeeAccountDetails,
    },
  ],
};
