import SalesReportPage from "./SalesReportPage";

export const SalesReportPageConfig = {
  routes: [
    {
      path: "/operation/SalesReport",
      exact: true,
      component: SalesReportPage
    }
  ]
};
