// ReviewRating
import ReviewRatingPage from "./ReviewRatingPage";

export const ReviewRatingPageConfig = {
  routes: [
    {
      path: "/operation/ReviewRating",
      exact: true,
      component: ReviewRatingPage
    }
  ]
};
