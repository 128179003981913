import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Col, Form, Table, Modal, Button } from "react-bootstrap";

const users = [
  {
    id: 1,
    taskname: "Task 1",
    date: "12/12/2021",
    deadline: "12/12/2021",
    status: "Pending",
  },
  {
    id: 2,
    taskname: "Task 2",
    date: "12/12/2021",
    deadline: "12/12/2021",
    status: "Completed",
  },
  {
    id: 3,
    taskname: "Task 3",
    date: "12/12/2021",
    deadline: "12/12/2021",
    status: "Pending",
  },
  {
    id: 4,
    taskname: "Task 4",
    date: "12/12/2021",
    deadline: "12/12/2021",
    status: "Completed",
  },
  {
    id: 5,
    taskname: "Task 5",
    date: "12/12/2021",
    deadline: "12/12/2021",
    status: "Pending",
  },
];

const TaskManagementPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <OperationLayout>
      <h5>Task Management</h5>

      <Row className="mt-4">
        <Col md={3}>
          <Form.Control
            type="text"
            className="rounded-5"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Task Name</th>
            <th className="text-white">Date & Time</th>
            <th className="text-white">Deadline</th>
            <th className="text-white">Status</th>
            <th className="text-white">View</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.id}</td>
              <td>{user.taskname}</td>
              <td>{user.date}</td>
              <td>{user.deadline}</td>
              <td>
                {user.status === "Pending" ? (
                  <span className="badge bg-danger">{user.status}</span>
                ) : (
                  <span className="badge bg-success">{user.status}</span>
                )}
              </td>
              <td
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                View
              </td>
              <td className="text-center">
                <input type="checkbox" name="" id="" />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Task Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Task Name : <b>Task 1</b>
          </p>
          <p>
            Task Date : <b>12/12/2021</b>
          </p>
          <p>
            Task Deadline : <b>12/12/2021</b>
          </p>
          <p>
            Task Status : <b>Pending</b>
          </p>
          <p>
            Task Description :{" "}
            <b>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa qui
              error, iure nemo, dolorem odio perspiciatis accusamus illum,
              facere fugit consequuntur.
            </b>
          </p>
        </Modal.Body>
      </Modal>
    </OperationLayout>
  );
};

export default TaskManagementPage;
