import { IconButton, Button as MaterialButton, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaBook, FaRegHeart, FaSwatchbook } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { SiGoogleclassroom } from "react-icons/si";
import { Button, Form, Col, Modal, Select } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
const BlogCard = ({
  img,
  head,
  review,
  time,
  blogIcon,
  blog,
  bottomRowText1,
  bottomRowText2,
  bottomRowText2Icon,
  deleteButton,
  blogLikeDislike,
  teacherDetails,
  redirect,
  isFevourite,
  isEdit,
}) => {
  let navigate = useNavigate();

  const [like, setLike] = useState(false);

  const handlenavigatetoviewblog = () => {
    navigate("/blog/2354");
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("active"); // Initial status value

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleStatusChange = () => {
    // Implement logic to change blog status using the 'status' state value
    console.log("New status:", status);
    setShowModal(false); // Close the modal after status change
  };

  const handleDeleteBlog = () => {
    // Implement logic to delete the blog
  };
  return (
    <div
      className="parent_card2 border rounded-4 mb-3 ms-3"
      onClick={redirect ? () => handleNavigate(redirect) : null}
    >
      <div className="parent_card2_top d-flex align-items-center justify-content-center pe-2 py-2 overflow-hidden position-relative">
        <img src={img} className="rounded-3" alt="" />
      </div>
      <div className="parent_card2_bottom p-3">
        {head && (
          <h5 className="fw-bold mt-1 d-flex align-content-center justify-content-between py-1">
            {head}
            {blogLikeDislike && (
              <IconButton
                onClick={() => setLike(!like)}
                className="ms-5"
                aria-label="delete"
              >
                {like ? <FaHeart color="red" /> : <FaRegHeart />}
              </IconButton>
            )}
            {isEdit && (
              <IconButton
                onClick={() => handleEditClick()}
                className="ms-5 bg-secondary text-white"
                aria-label="delete"
              >
                <CiEdit />
              </IconButton>
            )}
          </h5>
        )}
        {review && time && blogIcon && blog && (
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center gap-4">
              <p className="m-0">{review}</p>
              <p className="m-0">{time}</p>
            </div>
            {deleteButton && <MdDeleteOutline className="fs-5 text-danger" />}
            <p
              className="m-0 fw-bold text_yellow cursor-pointer"
              onClick={handlenavigatetoviewblog}
            >
              {blog} {blogIcon}
            </p>
          </div>
        )}

        {isFevourite && (
          <>
            <div style={{ position: "absolute", top: 10, right: 10 }}>
              <div
                className="rounded-circle bg-light d-flex align-items-center justify-content-center cursor-pointer"
                style={{ width: "40px", height: "40px" }}
              >
                <FaHeart size={20} style={{ color: "red" }} />
              </div>
            </div>
          </>
        )}

        {bottomRowText1 && bottomRowText2 && bottomRowText2Icon && (
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 fw-bold d-flex align-items-center">
              {bottomRowText2Icon} {bottomRowText2}
            </p>
            <p className="m-0 fw-bold">{bottomRowText1}</p>
          </div>
        )}

        {teacherDetails && (
          <>
            <hr />
            <div className="mt-3">
              <FaSwatchbook
                size={18}
                className="me-3"
                style={{ color: "#ff5733" }}
              />
              12 Years Experience.
            </div>
            <div className="mt-1">
              <FaBook size={18} className="me-3" style={{ color: "#388707" }} />
              120+ Courses Completed.
            </div>
            <div className="mt-1">
              <SiGoogleclassroom
                size={18}
                className="me-3"
                style={{ color: "#5733ff" }}
              />
              100+ Completed Classes.
            </div>

            <div className="d-flex flex-wrap gap-2 mt-3">
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                Hindi
              </div>
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                English
              </div>
              <div
                className="px-3 py-1 border rounded-5 cursor-pointer"
                style={{ width: "fit-content" }}
              >
                Math's
              </div>
            </div>
          </>
        )}

        <Modal show={showModal} onHide={handleModalClose} style={{marginTop:'15vh'}}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} className="p-0 m-0">
                <Form.Label>Status</Form.Label>
              <Form.Select className="rounded-3">
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Select>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleStatusChange}>
              Change Status
            </Button>
            <Button variant="danger" onClick={handleDeleteBlog}>
              Delete Blog
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default BlogCard;
