import QueryPage from "./QueryPage";

export const QueryPageConfig = {
  routes: [
    {
      path: "/account/dashboard/query",
      exact: true,
      component: QueryPage
    }
  ]
};
