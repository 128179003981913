import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Navbar from "../../components/Navbar.js";
import { Col, Row, Button, Container, Spinner } from "react-bootstrap";
import "../../assets/css/Admin/sign-in.css";
import { adminLogin } from "../../Api/AdminApi/adminlogin.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!username || !password) {
      setLoading(false);
      return toast.error("Username and password are mandatory");
    }

    try {
      await adminLogin(username, password).then((res) => {
        console.log(res);

        toast.success("Login Successful");

        localStorage.setItem("superAdminToken", res.token);

        navigate("/admin/dashboard");
      });
      // Redirect or perform further actions after successful login
    } catch (error) {
      // Error handling is done in the login function
    } finally {
      setLoading(false);
    }
  };

  const handleDashboard = ()=>{
    navigate("/admin/dashboard");
  };

  return (
    <>
      <div className="main admin-main">
        <ToastContainer position="bottom-right" />
        <Navbar />
        <Container fluid className="login-container mt-5 p-0 m-0">
          <Row>
            <Col>
              <h1 className="sign-in-title">Admin Login</h1>
              <p>Please enter your Username & Password</p>
              <Col className="login-container-semi mt-3 pt-5">
                <div className="input">
                  <input
                    type="email"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="input">
                  <input
                    type="password"
                    placeholder="********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="remember-area">
                  <div className="left">
                    {" "}
                    <input type="checkbox" name="remember" id="remember" />{" "}
                    <label htmlFor="remember">Remember me</label>{" "}
                  </div>
                  <div className="right login-n-link">Reset Password</div>
                </div>
                {loading ? (
                  <Spinner as="span" animation="border" />
                ) : (

                  // <div className="btn" onClick={(e) => handleLogin(e)}>
                  //   <Button className="login-btn" disabled={loading}>
                  //     <p className="login_animation_text1">Login Now</p>
                  //     <p className="login_animation_text2">Login Now</p>
                  //   </Button>
                  // </div>
                  <div className="btn" onClick={handleLogin}>
                    <Button className="login-btn" disabled={loading}>
                      <p className="login_animation_text1">Login Now</p>
                      <p className="login_animation_text2">Login Now</p>
                    </Button>
                  </div>
                )}
                <br />
                {/* <center>
                  <p>
                    don't have an account?{" "}
                    <span className="login-n-link">Register Here</span>{" "}
                  </p>
                </center> */}
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AdminLogin;
