import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Row, Col, Form, Table, Modal, Button } from "react-bootstrap";

const users = [
  {
    id: 1,
    courseId: "13443434",
    courseName: "Course 1",
    studentID: "2366778",
    studentName: "Student 1",
    parentId: "345666",
    parentName: "Parent 1",
    teacherId: "456789",
    teacherName: "Teacher 1",
    currentCycleNo: "1",
    totalNoOfClasses: "10/30",
    cencellatioDateTIme: "2021-09-01 12:00:00",
    courseStartDate: "2021-09-01",
    courseEndDate: "2021-09-10",
    reaseon: "Some Reason",
  },
  {
    id: 2,
    courseId: "13443434",
    courseName: "Course 2",
    studentID: "2366778",
    studentName: "Student 2",
    parentId: "345666",
    parentName: "Parent 2",
    teacherId: "456789",
    teacherName: "Teacher 2",
    currentCycleNo: "1",
    totalNoOfClasses: "10/30",
    cencellatioDateTIme: "2021-09-01 12:00:00",
    courseStartDate: "2021-09-01",
    courseEndDate: "2021-09-10",
    reaseon: "Some Reason",
  },
  {
    id: 3,
    courseId: "13443434",
    courseName: "Course 3",
    studentID: "2366778",
    studentName: "Student 3",
    parentId: "345666",
    parentName: "Parent 3",
    teacherId: "456789",
    teacherName: "Teacher 3",
    currentCycleNo: "1",
    totalNoOfClasses: "10/30",
    cencellatioDateTIme: "2021-09-01 12:00:00",
    courseStartDate: "2021-09-01",
    courseEndDate: "2021-09-10",
    reaseon: "Some Reason",
  },
  {
    id: 4,
    courseId: "13443434",
    courseName: "Course 4",
    studentID: "2366778",
    studentName: "Student 4",
    parentId: "345666",
    parentName: "Parent 4",
    teacherId: "456789",
    teacherName: "Teacher 4",
    currentCycleNo: "1",
    totalNoOfClasses: "10/30",
    cencellatioDateTIme: "2021-09-01 12:00:00",
    courseStartDate: "2021-09-01",
    courseEndDate: "2021-09-10",
    reaseon: "Some Reason",
  },
  {
    id: 5,
    courseId: "13443434",
    courseName: "Course 5",
    studentID: "2366778",
    studentName: "Student 5",
    parentId: "345666",
    parentName: "Parent 5",
    teacherId: "456789",
    teacherName: "Teacher 5",
    currentCycleNo: "1",
    totalNoOfClasses: "10/30",
    cencellatioDateTIme: "2021-09-01 12:00:00",
    courseStartDate: "2021-09-01",
    courseEndDate: "2021-09-10",
    reaseon: "Some Reason",
  },
];

const CancelClassPage = () => {
  return (
    <OperationLayout>
      <h5>Cancel Class Request</h5>

      <Row className="mt-4">
        <Col md={4}>
          <Form.Control
            type="text"
            className="rounded-5"
            placeholder="Search..."
          />
        </Col>
      </Row>

      <Table
        className="mt-4 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white">S. No.</th>
            <th className="text-white">Course Id</th>
            <th className="text-white">Course Name</th>
            <th className="text-white">Student ID</th>
            <th className="text-white">Student Name</th>
            <th className="text-white">Parent ID</th>
            <th className="text-white">Parent Name</th>
            <th className="text-white">Teacher ID</th>
            <th className="text-white">Teacher Name</th>
            <th className="text-white">Current Cycle No</th>
            <th className="text-white">Total No Of Classes</th>
            <th className="text-white">Cancellation Date Time</th>
            <th className="text-white">Course Start Date</th>
            <th className="text-white">Course End Date</th>
            <th className="text-white">Reason</th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr className="text-center bg-light" key={user.id}>
              <td>{user.id}</td>
              <td>{user.courseId}</td>
              <td>{user.courseName}</td>
              <td>{user.studentID}</td>
              <td>{user.studentName}</td>
              <td>{user.parentId}</td>
              <td>{user.parentName}</td>
              <td>{user.teacherId}</td>
              <td>{user.teacherName}</td>
              <td>{user.currentCycleNo}</td>
              <td>{user.totalNoOfClasses}</td>
              <td>{user.cencellatioDateTIme}</td>
              <td>{user.courseStartDate}</td>
              <td>{user.courseEndDate}</td>
              <td style={{ width: 300 }}>{user.reaseon}</td>

              <td className="text-primary">
                <button className="btn btn-sm btn-success rounded-5 mb-3 px-3">
                  Accept
                </button>
                <button className="btn btn-sm btn-danger rounded-5  px-3">
                  Reject
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default CancelClassPage;
