import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import styled from "styled-components";
import { Col, Row, Table, Badge, Modal } from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { IoMdShareAlt } from "react-icons/io";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const GiftPage = () => {
  const [selectedGift, setSelectedGift] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [gift, setGift] = useState(0);
  const [customGift, setCustomGift] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const parentToken = useSelector((state) => state.token.value);

  const handleSelect = (gift) => {
    if (selectedGift === gift) {
      setSelectedGift(null);
      setIsSelected(false);
      setGift(0);
    } else {
      setSelectedGift(gift);
      setIsSelected(true);
      if (gift === "custom") {
        setGift(customGift);
      } else {
        setGift(gift);
      }
    }
  };
  const handleShow = async () => {
    // setShow(true);

    // console.log("gift", Number(gift));

    try {
      const response = await axios.post(
        "/parent/gift",
        {
          reciever_parent_id: "665703b9a31201e8652fd49a",
          amount: Number(gift),
        },
        {
          headers: {
            Authorization: `Bearer ${parentToken}`,
          },
        }
      );

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <OftadehLayout>
      <StyledContainer>
        <Row className="mt-3 position-relative">
          <Col className="gift_hero position-relative"></Col>
          {isSelected && (
            <div
              onClick={handleShow}
              className="share shadow position-absolute bg-white rounded-5 px-3 py-1 d-flex align-items-center justify-content-center fs-5"
            >
              <IoMdShareAlt className="me-2" /> share
            </div>
          )}

          <div className="boxes d-flex align-items-center gap-3">
            <div className="bg-white shadow rounded-4 p-3">
              <p className="m-0">
                <span className="fw-bold">Total Gift:</span> {gift}
              </p>
            </div>
            <div className="bg-white shadow rounded-4 p-3">
              <p className="m-0">
                <span className="fw-bold">Code:</span> A#ghT68!@3549O
              </p>
            </div>
          </div>
        </Row>

        <h5 className="mt-5">
          Congratulations ! <br /> You are now a part of our family. You can now
          share a gift with your loved ones.
        </h5>

        <Row className="mt-4">
          <Col md={3}>
            <div
              onClick={() => handleSelect(1000)}
              className="share_box p-3 rounded-4 shadow bg-white d-flex align-items-center justify-content-center flex-column"
              style={{
                border:
                  selectedGift === 1000
                    ? "2px solid var(--color-primary)"
                    : "none",
                transition: "all 0.1s ease",
              }}
            >
              <p>Share Gift</p>
              <h5>₹ 1000</h5>
            </div>
          </Col>
          <Col md={3}>
            <div
              onClick={() => handleSelect(2000)}
              className="share_box p-3 rounded-4 shadow bg-white d-flex align-items-center justify-content-center flex-column"
              style={{
                border:
                  selectedGift === 2000
                    ? "2px solid var(--color-primary)"
                    : "none",
                transition: "all 0.1s ease",
              }}
            >
              <p>Share Gift</p>
              <h5>₹ 2000</h5>
            </div>
          </Col>
          <Col md={3}>
            <div
              onClick={() => handleSelect(4000)}
              className="share_box p-3 rounded-4 shadow bg-white d-flex align-items-center justify-content-center flex-column"
              style={{
                border:
                  selectedGift === 4000
                    ? "2px solid var(--color-primary)"
                    : "none",
                transition: "all 0.1s ease",
              }}
            >
              <p>Share Gift</p>
              <h5>₹ 4000</h5>
            </div>
          </Col>
          <Col md={3}>
            <div
              onClick={() => handleSelect(customGift)}
              className="share_box py-3 px-5 rounded-4 shadow d-flex align-items-center justify-content-center flex-column"
              style={{
                background: "#f8d7da",
                color: "#721c24",
                border:
                  selectedGift === "custom"
                    ? "2px solid var(--color-primary)"
                    : "none",
                transition: "all 0.1s ease",
              }}
            >
              <p>Share Gift</p>
              <input
                type="number"
                value={customGift}
                onChange={(e) => setCustomGift(e.target.value)}
                className="px-2 py-2 rounded-2 border-0"
                placeholder="Your gift price"
              />
            </div>
          </Col>
        </Row>
      </StyledContainer>

      <Row className="mt-5">
        <Col>
          <h5>Gift Send</h5>
        </Col>
      </Row>

      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">S. no.</th>
            <th className="text-white">Gift Price</th>
            <th className="text-white">Gift Number</th>
            <th className="text-white">Gift Status</th>
            <th className="text-white">Gift Receiver</th>
            <th className="text-white">Purchase Date</th>
            {/* <th className="text-white">Expiry Date</th> */}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>1</td>
            <td>₹50</td>
            <td>123456789</td>
            <td>
              <Badge variant="success">Active</Badge>
            </td>
            <td>Jane Doe</td>
            <td>12 January 2012</td>
            {/* <td>12 January 2013</td> */}
          </tr>
          <tr className="bg-light">
            <td>2</td>
            <td>₹100</td>
            <td>987654321</td>
            <td>
              <Badge bg="danger" variant="danger">
                Inactive
              </Badge>
            </td>
            <td>-</td>
            <td>15 February 2013</td>
            {/* <td>15 February 2014</td> */}
          </tr>
          <tr className="bg-light">
            <td>3</td>
            <td>₹200</td>
            <td>135792468</td>
            <td>
              <Badge variant="success">Active</Badge>
            </td>
            <td>Alice Johnson</td>
            <td>20 March 2014</td>
            {/* <td>20 March 2015</td> */}
          </tr>
          <tr className="bg-light">
            <td>4</td>
            <td>₹150</td>
            <td>246813579</td>
            <td>
              <Badge bg="danger" variant="danger">
                Inactive
              </Badge>
            </td>
            <td>-</td>
            <td>25 April 2015</td>
            {/* <td>25 April 2016</td> */}
          </tr>
          <tr className="bg-light">
            <td>5</td>
            <td>₹250</td>
            <td>369258147</td>
            <td>
              <Badge variant="success">Active</Badge>
            </td>
            <td>Eva Brown</td>
            <td>30 May 2016</td>
            {/* <td>30 May 2017</td> */}
          </tr>
          <tr className="bg-light">
            <td>6</td>
            <td>₹300</td>
            <td>481516234</td>
            <td>
              <Badge bg="danger" variant="danger">
                Inactive
              </Badge>
            </td>
            <td>-</td>
            <td>5 June 2017</td>
            {/* <td>5 June 2018</td> */}
          </tr>
        </tbody>
      </Table>

      <Row className="mt-3">
        <Col>
          <h5>Gift Received</h5>
        </Col>
      </Row>

      <Table
        className="mt-3 text-center"
        responsive
        striped
        style={{
          borderRadius: "25px",
          backgroundColor: "var(--color-primary)",
        }}
      >
        <thead>
          <tr>
            <th className="text-white">S. no.</th>
            <th className="text-white">Gift Price</th>
            <th className="text-white">Gift Number</th>
            <th className="text-white">Gift Status</th>
            <th className="text-white">Gift Sender</th>
            <th className="text-white">Purchase Date</th>
            <th className="text-white">Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-light">
            <td>1</td>
            <td>₹50</td>
            <td>123456789</td>
            <td>
              <Badge variant="success">Added</Badge>
            </td>
            <td>Jane Doe</td>
            <td>12 January 2012</td>
            <td>12 January 2013</td>
          </tr>
          <tr className="bg-light">
            <td>2</td>
            <td>₹100</td>
            <td>987654321</td>
            <td>
              <Badge bg="danger" variant="danger">
                Failed
              </Badge>
            </td>
            <td>John Smith</td>
            <td>15 February 2013</td>
            <td>15 February 2014</td>
          </tr>
          <tr className="bg-light">
            <td>3</td>
            <td>₹200</td>
            <td>135792468</td>
            <td>
              <Badge variant="success">Added</Badge>
            </td>
            <td>Alice Johnson</td>
            <td>20 March 2014</td>
            <td>20 March 2015</td>
          </tr>
          <tr className="bg-light">
            <td>4</td>
            <td>₹150</td>
            <td>246813579</td>
            <td>
              <Badge bg="danger" variant="danger">
                Failed
              </Badge>
            </td>
            <td>Bob Williams</td>
            <td>25 April 2015</td>
            <td>25 April 2016</td>
          </tr>
          <tr className="bg-light">
            <td>5</td>
            <td>₹250</td>
            <td>369258147</td>
            <td>
              <Badge variant="success">Added</Badge>
            </td>
            <td>Eva Brown</td>
            <td>30 May 2016</td>
            <td>30 May 2017</td>
          </tr>
          <tr className="bg-light">
            <td>6</td>
            <td>₹300</td>
            <td>481516234</td>
            <td>
              <Badge bg="danger" variant="danger">
                Failed
              </Badge>
            </td>
            <td>Michael Davis</td>
            <td>5 June 2017</td>
            <td>5 June 2018</td>
          </tr>
        </tbody>
      </Table>

      <ParentFooter />

      <Modal show={show} size="md" onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Share Gift</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-4 ">
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#3B5998",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaFacebookF className="fs-4 text-white " />
            </div>
            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#007FB1",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaLinkedinIn className="fs-4 text-white " />
            </div>

            <div
              style={{
                height: "45px",
                width: "45px",
                borderRadius: "50%",
                backgroundColor: "#25D366",
              }}
              className="d-flex align-items-center justify-content-center "
            >
              <FaWhatsapp className="fs-4 text-white " />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col py-2 rounded bg-black d-md-flex align-items-md-center justify-content-between ">
              <p style={{ fontSize: 13 }} className="fw-bold m-0 text-white">
                https://www.urjatalents.com/gift
              </p>

              <button className="btn btn-sm btn-primary mt-md-0 mt-2">
                copy
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .gift_hero {
    background-image: url("https://images.unsplash.com/photo-1512909006721-3d6018887383?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    border-radius: 25px;
  }
  .share {
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: fit-content !important;
  }
  .share_box {
    cursor: pointer;
  }
  .boxes {
    position: absolute;
    left: 30px;
    bottom: 30px;
  }
`;

export default GiftPage;
