import KRAPage from "./KRAPage";

export const KRAPageConfig = {
  routes: [
    {
      path: "/operation/KRA",
      exact: true,
      component: KRAPage
    }
  ]
};
