// ParentsFees
import ParentsFeesPage from "./ParentsFeesPage";

export const ParentsFeesPageConfig = {
  routes: [
    {
      path: "/account/parent-fee",
      exact: true,
      component: ParentsFeesPage
    }
  ]
};
