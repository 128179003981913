import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

const AccountDeletionRequests = () => {
  const [lists, setLists] = useState([]);
  const token = useSelector((state) => state.token.value);

  const fetchAccountDeletionRequests = async () => {
    try {
      const res = await axios.get("/admin/parent/delete-req", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res.data);

      if (res.data.res_type === "success") {
        setLists(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAccountDeletionRequests();
  }, [lists]);

  return (
    <>
      <h5>Parent Account Deletion Requests</h5>

      <Table
        className="mt-2 mb-3 rounded-4"
        style={{ backgroundColor: "var(--color-primary)" }}
        striped
        responsive
        hover
      >
        <thead>
          <tr className="text-center">
            <th className="text-white text-truncate">S. No.</th>
            <th className="text-white text-truncate">Parent ID</th>
            <th className="text-white text-truncate">Parent Name</th>
            <th className="text-white text-truncate">Email</th>
            <th className="text-white text-truncate">Phone</th>
            <th className="text-white text-truncate">Address</th>
            <th className="text-white text-truncate">Pincode</th>
            <th className="text-white text-truncate">Action</th>
          </tr>
        </thead>
        <tbody>
          {lists.map((list, index) => (
            <tr className="text-center bg-light" key={index}>
              <td className="text-truncate">{index + 1}.</td>
              <td className="text-truncate">{list.uid}</td>
              <td className="text-truncate">
                {list.firstName} {list.lastName}
              </td>
              <td className="text-truncate">{list.email}</td>
              <td className="text-truncate">{list.phone}</td>
              <td className="text-truncate">{list.address}</td>
              <td className="text-truncate">{list.pincode}</td>
              <td className="emp_mngmnt-drop text-truncate">
                <button className="btn btn-danger btn-sm">Delete</button>
                <button className="btn btn-sm btn-success ms-3">Approve</button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AccountDeletionRequests;
