// Behaviour
import BehaviourPage from "./BehaviourPage";

export const BehaviourPageConfig = {
  routes: [
    {
      path: "/operation/Behaviour",
      exact: true,
      component: BehaviourPage
    }
  ]
};
