import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const EChartsGraph = ({ id, data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ["Leads", "Total Leads"],
      },
      xAxis: [
        {
          type: "category",
          data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "Leads",
          min: 0,
          max: 250,
          interval: 50,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          name: "Total Leads",
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: data.map((seriesData, index) => ({
        name: seriesData.name,
        type: seriesData.type,
        yAxisIndex: seriesData.yAxisIndex || 0,
        tooltip: {
          formatter: seriesData.tooltip
            ? seriesData.tooltip.valueFormatter
            : "{value}",
        },
        data: seriesData.data,
      })),
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [data]);

  return (
    <div id={id} ref={chartRef} style={{ width: "100%", height: "400px" }} />
  );
};

export default EChartsGraph;
