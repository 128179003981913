import OtherReqPage from "./OtherReqPage";

export const OtherReqPageConfig = {
  routes: [
    {
      path: "/account/dashboard/other-req",
      exact: true,
      component: OtherReqPage
    }
  ]
};
