import React, { useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import { Col, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import Upload from "../courseManagement/Upload";
import Progress from "../courseManagement/Progress";
import ParentFooter from "../../components/ParentFooter";
import Editor from "react-simple-wysiwyg";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const AddBlog = () => {
  const token = useSelector((state) => state.token.value);
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => setFiles(acceptedFiles),
  });

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleAddBlog = async () => {
    if (!title) {
      toast.warn("Please enter title.");
      return;
    }

    if (!description) {
      toast.warn("Please enter description.");
      return;
    }
    if (!editorContent) {
      toast.warn("Please enter content.");
      return;
    }

    if (!files && files?.length === 0) {
      toast.warn("Please select at least one file.");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("intro", description);
    formData.append("description", editorContent);
    formData.append("thumbnail", files[0]);
    setLoading(true);
    try {
      let res = await axios.post("/teacher/blog", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let { res_type, status, message, error } = res.data || res;
      if (res_type === "success") {
        toast.success("Blog created successfully.");
        setTitle("");
        setDescription("");
        setEditorContent("");
        setFiles([]);
        navigate("/teacher/dashboard/pages/blog");
      } else {
        toast.error(message || error || "Server Error please try again later.");
      }
      console.log(res.data);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TeacherLayout>
      <h3>Blog</h3>

      <StyledContainer>
        <Row className="mt-4">
          <Col md={12}>
            <input
              type="text"
              className="form-control shadow"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
          <Col md={12} className="mt-4">
            <textarea
              className="form-control shadow"
              placeholder="Description"
              rows="5"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </Col>
          <Col md={12} className="mt-4">
            {/* <Editor
              className="shadow border-yellow"
              value={editorContent}
              onChange={setEditorContent}
              containerProps={{
                style: {
                  resize: "vertical",
                  minHeight: "40vh",
                  marginInline: "auto",
                },
              }}
            /> */}
            <ReactQuill
              value={editorContent}
              onChange={setEditorContent}
              modules={modules}
              formats={formats}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h3>Thumbnail</h3>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={6}>
            <div
              style={{ height: "40vh" }}
              {...getRootProps({
                className:
                  "dropzone d-flex align-items-center justify-content-center flex-row",
              })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {files?.length > 0 && (
              <ul>
                {files.map((file) => (
                  <li key={file.name}>{file.name}</li>
                ))}
              </ul>
            )}
          </Col>
          <Col md={6} className="pt-5">
            <Progress />
          </Col>
        </Row>

        <Row className="mt-5 d-flex justify-content-end pe-3">
          <button onClick={!loading && handleAddBlog} className="publish_btn">
            {loading ? (
              <Spinner animation="border" role="status" size="sm"></Spinner>
            ) : (
              "Request & Publish"
            )}
          </button>
        </Row>
      </StyledContainer>
      <ParentFooter />
    </TeacherLayout>
  );
};

const StyledContainer = styled.div`
  .form-control {
    border: 1px solid var(--color-primary) !important;
    border-radius: 10px !important;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: start !important;
  }

  .publish_btn {
    background-color: var(--color-primary);
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
    line-height: 24px;
    padding: 10px 20px;
    border: none;
    color: white;
  }

  .dropzone {
    border: 2px dashed var(--color-primary);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
`;

export default AddBlog;
