import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";

const ConversationPage = (props) => {
  const { history } = props;
  const [selectedOption, setSelectedOption] = useState('grade');
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <OperationLayout>
      <h5>Manage Rate Calculator</h5>

      <Row>
        <Col md={3}>
          <Form.Select className="shadow rounded-5" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
            <option value="grade">Grade</option>
            <option value="duration">Duration</option>
            <option value="teacherType">Teacher Type</option>
          </Form.Select>
        </Col>
        <Col md={6}></Col>
        <Col md={3} className="d-flex align-content-center justify-content-end">
          <Button className="new-button rounded-5 border-0" onClick={handleShowModal}>Add {selectedOption}</Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={3} className="text-bold">Title</Col>
        <Col md={3} className="text-bold">Value</Col>
        <Col md={6} className="text-bold">
          Status
        </Col>
      </Row>
      {
        selectedOption === 'grade' && (
          <Row className="mt-3">
            <Col md={3}>Grade 1</Col>
            <Col md={3}>Value 1</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>Grade 2</Col>
            <Col md={3}>Value 2</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>Grade 3</Col>
            <Col md={3}>Value 3</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
          </Row>
        )
      }

      {
        selectedOption === 'duration' && (
          <Row className="mt-3">
            <Col md={3}>duration 1</Col>
            <Col md={3}>Value 1</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>duration 2</Col>
            <Col md={3}>Value 2</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>duration 3</Col>
            <Col md={3}>Value 3</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
          </Row>
        )
      }

{
        selectedOption === 'teacherType' && (
          <Row className="mt-3">
            <Col md={3}>Teacher Type 1</Col>
            <Col md={3}>Value 1</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>Teacher Type 2</Col>
            <Col md={3}>Value 2</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
            <Col md={3}>Teacher Type 3</Col>
            <Col md={3}>Value 3</Col>
            <Col md={6}>
              <Form.Switch
                id="statusSwitch"
              />
            </Col>
          </Row>
        )
      }


      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} style={{ marginTop: '20vh' }}>
        <Modal.Header closeButton>
          <Modal.Title>Add {selectedOption}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Enter title" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="value">
              <Form.Label>Value</Form.Label>
              <Form.Control type="text" placeholder="Enter value" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Select>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* Add Save or Submit button here */}
        </Modal.Footer>
      </Modal>
    </OperationLayout>
  );
};

export default ConversationPage;
