import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Modal, Row } from "react-bootstrap";
import Card1 from "../parent/Card1";
import card1 from "../../assets/parent/card1.png";
import { MdOutlineClose } from "react-icons/md";
import { TiBook, TiTick } from "react-icons/ti";
import { FaRegCalendarTimes, FaRegStar, FaStar } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import FeedbackModal from "./FeedbackModal";
import { Button } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";
import { FiHeart } from "react-icons/fi";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { IconButton } from "@material-ui/core";
import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import c4 from "../../assets/cc4.jpg";

const FreeDemo = (props) => {
  const { history } = props;
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <OftadehLayout>
      <h1>Free Demo</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ zIndex: "1111", marginTop: "10vh" }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Manual Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mt-3" md={4}>
              <Form.Label>Child Name</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="John Doe"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Age</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="18 years"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>STD</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="123-122-1232"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Mobile No.</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="text"
                placeholder="+91 1234567890"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="shadow rounded-5"
                type="email"
                placeholder="example@email.com"
              />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Select Courses</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="course1">Course 1</option>
                  <option value="course2">Course 2</option>
                  <option value="course3">Course 3</option>
                  <option value="course4">Course 4</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Courses Mode</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">Online</option>
                  <option value="Offline">Offline</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Courses Shift</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">Day</option>
                  <option value="Offline">Night</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Preferred Teacher</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">teacher 1</option>
                  <option value="Offline">teacher 2</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Select Child</Form.Label>
                <Form.Control className="shadow rounded-5" as="select">
                  <option value="Online">child 1</option>
                  <option value="Offline">child 2</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Preferred Date</Form.Label>
              <Form.Control className="shadow rounded-5" type="date" />
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Label>Preferred Time</Form.Label>
              <Form.Control className="shadow rounded-5" type="time" />
            </Col>
            <Col className="mt-3" md={4}>
              <hr className="bg-transparent border-0" />
              {/* <Button className='shadow rounded-5 border-warning text-warning' variant="outlined">Book Demo</Button> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            className="me-3 bg-danger text-white border-danger"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          {/* You can add additional buttons or functionality here */}
          <Button variant="outlined" onClick={handleCloseModal}>
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="justify-content-between">
        <Col md={3}>
          <Form.Control
            className="shadow rounded-5"
            type="text"
            placeholder="Search"
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <button
            onClick={handleButtonClick}
            className="new-button"
            variant="outlined"
          >
            Manual Demo
          </button>
        </Col>
      </Row>

      <Row className="mt-3 p-3 border border-warning rounded-3 shadow">
        <Col>
          <p>You have 3 free demo's after that all demo's will be 100/- INR.</p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h3 className="mb-4">Enroll past Demo (3 demo free)</h3>
        </Col>
      </Row>

      <Row>
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} feedbackbtn={true} completed={true} />
          </SwiperSlide>
        </Swiper>
      </Row>

      <Row className="mt-5">
        <Col>
          <h3 className="mb-4">Today's Demo</h3>
        </Col>
      </Row>

      <Row>
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c1} joinbtn={true} />
          </SwiperSlide>
        </Swiper>
      </Row>

      <Row className="mt-3">
        <Col>
          <h3 className="mb-4">Upcoming Demo</h3>
        </Col>
      </Row>

      <Row className="position-relative pe-5">
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} paybtn={true} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c2} paybtn={true} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c3} paybtn={true} joinbtn={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c4} paybtn={true} joinbtn={true} />
          </SwiperSlide>
        </Swiper>
      </Row>

      <Row className="mt-3">
        <Col>
          <h3 className="mb-4">Previous Demos</h3>
        </Col>
      </Row>

      <Row className="position-relative pe-5">
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} enroll={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c2} enroll={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c3} enroll={true} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c4} enroll={true} />
          </SwiperSlide>
        </Swiper>
      </Row>

      <Row className="mt-3">
        <Col>
          <h3 className="mb-4">Suggested Courses</h3>
        </Col>
      </Row>

      <Row className="position-relative pe-5">
        <Swiper
          slidesPerView={window.innerWidth > 768 ? 3 : 1}
          spaceBetween={20}
          modules={[Navigation, Autoplay, Pagination]}
          grabCursor={true}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className="mySwiper pt-4 pb-5"
        >
          <SwiperSlide>
            <Cards img={c1} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c2} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c3} />
          </SwiperSlide>
          <SwiperSlide>
            <Cards img={c4} />
          </SwiperSlide>
        </Swiper>
      </Row>
      <ParentFooter />
    </OftadehLayout>
  );
};

export default FreeDemo;

const Cards = ({ img, paybtn, joinbtn, feedbackbtn, completed, enroll }) => {
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0);

  const toggleModal = () => {
    setShow(!show);
  };

  const handleStarClick = (starCount) => {
    setRating(starCount);
  };

  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img
          style={{ width: "90%" }}
          className="cource_img rounded-3 px-3"
          src={img}
          alt=""
        />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
        </div>

        <h5 className="mt-3 px-3">
          Learn Mandarin - Beginner <br /> Level
        </h5>

        <p className="mx-3">demo#00njknasd89719uuij</p>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img">
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
          </div>
            <p className="m-0 text-secondary">Robert Fox</p>
          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        <div
          className={` ${
            feedbackbtn || paybtn || completed || joinbtn ? "bottom mt-2" : ""
          }  py-2 px-3 d-flex align-items-center justify-content-between`}
        >
          {feedbackbtn && (
            <button className="py-1 px-3" onClick={toggleModal}>
              Feedback
            </button>
          )}

          {paybtn && <button className="py-1 px-3">pay</button>}

          {completed && (
            <span
              className="badge"
              style={{ background: "var(--color-primary)" }}
            >
              90% Completed
            </span>
          )}
          {joinbtn && <button className="py-1 px-3">Join Now</button>}
        </div>

        {enroll && (
          <div className="bottom mt-2  py-2 px-3 d-flex align-items-center justify-content-between">
            <Form.Group controlId="classSelect">
              <Form.Control
                className="rounded-5"
                style={{ width: 100 }}
                as="select"
              >
                <option selected disabled>
                  Enroll
                </option>
                <option value={4}>4</option>
                <option value={8}>8</option>
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={30}>30</option>
              </Form.Control>
            </Form.Group>
            <button className="py-1 px-3">Proceed</button>
          </div>
        )}

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
          </p>
        </div>
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={toggleModal}
        style={{ zIndex: "1111", marginTop: "7vh" }}
      >
        <Modal.Header className="border-0 border-bottom" closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Col className="ps-2 m-0">
                <h5>Attitude</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={6}>
              <Col className="ps-2 m-0">
                <h5>Presentability</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={6} className="mt-3">
              <Col className="ps-2 m-0">
                <h5>Doubt Clearing</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={6} className="mt-3">
              <Col className="ps-2 m-0">
                <h5>Communication Skill</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={6} className="mt-3">
              <Col className="ps-2 m-0">
                <h5>Punctuality</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={6} className="mt-3">
              <Col className="ps-2 m-0">
                <h5>Knowledge Level</h5>
              </Col>
              <Col className="d-flex align-items-center justify-content-start">
                {[1, 2, 3, 4, 5].map((index) => (
                  <IconButton
                    key={index}
                    aria-label="rating"
                    onClick={() => handleStarClick(index)}
                    style={{
                      color: index <= rating ? "var(--yellow-color)" : "",
                    }}
                  >
                    {index <= rating ? <FaStar /> : <FaRegStar />}
                  </IconButton>
                ))}
              </Col>
            </Col>
            <Col md={12} className="mt-3">
              <Form.Group controlId="textareaInput">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  className="shadow rounded-3"
                  as="textarea"
                  rows={3}
                  placeholder="Leave a Comment."
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="btn btn-secondary rounded-5 shadow"
            onClick={toggleModal}
          >
            Close
          </button>
          <button
            className="btn btn-primary rounded-5 shadow"
            onClick={toggleModal}
          >
            Next Demo
          </button>
          <button className="new-button" onClick={toggleModal}>
            Enroll Now
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
