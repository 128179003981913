import React, { useState } from 'react'
import OftadehLayout from '../../components/parentComponents/OftadehLayout/OftadehLayout'
import OftadehBreadcrumbs from '../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs'
import { Badge, Col, Form, Row, Table } from 'react-bootstrap'
import { Button, IconButton } from '@mui/material'
import { MdCallReceived } from 'react-icons/md'
import { FiDownload } from 'react-icons/fi'
import { FaRupeeSign } from 'react-icons/fa'
import { LiaAmazonPay } from 'react-icons/lia'
import { VscFeedback } from 'react-icons/vsc'
import FeedbackModal from '../freeDemo/FeedbackModal'
import { AiTwotoneLike } from 'react-icons/ai'
import { BiSolidLike } from 'react-icons/bi'
import { CiCalendar } from 'react-icons/ci'
import ParentFooter from '../../components/ParentFooter'

function ManualDemoPage(props) {
  const { history } = props
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [like1, setLike1] = useState(false)

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal)
  }
  return (
    <OftadehLayout>
      <h4>Manual Demo</h4>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row>
        <Col className='mt-3' md={4}>
          <Form.Label>Child Name</Form.Label>
          <Form.Control
            className='shadow rounded-5'
            type='text'
            placeholder='John Doe'
          />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Label>Age</Form.Label>
          <Form.Control
            className='shadow rounded-5'
            type='text'
            placeholder='18 years'
          />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Label>STD</Form.Label>
          <Form.Control
            className='shadow rounded-5'
            type='text'
            placeholder='123-122-1232'
          />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Label>Mobile No.</Form.Label>
          <Form.Control
            className='shadow rounded-5'
            type='text'
            placeholder='+91 1234567890'
          />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            className='shadow rounded-5'
            type='email'
            placeholder='example@email.com'
          />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Label>Select Courses</Form.Label>
            <Form.Control className='shadow rounded-5' as='select'>
              <option value='course1'>Course 1</option>
              <option value='course2'>Course 2</option>
              <option value='course3'>Course 3</option>
              <option value='course4'>Course 4</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Label>Courses Mode</Form.Label>
            <Form.Control className='shadow rounded-5' as='select'>
              <option value='Online'>Online</option>
              <option value='Offline'>Offline</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Label>Courses Shift</Form.Label>
            <Form.Control className='shadow rounded-5' as='select'>
              <option value='Online'>Day</option>
              <option value='Offline'>Night</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Label>Preferred Teacher</Form.Label>
            <Form.Control className='shadow rounded-5' as='select'>
              <option value='Online'>teacher 1</option>
              <option value='Offline'>teacher 2</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Label>Select Child</Form.Label>
            <Form.Control className='shadow rounded-5' as='select'>
              <option value='Online'>child 1</option>
              <option value='Offline'>child 2</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className='mt-3'>
        <h6>Schedule Time</h6>
      </Row>
      <Row>
        <Col className='mt-3' md={4}>
          <Form.Label>Preferred Date</Form.Label>
          <Form.Control className='shadow rounded-5' type='date' />
        </Col>
        <Col className='mt-3' md={4}>
          <Form.Label>Preferred Time</Form.Label>
          <Form.Control className='shadow rounded-5' type='time' />
        </Col>
        <Col className='mt-3' md={4}>
          <hr className='bg-transparent border-0' />
          {/* <Button className='shadow rounded-5 border-warning text-warning' variant="outlined">Book Demo</Button> */}
        </Col>
      </Row>
      {/* {like1 && <> */}

      {/* <Row className='mt-3'>
        <h6>Payment Information</h6>
      </Row>
      <Col className='mt-3  mb-3 px-4 py-3 shadow border border-warning rounded-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center justify-content-start'>
            <span className='p-2 rounded-3 bg-warning me-3'>
              <FaRupeeSign size={22} color='white' />
            </span>
            <div>
              <h5 className='mt-1'>You Need To Pay</h5>
              <span>24 Jan, 2023</span>
            </div>
          </div>
          <h5> ₹ 500</h5>
        </div>
        <Row className='mt-3'>
          <Col className='mt-1' md={6}>
            <b>Transaction I'D</b> : 87ehjw76dsahjgh
          </Col>
          <Col className='mt-1' md={6}>
            <b>Transaction Date</b> : 24 January 2023
          </Col>
          <Col className='mt-1' md={6}>
            <b>UPI I'D</b> : upi@upi.com
          </Col>
          <Col className='mt-1' md={6}>
            <b>Bank Name</b> : City Bank India
          </Col>
          <Col className='mt-1' md={6}>
            <b>Payee</b> : John Doe
          </Col>
          <Col className='mt-1' md={6}>
            <b>Status</b> : <span className='badge  bg-warning'>Pending</span>{' '}
          </Col>
          <Col className='mt-1' md={12}>
            <Form.Group className='mb-3' controlId='formBasicCheckbox'>
              <Form.Check
                type='checkbox'
                label='I agree to the terms and conditions'
              />
            </Form.Group>
          </Col>
          <Col className='mt-1' md={6}>
            <b>Pay Now</b> :
            <Button
              className='ms-3 border-warning text-warning'
              size='small'
              variant='outlined'
              startIcon={<LiaAmazonPay />}
            >
              Pay & book
            </Button>
          </Col>
        </Row>
      </Col> */}
      {/* </>} */}
      {/* <Row className='mt-3 mb-3'>
        <h6>Demos</h6>
      </Row>

      <FeedbackModal
        show={showFeedbackModal}
        toggleModal={toggleFeedbackModal}
      />

      <Table
        className='mt-2 mb-3 rounded-4'
        striped
        responsive
        hover
        style={{ backgroundColor: '#FFD63A' }}
      >
        <thead>
          <tr className='text-center'>
            <th>Demo I'D</th>
            <th>Teacher Name</th>
            <th>Subject</th>
            <th>Demo Date</th>
            <th>Completed Date</th>
            <th>status</th>
            <th>Do You like Demo</th>
            <th>Teacher Feed Back</th>
            <th colSpan={2}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr className='text-center bg-light'>
            <td>demo#0001</td>
            <td>John Doe</td>
            <td>Math's</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>
              {' '}
              <Badge bg='success' variant='success'>
                Completed
              </Badge>
            </td>
            <td>
              <IconButton aria-label='feed-back' onClick={toggleFeedbackModal}>
                <VscFeedback color='blue' />
              </IconButton>
            </td>
            <td onClick={toggleFeedbackModal}>view</td>
            <td>&nbsp;</td>
            <td>
              <Button disabled variant="outlined" size="small">
                send syllabus
              </Button>
            </td>
          </tr>
          <tr className='text-center bg-light'>
            <td>demo#0001</td>
            <td>John Doe</td>
            <td>Math's</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>
              <Badge bg='warning' variant='success'>
                Pending
              </Badge>
            </td>
            <td>
              <IconButton
                disabled
                aria-label='feed-back'
                onClick={toggleFeedbackModal}
              >
                <VscFeedback color='grey' />
              </IconButton>
            </td>
            <td onClick={toggleFeedbackModal}>view</td>
            <td>
              <Button
                className='border-danger bg-danger text-white'
                variant='outlined'
                size='small'
              >
                cancel
              </Button>
            </td>
            <td>
              <Button disabled variant="outlined" size="small">
                send syllabus
              </Button>
            </td>
          </tr>
          <tr className='text-center bg-light'>
            <td>demo#0001</td>
            <td>John Doe</td>
            <td>Math's</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>12 January, 2024 10:00 AM</td>
            <td>
              {' '}
              <Badge bg='danger' variant='success'>
                Canceled
              </Badge>
            </td>
            <td>
              <IconButton
                disabled
                aria-label='feed-back'
                onClick={toggleFeedbackModal}
              >
                <VscFeedback color='grey' />
              </IconButton>
            </td>
            <td onClick={toggleFeedbackModal}>view</td>
            <td>
              <IconButton aria-label='delete'>
                <CiCalendar />
              </IconButton>
            </td>
            <td>
            <Button disabled variant="outlined" size="small">
              send syllabus
            </Button>
          </td>
          </tr>
          
        <tr className='text-center bg-light'>
          <td>demo#0001</td>
          <td>John Doe</td>
          <td>Math's</td>
          <td>12 January, 2024 10:00 AM</td>
          <td>12 January, 2024 10:00 AM</td>
          <td>
            <Badge bg='secondary'>Accepted</Badge>
          </td>
          <td>
            <IconButton
              disabled
              aria-label='feed-back'
              onClick={toggleFeedbackModal}
            >
              <VscFeedback color='grey' />
            </IconButton>
          </td>
          <td onClick={toggleFeedbackModal}>view</td>
          <td>
            <Button
              className='border-danger bg-danger text-white'
              variant='outlined'
              size='small'
            >
              cancel
            </Button>
          </td>
          <td>
            <Button variant='outlined' size='small'>
              send syllabus
            </Button>
          </td>
        </tr>
      </tbody>
    </Table> */}
    <ParentFooter />
    </OftadehLayout >
  )
}

export default ManualDemoPage
