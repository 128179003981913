import React, { useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const Facilities = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <FacilitiesContainer>
        <div className="hero d-flex align-items-center justify-content-center">
          <h2 className="fw-bold text-white">Art & Crafts</h2>
        </div>

        <Row className="mt-5 px-5">
          <Col md={3}>
            <div className="sidebar">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-between px-3 py-2 rounded-3 sidebar_link"
              >
                <p className="m-0">Art & Crafts</p>
                <FaArrowRight />
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-between px-3 py-2 rounded-3 sidebar_link mt-2"
              >
                <p className="m-0">Academics</p>
                <FaArrowRight />
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-between px-3 py-2 rounded-3 sidebar_link mt-2"
              >
                <p className="m-0">GK</p>
                <FaArrowRight />
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-between px-3 py-2 rounded-3 sidebar_link mt-2"
              >
                <p className="m-0">Fitness</p>
                <FaArrowRight />
              </Link>
              <Link
                to="#"
                className="d-flex align-items-center justify-content-between px-3 py-2 rounded-3 sidebar_link mt-2"
              >
                <p className="m-0">Music</p>
                <FaArrowRight />
              </Link>
            </div>
          </Col>

          <Col md={9} className="mt-md-0 mt-4">
          
            <Row className="mb-4">
              <Col md={4}>
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1678812165206-688656de3b73?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1664811569206-83cc64fdb676?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1678812165206-688656de3b73?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} className="mt-4">
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1678812165206-688656de3b73?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} className="mt-4">
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1664811569206-83cc64fdb676?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4} className="mt-4">
                <div className="rounded-4 shadow overflow-hidden">
                  <img
                    src="https://plus.unsplash.com/premium_photo-1678812165206-688656de3b73?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body p-3">
                    <p className="m-0">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Ex libero sed exercitationem ab voluptatum fugiat aliquid
                      aut enim amet rerum.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </FacilitiesContainer>
      <Footer />
    </>
  );
};

const FacilitiesContainer = styled.div`
  margin-top: 8vmax;
  overflow-x: hidden;
  .hero {
    height: 50vh;
    background-image: url("https://plus.unsplash.com/premium_photo-1661767490975-f31a02946f48?q=80&w=2232&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    h2 {
      z-index: 9;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .sidebar_link {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #f7f7f7;
    &:hover {
      background: var(--color-primary-light);
    }
  }

  .card-img-top {
    height: 200px;
    object-fit: cover;
  }
`;

export default Facilities;
