import React, { useEffect, useState } from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Row, Form, Button as BootstrapButton } from "react-bootstrap";
import { Button, Badge } from "@mui/material";
import ParentFooter from "../../components/ParentFooter";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TeacherLeave = (props) => {
  const token = useSelector((state) => state.token.value);
  const { history } = props;
  const navigate = useNavigate();

  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [leaveDocument, setLeaveDocument] = useState(null);
  const [leaveReason, setLeaveReason] = useState("");
  const [loading, setLoading] = useState(false);

  const [leaves, setLeaves] = useState([]);
  const [dataLoading, setDataLoading] = useState(true); // Add loading state

  const fetchLeaves = async () => {
    setDataLoading(true); // Set loading to true before the request
    try {
      let res = await axios.get("/teacher/leave", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      let { res_type, message, data } = res.data || res;
      if (res_type === "success") {
        setLeaves(data.leaves);
      } else {
        toast.error(message || "Server error please try again later.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDataLoading(false); // Set loading to false after the request
    }
  };

  useEffect(() => {
    fetchLeaves();
  }, []);

  const ApplyForLeave = async () => {
    if (!token) {
      toast.error("Please login first");
      return;
    }

    if (!fromDate) {
      toast.warn("Please select from date.");
      return;
    }
    if (!toDate) {
      toast.warn("Please select to date.");
      return;
    }
    if (!leaveReason) {
      toast.warn("Please enter leave reason.");
      return;
    }
    if (!leaveDocument) {
      toast.warn("Please upload leave document.");
      return;
    }

    //new form data
    const formData = new FormData();
    formData.append("date_from", fromDate);
    formData.append("date_to", toDate);
    formData.append("reason", leaveReason);
    formData.append("document", leaveDocument);

    setLoading(true);

    try {
      let res = await axios.post("/teacher/leave", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let { res_type, status, message, error } = res.data || res;
      if (res_type === "success") {
        toast.success("Request Sent Successfully.");
        setToDate("");
        setFromDate("");
        setLeaveReason("");
        setLeaveDocument(null);
        fetchLeaves(); // Refresh the list of leaves after successful submission
      } else {
        toast.error(message || error || "Server Error please try again later.");
      }
      console.log(res.data || res);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  function formatDateRange(dateFrom, dateTo) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Parse the input dates
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);

    // Extract day and month
    const fromDay = fromDate.getDate();
    const fromMonth = months[fromDate.getMonth()];
    const toDay = toDate.getDate();
    const toMonth = months[toDate.getMonth()];

    // Format the date range
    let formattedRange;
    if (fromMonth === toMonth) {
      // Same month
      formattedRange = `${fromDay} - ${toDay} ${fromMonth}`;
    } else {
      // Different months
      formattedRange = `${fromDay} ${fromMonth} - ${toDay} ${toMonth}`;
    }

    return formattedRange;
  }

  return (
    <TeacherLayout>
      <h3>Teacher Leave</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row className="mt-3">
        <Col>
          <h5>Apply for Leave</h5>
        </Col>
      </Row>
      <Row className="mt-3 rounded-4 shadow bg-body-tertiary p-4">
        <Form.Group className="col-md-6 mt-3">
          <Form.Label htmlFor="fromDate">From</Form.Label>
          <Form.Control
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control rounded-5 border-yellow mt-2"
            id="fromDate"
            placeholder="From Date"
          />
        </Form.Group>
        <Form.Group className="col-md-6 mt-3">
          <Form.Label htmlFor="toDate">To</Form.Label>
          <Form.Control
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="form-control rounded-5 border-yellow mt-2"
            id="toDate"
            placeholder="To Date"
          />
        </Form.Group>
        <Form.Group className="col-md-6 mt-3">
          <Form.Label htmlFor="leaveReason">Reason</Form.Label>
          <Form.Control
            type="text"
            value={leaveReason}
            onChange={(e) => setLeaveReason(e.target.value)}
            className="form-control rounded-5 border-yellow mt-2"
            id="leaveReason"
            placeholder="Reason"
          />
        </Form.Group>
        <Form.Group className="col-md-6 mt-3">
          <Form.Label htmlFor="leaveDocument">Document</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setLeaveDocument(e.target.files[0])}
            className="form-control rounded-5 border-yellow mt-2"
            id="leaveDocument"
            placeholder="Document"
          />
        </Form.Group>
        <Form.Group className="col-md-12 mt-4">
          <BootstrapButton
            disabled={loading}
            onClick={ApplyForLeave}
            className="btn rounded-5 text-white w-100"
            style={{ background: "var(--color-primary)" }}
          >
            {loading ? (
              // React Bootstrap loading spinner
              <div className="spinner-border text-light md-sm sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Apply"
            )}
          </BootstrapButton>
        </Form.Group>
      </Row>

      <Row className="mt-4">
        <Col>
          <h5>Past Requests</h5>
        </Col>
      </Row>

      {dataLoading ? (
        <div className="mt-3">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : leaves && leaves?.length > 0 ? (
        leaves.map((leave, index) => (
          <Row
            key={index}
            className="ps-2 border-yellow rounded-3 mt-3 d-flex align-items-center justify-content-between"
            style={{ height: "7vh" }}
          >
            <Col
              md={4}
              className="text-start d-flex align-items-center justify-content-start"
            >
              <b>{leave && formatDateRange(leave.date_from, leave.date_to)}</b>
            </Col>
            <Col
              md={4}
              className="text-start d-flex align-items-center justify-content-start"
            >
              <b>{leave.reason ?? "Reason not found."}</b>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center"
            >
              <Badge
                variant="outlined"
                color={
                  leave.status === "approved"
                    ? "success"
                    : leave.status === "pending"
                    ? "warning"
                    : "error"
                }
              >
                {leave.status.charAt(0).toUpperCase() + leave.status.slice(1)}
              </Badge>
            </Col>
          </Row>
        ))
      ) : (
        <Col className="mt-3 text-center">
          <b>No leave requests found.</b>
        </Col>
      )}

      <ParentFooter />
    </TeacherLayout>
  );
};

export default TeacherLeave;
