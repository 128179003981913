import React, { useEffect, useState } from 'react'
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Badge, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { CiEdit } from 'react-icons/ci';
import { formattedDate, MyAPI, truncateText } from '../../utils/MyAPI';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
function CoursesRequests() {
    const token = useSelector((state) => state.token.value);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState('pending');
    const [reason, setReason] = useState('');

    const [dataLoading, setDataLoading] = useState(false)
    const [AllActiveCourses, setAllActiveCourses] = useState([])
    const [AllRequestedCourses, setAllRequestedCourses] = useState([])


    const fetchCourses = async (
        {
            status = "pending",
        },
        token
    ) => {
        try {
            // Construct the query string based on the passed parameters
            const queryParams = new URLSearchParams({
                status,
            }).toString();
            setDataLoading(true);

            const res = await MyAPI.GET(
                `/common/course-manage?${queryParams}`,
                token
            );
            let { res_type, message, data: apiData } = res.data || res;

            if (res_type === "success") {
                setAllActiveCourses(
                    apiData.filter((course) => course.status === "active")
                );
                setAllRequestedCourses(
                    apiData.filter((course) => course.status === "pending")
                );
            } else {
                toast.error(message || "Error Fetching Data.");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses({ status }, token)
    }, [status])


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can implement the logic to handle the submission
        console.log('New Status:', status);
        console.log('Reason:', reason);
        // Close the modal after submission
        handleClose();
    };

    const [currentData, setCurrentData] = useState(null)

    const [editStatus, setEditStatus] = useState('pending')

    const handleEdit = (data) => {
        setCurrentData(data)
        handleShow()
    }

    const [btnLoading, setBtnLoading] = useState(false)

    const handleChangeStatus = async (status, courseData) => {
        try {
            let payload = {
                status: status
            }
            setBtnLoading(true)
            let res = await MyAPI.PUT(`/common/course-manage?id=${courseData._id}`, payload, token)
            let { res_type, message, data: apiData } = res.data || res;
            if (res_type === 'success') {
                if (status === 'active') {
                    toast.success('Course Accepted.')
                } else {
                    toast.success('Course Rejected.')
                }
                setStatus('active')
                setEditStatus('pending')
                setCurrentData(null);
                handleClose()
            } else {
                toast.error(message || 'Error Changing Status...!')
            }
        } catch (error) {
            toast.error(error.message)
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <OperationLayout>
            <h5>Courses Requests</h5>
            <Row className='justify-content-between'>
                <Col md={3}>
                    <Form.Group>
                        <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} className='rounded-5 shadow mt-3 mb-3'>
                            <option value="pending">Pending</option>
                            <option value="active">Accepted</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Control
                            className='shadow rounded-5 mt-3 mb-3'
                            type="text"
                            name="search"
                            placeholder="Search..."
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Table className='mt-3' responsive striped style={{ borderRadius: '25px', backgroundColor: 'var(--color-primary)' }}>
                <thead>
                    <tr className="text-center">
                        <th className="text-white">No.</th>
                        <th className="text-white">I'D</th>
                        {/* <th className="text-white">Name</th> */}
                        <th className="text-white">Title</th>
                        <th className="text-white">Status</th>
                        {status === 'pending' && (
                            <th className="text-white">Action</th>
                        )}
                        <th className="text-white">Date/Time</th>
                    </tr>
                </thead>
                <tbody>
                    { //add dataLoading
                        status === 'pending' ? (
                            <>
                                {!dataLoading && AllRequestedCourses && AllRequestedCourses.length > 0 && AllRequestedCourses.map((item, index) => (
                                    <tr key={index} className="bg-light text-center">
                                        <td>{index + 1}</td>
                                        <td>{item._id}</td>
                                        {/* <td>{item.teacher_id}</td> */}
                                        <td className='text-start' >{item.title && truncateText(item.title, 5)}</td>
                                        <td><Badge bg={item.status === 'pending' ? "warning" : "success"}>{item.status}</Badge></td>
                                        <td>
                                            <CiEdit onClick={() => handleEdit(item)} size={22} color={'blue'} className='cursor-pointer' />
                                        </td>
                                        <td>{item.createdAt && formattedDate(item.createdAt)}</td>
                                    </tr>
                                ))}
                                {
                                    dataLoading && (
                                        <tr>
                                            <td colSpan={6} className="text-center">Loading...</td>
                                        </tr>
                                    )
                                }
                                {
                                    !dataLoading && AllRequestedCourses && AllRequestedCourses.length === 0 && (
                                        <tr>
                                            <td colSpan={6} className="text-center">No Data Found</td>
                                        </tr>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                {!dataLoading && AllActiveCourses && AllActiveCourses.length > 0 && AllActiveCourses.map((item, index) => (
                                    <tr key={index} className="bg-light text-center">
                                        <td>{index + 1}</td>
                                        <td>{item._id}</td>
                                        {/* <td>{item.teacher_id}</td> */}
                                        <td className='text-start'>{item.title && truncateText(item.title, 5)}</td>
                                        <td><Badge bg={item.status === 'pending' ? "warning" : "success"}>{item.status}</Badge></td>
                                        {/* <td>
                                            <CiEdit onClick={handleShow} size={22} color={'blue'} className='cursor-pointer' />
                                        </td> */}
                                        <td>{item.createdAt && formattedDate(item.createdAt)}</td>
                                    </tr>
                                ))}
                                {
                                    dataLoading && (
                                        <tr>
                                            <td colSpan={6} className="text-center">Loading...</td>
                                        </tr>
                                    )
                                }
                                {
                                    !dataLoading && AllActiveCourses && AllActiveCourses.length === 0 && (
                                        <tr>
                                            <td colSpan={6} className="text-center">No Data Found</td>
                                        </tr>
                                    )
                                }
                            </>
                        )
                    }
                </tbody>
            </Table>

            <Modal show={showModal} onHide={handleClose} style={{ marginTop: '10vh' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <p>
                            Are you sure you want to &nbsp;
                            {status === 'active' ? 'Activate' : 'Reject'} this course?
                            {currentData && currentData.title}
                        </p>
                        <Form.Group controlId="statusSelect">
                            <Form.Label>Select Status</Form.Label>
                            <Form.Select value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
                                <option value="pending">Pending</option>
                                <option value="active">Accept</option>
                                <option value="blocked">Reject</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="reasonInput">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter reason for status change"
                                value={reason}
                                onChange={handleReasonChange}
                            />
                        </Form.Group>
                        <Button onClick={() => handleChangeStatus(editStatus, currentData)} className='mt-3' variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </OperationLayout>
    )
}

export default CoursesRequests