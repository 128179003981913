import React, { useEffect, useState } from "react";
import { Table, Row, Col, Spinner, Badge, Pagination } from "react-bootstrap";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const TeacherVarify = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [searchTerm, setSearchTerm] = useState(""); // Search state
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const itemsPerPage = 10; // Items per page

  const token = useSelector((state) => state.token.value);

  const navigate = useNavigate();

  const fetchTeachers = async (token) => {
    setLoading(true); // Start loading
    setError(null); // Reset error
    try {
      const res = await axios.get("/admin/teacher", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.res_type === "success") {
        setTeachers(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      setError("Failed to load data. Please try again.");
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (token) {
      fetchTeachers(token);
    }
  }, [token]);

  // console.log(teachers);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Filter teachers by visibleName, email, or uuid
  const filteredTeachers = teachers.filter(
    (teacher) =>
      teacher.visibleName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.uid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastTeacher = currentPage * itemsPerPage;
  const indexOfFirstTeacher = indexOfLastTeacher - itemsPerPage;
  const currentTeachers = filteredTeachers.slice(
    indexOfFirstTeacher,
    indexOfLastTeacher
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {/* Search Input */}
      <Row className="mb-3 justify-content-between align-items-center">
        <Col md={8}>
          <h5>Teacher Verification</h5>
        </Col>
        <Col md={4}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by visible name, email, or uuid"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <div className="text-center text-danger">
          <p>{error}</p>
        </div>
      ) : currentTeachers?.length === 0 ? (
        <div className="text-center">
          <p>No data found</p>
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table
              className="mt-4 mb-3 rounded-4"
              style={{ backgroundColor: "var(--color-primary)" }}
              striped
              responsive
              hover
            >
              <thead>
                <tr className="text-center">
                  <th className="text-white text-truncate">UID</th>
                  <th className="text-white text-truncate">Visible Name</th>
                  <th className="text-white text-truncate">Email</th>
                  <th className="text-white text-truncate">Phone</th>
                  <th className="text-white text-truncate">Gender</th>
                  <th className="text-white text-truncate">Status</th>
                  <th className="text-white text-truncate">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentTeachers.map((teacher) => (
                  <tr className="text-center bg-light" key={teacher.uid}>
                    <td className="text-truncate">{teacher.uid ?? "N/A"}</td>
                    <td className="text-truncate">
                      {teacher.visibleName ?? "N/A"}
                    </td>
                    <td className="text-truncate">{teacher.email ?? "N/A"}</td>
                    <td className="text-truncate">{teacher.phone ?? "N/A"}</td>
                    <td className="text-truncate">{teacher.gender ?? "N/A"}</td>
                    <td className="text-truncate">
                      <Badge
                        bg={teacher.status === "active" ? "success" : "danger"}
                      >
                        {teacher.status}
                      </Badge>
                    </td>
                    <td
                      onClick={() =>
                        navigate("/admin/teacher-verification/details", {
                          state: { teacher },
                        })
                      }
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                    >
                      <FaEye size={22} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Pagination */}
          <Pagination className="justify-content-center">
            {Array.from(
              { length: Math.ceil(filteredTeachers?.length / itemsPerPage) },
              (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </>
      )}
    </>
  );
};

export default TeacherVarify;
