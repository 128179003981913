// CurrencyConversion
import CurrencyConversionPage from "./CurrencyConversionPage";

export const CurrencyConversionPageConfig = {
  routes: [
    {
      path: "/account/currency-conversion",
      exact: true,
      component: CurrencyConversionPage
    }
  ]
};
