import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const IncomeBreakdownGraph = ({ title, data }) => {
    const [selectedPeriod, setSelectedPeriod] = useState('monthly');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const chartDom = document.getElementById('main');
        const myChart = echarts.init(chartDom);

        const option = {
            title: {
                text: title,
                subtext: 'Fake Data',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
                bottom: 10,
                left: 'center',
                data: data.map(item => item.name)
            },
            series: [
                {
                    type: 'pie',
                    radius: '65%',
                    center: ['50%', '50%'],
                    selectedMode: 'single',
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    label: {
                        formatter: '{b}: {c} ({d}%)'
                    }
                }
            ]
        };

        myChart.setOption(option);

        // Clean up
        return () => {
            myChart.dispose();
        };
    }, [title, data]);

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        // Handle logic to fetch or filter data based on selected period
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        // Handle logic to fetch or filter data based on selected date range
    };

    return (
        <div>
            <div className="view-options ps-3 d-flex align-content-center justify-content-between">
                <select value={selectedPeriod} onChange={handlePeriodChange}>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                </select>
                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText="Select date range"
                />
            </div>
            <div id="main" style={{ height: '400px' }}></div>
        </div>
    );
};

export default IncomeBreakdownGraph;
