import React from "react";
import { Row, Col, Form, InputGroup, FormControl, Container, Card, Button } from 'react-bootstrap';
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { FaAmazon, FaLink, FaPlus, FaSearchengin } from "react-icons/fa6";
import './AppliatePage.css';
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";

import { BsFolderSymlinkFill } from "react-icons/bs";
const userImg = require('../../assets/parent/profile-user.jpg');
const AffliatePage = (props) => {
    const { history } = props;
    const cardStyle = {
        minHeight: '20vh',
    };

    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h4>Affliate</h4>
            <div className="min-vh-7 border-yellow rounded-5 shadow d-flex align-items-center justify-content-start">
                <FaSearchengin size={20} className="ms-3" />
                <Col md={11}>
                    <Form>
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search the name of teacher"
                                className="border-0 rounded-0 bg-transparent"
                            />
                        </InputGroup>
                    </Form>
                </Col>
            </div>
            <Container className="shadow rounded-4 border-yellow mt-3 pt-3 mb-3 pb-3">
                <Row>
                    <Col md={4} className="d-flex align-items-center justify-content-center">
                        <img className="rounded-2 shadow" src={userImg} style={{ width: '140px', height: '140px' }} alt="" />
                    </Col>
                    <Col md={8} className="p-0">
                        <div className="affliate-page-earning-pesa">
                            <div className="affliate-page-earning-pesa-items">
                                <div className="affliate-page-earning-pesa-item border-end">
                                    <h5>Total Earning</h5>
                                    <h6>$8347</h6>
                                </div>
                            </div>
                            <div className="affliate-page-earning-pesa-items">
                                <div className="affliate-page-earning-pesa-item border-end">
                                    <h5>Learning Earning</h5>
                                    <h6>$7107</h6>
                                </div>
                            </div>
                            <div className="affliate-page-earning-pesa-items">
                                <div className="affliate-page-earning-pesa-item ">
                                    <h5>Affiliate Earning</h5>
                                    <h6>$847</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="d-flex align-items-center justify-content-center">
                        <h5>Teacher Name</h5>
                    </Col>
                    <Col md={8} className="d-flex align-items-center justify-content-center min-vh-7 rounded-2 yellow border"> <h5>Fix ratio</h5></Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-4 shadow p-2 rounded-3">
                    <Col md={2}>
                        <Card className="border-0 bg-transparent">
                            <Card.Img variant="top" src="https://via.placeholder.com/150" alt="Card Image" style={{ width: '160px', height: '160px', objectFit: 'cover' }} />
                        </Card></Col>
                    <Col md={10}>
                        <Card className="border-0 bg-transparent">
                            <Card.Body>
                                <Card.Title>Heading</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Card.Text>
                                <Button variant="success" className="float-end">Approve</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-4 shadow p-2 rounded-3">
                    <Col md={2}>
                        <Card className="border-0 bg-transparent">
                            <Card.Img variant="top" src="https://via.placeholder.com/150" alt="Card Image" style={{ width: '160px', height: '160px', objectFit: 'cover' }} />
                        </Card></Col>
                    <Col md={10}>
                        <Card className="border-0 bg-transparent">
                            <Card.Body>
                                <Card.Title>Heading</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Card.Text>
                                <Button variant="success" className="float-end">Approve</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-4 shadow p-2 rounded-3">
                    <Col md={2}>
                        <Card className="border-0 bg-transparent">
                            <Card.Img variant="top" src="https://via.placeholder.com/150" alt="Card Image" style={{ width: '160px', height: '160px', objectFit: 'cover' }} />
                        </Card></Col>
                    <Col md={10}>
                        <Card className="border-0 bg-transparent">
                            <Card.Body>
                                <Card.Title>Heading</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Card.Text>
                                <Button variant="success" className="float-end">Approve</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="mt-4 shadow p-2 rounded-3">
                    <Col md={2}>
                        <Card className="border-0 bg-transparent">
                            <Card.Img variant="top" src="https://via.placeholder.com/150" alt="Card Image" style={{ width: '160px', height: '160px', objectFit: 'cover' }} />
                        </Card></Col>
                    <Col md={10}>
                        <Card className="border-0 bg-transparent">
                            <Card.Body>
                                <Card.Title>Heading</Card.Title>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Card.Text>
                                <Button variant="success" className="float-end">Approve</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Row className="mt-4">
                <Col xs={12} md={6}>
                </Col>
                <Col xs={12} md={6}>
                    {/* Right Side: View More Button */}
                    <Button variant="link" className="float-end text-decoration-underline">View More</Button>
                </Col>
            </Row>
            {/* Embed Manual link */}
            <Container className="mt-3">
                <Col className=" shadow mt-3 d-flex align-items-center justify-content-start border-yellow ps-3" style={{ minHeight: '7vh', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                    <h5><FaLink size={22} /> Embed Manual link</h5>
                </Col>
                <Col className="shadow d-flex align-items-start justify-content-start border-yellow p-3" style={{ flexDirection: 'column', gap: '10px' }}>
                    <Row><h6><BsFolderSymlinkFill size={22} /> &nbsp; Embed URL</h6></Row>
                    <h6>Paste a link to the content you want to display</h6>
                    <Col xs={10}>
                        <Form.Group controlId="youtubeLink">
                            <Form.Control className="rounded-5 border-yellow shadow" type="text" placeholder="Enter YouTube link" />
                        </Form.Group>
                    </Col>
                    <Col xs={2} className="ms-3">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Embed</Button>
                    </Col>
                    <Row>
                        <h6><u>Learn More</u></h6>
                    </Row>
                    <Row className="ps-3">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente accusamus sed illum, eos rem modi esse hic expedita, cupiditate quasi repellendus assumenda exercitationem accusantium animi est. Ea mollitia totam voluptatem.
                    </Row>
                    <div className="d-flex">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Try Again</Button>
                        <Button className="ms-3 teacher-button rounded-5 border-0" variant="outlined">Convert To Link</Button>
                    </div>
                </Col>
            </Container>
            <center>
                <Button className="mt-3 mb-3 "  >
                    <IoMdAdd /> Add New Products
                </Button>
            </center>
            <Row className="mt-3 mb-3"><h5>Learnie Mall Books</h5></Row>
            <Container>
                <Row className="mt-4">
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold">Book - 1</h5>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6} md={3} className="mt-2">
                        <Card className="rounded-4 shadow text-center" style={cardStyle}>
                            <Card.Body className="d-flex align-items-center justify-content-center">
                                <h5 className="font-weight-bold ">
                                    <FaPlus size={30} /> Add More Book
                                </h5>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="rounded-5 bg-yellow shadow p-2 mt-3">
                    <Col xs={8} className="d-flex align-items-center">
                        <h6>Connect to Amazon Affiliate</h6>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center justify-content-end">
                        <MdOutlineNavigateNext size={20} />
                    </Col>
                </Row>
            </Container>
        </OperationLayout>
    );
};

export default AffliatePage;
