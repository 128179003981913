import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import { Table } from "react-bootstrap";

const EmployeeAccount = () => {
  return (
    <AccountLayout>
      <h3>Employee Account</h3>

      <Table className="mt-4" striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Employee Name</th>
            <th>Employee ID</th>
            <th>Date of joining</th>
            <th>Role</th>
            <th>Per Day Salary</th>
            <th>Total No of Attendance</th>
            <th>Total No of Leave</th>
            <th>Total No of Halfday</th>
            <th>Company Expenses</th>
            <th>Finally Pay Amount</th>
            <th>Status</th>
            <th>Last Pay Date</th>
            <th>Next Payment Date</th>
            <th>Downloadn Report</th>
            <th>Add Comment</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td>John Deo</td>
                <td>EMP4545</td>
                <td>12/12/2021</td>
                <td>Teacher</td>
                <td>1000</td>
                <td>20</td>
                <td>2</td>
                <td>0</td>
                <td>500</td>
                <td>18000</td>
                <td>Active</td>
                <td>12/12/2021</td>
                <td>12/12/2021</td>
                <td>
                  {" "}
                  <button className="btn btn-sm rounded-4 btn-warning">
                    Download
                  </button>
                </td>
                <td>
                  <input
                    type="text"
                    className="py-1 px-3"
                    placeholder="Add Comment"
                  />
                </td>
                <td>
                  {" "}
                  <button
                    className="btn btn-sm rounded-4 text-white"
                    style={{ background: "var(--color-primary)" }}
                  >
                    Send For Approval
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AccountLayout>
  );
};

export default EmployeeAccount;
