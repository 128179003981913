import React from "react";
import TeacherLayout from "../../components/teacherCompoents/TeacherLayout/TeacherLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
const RateCalculator = (props) => {
    const { history } = props;

    return (
        <TeacherLayout>
            <h3>Rate Calculator</h3>
            {/* <OftadehBreadcrumbs path={history} /> */}

            <Row>
                {/* <div className="form-group col-md-3 mt-3">
                    <label htmlFor="gender">Class Timing:</label>
                    <select className="form-control border-yellow rounded-5" style={{ width: '100%', height: '6vh' }} id="gender">
                        <option value="" disabled selected>Select</option>
                        <option value="male">12 AM</option>
                        <option value="female">12 PM</option>
                        <option value="other">6 Hours</option>
                    </select>
                </div> */}
                <div className="form-group col-md-3 mt-3">
                    {/* <label htmlFor="gender"></label> */}
                    <select className="form-control border-yellow rounded-5" style={{ width: '100%', height: '6vh' }} id="gender">
                        <option value="" disabled selected>Courses</option>
                        <option value="male">IIT</option>
                        <option value="female">JEE</option>
                    </select>
                </div>
                <div class="form-group col-md-3 mt-3">
                    {/* <label for="FirstName">:</label> */}
                    <input type="text" class="form-control rounded-5 border-yellow" id="FirstName" placeholder="Grade" />
                </div>
                <div className="form-group col-md-3 mt-3">
                    {/* <label htmlFor="gender"></label> */}
                    <select className="form-control border-yellow rounded-5" style={{ width: '100%', height: '6vh' }} id="gender">
                        <option value="" disabled selected>Qualification</option>
                        <option value="male">IITIAN</option>
                        <option value="female">Other</option>
                    </select>
                </div>
                <div class="form-group col-md-3 mt-3">
                    <button className="new-button">Calculate</button>
                </div>
            </Row>

            <Container className="px-3 py-3 shadow rounded-3 border mt-4">
                <p className="d-flex m-0 p-0"><h6>Teacher Price :</h6> &nbsp; 550/-</p>
                <p className="d-flex m-0 p-0"><h6>Learniee Profit :</h6> &nbsp; 30%</p>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio rem reprehenderit inventore deleniti impedit enim voluptates laboriosam quod nam aspernatur.</p>
            </Container>

            {/* <Row>
            <div class="form-group col-md-4 mt-3">
                    <label for="FirstName">Rate:</label>
                    <input type="text" class="form-control rounded-5 border-yellow" id="FirstName" placeholder="Rs. 500" />
                </div>
            </Row> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ParentFooter />
        </TeacherLayout>
    );
};

export default RateCalculator;
