// Teachers
import TeachersPage from "./TeachersPage";
import TecherWithID from "./TecherWithID";
import TranscriptReportPage from "./TranscriptReportPage";

export const TeachersPageConfig = {
  routes: [
    {
      path: "/operation/Teachers",
      exact: true,
      component: TeachersPage,
    },
    {
      path: "/operation/v1/transcript/report",
      exact: true,
      component: TranscriptReportPage,
    },
    {
      path: "/operation/Teachers/:id",
      exact: true,
      component: TecherWithID,
    },
  ],
};
