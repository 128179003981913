import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../assets/css/bookADemo.css";
import { FaChevronRight } from "react-icons/fa6";
import Button from "../components/Button.jsx";

const OperationAccountLogin = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className="bookademo">
        <Navbar />

        <div className="top_bar_book d-flex flex-column justify-content-center px-5">
          <h3 className="fw-bold">Operation/Account Login</h3>
          <p>
            Home <FaChevronRight style={{ fontSize: 12 }} /> Operation/Account
            Login
          </p>
        </div>

        <Container className="d-flex align-items-center justify-content-center pt-5">
          <div className="book_form p-4 rounded-3 border">
            <h4 className="text-center mb-4 fw-semibold">Login</h4>
            <Row>
              <Form.Group as={Col} md={12} className="mt-3">
                <Form.Label htmlFor="FirstName" className="fw-semibold">
                  Username
                </Form.Label>
                <Form.Control
                  type="text"
                  id="FirstName"
                  placeholder="Username"
                />
              </Form.Group>

              <Form.Group as={Col} md={6} className="mt-3">
                <Form.Label htmlFor="LastName" className="fw-semibold">
                  Password
                </Form.Label>
                <Form.Control
                  type="Password"
                  id="LastName"
                  placeholder="Password"
                />
              </Form.Group>

              <Form.Group as={Col} md={6} className="mt-3">
                <Form.Label htmlFor="LastName" className="fw-semibold">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  type="Password"
                  id="LastName"
                  placeholder="Confirm Password"
                />
              </Form.Group>

              <Form.Group as={Col} md={12} className="mt-3">
                <Form.Label htmlFor="LastName" className="fw-semibold">
                  Select Role
                </Form.Label>
                <Form.Control
                  as="select"
                  id="LastName"
                  placeholder="Select Role"
                >
                  <option>Operation</option>
                  <option>Account</option>
                </Form.Control>
              </Form.Group>
            </Row>

            <Row className="mt-4">
              <div>
                <Button text="Login" />
              </div>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default OperationAccountLogin;
