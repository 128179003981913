import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa"; // Importing only the close icon

const FullscreenVideoButton = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  // Trigger video play in fullscreen mode
  const handlePlayInFullscreen = () => {
    if (videoRef.current) {
      videoRef.current
        .requestFullscreen()
        .then(() => {
          videoRef.current.play();
        })
        .catch((err) => {
          console.error("Failed to enable fullscreen mode:", err.message);
        });
    }
  };

  const handleButtonClick = () => {
    setShowModal(true);
    handlePlayInFullscreen();
  };

  const handleClose = () => {
    setShowModal(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset the video to the beginning
    }
  };

  return (
    <>
      <button onClick={handleButtonClick} className="ms-3 mb-3 new-button">
        Course Intro
      </button>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        style={{ zIndex: "1425120001" }}
        centered
        fullscreen={true} // Fullscreen modal
      >
        <Modal.Body
          style={{
            position: "relative",
            padding: "0", // Remove padding for full-width video
            width: "100%",
            height: "100%",
            backgroundColor: "black",
          }}
        >
          {/* Close Icon positioned top-right */}
          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "red",
              color: "white",
              border: "none",
              borderRadius: "50%",
              fontSize: "20px",
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              zIndex: "10", // Ensure it appears above the video
            }}
          >
            <FaTimes />
          </button>

          {/* Video Element with default controls */}
          <video
            ref={videoRef}
            src={videoSrc}
            width="100%"
            height="99%"
            autoPlay
            controls // Enable default browser controls
            style={{
              display: "block",
              backgroundColor: "black",
              pointerEvents: "none", // Prevent video download via right-click
            }}
            onContextMenu={(e) => e.preventDefault()} // Disable right-click
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FullscreenVideoButton;