import React, { useEffect, useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import ParentFooter from "../../components/ParentFooter";
import LearnieMallCard from "./LearnieMallCard";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

const LearnieMall = () => {
  const [learnieMall, setLearnieMall] = useState([]);
  const [search, setSearch] = useState("");

  const token = useSelector((state) => state.token.value);

  const getLearnieMall = async () => {
    try {
      const response = await axios.get(
        `/mall/show?keyword=${search}&page=&limit=`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.res_type === "success") {
        setLearnieMall(response.data.data.items);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    getLearnieMall();
  }, [token, search]);

  // console.log("Learnie Mall: ", learnieMall);

  return (
    <OftadehLayout>
      <h1>Learnie Mall</h1>
      <StyledContainer>
        <Row className="mt-3 pe-5">
          <Col md={3} className="mt-4">
            <Form.Group controlId="nameInput">
              <Form.Control
                className="rounded-5 shadow"
                type="text"
                placeholder="Search here."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-5 mb-3">
          {learnieMall && learnieMall?.length > 0 ? (
            learnieMall.map((item, index) => (
              <LearnieMallCard
                key={index}
                name={item?.title}
                desc={item?.description}
                image={item?.image_key}
              />
            ))
          ) : (
            <h3 className="mt-5 text-center">No Learnie Mall items found!</h3>
          )}
        </Row>
      </StyledContainer>
      <ParentFooter />
    </OftadehLayout>
  );
};

const StyledContainer = styled.div`
  .search_btn_learnie {
    button {
      background: var(--color-primary);
      border-radius: 100px;
      border: none;
      outline: none;
      font-weight: 600;
      cursor: pointer;
      width: 100%;
    }
  }
`;

export default LearnieMall;
