import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
// import HomePage from "./screens/HomePage.js";
import AdminLogin from "./screens/Admin/AdminLogin.js";
import SignIn from "./screens/Parent/SignIn.js";
import "react-toastify/dist/ReactToastify.css";
import BlogPage from "./screens/BlogPage.js";
import BlogPage1 from "./screens/BlogPage1.js";
import AdminPanel from "./screens/Admin/SuperAdminDashboard.js";
import AdminBlog from "./screens/Admin/AdminBlog.js";
import ThemeContext from "./context/ThemeContext.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import getTheme from "./oftadeh-configs/themeConfig.js";
import OftadehRoutes from "./components/parentComponents/OftadehRoutes/OftadehRoutes.jsx";
import TeacherRoute from "./components/teacherCompoents/TeacherRoutes/TeacherRoutes.jsx";
import OperationsRoute from "./components/operationComponents/OperationRoutes/OperationRoutes.jsx";
import AccountRoute from "./components/accountComponents/AccountRoutes/AccountRoutes.jsx";
import WelcomePage from "./TeacherPages/welcome/WelcomePage.jsx";
import Course from "./screens/Course.js";
import About from "./screens/About.js";
import Privacy from "./screens/Privacy.js";
import Refund from "./screens/Refund.js";
import Cookie from "./screens/Cookie.js";
import Security from "./screens/Security.js";
import Career from "./screens/Career.js";
import Facilities from "./screens/Facilities.js";
import BookADemo from "./screens/BookADemo.js";
import Help from "./screens/Help.js";
import FAQs from "./screens/FAQs.js";
import InstructionsToJoin from "./screens/InstructionsToJoin.js";
import HomeDonate from "./screens/HomeDonate.js";
import GiftOnlineCources from "./screens/GiftOnlineCources.js";
import HowDoesItWork from "./screens/HowDoesItWork.js";
import SearchResults from "./screens/SearchResults.js";
import Testimonials from "./screens/Testimonials.js";
import BecomeAMentore from "./screens/BecomeAMentore.js";
import Disclaimer from "./screens/Disclaimer.js";
import TermAndConditions from "./screens/TermAndConditions.js";
import FeedBack from "./screens/FeedBack.js";
import LearningCards from "./screens/LearningCards.js";
import CopyRight from "./screens/CopyRight.js";
import PasswordManager from "./screens/Admin/PasswordManager.js";
import CompanyDocument from "./screens/Admin/CompanyDocument.js";
import EmployeeManagement from "./screens/Admin/EmployeeManagement.js";
import KeyResponsiblityArea from "./screens/Admin/KeyResponsiblityArea.js";
import Authortise from "./screens/Admin/Authortise.js";
import Payment from "./screens/Admin/Payment.js";
import EmployeeHistory from "./screens/Admin/EmployeeHistory.js";
import AlocateTask from "./screens/Admin/AlocateTask.js";
import ProjectDetails from "./screens/Admin/ProjectDetails.js";
import TaskStatus from "./screens/Admin/TaskStatus.js";
import OperationAnalytics from "./screens/Admin/OperationAnalytics.js";
import Liquidity from "./screens/Admin/Liquidity.js";
import SecurityAdmin from "./screens/Admin/Security.js";
import InformationTechnology from "./screens/Admin/InformationTechnology.js";
import EditWebsite from "./screens/Admin/EditWebsite.js";
import ActivityLog from "./screens/Admin/ActivityLog.js";
import SignUp from "./screens/Parent/SignUp.js";
import ForgotPassword from "./screens/Parent/ForgerPassword.js";
import InstructorSupport from "./screens/Admin/InstructorSupport.js";
import TeachingFAQ from "./screens/Admin/TeachingFAQ.js";
import PinPost from "./screens/Admin/PinPost.js";
import TeacherPayment from "./screens/Admin/TeacherPayment.js";
import GiftPage from "./screens/Admin/GiftPage.js";
import ParentPayment from "./screens/Admin/ParentPayment.js";
import Employee from "./screens/Admin/Employee.js";
import gsap from "gsap";
import AccountLogin from "./screens/Parent/AdminLogin.js";
import OperationLogin from "./screens/Parent/OperationLogin.js";
import DashBoard from "./screens/Admin/DashBoard.js";
import ParentManagment from "./screens/Admin/ParentManagment.js";
import ParentProfile from "./screens/Admin/ParentProfile.js";
import AllNewsLetters from "./screens/Admin/AllNewsLetters.js";
import GetAConsultation from "./screens/Admin/GetAConsultation.js";
import AllDemos from "./screens/Admin/AllDemos.js";
import CourcePage from "./screens/CourcePage.js";
import FindMentor from "./screens/FindMentor.js";
import OperationAccountLogin from "./screens/OperationAccountLogin.js";
import TeacherSignUp from "./screens/TeacherSignUp.js";
import TeacherVarify from "./screens/Admin/TeacherVarify.js";
import AllBlogs from "./screens/Admin/AllBlogs.js";
import ReqBlogs from "./screens/Admin/ReqBlogs.js";
import Suggestion from "./screens/Admin/Suggestion.js";
import ChatReport from "./screens/Admin/ChatReport.js";

import "react-calendar/dist/Calendar.css";
import TeacherPublicProfile from "./screens/TeacherPublicProfile.js";
import ResetLinkPage from "./screens/ResetLinkPage.js";
import Loader from "./components/Loader.js";
import MenualDemos from "./screens/Admin/MenualDemos.js";
import AllClasses from "./screens/Admin/AllClasses.js";
import LearnieeMall from "./screens/Admin/LearnieeMall.js";
import { useSelector } from "react-redux";
import NoPage from "./screens/NoPage.jsx";
import AccountDeletionRequests from "./screens/Admin/AccountDeletionRequests.js";
import Chat from "./screens/Admin/Chat.js";
import TeacherVerifyDetails from "./screens/Admin/TeacherVerifyDetails.js";
const HomePage = lazy(() => import("./screens/HomePage.js"));

const App = () => {
  const curThemeName = localStorage.getItem("appTheme") || "light";
  const [themeType, setThemeType] = useState(curThemeName);
  const token = useSelector((state) => state.token.value);

  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
    setThemeType(themeName);
  };

  const theme = getTheme({
    paletteType: themeType,
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (window !== undefined) {
      document.addEventListener("mousemove", (e) => {
        gsap.to(".mouse", {
          x: e.clientX,
          y: e.clientY,
          stagger: -0.1,
        });
      });

      document.addEventListener("mouseleave", () => {
        gsap.to(".mouse", {
          opacity: 0,
          scale: 0,
        });
      });

      document.addEventListener("mouseenter", () => {
        gsap.to(".mouse", {
          opacity: 1,
          scale: 1,
        });
      });
    }
  }, []);

  return (
    <>
      <div className="mouse"></div>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />

        <Route path="/find/mentor" element={<FindMentor />} />
        <Route path="/teacher/profile/:id" element={<TeacherPublicProfile />} />
        <Route path="/teacher/sign-up" element={<TeacherSignUp />} />
        <Route path="/admin/sign-in" element={<AdminLogin />} />
        <Route path="/account/sign-in" element={<AccountLogin />} />
        <Route path="/operation/sign-in" element={<OperationLogin />} />
        <Route path="/parent/sign-in" element={<SignIn />} />
        <Route path="/parent/sign-up" element={<SignUp />} />
        <Route path="/parent/forgot-password" element={<ForgotPassword />} />
        <Route path="/book-demo" element={<BookADemo />} />
        <Route path="/help" element={<Help />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/instraction-join" element={<InstructionsToJoin />} />
        <Route path="/donate" element={<HomeDonate />} />
        <Route path="/gift" element={<GiftOnlineCources />} />
        <Route path="/how-it-works" element={<HowDoesItWork />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/mentor" element={<BecomeAMentore />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/term&conditions" element={<TermAndConditions />} />
        <Route path="/feed-back" element={<FeedBack />} />
        <Route path="/copyright" element={<CopyRight />} />
        <Route
          path="/parent/reset-password-executor/:token"
          element={<ResetLinkPage />}
        />

        <Route path="/blog" element={<BlogPage1 />} />
        <Route path="/blog/:blogId" element={<BlogPage />} />

        <Route path="/admin" element={<AdminPanel />}>
          {/* <Route path="dashboard" element={<SuperAdminDashboardView />} /> */}
          <Route path="dashboard" element={<DashBoard />} />
          <Route path="AdminBlog" element={<AdminBlog />} />
          <Route path="blog/all" element={<AllBlogs />} />
          <Route path="blog/request" element={<ReqBlogs />} />
          <Route path="password-manager" element={<PasswordManager />} />
          <Route path="company-documents" element={<CompanyDocument />} />
          <Route path="employee-managment" element={<EmployeeManagement />} />
          <Route path="parent-management" element={<ParentManagment />} />
          <Route path="key-responsiblity" element={<KeyResponsiblityArea />} />
          <Route path="parent-profile" element={<ParentProfile />} />
          <Route path="authorize" element={<Authortise />} />
          <Route path="payment" element={<Payment />} />
          <Route path="history" element={<EmployeeHistory />} />
          <Route path="allocate-task" element={<AlocateTask />} />
          <Route path="project-details" element={<ProjectDetails />} />
          <Route path="task-status" element={<TaskStatus />} />
          <Route path="operations-analytics" element={<OperationAnalytics />} />
          <Route path="liquidity" element={<Liquidity />} />
          <Route path="Security" element={<SecurityAdmin />} />
          <Route path="all-newsletters" element={<AllNewsLetters />} />
          <Route path="get-a-consultation" element={<GetAConsultation />} />
          <Route path="all-demos" element={<AllDemos />} />
          <Route path="teacher-verification" element={<TeacherVarify />} />
          <Route
            path="teacher-verification/details"
            element={<TeacherVerifyDetails />}
          />
          <Route path="suggestion" element={<Suggestion />} />
          <Route path="manual-demos" element={<MenualDemos />} />
          <Route path="all-classes" element={<AllClasses />} />
          <Route path="chat-report" element={<ChatReport />} />
          <Route path="learniee-mall" element={<LearnieeMall />} />
          <Route path="chat" element={<Chat />} />
          <Route
            path="information-technology"
            element={<InformationTechnology />}
          />
          <Route path="edit-website" element={<EditWebsite />} />
          <Route path="activity-log" element={<ActivityLog />} />
          <Route path="instructor-suport" element={<InstructorSupport />} />
          <Route path="teaching-faq" element={<TeachingFAQ />} />
          <Route path="pin-post" element={<PinPost />} />
          <Route path="teacher-payment" element={<TeacherPayment />} />
          <Route path="gift" element={<GiftPage />} />
          <Route path="parent-payment" element={<ParentPayment />} />
          <Route path="employee" element={<Employee />} />
          <Route
            path="account-deletion-requests"
            element={<AccountDeletionRequests />}
          />
        </Route>

        <Route path="/teacher/welcome" element={<WelcomePage />} />
        <Route path="/course/:courseId?" element={<Course />} />
        <Route path="/course-main" element={<CourcePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        <Route path="/security-policy" element={<Security />} />
        <Route path="/career" element={<Career />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/learning" element={<LearningCards />} />
        <Route
          path="/operation-account/login"
          element={<OperationAccountLogin />}
        />
        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>

      <ThemeContext.Provider value={{ setThemeName, curThemeName }}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <OftadehRoutes />
            <TeacherRoute />
            <OperationsRoute />
            <AccountRoute />
          </div>
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
};

export default App;
