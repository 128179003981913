import React from "react";
import cource1 from "../../assets/cource1.png";
import cource2 from "../../assets/cource2.png";
import cource3 from "../../assets/cource3.png";
import cource4 from "../../assets/cource4.png";
import cource5 from "../../assets/cource5.png";
import cource6 from "../../assets/cource6.png";
import cource7 from "../../assets/cource7.png";
import cource8 from "../../assets/cource8.png";
import cource9 from "../../assets/cource9.png";
import cource10 from "../../assets/cource10.png";
import cource11 from "../../assets/cource11.png";
import cource12 from "../../assets/cource12.png";
import down from "../../assets/down.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const cardData = [
  {
    id: 1,
    name: "Grade/Age",
    icon: cource1,
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Prep - 1st (3-6 yrs )",
      },
      {
        id: 2,
        name: "2-4 std (7-9 yrs )",
      },
      {
        id: 3,
        name: "5-7 std ( 10-12 yrs )",
      },
      {
        id: 4,
        name: "11-12 std ( 16-18 yrs )",
      },
    ],
  },
  {
    id: 2,
    name: "Board",
    icon: cource2,
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "IGCSE",
      },
      {
        id: 2,
        name: "IB",
      },
      {
        id: 3,
        name: "ICSE",
      },
      {
        id: 4,
        name: "CBSE",
      },
      {
        id: 5,
        name: "Other boards",
      },
    ],
  },
  {
    id: 3,
    name: "Language",
    icon: cource3,
    // bgcolor: "#EFFAFE",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "French",
      },
      {
        id: 2,
        name: "Spanish",
      },
      {
        id: 3,
        name: "English",
      },
      {
        id: 4,
        name: "Hindi",
      },
      {
        id: 5,
        name: "Regional (Marathi etc)",
      },
    ],
  },
  {
    id: 4,
    name: "Subjects",
    icon: cource4,
    // bgcolor: "#F7F1FD",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Maths",
      },
      {
        id: 2,
        name: "Science",
      },
      {
        id: 3,
        name: "Hindi",
      },
      {
        id: 4,
        name: "Social Studies",
      },
      {
        id: 5,
        name: "History",
      },
    ],
  },
  {
    id: 5,
    name: "Extra Academics",
    icon: cource5,
    // bgcolor: "#EFF9F8",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Vedic maths",
      },
      {
        id: 2,
        name: "Abacus",
      },
      {
        id: 3,
        name: "Phonics",
      },
      {
        id: 4,
        name: "GK",
      },
      {
        id: 5,
        name: "Logical Reasoning",
      },
    ],
  },
  {
    id: 6,
    name: "New Courses",
    icon: cource6,
    // bgcolor: "#F7F1FD",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Coding",
      },
      {
        id: 2,
        name: "Doodle Art",
      },
      {
        id: 3,
        name: "Python for Beginners",
      },
      {
        id: 4,
        name: "Robotics",
      },
      {
        id: 5,
        name: "Graphic Designing",
      },
    ],
  },
  {
    id: 7,
    name: "Professional Exams",
    icon: cource7,
    // bgcolor: "#EFF9F8",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "NEET JEE",
      },
      {
        id: 2,
        name: "MBA",
      },
      {
        id: 3,
        name: "NIFT",
      },
      {
        id: 4,
        name: "GATE",
      },
      {
        id: 5,
        name: "GRE GMAT",
      },
    ],
  },
  {
    id: 8,
    name: "Competitive Exams",
    icon: cource8,
    // bgcolor: "#FDF9EE",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "HOMI BABA",
      },
      {
        id: 2,
        name: "Spell - B",
      },
      {
        id: 3,
        name: "Olympiads",
      },
      {
        id: 4,
        name: "RMO",
      },
      {
        id: 5,
        name: "NSTSE",
      },
    ],
  },
  {
    id: 9,
    name: "New Age Learning",
    icon: cource9,
    // bgcolor: "#FDF9EE",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Coding",
      },
      {
        id: 2,
        name: "Digital marketing",
      },
      {
        id: 3,
        name: "Personality development",
      },
      {
        id: 4,
        name: "Artificial Intelligence",
      },
      {
        id: 5,
        name: "Game Development",
      },
    ],
  },
  {
    id: 10,
    name: "Type of classes",
    icon: cource10,
    // bgcolor: "#FDF9EE",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "1-1 tutoring",
      },
      {
        id: 2,
        name: "Group class",
      },
      {
        id: 3,
        name: "One time class",
      },
      {
        id: 4,
        name: "Short courses",
      },
      {
        id: 5,
        name: "Topic wise classes",
      },
    ],
  },
  {
    id: 11,
    name: "Hobby Classes",
    icon: cource11,
    // bgcolor: "#EFF9F8",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "Art",
      },
      {
        id: 2,
        name: "Music",
      },
      {
        id: 3,
        name: "Dance",
      },
      {
        id: 4,
        name: "Life skills",
      },
      {
        id: 5,
        name: "Photography",
      },
    ],
  },
  {
    id: 12,
    name: "Counselling",
    icon: cource12,
    // bgcolor: "#F7F1FD",
    bgcolor: "#EFEEFE",
    children: [
      {
        id: 1,
        name: "School",
      },
      {
        id: 2,
        name: "Behavioural",
      },
      {
        id: 3,
        name: "Career",
      },
      {
        id: 4,
        name: "Examinations",
      },
      {
        id: 5,
        name: "History",
      },
    ],
  },
];

const CourcesMain = () => {
  const [active, setActive] = React.useState(null);

  const clickHandler = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  // handle click outside of dropdown
  React.useEffect(() => {
    const handleClick = (e) => {
      if (e.target.classList.contains("data_inner")) {
        return;
      }
      setActive(null);
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className="allCources p-md-5 p-3">
        <div className="badch_cu d-block mx-auto py-1 px-3">
          <p className="m-0 fw-medium">All Courses</p>
        </div>
        <div className="serach_part mt-4 gap-3">
          <form onSubmit={() => navigate("/search")}>
            <input
              type="text"
              placeholder="Search cources by name and category"
            />
            <button type="submit">Search</button>
          </form>
        </div>

        <div className="card_data row mt-5">
          {cardData.map((data, index) => (
            <>
              <motion.div
                className="col-md-4 mt-4"
                onClick={() => clickHandler(index)}
                key={index}
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
              >
                <div
                  className={`py-3 shadow data_inner px-3 d-flex align-items-center justify-content-between bg-white 
                  ${active === index ? " rounded-top-4" : "rounded-4"}
                  `}
                  // style={{ background: data.bgcolor }}
                >
                  <div className="d-flex align-items-center gap-3">
                    <div>
                      <img src={data.icon} height={45} width={45} alt="" />
                    </div>
                    <p className="m-0 fw-semibold">{data.name}</p>
                  </div>
                  <img src={down} style={{ pointerEvents: "none" }} alt="" />
                  <div
                    className={`dropdown_c shadow rounded-bottom-4 
                    ${active === index && "active py-3 px-3 "}
                    `}
                  >
                    {data.children.map((child, index) => (
                      <p
                        key={index}
                        className="m-0 py-2 mt-1 data_children px-3"
                      >
                        {child.name}
                      </p>
                    ))}
                  </div>
                </div>
              </motion.div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default CourcesMain;
