import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Editor from "react-simple-wysiwyg";
import { createBlog } from "../../Api/AdminApi/blogApi";

const UpdateBlog = (props) => {
  const { history } = props;

  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");
  const [imageURL, setimageURL] = useState("");
  const [ageGroup, setageGroup] = useState("");
  const [board, setboard] = useState("");
  const [standard, setstandard] = useState("");
  const [subject, setsubject] = useState("");
  const [topic, settopic] = useState("");
  const [course, setcourse] = useState("");

  function onChange(e) {
    setcontent(e.target.value);
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);
      createBlog({
        title,
        content,
        imageURL,
        ageGroup,
        board,
        standard,
        subject,
        topic,
        course,
      })
        .then((res) => {
          console.log("res:", res);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      console.log("sub");
    }
  };

  return (
    <OperationLayout>
      <h5>Update Blog</h5>
      {/* <OperationLocation path={history} /> */}

      <Container>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter title of blog"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Image URl</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Image of blog"
                value={imageURL}
                onChange={(e) => setimageURL(e.target.value)}
              />
            </Form.Group>
            <Col md={12} className="mb-3">
              <Editor
                containerProps={{
                  style: {
                    resize: "vertical",
                    minHeight: "40vh",
                    marginInline: "auto",
                  },
                }}
                value={content}
                onChange={onChange}
              />
            </Col>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Age Group</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter age group"
                value={ageGroup}
                onChange={(e) => setageGroup(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Board</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter board"
                value={board}
                onChange={(e) => setboard(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Standard</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter standard"
                value={standard}
                onChange={(e) => setstandard(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter subject"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter topic"
                value={topic}
                onChange={(e) => settopic(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3">
              <Form.Label>Course</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter course"
                value={course}
                onChange={(e) => setcourse(e.target.value)}
              />
            </Form.Group>
          </Row>
          {content !== "" ? (
            <Button type="submit" className="d-block mx-auto">
              Create Blog
            </Button>
          ) : (
            <Button disabled className="d-block mx-auto">
              Create Blog
            </Button>
          )}
        </Form>
      </Container>
    </OperationLayout>
  );
};

export default UpdateBlog;
