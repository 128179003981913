import TeacherDashboardPage from "./TeacherDashboard";

export const TeacherDashboardPageConfig = {
  routes: [
    {
      path: "/teacher/dashboard",
      exact: true,
      component: TeacherDashboardPage
    }
  ]
};
