import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { FaAnglesRight } from "react-icons/fa6";
import card2 from "../../assets/parent/card2.png";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
function AllBlogs() {
  const [selectedBlogCat, setSelectedBlogCat] = useState("admin");
  return (
    <>
      <OperationLayout>
        <Row>
          <Col md={3} className="mt-3 mb-3">
            <Form.Select
              onChange={(e) => setSelectedBlogCat(e.target.value)}
              className="rounded-5 shadow"
            >
              <option value="teacher">Teacher</option>
              <option value="admin">Admin</option>
              <option value="parent">Parent</option>
            </Form.Select>
          </Col>
          <Col md={6}></Col>
          <Col md={3}>
            <Form.Group controlId="formTitle">
              <Form.Control
                className="rounded-5 shadow "
                type="text"
                placeholder="Search by name / title"
                name="search"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3 mb-3">
          <Col>
            <h5 className="text-capitalize">{selectedBlogCat} Blogs</h5>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mt-3">
            <BlogCard
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              isEdit={true}
            />
          </Col>
          <Col md={6} className="mt-3">
            <BlogCard
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              isEdit={true}
            />
          </Col>
          <Col md={6} className="mt-3">
            <BlogCard
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              isEdit={true}
            />
          </Col>
          <Col md={6} className="mt-3">
            <BlogCard
              img={card2}
              head={"Why is Gravity? | Class 11 | Physics"}
              review={"267 views"}
              time={"3 days ago"}
              blogIcon={<FaAnglesRight />}
              blog={"View blog"}
              isEdit={true}
            />
          </Col>
        </Row>
      </OperationLayout>
    </>
  );
}

export default AllBlogs;
