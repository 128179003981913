// authService.js
import axios from "axios";

export const getAllBlogs = async () => {
    try {
        console.log('url',process.env.REACT_APP_BACKEND_URL);
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/admin/blog`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getOneBlog = async (blogId) => {
    try {
        console.log('receved blogId',blogId);
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/admin/blog/one?blogId=${blogId}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
