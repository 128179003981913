import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TeacherAccount = () => {
  const navigate = useNavigate();
  return (
    <OperationLayout>
      <h4>Teacher Account</h4>
      <h5 className="mt-4">All Teachers</h5>

      <Table striped bordered hover responsive className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Transaction ID</th>
            <th>Parent ID</th>
            <th>Course ID</th>
            <th>Course Name</th>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Course Date</th>
            <th>Course Time</th>
            <th>Cycle Count</th>
            <th>Class Count</th>
            <th>Bank Name</th>
            <th>Payee</th>
            <th>Status</th>
            <th>Total Hours</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>TRN345</td>
                <td>PAR345</td>
                <td>CRS345</td>
                <td>Course Name</td>
                <td>STU345</td>
                <td>Student Name</td>
                <td>12/09/2021</td>
                <td>12:00 PM</td>
                <td>5/10</td>
                <td>5</td>
                <td>Bank Name</td>
                <td>Payee</td>
                <td>Completed</td>
                <td>10</td>

                <td>
                  <p
                    onClick={() =>
                      navigate(
                        "/operation/account/parentAccount/teacherDetails"
                      )
                    }
                    className="text-decoration-underline text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </p>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default TeacherAccount;
