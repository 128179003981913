import React from "react";
import AccountLayout from "../../components/accountComponents/AccountLayout/AccountLayout";
import AccountLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import "../../assets/css/parent/parent.css";

const PaymentGatewayPage = (props) => {
  const { history } = props;
  return (
    <AccountLayout>
      <h3>Payment Getway</h3>
      {/* <AccountLocation path={history} /> */}
    </AccountLayout>
  );
};

export default PaymentGatewayPage;
