import React, { useState } from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Container, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { FaFolder } from "react-icons/fa";
import { IoMdCreate, IoMdDownload, IoMdMore, IoMdShare, IoMdTrash } from "react-icons/io";
import { FaFolderPlus } from "react-icons/fa";
import { Button, IconButton } from "@mui/material";
import { FaAnglesRight, FaCopy, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaGoogleDrive } from "react-icons/fa";
import { FaCloudUploadAlt } from "react-icons/fa";
import Upload from "../../TeacherPages/courseManagement/Upload";
import Progress from "../../TeacherPages/courseManagement/Progress";
import { FaBloggerB } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import { BsFolderSymlinkFill } from "react-icons/bs";

import Card2 from "./Card2";
import card2 from "../../assets/parent/card2.png";
import { Link } from "react-router-dom";
const MediaPage = (props) => {
    const { history } = props;

    const mediaItems = [
        {
            id: 1,
            name: 'HC Verma',
            type: 'folder'
        },
        {
            id: 2,
            name: 'DC Pandey',
            type: 'folder'
        },
        {
            id: 3,
            name: 'HC Verma',
            type: 'folder'
        },
        {
            id: 4,
            name: 'DC Pandey',
            type: 'folder'
        },
    ];

    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h4>Media</h4>
            <Row className='mt-3'>
                {mediaItems.map((media, index) => (
                    <>
                        <Col md={4} key={index} style={{ padding: '10px' }}>
                            <div className="resorce-parent-item">
                                <div className="name"><FaFolder />{media.name}</div>
                                <div className="more-option-icon">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" toggleIcon={null} className="custom-dropdown-toggle">
                                            <IoMdMore />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>
                                                <IoMdDownload color="#007BFF" />
                                                &nbsp; Download
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdCreate color="#28A745" />
                                                &nbsp; Rename
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdShare color="#17A2B8" />
                                                &nbsp; Share
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdCreate color="#6610F2" />
                                                &nbsp; Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <IoMdTrash color="#DC3545" />
                                                &nbsp; Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </>
                ))}
            </Row>
            <Container className="mt-3 mb-3 d-flex align-items-center justify-content-center" style={{ flexDirection: 'column', gap: '10px', minHeight: '10vh' }}>
                <div className="shadow d-flex align-items-center justify-content-center border" style={{ width: '150px', height: '150px', borderRadius: '50%', borderColor: 'var(--color-primary)' }}>
                    <FaFolderPlus size={52} color="var(--color-primary)" />
                </div>
                <Button onClick={handleButtonClick} className="teacher-button rounded-5 border-0 shadow" variant="outlined">Create New Folder</Button>
            </Container>

            <Modal show={showModal} onHide={handleCloseModal} style={{ zIndex: '1111', marginTop: '20vh' }}>
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>Create New Folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col md={12}>
                        <Form.Group controlId="nameInput">
                            <Form.Control type="text" placeholder="Enter Folder Name" />
                        </Form.Group>
                    </Col>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button className="bg-danger border-danger text-white me-3" variant="outlined" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="outlined">
                        create folder
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="mt-3 mb-3"> <h6>Links to Social Media</h6> </Row>
            <Row className="align-items-center">
                {/* Column 1: Facebook Icon */}
                <Col xs={2} className="text-center">
                    <FaFacebook size={32} color="#1877f2" />
                </Col>

                {/* Column 2: Input Field for Facebook Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="Facebook Link" />
                    </Col>
                </Col>

                {/* Column 3: Copy Link Button for Facebook */}
                <Col xs={2} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <FaCopy />
                    </IconButton>
                </Col>

                {/* Column 4: LinkedIn Icon */}
                <Col xs={2} className="text-center">
                    <FaLinkedin size={32} color="#0077b5" />
                </Col>

                {/* Column 5: Input Field for LinkedIn Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="LinkedIn Link" />
                    </Col>
                </Col>

                {/* Column 6: Copy Link Button for LinkedIn */}
                <Col xs={2} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <FaCopy />
                    </IconButton>
                </Col>

                {/* Column 7: Instagram Icon */}
                <Col xs={2} className="text-center">
                    <FaInstagram size={32} color="#e4405f" />
                </Col>

                {/* Column 8: Input Field for Instagram Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="Instagram Link" />
                    </Col>
                </Col>

                {/* Column 9: Copy Link Button for Instagram */}
                <Col xs={2} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <FaCopy />
                    </IconButton>
                </Col>

                {/* Column 10: YouTube Icon */}
                <Col xs={2} className="text-center">
                    <FaYoutube size={32} color="#FF0000" />
                </Col>

                {/* Column 11: Input Field for YouTube Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="YouTube Link" />
                    </Col>
                </Col>

                {/* Column 12: Copy Link Button for YouTube */}
                <Col xs={2} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <FaCopy />
                    </IconButton>
                </Col>
            </Row>
            <Row className="mt-3 mb-3"> <h6>Links to Social Media for Public</h6> </Row>
            <Row className="align-items-center">
                {/* Column 1: Facebook Icon */}
                <Col xs={2} className="text-center">
                    <FaFacebook size={32} color="#1877f2" />
                </Col>

                {/* Column 2: Input Field for Facebook Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="Facebook Link" />
                    </Col>
                </Col>

                {/* Column 3: Copy Link Button for Facebook */}
                <Col xs={1} className="text-center p-0">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <CiEdit />
                    </IconButton>
                </Col>
                <Col xs={1} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <MdOutlineContentCopy />
                    </IconButton>
                </Col>

                {/* Column 4: LinkedIn Icon */}
                <Col xs={2} className="text-center">
                    <FaLinkedin size={32} color="#0077b5" />
                </Col>

                {/* Column 5: Input Field for LinkedIn Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="LinkedIn Link" />
                    </Col>
                </Col>

                {/* Column 6: Copy Link Button for LinkedIn */}
                <Col xs={1} className="text-center p-0">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <CiEdit />
                    </IconButton>
                </Col>
                <Col xs={1} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <MdOutlineContentCopy />
                    </IconButton>
                </Col>

                {/* Column 7: Instagram Icon */}
                <Col xs={2} className="text-center">
                    <FaInstagram size={32} color="#e4405f" />
                </Col>

                {/* Column 8: Input Field for Instagram Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="Instagram Link" />
                    </Col>
                </Col>

                {/* Column 9: Copy Link Button for Instagram */}
                <Col xs={1} className="text-center p-0">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <CiEdit />
                    </IconButton>
                </Col>
                <Col xs={1} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <MdOutlineContentCopy />
                    </IconButton>
                </Col>

                {/* Column 10: YouTube Icon */}
                <Col xs={2} className="text-center">
                    <FaYoutube size={32} color="#FF0000" />
                </Col>

                {/* Column 11: Input Field for YouTube Link */}
                <Col xs={8}>
                    <Col className="mb-2">
                        <input type="text" className="form-control border-yellow shadow" placeholder="YouTube Link" />
                    </Col>
                </Col>

                {/* Column 12: Copy Link Button for YouTube */}
                <Col xs={1} className="text-center p-0">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <CiEdit />
                    </IconButton>
                </Col>
                <Col xs={1} className="text-center">
                    <IconButton className="teacher-button shadow" aria-label="delete" size="medium">
                        <MdOutlineContentCopy />
                    </IconButton>
                </Col>
            </Row>

            
            {/* <Row className="mt-3 mb-3"><h4>Upload</h4></Row>
            <Row className="mt-3 mb-3"> <h6><FaGoogleDrive /> Upload to Drive</h6> </Row>
            <Row>
                <Col md={8} className="d-flex align-items-center justify-content-center shadow rounded-3" style={{ flexDirection: 'column', gap: '10px', height: '30vh', border: '2px dashed #FFD32B' }}>
                    <FaCloudUploadAlt size={42} />
                    <h6>Drag and drop video files to upload</h6>
                </Col>
                <Col md={4} className=" mt-3 d-flex align-items-end justify-content-center">
                    <Button className="teacher-button rounded-5 shadow border-0" variant="outlined">Upload</Button>
                </Col>
            </Row> */}
            {/* <Row className="mt-3 mb-3"> <h6><FaYoutube size={22} /> Upload New Youtube Video</h6> </Row> */}
            {/* <Row className="mt-3">
                <Col xs={12}>
                    <Form.Group controlId="youtubeLink">
                        <Form.Control className="rounded-5 border-yellow shadow" type="text" placeholder="Enter YouTube link" />
                    </Form.Group>
                </Col>

                <Row className="mt-4">
                    <Col md={6}>
                        <Card2
                            img={card2}
                            head={"Why is Gravity? | Class 11 | Physics"}
                            review={"267 views"}
                            time={"3 days ago"}
                            editButton={true}
                            shereButton={true}
                            deleteButton={true}
                        />
                    </Col>
                    <Col md={6}>
                        <Card2
                            img={card2}
                            head={"Why is Gravity? | Class 11 | Physics"}
                            review={"267 views"}
                            time={"3 days ago"}
                            editButton={true}
                            shereButton={true}
                            deleteButton={true}
                        />
                    </Col>
                </Row>

                <Row className="mt-3 mb-3"> <h6><FaBloggerB size={22} /> Upload New Blog</h6> </Row>
                <Col xs={12}>
                    <Form.Group controlId="youtubeLink">
                        <Form.Control className="rounded-5 border-yellow shadow" type="text" placeholder="Title" />
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group controlId="content">
                        <Form.Label>Content</Form.Label>
                        <Form.Control className="rounded-3 border-yellow shadow" as="textarea" rows={6} placeholder="Enter your content here" />
                    </Form.Group>
                </Col>

                <Row className="mt-4">
                    <Col md={6}>
                        <Upload />
                    </Col>
                    <Col md={6} className="pt-5">
                        <Progress />
                    </Col>
                </Row>
            </Row> */}
            {/* <Row className="mt-3 mb-3"><h4>Your Blogs</h4></Row>
            <Row className="mt-4">
                <Col md={6}>
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        editButton={true}
                        shereButton={true}
                        deleteButton={true}
                    />
                </Col>
                <Col md={6}>
                    <Card2
                        img={card2}
                        head={"Why is Gravity? | Class 11 | Physics"}
                        review={"267 views"}
                        time={"3 days ago"}
                        editButton={true}
                        shereButton={true}
                        deleteButton={true}
                    />
                </Col>
            </Row> */}

           
{/* 
            <Container className="mt-3">
                <Col className="shadow mt-3 d-flex align-items-center justify-content-start border-yellow ps-3" style={{ minHeight: '7vh', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                    <h5><FaLink size={22} /> Embed Affliate link</h5>
                </Col>
                <Col className="shadow d-flex align-items-start justify-content-start border-yellow p-3" style={{ flexDirection: 'column', gap: '10px' }}>
                    <Row><h6><BsFolderSymlinkFill size={22} /> &nbsp; Embed URL</h6></Row>
                    <h6>Paste a link to the content you want to display</h6>
                    <Col xs={10}>
                        <Form.Group controlId="youtubeLink">
                            <Form.Control className="rounded-5 border-yellow shadow" type="text" placeholder="Enter YouTube link" />
                        </Form.Group>
                    </Col>
                    <Col xs={2} className="ms-3">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Embed</Button>
                    </Col>
                    <Row>
                        <h6><u>Learn More</u></h6>
                    </Row>
                    <Row className="ps-3">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente accusamus sed illum, eos rem modi esse hic expedita, cupiditate quasi repellendus assumenda exercitationem accusantium animi est. Ea mollitia totam voluptatem.
                    </Row>
                    <div className="d-flex">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Try Again</Button>
                        <Button className="ms-3 teacher-button rounded-5 border-0" variant="outlined">Convert To Link</Button>
                    </div>
                </Col>
            </Container> */}

{/*            
            <Container className="mt-3">
                <Col className=" shadow mt-3 d-flex align-items-center justify-content-start border-yellow ps-3" style={{ minHeight: '7vh', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                    <h5><FaLink size={22} /> Embed Manual link</h5>
                </Col>
                <Col className="shadow d-flex align-items-start justify-content-start border-yellow p-3" style={{ flexDirection: 'column', gap: '10px' }}>
                    <Row><h6><BsFolderSymlinkFill size={22} /> &nbsp; Embed URL</h6></Row>
                    <h6>Paste a link to the content you want to display</h6>
                    <Col xs={10}>
                        <Form.Group controlId="youtubeLink">
                            <Form.Control className="rounded-5 border-yellow shadow" type="text" placeholder="Enter YouTube link" />
                        </Form.Group>
                    </Col>
                    <Col xs={2} className="ms-3">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Embed</Button>
                    </Col>
                    <Row>
                        <h6><u>Learn More</u></h6>
                    </Row>
                    <Row className="ps-3">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente accusamus sed illum, eos rem modi esse hic expedita, cupiditate quasi repellendus assumenda exercitationem accusantium animi est. Ea mollitia totam voluptatem.
                    </Row>
                    <div className="d-flex">
                        <Button className="teacher-button rounded-5 border-0" variant="outlined">Try Again</Button>
                        <Button className="ms-3 teacher-button rounded-5 border-0" variant="outlined">Convert To Link</Button>
                    </div>
                </Col>
            </Container> */}

        </OperationLayout>
    );
};

export default MediaPage;
