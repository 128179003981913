import React from 'react'
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Col, Form, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import { LuBoxSelect } from 'react-icons/lu';
import { FiEdit3 } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { FaBookmark } from "react-icons/fa";

const profileImg = require('../../assets/parent/profile-user.jpg');
function ActivityLog(props) {
    const { history } = props;
    return (
        <>
            <h5>Activity Log</h5>
          {/* <OperationLocation path={history} /> */} 
            <Row className='mt-3 mb-3'>
                <Col md={7} className='d-flex align-items-center justify-content-start'>
                    <Col md={3}>
                        <Form.Group controlId="nameInput">
                            <Form.Control className='shadow rounded-5' type="date" />
                        </Form.Group>
                    </Col>
                    <Col md={3} className='ms-2'>
                        <Form.Group controlId="selectOption">
                            <Form.Select className='shadow rounded-5'>
                                <option disabled value="option1">Category</option>
                                <option value="option2">All</option>
                                <option value="option2">Student</option>
                                <option value="option2">Parent</option>
                                <option value="option2">Teacher</option>
                                <option value="option2">Employee</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col><Button className='black-outline-button rounded-5 ms-3' variant="outlined" > Download Report </Button></Col>
                </Col>
                <Col md={5} className='d-flex align-items-center justify-content-end flex-wrap'>
                    <Button className='black-outline-button rounded-5' variant="outlined" startIcon={<LuBoxSelect />}> Select </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<FiEdit3 />}> Edit </Button>
                    <Button className='ms-3 black-outline-button rounded-5' variant="outlined" startIcon={<MdDelete />}> Delete </Button>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'><h6>May 20, 2023 - Today</h6></Row>
            <Row>
                <Col md={2} className='d-flex align-items-center justify-content-start'>
                    <div style={{ height: '100%' }}>09:44 AM</div>
                </Col>
                <Col md={10} >
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col md={1} className=' d-flex align-items-center justify-content-center'>
                    <div className="line border-3 border-start" style={{ height: '100%' }}>&nbsp;</div>
                </Col>
                <Col md={11} >
                    <Row className='p-2 rounded-3' style={{ backgroundColor: '#EFE5FF' }}>
                        <Col md={3} className='d-flex align-items-center jsutify-content-between' style={{ gap: '10px' }}>
                            <img src={profileImg} className='rounded-5' style={{ width: '60px', height: '60px' }} alt="" />
                            <h6>User Name</h6>
                        </Col>
                        <Col md={8}>
                            <span>Comment on some abcd Post by Username2</span>
                            <br />
                            <span style={{ fontSize: '12px' }}>some details</span>
                        </Col>
                        <Col md={1} className='d-flex align-items-center jsutify-content-center'>
                            <FaBookmark size={22} color='blue' />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={2} className='d-flex align-items-center justify-content-start'>
                    <div style={{ height: '100%' }}>09:44 AM</div>
                </Col>
                <Col md={10} >
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col md={1} className=' d-flex align-items-center justify-content-center'>
                    <div className="line border-3 border-start" style={{ height: '100%' }}>&nbsp;</div>
                </Col>
                <Col md={11} >
                    <Row className='p-2 rounded-3' style={{ backgroundColor: '#EFE5FF' }}>
                        <Col md={3} className='d-flex align-items-center jsutify-content-between' style={{ gap: '10px' }}>
                            <img src={profileImg} className='rounded-5' style={{ width: '60px', height: '60px' }} alt="" />
                            <h6>User Name</h6>
                        </Col>
                        <Col md={8}>
                            <span>Comment on some abcd Post by Username2</span>
                            <br />
                            <span style={{ fontSize: '12px' }}>some details</span>
                        </Col>
                        <Col md={1} className='d-flex align-items-center jsutify-content-center'>
                            <FaBookmark size={22} color='blue' />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-3 mb-3'><h6>May 19, 2023 - Yesterday</h6></Row>
            <Row>
                <Col md={2} className='d-flex align-items-center justify-content-start'>
                    <div style={{ height: '100%' }}>09:44 AM</div>
                </Col>
                <Col md={10} >
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col md={1} className=' d-flex align-items-center justify-content-center'>
                    <div className="line border-3 border-start" style={{ height: '100%' }}>&nbsp;</div>
                </Col>
                <Col md={11} >
                    <Row className='p-2 rounded-3' style={{ backgroundColor: '#EFE5FF' }}>
                        <Col md={3} className='d-flex align-items-center jsutify-content-between' style={{ gap: '10px' }}>
                            <img src={profileImg} className='rounded-5' style={{ width: '60px', height: '60px' }} alt="" />
                            <h6>User Name</h6>
                        </Col>
                        <Col md={8}>
                            <span>Comment on some abcd Post by Username2</span>
                            <br />
                            <span style={{ fontSize: '12px' }}>some details</span>
                        </Col>
                        <Col md={1} className='d-flex align-items-center jsutify-content-center'>
                            <FaBookmark size={22} color='blue' />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md={2} className='d-flex align-items-center justify-content-start'>
                    <div style={{ height: '100%' }}>09:44 AM</div>
                </Col>
                <Col md={10} >
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col md={1} className=' d-flex align-items-center justify-content-center'>
                    <div className="line border-3 border-start" style={{ height: '100%' }}>&nbsp;</div>
                </Col>
                <Col md={11} >
                    <Row className='p-2 rounded-3' style={{ backgroundColor: '#EFE5FF' }}>
                        <Col md={3} className='d-flex align-items-center jsutify-content-between' style={{ gap: '10px' }}>
                            <img src={profileImg} className='rounded-5' style={{ width: '60px', height: '60px' }} alt="" />
                            <h6>User Name</h6>
                        </Col>
                        <Col md={8}>
                            <span>Comment on some abcd Post by Username2</span>
                            <br />
                            <span style={{ fontSize: '12px' }}>some details</span>
                        </Col>
                        <Col md={1} className='d-flex align-items-center jsutify-content-center'>
                            <FaBookmark size={22} color='blue' />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default ActivityLog