import React, { useState } from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ParentFooter from "../../components/ParentFooter";
import { FaRegStar, FaStar } from "react-icons/fa";
import { IoSchoolOutline, IoStar } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TiBook } from "react-icons/ti";
import { FiHeart } from "react-icons/fi";

import c1 from "../../assets/cc1.jpg";
import c2 from "../../assets/cc2.jpg";
import c3 from "../../assets/cc3.jpg";
import { IconButton } from "@material-ui/core";

function CompleteCources(props) {
  const { history } = props;
  const navigate = useNavigate();
  const handleCoursesView = () => {
    navigate("/parent/dashboard/pages/courses/123456");
  };
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  return (
    <OftadehLayout>
      <h3>Completed Courses </h3>

      <Row>
        <Col md={4}>
          <Cards img={c1} />
        </Col>
        <Col md={4}>
          <Cards img={c2} />
        </Col>
        <Col md={4}>
          <Cards img={c3} />
        </Col>
      </Row>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default CompleteCources;

const Cards = ({ img }) => {
  const [show, setShow] = useState(false);
  const [rating, setRating] = useState(0);

  const navigate = useNavigate();

  const toggleModal = () => {
    setShow(!show);
  };

  const handleStarClick = (starCount) => {
    setRating(starCount);
  };

  return (
    <>
      <div className="card_c pt-3 px-0 bg-white rounded-3 overflow-hidden position-relative">
        <img onClick={() => navigate('/parent/dashboard/courses/142512')} className=" cursor-pointer cource_img rounded-3 px-3" src={img} alt="" />
        <div className="heart d-flex align-items-center justify-content-center shadow">
          <FiHeart className="fs-5" />
        </div>

        <div className="d-flex px-3 align-items-center justify-content-between mt-3">
          <button className="px-3">Business</button>
          <h6 className="m-0 fw-semibold">Rs. 1500.00</h6>
        </div>

        <h5 className="mt-3 px-3">
          Learn Mandarin - Beginner <br /> Level
        </h5>

        <div className="d-flex align-items-center justify-content-between mt-3 user_main px-3">
          <div className="user d-flex align-items-center gap-2">
            <div className="user_img cursor-pointer" >
              <img
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
            <p className="m-0 text-secondary">Robert Fox</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <IoStar className="star" /> (4 Reviews)
          </div>
        </div>

        <div className="bottom mt-2 py-2 px-1 d-flex align-items-center justify-content-between">
          <button className="py-1 px-3" onClick={toggleModal}>
            Feedback
          </button>

          <button className="py-1 px-3">
            Download Certificate
          </button>
        </div>

        <div className="bottom py-2 px-3 d-flex align-items-center justify-content-between">
          <p className="m-0">
            <TiBook className="fs-5 me-1 mb-1" /> 05
          </p>
          <p className="m-0">
            <IoMdTime className="fs-5 me-1 mb-1" /> 11h 20m
          </p>
          <p className="m-0">
            <IoSchoolOutline className="fs-5 me-1 mb-1" /> 22
          </p>
        </div>
      </div>

      <Modal
        show={show}
        onHide={toggleModal}
        style={{ zIndex: "1111", marginTop: "20vh" }}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col className="d-flex align-items-center justify-content-start">
            {[1, 2, 3, 4, 5].map((index) => (
              <IconButton
                key={index}
                aria-label="rating"
                onClick={() => handleStarClick(index)}
                style={{ color: index <= rating ? "var(--yellow-color)" : "" }}
              >
                {index <= rating ? <FaStar /> : <FaRegStar />}
              </IconButton>
            ))}
          </Col>
          <Col md={12} className="mt-3">
            <Form.Group controlId="textareaInput">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                className="shadow rounded-3"
                as="textarea"
                rows={3}
                placeholder="Leave a Comment."
              />
            </Form.Group>
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={toggleModal}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
