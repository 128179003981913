import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { TiTick } from "react-icons/ti";
import { PiSmileyStickerBold } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";
import { BiSolidError } from "react-icons/bi";
import { IoReloadOutline } from "react-icons/io5";

const Progress = () => {
  return (
    <StyledContainer>
      <Row className="px-5 align-items-center">
        <Col sm={1}>
          <TiTick className="fs-4 text_yellow" />
        </Col>
        <Col sm={10}>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 mb-1">FileName.jpg</p>
            <p className="m-0 mb-1">350MB</p>
          </div>
          <div className="line">
            <div className="line_inner"></div>
          </div>
        </Col>
      </Row>

      <Row className="px-5 align-items-center mt-4">
        <Col sm={1}>
          <PiSmileyStickerBold className="fs-4 text_yellow " />
        </Col>
        <Col sm={10}>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 mb-1">FileName.jpg</p>
            <p className="m-0 mb-1">350MB</p>
          </div>
          <div className="line ">
            <div className="line_inner "></div>
          </div>
        </Col>
        <Col sm={1}>
          <AiOutlineClose className="fs-5" />
        </Col>
      </Row>

      <Row className="px-5 align-items-center mt-4">
        <Col sm={1}>
          <BiSolidError className="fs-4 text-danger" />
        </Col>
        <Col sm={10}>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 mb-1">FileName.jpg</p>
            <p className="m-0 mb-1">350MB</p>
          </div>
          <div className=" line_danger">
            <div className=" line_danger_inner"></div>
          </div>
        </Col>
        <Col sm={1}>
          <IoReloadOutline className="fs-5" />
        </Col>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .line {
    width: 100%;
    height: 4px;
    background-color: #f7e08478;
    border-radius: 10px;
    margin-top: 0.5rem;

    .line_inner {
      width: 50%;
      height: 100%;
      background-color: var(--color-primary);
      border-radius: 10px;
    }
  }

  .line_danger {
    width: 100%;
    height: 4px;
    background-color: #ff595956;
    border-radius: 10px;
    margin-top: 0.5rem;

    .line_danger_inner {
      width: 50%;
      height: 100%;
      background-color: #ff211180;
      border-radius: 10px;
    }
  }
`;

export default Progress;
