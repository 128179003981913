import WalletPage from "./WalletPage";

export const WalletConfig = {
  routes: [
    {
      path: "/parent/wallet",
      exact: true,
      component: WalletPage,
    },
  ],
};
