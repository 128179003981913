import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import OperationLocation from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Button } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";

const FbAndWatsappPage = (props) => {
    const { history } = props;


    return (
        <OperationLayout>
            {/* <OperationLocation path={history} /> */}
            <h4>Fb & Whatsapp</h4>
            <Col className="d-flex align-items-start justify-content-between mt-3">
                <Col md={6}><h6>Whatsapp Groups</h6></Col>
                <Col className="d-flex align-items-center justify-content-end" md={6}> <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" startIcon={<IoMdAdd />}>
                    Add
                </Button></Col>
            </Col>
            <Row className="p-2 mt-3">
                <Col md={8}>Group name 1</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="web.watsapp.com/g/jhds87ad"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Row className="p-2 mt-3">
                <Col md={8}>Group name 2</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="web.watsapp.com/g/jhds87ad"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Col className="d-flex align-items-start justify-content-between mt-3">
                <Col md={6}><h6>Fb Groups</h6></Col>
                <Col className="d-flex align-items-center justify-content-end" md={6}> <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" startIcon={<IoMdAdd />}>
                    Add
                </Button></Col>
            </Col>
            <Row className="p-2 mt-3">
                <Col md={8}>Fb Group 1</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="web.facebook.com/g/jhds87ad"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
            <Row className="p-2 mt-3">
                <Col md={8}>Fb Group 2</Col>
                <Col md={4} className="d-flex align-items-center justify-content-center p-1 border-yellow rounded-3">
                    <Col md={8}>
                        <input
                            type="text" placeholder="web.facebook.com/g/jhds87ad"
                            style={{ paddingLeft: '10px', backgroundColor: 'transparent', width: '80%', height: '6vh', border: 'none', outline: 'none' }}
                        />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                        <Button className="teacher-button rounded-5 border-0 shadow" variant="outlined" size="small" >
                            Edit
                        </Button>
                        <Button className="ms-2 teacher-button rounded-5 border-0 shadow" variant="outlined" size="small">
                            Copy
                        </Button>
                    </Col>
                </Col>
            </Row>
        </OperationLayout>
    );
};

export default FbAndWatsappPage;
