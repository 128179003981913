import React from "react";
import OperationLayout from "../../components/operationComponents/OperationLayout/OperationLayout";
import { Table } from "react-bootstrap";

const TranscriptReportPage = () => {
  return (
    <OperationLayout>
      <h4>Transcript Report</h4>

      <Table className="mt-4" striped bordered hover responsive>
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Student Name</th>
            <th>Class</th>
            <th>Term</th>
            <th>Subject</th>
            <th>Score</th>
            <th>Grade</th>
            <th>Teacher</th>
            <th>Date</th>
            <th>View Transcript</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>1st</td>
            <td>Maths</td>
            <td>90</td>
            <td>A</td>
            <td>Mr. John</td>
            <td>12/12/2021</td>
            <td>
              <button className="btn btn-sm btn-primary">
                View Transcript
              </button>
            </td>
          </tr>
          <tr>
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>1st</td>
            <td>Maths</td>
            <td>90</td>
            <td>A</td>
            <td>Mr. John</td>
            <td>12/12/2021</td>
            <td>
              {" "}
              <button className="btn btn-sm btn-primary">
                View Transcript
              </button>
            </td>
          </tr>
          <tr>
            <td>STU3313</td>
            <td>John Doe</td>
            <td>10th</td>
            <td>1st</td>
            <td>Maths</td>
            <td>90</td>
            <td>A</td>
            <td>Mr. John</td>
            <td>12/12/2021</td>
            <td>
              {" "}
              <button className="btn btn-sm btn-primary">
                View Transcript
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </OperationLayout>
  );
};

export default TranscriptReportPage;
