import React from "react";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout";
import OftadehBreadcrumbs from "../../components/parentComponents/OftadehBreadcrumbs/OftadehBreadcrumbs";
import { Row, Col, Form } from "react-bootstrap";
import "./HomeTuation.css";
import ParentFooter from "../../components/ParentFooter";
function HomeTuation(props) {
  const { history } = props;

  return (
    <OftadehLayout>
      <h3>Home Tution</h3>
      {/* <OftadehBreadcrumbs path={history} /> */}
      <Row>
        <Col
          md={4}
          className="d-flex align-items-center jsutify-content-between mt-2"
        >
          <select
            className="form-control border-yellow rounded-5"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
          >
            <option value="" disabled selected>
              Do You Want Home Tuition
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </Col>
        <div className="form-group col-md-3 mt-2">
          <select
            className="form-control border-yellow rounded-5"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
          >
            <option value="" disabled selected>
              Subject for Tuition
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <Col
          md={3}
          className="d-flex align-items-center jsutify-content-between mt-2"
        >
          <select
            className="form-control border-yellow rounded-5"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
          >
            <option value="" disabled selected>
              Available teachers
            </option>
            <option value="Teacher 1">Teacher 1</option>
            <option value="Teacher 2">Teacher 2</option>
          </select>
        </Col>
      </Row>

      <button className="bg-yellow py-2 px-4 rounded-4 text-white fw-semibold mt-3 border-0">
        See Teacher's Profile
      </button>

      <Row className="mt-3">
        <Col
          md={4}
          className="d-flex align-items-center jsutify-content-between"
        >
          <span style={{ width: "80%" }} className="stting-parent-title">
            Home Address
          </span>
          <span></span>
        </Col>

        <Col
          md={4}
          className="d-flex align-items-center jsutify-content-between"
        ></Col>
      </Row>

      <Col
        md={12}
        className="d-flex align-items-center justify-content-center pe-3"
      >
        <div className="form-group col-md-5 mt-sm-3 mt-2">
          <input
            type="text"
            class="form-control border-yellow rounded-5"
            id="inputEmail4"
            placeholder="Address"
          />
        </div>
        <div className="form-group col-md-5 mt-sm-3 ps-3 mt-2">
          <select
            className="form-control border-yellow rounded-5"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
          >
            <option value="" disabled selected>
              City
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <Col md={2}>&nbsp;</Col>
      </Col>

      <Col
        md={12}
        className="d-flex align-items-center justify-content-center pe-3"
      >
        <div className="form-group col-md-5 mt-sm-3 mt-2">
          <select
            className="form-control border-yellow rounded-5"
            style={{ width: "100%", height: "6vh" }}
            id="gender"
          >
            <option value="" disabled selected>
              Country
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="form-group col-md-5 mt-sm-3 ps-3 mt-2">
          <input
            type="text"
            class="form-control border-yellow rounded-5"
            id="inputEmail4"
            placeholder="Pincode"
          />
        </div>
        <Col md={2}>&nbsp;</Col>
      </Col>

      <Row className="mt-3">
        <Col
          md={4}
          className="d-flex align-items-center jsutify-content-between"
        >
          <span style={{ width: "80%" }} className="stting-parent-title">
            Timing of tuition
          </span>
          <span></span>
        </Col>

        <Col
          md={4}
          className="d-flex align-items-center jsutify-content-between"
        ></Col>
      </Row>

      <Row className="mt-3">
        <Col md={4} className="mt-2">
          <Form.Group controlId="formDate">
            <Form.Label>Days</Form.Label>
            <Form.Control className="border-yellow rounded-5" type="date" />
          </Form.Group>
        </Col>
        <Col md={3} className="mt-2">
          <Form.Group controlId="formDate">
            <Form.Label>start time</Form.Label>
            <Form.Control className="border-yellow rounded-5" type="time" />
          </Form.Group>
        </Col>
        <Col md={3} className="mt-2">
          <Form.Group controlId="formDate">
            <Form.Label>end time</Form.Label>
            <Form.Control className="border-yellow rounded-5" type="time" />
          </Form.Group>
        </Col>
      </Row>
      <ParentFooter />
    </OftadehLayout>
  );
}

export default HomeTuation;
