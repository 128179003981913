import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Navbar from "../../components/Navbar.js";
import { Col, Row, Button, Form, Container } from "react-bootstrap";
import "../../assets/css/parent/SignIn.css";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";


function OperationLogin() {
    const navigate = useNavigate();
    const handleDashboard = () => {
        localStorage.setItem("parentToken", "res.token");
        navigate("/parent/dashboard");
    };

    return (
        <>
            <ToastContainer position="bottom-right" />
            <div className="main admin-main parent-main-container" style={{ overflowY: 'hidden' }}>

                <Navbar />
                <br />
                <br />
                <br />
                <Container className="mt-5 d-flex align-items-center justify-content-center p-2">
                    <Row>
                        <Col></Col>
                        <Col md={5} className="login-container-semi-p mt-3 p-5 pt-4 pb-2 border-0" style={{ overflowY: 'hidden' }}>
                            <Row>
                                <Col md={12}><center><h2>Operation Login</h2></center></Col>
                                <br /><br />
                                <Col md={12} className='mt-3 mb-3'>
                                    <Form.Group controlId='nameInput'>
                                        <Form.Control
                                            className='shadow rounded-2 border-2'
                                            type='email'
                                            placeholder='Enter Username'
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <center><h5>Or</h5></center> */}
                                <Col md={12} className=' mb-3'>
                                    <Form.Group controlId='nameInput'>
                                        <Form.Control
                                            className='shadow rounded-2 border-2'
                                            type='number'
                                            placeholder='Enter mobile number.'
                                        />
                                    </Form.Group>
                                </Col>
                                <div className="remember-area" style={{width:'100%'}}>
                                    <div className="left">
                                        <input type="checkbox" name="remember" id="remember" />
                                        <label htmlFor="remember"> &nbsp; Remember me</label>
                                    </div>
                                    <div className="right login-n-link">Reset Password</div>
                                </div>
                                {/* <div className="mt-3 d-flex align-items-center justify-content-between">
                                    <span className="text-start">Already have a account? <Link className="text-primary" to={'/parent/sign-in'} >sign-in</Link> </span>
                                </div> */}
                            </Row>
                            <Button onClick={handleDashboard} style={{ width: '100%' }} className="p-2 bg-dark text-white border-0">Log In</Button>
                            {/* <center><p className="text-primary">need help ?</p></center> */}
                            <center><p className="text-primary">&nbsp;</p></center>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default OperationLogin;
